<script setup>
    import { ref, onMounted, getCurrentInstance } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import SNBSysAdmin from '../../../../layouts/nav/SNBSysAdmin.vue'
    import systemSchedulerService from "../../services/scheduler/systemSchedulerService";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useSchedulerDetailStore} from "../../stores/schedulerDetailStore";
    import _ from 'lodash'

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    const { $toast } = getCurrentInstance().appContext.config.globalProperties;


    onMounted(()=>{
        const schedulerDetailStore = useSchedulerDetailStore();
        if(schedulerDetailStore.trigger && schedulerDetailStore.trigger.triggerId) {
            input.value = schedulerDetailStore.trigger;

            const { taskHms } = input.value;
            if(taskHms) {
                input.value.taskHms = taskHms.replace(':', '').replace(':', '');
            }
        } else {
            router.push({name:'SchedulerManage'});
        }
    })

    const input = ref({
        triggerId: 0,
        triggerClsf: "",
        triggerName: "",
        taskId: "",
        taskData: "",
        startYmd: "",
        expression: "",
        expressionTxt: "",
        taskHms: "",
        repeatGb: "DAY",
        repeatNum: "",
        repeatCount: "",
        createId: 0
    })

    const inputError = ref({
        triggerClsf: "",
        triggerName: "",
        taskId: "",
        taskData: "",
        startYmd: "",
        expression: "",
        expressionTxt: "",
        taskHms: "",
        repeatGb: "",
        repeatNum: "",
        repeatCount: "",
    })

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref('');

    const create = () => {
        let isInvalid = false;

        let reqObj = _.cloneDeep(input.value);
        const { triggerClsf, triggerName, taskId, taskData, startYmd, taskHms, repeatNum } = reqObj;

        inputError.value = {
            triggerClsf: "",
            triggerName: "",
            taskId: "",
            taskData: "",
            startYmd: "",
            expression: "",
            expressionTxt: "",
            taskHms: "",
            repeatGb: "",
            repeatNum: "",
            repeatCount: "",
        };

        if(!triggerClsf) {
            inputError.value.triggerClsf = '필수 입력 항목입니다.';
            isInvalid = true;
        }
        if(!triggerName) {
            inputError.value.triggerName = '필수 입력 항목입니다.';
            isInvalid = true;
        }
        if(!taskId) {
            inputError.value.taskId = '필수 입력 항목입니다.';
            isInvalid = true;
        }
        if(!startYmd) {
            inputError.value.startYmd = '필수 입력 항목입니다.';
            isInvalid = true;
        } else if(startYmd.length !== 8) {
            inputError.value.startYmd = 'YYYYMMDD 8자리를 입력하세요.';
            isInvalid = true;
        }

        if(!taskHms) {
            inputError.value.taskHms = '필수 입력 항목입니다.';
            isInvalid = true;
        } else if(taskHms.length !== 6) {
            inputError.value.taskHms = 'HHmmss 6자리를 입력하세요.';
            isInvalid = true;
        }

        if(!repeatNum) {
            inputError.value.repeatNum = '필수 입력 항목입니다.';
            isInvalid = true;
        }

        if(!isInvalid) {
            reqObj.taskHms = `${reqObj.taskHms.substring(0,2)}:${reqObj.taskHms.substring(2,4)}:${reqObj.taskHms.substring(4)}`;
            loadingStore.loading = true;
            systemSchedulerService.updateScheduler(reqObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        $toast('', '저장 되었습니다.');
                    } else {
                        modalOpen(["저장을 실패했습니다."]);
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalOpen([res.response.data.result.message]);
            });
        }
    }

    const deleteScheduler = async () => {
        modalContent.value = ["스케쥴러를 삭제하시겠습니까?"];
        const ok = await confirmModal.value.show();

        if(ok) {
            loadingStore.loading = true;
            systemSchedulerService.deleteScheduler(input.value.triggerId)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        $toast('', '삭제 되었습니다.');
                        router.push({name: 'SchedulerManage'});
                    } else {
                        modalOpen(["삭제를 실패했습니다."]);
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalOpen([res.response.data.result.message]);
            });
        }
    }

    const modalOpen = (message) => {
        modalContent.value = message;
        modal.value.show();
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBSysAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>스케쥴러 수정</h3>
                    <p><span>* 표시는 필수 입력사항</span> 입니다.</p>
                </div>
                <div class="content-inner">
                    <div class="content-title">
                        <h4>기본 정보</h4>
                    </div>
                    <div class="table-form">
                        <table class="vertical">
                            <colgroup>
                                <col width="200px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th class="mark">트리거 분류</th>
                                <td>
                                    <KSAInput v-model="input.triggerClsf" placeholder="트리거 분류를 입력하세요." max-length="200" :msg="inputError.triggerClsf"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">트리거명</th>
                                <td>
                                    <KSAInput v-model="input.triggerName" placeholder="트리거명을 입력하세요." max-length="200" :msg="inputError.triggerName"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">taskId</th>
                                <td>
                                    <KSAInput v-model="input.taskId" placeholder="작업아이디를 입력하세요." max-length="50" :msg="inputError.taskId"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="">taskData</th>
                                <td>
                                    <KSAInput v-model="input.taskData" placeholder="작업데이터를 입력하세요." max-length="2000" :msg="inputError.taskData"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">시작일자(YYYYMMDD)</th>
                                <td>
                                    <KSAInput v-model="input.startYmd" placeholder="YYYYMMDD 입력하세요." max-length="8" :msg="inputError.startYmd"/>
                                </td>
                            </tr>
<!--                            <tr>-->
<!--                                <th class="">작업표현시간(cron)</th>-->
<!--                                <td>-->
<!--                                    <KSAInput v-model="input.expression" placeholder="작업표현시간(cron) 입력하세요." :msg="inputError.expression"/>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <th class="">반복표현시간(text)</th>-->
<!--                                <td>-->
<!--                                    <KSAInput v-model="input.expressionTxt" placeholder="반복표현시간(text) 입력하세요." :msg="inputError.expressionTxt"/>-->
<!--                                </td>-->
<!--                            </tr>-->
                            <tr>
                                <th class="mark">작업시분초(HHmmss)</th>
                                <td>
                                    <KSAInput v-model="input.taskHms" placeholder="작업시분초(HHmmss) 6자리를 입력하세요." max-length="6" :msg="inputError.taskHms"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">반복구분</th>
                                <td>
                                    <KSARadioButton class="mr-md" name="type" label="초" v-model="input.repeatGb" value="SECOND" />
                                    <KSARadioButton class="mr-md" name="type" label="분" v-model="input.repeatGb" value="MINUTE" />
                                    <KSARadioButton class="mr-md" name="type" label="시간" v-model="input.repeatGb" value="HOUR" />
                                    <KSARadioButton class="mr-md" name="type" label="일" v-model="input.repeatGb" value="DAY" />
                                    <KSARadioButton class="mr-md" name="type" label="주" v-model="input.repeatGb" value="WEEK" />
                                    <KSARadioButton class="mr-md" name="type" label="월" v-model="input.repeatGb" value="MONTH" />
                                    <KSARadioButton class="mr-md" name="type" label="년" v-model="input.repeatGb" value="YEAR" />
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">반복주기</th>
                                <td>
                                    <KSAInput v-model="input.repeatNum" placeholder="반복주기 입력하세요." :msg="inputError.repeatNum"/>
                                </td>
                            </tr>
<!--                            <tr>-->
<!--                                <th class="mark">반복한갯수</th>-->
<!--                                <td>-->
<!--                                    <KSAInput v-model="input.repeatCount" placeholder="반복한갯수 입력하세요." :msg="inputError.triggerClsf"/>-->
<!--                                </td>-->
<!--                            </tr>-->
                        </table>
                    </div>
                </div>
                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="취소" @click="router.push({name:'SchedulerManage'})" />
                    <div>
                        <KSASecondaryButton class="red_button" text="삭제" @click="deleteScheduler" />
                        <KSAPrimaryButton text="저장" @click="create" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent"/>
    <ConfirmModal ref="confirmModal" :content="modalContent"/>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>