<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>대표 강의 설정</h3>
            </div>
            <div class="modal-body">
                <div class="list-top mb-xs">
                    <p class="text-left">
                        - 공개 중인 강의 {{ limit }}개를 대표 강의로 설정할 수 있습니다.<br>
                        - 현재 대표 강의는 {{ count }}개 추가되어 있습니다.
                    </p>
                    <div class="search-box">
                        <KSASelect :options="[{label:'전체', value:''},{label:'온라인', value:'01'},{label:'오프라인', value:'02'}]" class="select" @selectOption="onSelectLectGb"/>
                    </div>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th><KSACheckBox v-model="isSelectedAll" /></th>
                            <th>구분</th>
                            <th>강의명</th>
                            <th>신청 기간</th>
                            <th>교육 기간</th>
                        </tr>
                        <tr v-for="lect in getListByLecgGb(lectureList)">
                            <td><KSACheckBox v-model="lect.repreLectYn" /></td>
                            <td>{{ lect.lectGb === '01' ? '온라인' : '오프라인' }}</td>
                            <td class="text-left">{{ lect.lectName }}</td>
                            <td>
                                <span v-if="lect.regTermGb === '01'">상시</span>
                                <span v-else>{{ $yyyymmdd(lect.regStartYmd) }} ~ {{ $yyyymmdd(lect.regEndYmd) }}</span>
                            </td>
                            <td>
                                <span v-if="lect.lectTermGb === '01'">신청일로부터 {{ lect.lectTerm }}일</span>
                                <span v-else>{{ $yyyymmdd(lect.lectStartYmd) }} ~ {{ $yyyymmdd(lect.lectEndYmd) }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSAPrimaryButton text="추가" @click="confirm" />
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
</template>


<script>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, watch, onMounted } from "vue";
import _ from 'lodash'

export default {
    name: "LectureMainSettingModal",
    components: {
        Modal,
    },
    props: {
    },
    setup() {
        const baseModal = ref(null);
        const resolvePromise = ref(null);

        const isSelectedAll = ref(false)

        const lectureList = ref([])
        const limit = ref(4)
        const count = ref(0)

        const lectGb = ref('') // KSASelect - select

        const alertModal = ref(null)
        const modalContent = ref([])

        let disabledWatchIsSelectedAll = false;
        let disabledWatachList = false;

        onMounted(()=>{
            setTimeout(()=>{
                baseModal.value.isAdminWrap = true;
            }, 500);
        })

        watch(isSelectedAll, (newValue)=>{
            if(!disabledWatchIsSelectedAll) {
                const newList = _.cloneDeep(lectureList.value);
                _.each(newList, (el)=>{
                    el.repreLectYn = newValue;
                });
                disabledWatachList = true;
                lectureList.value = newList;
            } else {
                disabledWatchIsSelectedAll = false;
            }
        })

        watch(lectureList, (newValue)=>{
            if(!disabledWatachList) {
                const checkedList = _.filter(newValue, { repreLectYn: true});
                if(checkedList.length === newValue.length) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = true;
                } else {
                    if(isSelectedAll.value) {
                        disabledWatchIsSelectedAll = true;
                        isSelectedAll.value = false;
                    }
                }
            } else {
                disabledWatachList = false;
            }
        }, { deep: true})

        const show = () => {
            baseModal.value.open();
            return new Promise((resolve, _) => {
                resolvePromise.value = resolve;
            });
        };

        const confirm = () => {
            const checkedList = _.filter(lectureList.value, { repreLectYn: true});
            if(checkedList.length > limit.value){
                modalContent.value = [`대표 강의는 최대 ${limit.value}개까지 설정할 수 있습니다.`];
                alertModal.value.show();
            } else { // 대표 강의로 설정
                baseModal.value.close();
                resolvePromise.value(lectureList.value);
            }
        };

        const cancel = () => {
            baseModal.value.close();
            resolvePromise.value();
        };

        const init = (params) => {
            isSelectedAll.value = false;
            if(params !== undefined) {
                // console.log(params)
                lectureList.value = params.list;
                limit.value = params.limit;
                count.value = params.count;
            }
        }

        const getListByLecgGb = () => {
            return _.filter(lectureList.value, lect => {
                return lect.lectGb.indexOf(lectGb.value) > -1;
            })
        }

        const onSelectLectGb = (option) => {
            lectGb.value = option.value;
        }

        return { baseModal, show, confirm, cancel, isSelectedAll, lectureList, limit, count, init, alertModal, modalContent, getListByLecgGb, onSelectLectGb  };
    },
};
</script>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

</style>