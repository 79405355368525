<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>총괄표</h3>
            </div>
            <img width="100%" src="/src/assets/images/lecture_curriculum.jpg" alt="교육 프로그램 총괄표" />



            <div class="title page-title">
                <h3>선발 절차 / 교육 특전</h3>
            </div>
            <div class="process-wrap">
                <div class="left-area">
                    <div class="title-box">
                        <b>온라인 교육</b>
                        <small>(온라인 교육의 경우, 별도의 선발과정 없이 신청 완료하신 분에 한해 수강 가능합니다.)</small>
                    </div>
                    <ul>
                        <li>
                            <img src="/src/assets/images/lecture-step1.png" alt="모집공고" />
                            <span>모집공고</span>
                        </li>
                        <li>
                            <img src="/src/assets/images/lecture-step2.png" alt="접수" />
                            <span>접수</span>
                        </li>
                        <li>
                            <img src="/src/assets/images/lecture-step3.png" alt="신청완료" />
                            <span>신청완료</span>
                        </li>
                    </ul>
                    <div class="title-box">
                        <b>오프라인 교육</b>
                        <small>(재직자 교육 및 학점인정교육의 경우 선발 절차가 다를 수 있습니다.)</small>
                    </div>
                    <ul>
                        <li>
                            <img src="/src/assets/images/lecture-step1.png" alt="모집공고" />
                            <span>모집공고</span>
                        </li>
                        <li>
                            <img src="/src/assets/images/lecture-step2.png" alt="서류 (1차)" />
                          <span>서류 (1차)</span>
                        </li>
                        <li>
                            <img src="/src/assets/images/lecture-step3_offline.png" alt="면접 (2차)" />
                          <span>면접 (2차)</span>
                        </li>
                        <li>
                            <img src="/src/assets/images/lecture-step4.png" alt="최종 선발" />
                          <span>최종 선발</span>
                        </li>
                    </ul>
                </div>
                <div class="right-area">
                    <div class="title-box blue">
                        <b>아카데미 특전</b>
                    </div>
                    <div class="text-box">
                        <p><i>1.</i>모든 강의를 <b>전액 무료로 제공</b>합니다.</p>
                    </div>
                    <div class="text-box">
                        <p><i>2.</i>교육과정 및 취업 역량 강화를 위한 <b>컨설팅을 제공</b>합니다.</p>
                    </div>
                    <div class="text-box">
                        <p><i>3.</i>기업체 초빙 <b>직무특강 및 채용설명회를 개최</b>합니다.</p>
                    </div>
                    <div class="text-box">
                        <p><i>4.</i>심화 과정은 Team-Project를 포함한 <b>실습 중심의 교육</b>으로 진행됩니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    hr {
        background-color: $gray-regular_color;
        height: 1px;
        border: 0;
        line-height: inherit;
        margin: 20px 0 0;
    }

    .process-wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & > div {
            &.left-area {
                width: 828px;
                border-right: $border_line;
                padding-right: 73px;
            }
            &.right-area {
                width: 614px;
                padding-left: 73px;
                .title-box {
                    margin-bottom: 10px;
                }
            }
        }
        .title-box {
            background-color: $gray-light_color;
            @include font-size_lg;
            padding: 10px 20px;
            margin-bottom: 20px;
            &.blue {
                background-color: $blue_color;
                color: $gray-thin_color;
            }
            small {
                @include font-size_sm;
                margin-left: 4px;
            }
        }
        .text-box {
            position: relative;
            height: 97px;
            @include font-size_md;
            background-color: $gray-light_color;
            margin-top: 6px;
            p {
                position: absolute;
                top: 50%;
                left: 40px;
                transform: translateY(-50%);
                i {
                    position: absolute;
                    font-style: normal;
                    left: -20px;
                }
            }
        }
        ul {
            display: flex;
            align-items: center;
            padding: 0 20px 80px;
            &:last-child {
                padding-bottom: 0;
            }
            li {
                position: relative;
                @include font-size_md;
                text-align: center;
                margin-right: 84px;
                img {
                    display: block;
                    margin-bottom: 10px;
                }
                &:last-child {
                    color: $blue_color;
                    font-weight: 700;
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 40px;
                    right: -66px;
                    width: 48px;
                    height: 26px;
                    background: url(/src/assets/images/lecture-step_arrow.png) no-repeat center;
                }
            }
        }
    }
</style>