// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, reactive, watch, computed } from 'vue'

export const useUserStore = defineStore('user', () => {
    const user = reactive({
        userGb: '',
        userCd: '',
        userLoginId: '',
        userName: '',
        token: '',
        refreshToken: '',
        userInfo: {},
        accessMenu: '',
    })

    const isAdmin = computed(()=>{
        return user.userGb && user.userGb.toLowerCase().indexOf('admin') > -1;
    })

    const isSysAdmin = computed(()=>{
        return user.userGb && user.userGb.toUpperCase() === 'SYSTEM_ADMIN';
    })

    const isAuthenticated = computed(()=>{
        return user.userLoginId !== null && user.userLoginId !== '';
    })

    const userInfo = ref({}) // user mypage profile 정보

    const userInStorage = sessionStorage.getItem('user')
    if (userInStorage) {
        const { userGb, userCd, userLoginId, userName, token, refreshToken, userInfo, accessMenu } = JSON.parse(userInStorage)
        user.userGb = userGb || '';
        user.userCd = userCd || '';
        user.userLoginId = userLoginId || '';
        user.userName = userName || '';
        user.token = token || '';
        user.refreshToken = refreshToken || '';
        user.userInfo = userInfo || {};
        user.accessMenu = accessMenu || '';
    }

    watch(() => user, (state) => {
        sessionStorage.setItem('user', JSON.stringify(state))
    }, { deep: true })

    // setUser simple function
    function setUser ({ userGb, userCd, userLoginId, userName, token, refreshToken, userInfo, accessMenu }) {
        user.userGb = userGb || '';
        user.userCd = userCd || '';
        user.userLoginId = userLoginId || '';
        user.userName = userName || '';
        user.token = token || '';
        user.refreshToken = refreshToken || '';
        user.userInfo = userInfo || {};
        user.accessMenu = accessMenu || '';
    }

    function setUserName(userName) {
        user.userName = userName;
    }

    function setToken(token) {
        user.token = token;
    }

    function setRefreshToken(token) {
        user.refreshToken = token;
    }

    function setAccessMenu(accessMenu) {
        user.accessMenu = accessMenu;
    }

    return {
        user,
        setUser,
        setUserName,
        setToken,
        setRefreshToken,
        setAccessMenu,
        isAdmin,
        isSysAdmin,
        isAuthenticated,
        userInfo
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
