import { defineStore, acceptHMRUpdate } from 'pinia'
import { reactive, watch } from 'vue'

export const useMatchingUpdateStore = defineStore( 'matchingUpdateStore', () => {

    const matchingUpdate = reactive({
        propStateCnt: 0,
        resStateCnt: 0,
        delayResStateCnt: 0,
        delayPropStateCnt: 0,
    })

    const useMatchingUpdateInStorage = sessionStorage.getItem( 'matchingUpdate' )
    if( useMatchingUpdateInStorage ) {
        const { propStateCnt, resStateCnt, delayResStateCnt, delayPropStateCnt } = JSON.parse(useMatchingUpdateInStorage)
        matchingUpdate.propStateCnt = propStateCnt || 0;
        matchingUpdate.resStateCnt = resStateCnt || 0;
        matchingUpdate.delayResStateCnt = delayResStateCnt || 0;
        matchingUpdate.delayPropStateCnt = delayPropStateCnt || 0;
    }

    watch(() => matchingUpdate, (state) => {
        sessionStorage.setItem('matchingUpdate', JSON.stringify(state))
    }, { deep: true })

    function setMatchingUpdate ({ propStateCnt, resStateCnt, delayResStateCnt, delayPropStateCnt}) {
        matchingUpdate.propStateCnt = propStateCnt || 0;
        matchingUpdate.resStateCnt = resStateCnt || 0;
        matchingUpdate.delayResStateCnt = delayResStateCnt || 0;
        matchingUpdate.delayPropStateCnt = delayPropStateCnt || 0;
    }



    return {
        matchingUpdate,
        setMatchingUpdate,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMatchingUpdateStore, import.meta.hot))
}