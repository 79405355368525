import http from '../../../../api/http';

function retrieveAllPaging(paramObj) {
    return http.post('/insights/faq/retrieveAllPaging', {
        boardGb: "FAQ",
        boardGroup: paramObj.boardGroup || '',
        boardGroupList: paramObj.boardGroupList,
        pageNumber: paramObj.pageNo,
        size: paramObj.size,
        searchType: paramObj.searchType,
        searchInput: paramObj.searchInput,
        startYmd: paramObj.startYmd,
        endYmd: paramObj.endYmd,
    });
}

function retrieveDetail(boardId) {
    return http.post('/insights/faq/retrieve', {
        boardId
    });
}

function createFAQ(paramObj) {
    return http.post('/insights/faq/create', {
        boardId: paramObj.boardId,
        boardGb:paramObj.boardGb || 'FAQ',
        boardGroup: paramObj.boardGroup,
        title: paramObj.title,
        content: paramObj.content,
        secretYn: paramObj.secretYn,
        answer: paramObj.answer,
        hitCount: paramObj.hitCount,
        createId: paramObj.createId,
        attachList: paramObj.attachList,
    });
}

function updateFAQ(paramObj) {
    return http.post('/insights/faq/update', {
        boardId: paramObj.boardId,
        boardGb:paramObj.boardGb || 'FAQ',
        boardGroup: paramObj.boardGroup,
        title: paramObj.title,
        content: paramObj.content,
        secretYn: paramObj.secretYn,
        answer: paramObj.answer,
        hitCount: paramObj.hitCount,
        createId: paramObj.createId,
        attachList: paramObj.attachList,
    });
}

function deleteFAQ(boardId) {
    return http.post('/insights/faq/delete', {
        boardId
    });
}


export default {
    retrieveAllPaging,
    retrieveDetail,
    createFAQ,
    updateFAQ,
    deleteFAQ
};