<script setup>
    import { ref, watch, onMounted, getCurrentInstance } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import lectureService from "../../services/lecture/lectureService";
    import _ from 'lodash'
    import {useLectureStore} from "../../stores/lecture/lectureStore";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const lectureStore = useLectureStore()
    const { $toast, $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveLectureList()
    })

    const list = ref([])
    const searchOpen = ref(true)

    const searchInput = ref({
        lectGb: '00',
        lectName: '',
        searchDurationGb: 'C',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    })

    const searchDurationGbRef = ref(null)

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const lectGbGroup = ref([
        { label:"전체", value: "00" },
        { label:"온라인", value: "01" },
        { label:"오프라인", value: "02" },
    ]);

    const searchDurationGbGroup = ref([
        { label:"교육 기간", value: "C" },
        { label:"신청 기간", value: "U" },
    ])

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const modal = ref(null)
    const modalContent = ref([])

    const retrieveLectureList = () => {

        let paramObj = {
            lectGb: searchInput.value.lectGb,
            lectName: searchInput.value.lectName,
            searchDurationGb: searchInput.value.searchDurationGb,
            startYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.startYmd,
            endYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        lectureService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                    _.each(list.value, lect => {
                        lect.checked = false;
                    })
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        searchInput.value.lectGb = '00';
        searchInput.value.lectName = '';
        searchInput.value.searchDurationGb = 'C';
        searchInput.value.searchDurationType = 'all';

        searchDurationGbRef.value.initSelectedOption(searchInput.value.searchDurationGb)
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveLectureList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveLectureList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveLectureList();
    }

    const onSelectSearchDurationGbGroup = (option) => {
        searchInput.value.searchDurationGb = option.value;
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>수강 관리</h3>
                </div>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen = !searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>구분</dt>
                            <dd>
                                <template v-for="group in lectGbGroup" key="person.name" >
                                    <KSARadioButton class="mr-md" :label="group.label" v-model="searchInput.lectGb" :value="group.value" />
                                </template>
                            </dd>
                        </dl>
                        <dl>
                            <dt>강의명</dt>
                            <dd>
                                <KSAInput class="text-box" v-model="searchInput.lectName" placeholder="강의명을 입력해주세요." />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchDurationGbRef" :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                        <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="searchInput.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="searchInput.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                                :startDate="searchInput.startYmd"
                                                :endDate="searchInput.endYmd"
                                                @update:startDate="(value)=> searchInput.startYmd = value"
                                                @update:endDate="(value)=> searchInput.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ totalCount }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>No</th>
                            <th>강의ID</th>
                            <th>구분</th>
                            <th>강의명</th>
                            <th>신청인원</th>
                            <th>선발인원</th>
                            <th>수강생수</th>
                            <th>수료자수</th>
                            <th>신청 기간</th>
                            <th>교육 기간</th>
                        </tr>
                        <tr v-for="lect in list">
                            <td>{{ lect.boardNo }}</td>
                            <td>{{ lect.lectId }}</td>
                            <td>{{ lect.lectGb === '01' ? '온라인' : '오프라인' }}</td>
                            <td class="link text-left"><RouterLink class="flex-wrap" :to="{name: 'LectureCourseDetail', params:{id: lect.lectId }}"><p class="text-over">{{ lect.lectName }}</p><span v-if="lect.repreLectYn === 'Y'" class="badge">대표</span></RouterLink><span></span></td>
                            <td>{{ lect.userLectureCount }}</td>
                            <td>{{ lect.userLectureSelCount }}</td>
                            <td>{{ lect.userLectureStudyCount }}</td>
                            <td>{{ lect.userLecturePassCount }}</td>
                            <td>
                                <span v-if="lect.regTermGb === '01'">상시</span>
                                <span v-else>{{ $yyyymmdd(lect.regStartYmd) }} ~ {{ $yyyymmdd(lect.regEndYmd) }}</span>
                            </td>
                            <td>
                                <span v-if="lect.lectTermGb === '01'">신청일로부터 {{ lect.lectTerm }}일</span>
                                <span v-else>{{ $yyyymmdd(lect.lectStartYmd) }} ~ {{ $yyyymmdd(lect.lectEndYmd) }}</span>
                            </td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>