// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useUserInsightsStore = defineStore('userInsightsStore', () => {

    const noticeList= ref([]);
    const faqList = ref([]);
    const qnaList = ref([]);
    const newsletterList = ref([]);

    const setNoticeList = (list) => {
        noticeList.value = list;
    }

    const setFaqList = (list) => {
        faqList.value = list;
    }

    const setQnaList = (list) => {
        qnaList.value = list;
    }

    const setNewsletterList = (list) => {
        newsletterList.value = list;
    }

    return {
        noticeList,
        faqList,
        qnaList,
        newsletterList,
        setNoticeList,
        setFaqList,
        setQnaList,
        setNewsletterList
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserInsightsStore, import.meta.hot))
}