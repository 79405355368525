<script setup>
    import { ref, defineEmits, toRefs, defineExpose } from "vue";
    const props = defineProps({
        modelValue: { type: String },
        type: { type: String },
        label: { type: String },
        placeholder: { type: String },
        msg: { type: String },
        msgColor: { type: String },
        maxLength: { type: String },
        disabled: { type: Boolean, default: false },
    });
    const { type, label, placeholder, msg, msgColor, maxLength, disabled } = toRefs(props)
    const emit = defineEmits(["update:modelValue", "blur"]);

    const inputRef = ref(null)

    const focus = () => {
        inputRef.value.focus();
    }

    defineExpose({
        focus
    })
</script>

<template>
    <div>
        <label>
            <p v-if="label">{{ label }}</p>
            <input
                ref="inputRef"
                :type="type ? type : 'text'"
                :value="modelValue"
                @input="event => emit('update:modelValue', event.target.value)"
                @blur="emit('blur')"
                :placeholder="placeholder"
                :maxlength="maxLength"
                :disabled="disabled"
            />
        </label>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/ksa_input";

    label {
        p {
            @include font-size_xs;
            color: $gray_color;
        }
    }
    input {
        &:disabled {
            background: $gray-thin_color;
        }
    }
</style>