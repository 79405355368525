<script setup>
    import axios from 'axios'
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, onMounted,  computed, defineExpose, getCurrentInstance } from "vue";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import lectureHomeworkService from "../../../../admin/services/lecture/lectureHomeworkService";
    import KSAInputNumber from "../../../../../components/ui-components/input/KSAInputNumber.vue";
    import lectureUserService from "../../../services/lecture/lectureUserService";

    const loadingStore = usePageLoadingStore()

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const alertModal = ref(null);
    const confirmModal = ref(null);
    const modalContent = ref([]);

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);
        checkIp(); // 접속IP를 markIp에 저장
    })

    const input = ref({    // 과제 생성 정보
        userId: 0,         // 사용자아이디
        lectId: 0,         // 강의아이디
        regDt: 0,          // 신청일시
        hwId: 0,           // 과제아이디
        score: 0,          // 점수
        markIp: "0.0.0.0", // 채점자IP
        fullMarks: 0       // 과제배점
    })
    const homeworkFullMarks = ref(null);

    const updateHomeworkScore = async () => {

        checkIp(); // 접속IP를 markIp에 저장

        const { userId, lectId, regDt, hwId, score, markIp, fullMarks} = input.value;
        let isInvalid = false;

        if(!score || score <= 0) {
            console.log(score)
            modalContent.value = [`점수를 입력하세요.`];
            alertModal.value.show();
            isInvalid = true;
            return false;
        }
        if(score > fullMarks) {
            modalContent.value = [`점수를 배점보다 크게 입력하였습니다.`, `다시 입력하세요.`];
            alertModal.value.show();
            isInvalid = true;
            return false;
        }

        if (!isInvalid) {
            modalContent.value = ["채점 결과를 저장할까요? 저장 후 수정할 수 없습니다."];
            const ok = await confirmModal.value.show();
            if(ok) {
                let paramObj = {
                    userId, lectId, regDt, hwId, score, markIp
                }

                loadingStore.loading = true;
                lectureHomeworkService.saveHomeworkScore(paramObj)
                    .then(async (res) => {
                        loadingStore.loading = false;
                        if (res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            confirm();
                        } else {
                            modalContent.value = ["등록을 실패하였습니다."];
                            alertModal.value.show();
                        }
                    }).catch((res) => {
                    console.log(res);
                    loadingStore.loading = false;
                });
            }
        }
    }

/*    const checkIp = () => { // 접속 IP 저장
        let myIp = '';
        axios.get("https://api.db-ip.com/v2/free/self") // 공인IP 확인 (하루1000개제한)
            .then( (res) => {
                // 접속IP 확인
                myIp = res.data.ipAddress; // db-ip.com
                //myIp = res.data.ip;
                if( myIp.indexOf(',') > 0  ) { // 경로상 IP 제거
                    myIp = myIp.substring(0,myIp.indexOf(','));
                }
                if( myIp.indexOf(':') > 0  ) { // 포트 제거
                    myIp = myIp.substring(0,myIp.indexOf(':'));
                }
                input.value.markIp = myIp;
                console.log("myIp : " + myIp);
                console.log("input.value.markIp : " + input.value.markIp);
            }).catch( (error) =>  {
            console.log(error);
        });
    }*/

    // 접속 IP 저장
    const checkIp = () => {
      let myIp = '';
      lectureUserService.getClientIp()
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              myIp = result; // db-ip.com
              //myIp = res.data.ip;
              if( myIp.indexOf(',') > 0  ) { // 경로상 IP 제거
                myIp = myIp.substring(0,myIp.indexOf(','));
              }
              if( myIp.indexOf(':') > 0  ) { // 포트 제거
                myIp = myIp.substring(0,myIp.indexOf(':'));
              }
              input.value.markIp = myIp;
              console.log("myIp : " + myIp);
              console.log("input.value.markIp : " + input.value.markIp);
            }
          }).catch( (error) =>  {
        console.log(error);
      });
    }


    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        baseModal.value.close();
        resolvePromise.value(true);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const init = (userId, lectId, regDt, hwId, fullMarks) => {
        // 기본값 저장
        input.value.userId = userId;
        input.value.lectId = lectId;
        input.value.regDt = regDt;
        input.value.hwId = hwId;
        input.value.fullMarks = fullMarks;
    }

    defineExpose({
        show,
        init
    })
</script>

<template>
    <Modal ref="baseModal">
        <div class="modal-inner">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>채점하기</h3>
            </div>
            <div class="modal-body">
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="150px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>점수 배점</th>
                            <td>
                                <div class="grid half">
                                    <KSAInputNumber v-model="input.score" maxLength="3" />
                                    <span class="ml-xs">점/{{input.fullMarks}}점</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="닫기" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" text="저장" @click="updateHomeworkScore" />
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="저장" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .modal-inner {
        width: 598px !important;
    }

    .list-top {
        p {
            margin-right: 0;
            padding-left: 2px;
            &:first-of-type {
                padding-left: 0;
            }
        }
    }

    .grid {
        &.half {
            div { width: 234px; }
        }
    }

    .table-form table .grid span { margin: 0 0 0 10px; }

</style>