<script setup>
    import { ref, watch, computed } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { useSiteMenuStore } from '../../stores/sitemenu'
    import { useNavigationStore } from '../../stores/navigation'
    import _ from 'lodash'

    const route = useRoute();
    const router = useRouter();
    const siteMenuStore = useSiteMenuStore();
    const navigationStore = useNavigationStore();

    const userInStorage = sessionStorage.getItem('user')
    let corpMasterYn = ''
    if(userInStorage) {
      const { userInfo } = JSON.parse(userInStorage)
      corpMasterYn = userInfo.corpMasterYn;
    }
    const masterMenuId = [61,62,63,64];

    const alertModal = ref(null)

    const showDropdown = ref({
        level1: false,
        level2: false,
        level3: false,
        level4: false,
    })

    const menuLevel1Options = computed(() => {
        return _.map(_.filter(siteMenuStore.menuList, { menuAreaGb: '01', validYn: 'Y'}), (menu) => {
            return {
                menuId: menu.menuId,
                menuName: menu.menuName,
                menuUrl: menu.menuUrl,
            }
        }) || [];
    })
    // console.log('menuLevel1Options:');
    // console.log(menuLevel1Options);

    const menuLevel2Options = computed(() => {
        const level1Menu = _.find(siteMenuStore.menuList, { menuName: navigationStore.localNavigation.menuLevel1});

        if(level1Menu) {
            const filter1Menu = [];
            for(let i=0; i < level1Menu.child.length; i++) {
              if(masterMenuId.indexOf(level1Menu.child[i].menuId) > -1) {
                if(corpMasterYn === 'Y'){
                  filter1Menu.push(level1Menu.child[i]);
                }
              } else {
                filter1Menu.push(level1Menu.child[i]);
              }
            }
            return _.map(filter1Menu, (menu) => {
                return {
                    menuId: menu.menuId,
                    menuName: menu.menuName,
                    menuUrl: menu.menuUrl,
                }
            }) || [];
        } else {
            return [];
        }
    })
    // console.log('menuLevel2Options:');
    // console.log(menuLevel2Options);

    const onClickMenuLevel1 = () => {
        showDropdown.value.level1 = !showDropdown.value.level1;
        showDropdown.value.level2 = false;
    }

    const onClickMenuLevel2 = () => {
        showDropdown.value.level1 = false;
        showDropdown.value.level2 = !showDropdown.value.level2;
    }

    const onClickMenu = (menu) => {
        const closedMatchingMenuId   = [19]; // 채용열기  // Matching > 채용 지원, 기증 장비 지원
      // const closedMatchingMenuId   = [18,19]; // 채용닫기 // Matching > 채용 지원, 기증 장비 지원
        const closedLectureMenuId    = [15,16,17]; // 세미나, SEMI-PASS, 위탁교육의뢰
        const closedStatisticsMenuId = [5];  // 채용열기      // Statistics
      // const closedStatisticsMenuId = [4,5];  // 채용닫기      // Statistics
        const closedMypageMenuId     = [33,34];    // 마이페이지 > 자격 현황, 장비 매칭 현황
        if([...closedMatchingMenuId, ...closedLectureMenuId, ...closedStatisticsMenuId, ...closedMypageMenuId].indexOf(menu.menuId) > -1) {
            alertModal.value.show();
        } else {
            router.push(menu.menuUrl);
        }
    }

    const closeDropdown = () => {
        showDropdown.value = {
            level1: false,
            level2: false,
            level3: false,
            level4: false,
        }
    }

    document.body.addEventListener('click', function(event){
        if(!event.target.closest('.select-wrap')) {
            closeDropdown();
        }
    });
</script>

<template>
    <div class="global-nav">
        <div class="inner">
            <div @click="router.push({name: 'Home'})">
                <p>홈</p>
            </div>
            <div class="select-wrap" :class="{'open': showDropdown.level1}" v-if="menuLevel1Options.length > 0" @click="onClickMenuLevel1">
                <p>{{ navigationStore.localNavigation.menuLevel1 }}</p>
                <ul class="select-item" :class="{'hide': !showDropdown.level1}" v-show="showDropdown.level1">
                    <li v-for="menu in menuLevel1Options" @click="onClickMenu(menu)">{{ menu.menuName }}</li>
                </ul>
            </div>
            <div class="select-wrap" :class="{'open': showDropdown.level2}" v-if="menuLevel2Options.length > 0" @click="onClickMenuLevel2">
                <p>{{ navigationStore.localNavigation.menuLevel2 }}</p>
                <ul class="select-item" :class="{'hide': !showDropdown.level2}" v-show="showDropdown.level2">
                    <li v-for="menu in menuLevel2Options" @click="onClickMenu(menu)">{{ menu.menuName }}</li>
                </ul>
            </div>
        </div>
    </div>
    <AlertModal ref="alertModal" :content="['준비중 입니다.']" />
</template>

<style lang="scss">
    @import "../../assets/scss/theme";

    .global-nav {
        border-top: $border_line;
        border-bottom: $border_line;
        background: $gray-light_color;
       padding:116px 20px 0 20px;
        .inner {
            display: flex;
            align-items: center;
            > div {
                position: relative;
                float: left;
                width: 240px;
                height: 56px;
                line-height: 20px;
                color: $gray_color;
                cursor: pointer;
                padding: 16px 20px;
                p {
                    line-height: inherit;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 16px;
                    left: 0;
                    display: block;
                    width: 1px;
                    height: 24px;
                    background: $gray-regular_color;
                }
                &:first-child {
                    width: fit-content;
                    padding-left: 0;
                    &:before {
                        display: none;
                    }
                }
                &.select-wrap {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        display: block;
                        width: 5px;
                        height: 5px;
                        border-top: 1px solid $gray_color;
                        border-left: 1px solid $gray_color;
                        -webkit-transform: translateY(-50%) rotate(225deg);
                        transform: translateY(-50%) rotate(225deg);
                        transition-duration: 0.2s;
                    }
                    &.open {
                        &:after {
                            transform: rotate(45deg);
                            transition-duration: 0.2s;
                        }
                    }
                    .select-item {
                        position: absolute;
                        margin-top: 20px;
                        left: 20px;
                        width: 200px;
                        background: #FFF;
                        border: $border_line;
                        color: #000;
                        padding: 20px;
                        z-index: 1;
                        &.hide {
                            height: 0;
                            padding: 0 20px !important;
                            border-bottom: 0 !important;
                            transform: translateY(0);
                            -webkit-transform: translateY(0);
                        }
                        li {
                            @include font-size_xs;
                            cursor: pointer;
                            margin-bottom: 16px;
                            a {
                                display: block;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:hover {
                                color: $blue_color;
                            }
                        }
                    }
                }
            }
        }
    }
</style>