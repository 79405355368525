    <script setup>
    import { ref, onMounted, defineExpose, getCurrentInstance } from "vue";
    import { useRouter } from 'vue-router'
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import mypageProposalService from "../../../services/mypage/mypageProposalService";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import MyPageResumeModal from "../mypage/MyPageResumeModal.vue";
    import MypageProposalContentModal from "../../../views/mypage/proposal/MypageProposalContentModal.vue";
    const loadingStore = usePageLoadingStore()
    const { $pageListBoardNo, $number, $yyyymmddHHmmss } = getCurrentInstance().appContext.config.globalProperties;

    const router = useRouter()

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 5;
    const totalPages = ref(1);
    const totalCount = ref(0);

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const alertModal = ref(null);
    const resumeModal = ref(null);
    const proposalModal = ref(null);
    const modalContent = ref([]);

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);

        retrieveList();
    })

    // 채용 제안서 리스트 조회
    const retrieveList = () => {
        let paramObj = {
            pageNumber: currentPage.value,
            size: pageSize,
            // searchInterests: ''
        }

        loadingStore.loading = true;
        mypageProposalService.retrieveProposalList(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                }
            }).catch( (error) =>  {
              loadingStore.loading = false;
              console.log(error);
        });
    }

    // 채용 제안서 보기
    const showProposal = async (propId) => {
        // 채용 제안서 작성 - 채용 제안서 보기 / 아래 버튼 2개(제안서 수정,인재 검색) 표시
        proposalModal.value.init(true);
        proposalModal.value.retrieveProposalContent(propId);
        await proposalModal.value.show();
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        baseModal.value.close();
        resolvePromise.value(true);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    defineExpose({
        show
    })
</script>

<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>채용 제안서 선택</h3>
                <p>
                    기존 전송한 제안서를 선택하여 인재에게 다시 제안할 수 있습니다.<br>
                    새로운 제안서 작성을 원하실 경우 [채용 제안서 작성하기]를 눌러주세요.
                </p>
            </div>
            <div class="modal-body">
                <div class="list-top">
                    <p>총 <span>{{ $number(totalCount)}}</span>개</p>
                </div>
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col />
                            <col width="150px" />
                            <col width="150px" />
                        </colgroup>
                        <tr>
                            <th class="text-center">제목</th>
                            <th class="text-center">전송일시</th>
                            <th class="text-center">제안서 확인</th>
                        </tr>
                        <tr v-for="proposal in list">
                            <td class="text-left">{{ proposal.title }}</td>
                            <td class="text-center">{{ $yyyymmddHHmmss(proposal.createDate) }}</td>
                            <td class="link text-center">
                                <KSATextButton text="제안서 보기" @click="showProposal(proposal.propId)" />
                            </td>
                        </tr>
                        <tr v-if="list.length === 0">
                            <td class="text-center" colspan="3">
                                전송한 채용 제안서가 없습니다.
                            </td>
                        </tr>
                        <tr>
                            <th class="text-left" style="font-weight: 400;"></th>
                            <th colspan="2" class="link text-right" style="font-weight: 400; ">
                                <KSATextButton text="[채용 제안서 작성하기]" @click="router.push({name: 'MypageProposalCreateView'})" />
                            </th>
                        </tr>
                    </table>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectedPage"
                />
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
            </div>
        </div>
    </Modal>
    <MyPageResumeModal ref="resumeModal"/>
    <MypageProposalContentModal ref="proposalModal"/>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .modal-inner {
        width: 598px !important;
    }

    .list-top {
        p {
            margin-right: 0;
            padding-left: 2px;
            &:first-of-type {
                padding-left: 0;
            }
        }
    }

    .grid {
        &.half {
            div { width: 312px; }
        }
    }

    .modal-title p span {
        color: $red_color;
        font-weight: 700;
    }

    .table-form table .grid span { margin: 0 0 0 10px; }

</style>