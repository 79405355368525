import http from '../../../../api/http.js'

function retrieveList(paramObj) {
    return http.post('/system/trigger/retrieveAllPaging', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 20,
        searchType: paramObj.searchType || '',
        searchInput: paramObj.searchInput || ''
    })
}

function createScheduler(paramObj) {
    return http.post('/system/trigger/create',paramObj)
}


function updateScheduler(paramObj) {
    return http.post('/system/trigger/update',paramObj)
}

function deleteScheduler(triggerId) {
    return http.post('/system/trigger/delete',{
        triggerId,
    })
}

export default {
    retrieveList,
    createScheduler,
    updateScheduler,
    deleteScheduler
}