<script setup>
    import { ref, reactive, defineEmits, defineProps, defineExpose, onMounted, toRefs } from 'vue'
    import Datepicker from 'vue3-datepicker'
    import { ko } from 'date-fns/locale'
    import moment from 'moment';


    const props = defineProps({
        modelValue: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    })

    const { modelValue, disabled } = toRefs(props)


    const emit = defineEmits(['update:modelValue'])

    const date = ref(new Date());
    const datepicker = ref(null);

    const locale = reactive(ko)
    const inputFormat = ref('yyyy.MM.dd')

    onMounted(()=>{
        if(modelValue.value) {
            date.value = new Date(modelValue.value.substring(0,4) + '-' + modelValue.value.substring(4,6) + '-' + modelValue.value.substring(6));
        } else {
            emit('update:modelValue', moment(date.value).format('YYYYMMDD'));
        }
    })

    const handleDate = (modelData) => {
        date.value = modelData;
        emit('update:modelValue', moment(date.value).format('YYYYMMDD'));
    }

    const setDisabled = (value) => {
        disabled.value = value;
    }

    defineExpose({
        setDisabled
    })
</script>

<template>
    <div>
        <Datepicker
                v-model="date"
                :locale="locale"
                :weekStartsOn="0"
                :inputFormat="inputFormat"
                :clearable="true"
                @update:model-value="handleDate"
                :ref="datepicker"
                :disabled="disabled"
        />
    </div>
</template>

<style lang="scss">
    @import "../../assets/scss/theme";
    .v3dp__datepicker {
        .v3dp__input_wrapper {
            position: relative;
            input {
                width: 130px;
                border: $border_line;
                border-radius: 4px;
                height: 48px;
                padding: 0 12px !important;
                font-size: $font-size_sm;
            }
            &:after {
                content: "";
                position: absolute;
                top: 16px;
                right: 12px;
                display: block;
                width: 12px;
                height: 14px;
                background-image: url(../../assets/images/icon-main.png);
                background-size: 200px 200px;
                background-position: -116px -53px;
            }
        }
        .v3dp__clearable {
            display: none;
            left: -25px;
            i {
                font-style: normal;
            }
        }
        .v3dp__popout {
            border: $border_line;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin-top: 10px;
            .v3dp__body {
                .v3dp__subheading {
                    span {
                        &:first-child {
                            color: $red_color;
                        }
                        &:last-child {
                            color: #5465FF;
                        }
                    }
                }
                .v3dp__divider {
                    display: none;
                }
                .v3dp__elements {
                    button {
                        padding: 0.3em calc(0.6em - 4px);
                        &:hover {
                            span {
                                background-color: $blue_color;
                            }
                        }
                        &:disabled {
                            text-indent: -99999px;
                            &:hover {
                                span {
                                    background-color: transparent;
                                }
                            }
                        }
                        &.selected {
                            span {
                                background-color: $blue_color;
                            }
                        }
                        &.current {
                            text-decoration: underline;
                        }
                        &:nth-child(7n+1) {
                            color: $red_color;
                        }
                        &:nth-child(7n) {
                            color: #5465FF;
                        }
                        span {
                            border-radius: 0;
                            padding: 0 4px !important;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
</style>