import http from '../../../../api/http';

// 입사지원현황 리스트
function retrieveUserMatchingResumeList(paramObj) {
    return http.post('/mypage/matching/retrieveUserMatchingResumeList', paramObj);
}

// 매칭 희망기업 리스트
function retrieveUserMatchingCorporationList(paramObj) {
    return http.post('/mypage/matching/retrieveUserMatchingCorporationList', paramObj);
}

// 채용이력서 전송
function createResume(
    interests, desiredJob, desiredSalary, // 기본 정보
    introOpenYn, introAline, selfIntro, // 소개
    eduHistOpenYn, eduHistoris, // 학력
    portfolioOpenYn, portfolios, // 포트폴리오
    certForeignOpenYn, certificates, languageTests, foreignLanguages, // 자격증및외국어
    jobHistOpenYn, jobHistorys, // 업무경험
    milOpenYn, milServiceGb // 병역
) {
    return http.post('/mypage/matching/createResume', {
        interests, // 관심카테고리
        desiredJob, // 희망직무
        desiredSalary, // 희망연봉
        introOpenYn: introOpenYn ? 'Y' : 'N', // 공개여부
        introAline, // 한줄소개
        selfIntro, // 자기소개
        eduHistOpenYn: eduHistOpenYn ? 'Y' : 'N',  // 공개여부
        eduHistoris,
        portfolioOpenYn: portfolioOpenYn ? 'Y' : 'N',  // 공개여부
        portfolios,
        certForeignOpenYn: certForeignOpenYn ? 'Y' : 'N',  // 공개여부
        certificates,
        languageTests,
        foreignLanguages,
        jobHistOpenYn: jobHistOpenYn ? 'Y' : 'N',  // 공개여부
        jobHistorys,
        milOpenYn: milOpenYn ? 'Y' : 'N',  // 공개여부
        milServiceGb,
    });
}

// 채용이력서 전송
function createResumeRecord( paramObj ) {
    return http.post('/mypage/matching/createResume', paramObj);
}

// 제3자 개인정보제공 동의유무 조회
function retrieveAgreeInfoProvYn() {
    return http.get('/mypage/matching/retrieveAgreeInfoProvYn');
}

// 제3자 개인정보제공 동의유무 변경
function changeAgreeInfoProvYnState(paramObj) {
    return http.post('/mypage/matching/changeAgreeInfoProvYnState', paramObj);
}

// 수강이력서 리스트 리턴
function retrieveLectureResumeList(paramObj) {
    return http.post('/mypage/matching/retrieveLectureResumeList', paramObj);
}

// 인재노출여부 변경
function changeResOpenYnState(paramObj) {
    return http.post('/mypage/matching/changeResOpenYnState', paramObj);
}

// 받은 채용제안 리스트
function retrieveUserProposalUserList(paramObj) {
    return http.post('/mypage/matching/retrieveUserProposalUserList', paramObj);
}

// 입사지원 이력서 조회
function retrieveMatchingResume(paramObj) {
    return http.get(`/mypage/matching/retrieveMatchingResume?resumeId=${paramObj.resumeId}&hidden=${paramObj.hidden}`);
}

// 이력서의 매칭희망 기업의 채용 상태 변경
function changeResumeCorporationStateForStudent(paramObj) {
    return http.post('mypage/matching/changeResumeCorporationStateForStudent', paramObj)
}


export default {
    retrieveUserMatchingResumeList,
    retrieveUserMatchingCorporationList,
    createResume,
    createResumeRecord,
    retrieveAgreeInfoProvYn,
    changeAgreeInfoProvYnState,
    retrieveLectureResumeList,
    changeResOpenYnState,
    retrieveUserProposalUserList,
    retrieveMatchingResume,
    changeResumeCorporationStateForStudent
};