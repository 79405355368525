import moment from 'moment'
import {useToast} from 'vue-toast-notification';

export default {
    install: (app, options) => {
        const toast = useToast();

        app.config.globalProperties.$toast = (type, message) => {
            toast.open({
                message: message || '',
                type: type || 'default',
                position: 'bottom',
                duration: 3000,
            });
        }
    }
}