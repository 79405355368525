    <script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, onMounted,  computed, defineExpose, getCurrentInstance } from "vue";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import fileService from "../../../../../services/fileService";
    import lectureHomeworkService from "../../../../admin/services/lecture/lectureHomeworkService";
    import KSAInputNumber from "../../../../../components/ui-components/input/KSAInputNumber.vue";

    const loadingStore = usePageLoadingStore()

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const alertModal = ref(null)
    const modalContent = ref([])

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);
    })

    const MAX_SIZE = 200;  // 최대파일사이즈(MB)
    const input = ref({    // 과제 생성 정보
        lectId: 0,     // 강의아이디
        title: "",     // 제목
        fullMarks: 0,  // 배점
        attachList: [  // 첨부파일
            {
                attachSeq: 0,
                fileName: "",
                fileSize: "",
                fileDir: "",
                deleteYn: "N"
            }
        ],
    })

    const createHomework = () => {
        const { lectId, title, fullMarks, attachList} = input.value;
        let isInvalid = false;

        if(!title) {
            modalContent.value = [`제목을 입력하세요.`];
            alertModal.value.show();
            isInvalid = true;
            return false;
        }
        if(!fullMarks || fullMarks <= 0) {
            modalContent.value = [`점수 배점을 입력하세요.`];
            alertModal.value.show();
            isInvalid = true;
            return false;
        }
        if(!attachList) {
            modalContent.value = [`문서파일을 업로드하세요.`];
            alertModal.value.show();
            isInvalid = true;
            return false;
        }

        if (!isInvalid) {
            let paramObj = {
                lectId, title, fullMarks, attachList
            }

            loadingStore.loading = true;
            lectureHomeworkService.createHomework(paramObj)
                .then(async (res) => {
                    loadingStore.loading = false;
                    if (res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        confirm();
                    } else {
                        modalContent.value = ["등록을 실패하였습니다."];
                        alertModal.value.show();
                    }
                }).catch((res) => {
                console.log(res);
                loadingStore.loading = false;
            });
        }
    }

    const uploadFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];
            // console.log(file);
            if(file.size > (MAX_SIZE*1024*1024)) {
                modalContent.value = [`파일은 최대 ${MAX_SIZE}MB까지 첨부할 수 있습니다.`];
                alertModal.value.show();
            } else {
                let paramObj = {
                    container: 'public',
                    folder: 'cs_notice',
                    file,
                }

                fileService.uploadFile(paramObj)
                    .then((res) => {
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            input.value.attachList[0].fileName = result.originalFilename;
                            input.value.attachList[0].fileSize = result.fileSize;
                            input.value.attachList[0].fileDir = result.blobUrl;
                            // console.log(input.value)
                        }
                    })
                    .catch( (error) =>  {
                        console.log(error);
                    });
            }
        });
    }

    const removeFile = () => {
        input.value.attachList[0].fileName = '';
        input.value.attachList[0].fileSize =  '';
        input.value.attachList[0].fileDir =  '';
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        baseModal.value.close();
        resolvePromise.value(true);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const init = (lectId) => {
        // 기본값 저장
        input.value.lectId = lectId;
    }

    defineExpose({
        show,
        init
    })
</script>

<template>
    <Modal ref="baseModal">
        <div class="modal-inner">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>과제 등록</h3>
                <p><span>모든 정보는 필수 입력사항 입니다.</span></p>
            </div>
            <div class="modal-body">
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="150px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>과제 제목</th>
                            <td>
                                <KSAInput v-model="input.title" />
                            </td>
                        </tr>
                        <tr>
                            <th>문서</th>
                            <td class="text-box">
                                <div class="grid file" v-for="attach in input.attachList">
                                    <KSAPrimaryButton text="파일 선택" @click="uploadFile" />
                                    <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                    <button class="delete" @click="removeFile" :disabled="!attach.fileName"><i></i></button>
                                </div>
                                <small>최대 {{ MAX_SIZE }}MB 까지 첨부 가능</small>
                            </td>
                        </tr>
                        <tr>
                            <th>점수 배점</th>
                            <td>
                                <div class="grid half">
                                    <KSAInputNumber v-model="input.fullMarks" maxLength="3" />
                                    <span class="ml-xs">점</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" text="등록" @click="createHomework" />
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .modal-inner {
        width: 598px !important;
    }

    .list-top {
        p {
            margin-right: 0;
            padding-left: 2px;
            &:first-of-type {
                padding-left: 0;
            }
        }
    }

    .grid {
        &.half {
            div { width: 312px; }
        }
    }

    .modal-title p span {
        color: $red_color;
        font-weight: 700;
    }

    .table-form table .grid span { margin: 0 0 0 10px; }

</style>