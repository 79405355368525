<script setup>
    import { ref, onMounted, watch, getCurrentInstance } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import { useLectureStore } from "../../stores/lecture/lectureStore";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import lectureService from "../../services/lecture/lectureService";
    import _ from 'lodash'
    import fileService from "../../../../services/fileService";
    import KSAInputWithBytes from "../../../../components/ui-components/input/KSAInputWithBytes.vue";
    import LectureSurveySearchModal from "../../components/modal/lecture/LectureSurveySearchModal.vue";
    const { $number } = getCurrentInstance().appContext.config.globalProperties;

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const lectureStore = useLectureStore()
    const resumeRequiredRef = ref(null)

    onMounted(()=>{
        if(lectureStore.lectGb) {
            input.value.lectGb = lectureStore.lectGb;
            if(lectureStore.lectGb === '02') {
              input.value.ticketOfferGb = '02';
            }
        }
        setTimeout(() => {
          resumeRequiredRef.value.initOptionsWithValues([]);
        }, 500);
    })

    const input = ref({
        lectId: 0,
        lectGb: "01", // 강의구분 01 온라인 02 오프라인
        lectOpenYn: "N", // 강의공개여부
        lectThombName: "", // 강의썸네일명 이미지 파일명
        lectThombSize: "", // 강의썸네일용량 이미지 파일 용량
        lectThombDir: "", // 강의썸네일위치  이미지 파일 위치
        lectName: "", //강의명
        regTermGb: "02", // 신청기간구분 01 상시 02 기간선택
        regStartYmd: "", // 신청시작일자
        regEndYmd: "", // 신청종료일자
        lectTermGb: "02", // 교육기간구분 01 신청일 기준 02 기간 선택
        lectTerm: 0, // 교육기간  신청일로부터 N일
        lectStartYmd: "", // 교육시작일자
        lectEndYmd: "", // 교육종료일자
        finCriteriaGb: "01", // 수료기준구분  01 없음  02 진도율 기준  03 pass/fail
        finishCriteria: 0, // 수료기준
        allowIpList: "", // 허용 IP
        note: "", // 참고사항 (구: 기타)
        lectDesc: "", // 강의설명
        lectIntro: "", // 과정소개
        repreLectYn: "N", // 대표강의여부
        ticketOfferGb: "01", // 선발 기준 (구: 수강권제공구분) 온라인: 01 선착순  02 대상자 선정 오프라인: 01 선착순 02 서류전형 03 서류+면접전형
        resumeRequired: "", // - db 컬럼 추가. 이력서 필수 항목 01소개,02학력,03포트폴리오,04자격증 및 외국어,05업무 경험,06병역
        intvTermGb: "02", // 면접기간구분 01 상시 02 기간선택 -> 기간선택
        intvStartYmd: "", // 면접시작일자
        intvEndYmd: "", // 면접종료일자
        lectHour: 0, // 교육시간_시
        lectMin: 0, // 교육시간_분
        lectPlace: "", // 교육장소
        lectTrgt: "ALL", // 교육대상자 STUDENT COMPANY
        limitMemOpenYn: "Y", // - db 컬럼 추가  교육인원공개여부
        regMemOpenYn: "Y", // 신청인원공개여부
        selMemOpenYn: "Y", // 선발인원공개여부
        limitMemYn: false, // 제한인원여부 false: 무제한
        limitMemNum: 0, // 교육인원(구: 제한인원)
        survey: {} // 만족도
    })

    const isDisabled = ref({
        regTerm: false,
        lectTerm: false,
    })

    const inputSurvey = ref({
        surveyId: 0,
        title: "",
    })

    const inputError = ref({
        lectThombName: '',
        lectName: '',
        lectTerm: '',
        limitMemNum: '',
        lectIntro: '',
        resumeRequired: '',
        finishCriteria: '',
        allowIpList: '',
    })

    // 이력서 필수 항목
    const resumeRequiredGroup = ref([
      { label:"소개", value: "소개" },
      { label:"학력", value: "학력" },
      { label:"포트폴리오", value: "포트폴리오" },
      { label:"자격증 및 외국어", value: "자격증 및 외국어" },
      { label:"업무 경험", value: "업무 경험" },
      { label:"병역", value: "병역" },
    ]);

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref('');
    const surveySearchModal = ref(null)

    // 구분(온라인 강의, 오프라인 강의) 선택시
/*    watch(() => input.value.lectGb, (newLectGb) => {
      if( newLectGb === '01') { // 온라인
        input.value.ticketOfferGb = '01'; // default 01 선착순
      } else { // 오프라인
        input.value.ticketOfferGb = '02'; // default 02 서류전형
      }
    }, { deep: true });*/

    // 선발기준 radio 버튼 선택시
    watch(() => input.value.ticketOfferGb, (newTicketOfferGb) => {
      if( newTicketOfferGb !== '01') { // 대상자 선정
        // 3.4.7.8. 선발기준 대상자선정 => 신청기간 기간선택/상시 disabled, 교육기간 기간선택/신청일기준 disabled
        // 신청기간
        input.value.regTermGb = '02'; // 기간선택
        isDisabled.value.regTerm = true; // 상시 disabled
        // 교육기간
        input.value.lectTermGb = '02';  // 기간 선택
        isDisabled.value.lectTerm = true; // 신청일 기준 disabled
      } else { // 없음(선착순)
        // 1.2.5.6. 선발기준 선착순 => 신청기간,교육기간 모두 albed
        // 신청기간
        isDisabled.value.regTerm = false; // 상시 abled
        // 교육기간
        isDisabled.value.lectTerm = false; // 신청일 기준 abled
      }
    }, { deep: true });

    const uploadThumbFile = (type) => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];

            let paramObj = {
                container: 'public',
                folder: 'lecture',
                file,
            }
            fileService.uploadFile(paramObj)
                .then((res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        if(type === 'thumb') {
                            input.value.lectThombName = result.originalFilename;
                            input.value.lectThombSize = result.fileSize;
                            input.value.lectThombDir = result.blobUrl;
                        } else {
                            input.value.oneMovName = result.originalFilename;
                            input.value.oneMovSize = result.fileSize;
                            input.value.oneMovDir = result.blobUrl;
                        }
                    }
                })
                .catch( (error) =>  {
                    console.log(error);
                });
        });
    }

    const removeThumbFile = (type) => {
        if(type === 'thumb') {
            input.value.lectThombName = '';
            input.value.lectThombSize = '';
            input.value.lectThombDir = '';
        } else {
            input.value.oneMovName = '';
            input.value.oneMovSize = '';
            input.value.oneMovDir = '';
        }
    }

    const create = () => {
        const request = _.cloneDeep(input.value);
        const {
            lectGb,
            lectThombName,
            lectName,
            lectTermGb,
            lectTerm,
            finCriteriaGb,
            finishCriteria,
            limitMemYn,
            limitMemNum,
            lectStartYmd,
            lectEndYmd,
            lectIntro,
            lectHour,
            lectMin,
            lectPlace,
            ticketOfferGb,
            allowIpList,
            survey
        } = request;
        let isInvalid = false;

        inputError.value = {};
        if(!lectThombName) {
            inputError.value.lectThombName = '이미지를 첨부하세요.';
            isInvalid = true;
        }
        if(!lectName) {
            inputError.value.lectName = '강의명을 입력하세요.';
            isInvalid = true;
        }
        if(lectTermGb === '01' && (lectTerm === null || lectTerm === '' || $number(lectTerm) === '0')) {
            inputError.value.lectTerm = '교육기간을 입력하세요.';
            isInvalid = true;
        } else if(lectTermGb === '02' && (!lectStartYmd || !lectEndYmd) ){
            inputError.value.lectTerm = '교육기간을 입력하세요.';
            isInvalid = true;
        }
        if(!limitMemYn && (limitMemNum === null || limitMemNum === '' || $number(limitMemNum) === '0')) {
          inputError.value.limitMemNum = '교육인원을 입력하세요.';
            isInvalid = true;
        }
        if(!lectIntro) {
            inputError.value.lectIntro = '과정 소개를 입력하세요.';
            isInvalid = true;
        }

        if(lectGb === '01' && finCriteriaGb === '02' && (finishCriteria === null || finishCriteria === '' || $number(finishCriteria) === '0')) {
            inputError.value.finishCriteria = '교육기간 내 진도율을 입력하세요.';
            isInvalid = true;
        }

        if(lectGb === '02') {
            if(!lectPlace) {
                inputError.value.lectPlace = '교육 장소를 입력하세요.';
                isInvalid = true;
            }
        }

        // 이력서 필수 항목
        if(lectGb === '02' && ticketOfferGb !== '01') {
            const resumeRequired = _.map(input.value.resumeRequired).join(',');
            if( !resumeRequired ) {
              inputError.value.resumeRequired = '이력서 필수 항목을 선택하세요.';
              isInvalid = true;
            }
        }

        // IP 입력값이 존재하는 경우, 형식에 맞는지 validation.
        if (allowIpList) {
            let result = false;
            const cidrs = allowIpList.split(',').map(cidr => cidr.trim());
            result = cidrs.every(isValidCidr);
            if (!result) {
              inputError.value.allowIpList = 'IP 형식을 맞춰서 입력해주세요.';
              isInvalid = true;
            }
        }

        if(!isInvalid) {
            request.limitMemYn = request.limitMemYn ? 'N' : 'Y';
            request.limitMemNum = Number(request.limitMemNum);
            request.lectTerm = Number(request.lectTerm);
            request.lectHour = Number(request.lectHour);
            request.lectMin = Number(request.lectMin);
            request.finishCriteria = Number(request.finishCriteria);
            request.resumeRequired = _.map(input.value.resumeRequired).join(',');
            request.survey = inputSurvey.value;
            if( lectGb === '02' ) {
              request.finCriteriaGb = '03'  // 오프라인 강의는 모두 pass/fail
            }

            loadingStore.loading = true;
            lectureService.createLecture(request)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        modalContent.value = ["강의를 등록했습니다."];
                        const ok = await confirmModal.value.show();
                        if(ok) { // 차시 등록
                            lectureStore.lectGb = result.lectGb;
                            lectureStore.lectId = result.lectId;
                            router.push({name: 'LectureCourseCreate'});
                        } else { // 목록으로 이동
                            router.push({name: 'LectureList'});
                        }
                    } else {
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    // CIDR 유효성 검사 함수
    const isValidCidr = cidr => {
      const cidrPattern = /^([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2])$/;
      if (cidrPattern.test(cidr)) {
        const [ip, prefix] = cidr.split('/');
        return ip.split('.').every(part => {
          const num = Number(part);
          return num >= 0 && num <= 255;
        });
      }
      return false;
    };

    const onClickSurveyModal = async () => {
        surveySearchModal.value.init();
        const result = await surveySearchModal.value.show();
        if( result ) {
          inputSurvey.value = result;
        }
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>강의 등록</h3>
                    <p><span>* 표시는 필수 입력사항</span> 입니다.</p>
                </div>
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="200px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th colspan="2">● 강의 정보</th>
                        </tr>
                        <tr>
                            <th class="mark">구분</th>
                            <td>
                                <KSARadioButton class="mr-md" name="lectGb" label="온라인 강의" v-model="input.lectGb" value="01" />
                                <KSARadioButton class="mr-md" name="lectGb" label="오프라인 강의" v-model="input.lectGb" value="02" />
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">강의명</th>
                            <td>
                                <KSAInputWithBytes class="text-box" v-model="input.lectName" :msg="inputError.lectName" max-bytes="50" placeholder="강의명을 입력해주세요."/>
                            </td>
                        </tr>
                        <tr>
                          <th>강의 설명<br><span>(목록 출력)</span></th>
                            <td>
                                <KSAInput class="text-box" v-model="input.lectDesc" max-length="2000" placeholder="강의에 대한 설명을 간략히 입력해주세요."/>
                            </td>
                        </tr>
                        <tr>
                          <th><span class="mark">이미지</span><br><span>(목록+상세 출력)</span></th>
                            <td class="text-box">
                                <div class="grid file">
                                    <KSAPrimaryButton text="파일 선택" @click="uploadThumbFile('thumb')" />
                                    <KSAInput class="input icon" placeholder="파일 선택(398px X 284px 사이즈 이미지를 권장합니다.)" v-model="input.lectThombName" disabled />
                                    <button class="delete" :disabled="!input.lectThombName" @click="removeThumbFile('thumb')"><i></i></button>
                                </div>
                                <small>최대 200MB 까지 첨부 가능</small>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.lectThombName }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '01'">
                            <th class="">미리보기 영상</th>
                            <td class="text-box">
                                <div class="grid file">
                                    <KSAPrimaryButton text="파일 선택" @click="uploadThumbFile('onemov')" />
                                    <KSAInput class="input icon" placeholder="파일 선택" v-model="input.oneMovName" disabled />
                                    <button class="delete" :disabled="!input.oneMovName" @click="removeThumbFile('onemov')"><i></i></button>
                                </div>
                                <small>최대 200MB 까지 첨부 가능</small>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.oneMovName }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">과정 소개</th>
                            <td>
                                <KSACKEditor v-model="input.lectIntro" container="public"/>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.lectIntro }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>수료 기준</th>
                            <td v-if="input.lectGb === '01'">
                                <KSARadioButton class="mr-md" name="finCriteriaGb" label="없음(교육 종료 후 100% 수료)" v-model="input.finCriteriaGb" value="01" />
                                <KSARadioButton class="mr-md" name="finCriteriaGb" label="진도율 기준" v-model="input.finCriteriaGb" value="02" />
                                <KSARadioButton class="mr-md" name="finCriteriaGb" label="수료(Pass)/미수료(Fail) 관리자 평가" v-model="input.finCriteriaGb" value="03" />
                                <div style="display: inline-block;" v-if="input.finCriteriaGb === '02'">
                                    <div class="grid">
                                        교육기간 내 진도율 <KSAInput style="width: 100px" class="text-box ml-sm" v-model="input.finishCriteria" max-length="3" />% 이상
                                    </div>
                                </div>
                                <div style="display: block;">
                                  <small style="color:#E71414;">{{ inputError.finishCriteria }}</small>
                                </div>
                            </td>
                            <td v-if="input.lectGb === '02'">
                                *수료(Pass)/미수료(Fail) 관리자 평가에 따라 결정됩니다.
                            </td>
                        </tr>
                        <tr>
                            <th colspan="2">● 모집 요강</th>
                        </tr>
                        <tr>
                            <th class="mark">교육 대상자</th> <!--온라인에 추가-->
                            <td>
                                <KSARadioButton class="mr-md" name="lectTrgt" label="전체" v-model="input.lectTrgt" value="ALL" />
                                <KSARadioButton class="mr-md" name="lectTrgt" label="재직자/기업" v-model="input.lectTrgt" value="COMPANY" />
                                <KSARadioButton class="mr-md" name="lectTrgt" label="학생/취업준비생" v-model="input.lectTrgt" value="STUDENT" />
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">교육 인원</th> <!--구: 제한 인원-->
                            <td class="align-center">
                                <KSAInput style="width: 160px;" class="text-box" v-model="input.limitMemNum" max-length="3" :disabled="input.limitMemYn" placeholder="숫자"/>명
                                <KSACheckBox v-if="input.lectGb === '01'" class="ml-xs" label="무제한" v-model="input.limitMemYn" />
                                <div style="display: block;">
                                  <small style="color:#E71414;">{{ inputError.limitMemNum }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02'">
                            <th class="mark">교육 장소</th>
                            <td>
                                <KSAInput class="text-box" v-model="input.lectPlace" :msg="inputError.lectPlace" max-length="50" placeholder="교육 장소(지명)명 또는 주소 정보를 입력해주세요."/>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">선발 기준</th> <!--구: 수강권 제공-->
                            <td v-if="input.lectGb === '01'">
                                <KSARadioButton class="mr-md" name="ticketOfferGb" label="없음(선착순)" v-model="input.ticketOfferGb" value="01" />
                                <KSARadioButton class="mr-md" name="ticketOfferGb" label="대상자 선정(수강 신청 인원 중)" v-model="input.ticketOfferGb" value="02" />
                            </td>
                            <td v-if="input.lectGb === '02'"> <!--오프라인에 추가-->
                                <KSARadioButton class="mr-md" name="ticketOfferGb" label="서류전형" v-model="input.ticketOfferGb" value="02" />
                                <KSARadioButton class="mr-md" name="ticketOfferGb" label="서류+면접전형" v-model="input.ticketOfferGb" value="03" />
                                <KSARadioButton class="mr-md" name="ticketOfferGb" label="없음(선착순)" v-model="input.ticketOfferGb" value="01" />
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02' && input.ticketOfferGb !== '01'">
                            <th class="mark">이력서 필수 항목</th>  <!--오프라인에 추가-->
                            <td>
                                <div style="display: inline-block;">
                                    <KSACheckBoxGroup
                                          ref="resumeRequiredRef"
                                          className="mr-md"
                                          name="resumeRequiredList"
                                          v-model="input.resumeRequired"
                                          :options="resumeRequiredGroup"
                                      />
                                </div>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.resumeRequired }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">신청 기간</th>
                            <td v-if="input.lectGb === '01'" class="text-box">
                                <KSARadioButton class="mr-md" name="regTermGb" label="기간선택" v-model="input.regTermGb" value="02" />
                                <div style="display: inline-block;" v-if="input.regTermGb === '02'">
                                    <KSADatepickerTerms
                                        :startDate="input.regStartYmd"
                                        :endDate="input.regEndYmd"
                                        @update:startDate="(value)=> input.regStartYmd = value"
                                        @update:endDate="(value)=> input.regEndYmd = value"
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <KSARadioButton :disabled="isDisabled.regTerm" class="mr-md" name="regTermGb" label="상시" v-model="input.regTermGb" value="01" />
                            </td>
                            <td v-if="input.lectGb === '02'" class="text-box">
                                <div style="display: inline-block;">
                                    <KSADatepickerTerms
                                        :startDate="input.regStartYmd"
                                        :endDate="input.regEndYmd"
                                        @update:startDate="(value)=> input.regStartYmd = value"
                                        @update:endDate="(value)=> input.regEndYmd = value"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02' && input.ticketOfferGb === '03'">
                            <th class="mark">면접 기간</th>
                            <td>
                                <div style="display: inline-block;">
                                    <KSADatepickerTerms
                                        :startDate="input.intvStartYmd"
                                        :endDate="input.intvEndYmd"
                                        @update:startDate="(value)=> input.intvStartYmd = value"
                                        @update:endDate="(value)=> input.intvEndYmd = value"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">교육 기간</th>
                            <td v-if="input.lectGb === '01'">
                                <KSARadioButton class="mr-md" name="lectTermGb" label="기간선택" v-model="input.lectTermGb" value="02" />
                                <div style="display: inline-block;"  v-if="input.lectTermGb === '02'">
                                    <KSADatepickerTerms
                                        :startDate="input.lectStartYmd"
                                        :endDate="input.lectEndYmd"
                                        @update:startDate="(value)=> input.lectStartYmd = value"
                                        @update:endDate="(value)=> input.lectEndYmd = value"
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <KSARadioButton  :disabled="isDisabled.lectTerm" class="mr-md" name="lectTermGb" label="신청일기준" v-model="input.lectTermGb" value="01" />
                                <div style="display: inline-block;" v-if="input.lectTermGb === '01'">
                                    <div class="grid">
                                        신청일로부터 <KSAInput style="width: 160px;" class="text-box ml-sm" v-model="input.lectTerm" max-length="4" />일
                                    </div>
                                </div>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.lectTerm }}</small>
                                </div>
                            </td>
                            <td v-if="input.lectGb === '02'">
                                <div style="display: inline-block;">
                                    <KSADatepickerTerms
                                        :startDate="input.lectStartYmd"
                                        :endDate="input.lectEndYmd"
                                        @update:startDate="(value)=> input.lectStartYmd = value"
                                        @update:endDate="(value)=> input.lectEndYmd = value"
                                    />
                                </div>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.lectTerm }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="2">● 기타</th>
                        </tr>
                        <tr>
                            <th>참고 사항<br><span>(상세 출력)</span></th> <!--구: 기타-->
                            <td>
                                <KSAInput class="text-box" v-model="input.note" max-length="2000"/>
                            </td>
                        </tr>
                        <tr>
                            <th>만족도 평가</th>
                            <td class="text-box">
                                <div class="grid">
                                    <KSAPrimaryButton text="검색" @click="onClickSurveyModal" />
                                    <KSAInput style="max-width: 1000px; width: 100%; margin-top: 0px;" class="text-box ml-xs" v-model="inputSurvey.title" max-length="2000" disabled />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="2">● 공개/비공개 설정</th>
                        </tr>
                        <tr>
                            <th class="mark">교육 인원</th> <!--온라인/오프라인에 추가-->
                            <td>
                                <KSARadioButton class="mr-md" name="limitMemOpenYn" label="공개" v-model="input.limitMemOpenYn" value="Y" />
                                <KSARadioButton class="mr-md" name="limitMemOpenYn" label="비공개" v-model="input.limitMemOpenYn" value="N" />
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '01'">
                            <th class="mark">수강중 인원</th>
                            <td>
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="공개" v-model="input.selMemOpenYn" value="Y" />
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="비공개" v-model="input.selMemOpenYn" value="N" />
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02'" class="mark">
                            <th class="mark">신청 인원</th>
                            <td>
                                <KSARadioButton class="mr-md" name="regMemOpenYn" label="공개" v-model="input.regMemOpenYn" value="Y" />
                                <KSARadioButton class="mr-md" name="regMemOpenYn" label="비공개" v-model="input.regMemOpenYn" value="N" />
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02'">
                            <th class="mark">선발 인원</th>
                            <td>
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="공개" v-model="input.selMemOpenYn" value="Y" />
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="비공개" v-model="input.selMemOpenYn" value="N" />
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">강의 공개 여부</th> <!--구: 상태-->
                            <td>
                                <KSARadioButton class="mr-md" name="lectOpenYn" label="공개" v-model="input.lectOpenYn" value="Y" :disabled="true" />
                                <KSARadioButton class="mr-md" name="lectOpenYn" label="비공개" v-model="input.lectOpenYn" value="N" :disabled="true" />
                                <p class="mt-xs">*차시 등록 후 공개가 가능합니다.</p>
                            </td>
                        </tr>
                        <tr>
                            <th>허용IP</th>
                            <td>
                                <KSAInput class="text-box" v-model="input.allowIpList" :msg="inputError.allowIpList" max-length="2000"/>
                                설정한 IP주소에서만 보안PDF를 볼 수 있게 설정합니다.(예 255.255.0.0/16)<br>
                                입력폼내 콤마(,)로 구분하여 여러 개의 IP주소를 설정할 수 있습니다.
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="취소" @click="router.push({name: 'LectureList'})" />
                    <KSAPrimaryButton text="등록" @click="create" />
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent"/>
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="차시 등록" cancelButtonText="목록으로 이동" />
    <LectureSurveySearchModal ref="surveySearchModal"/>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>