<script setup>
    import { ref, defineProps, defineEmits, defineExpose } from 'vue'
    import _ from 'lodash'

    const { options, disabled } = defineProps(['options', 'disabled'])
    const selectedOption = ref(options[0]);
    const emit = defineEmits(['change'])

    const changeTab = (option) => {
        if(!disabled) {
            selectedOption.value = option;
            emit('change', option);
        }
    }

    const init = (value) => {
        const option = _.find(options, { value });
        if(option) {
            selectedOption.value = option;
        }
    }

    defineExpose({
        init
    })
</script>

<template>
    <ul>
        <li :class="{'active': selectedOption.value === option.value}" v-for="option in options" @click="changeTab(option)">
            {{ option.label }}
        </li>
    </ul>
</template>


<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background: $gray-thin_color;
        li {
            color: $gray_color;
            text-align: center;
            @include font-size_md;
            padding: 20px 0;
            span {
                display: inline-block;
                padding: 10px;
                vertical-align: middle;
            }
            &:hover, &.active {
                cursor: pointer;
                background: $blue_color;
                color: #fff;
            }
        }
        &.tab-style_border {
            display: flex;
            background: transparent;
            margin-bottom: 40px;
            border-bottom: $border_line;
            li {
                width: 100px;
                border-bottom: 0;
                color: $gray_color;
                padding: 0 0 8px;
                &:hover, &.active {
                    cursor: pointer;
                    background: transparent;
                    color: $black_color;
                    font-weight: 700;
                    border-bottom: 2px solid $blue_color;
                }
            }
        }
        &.tab-style_small {

            display: flex;
            background: transparent;
            margin-bottom: 20px;
            li {
                @include font-size_sm;
                border-radius: 4px;
                color: $gray_color;
                background: $gray-thin_color;
                padding: 1px 10px;
                margin-left: 10px;
                &:first-child {
                    margin-left: 0;
                }
                &:hover, &.active {
                    cursor: pointer;
                    background: transparent;
                    color: #fff;
                    background: $black_color;
                }
            }
        }
    }
</style>