<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, onMounted, computed, defineExpose, getCurrentInstance } from "vue";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import surveyService from "../../../services/lecture/surveyService";

    const loadingStore = usePageLoadingStore()
    const { $toast, $pageListBoardNo, $gbNameKR } = getCurrentInstance().appContext.config.globalProperties;

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);
        retrieveSurveyQuestionList();
    })

    let disabledWatchIsSelectedAll = false; // 개별 checkbox 클릭시 watch disabled
    let disabledWatchList = false;          // all checkbox 클릭시 watch disabled

    const isSelectedAll = ref(false);
    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(list.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            list.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    const list = ref([])
    watch(list, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    const checkedSurveyQuestionList = computed(()=>{
        return _.filter(list.value, { checked: true});
    })

    const searchInput = ref({
        surveyQGb: '01',
        surveyQType: '04',
        surveyQTitle: '',
        surveyQGbList: [],
        surveyQTypeList: [],
    })

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(0);
    const totalCount = ref(0);
    const orderDirection = ref('ASC');
    const ascable = ref(true);

    const surveyQGbGroup = ref([
        { label:"교육 전후 역량 변화", value: "01" },
        { label:"교육 만족도", value: "02" },
        { label:"현업 활용도", value: "03" },
        { label:"수요 조사", value: "04" },
        { label:"교육과정 안내 경로", value: "05" },
    ])

    const pageSizeGroup = ref([
        { label:"10개씩 보기", value: "10" },
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
    ])

    const retrieveSurveyQuestionList = () => {
        searchInput.value.surveyQGbList = [];
        searchInput.value.surveyQTypeList = [];
        searchInput.value.surveyQGbList.push(searchInput.value.surveyQGb);
        searchInput.value.surveyQTypeList.push(searchInput.value.surveyQType);

        let paramObj = {
            useYn: "Y",
            surveyQGbList: searchInput.value.surveyQGbList,
            surveyQTypeList: searchInput.value.surveyQTypeList,
            surveyQTitle: searchInput.value.surveyQTitle,
            pageNo: currentPage.value,
            size: pageSize.value,
            orderProperty: "surveyQTitle",
            orderDirection: orderDirection.value,
        }

        loadingStore.loading = true;
        surveyService.retrieveAllSurveQuestion(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                    _.each(list.value, surveyQuestion => {
                        surveyQuestion.checked = false;
                    })
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        searchInput.value = {
            surveyQGb: '01',
            surveyQType: '04',
            surveyQTitle: '',
            surveyQGbList: [],
            surveyQTypeList: [],
        }
    }

    const onSelectSurveyQGb = (option) => {
        searchInput.value.surveyQGb = option.value;
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveSurveyQuestionList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveSurveyQuestionList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveSurveyQuestionList();
    }

    const onClickAscable = () => {
        if( orderDirection.value === 'ASC' ) {
            orderDirection.value = 'DESC';
            ascable.value = false;
        } else {
            orderDirection.value = 'ASC';
            ascable.value = true;
        }
        retrieveSurveyQuestionList();
    }

    const show = () => {
        baseModal.value.open();
        retrieveSurveyQuestionList();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        baseModal.value.close();
        resolvePromise.value(checkedSurveyQuestionList.value);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const init = () => {
        clearSearchInput();
        isSelectedAll.value = false;
    }

    defineExpose({
        show,
        init,
    })

</script>
<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>문항 검색</h3>
            </div>
            <div class="modal-body">
                <div class="filter-wrap open">
                    <p>검색</p>
                    <div class="filter">
                        <dl>
                            <dt>문항 구분</dt>
                            <dd><KSASelect :options="surveyQGbGroup" class="select" @selectOption="onSelectSurveyQGb"/></dd>
                        </dl>
                        <dl>
                            <dt>문항 유형</dt>
                            <dd>
                                <KSARadioButton class="mr-md" label="5점 척도"  v-model="searchInput.surveyQType" value="04" />
                                <KSARadioButton class="mr-md" label="주관식"    v-model="searchInput.surveyQType" value="02" />
                                <KSARadioButton class="mr-md" label="다중응답형" v-model="searchInput.surveyQType" value="03" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>문항</dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <KSAInput style="max-width: calc(100% - 80px); width: 100%;" class="text-box" v-model="searchInput.surveyQTitle" />
                                    <KSAPrimaryButton class="ml-xs" text="검색" @click="onClickSearch" />
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div class="list-top">
                    <p>총 <span>{{ totalCount }}</span>개</p>
                    <div>
                        <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th><KSACheckBox v-model="isSelectedAll"/></th>
                            <th>문항 구분</th>
                            <th>문항 유형</th>
                            <th class="text-align" @click="onClickAscable">
                                문항 <i :class="{'asc': ascable, 'desc': !ascable}"></i>
                            </th>
                        </tr>
                        <tr v-for="surveyQuestion in list" >
                            <td><KSACheckBox v-model="surveyQuestion.checked"/></td>
                            <td>{{ $gbNameKR('SURVEY_Q_GB', surveyQuestion.surveyQGb) }}</td>
                            <td>{{ $gbNameKR('SURVEY_Q_TYPE', surveyQuestion.surveyQType) }}</td>
                            <td class="text-left">{{ surveyQuestion.surveyQTitle }}</td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" text="선택" @click="confirm" />
            </div>
        </div>
    </Modal>
</template>
<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.table-form {
    table {
        th.text-align {
            position: relative;
            i {
                position: absolute;
                top: 50%;
                right: 24px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 6px 10px 6px;
                border-color: transparent transparent $black_color transparent;
                &.asc {
                    transform: rotate(0deg) translateY(-50%);
                }
                &.desc {
                    transform: rotate(180deg) translateY(50%);
                }

            }
        }
    }
}
</style>
