<script setup>
    import { ref } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { useUserStore } from "../../stores/user";
    import { storeToRefs } from "pinia";

    const route = useRoute();
    const router = useRouter();
    const { isAdmin } = storeToRefs(useUserStore())

    const id = ref(route.query.id || '')

    const login = () => {
        router.push({name: isAdmin.value ? 'SigninAdmin' : 'Signin'});
    }
</script>

<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <div class="title">
                <h3>아이디 찾기</h3>
            </div>
            <div class="id-complete-box">
                <p>{{ id }}</p>
            </div>
            <KSAPrimaryButton class="full-btn" text="로그인" @click="login" />
            <ul class="member">
                <li><RouterLink :to="{name: 'SigninSearchPW'}">비밀번호 찾기</RouterLink></li>
                <li v-if="!isAdmin"><RouterLink :to="{name: 'Signup'}">회원가입</RouterLink></li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .sign-wrap {
        .id-complete-box {
            position: relative;
            border: $border_line;
            background: $gray-thin_color;
            width: 100%;
            height: 190px;
            text-align: center;
            margin-bottom: 40px;
            p {
                position: absolute;
                top: 50%;
                width: 100%;
                @include font-size_xl;
                font-weight: 700;
                transform: translateY(-50%);
            }
        }
    }
</style>