<script setup>
    import { ref, onMounted, computed, getCurrentInstance, defineProps } from 'vue'
    import { useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import mypageUserResumeService from "../../../services/mypage/mypageUserResumeService";
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import UserMatchingResumeCreateModal from "../../../components/modal/mypage/UserMatchingResumeCreateModal.vue";
    import MyPageResumeModal from "../../../components/modal/mypage/MyPageResumeModal.vue";
    import MatchingResumeModal from "../../../components/modal/matching/MatchingResumeModal.vue";
    import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";
    import mypageMatchingService from "../../../services/mypage/mypageMatchingService";

    const globals = getCurrentInstance().appContext.config.globalProperties;

    const { $pageListPorpId } = globals;
    const data_interests = ref(globals.$interests)

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const matchingUpdateStore = useMatchingUpdateStore()

    const interestGbListRef = ref(null)
    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);
    const matchingResumeModal = ref(null);

    const snbKey = ref(0);

    const searchOpen = ref(true)
    const _searchInput = ref({
        searchMonths: '1',
        searchInterests: '',
        searchResOpenYn: 'A',
    });

    // 검색조건 - 이력서 전송일 (0 전체 기간, 1 최근 1개월, 2 최근 2개월, 3 최근 3개월)
    const searchMonths = ref([
        { label:"최근 1개월", value: "1" },
        { label:"최근 2개월", value: "2" },
        { label:"최근 3개월", value: "3" },
        { label:"전체 기간",  value: "0" },
    ]);

    // 검색조건 - 인재 노출 (Y 희망함, N 희망하지 않음)
    const searchResOpenYn = ref([
        { label:"전체", value: "A" },
        { label:"희망함", value: "Y" },
        { label:"희망하지 않음", value: "N" },
    ]);

    // 모달
    const confirmModal = ref(null);
    const userMatchingResumeCreateModal = ref(null);
    const userMatchingResumeModal = ref(null);
    const modalContent = ref([]);
    const okButtonText = ref("동의");
    const cancelButtonText = ref("동의하지 않음");
    const modal = ref(null);
    const alertModalContent = ref([]);

    let letUserGb = ''
    let corpMasterYn = ''

    const shouldRender = ref(true);
    const getUserInfo = async () => {
      loadingStore.loading = true;
      const userInStorage = sessionStorage.getItem('user');
      if(userInStorage) {
        const { userGb, userInfo } = JSON.parse(userInStorage)
        letUserGb = userGb
        corpMasterYn = userInfo.corpMasterYn;
      }
      loadingStore.loading = false;
    }

    const isModal = localStorage.getItem('isModalVisible')

    onMounted( async () => {
      retrieveList();

      await getUserInfo()
      if(letUserGb === 'STUDENT') {
        if(isModal === 'true') {
          matchingResumeModal.value.show();
        }
      } else {
        shouldRender.value = false;
        alertModalContent.value = ["학생/취업준비생 회원만 지원 가능합니다."];
        await modal.value.show();
        router.go(-2);
      }
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1;
    });

    const retrieveList = () => {

        const interests = _.map(_searchInput.value.searchInterests).join(',');

        let paramObj = {
            pageNumber: currentPage.value,
            size: pageSize,
            searchInterests: interests,
            searchMonths: _searchInput.value.searchMonths,
            searchResOpenYn: _searchInput.value.searchResOpenYn
        }
        // console.log(paramObj);
        loadingStore.loading = true;
        mypageUserResumeService.retrieveUserMatchingResumeList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListPorpId(result.content, totalCount.value, pageSize, currentPage.value);
                    //console.log(list.value)
                    // 매칭 상태값 업데이트
                    await retrieveMatchingUpdate();
                }
            }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
    }

    // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
    const retrieveMatchingUpdate = async () => {
      await mypageMatchingService.retrieveMatchingUpdate()
          .then( async (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              matchingUpdateStore.matchingUpdate = result;
              await matchingUpdateStore.setMatchingUpdate( result );
              matchingUpdateStore.value = await useMatchingUpdateStore();
              snbKey.value += 1;
            }
          }).catch( (error) => {
            loadingStore.loading = false;
            console.log(error);
          });
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    // 이력서 보기
    const onClickUserMatchingResumeDetail = (resumeId) => {
        userMatchingResumeModal.value.init(false);
        userMatchingResumeModal.value.retrieveUserMatchingResumeDetail(resumeId, 0);
        userMatchingResumeModal.value.show();
    }

    // 이력서 작성 버튼
    const onClickCreateUserMatchingResume = async () => {

        mypageUserResumeService.retrieveAgreeInfoProvYn()
            .then( (res) => {
                if ( res.data.result.agreeInfoProvYn === "Y" ) {
                    showUserMatchingResumeCreateModal();
                } else {
                    confirmAgreeInfoProvYnChange();
                }
            }).catch( (res) =>  {
            console.log(res);
            $toast('error', '오류가 발생하였습니다.');
        });

    }

    // 제3자 정보 제공 동의처리 의사 확인
    const confirmAgreeInfoProvYnChange = async () => {
        modalContent.value = ["개인정보 제공에 동의하나요?", "동의하지 않을 시 이력서 작성 후 전송을 할 수 없습니다."];
        okButtonText.value = "동의";
        cancelButtonText.value = "동의하지 않음";
        const ok = await confirmModal.value.show();
        if ( ok ) { // 동의
            changeAgreeInfoProvYn();
        } else {    // 동의하지 않음

        }
    }

    // 제3자 정보 제공 동의여부 변경
    const changeAgreeInfoProvYn = () => {

        loadingStore.loading = true;

        let paramObj = {
            agreeInfoProvYn: "Y",
        }

        mypageUserResumeService.changeAgreeInfoProvYnState(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    //let result = res.data.result;
                    //console.log(result);
                    showUserMatchingResumeCreateModal();
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });

    }

    // 이력서 작성 모달 실행
    const showUserMatchingResumeCreateModal = async () => {
        userMatchingResumeCreateModal.value.init();
        await userMatchingResumeCreateModal.value.show();
    }

    // 초기화 버튼
    const clearSearchInput = () => {
      interestGbListRef.value.initOptions()
      _searchInput.value = {
        searchMonths: '1',
        searchInterests: '설계,장비,소재,제조공정,패키지,테스트,SW,기타',
        searchResOpenYn: 'A',
      };
    }

    // 노출여부 변경
    const onClickChangeResOpenYn = async (resumeId, resOpenYn) => {

        if ( resOpenYn === "Y" ) {
            modalContent.value = ["인재 노출할까요?", "노출 시 기업 인사 담당자에게 이력서가 노출됩니다."];
            okButtonText.value = "노출";
            cancelButtonText.value = "취소";
        } else {
            modalContent.value = ["인재 노출을 중지할까요?", "중지 시 기업 인사 담당자에게 이력서가 노출되지 않습니다."];
            okButtonText.value = "중지";
            cancelButtonText.value = "취소";
        }

        const ok = await confirmModal.value.show();

        if ( ok ) {

            let paramObj = {
                resumeId: resumeId,
                resOpenYn: resOpenYn
            }

            mypageUserResumeService.changeResOpenYnState(paramObj)
                .then((res) => {
                    loadingStore.loading = false;
                    if (res.data.code.toLowerCase() === 'success') {
                        //let result = res.data.result;
                        //console.log(result);
                        retrieveList();
                    }
                }).catch((error) => {
                loadingStore.loading = false;
                console.log(error);
            });

        }

    }

</script>

<template>
    <div v-if="shouldRender" class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB :key="snbKey" class="snb" />
                <div class="content">
                    <div class="sub-title">
                        <h3>입사 지원 현황</h3>
                    </div>
                    <div class="process-wrap">
                       <h4>입사 지원 절차</h4>
                      <div class="process-layout">
                        <div class="process-steps-scroll">
                          <div class="process-steps">
                            <div class="step"><div>이력서<br>작성/전송</div></div>
                            <div class="step-arrow"><div></div></div>
                            <div class="step"><div>인재 정보<br>등록/노출</div></div>
                            <div class="step-arrow"><div></div></div>
                            <div class="step"><div>채용 제안<br>확인/수락</div></div>
                            <div class="step-arrow"><div></div></div>
                            <div class="step"><div>매칭 완료</div></div>
                            <div class="step-arrow active"><div></div></div>
                            <div class="step large active"><div>기업별 채용<br>전형 진행</div></div>
                          </div>
                        </div>
                        <div class="button-with-info">
                          <p><em>실시간 인재정보 등록</em>하고<br><strong>상시 채용제안</strong>을 받아보세요.</p>
                          <div class="button-with-info-cta" @click="onClickCreateUserMatchingResume">입사 지원하기<span class="icon-button-right"></span></div>
                        </div>
                      </div>
                    </div>
                    <div class="filter-wrap" :class="{'open': searchOpen}">
                        <p @click="searchOpen = !searchOpen">검색</p>
                        <div class="filter">
                            <dl>
                                <dt>전송일</dt>
                                <dd>
                                    <div class="grid">
                                        <template v-for="group in searchMonths">
                                            <KSARadioButton class="mr-md" name="searchMonthGb" :label="group.label" v-model="_searchInput.searchMonths" :value="group.value" />
                                        </template>
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>관심 카테고리</dt>
                                <dd>
                                  <div class="grid">
                                    <KSACheckBoxGroup
                                        ref="interestGbListRef"
                                        className="mr-md"
                                        name="searchInterests"
                                        v-model="_searchInput.searchInterests"
                                        :options="data_interests" />
                                  </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>인재 노출</dt>
                                <dd>
                                    <div class="grid">
                                        <template v-for="group in searchResOpenYn">
                                            <KSARadioButton class="mr-md" name="searchResOpenYnGb" :label="group.label" v-model="_searchInput.searchResOpenYn" :value="group.value" />
                                        </template>
                                    </div>
                                </dd>
                            </dl>
                            <div class="btn-wrap">
                                <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                <KSAPrimaryButton text="검색" @click="retrieveList" />
                            </div>
                        </div>
                    </div>
                    <div class="list-top">
                        <p>제안 <span>{{ $number(totalCount) }}</span>건</p>
                    </div>
                    <div class="table-form board-mobile-vertical">
                        <table>
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <tr>
                                <th>매칭 희망 기업</th>
                                <th>이력서</th>
                                <th>관심 카테고리</th>
                                <th>전송일</th>
                                <th>인재 노출 희망</th>
                            </tr>
                            <tr v-for="(proposal) in list">
                                <td data-title="매칭 희망 기업 : ">
                                    <span v-if="proposal.stateCnt > 0" class="red-dot"></span>
                                    <span @click="router.push({name:'UserMatchingResumeProposalDetail', params:{id:proposal.resumeId}})" class="clickable proposal" style="cursor:pointer">{{ proposal.corpCnt }} 개 기업이 매칭 희망합니다</span>
                                </td>
                                <td data-title="이력서 : "><KSATextButton class="clickable resume" text="이력서 보기" @click="onClickUserMatchingResumeDetail(proposal.resumeId)" /></td>
                                <td data-title="관심 카테고리 : ">{{ proposal.interests }}</td>
                                <td data-title="전송일 : ">{{ $yyyymmddHHmmss(proposal.createDate)}}</td>
                                <td data-title="인재 노출 희망 : ">
                                    <img v-if="proposal.resOpenYn === 'Y'" src="../../../../../assets/images/toggle_on.png" @click="onClickChangeResOpenYn(proposal.resumeId, 'N')" style="cursor:pointer;" />
                                    <img v-else src="../../../../../assets/images/toggle_off.png" @click="onClickChangeResOpenYn(proposal.resumeId, 'Y')" style="cursor:pointer;" />
                                </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="list.length === 0">
                            전송한 이력서가 없습니다.
                        </div>
                    </div>
                    <Paginate
                        v-if="list.length > 0"
                        v-model="currentPage"
                        :page-count="totalPages"
                        :click-handler="onSelectedPage"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-else class="white-background"></div>
    <AlertModal ref="modal" :content="alertModalContent" />
    <MatchingResumeModal v-if="isModal" ref="matchingResumeModal" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" :cancelButtonText="cancelButtonText" />
    <UserMatchingResumeCreateModal ref="userMatchingResumeCreateModal"/>
    <MyPageResumeModal ref="userMatchingResumeModal"/>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    /* Filter */
    .filter-wrap {
        margin-bottom: 40px;
        &.open {
            p {
                &:before {
                    transition-duration: 0.2s;
                    -webkit-transform: translateY(-50%) rotate(225deg);
                    transform: translateY(-50%) rotate(225deg);
                }
            }
            .filter {
                display: block;
                border-bottom: $border_line;
            }
        }
        p {
            position: relative;
            @include font-size_md;
            background: $gray-light_color;
            border: $border_line;
            font-weight: 700;
            cursor: pointer;
            padding: 10px 20px;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 20px;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 1px solid #000000;
                border-left: 1px solid #000000;
                -webkit-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg);
                transition-duration: 0.2s;
            }
        }
        .filter {
            display: none;
            border-left: $border_line;
            border-right: $border_line;
            dl {
                display: flex;
                align-items: center;
                dt {
                    display: flex;
                    align-items: center;
                    width: 180px;
                    height: 48px;
                    font-weight: 700;
                    padding: 10px 10px 10px 20px;
                    .select {
                        font-weight: initial;
                    }
                }
                dd {
                    width: calc(100% - 180px);
                    padding: 10px 20px 10px 0;
                    .grid {
                        span {
                            margin: 0 4px;
                        }
                    }
                }
                &:first-of-type {
                    padding-top: 10px;
                }
                &:last-of-type {
                    padding-bottom: 10px;
                }
            }
            .btn-wrap {
                border-top: $border_line;
                padding: 10px 20px;
                margin: 0;
                button {
                    margin: 0 5px;
                }
            }
            .text-box {
                margin-top: 0;
            }
        }
    }

    .clickable {
      cursor: pointer;
      color: #3843AF;
      &.proposal {
        &:hover {
          font-weight: 700;
          // text-decoration: underline;
        }
      }
      &.resume {
        color: #3C72FF;
        &:hover {
          font-weight: 700;
          text-decoration: underline;
        }
      }


    }

    .white-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }

    .blueTextButton { color: $blue_color; }
</style>