<script setup>
    import { ref, defineEmits, toRefs, defineExpose } from "vue";
    const props = defineProps({
        modelValue: { type: String },
        type: { type: String },
        label: { type: String },
        placeholder: { type: String },
        msg: { type: String },
        msgColor: { type: String },
        maxBytes: { type: String, default: "50" },
        disabled: { type: Boolean, default: false },
    });
    const { type, label, placeholder, msg, msgColor, maxBytes, disabled } = toRefs(props)
    const emit = defineEmits(["update:modelValue", "blur"]);

    const inputRef = ref(null)

    const focus = () => {
        inputRef.value.focus();
    }

    const onInput = (event) => {
        const newStr = fnCut(event.target.value, 10);
        emit('update:modelValue', newStr)
        inputRef.value.value = newStr;
    }

    defineExpose({
        focus
    })

    function fnCut(str) // str은 inputbox에 입력된 문자열이고,lengths는 제한할 문자수 이다.
    {
        var len = 0;
        var newStr = '';

        for (var i=0;i<str.length; i++)
        {
            var n = str.charCodeAt(i); // charCodeAt : String개체에서 지정한 인덱스에 있는 문자의 unicode값을 나타내는 수를 리턴한다.
            // 값의 범위는 0과 65535사이이여 첫 128 unicode값은 ascii문자set과 일치한다.지정한 인덱스에 문자가 없다면 NaN을 리턴한다.

            var nv = str.charAt(i); // charAt : string 개체로부터 지정한 위치에 있는 문자를 꺼낸다.


            if ((n>= 0)&&(n<256)) len ++; // ASCII 문자코드 set.
            else len += 2; // 한글이면 2byte로 계산한다.

            if (len>Number(maxBytes.value)) break; // 제한 문자수를 넘길경우.
            else newStr = newStr + nv;
        }
        return newStr;
    }
</script>

<template>
    <div>
        <label>
            <p v-if="label">{{ label }}</p>
            <input
                ref="inputRef"
                :type="type ? type : 'text'"
                :value="modelValue"
                @input="onInput"
                @blur="emit('blur')"
                :placeholder="placeholder"
                :disabled="disabled"
            />
        </label>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/ksa_input";

    label {
        p {
            @include font-size_xs;
            color: $gray_color;
        }
    }
    input {
        &:disabled {
            color: $black_color;
            background: $gray-thin_color;
        }
    }
</style>