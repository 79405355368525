<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const userInStorage = sessionStorage.getItem('user')
  localStorage.setItem('isModalVisible', 'false');
  let letUserGb = ''
  let corpMasterYn = ''
  if(userInStorage) {
    const { userGb, userInfo } = JSON.parse(userInStorage)
    // console.log(userGb)
    letUserGb = userGb
    corpMasterYn = userInfo.corpMasterYn;
  }

  const modal = ref(null);
  const modalContent = ref([]);


  // 지원하기
  const goMatchingResume = async () => {
    if(letUserGb === 'STUDENT') {
      //router.push({name:'UserMatchingResumeProposalList', state: {isModalVisible: true}});
      localStorage.setItem('isModalVisible', 'true');
      router.push({name:'UserMatchingResumeProposalList'});
    } else if(letUserGb === '') {
      modalContent.value = ["로그인 후 이용해주세요."];
      await modal.value.show()
      localStorage.setItem('isModalVisible', 'true');
      router.push({name:'UserMatchingResumeProposalList'});
      // router.push({name: 'Signin'});
    }else {
      modalContent.value = ["학생/취업준비생 회원만 지원 가능합니다."];
      modal.value.show();
      return -1;
    }
  }

  // 채용 제안하기
  const goProposal = async () => {
    if (letUserGb === 'COMPANY' && corpMasterYn === 'Y') {
      //router.push({name: 'MypageProposalListView', state: {isModalVisible: true}});
      localStorage.setItem('isModalVisible', 'true');
      router.push({name: 'MypageProposalListView'});
    } else if (letUserGb === '') {
      modalContent.value = ["로그인 후 이용해주세요."];
      await modal.value.show()
      localStorage.setItem('isModalVisible', 'true');
      router.push({name: 'MypageProposalListView'});
      //router.push({name: 'Signin'});
    }else {
      modalContent.value = ["재직자/기업 마스터 회원만 제안 가능합니다."];
      modal.value.show();
      return -1;
    }
  }
</script>

<template>
  <div class="container">
    <div class="inner">
      <div class="title page-title">
        <h3>채용 지원</h3>
      </div>
      <!--
      <ul>
        <li>
         <img src="/src/assets/images/matching_job.jpg" alt="KSA채용지원시스템" />
        </li>
       <li>
          <img src="/src/assets/images/job_student.png" alt="입사지원하기" />
          <img src="/src/assets/images/job_company.png" alt="채용제안하기" />
        </li>
      </ul>
      -->

      <div class="info-container" >
        <h4>KSA 채용지원시스템</h4>
        <div class="info-container-detail">
          <ul>
            <li>- 취업준비생/기업 간 입사 지원과 채용 제안이 <em>실시간으로 이루어지는 양방향 시스템</em>입니다.</li>
            <li>- 학생/취업준비생 회원은 반도체 아카데미의 채용지원시스템을 통해 관심 분야 기업군 대상 <strong><em>입사 지원</em></strong>이 가능합니다.</li>
            <li>- 재직자/기업(인사담당자) 회원은 채용지원시스템을 통해 채용 직무를 관심 카테고리로 등록한 미취업자 대상 <strong><em>채용 제안</em></strong>이 가능합니다.</li>
          </ul>
          <p>* 상대가 제안을 수락하는 경우에 <strong>구직/구인 매칭이 이루어집니다.</strong></p>
        </div>
      </div>

      <div style="text-align: center;">
        <div class="btn-box">
          <img src="/src/assets/images/job_student.jpg" alt="입사지원하기" @click="goMatchingResume()"/>
        </div>
        <div class="btn-box">
          <img src="/src/assets/images/job_company.jpg" alt="채용제안하기" @click="goProposal()"/>
        </div>
<!--        <div class="btn-box">
          구직중 인가요?<br>
          이력서를 작성하고 지원해보세요.<br>
          <br>
          <KSATextButton text="지원하기 >" @click="goMatchingResume()" />
        </div>
        <div class="btn-box">
          인재를 찾고 있나요?<br>
          채용 제안서를 작성하고 전송해보세요.<br>
          <br>
          <KSATextButton text="채용 제안하기 >" @click="goProposal()" />
        </div>-->
      </div>



    </div>
    <div class="image-full-width-container">
      <img src="/src/assets/images/ksa-hire-process.png" alt="실시간 양방향 채용지원시스템" class="image-full-width"/></div>


  </div>
  <AlertModal ref="modal" :content="modalContent" />

</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/theme";

.container .inner img { width: 100%; }

.btn-box {
  width: 600px;
  display: inline-block;
  background: #fff;
  padding: 35px 20px;
  margin: 20px;
  text-align: center;
  font-size: 15px;
  cursor:pointer;
  img{cursor:pointer;}
}

/*.btn-box {
  width: 300px;
  display: inline-block;
  background: #fff;
  border: 1px solid $blue_color;
  border-radius: 10px;
  padding: 25px 10px;
  margin: 10px;
  text-align: center;
  font-size: 15px;
  line-height: 30px;
}*/

hr {
  background-color: $gray-regular_color;
  height: 1px;
  border: 0;
  line-height: inherit;
  margin: 20px 0 0;
}
.image-full-width-container{
  background-color:#e8f5fd;
  text-align: center;
  padding:0;
  margin:0;
  .image-full-width{
    height:500px;
    position:relative;
    bottom:-5px;
  }
}

/* 모바일 진행시 적용할 것
@media (max-width: 639px) {
  .btn-box {
    width:auto;
    padding:0;
  }
}
 */
</style>