<script setup>
    import { ref, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import signinService from "../../services/signin/signinService";

    const route = useRoute()
    const router = useRouter()

    const modal = ref(null)
    const modalContent = ref([])

    onMounted(()=>{
        const { email } = route.params;
        // console.log(email);
        if(!email) {
            goHome();
        }
    })

    const unsubscribe = () => {
        const { email } = route.params;
        signinService.requestUnsubscribe(email)
            .then( async (res) => {
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    modalContent.value = ["이메일 수신 거부 되었습니다."];
                    const ok = await modal.value.show();
                    if(ok) {
                        goHome();
                    }
                } else {
                    modalContent.value = ["수신 거부를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
                console.log(res);
                modalContent.value = ["수신 거부를 실패하였습니다."];
                modal.value.show();
            });
    }

    const goHome = () => {
        router.push({name: 'Home'});
    }
</script>

<template>
    <div class="head" @click="goHome">
        <img src="http://academy.ksia.or.kr/platformfile/public/email/logo.svg" />
    </div>
    <div class="body">
        <p style="">이메일을 수신거부 하시겠습니까?</p>
        <div>
            <a href="javascript:void(0);" @click="goHome">취소</a>
            <a class="subscribe" href="javascript:void(0);" @click="unsubscribe">수신거부</a>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    .head {
        background-color: #3843AF;
        text-align: left;
        padding: 20px;
        cursor:pointer;
    }
    .body {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 40px);
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
        padding: 20px;
        p {
            font-size: 24px; line-height: 32px; font-weight: 700; text-align: center;
        }
        div {
            text-align: center;
            margin-top: 20px;
            a {
                display: inline-block;
                max-width: 280px;
                width: 100%;
                height: 50px;
                text-decoration: none;
                border-radius: 4px;
                background-color: #ffffff;
                color: #3843AF;
                border: 1px solid #3843AF;
                line-height: 50px;
                text-align: center;
                margin: 0 4px;
                &.subscribe {
                    background-color: #3843AF;
                    color: #ffffff;
                }
            }
        }
    }
</style>