import RouterView from '../components/RouterView.vue'

import MemberListView from '../pages/admin/views/member/MemberListView.vue'
import MemberDetailView from '../pages/admin/views/member/MemberDetailView.vue'
import UserGbHistoryListView from "../pages/admin/views/member/UserGbHistoryListView.vue";

import LectureListView from '../pages/admin/views/lecture/LectureListView.vue'
import LectureCreateView from '../pages/admin/views/lecture/LectureCreateView.vue'
import LectureDetailView from '../pages/admin/views/lecture/LectureDetailView.vue'
import LectureUpdateView from '../pages/admin/views/lecture/LectureUpdateView.vue'
import LectureCourseCreateView from '../pages/admin/views/lecture/LectureCourseCreateView.vue'
import LectureMainView from '../pages/admin/views/lecture/LectureMainView.vue'

import LectureSurveyQuestionListView from '../pages/admin/views/lecture/survey/LectureSurveyQuestionListView.vue'
import LectureSurveyQuestionCreateView from '../pages/admin/views/lecture/survey/LectureSurveyQuestionCreateView.vue'
import LectureSurveyQuestionDetailView from '../pages/admin/views/lecture/survey/LectureSurveyQuestionDetailView.vue'
import LectureSurveyPaperListView from '../pages/admin/views/lecture/survey/LectureSurveyPaperListView.vue'
import LectureSurveyPaperCreateView from '../pages/admin/views/lecture/survey/LectureSurveyPaperCreateView.vue'
import LectureSurveyPaperDetailView from '../pages/admin/views/lecture/survey/LectureSurveyPaperDetailView.vue'

import LectureCourseListView from '../pages/admin/views/course/LectureCourseListView.vue'
import LectureCourseDetailView from '../pages/admin/views/course/LectureCourseDetailView.vue'

import ManagerListView from '../pages/admin/views/manager/ManagerListView.vue'
import ManagerCreateView from '../pages/admin/views/manager/ManagerCreateView.vue'
import ManagerDetailView from '../pages/admin/views/manager/ManagerDetailView.vue'
import ManagerUpdateView from '../pages/admin/views/manager/ManagerUpdateView.vue'

import UserMatchingListView from "../pages/admin/views/matching/UserMatchingListView.vue";
import MatchingListView from "../pages/admin/views/matching/MatchingListView.vue";
import MatchingCorpDetailView from "../pages/admin/views/matching/MatchingCorpDetailView.vue";

import SiteMainBannerView from '../pages/admin/views/site/SiteMainBannerView.vue'
import SiteCorpBannerView from '../pages/admin/views/site/SiteCorpBannerView.vue'

import MailSMSHistoryView from '../pages/admin/views/mail/MailSMSHistoryView.vue'
import MailSMSHistoryMailCreateView from '../pages/admin/views/mail/MailSMSHistoryMailCreateView.vue'
import MailSMSHistorySMSCreateView from '../pages/admin/views/mail/MailSMSHistorySMSCreateView.vue'
import MailSMSTemplateView from '../pages/admin/views/mail/MailSMSTemplateView.vue'
import MailSMSTemplateUpdateView from '../pages/admin/views/mail/MailSMSTemplateUpdateView.vue'

import CSNotifyListView from '../pages/admin/views/cs/notice/CSNotifyListView.vue'
import CSNotifyCreateView from '../pages/admin/views/cs/notice/CSNotifyCreateView.vue'
import CSNotifyUpdateView from '../pages/admin/views/cs/notice/CSNotifyUpdateView.vue'
import CSNotifyDetailView from '../pages/admin/views/cs/notice/CSNotifyDetailView.vue'
import CSFAQListView from '../pages/admin/views/cs/faq/CSFAQListView.vue'
import CSFAQCreateView from '../pages/admin/views/cs/faq/CSFAQCreateView.vue'
import CSFAQUpdateView from '../pages/admin/views/cs/faq/CSFAQUpdateView.vue'
import CSFAQDetailView from '../pages/admin/views/cs/faq/CSFAQDetailView.vue'
import CSQNAListView from '../pages/admin/views/cs/qna/CSQNAListView.vue'
import CSQNADetailView from '../pages/admin/views/cs/qna/CSQNADetailView.vue'
import CSNewsletterListView from '../pages/admin/views/cs/newsletter/CSNewsletterListView.vue'
import CSNewsletterCreateView from '../pages/admin/views/cs/newsletter/CSNewsletterCreateView.vue'
import CSNewsletterUpdateView from '../pages/admin/views/cs/newsletter/CSNewsletterUpdateView.vue'
import CSNewsletterDetailView from '../pages/admin/views/cs/newsletter/CSNewsletterDetailView.vue'

import SigninAdminView from '../pages/admin/views/signin/SigninView.vue'

export default [
    {
        path: '/admin',
        redirect: '/admin/signin',
        children: [
            {
                path: 'signin',
                name: 'SigninAdmin',
                component: SigninAdminView,
                meta: {
                    title: '관리자 로그인',
                }
            },
            {
                path: 'member',
                component: RouterView,
                meta: {
                    title: '회원 관리',
                    requiresAuth: true,
                    admin: true,
                },
                children: [
                    {
                        path: '',
                        name: 'MemberList',
                        component: MemberListView,
                        meta: {
                            title: '회원 목록',
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'MemberDetail',
                        component: MemberDetailView,
                        meta: {
                            title: '회원 상세',
                        }
                    },
                    {
                        path: 'userGbHistory',
                        name: 'UserGbHistoryListView',
                        component: UserGbHistoryListView,
                        meta: {
                            title: '회원 유형 변경 현황',
                        }
                    },
                ]
            },
            {
                path: 'lecture',
                component: RouterView,
                meta: {
                    title: '강의 관리',
                    requiresAuth: true,
                    admin: true,
                },
                redirect: '/admin/lecture/manage',
                children: [
                    {
                        path: 'manage',
                        component: RouterView,
                        meta: {
                            title: '강의 목록',
                        },
                        children: [
                            {
                                path: '',
                                name: 'LectureList',
                                component: LectureListView,
                                meta: {
                                    title: '강의 목록',
                                }
                            },
                            {
                                path: 'create',
                                name: 'LectureCreate',
                                component: LectureCreateView,
                                meta: {
                                    title: '강의 등록',
                                }
                            },
                            {
                                path: 'course',
                                name: 'LectureCourseCreate',
                                component: LectureCourseCreateView,
                                meta: {
                                    title: '차시 등록',
                                }
                            },
                            {
                                path: 'detail/:id',
                                name: 'LectureDetail',
                                component: LectureDetailView,
                                meta: {
                                    title: '강의 상세',
                                }
                            },
                            {
                                path: 'update/:id',
                                name: 'LectureUpdate',
                                component: LectureUpdateView,
                                meta: {
                                    title: '강의 수정',
                                }
                            },
                        ]
                    },
                    {
                        path: 'main',
                        name: 'LectureMain',
                        component: LectureMainView,
                        meta: {
                            title: '대표 강의 설정',
                        }
                    },
                    {
                        path: 'survey',
                        component: RouterView,
                        meta: {
                            title: '만족도 평가',
                        },
                        redirect: '/admin/lecture/survey/surveyQuestion',
                        children: [
                            {
                                path: 'surveyQuestion',
                                component: RouterView,
                                meta: {
                                    title: '문항 관리',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'LectureSurveyQuestionList',
                                        component: LectureSurveyQuestionListView,
                                        meta: {
                                            title: '문항 관리',
                                        }
                                    },
                                    {
                                        path: 'create/:id',
                                        name: 'LectureSurveyQuestionCreate',
                                        component: LectureSurveyQuestionCreateView,
                                        meta: {
                                            title: '문항 등록하기',
                                        }
                                    },
                                    {
                                        path: 'detail/:id',
                                        name: 'LectureSurveyQuestionDetail',
                                        component: LectureSurveyQuestionDetailView,
                                        meta: {
                                            title: '문항 상세',
                                        }
                                    },
                                ],
                            },
                            {
                                path: 'surveyPaper',
                                component: RouterView,
                                meta: {
                                    title: '평가 관리',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'LectureSurveyPaperList',
                                        component: LectureSurveyPaperListView,
                                        meta: {
                                            title: '평가 관리',
                                        }
                                    },
                                    {
                                        path: 'create/:id',
                                        name: 'LectureSurveyPaperCreate',
                                        component: LectureSurveyPaperCreateView,
                                        meta: {
                                            title: '평가 등록하기',
                                        }
                                    },
                                    {
                                        path: 'detail/:id',
                                        name: 'LectureSurveyPaperDetail',
                                        component: LectureSurveyPaperDetailView,
                                        meta: {
                                            title: '평가 관리 상세 ',
                                        }
                                    }
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                path: 'course',
                component: RouterView,
                meta: {
                    title: '수강 관리',
                    requiresAuth: true,
                    admin: true,
                },
                children: [
                    {
                        path: '',
                        name: 'LectureCourseList',
                        component: LectureCourseListView,
                        meta: {
                            title: '수강 관리',
                        }
                    },
                    {
                        path: ':id',
                        name: 'LectureCourseDetail',
                        component: LectureCourseDetailView,
                        meta: {
                            title: '수강 관리',
                        }
                    },
                ]
            },
            {
                path: 'manager',
                component: RouterView,
                meta: {
                    title: '관리자 관리',
                    requiresAuth: true,
                    admin: true,
                },
                children: [
                    {
                        path: '',
                        name: 'ManagerList',
                        component: ManagerListView,
                        meta: {
                            title: '관리자 목록',
                        }
                    },
                    {
                        path: 'create',
                        name: 'ManagerCreate',
                        component: ManagerCreateView,
                        meta: {
                            title: '관리자 생성',
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'ManagerDetail',
                        component: ManagerDetailView,
                        meta: {
                            title: '관리자 상세',
                        }
                    },
                    {
                        path: 'update/:id',
                        name: 'ManagerUpdate',
                        component: ManagerUpdateView,
                        meta: {
                            title: '관리자 수정',
                        }
                    },
                ]
            },
            {
                path: 'matching',
                component: RouterView,
                meta: {
                    title: '채용 관리',
                    requiresAuth: true,
                    admin: true,
                },
                children: [
                    {
                        path: 'userMatching',
                        name: 'UserMatchingList',
                        component: UserMatchingListView,
                        meta: {
                            title: '회원별 채용 현황',
                        }
                    },
                    {
                        path: 'corpMatching',
                        name: 'MatchingList',
                        component: MatchingListView,
                        meta: {
                            title: '기업별 채용 현황',
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'MatchingCorpDetail',
                        component: MatchingCorpDetailView,
                        meta: {
                            title: '기업별 상세',
                        }
                    },
                ]
            },
            {
                path: 'site',
                component: RouterView,
                meta: {
                    title: '사이트 관리',
                    requiresAuth: true,
                    admin: true,
                },
                redirect: '/admin/site/main_banner',
                children: [
                    {
                        path: 'main_banner',
                        name: 'SiteMainBanner',
                        component: SiteMainBannerView,
                        meta: {
                            title: '메인 배너 관리',
                        }
                    },
                    {
                        path: 'corp_banner',
                        name: 'SiteCorpBanner',
                        component: SiteCorpBannerView,
                        meta: {
                            title: '기업 홍보 배너 관리',
                        }
                    },
                ]
            },
            {
                path: 'mail',
                component: RouterView,
                meta: {
                    title: '메일/메시지 관리',
                    requiresAuth: true,
                    admin: true,
                },
                redirect: '/admin/mail/history',
                children: [
                    {
                        path: 'history',
                        component: RouterView,
                        meta: {
                            title: '전송 내역',
                        },
                        children: [
                            {
                                path: '',
                                name: 'MailSMSHistory',
                                component: MailSMSHistoryView,
                                meta: {
                                    title: '전송 내역',
                                }
                            },
                            {
                                path: 'mail_create',
                                name: 'MailSMSHistoryMailCreate',
                                component: MailSMSHistoryMailCreateView,
                                meta: {
                                    title: '메일 쓰기',
                                }
                            },
                            {
                                path: 'sms_create',
                                name: 'MailSMSHistorySMSCreate',
                                component: MailSMSHistorySMSCreateView,
                                meta: {
                                    title: '메시지 쓰기',
                                }
                            },
                        ]
                    },
                    {
                        path: 'template',
                        component: RouterView,
                        meta: {
                            title: '템플릿 관리',
                        },
                        children: [
                            {
                                path: '',
                                name: 'MailSMSTemplate',
                                component: MailSMSTemplateView,
                                meta: {
                                    title: '템플릿 관리',
                                }
                            },
                            {
                                path: ':id',
                                name: 'MailSMSTemplateUpdate',
                                component: MailSMSTemplateUpdateView,
                                meta: {
                                    title: '템플릿 수정',
                                }
                            },
                        ]
                    },
                ]
            },
            {
                path: 'cs',
                component: RouterView,
                meta: {
                    title: '고객센터',
                    requiresAuth: true,
                    admin: true,
                },
                redirect: '/admin/cs/notice',
                children: [
                    {
                        path: 'notice',
                        component: RouterView,
                        meta: {
                            title: '공지사항',
                        },
                        children: [
                            {
                                path: '',
                                name: 'CSNotifyList',
                                component: CSNotifyListView,
                                meta: {
                                    title: '공지사항',
                                }
                            },
                            {
                                path: 'create',
                                name: 'CSNotifyCreate',
                                component: CSNotifyCreateView,
                                meta: {
                                    title: '공지사항 작성',
                                }
                            },
                            {
                                path: ':id',
                                name: 'CSNotifyDetail',
                                component: CSNotifyDetailView,
                                meta: {
                                    title: '공지사항 상세',
                                }
                            },
                            {
                                path: 'update/:id',
                                name: 'CSNotifyUpdate',
                                component: CSNotifyUpdateView,
                                meta: {
                                    title: '공지사항 수정',
                                }
                            },
                        ]
                    },
                    {
                        path: 'faq',
                        component: RouterView,
                        meta: {
                            title: 'FAQ',
                        },
                        children: [
                            {
                                path: '',
                                name: 'CSFAQList',
                                component: CSFAQListView,
                                meta: {
                                    title: 'FAQ',
                                }
                            },
                            {
                                path: 'create',
                                name: 'CSFAQCreate',
                                component: CSFAQCreateView,
                                meta: {
                                    title: 'FAQ 작성',
                                }
                            },
                            {
                                path: ':id',
                                name: 'CSFAQDetail',
                                component: CSFAQDetailView,
                                meta: {
                                    title: 'FAQ 상세',
                                }
                            },
                            {
                                path: 'update/:id',
                                name: 'CSFAQUpdate',
                                component: CSFAQUpdateView,
                                meta: {
                                    title: 'FAQ 수정',
                                }
                            },
                        ]
                    },
                    {
                        path: 'qna',
                        component: RouterView,
                        meta: {
                            title: 'Q&A',
                        },
                        children: [
                            {
                                path: '',
                                name: 'CSQNAList',
                                component: CSQNAListView,
                                meta: {
                                    title: 'Q&A',
                                }
                            },
                            {
                                path: ':id',
                                name: 'CSQNADetail',
                                component: CSQNADetailView,
                                meta: {
                                    title: 'Q&A 상세',
                                }
                            },
                        ]
                    },
                    {
                        path: 'newsletter',
                        component: RouterView,
                        meta: {
                            title: '뉴스레터',
                        },
                        children: [
                            {
                                path: '',
                                name: 'CSNewsletterList',
                                component: CSNewsletterListView,
                                meta: {
                                    title: '뉴스레터',
                                }
                            },
                            {
                                path: 'create',
                                name: 'CSNewsletterCreate',
                                component: CSNewsletterCreateView,
                                meta: {
                                    title: '뉴스레터 작성',
                                }
                            },
                            {
                                path: ':id',
                                name: 'CSNewsletterDetail',
                                component: CSNewsletterDetailView,
                                meta: {
                                    title: '뉴스레터 상세',
                                }
                            },
                            {
                                path: 'update/:id',
                                name: 'CSNewsletterUpdate',
                                component: CSNewsletterUpdateView,
                                meta: {
                                    title: '뉴스레터 수정',
                                }
                            },
                        ]
                    },
                ]
            },
        ]
    },
]