<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import insightsFAQService from "../../../../user/services/insights/insightsFAQService";
    import insightsNotifyService from "../../../../user/services/insights/insightsNotifyService";

    const route = useRoute();
    const router = useRouter();
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name: 'CSNotifyList'})
        } else {
            retrieveDetail(id)
        }
    })

    const faqDetail = ref({});

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])

    const retrieveDetail = (id) => {
        loadingStore.loading = true;
        insightsFAQService.retrieveDetail(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    faqDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickDelete = async () => {
        modalContent.value = ["FAQ를 삭제할까요?", "삭제 시 사용자 사이트에 즉시 적용되며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show()
        if(ok){
            deleteFAQ()
        }
    }

    function deleteFAQ() {
        loadingStore.loading = true;
        insightsFAQService.deleteFAQ(faqDetail.value.boardId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    goBack();
                } else {
                    modalContent.value = ["삭제를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            console.log(res);
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
        });
    }

    const onClickUpdate = () => {
        router.push({name:'CSFAQUpdate', params:{id: faqDetail.value.boardId}})
    }

    const goBack = () => {
        router.push({name:'CSFAQList'})
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>FAQ</h3>
                </div>
                <div class="btn-wrap space-between mb-xs">
                    <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                    <div>
                        <KSASecondaryButton text="수정" @click="onClickUpdate" />
                        <KSASecondaryButton class="red_button" text="삭제" @click="onClickDelete" />
                    </div>
                </div>
                <div class="board-detail">
                    <div class="header">
                        <div class="board-title">
                            <p>
                                [{{ $gbNameKR('BOARD_GROUP_FAQ', faqDetail.boardGroup)}}]
                                {{ faqDetail.title }}
                            </p>
                        </div>
                        <span>작성일 <i>{{ $yyyymmddOrHHmm(faqDetail.createDate) }}</i></span>
                        <span>작성자
                            <i>
                                {{ faqDetail.createUserName }}<span v-if="faqDetail.createUserLoginId">({{ faqDetail.createUserLoginId }})</span>
                            </i>
                        </span>
<!--                        <span>조회수 <i>{{ $number(faqDetail.hitCount)}}</i></span>-->
                    </div>
                    <div class="body">
                        <div class="text" v-html="faqDetail.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="삭제" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .textarea-box {
        height: 300px;
    }
    small {
        @include font-size_xs;
        color: $gray_color;
    }

    img {
        width: 14px;
        margin-left: 4px;
    }

</style>