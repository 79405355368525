import http from '../api/http'

function upload(paramObj) {
    let formData = new FormData();
    formData.append('file', paramObj.file)

    return http.post(`resource/file/kollus/upload?title=${paramObj.title}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
}

function getUploadUrl(paramObj) {
    return http.post('resource/file/kollus/getUploadUrl',{
        title: paramObj.title || '',
        expireTime: 600,
        categoryKey: paramObj.categoryKey || '',
        isEncryptionUpload: 0,
        is_audio_upload: 0
    });
}


function getVideoPlayUrl(paramObj) {
    const queryString = `?uploadFileKey=${paramObj.uploadFileKey}&lectId=${paramObj.lectId}&lecIdx=${paramObj.lectIdx}&regDt=${paramObj.regDt}`;
    return http.get(`resource/file/kollus/getPlayUrlByJWT${queryString}`);
}

function getVideoPlayUrlWithoutRegDt(paramObj) {
    const queryString = `?uploadFileKey=${paramObj.uploadFileKey}&lectId=${paramObj.lectId}&lecIdx=${paramObj.lectIdx}`;
    return http.get(`resource/file/kollus/getPlayUrlByJWTForPreview${queryString}`);
}

export default {
    upload,
    getUploadUrl,
    getVideoPlayUrl, // 비디오 url 구하기(사용자)
    getVideoPlayUrlWithoutRegDt, // 비디오 url 구하기(관리자, 강사)
};