import RouterView from '../components/RouterView.vue'
import HomeView from '../pages/user/views/HomeView.vue'

import SigninView from '../pages/user/views/signin/SigninView.vue'
import SigninCorpInfoView from '../pages/user/views/signin/SigninCorpInfoView.vue'
import SigninSearchIDView from '../pages/user/views/signin/SigninSearchIDView.vue'
import SigninSearchIDCompleteView from '../pages/user/views/signin/SigninSearchIDCompleteView.vue'
import SigninSearchPWView from '../pages/user/views/signin/SigninSearchPWView.vue'
import SigninSearchPWResetView from '../pages/user/views/signin/SigninSearchPWResetView.vue'

import SignupCertifyView from '../pages/user/views/signup/SignupCertifyView.vue'
import SignupMemberTypeView from '../pages/user/views/signup/SignupMemberTypeView.vue'
import SignupTermsView from '../pages/user/views/signup/SignupTermsView.vue'
import SignupMemberInfoView from '../pages/user/views/signup/SignupMemberInfoView.vue'
import SignupCompleteView from '../pages/user/views/signup/SignupCompleteView.vue'

import AboutCompanyView from '../pages/user/views/about/AboutCompanyView.vue'
import AboutDirectorView from '../pages/user/views/about/AboutDirectorView.vue'
import AboutWithusView from '../pages/user/views/about/AboutWithusView.vue'
import AboutHistoryView from '../pages/user/views/about/AboutHistoryView.vue'

import LectureCurriculumView from '../pages/user/views/lecture/LectureCurriculumView.vue'
import LectureOnlineListView from '../pages/user/views/lecture/online/LectureOnlineListView.vue'
import LectureOnlineDetailView from '../pages/user/views/lecture/online/LectureOnlineDetailView.vue'
import LectureOfflineListView from '../pages/user/views/lecture/offline/LectureOfflineListView.vue'
import LectureOfflineDetailView from '../pages/user/views/lecture/offline/LectureOfflineDetailView.vue'
import CreditAcceptableLectureView from '../pages/user/views/lecture/CreditAcceptableLectureView.vue'

import MatchingJobView from '../pages/user/views/matching/MatchingJobView.vue'

import StatisticsView from '../pages/user/views/statistics/StatisticsView.vue'

import InsightsNotifyView from  '../pages/user/views/insights/InsightsNotifyView.vue'
import InsightsNotifyDetailView from  '../pages/user/views/insights/InsightsNotifyDetailView.vue'
import InsightsFAQView from  '../pages/user/views/insights/InsightsFAQView.vue'
import InsightsQNAView from '../pages/user/views/insights/InsightsQnAView.vue'
import InsightsQNADetailView from '../pages/user/views/insights/InsightsQnADetailView.vue'
import InsightsQNACreateView from '../pages/user/views/insights/InsightsQnACreateView.vue'
import InsightsNewsletterView from '../pages/user/views/insights/InsightsNewsletterView.vue'
import InsightsNewsletterDetailView from '../pages/user/views/insights/InsightsNewsletterDetailView.vue'

import MyPageRecordView from '../pages/user/views/mypage/MyPageRecordView.vue'
import MyPageResumeView from '../pages/user/views/mypage/MyPageResumeView.vue'

import MyPageLectureView from '../pages/user/views/mypage/lecture/MyPageLectureView.vue'
import MyPageLectureDetailView from '../pages/user/views/mypage/lecture/MyPageLectureDetailView.vue'
import MyPageLectureCertificateView from '../pages/user/views/mypage/lecture/MyPageLectureCertificatesView.vue'
import MyPageLecturePDFViewerView from '../pages/user/views/mypage/lecture/MyPageLecturePDFViewerView.vue'

import MyPageMyClassView from '../pages/user/views/mypage/myClass/MyPageMyClassView.vue'
import MyPageMyClassDetailView from '../pages/user/views/mypage/myClass/MyPageMyClassDetailView.vue'

import MypageProposalListView from '../pages/user/views/mypage/proposal/MypageProposalListView.vue'
import MypageProposalUserListView from '../pages/user/views/mypage/proposal/MypageProposalUserListView.vue'
import MypageProposalCreateView from '../pages/user/views/mypage/proposal/MypageProposalCreateView.vue'

import MypageResumeLikeView from '../pages/user/views/mypage/proposal/MypageResumeLikeView.vue'
import MypageSearchResumeView from '../pages/user/views/mypage/proposal/MypageSearchResumeView.vue'
import MypageResumeCorporationView from '../pages/user/views/mypage/proposal/MypageResumeCorporationView.vue'

import MyPageProfileView from '../pages/user/views/mypage/MyPageProfileView.vue'
import MyPageProfilePWResetView from '../pages/user/views/mypage/MyPageProfilePWResetView.vue'
import MyPageProfileWithdrawView from '../pages/user/views/mypage/MyPageProfileWithdrawView.vue'

import MyPageUserGbHistoryView from "../pages/user/views/mypage/userGb/MyPageUserGbHistoryView.vue";
import MypageUserGbHistoryCreateView from "../pages/user/views/mypage/userGb/MypageUserGbHistoryCreateView.vue";

import EmailUnsubscribe from '../pages/user/views/email/EmailUnsubscribe.vue'
import UserMatchingResumeProposalListView from "../pages/user/views/mypage/userResume/UserMatchingResumeProposalListView.vue";
import UserMatchingResumeView from "../pages/user/views/mypage/userResume/UserMatchingResumeView.vue";

import UserProposalUserView from "../pages/user/views/mypage/userResume/UserProposalUserView.vue";
import UserMatchingResumeProposalDetailView from "../pages/user/views/mypage/userResume/UserMatchingResumeProposalDetailView.vue";


export default [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: {
            title: '한국반도체아카데미',
        }
    },
    {
        path: '/signin',
        component: RouterView,
        meta: {
            title: '로그인',
        },
        children: [
            {
                path: '',
                name: 'Signin',
                component: SigninView,
                meta: {
                    title: '로그인',
                }
            },
            {
                path: 'corp',
                name: 'SigninCorpInfo',
                component: SigninCorpInfoView,
                meta: {
                    title: '기업 정보 입력',
                    requiresAuth: true,
                }
            },
            {
                path: 'search_id',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'SigninSearchID',
                        component: SigninSearchIDView,
                        meta: {
                            title: '아이디 찾기',
                        }
                    },
                    {
                        path: 'complete',
                        name: 'SigninSearchIDComplete',
                        component: SigninSearchIDCompleteView,
                        meta: {
                            title: '아이디 찾기 완료',
                        }
                    },
                ]
            },
            {
                path: 'search_pw',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'SigninSearchPW',
                        component: SigninSearchPWView,
                        meta: {
                            title: '비밀번호 찾기',
                        }
                    },
                    {
                        path: 'reset_pw',
                        name: 'SigninSearchPWReset',
                        component: SigninSearchPWResetView,
                        meta: {
                            title: '비밀번호 재설정',
                        }
                    },
                ]
            },
        ]
    },
    {
        path: '/signup',
        component: RouterView,
        name: 'Signup',
        meta: {
            title: '회원가입',
        },
        redirect: '/signup/certify',
        children: [
            {
                path: 'certify',
                name: 'SignupCertify',
                component: SignupCertifyView,
            },
            {
                path: 'membertype',
                name: 'SignupMemberType',
                component: SignupMemberTypeView,
            },
            {
                path: 'terms',
                name: 'SignupTerms',
                component: SignupTermsView,
            },
            {
                path: 'memberinfo',
                name: 'SignupMemberInfo',
                component: SignupMemberInfoView,
            },
            {
                path: 'complete',
                name: 'SignupComplete',
                component: SignupCompleteView,
            },

        ]
    },
    {
        path: '/about',
        component: RouterView,
        redirect: '/about/company',
        meta: {
            title: 'About KSA',
        },
        children: [
            {
                path: 'company',
                name: 'AboutCompany',
                component: AboutCompanyView,
                meta: {
                    title: 'KSA란?',
                }
            },
            {
                path: 'director',
                name: 'AboutDirector',
                component: AboutDirectorView,
                meta: {
                    title: '원장님 소개',
                }
            },
            {
                path: 'withus',
                name: 'AboutWithus',
                component: AboutWithusView,
                meta: {
                    title: '함께하는 분들',
                }
            },
            {
                path: 'history',
                name: 'AboutHistory',
                component: AboutHistoryView,
                meta: {
                    title: 'History',
                }
            },
        ]
    },
    {
        path: '/lecture',
        component: RouterView,
        redirect: '/lecture/curriculum',
        meta: {
            title: 'Lecture',
        },
        children: [
            {
                path: 'curriculum',
                name: 'LectureCurriculum',
                component: LectureCurriculumView,
                meta: {
                    title: '교육 프로그램',
                }
            },
            {
                path: 'online',
                component: RouterView,
                meta: {
                    title: '온라인 강의',
                },
                children: [
                    {
                        path: '',
                        name: 'LectureOnlineList',
                        component: LectureOnlineListView,
                        meta: {
                            title: '온라인 강의',
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'LectureOnlineDetail',
                        component: LectureOnlineDetailView,
                        meta: {
                            title: '온라인 강의 상세',
                            apiType: 'id' // 일반 lectId 사용
                        }
                    },
                    {
                        path: 'sdetail/:uuid',
                        name: 'SLectureOnlineDetail',
                        component: LectureOnlineDetailView,
                        meta: {
                            title: '비공개 온라인 강의 상세',
                            apiType: 'uuid' // lectUuid 사용
                        }
                    },
                 ]
            },
            {
                path: 'offline',
                component: RouterView,
                meta: {
                    title: '오프라인 강의',
                },
                children: [
                    {
                        path: '',
                        name: 'LectureOfflineList',
                        component: LectureOfflineListView,
                        meta: {
                            title: '오프라인 강의',
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'LectureOfflineDetail',
                        component: LectureOfflineDetailView,
                        meta: {
                            title: '오프라인 강의 상세',
                            apiType: 'id' // 일반 lectId 사용
                        }
                    },
                    {
                        path: 'sdetail/:uuid',
                        name: 'SLectureOfflineDetail',
                        component: LectureOfflineDetailView,
                        meta: {
                            title: '비공개 오프라인 강의 상세',
                            apiType: 'uuid' // lectUuid 사용
                        }
                    },
                ]
            },
            {
                path: 'creditAcceptable',
                name: 'CreditAcceptableLecture',
                component: CreditAcceptableLectureView,
                meta: {
                    title: '학점인정교육',
                }
            }
        ]
    },
    {
        path: '/matching',
        component: RouterView,
        redirect: '/matching/job',
        meta: {
            title: 'Matching',
        },
        children: [
            {
                path: 'job',
                name: 'MatchingJob',
                component: MatchingJobView,
                meta: {
                    title: '채용 지원',
                }
            },
        ]
    },
    {
        path: '/statistics',
        component: RouterView,
        children: [
            {
                path: '',
                component: StatisticsView,
                meta: {
                    title: '개요',
                },
            }
        ]
    },
    {
        path: '/insights',
        component: RouterView,
        redirect: '/insights/notice',
        meta: {
            title: 'KSA Info',
        },
        children: [
            {
                path: 'notice',
                component: RouterView,
                meta: {
                    title: '공지사항',
                },
                children: [
                    {
                        path: '',
                        name: 'InsightsNotify',
                        component: InsightsNotifyView,
                        meta: {
                            title: '공지사항',
                        }
                    },
                    {
                        path: ':id',
                        name: 'InsightsNotifyDetail',
                        component: InsightsNotifyDetailView,
                        meta: {
                            title: '공지사항 상세',
                        }
                    },
                ]
            },
            {
                path: 'faq',
                name: 'InsightsFAQ',
                component: InsightsFAQView,
                meta: {
                    title: 'FAQ',
                }
            },
            {
                path: 'qna',
                component: RouterView,
                meta: {
                    title: 'Q&A',
                },
                children: [
                    {
                        path: '',
                        name: 'InsightsQNA',
                        component: InsightsQNAView,
                        meta: {
                            title: 'Q&A',
                        }
                    },
                    {
                        path: ':id',
                        name: 'InsightsQNADetail',
                        component: InsightsQNADetailView,
                        meta: {
                            title: 'Q&A 상세',
                        }
                    },
                    {
                        path: 'create',
                        name: 'InsightsQNACreate',
                        component: InsightsQNACreateView,
                        meta: {
                            title: 'Q&A 작성',
                            requiresAuth: true,
                        }
                    },
                ]
            },
            {
                path: 'newsletter',
                component: RouterView,
                meta: {
                    title: '뉴스레터',
                },
                children: [
                    {
                        path: '',
                        name: 'InsightsNewsletter',
                        component: InsightsNewsletterView,
                        meta: {
                            title: '뉴스레터',
                        }
                    },
                    {
                        path: ':id',
                        name: 'InsightsNewsletterDetail',
                        component: InsightsNewsletterDetailView,
                        meta: {
                            title: '뉴스레터 상세',
                        }
                    },
                ],
            },
        ],
    },
    {
        path: '/mypage',
        name: '마이페이지',
        component: RouterView,
        redirect: '/mypage/record',
        meta: {
            title: '마이페이지',
            requiresAuth: true,
        },
        children: [
            {
                path: 'record',
                name: 'MyPageRecord',
                component: MyPageRecordView,
                meta: {
                    title: '이력 관리',
                },
            },
            {
                path: 'resume',
                name: 'MyPageResume',
                component: MyPageResumeView,
                meta: {
                    title: '이력서',
                },
            },
            {
                path: 'lecture',
                component: RouterView,
                meta: {
                    title: '수강현황'
                },
                children: [
                    {
                        path: '',
                        name: 'MyPageLecture',
                        component: MyPageLectureView,
                        meta: {
                            title: '수강현황',
                        },
                    },
                    {
                        path: 'certificate',
                        name: 'MyPageLectureCertificate',
                        component: MyPageLectureCertificateView,
                        meta: {
                            title: '수료증 발급',
                        },
                    },
                    {
                        path: 'viewer',
                        name: 'MyPageLecturePDFViewer',
                        component: MyPageLecturePDFViewerView,
                        meta: {
                            title: '강의자료',
                        },
                    },
                    {
                        path: ':id',
                        name: 'MyPageLectureDetail',
                        component: MyPageLectureDetailView,
                        meta: {
                            title: '수강현황 상세',
                        },
                    },
                ]
            },
            {
                path: 'class',
                component: RouterView,
                meta: {
                    title: '개설 강의 현황',
                },
                children: [
                    {
                        path: '',
                        name: 'MyPageMyClass',
                        component: MyPageMyClassView,
                        meta: {
                            title: '개설 강의 현황',
                        },
                    },
                    {
                        path: ':id',
                        name: 'MyPageMyClassDetail',
                        component: MyPageMyClassDetailView,
                        meta: {
                            title: '수강현황 상세',
                        },
                    },
                ]
            },
            {
                path: 'proposal',
                component: RouterView,
                meta: {
                    title: '채용 제안 현황',
                },
                children: [
                    {
                        path: '',
                        name: 'MypageProposalListView',
                        component: MypageProposalListView,
                        meta: {
                            title: '채용 제안 현황',
                        },
                    },
                    {
                        path: 'create',
                        name: 'MypageProposalCreateView',
                        component: MypageProposalCreateView,
                        meta: {
                            title: '채용 제안 작성 - 채용 제안 현황',
                            requiresAuth: true,
                        }
                    },
                    {
                        path: ':id/:title',
                        name: 'MypageProposalUserListView',
                        component: MypageProposalUserListView,
                        meta: {
                            title: '제안 인재 - 채용 제안 현황',
                        },
                    },
                ]
            },
            {
                path: 'hire',
                component: RouterView,
                meta: {
                    title: '인재 채용 현황',
                },
                children: [
                    {
                        path: '',
                        name: 'MypageResumeCorporation',
                        component: MypageResumeCorporationView,
                        meta: {
                            title: '인재 채용 현황',
                        }
                    },
                ]
            },
            {
                path: 'search',
                component: RouterView,
                meta: {
                    title: '인재 검색',
                },
                children: [
                    {
                        path: '',
                        name: 'MypageSearchResume',
                        component: MypageSearchResumeView,
                        meta: {
                            title: '인재 검색',
                        }
                    },
                ]
            },
            {
                path: 'like',
                component: RouterView,
                meta: {
                    title: '관심 인재',
                },
                children: [
                    {
                        path: '',
                        name: 'MypageResumeLike',
                        component: MypageResumeLikeView,
                        meta: {
                            title: '관심 인재',
                        }
                    },
                ]
            },
            {
                path: 'userResume',
                component: RouterView,
                meta: {
                    title: '입사 지원 현황',
                },
                children: [
                    {
                        path: '',
                        name: 'UserMatchingResumeProposalList',
                        component: UserMatchingResumeProposalListView,
                        meta: {
                            title: '입사 지원 현황',
                        },
                    },
                    {
                        path: 'userMatchingResume',
                        name: 'UserMatchingResume',
                        component: UserMatchingResumeView,
                        meta: {
                            title: '입사 지원서',
                        },
                    },
                    {
                        path: ':id',
                        name: 'UserMatchingResumeProposalDetail',
                        component: UserMatchingResumeProposalDetailView,
                        meta: {
                            title: '입사 지원 상세',
                        },
                    },
                ]
            },
            {
                path: 'userProposal',
                component: RouterView,
                meta: {
                    title: '받은 채용 제안',
                },
                children: [
                    {
                        path: '',
                        name: 'UserProposalUser',
                        component: UserProposalUserView,
                        meta: {
                            title: '받은 채용 제안',
                        },
                    },
                ]
            },
            {
                path: 'profile',
                 component: RouterView,
                meta: {
                    title: '내 정보',
                },
                children: [
                    {
                        path: '',
                        name: 'MyPageProfile',
                        component: MyPageProfileView,
                        meta: {
                            title: '내정보',
                        }
                    },
                    {
                        path: 'reset_pw',
                        name: 'MyPageProfilePWReset',
                        component: MyPageProfilePWResetView,
                        meta: {
                            title: '내 정보 - 비밀번호 변경',
                        }
                    },
                    {
                        path: 'withdraw',
                        name: 'MyPageProfileWithdraw',
                        component: MyPageProfileWithdrawView,
                        meta: {
                            title: '내 정보 - 회원 탈퇴',
                        }
                    },
                ]
            },
            {
                path: 'userGbHistory',
                component: RouterView,
                meta: {
                    title: '회원 유형 변경',
                },
                children: [
                    {
                        path: '',
                        name: 'MyPageUserGbHistory',
                        component: MyPageUserGbHistoryView,
                        meta: {
                            title: '회원 유형 변경',
                        },
                    },
                    {
                        path: 'create',
                        name: 'MypageUserGbHistoryCreate',
                        component: MypageUserGbHistoryCreateView,
                        meta: {
                            title: '회원 유형 변경 - 신청서 작성',
                            requiresAuth: true,
                        }
                    },
                ]
            },
        ]
    },
    {
        path: '/email/unsubscribe/:email',
        name: 'EmailUnsubscribe',
        component: EmailUnsubscribe,
        meta: {
            title: '수신거부',
            fullScreen: true,
        }
    },
]
