<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useMailTemplateStore} from "../../stores/mail/mailTemplateStore";
    import mailTemplateServcie from "../../services/mail/mailTemplateServcie"
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import _ from 'lodash'

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const mailTemplateStore = useMailTemplateStore()
    const { $toast } = getCurrentInstance().appContext.config.globalProperties;

    const tabRef = ref(null)
    const editorRef = ref(null)

    const confirmModal = ref(null)
    const modalContent = ref([])

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name: 'MailSMSTemplate'})
        } else {
            retrieveTemplateDetail(id)
        }

        if(mailTemplateStore.msgGb) {
            tabRef.value.init(mailTemplateStore.msgGb);
            mailTemplateStore.msgGb = '';
        }
    })

    const tmpl = ref({})
    const original_tmpl = ref({})

    const inputError = ref({
        title: '',
        content: '',
    })

    const tabOptions = ref([
        {label:'메일', value:'01'},
        {label:'메시지', value:'02'},
    ])

    const retrieveTemplateDetail = (id) => {
        loadingStore.loading = true;
        mailTemplateServcie.retrieveTemplateDetail(id)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    tmpl.value = result;
                    tabRef.value.init(tmpl.value.msgGb)

                    original_tmpl.value = _.cloneDeep(tmpl.value)

                    if(editorRef.value) {
                        setTimeout(()=>{
                            editorRef.value.init(tmpl.value.content);
                        }, 500)
                    }

                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const onClickResetTemplate = async () => {
        modalContent.value = [`${tmpl.msgGb === '01' ? '메일' : '메시지'} 템플릿을 초기화하고 저장할까요?`];
        const ok = await confirmModal.value.show();

        if(ok) {
            tmpl.value.title = original_tmpl.value.title;
            tmpl.value.content = original_tmpl.value.content;

            if(editorRef.value) {
                editorRef.value.init(tmpl.value.content);
            }

            // save
            saveTemplate();
        }
    }

    const saveTemplate = () => {
        const { msgGb, title, content } = tmpl.value;
        let isInvalid = false;

        inputError.value = {
            title: '',
            content: '',
        }

        if(msgGb === '01' && !title) {
            inputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            inputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            loadingStore.loading = true;
            mailTemplateServcie.updateTemplate(tmpl.value)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', '저장하였습니다.');
                        tmpl.value = result;
                        original_tmpl.value = _.cloneDeep(tmpl.value)
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    const onSelectTab = (option) => {
        mailTemplateStore.msgGb = option.value;
        router.push({name: 'MailSMSTemplate'});
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content mail">
                <div class="sub-title">
                    <h3>템플릿 관리</h3>
                </div>
                <KSATab ref="tabRef" class="tab mb-lg" :options="tabOptions" @change="onSelectTab"/>
                <div class="content-inner">
                    <!-- s : 메일 -->
                    <div class="table-form" v-if="tmpl.msgGb === '01'">
                        <table class="vertical">
                            <colgroup>
                                <col width="200px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th>타입</th>
                                <td>{{ $gbNameKR('TEMPATE_TYPE_EMAIL', tmpl.tempateType) }}</td>
                            </tr>
                            <tr>
                                <th>제목</th>
                                <td>
                                    <KSAInput v-model="tmpl.title" :msg="inputError.title" placeholder="제목을 입력하세요." />
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <KSACKEditor ref="editorRef" v-model="tmpl.content"/>
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.content }}</small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- e : 메일 -->
                    <!-- s : 메시지 -->
                    <div class="table-form" v-else>
                        <table class="vertical">
                            <colgroup>
                                <col width="200px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th>타입</th>
                                <td>{{ $gbNameKR('TEMPATE_TYPE_SMS', tmpl.tempateType) }}</td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <KSATextArea class="textarea-box" v-model="tmpl.content" placeholder="내용을 입력하세요." max-length="2000"/>
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.content }}</small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- e : 메시지 -->
                </div>

                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="초기화" @click="onClickResetTemplate" />
                    <KSAPrimaryButton text="저장" @click="saveTemplate" />
                </div>
            </div>
        </div>
    </div>
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="초기화" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .tab {
        grid-template-columns: repeat(2, 1fr);
    }

    .textarea-box {
        padding-bottom: 26px;
    }
</style>