import http from '../../../../api/http'

function retrieveAllUserMatchingList(paramObj) {
    return http.post('/admin/matching/retrieveAllPagingUserMatching', paramObj)
}

function retrieveMatchingList(paramObj) {
    return http.post('/admin/matching/retrieveAllPagingMatching', paramObj)
}

function retrieveMatchingTotalCount(paramObj) {
    return http.post('/admin/matching/retrieveMatchingTotalCount', paramObj)
}

function retrieveCorp(corpId) {
    return http.post('/admin/matching/retrieveCorporationBasicInfo', corpId)
}

function retrieveCorpEmpList(paramObj) {
    return http.post('/admin/matching/retrieveCorpEmpListPaging', paramObj)
}

function retrieveCorpMatchingList(paramObj) {
    return http.post('/admin/matching/retrieveCorpMatchingListPaging', paramObj)
}

function updateAdminCorpMasterYn(paramObj) {
    return http.post('/admin/matching/updateCorpMasterYn', paramObj)
}

function retrieveMasterYnCount(corpId) {
    return http.post('/admin/matching/retrieveMasterYnCount', corpId)
}

function retrieveUserMatchingList(paramObj) {
    return http.post('/admin/matching/retrieveUserMatchingListPaging', paramObj)
}

function retrieveMatchingUserTotalCount(userId) {
    return http.post('/admin/matching/retrieveMatchingUserTotalCount', userId)
}

export default {
    retrieveAllUserMatchingList,
    retrieveMatchingList,
    retrieveMatchingTotalCount,
    retrieveCorp,
    retrieveCorpEmpList,
    retrieveCorpMatchingList,
    updateAdminCorpMasterYn,
    retrieveMasterYnCount,
    retrieveUserMatchingList,
    retrieveMatchingUserTotalCount,
}
