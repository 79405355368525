<script setup>
    import { ref, onMounted, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from '../../stores/user'
    import { useCookies } from 'vue3-cookies'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import {useSignupStore} from "../../stores/signup";
    import { useNavigationStore } from "../../../../stores/navigation";
    import { useMatchingUpdateStore } from "../../stores/matching/matchingUpdateStore";
    import signinService from "../../services/signin/signinService"
    import mypageUserService from "../../services/mypage/mypageUserService";
    import mypageMatchingService from "../../services/mypage/mypageMatchingService";
    import {useSiteMenuStore} from "../../../../stores/sitemenu";

    const { cookies } = useCookies();
    const router = useRouter()
    const userStore = useUserStore()
    const signupStore = useSignupStore()
    const loadingStore = usePageLoadingStore()
    const navigationStore = useNavigationStore()
    const siteMenuStore = useSiteMenuStore()
    const matchingUpdateStore = useMatchingUpdateStore()

    const onCorpLoginSuccess = inject('onCorpLoginSuccess');
    const onUserLoginSuccess = inject('onUserLoginSuccess');
    const onUserGbHistoryApproveModal = inject('onUserGbHistoryApproveModal');
    const onUserGbHistoryRejectModal = inject('onUserGbHistoryRejectModal');

    onMounted(()=>{
        signupStore.setSignupRequest({});

        if(localStorage.getItem("ERR409") !== undefined && localStorage.getItem("ERR409") !== null) {
            modalContent.value = ["다른 기기에서 로그인이 감지되어 로그아웃합니다.", "직접 로그인을 하지 않으셨다면 로그인 후 비밀번호를 변경해주세요."];
            modal.value.show();
            localStorage.removeItem("ERR409");
        }
    })

    const input = ref({
        userLoginId: '',
        password: '',
    });

    const inputError = ref({
        userLoginId: '',
        password: '',
    })

    const checkedSaveId = ref(true);

    const modal = ref(null)
    const modalContent = ref([]);

    const cookie_user_id = cookies.get("userId");
    // console.log(cookie_user_id);
    if(cookie_user_id) {
        input.value.userLoginId = cookie_user_id;
    }

    const _login = async () => {
        const { userLoginId, password } = input.value;
        const isInvalid = ref(false);

        inputError.value.userLoginId = '';
        inputError.value.password = '';

        if(!userLoginId) {
            inputError.value.userLoginId = '아이디를 입력해주세요.';
            isInvalid.value = true;
        }
        if(!password) {
            inputError.value.password = '비밀번호를 입력해주세요.';
            isInvalid.value = true;
        }

        if(!isInvalid.value) {
            loadingStore.loading = true;
            signinService.signin({loginType: 'user', userLoginId, password})
                .then( async (res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        userStore.setUser({
                            userGb: result.userGb,
                            userCd: result.userCd,
                            userLoginId: result.userLoginId,
                            userName: result.userName,
                            token: result.token,
                            refreshToken: result.refreshToken,
                            accessMenu: result.accessMenu,
                        });
                        siteMenuStore.getSiteMenu();

                        // console.log(checkedSaveId.value);
                        if(checkedSaveId.value) {
                            cookies.set("userId", input.value.userLoginId, -1); // -1 : never expire
                        } else {
                            cookies.remove("userId");
                        }

                        if(result.userGb === 'COMPANY') {
                            await retrieveUserInfo();
                        } else {
                            //loadingStore.loading = false;
                            if(navigationStore.lastAccessPage) {
                                router.push(navigationStore.lastAccessPage);
                                navigationStore.lastAccessPage = '';
                            } else {
                                router.push({name: 'Home'});
                            }

                            mypageUserService.retrieveUserInfo()
                                .then( async (res) => {
                                    if(res.data.code.toLowerCase() === 'success') {
                                        const result = res.data.result;
                                        userStore.user.userInfo = result;
                                    }
                                }).catch( (error) =>  {
                            });
                            loadingStore.loading = false;

                        }
                        await retrieveMatchingUpdate();

                        const propStateCnt = ref(0);
                        const resStateCnt = ref(0);
                        const delayResStateCnt = ref(0);
                        const delayPropStateCnt = ref(0);

                        if(matchingUpdateStore) {
                          propStateCnt.value = matchingUpdateStore.matchingUpdate.propStateCnt;
                          resStateCnt.value = matchingUpdateStore.matchingUpdate.resStateCnt;
                          delayResStateCnt.value = matchingUpdateStore.matchingUpdate.delayResStateCnt;
                          delayPropStateCnt.value = matchingUpdateStore.matchingUpdate.delayPropStateCnt;
                        }
                        if( result.userGb === 'COMPANY' && userStore.user.userInfo.corpMasterYn === 'Y' && ( delayPropStateCnt.value > 0 || delayResStateCnt.value > 0) ) {
                          // 기업로그인일 경우, 기업 매칭 모달
                          onCorpLoginSuccess()
                        } else if(result.userGb === 'STUDENT' && ( propStateCnt.value > 0 || resStateCnt.value > 0 ) ) {
                          // 구직자 로그인일 경우, 구직자 매칭 모달
                          onUserLoginSuccess();
                        }

                        // 회원 유형 변경 모달 띄우기
                        mypageUserService.checkUserGbHistoryApprove()
                            .then( async (res) => {
                              if(res.data.code.toLowerCase() === 'success') { // Y가 존재하는 경우에만 success
                                const userGbHistory = res.data.result;
                                const { seq, stateGb, beforeUserGb, afterUserGb } = userGbHistory;

                                if(stateGb === '03') {  // 승인
                                  onUserGbHistoryApproveModal(seq, beforeUserGb, afterUserGb);
                                } else if (stateGb === '04') { // 거절
                                  onUserGbHistoryRejectModal(seq, beforeUserGb, afterUserGb);
                                }
                              } else {
                              }
                            }).catch( (error) =>  {
                        });
                    } else {
                        modalContent.value = ["아이디가 존재하지 않거나 아이디 또는 비밀번호가 올바르지 않습니다."]
                        modal.value.show();
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
                modalContent.value = ["아이디가 존재하지 않거나 아이디 또는 비밀번호가 올바르지 않습니다."]
                modal.value.show();
            });
            loadingStore.loading = false;
        }
    }

    const retrieveUserInfo = async () => {
        loadingStore.loading = true;
        await mypageUserService.retrieveUserInfo()
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    userStore.user.userInfo = result;
                    // console.log(result);
                    if(result.corpMasterYn === 'Y' && result.corporation.corpId === 0) {
                        router.push({name: 'SigninCorpInfo'});
                    } else {
                        if(navigationStore.lastAccessPage) {
                            router.push(navigationStore.lastAccessPage);
                            navigationStore.lastAccessPage = '';
                        } else {
                            router.push({name: 'Home'});
                        }
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const retrieveMatchingUpdate = async () => {
      await mypageMatchingService.retrieveMatchingUpdate()
          .then( async (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              matchingUpdateStore.matchingUpdate = result;
              await matchingUpdateStore.setMatchingUpdate( result );
              matchingUpdateStore.value = await useMatchingUpdateStore();
            }
            loadingStore.loading = false;
          }).catch( (error) => {
          loadingStore.loading = false;
          console.log(error);
      });
    }

    const goView = (routeName) => {
        router.push({name: routeName});
    }

    const onKeyDown = (e)  => {
        if(e.keyCode === 13) { // enter
            console.log("enter");
            _login();
        }
    }
</script>

<template>
    <div class="sign-wrap" @keydown="onKeyDown" >
        <div class="sign-inner">
            <div class="title">
                <h3>로그인</h3>
            </div>
            <div class="sign-content">
                <KSAInput class="text-box" v-model="input.userLoginId" label="아이디" placeholder="아이디 입력하세요." max-length="20" :msg="inputError.userLoginId" autocomplete="off"/>
                <KSAInput type="password" class="text-box" v-model="input.password" label="비밀번호" placeholder="비밀번호 입력하세요." max-length="30" :msg="inputError.password" autocomplete="new-password"/>
                <p class="save"><KSACheckBox label="아이디 저장" v-model="checkedSaveId"/></p>
                <KSAPrimaryButton class="full-btn" text="로그인" @click="_login" />
            </div>
            <ul class="member">
                <li><span @click="goView('SigninSearchID')">아이디 찾기</span></li>
                <li><span @click="goView('SigninSearchPW')">비밀번호 찾기</span></li>
                <li><span @click="goView('Signup')">회원가입</span></li>
            </ul>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style scoped>

</style>