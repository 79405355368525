<script setup>
    import { ref, defineProps, defineEmits, defineExpose, onMounted, onUnmounted, toRefs, watch } from 'vue'
    import _ from 'lodash'

    const props = defineProps({
        modelValue: {
            type: String,
        },
        options: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        selected: {
            type: Object,
            default: () => {
                return null;
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    });

    const { modelValue, options, selected, disabled } = toRefs(props)

    const open = ref(false);
    const _selectedOption = ref({});

    onMounted(()=>{
        if(selected.value === null) {
            initSelectedOption();
        }
        document.body.addEventListener('click', clickEventHandler)
    })

    onUnmounted(()=>{
        document.body.removeEventListener('click', clickEventHandler)
    })

    const emit = defineEmits(['update:modelValue', 'selectOption']);

    const emitSelectedOption = (option) => {
      if( disabled.value ) return;
        open.value = false;
        _selectedOption.value = option;
        if(modelValue.value !== undefined && modelValue.value !== null) {
            emit('update:modelValue', option.value)
        } else {
            emit("selectOption", option);
        }
    }

    function initSelectedOption(value) {
        _selectedOption.value = _.find(options.value, { value: value || modelValue.value}) || options.value[0];
    }

    // disabled될 경우, 데이터 초기화 함수
    function initSelectedData() {
      // Set to the first option or default state when disabled
        if (options.value.length > 0) {
          _selectedOption.value = options.value[0];
          emit('update:modelValue', options.value[0].value);
        } else {
          _selectedOption.value = {};
          emit('update:modelValue', null);
        }
    }

    defineExpose({
        initSelectedOption,
        initSelectedData,
    })

    const clickEventHandler = (event) => {
        if(!event.target.closest('.custom-select')) open.value = false;
    }

    // disabled 될 경우, 열려있는 select 닫고, 선택된 내용 초기화.
    watch(disabled, (newValue) => {
      if (newValue) {
        open.value = false;
      }
    });
</script>

<template>
    <div class="custom-select" :class="{ disabled }" @blur="open = false">
        <div class="selected" :class="{ open }" @click="open = !open">
           {{ _selectedOption && _selectedOption.label }}
        </div>
        <div class="items" :class="{'selectHide': !open }">
            <div v-for="option in options" @click="emitSelectedOption(option);">
                {{ option.label  }}
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
@import "../../../assets/scss/ui-components/ksa_select";

.custom-select.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.custom-select .selected.disabled {
  cursor: not-allowed;
}

.custom-select .items .disabled {
  cursor: not-allowed;
}
</style>
