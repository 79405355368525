<script setup>
    import { computed } from 'vue'
    import { useRoute } from 'vue-router'
    import {useNavigationStore} from "../stores/navigation";
    import { storeToRefs } from "pinia";
    import {usePageLoadingStore} from "../stores/pageLoading";
    const { isAdminView, isMobileMenuView } = storeToRefs(useNavigationStore());
    import Loading from 'vue3-loading-overlay';
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'

    const route = useRoute()
    const pageLoadingStore = usePageLoadingStore()

    const isLoading = computed(()=>{
        return pageLoadingStore.loading;
    })

    const isFullScreen = computed(()=>{
        return route.meta.fullScreen !== undefined && route.meta.fullScreen;
    })
</script>

<template>
    <div class="wrap" :class="{'admin-wrap':isAdminView}"> <!-- admin-wrap -->
        <Header v-if="!isFullScreen"/>
        <div class="vld-parent" :style="{'display': isMobileMenuView?'none':'block'}">
            <loading
                :active="isLoading"
                loader="dots"
                color="#3843AF"
                opacity="0.26"
                width="35"
                :is-full-page="false"></loading>
            <slot/>
        </div>
    </div>
    <Footer v-if="!isFullScreen" />
</template>


<style lang="scss">
    @import '../assets/scss/common';
    @import '../assets/scss/admin';
    @import '../assets/scss/ckeditor';

</style>