<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, defineExpose } from "vue";
    import kollusVideoService from "../../../../../services/kollusVideoService";
    import Loading from 'vue3-loading-overlay';

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const loadedVideo = ref(false)
    const lectDetail = ref({})
    const videoUrl = ref('')

    const show = (_lectDetail, paramObj, isAdmin) => {
        lectDetail.value = _lectDetail;

        loadedVideo.value = false;
        let func = isAdmin ? kollusVideoService.getVideoPlayUrlWithoutRegDt : kollusVideoService.getVideoPlayUrl;
        func(paramObj)
            .then( (res) => {
                loadedVideo.value = true;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    if(result) {
                        videoUrl.value = result;
                    }
                }
            }).catch( (error) =>  {
                loadedVideo.value = true;
                // console.log(error);
            });

        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    defineExpose({
        show,
    })
</script>

<template>
    <Modal ref="baseModal" class="vld-parent">
        <loading
            :active="!loadedVideo"
            loader="dots"
            color="#3843AF"
            opacity="0.5"
            width="35"
            :is-full-page="false"></loading>
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3><span class="badge">{{ lectDetail.lectGb === '01' ? '온라인' : '오프라인' }} 강의</span>{{ lectDetail.lectName }}</h3>
            </div>
            <div class="modal-body">
                <div class="player-wrap">
                    <iframe id="child" :src="videoUrl" allowfullscreen></iframe>
                    <div id="lms"></div>
                </div>
            </div>
<!--            <div class="modal-footer btn-wrap">-->
<!--                <KSASecondaryButton text="취소" @click="cancel" />-->
<!--                <KSAPrimaryButton text="확인" @click="confirm" />-->
<!--            </div>-->
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .modal-inner {
        .modal-body {
            .player-wrap {
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 30px;
                height: 0;
                overflow: hidden;
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
</style>