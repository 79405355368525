// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useRecordStore = defineStore('recordStore', () => {
    const record = ref(null)

    return {
        record,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRecordStore, import.meta.hot))
}
