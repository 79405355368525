<script setup >
    import { ref, computed, defineEmits, defineExpose, toRefs } from "vue";
    const props = defineProps({
        modelValue: { type: [Array, Boolean] },
        value: { type: [Boolean, Object] },
        label: { type: String },
        name: { type: String },
        disabled: { type: Boolean, default: false },
    });

    const { value } = toRefs(props);
    const isChecked= ref(false)

    const emit = defineEmits(["update:modelValue"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const init = (v) => {
        // console.log('checkbox init! value is ' + v);
        value.value = v;
        isChecked.value = v;
    }

    defineExpose({
        init
    })
</script>

<template>
    <label class="check-box" :class="{'disabled' : disabled}">
        {{ label }}
        <input type="checkbox" :name="name" v-model="model" :value="value" :checked="isChecked" :disabled="disabled"/>
        <span class="checkmark"></span>
    </label>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    .check-box {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        &.disabled {
            cursor: default;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                background-color: $blue_color;
            }
            &:checked ~ .checkmark:after {
                display: block;
            }
            &:disabled ~ .checkmark {
                background-color: $gray-light_color;
            }
        }
        .checkmark {
            position: absolute;
            top: 4px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: $border_line;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 4px;
                top: 4px;
                width: 10px;
                height: 10px;
                background-image: url(../../assets/images/icon-main.png);
                background-size: 200px 200px;
                background-position: -89px -31px;
            }
        }
    }

</style>