<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, defineExpose } from "vue";
    import { useRouter } from 'vue-router'
    import mypageRecordService from "../../../services/mypage/mypageRecordService";
    import adminUserService from "../../../../admin/services/user/adminUserService";
    import mypageProposalService from "../../../services/mypage/mypageProposalService";
    import Loading from 'vue3-loading-overlay';
    import MyPageResumeModalBody from "../mypage/MyPageResumeModalBody.vue";
    import {useMyPageLectDetailStore} from "../../../stores/mypage/mypageLectDetailStore";
    import mypageUserResumeService from "../../../services/mypage/mypageUserResumeService";
    import KSASecondaryButton from "../../../../../components/ui-components/button/KSASecondaryButton.vue";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";

    const router = useRouter();
    const loadingStore = usePageLoadingStore()
    const lectureStore = useMyPageLectDetailStore()

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const modalBody = ref(null)
    const alertModal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])
    const okButtonText = ref("네");
    const cancelButtonText = ref("아니오");

    const loaded = ref(false)

    const showButtons = ref(false)
    const showPropButtons = ref(false)
    const resumeLikeTxts = ref("♡관심인재");
    const disabledCorpYns = ref(false);
    const resumeLikeYns = ref(0)
    const resumeIds = ref(0)

    const mode = ref("mypage")

    const recordCopy = ref({})
    const interests = ref("")

    // 마이페이지 이력서 보기
    // userId가 있으면 해당인원, 없으면 로그인한 계정
    const retrieveRecordDetail = (userId) => {
        loaded.value = false;
        mode.value = "mypage";
        if(userId) {
          let paramObj = {
            userId: userId,
            propId: 0,
            hidden: 0,
          }
            adminUserService.retrieveUserMyPage(paramObj)
                .then( (res) => {
                    loaded.value = true;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        console.log(result);
                        modalBody.value.setHidden(0);
                        modalBody.value.setRecord(result);
                        recordCopy.value = result;
                        interests.value = result.interests
                    }
                }).catch( (error) =>  {
                loaded.value = true;
                console.log(error);
            });
        } else {
            mypageRecordService.retrieveRecord()
                .then( (res) => {
                    loaded.value = true;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        console.log(result);
                        modalBody.value.setHidden(0);
                        modalBody.value.setRecord(result);
                        recordCopy.value = result;
                        interests.value = result.interests
                    }
                }).catch( (error) =>  {
                loaded.value = true;
                console.log(error);
            });
        }
    }

    // 기업 마스터쪽에서 이력서 조회. hidden 1
    // userId가 있으면 해당인원, 없으면 로그인한 계정
    const retrieveMatchingRecordDetail = (userId, propId) => {
      loaded.value = false;
      mode.value = "mypage";
      if(userId !== undefined && propId !== undefined) {
        let paramObj = {
          userId: userId,
          propId: propId,
          hidden: 1,
        }
        adminUserService.retrieveUserMyPage(paramObj)
            .then( (res) => {
              loaded.value = true;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                console.log(result);
                modalBody.value.setHidden(1);
                modalBody.value.setRecord(result);
                recordCopy.value = result;
                interests.value = result.interests
              }
            }).catch( (error) =>  {
          loaded.value = true;
          console.log(error);
        });
      } else {
        console.log('USER ID가 없습니다.')
      }
    }

    // 수강신청이력서 보기
    const retrieveUserLectureRecordDetail = (userId, lectId, regDt) => {
        loaded.value = false;

        mode.value = "userLecture";
        const lecture = {
            "userId" : userId,
            "lectId" : lectId,
            "regDt" : regDt
        }
        lectureStore.setLecture(lecture)

        if(userId && lectId && regDt) {
            adminUserService.retrieveUserLectureMypage(userId, lectId, regDt)
                .then( (res) => {
                    loaded.value = true;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        console.log(result);
                        modalBody.value.setRecord(result);
                        recordCopy.value = result;
                        interests.value = result.interests
                    }
                }).catch( (error) =>  {
                loaded.value = true;
                console.log(error);
            });
        } else {
            $toast('', '새로고침 후 다시 실행해주세요.');
        }
    }

    // 입사지원이력서 보기
    const retrieveUserMatchingResumeDetail = (resumeId, hidden) => {
        loaded.value = false;
        resumeIds.value = resumeId;

        if(resumeId) {
          let paramObj = {
            resumeId: resumeId,
            hidden: hidden,
          }
            mypageUserResumeService.retrieveMatchingResume(paramObj)
                .then( (res) => {
                    loaded.value = true;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        console.log(result);
                        modalBody.value.setHidden(hidden);
                        modalBody.value.setRecord(result);
                        recordCopy.value = result;
                        interests.value = result.interests
                    }
                }).catch( (error) =>  {
                loaded.value = true;
                console.log(error);
            });
        } else {
            $toast('', '새로고침 후 다시 실행해주세요');
        }
    }

    // 이력서 수정 버튼. 새창으로 열기
    const onClickChangeResume = () => {
        router.push({ name: 'UserMatchingResume', query : { mode: mode.value }})
    }

    // 이력서 전송 버튼. 완료 후 입사지원현황 페이지로 이동
    const onClickSendResume = () => {
        if (interests.value === null || interests.value === "") {
            modalContent.value = ["관심 카테고리를 선택하지 않았습니다.", "[이력서 수정]버튼을 눌러 관심 카테고리를 선택하세요."];
            alertModal.value.show();
        } else {
            confirmSendResume();
        }
    }

    // 이력서 전송
    const confirmSendResume = async () => {
        modalContent.value = ["이력서를 기업에 전송할까요?", "전송 이후 인재 노출 희망을 Off하면 이력서는 비공개 됩니다."];
        okButtonText.value = "전송";
        cancelButtonText.value = "취소";
        const ok = await confirmModal.value.show();

        if ( ok ) { // 전송
            loaded.value = false;

            mypageUserResumeService.createResumeRecord ( recordCopy.value )
            .then((res) => {
                loaded.value = true;

                if(res.data.code.toLowerCase() === 'success') {
                    router.push({ name: 'UserMatchingResumeProposalList'});
                }
            }).catch( (error) =>  {
                loaded.value = true;
                console.log(error);
            });
        } else {    // 취소

        }
    }

    // 채용 제안
    const onClickResumeCorpYn = async (resumeId) => {
      modalContent.value = ["지원자에게 채용을 제안할까요?"];
      okButtonText.value = "채용 제안";
      const ok = await confirmModal.value.show();
      if(!ok) {
        return -1;
      } else {
        let paramObj2 = {
          resumeId: resumeId,
        }
        mypageProposalService.createResumeCorporationByResumeId(paramObj2)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                modalContent.value = ["채용을 제안하였습니다."];
                alertModal.value.show();
                disabledCorpYns.value = true;
                //$toast('', "채용을 제안하였습니다.")
              } else {
                modalContent.value = ["제안을 실패하였습니다."];
                alertModal.value.show();
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          modalContent.value = ["제안을 실패하였습니다."];
          alertModal.value.show();
          console.log(res);
        });
      }
    }

    // 관심 인재
    const onClickResumeLikeYn = async (resumeId, yn) => {
      if(yn === 1) {
        modalContent.value = ["관심인재를 삭제하시겠습니까?"];
        okButtonText.value = "관심인재 삭제";
      } else {
        modalContent.value = ["관심인재를 등록하시겠습니까?"];
        okButtonText.value = "관심인재 등록";
      }
      const ok = await confirmModal.value.show();
      if(!ok) {
        return -1;
      } else {
        let paramObj3 = {
          resumeId: resumeId,
          likeState: yn,
        }
        mypageProposalService.changeResumeLikeByResumeId(paramObj3)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                alertModal.value.show();
                if(yn === 1) {
                  resumeLikeTxts.value = "♡관심인재";
                  resumeLikeYns.value = 0;
                } else {
                  resumeLikeTxts.value = "♥관심인재";
                  resumeLikeYns.value = 1;
                }
                //$toast('', msg+"을(를) 하였습니다.")
              } else {
                modalContent.value = ["변경을 실패하였습니다."];
                alertModal.value.show();
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          modalContent.value = ["변경을 실패하였습니다."];
          alertModal.value.show();
          console.log(res);
        });
      }
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = async () => {
        baseModal.value.close();
        resolvePromise.value(true);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const init = (showButton, showPropButton, resumeCorpYn, resumeLikeYn) => {
        // 기본값 저장
        //input.value.lectId = lectId;
        showButtons.value = showButton;
        showPropButtons.value = showPropButton;
        if(resumeLikeYn === 1) {
          resumeLikeTxts.value = "♥관심인재";
        } else {
          resumeLikeTxts.value = "♡관심인재";
        }

        if(resumeCorpYn === 1) {
          disabledCorpYns.value = true;
        } else {
          disabledCorpYns.value = false;
        }
        resumeLikeYns.value = resumeLikeYn;
    }

    defineExpose({
        show,
        init,
        retrieveRecordDetail,
        retrieveMatchingRecordDetail,
        retrieveUserLectureRecordDetail,
        retrieveUserMatchingResumeDetail,
    })
</script>

<template>
    <Modal ref="baseModal" class="vld-parent">
        <loading
            :active="!loaded"
            loader="dots"
            color="#3843AF"
            opacity="0.5"
            width="35"
            :is-full-page="false"></loading>
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-body" v-show="loaded">
                <MyPageResumeModalBody ref="modalBody"/>
            </div>
            <div v-if="showButtons" class="modal-footer btn-wrap">
                <KSASecondaryButton text="이력서 수정" @click="onClickChangeResume" />
                <KSAPrimaryButton text="이력서 전송" @click="onClickSendResume" />
            </div>
            <div v-if="showPropButtons" class="modal-footer btn-wrap">
              <span data-title="관심 인재 : ">
                <KSASecondaryButton :text="resumeLikeTxts" @click="onClickResumeLikeYn(resumeIds, resumeLikeYns)" class="red_button"/>
              </span>
              <span data-title="채용 제안 : ">
                <KSAPrimaryButton text="채용 제안" :disabled="disabledCorpYns" @click="onClickResumeCorpYn(resumeIds)" />
              </span>
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" :cancelButtonText="cancelButtonText" />

</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
    margin-top: 50px;
    .close {
        top: -20px !important;
    }
    .modal-footer {
        padding: 0 30px;
    }
}
</style>