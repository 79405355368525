<script setup>
    import { ref, watch, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from '../../stores/user'
    import insightsQNAService from "../../services/insights/insightsQNAService";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useUserInsightsStore} from "../../stores/insights/userInsightsStore";

    const router = useRouter();
    const userStore = useUserStore()
    const loadingStore = usePageLoadingStore()
    const userInsightsStore = useUserInsightsStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    const searchType = ref('all');
    const searchTypeOptions = [
        { label:'전체', value:'all', },
        { label:'제목', value:'title', },
        { label:'내용', value:'content', },
    ]
    const input = ref({
        search: ''
    });

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    const showMyPostCheckbox = computed(()=>{
        return userStore.user && userStore.user.userLoginId;
    })
    const isSelected = ref(false); // Checkbox - 내가 작성한 Q&A 보기

    const modal = ref(null);
    const modalContent = ref([]);

    onMounted(() => {
        retrieveList();
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1 && searchType.value === 'all' && input.value.search === '' && !isSelected.value;
    });

    const retrieveList = () => {

        let paramObj = {
            pageNo: currentPage.value,
            myPostGb: isSelected.value ? 'Y' : 'N',
            size: pageSize,
            searchType: searchType.value,
            searchInput: input.value.search || ""
        };

        loadingStore.loading = true;
        insightsQNAService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                    // console.log(list.value);
                    if(isInitView.value) {
                        userInsightsStore.setQnaList(list.value);
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    watch(isSelected, ()=>{
        currentPage.value = 1;
        retrieveList();
    });

    const onSearchInput = (value) => {
        input.value.search = value;
        currentPage.value = 1;
        retrieveList();
    }

    const onOptionSelected = (option) => {
        searchType.value = option.value
    }

    const onClickQNA = (qna) => {
        if(qna.secretYn === 'Y') { // 비밀글
            if(!userStore.isAuthenticated || qna.createUserLoginId !== userStore.user.userLoginId) {
                modalContent.value = ["비밀글은 작성자만 읽을 수 있습니다."];
                modal.value.show();
            } else {
                router.push({name: 'InsightsQNADetail', params:{ id: qna.boardId}});
            }
        } else {
            router.push({name: 'InsightsQNADetail', params:{ id: qna.boardId}});
        }
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>Q&amp;A</h3>
            </div>
            <div class="list-top">
                <div style="display: flex">
                    <p>총 게시물 <span>{{ totalCount }}</span>건</p>
                    <KSACheckBox v-if="showMyPostCheckbox" label="내가 작성한 Q&amp;A 보기" v-model="isSelected"  />
                </div>
                <div class="search-box">
                    <KSASelect :options="searchTypeOptions" class="search-select" @selectOption="onOptionSelected"/>
                    <KSASearchInputBox placeholder="검색어를 입력하세요." class="search-input" @search="onSearchInput" />
                </div>
            </div>
            <div class="table-form board-mobile-vertical">
                <table>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>작성자</th>
                        <th>작성일</th>
                        <th>상태</th>
                    </tr>
                    <tr v-for="qna in (isInitView ? userInsightsStore.qnaList : list)">
                        <td data-title="번호 : ">
                            {{ qna.boardNo }}
                        </td>
                        <td class="text-left link" @click="onClickQNA(qna)" data-title="제목 : ">
                            {{ qna.title }} <img v-if="qna.secretYn === 'Y'" src="../../../../assets/images/icon-lock.png" />
                        </td>
                        <td data-title="작성자 : ">{{ qna.createUserName || '작성자' }}</td>
                        <td data-title="작성일 : ">{{ $yyyymmddOrHHmm(qna.createDate)}}</td>
                        <td :class="{'wait': !qna.answer, 'complete': qna.answer}" data-title="상태 : ">답변 {{ qna.answer ? '완료' : '대기'}}</td>
                    </tr>
                </table>
                <div class="result-none" v-if="list.length === 0">
                    검색 결과가 없습니다.
                </div>
            </div>
            <Paginate
                v-if="list.length > 0"
                v-model="currentPage"
                :page-count="totalPages"
                :click-handler="onSelectedPage"
            />
            <div class="btn-wrap text-right">
                <KSAPrimaryButton text="Q&amp;A 작성하기" @click="router.push({name: 'InsightsQNACreate'})" />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />

</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>
