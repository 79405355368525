<script setup>
    import { ref, defineProps, defineEmits, onMounted, onUnmounted } from 'vue'
    const { text, options, color, disabled } = defineProps(['text', 'options', 'color', 'disabled'])

    onMounted(()=>{
        document.body.addEventListener('click', clickEventHandler)
    })
    onUnmounted(()=>{
        document.body.removeEventListener('click', clickEventHandler)
    })

    const emit = defineEmits(['click'])

    const open = ref(false)

    const emitClick = (index) => {
        open.value = false;
        emit('click', index)
    }

    const clickEventHandler = (event) => {
        if(!event.target.closest('.button-select')) open.value = false;
    }
</script>

<template>
    <div class="button-select">
        <div @click="open = !open" :style="color && {'border-color': color}" class="selected">{{ text }}</div>
        <div class="items" v-if="open">
            <div v-for="(option, index) in options" @click="emitClick(index)">
                {{ option.label ? option.label : option }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    .button-select {
        position: relative;
        width: 200px;
        height: 46px;
        line-height: 46px;
        text-align: left;
        outline: none;
        &.gray_button {
            .selected {
                color: $black_color;
                border: $border_line;
                &:after {
                    border-color: $black_color;
                }
            }
            .items {
                border-color: $gray-regular_color;
            }
        }
        &.disabled {
            cursor: default;
            .selected {
                background: $gray-light_color;
                color: $gray-regular_color;
                border: 0;
                &:after {
                    border-color: $gray-regular_color;
                }
            }
        }
        .selected {
            width: 100%;
            border: 1px solid $blue_color;
            border-radius: 4px;
            background-color: transparent;
            color: $blue_color;
            cursor: pointer;
            user-select: none;
            line-height: inherit;
            padding: 0 44px 0 20px;
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 20px;
                display: block;
                width: 5px;
                height: 5px;
                border-top: 1px solid $blue_color;
                border-left: 1px solid $blue_color;
                -webkit-transform: translateY(-50%) rotate(225deg);
                transform: translateY(-50%) rotate(225deg);
                transition-duration: 0.2s;
            }
            &.open {
                border-radius: 4px 4px 0 0;
                &:after {
                    transform: rotate(45deg);
                    transition-duration: 0.2s;
                }
            }
        }
        .items {
            width: 100%;
            height: max-content;
            overflow: auto;
            position: absolute;
            background-color: #fff;
            border: 1px solid $blue_color;
            left: 0;
            right: 0;
            z-index: 1;
            padding: 20px;
            margin-top: -1px;
            transition-duration: 0.2s;
            div {
                color: #000;
                @include font-size_xs;
                cursor: pointer;
                user-select: none;
                margin-bottom: 16px;
                &:hover {
                    color: $blue_color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>