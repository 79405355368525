<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, defineExpose } from "vue";
import { useRouter } from 'vue-router'
import LectureResumeModal from "./LectureResumeModal.vue";

const router = useRouter();

const baseModal = ref(null);
const resolvePromise = ref(null);

const modalContent = ref([]);
const lectureResumeModal = ref(null);

const loaded = ref(false);
const content = ref(null);

const input = ref({
  ticketOfferGb: '',  // 선발 기준
  resumeRequired: '', // 이력서 필수 항목
  lectDetail: {},  // 강의 상세 정보
});

const show = () => {
  baseModal.value.open();
  return new Promise((resolve, _) => {
    resolvePromise.value = resolve;
  });
};

const cancel = () => {
  baseModal.value.close();
  resolvePromise.value(false);
  //localStorage.setItem('isModalVisible', 'false');
};

const init = (ticketOfferGb, resumeRequired, lectDetail) => {
  // 기본값 저장
  input.value.ticketOfferGb = ticketOfferGb;
  input.value.resumeRequired = resumeRequired;
  input.value.lectDetail = lectDetail;
};

const goLectureResumeModal = async () => {
  lectureResumeModal.value.setOfflineLecture(1, input.value.resumeRequired, input.value.lectDetail); // 오프라인 강의 1 ,이력서필수항목, 강의상세정보 이력서모달에 전달
  lectureResumeModal.value.retrieveRecordDetail();
  lectureResumeModal.value.show();
  cancel();
};

defineExpose({
  show,
  init,
})
</script>

<template>
  <Modal ref="baseModal" class="vld-parent">
    <div class="modal-inner m-size">
      <button class="close" @click="cancel"></button>
      <div class="modal-body">
          <h1>수강 신청 안내</h1>
          <p>본 강의는 아래 선발 기준<br>
            결과에 따라 수강이 확정됩니다.</p>
          <div class="table-form">
              <table class="horizontal">
                  <tr>
                      <th>선발 기준</th>
                      <td>
                          <span v-if="input.ticketOfferGb ==='02'">서류전형</span>
                          <span v-else-if="input.ticketOfferGb ==='03'">서류+면접전형</span>
                          <span v-else>-</span>
                      </td>
                  </tr>
                  <tr>
                      <th>이력서 필수 항목</th>
                      <td>기본 정보<span v-if="input.resumeRequired">,{{ input.resumeRequired }}</span></td>
                  </tr>
              </table>
          </div>
          <p>이력서를 확인하고<br>
            수강 신청을 완료해 주세요.</p>
      </div>
      <div class="modal-footer btn-wrap">
        <button class="full-btn" style="background: #fff; color: #86889A; border: 1px solid #86889A;" @click="cancel">취소</button>
        <button class="full-btn" @click="goLectureResumeModal">이력서 확인하기</button>
      </div>
    </div>
  </Modal>
  <LectureResumeModal ref="lectureResumeModal"/>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
  margin-top: 50px;
  .close {
    top: -20px !important;
    right: -10px !important;;
  }
  .modal-footer {
    display: block;
    padding: 0 30px;
  }
}
</style>