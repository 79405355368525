<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, defineExpose } from "vue";
import { useRouter } from 'vue-router'

const router = useRouter();

const baseModal = ref(null);
const resolvePromise = ref(null);

const modalContent = ref([])

const loaded = ref(false)
const content = ref(null);

const show = () => {
  baseModal.value.open();
  return new Promise((resolve, _) => {
    resolvePromise.value = resolve;
  });
};

const cancel = () => {
  baseModal.value.close();
  resolvePromise.value(false);
  localStorage.setItem('isModalVisible', 'false');
};

const goProposal = () => {
  router.push({name: 'MypageProposalCreateView'});
  cancel();
};

defineExpose({
  show,
})
</script>

<template>
  <Modal ref="baseModal" class="vld-parent">
    <div class="modal-inner m-size">
      <button class="close" @click="cancel"></button>
      <div class="modal-body">
        <h1>실시간 채용 지원 시스템</h1>
        <p>맞춤형 매칭 서비스를 이용해보세요.<br>
        <strong>우리 기업에 딱 맞는 인재 정보를 확인할 수 있습니다.</strong></p>
        <div class="vertical-process-container">
          <div class="step first">
            <div>채용 제안서 작성</div>
          </div>
          <div class="step">
            <div>인재 검색 / 채용 제안</div>
          </div>
          <div class="step important">
            <div>[학생/취업준비생]<br>채용 제안서 확인 / 수락</div>
          </div>
          <div class="step last">
            <div>매칭 완료</div>
          </div>
        </div>
      </div>
      <div class="modal-footer btn-wrap">
        <button class="full-btn" @click="goProposal">채용 제안서 작성하기</button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
  margin-top: 50px;
  .close {
    top: -20px !important;
    right: -10px !important;;
  }
  .modal-footer {
    display: block;
    padding: 0 30px;
  }
}
</style>