<script setup>
import { ref, onMounted, getCurrentInstance, inject, computed } from 'vue'
import { RouterLink } from 'vue-router'
import adminMatchingService from "../../services/matching/adminMatchingService";
import mypageProposalService from "../../../user/services/mypage/mypageProposalService";
import mypageMatchingService from "../../../user/services/mypage/mypageMatchingService";
import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
import {usePageLoadingStore} from "../../../../stores/pageLoading";
import { useMatchingUpdateStore} from "../../../user/stores/matching/matchingUpdateStore";
import _ from "lodash";
import KSASecondaryButton from "../../../../components/ui-components/button/KSASecondaryButton.vue";
import MypageProposalContentModal from "../../../user/views/mypage/proposal/MypageProposalContentModal.vue";
import MyPageResumeModal from "../../../user/components/modal/mypage/MyPageResumeModal.vue";

const loadingStore = usePageLoadingStore()
const matchingUpdateStore = useMatchingUpdateStore()
const { $pageListBoardNo, $toast, $gb, $gbNameKR, $number, $yyyymmddHHmmss, $corpState, $interests } = getCurrentInstance().appContext.config.globalProperties;
const getSystemCodeValues = inject('systemCodeValues');

onMounted(()=>{
  retrieveList()
  retrieveCount()
})

// const list = ref([])
const resultCount = ref({})

const currentPage = ref(1);
const pageSize = ref(10);
const totalPages = ref(0);
const totalCount = ref(0);

const interests = ref($interests)
const corpState = ref($corpState)
const searchOpen = ref(true)
const stateGbsListRef = ref(null)
const interestsListRef = ref(null)
const matchingGbListRef = ref(null)

const corpMatchingList = ref([]);

const modal = ref(null)
const confirmModal = ref(null)
const proposalContentModal = ref(null);
const userMatchingResumeModal = ref(null);
const modalContent = ref([])
const okButtonText = ref('변경')

// 검색 조건
const searchInput = ref({
  stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
  interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
  matchingGbList: ['01','02'],
  searchType: 'userName', // userName 구직자명  masterName 마스터명
  searchInput: '',
  searchDurationType: 'all',
  startYmd: '',
  endYmd: '',
});

const GroupMatching = ref([
  { label:'인재 채용', value: '02' },
  { label:'채용 제안', value: '01' },
]);

const searchDurationTypeGroup = ref([
  { label:"모든 기간", value: "all" },
  { label:"기간 검색", value: "term" },
])

const searchTypeGroup = ref([
  { label:'구직자이름', value: 'userName', placeholder: '이름을 입력해 주세요.'  },
  { label:'구직자회원코드', value: 'code', placeholder: '회원코드를 입력해 주세요.'  },
  { label:'마스터이름', value: 'masterName', placeholder: '이름을 입력해 주세요.'  },
  { label:'기업명', value: 'corpName', placeholder: '기업명을 입력해 주세요.'  },
]);

const pageSizeGroup = ref([
  { label:"10개씩 보기", value: "10" },
  { label:"30개씩 보기", value: "30" },
  { label:"50개씩 보기", value: "50" },
  { label:"100개씩 보기", value: "100" },
])

const onSelectSearchTypeGroup = (option) => {
  searchInput.value.searchType = option.value;
}

const searchInputPlaceholder = computed(()=>{
  const option = _.find(searchTypeGroup.value, { value: searchInput.value.searchType });
  if(option) {
    return option.placeholder;
  } else {
    return '';
  }
});

const retrieveList = async () => {
  let paramObj = {
    corpId: 0,
    searchStateGbs: _.map(searchInput.value.stateGbsList).join(','),
    searchInterests: _.map(searchInput.value.interestsList).join(','),
    searchMatchingGb: _.map(searchInput.value.matchingGbList).join(','),
    searchType: searchInput.value.searchType,
    searchInput: searchInput.value.searchInput,
    startYmd: searchInput.value.searchDurationType === 'all' ? '' : searchInput.value.startYmd,
    endYmd: searchInput.value.searchDurationType === 'all' ? '' : searchInput.value.endYmd,
    pageNumber: currentPage.value,
    size: pageSize.value,
  }
  //loadingStore.loading = true;
  await getSystemCodeValues();
  adminMatchingService.retrieveAllUserMatchingList(paramObj)
      .then( async (res) => {
        //loadingStore.loading = false;
        await retrieveCount();
        // console.log(res);
        if(res.data.code.toLowerCase() === 'success') {
          const result = res.data.result;
          totalPages.value = result.totalPages;
          totalCount.value = result.totalElements;
          corpMatchingList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
        } else {
        }
      }).catch( (res) =>  {
    console.log(res);
    //loadingStore.loading = false;
  });
}

const retrieveCount = () => {
  loadingStore.loading = true;
  let paramObj = {
    corpId: 0,
    corpUserId: 0,
  }
  adminMatchingService.retrieveMatchingTotalCount(paramObj)
      .then( (res) => {
        loadingStore.loading = false;
        if(res.data.code.toLowerCase() === 'success') {
          resultCount.value = res.data.result;
        } else {
        }
      }).catch( (res) => {
        loadingStore.loading = false;
  });
}

// s: 채용 진행 현황
// 채용 상태 변경
const changeState = (matchingGb, userId, stateGb, propId, corpId, resumeId) => {
  if(matchingGb === '01') {  // 채용 제안
    changePropState(userId, propId, stateGb);
  } else if(matchingGb === '02') {  // 인재 채용
    changeResState(resumeId, corpId, stateGb)
  }
}

// 채용 제안 상태 변경
const changePropState = async (userId, propId, stateGb) => {
  if(stateGb === '02') {
    modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
  } else if(stateGb === '05') {
    modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
  } else if(stateGb === '06') {
    modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
  } else if(stateGb === '07') {
    modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
  } else if(stateGb === '08') {
    modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
  }
  okButtonText.value = "변경";

  const ok = await confirmModal.value.show();
  if(!ok) {
    return false;
  } else {
    let paramObj = {
      propId: propId,
      userId: userId,
      propStateGb: stateGb
    };
    //console.log(paramObj);

    loadingStore.loading = true;
    mypageProposalService.changeProposalUserState(paramObj)
        .then( (res) => {
          loadingStore.loading = false;
          if(res.data.code.toLowerCase() === 'success') {
            // const result = res.data.result;
            modalContent.value = ["변경하였습니다."];
            modal.value.show();
            retrieveList();
            // 매칭 상태 값 업데이트
            retrieveMatchingUpdate();
          } else {
            console.log(res);
          }
        }).catch( (res) => {
      loadingStore.loading = false;
      console.log(res);
    });
  }
};

// 인재 채용 상태 변경
const changeResState = async (resumeId, corpId, stateGb) => {
  if(stateGb === '02') {
    modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
  } else if(stateGb === '05') {
    modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
  } else if(stateGb === '06') {
    modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
  } else if(stateGb === '07') {
    modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
  } else if(stateGb === '08') {
    modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
  }
  okButtonText.value = "변경";
  const ok = await confirmModal.value.show();
  if(!ok) {
    return -1;
  } else {
    let paramObj2 = {
      resumeId: resumeId,
      corpId: corpId,
      resStateGb: stateGb,
    }
    mypageProposalService.changeResumeCorporationState(paramObj2)
        .then( (res) => {
          loadingStore.loading = false;
          if(res.data.code.toLowerCase() === 'success') {
            //const result = res.data.result;
            modalContent.value = ["변경하였습니다."];
            modal.value.show();
            retrieveList();
            // 매칭 상태값 업데이트
            retrieveMatchingUpdate();
          } else {
            console.log(res);
          }
        }).catch( (res) =>  {
      loadingStore.loading = false;
      console.log(res);
    });
  }
}

// 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
const retrieveMatchingUpdate = async () => {
  await mypageMatchingService.retrieveMatchingUpdate()
      .then( async (res) => {
        loadingStore.loading = false;
        if(res.data.code.toLowerCase() === 'success') {
          const result = res.data.result;
          matchingUpdateStore.matchingUpdate = result;
          await matchingUpdateStore.setMatchingUpdate( result );
          matchingUpdateStore.value = await useMatchingUpdateStore();
        }
      }).catch( (error) => {
        loadingStore.loading = false;
        console.log(error);
      });
}

// 기본 이력서 보기
const showUserResumeModal = (userId) => {
  userMatchingResumeModal.value.init(false);
  userMatchingResumeModal.value.retrieveRecordDetail(userId);
  userMatchingResumeModal.value.show();
}

// 채용 이력서 보기
const showMatchingResumeModal = (resumeId) => {
  userMatchingResumeModal.value.init(false);
  userMatchingResumeModal.value.retrieveUserMatchingResumeDetail(resumeId, 0);
  userMatchingResumeModal.value.show();
}

// 채용 제안서 보기
const showProposalContentModal = async (propId) => {
  proposalContentModal.value.retrieveProposalContent(propId);
  await proposalContentModal.value.show();
};
// e: 채용 진행 현황

const clearSearchInput = () => {
  searchInput.value = {
    stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
    interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
    matchingGbList: ['01','02'],
    searchType: 'userName', // userName 구직자명  masterName 마스터명
    searchInput: '',
    searchDurationType: 'all',
    startYmd: '',
    endYmd: '',
  }
  stateGbsListRef.value.initOptions();
  interestsListRef.value.initOptions();
  matchingGbListRef.value.initOptions();
}

const onClickSearch = () => {
  currentPage.value = 1;
  retrieveList();
}

const onSelectPage = (pageNo) => {
  currentPage.value = pageNo;
  retrieveList();
}

const onSelectPageSize = (option) => {
  pageSize.value = option.value;
  currentPage.value = 1;
  retrieveList();
}

</script>

<template>
  <div class="container">
    <div class="flex-wrap admin">
      <SNBAdmin class="snb" />
      <div class="content">
        <div class="sub-title">
          <h3>회원별 채용 현황</h3>
        </div>

        <div class="table-form">
          <table class="horizontal all-border">
            <tr class="tr-focus-top tr-focus-side">
              <th rowspan="2">전체 현황</th>
              <th class="total" >전체</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
            </tr>
            <tr class="tr-focus-side tr-focus-bottom">
              <td>{{ resultCount.totalState }}</td>
              <td>{{ resultCount.totalState01 }}</td>
              <td>{{ resultCount.totalState02 }}</td>
              <td>{{ resultCount.totalState03 }}</td>
              <td>{{ resultCount.totalState04 }}</td>
              <td>{{ resultCount.totalState05 }}</td>
              <td>{{ resultCount.totalState06 }}</td>
              <td>{{ resultCount.totalState07 }}</td>
              <td>{{ resultCount.totalState08 }}</td>
            </tr>
            <tr>
              <th rowspan="2">인재 채용 현황</th>
              <th class="total">전체</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
            </tr>
            <tr>
              <td>{{ resultCount.totalResState }}</td>
              <td>{{ resultCount.resState01 }}</td>
              <td>{{ resultCount.resState02 }}</td>
              <td>{{ resultCount.resState03 }}</td>
              <td>{{ resultCount.resState04 }}</td>
              <td>{{ resultCount.resState05 }}</td>
              <td>{{ resultCount.resState06 }}</td>
              <td>{{ resultCount.resState07 }}</td>
              <td>{{ resultCount.resState08 }}</td>
            </tr>
            <tr>
              <th rowspan="2">채용 제안 현황</th>
              <th class="total">전체</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
            </tr>
            <tr>
              <td>{{ resultCount.totalPropState }}</td>
              <td>{{ resultCount.propState01 }}</td>
              <td>{{ resultCount.propState02 }}</td>
              <td>{{ resultCount.propState03 }}</td>
              <td>{{ resultCount.propState04 }}</td>
              <td>{{ resultCount.propState05 }}</td>
              <td>{{ resultCount.propState06 }}</td>
              <td>{{ resultCount.propState07 }}</td>
              <td>{{ resultCount.propState08 }}</td>
            </tr>
          </table>
        </div>
        <br>
        <!-- s: 검색 -->
        <div class="filter-wrap" :class="{'open': searchOpen}">
          <p @click="searchOpen = !searchOpen">검색</p>
          <div class="filter">
            <dl>
              <dt style="display:block;"><span>상태</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
              <dd>
                <KSACheckBoxGroup
                    ref="stateGbsListRef"
                    className="mr-md"
                    name="stateGbsList"
                    v-model="searchInput.stateGbsList"
                    :options="corpState" />
              </dd>
            </dl>
            <dl>
              <dt style="display:block;"><span>관심 카테고리</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
              <dd>
                <KSACheckBoxGroup
                    ref="interestsListRef"
                    className="mr-md"
                    name="interestsList"
                    v-model="searchInput.interestsList"
                    :options="interests" />
              </dd>
            </dl>
            <dl>
              <dt>경로 구분</dt>
              <dd>
                <KSACheckBoxGroup
                    ref="matchingGbListRef"
                    className="mr-md"
                    name="matchingGbList"
                    v-model="searchInput.matchingGbList"
                    :options="GroupMatching" />
              </dd>
            </dl>
            <dl>
              <dt>채용제안일</dt>
              <dd class="text-box">
                <div class="grid">
                  <template v-for="group in searchDurationTypeGroup" key="item.value" >
                    <KSARadioButton class="mr-md" name="searchDurationGb3" :label="group.label" v-model="searchInput.searchDurationType" :value="group.value" />
                  </template>
                  <div style="display: inline-block;" v-show="searchInput.searchDurationType !== 'all'">
                    <KSADatepickerTerms
                        :startDate="searchInput.startYmd"
                        :endDate="searchInput.endYmd"
                        @update:startDate="(value)=> searchInput.startYmd = value"
                        @update:endDate="(value)=> searchInput.endYmd = value"
                    />
                  </div>
                </div>
              </dd>
            </dl>
            <!-- 구직자이름, 구직자회원코드, 마스터이름, 기업명 검색 -->
            <dl>
              <dt>
                <KSASelect :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
              </dt>
              <dd>
                <KSAInput class="text-box" v-model="searchInput.searchInput" :placeholder="searchInputPlaceholder" />
              </dd>
            </dl>
            <div class="btn-wrap">
              <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
              <KSAPrimaryButton text="검색" @click="onClickSearch" />
            </div>
          </div>
        </div>
        <!-- e: 검색 -->

        <div class="list-top">
          <p>전체 <span>{{ $number(totalCount)}}</span>건</p>
          <div>
            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
          </div>
        </div>

        <div class="table-form">
          <table class="horizontal" v-if="corpMatchingList !== null">
            <tr>
              <th rowspan="2">NO</th>
              <th rowspan="2">채용 제안일</th>
              <th rowspan="2">경로 구분</th>
              <th rowspan="2">기업명</th>
              <th rowspan="2" class="border-right">제안 마스터</th>
              <th colspan="2">구직자</th>
              <th rowspan="2">관심 카테고리</th>
              <th rowspan="2">이력서 보기</th>
              <th rowspan="2">채용 제안서 보기</th>
              <th rowspan="2">상태</th>
              <th rowspan="2">상태 변경</th>
            </tr>
            <tr>
              <th>이름</th>
              <th>회원코드</th>
            </tr>
            <tr v-for="corpMatching in corpMatchingList">
              <td>{{ corpMatching.boardNo }}</td>
              <td>{{ $yyyymmddHHmmss(corpMatching.createDate) }}</td>
              <td>
                <span v-if="corpMatching.matchingGb === '01'">채용 제안</span>
                <span v-else>인재 채용</span>
              </td>
              <td class="link text"><RouterLink :to="{name:'MatchingCorpDetail', params:{id: corpMatching.corpId}}">{{ corpMatching.corpName }}</RouterLink></td>
              <td class="link text"><RouterLink :to="{name:'MemberDetail', params:{id: corpMatching.corpUserId}}">{{ corpMatching.corpUserName }}</RouterLink></td>
              <td class="link text"><RouterLink :to="{name:'MemberDetail', params:{id: corpMatching.userId}}">{{ corpMatching.userName }}</RouterLink></td>
              <td>{{ corpMatching.userId }}</td>
              <td>{{ corpMatching.interests }}</td>
              <td class="link text">
                <span v-if="corpMatching.resumeId !== 0" @click="showMatchingResumeModal(corpMatching.resumeId)">이력서 보기</span>
                <span v-else @click="showUserResumeModal(corpMatching.userId)">이력서 보기</span>
              </td>
              <td>
                <span v-if="corpMatching.propId !== 0" @click="showProposalContentModal(corpMatching.propId)" class="link text">제안서 보기</span>
                <span v-else>-</span>
              </td>
              <td>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB', corpMatching.stateGb) }}</td>
              <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
              <td>
                <span v-if="corpMatching.stateGb === '01'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '02')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '02', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="gray_button md_btn"/>
                </span>
                <span v-else-if="corpMatching.stateGb === '03'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '05', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="green_button md_btn"/>
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '06')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '06', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="orange_button md_btn"/>
                </span>
                <span v-else-if="corpMatching.stateGb === '05'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '07', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="blue_button md_btn"/>
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '08', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="red_button md_btn"/>
                </span>
                <span v-else-if="corpMatching.stateGb === '06'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '05', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="green_button md_btn"/>
                </span>
                <span v-else-if="corpMatching.stateGb === '07'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '08', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="red_button md_btn"/>
                </span>
                <span v-else-if="corpMatching.stateGb === '08'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '07', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="blue_button md_btn"/>
                </span>
                <span v-else data-title="상태 변경 : ">
                  -
                </span>
              </td>
            </tr>
          </table>
          <div class="result-none" v-if="corpMatchingList.length === 0">
            진행중인 채용 건이 없습니다.
          </div>
        </div>
        <Paginate
            v-if="corpMatchingList.length > 0"
            v-model="currentPage"
            :page-count="totalPages"
            :click-handler="onSelectPage"
        />
      </div>
      <!-- e: 채용 진행 현황 -->
    </div>
  </div>
  <MypageProposalContentModal ref="proposalContentModal"/>
  <MyPageResumeModal ref="userMatchingResumeModal"/>
  <AlertModal ref="modal" :content="modalContent" />
  <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/theme";

</style>