<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import fileService from "../../../../../services/fileService";
    import insightsNewsletterService from "../../../../user/services/insights/insightsNewsletterService";
    import _ from 'lodash'

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            goBack()
        } else {
            retrieveNewsletterDetail(id)
        }
    })

    const newsletterDetail = ref({})
    const input = ref({
        title: '',
        content: '',
    })

    const inputError = ref({
        title: '',
        content: '',
    })

    const editorRef = ref(null)

    const modal = ref(null)
    const modalContent = ref([])

    const retrieveNewsletterDetail = (id) => {
        loadingStore.loading = true;
        insightsNewsletterService.retrieveNewsletterDetail(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    newsletterDetail.value = result;
                    input.value.title = result.title;
                    input.value.content = result.content;

                    setTimeout(()=>{
                        try {
                            editorRef.value.init(result.content)
                        } catch(e){}
                    }, 100)
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const update = () => {
        const { title, content } = input.value;
        let isInvalid = false;


        inputError.value = {
            title: '',
            content: '',
        };

        if(!title) {
            inputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            inputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                newsletterId: newsletterDetail.value.newsletterId,
                title,
                content,
            }

            loadingStore.loading = true;
            insightsNewsletterService.updateNewsletter(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        goBack()
                    } else {
                        modalContent.value = ["저장을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = ["저장을 실패하였습니다."];
                modal.value.show();
            });
        }
    }

    const goBack = () => {
        router.push({ name: 'CSNewsletterDetail', params: {id: newsletterDetail.value.newsletterId}});
    };
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="lecture-wrap">
                    <div class="sub-title">
                        <h3>뉴스레터 수정</h3>
                    </div>
                    <div class="table-form">
                        <p>모든 정보는 필수입력 사항입니다.</p>
                        <table class="vertical">
                            <colgroup>
                                <col width="110px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th>제목</th>
                                <td>
                                    <KSAInput v-model="input.title" :msg="inputError.title" placeholder="제목을 입력하세요."/>
                                </td>
                            </tr>
                            <tr>
                                <th>뉴스레터</th>
                                <td class="text-box">
                                    <KSACKEditor ref="editorRef" v-model="input.content" />
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.content }}</small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="btn-wrap space-between">
                        <KSASecondaryButton class="gray_button" text="취소" @click="goBack" />
                        <KSAPrimaryButton text="저장" @click="update" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    small {
        @include font-size_xs;
        color: $gray_color;
    }
</style>