<script setup>
    import { ref, defineEmits, defineExpose, toRefs, onMounted, onUnmounted, watch } from "vue";
    import signupService from "../../../pages/user/services/signup/signupService";
    import _ from 'lodash'

    const props = defineProps({
        modelValue: { type: String },
        label: {
            type: String,
            default: '학교'
        },
        placeholder: {
            type: String,
            default: '학교를 검색하세요.',
        },
        msg: {
            type: String
        },
        msgColor: {
            type: String
        },
        maxLength: {
            type: String,
            default: '30'
        },
        disabled: {
            type: Boolean,
            default: false
        },
    });

    const { modelValue, label, placeholder, msg, msgColor, maxLength, disabled } = toRefs(props);

    onMounted(()=>{
        document.body.addEventListener('click', clickEventHandler)
    })
    onUnmounted(()=>{
        document.body.removeEventListener('click', clickEventHandler)
    })

    const emit = defineEmits(["update:modelValue"]);

    const inputRef = ref(null)
    const schoolList = ref([])
    const open = ref(false)

    let timer;

    // setValue 메서드 추가
    const setValue = (value) => {
        if (inputRef.value) {
            inputRef.value.value = value; // input의 값을 직접 설정
            emit('update:modelValue', value); // 부모 컴포넌트에 값 업데이트
        }
    };

    const getSchoolList = () => {
        open.value = inputRef.value.value.length > 0;
        if(inputRef.value.value.length > 1) {
            getList();
        } else {
            schoolList.value = [];
        }
    }

    const getList = _.debounce(() => {
        schoolList.value = [];
        if(timer) clearTimeout(timer);
        signupService.searchSchool(inputRef.value.value)
            .then( (res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    schoolList.value = result || [];
                    if(schoolList.value.length === 0) {
                        timer = setTimeout(()=>{
                            open.value = false;
                        }, 2000)
                    }
                }
            }).catch( (error) =>  {
                console.log(error);
            });
    }, 500);

    const onSelectSchool = (school) => {
        open.value = false;
        emit("update:modelValue", school.schoolName + ', ' + school.campusName + ', ' + school.campusLocation);
    }

    const clickEventHandler = (event) => {
        if(!event.target.closest('.input-select')) open.value = false;
    }

    const focus = () => {
        inputRef.value.focus();
    }

    defineExpose({
        focus,
        setValue
    })
</script>

<template>
    <div style="position: relative;">
        <label>
            <p v-if="label">{{ label }}</p>
            <div class="icon-text">
                <input ref="inputRef" type="text" :value="modelValue" @input="event => emit('update:modelValue', event.target.value)" @keyup="getSchoolList" :placeholder="placeholder" :maxlength="maxLength" :disabled="disabled">
            </div>
        </label>
        <div class="list input-select" v-show="open">
            <div v-for="school in schoolList" @click="onSelectSchool(school)">
                {{ school.schoolName }}, {{ school.campusName }}, {{ school.campusLocation }}
            </div>
            <div class="none" v-show="schoolList.length === 0">일치하는 학교가 없습니다.</div>
        </div>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/ksa_input";

    label {
        p {
            @include font-size_xs;
            color: $gray_color;
        }
    }
    .icon-text {
        position: relative;
        input {
            &:disabled {
                color: $black_color;
                background: $gray-thin_color;
            }
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 12px;
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(../../../assets/images/icon-main.png);
            background-size: 200px 200px;
            background-position: -92px -52px;
            transform: translateY(-50%);
        }
    }
    .list {
        position: absolute;
        width: 100%;
        height: 150px;
        border: $border_line;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        overflow: auto;
        z-index: 2;
        margin-top: 4px;
        div {
            padding: 10px 12px;
            &:hover {
                background: $blue-light_color;
            }
            &.none {
                &:hover {
                    background: transparent;
                }
            }
        }
    }
</style>