<script setup>
    import { ref, onBeforeMount, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import { useSignupStore } from "../../stores/signup";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import SignupProgressBar from '../../../../components/signup/SignupProgressBar.vue'
    import signupService from "../../services/signup/signupService";

    const router = useRouter()
    const signupStore = useSignupStore()
    const loadingStore = usePageLoadingStore()
    const verifyCheckInputRef = ref(null)

    const { $regex } = getCurrentInstance().appContext.config.globalProperties;

    const input = ref({
        username: '',
        phone: '',
        authCode: '',
    });

    const inputError = ref({
        username: '',
        phone: '',
        authCode: '',
    });

    let phoneVerifyId;
    const isAuthenticated = ref(false);

    const modal = ref(null)
    const modalContent = ref([])
    const phoneNumInput = ref(null)

    onBeforeMount(()=> {
        if(signupStore.signupRequest.userName) {
            input.value.username = signupStore.signupRequest.userName;
        }
        if(signupStore.signupRequest.phoneNum) {
            input.value.phone = signupStore.signupRequest.phoneNum;
        }
    })

    const sendAuthCode = () => {
        const { username } = input.value;
        let isInvalid = false;

        inputError.value = {
            username: '',
            phone: '',
            authCode: '',
        }

        if(!username || !verifyUserName(username)) {
            inputError.value.username = '주민등록상의 실명(한글 본명)을 입력해 주세요.';
            isInvalid = true;
        }

        const phoneNum = phoneNumInput.value.getValue();
        input.value.phoneNum = phoneNum;
        if(!phoneNum) {
            inputError.value.phone = '핸드폰 번호를 입력하세요';
            isInvalid = true;
        } else if(phoneNum.length !== 13 || !$regex.phoneNum.test(phoneNum)){ // 영문/국문 입력시, 11자리 아닌경우
            inputError.value.phone = '핸드폰 번호를 올바르게 입력하세요';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                verifyGubun: 'signup',
                name: username,
                phoneNum: phoneNum
            }
            loadingStore.loading = true;
            signupService.phoneVerify(paramObj)
                .then( (res) => {
                    // console.log(res);
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        phoneVerifyId = result.phoneVerifyId;
                        verifyCheckInputRef.value.startTimer();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });

        }
    }

    // 이름에 한글,영어대문자만 포함되어 있는지 validation.
    const verifyUserName = (userName) => {
      const regex = /^[가-힣A-Z]+$/;
      return regex.test(userName);
    }

    const verifyCheck = () => {
        const { username, phone, authCode } = input.value;

        let isInvalid = false;

        inputError.value.authCode = '';
        if(!authCode || authCode.length !== 6) {
            input.value.authCodeMsg = '인증번호 6자리를 입력해주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                verifyGubun: "signup",
                phoneVerifyId,
                phoneNum: phone,
                verifyNum: authCode
            }
            loadingStore.loading = true;
            signupService.phoneVerifyCheck(paramObj)
                .then( async (res) => {
                    // console.log(res);
                    const { code, msg } = res.data;
                    loadingStore.loading = false;
                    if(code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        inputError.value.authCode = '인증번호를 확인하였습니다.';
                        isAuthenticated.value = true;
                        verifyCheckInputRef.value.clearTimer()
                        signupStore.signupRequest.userName = username;
                        signupStore.signupRequest.phoneNum = phone;

                    } else { // fail
                        if(msg.indexOf('PhoneVerify Number different') > -1) {
                            inputError.value.authCode = '인증번호가 올바르지 않습니다.'; // msg;
                        }
                        isAuthenticated.value = false;
                    }
                }).catch( async (res) =>  {
                    loadingStore.loading = false;
                    // console.log(res);
                    const msg = res.response.data.msg || '';
                    modalContent.value = msg.split("\n");
                    const ok = await modal.value.show();
                    if(ok) {
                        router.push({name: 'Signin'})
                    }
            });
        }
    }

    const onUpdateErrorMsg = (value) => {
        inputError.value.authCode = value;
    }

    const next = () => {
        router.push({name: 'SignupMemberType'})
    }
</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <SignupProgressBar :activeIndex="0"/>
            <div class="sign-content">
                <div class="txt">
                  - 회원가입 시 반드시 <span style="color:red;">실명(본명)</span>으로 입력해 주세요.<br>
                  - <span style="color:red;">실명(본명)이 아닐 경우</span> 수강신청, 수료증발급, 채용지원시스템, 강의등록 등<br>
                  &nbsp;&nbsp;<span style="color:red;">  정상적인 서비스 이용이 불가능합니다</span>.
                </div>
                <KSAInput class="text-box" v-model="input.username" label="이름" placeholder="주민등록상의 실명(한글 본명)을 입력해 주세요. ex)홍길동" :msg="inputError.username" msg-color="red" max-length="16"/>
                <KSAInputPhoneNum ref="phoneNumInput" class="text-box" v-model="input.phone" :msg="inputError.phone"/>
                <KSASecondaryButton class="full-btn" text="인증번호 전송" @click="sendAuthCode" />

                <KSAInputVerifyCheck ref="verifyCheckInputRef" label="인증번호" v-model="input.authCode" @update:msg="onUpdateErrorMsg" :msg="inputError.authCode" :msg-color="isAuthenticated && 'black'"/>
                <KSASecondaryButton v-if="!isAuthenticated" class="full-btn" text="인증번호 확인" @click="verifyCheck" :disabled="input.authCode.length !== 6"/>

                <KSAPrimaryButton class="full-btn" text="다음" :disabled="!isAuthenticated" @click="next" />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style scoped>

</style>