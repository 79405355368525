// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, watch } from 'vue'
import { useUserStore } from "../pages/user/stores/user";
import http from "../api/http";

export const useSiteMenuStore = defineStore('siteMenu', () => {
    const menuList = ref([])

    const siteMenuInStorage = sessionStorage.getItem('siteMenu')
    if (siteMenuInStorage) {
        menuList.value = JSON.parse(siteMenuInStorage)
    }

    watch(() => menuList, (state) => {
        sessionStorage.setItem('siteMenu', JSON.stringify(menuList.value))
    }, { deep: true })

    const getSiteMenu = () => {
        const { user } = useUserStore();
        http.get(`/system/menu/retrieveAll?loginYn=${ user.userCd ? 'Y' : 'N'}`)
            .then((res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    setMenuList(result);
                }
            })
            .catch( (error) =>  {
                console.log(error);
            });
    }

    // setUser simple function
    function setMenuList (list) {
        menuList.value = list;
    }

    return {
        menuList,
        setMenuList,
        getSiteMenu
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSiteMenuStore, import.meta.hot))
}
