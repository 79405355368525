<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, computed, onMounted, defineExpose } from "vue";
    import _ from "lodash";
    import adminUserService from "../../../services/user/adminUserService";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);

        retrieveList();
    })

    const searchInputs = ref({
        userGbList: ["STUDENT","COMPANY","SCHOOL","TEACHER"],
        searchType: 'name',
        searchInput: '',
    })

    const list = ref([])
    const selectedUserList = ref([])

    const currentPage = ref(1);
    const size = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    let disabledWatchIsSelectedAll = false;
    let disabledWatchList = false;

    const isAllCheckedLeft = ref(false);
    const isAllCheckedRight = ref(false);

    watch(isAllCheckedLeft, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(list.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            list.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })
    watch(list, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isAllCheckedLeft.value = true;
            } else {
                if(isAllCheckedLeft.value) {
                    disabledWatchIsSelectedAll = true;
                    isAllCheckedLeft.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    watch(isAllCheckedRight, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(selectedUserList.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            selectedUserList.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })
    watch(selectedUserList, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isAllCheckedRight.value = true;
            } else {
                if(isAllCheckedRight.value) {
                    disabledWatchIsSelectedAll = true;
                    isAllCheckedRight.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    const isActiveMoveRight = computed(()=>{
        const checkedList = _.filter(list.value, { checked: true});
        return checkedList && checkedList.length > 0;
    })

    const isActiveMoveLeft = computed(()=>{
        const checkedList = _.filter(selectedUserList.value, { checked: true});
        return checkedList && checkedList.length > 0;
    })


    const userGbGroup = ref([
        { label:"학생/취업준비생", value: "STUDENT" },
        { label:"재직자/기업", value: "COMPANY" },
        // { label:"재직자/기업-마스터", value: "COMPANY_MASTER" },
        { label:"대학/연구실", value: "SCHOOL" },
        { label:"강사/전문가", value: "TEACHER" },
    ]);

    const searchTypeGroup = ref([
        { label:"이름", value: "name", placeholder: "이름을 입력해주세요."  },
        { label:"회원코드", value: "code", placeholder: "회원코드를 입력해주세요." },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        // { label:"이메일", value: "email", placeholder: "이메일을 입력해주세요."  },
    ]);

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: searchInputs.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const retrieveList = () => {
        let paramObj = {
            userGbList: searchInputs.value.userGbList,
            searchType: searchInputs.value.searchType,
            searchInput: searchInputs.value.searchInput,
            // searchDurationGb: searchInputs.value.searchDurationGb,
            // searchDurationType: searchInputs.value.searchDurationType,
            // startYmd: searchInputs.value.searchDurationType === 'A' ? "" : searchInputs.value.startYmd,
            // endYmd: searchInputs.value.searchDurationType === 'A' ? "" : searchInputs.value.endYmd,
            pageNo: currentPage.value,
            size: size.value,
        }

        loadingStore.loading = true;
        adminUserService.retrieveAllUsers(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    list.value = result.content;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectSearchType = (option) => {
        searchInputs.value.searchType = option.value;
    }

    const onSelectPageSize = (option) => {
        size.value = option.value;
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const moveRight = () => {
        const checkedList = _.cloneDeep(_.filter(list.value, {checked: true}));
        _.each(checkedList, user => {
            user.checked = false
        });

        const newList = [...selectedUserList.value, ...checkedList];
        selectedUserList.value = _.uniqBy(newList, 'userCd')
    }

    const moveLeft = () => {
        const unCheckedList = _.filter(selectedUserList.value, (user) => {
            return !user.checked;
        });
        selectedUserList.value = unCheckedList;
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        const result = {
            names: '',
            userIds: [],
        };

        if(selectedUserList.value.length > 0) {
            result.names = `${_.minBy(selectedUserList.value, 'userCd').userName}${selectedUserList.value.length > 1 ? (' 외 ' + (selectedUserList.value.length-1) + '명') : ''}`;
            result.userIds = _.map(selectedUserList.value, 'userId');

            baseModal.value.close();
            resolvePromise.value(result);
        } else {
            baseModal.value.close();
            resolvePromise.value(result);
        }
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    defineExpose({
        show,
    })
</script>

<template>
    <Modal ref="baseModal">
        <div class="modal-inner xxl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>받는 사람 선택</h3>
            </div>
            <div class="modal-body">
                <div class="filter-wrap open">
                    <div class="filter">
                        <dl>
                            <dt>유형</dt>
                            <dd>
                                <KSACheckBoxGroup
                                    className="mr-md"
                                    name="userGbList"
                                    v-model="searchInputs.userGbList"
                                    :options="userGbGroup" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect :options="searchTypeGroup" class="select" @selectOption="onSelectSearchType"/>
                            </dt>
                            <dd>
                                <KSAInput class="text-box" v-model="searchInputs.searchInput" :placeholder="searchInputPlaceholder" />
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <!-- s: 검색 결과 -->
                    <div class="item">
                        <div class="list-top">
                            <p>검색 결과 (<span>{{ $number(totalCount)}}</span>)</p>
                            <div>
                                <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                            </div>
                        </div>
                        <div class="table-form">
                            <div class="scroll">
                                <table class="horizontal">
                                    <thead>
                                        <tr>
                                            <th><KSACheckBox v-model="isAllCheckedLeft" /></th>
                                            <th>유형</th>
                                            <th>회원코드</th>
                                            <th>이름</th>
                                            <th>아이디</th>
                                            <th>이메일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in list">
                                            <td><KSACheckBox v-model="user.checked" /></td>
                                            <td>{{ $userGbKR(user.userGb) }}</td>
                                            <td>{{ user.userCd }}</td>
                                            <td>{{ user.userName }}</td>
                                            <td>{{ user.userLoginId }}</td>
                                            <td>{{ user.email }}</td>
                                        </tr>
                                        <tr v-if="list.length === 0">
                                            <td colspan="6">검색 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Paginate
                            v-if="list.length > 0"
                            v-model="currentPage"
                                :page-count="totalPages"
                                :click-handler="onSelectPage"
                        />
                    </div>
                    <!-- e: 검색 결과 -->

                    <div class="control">
                        <div class="left" :class="{'active': isActiveMoveRight}" @click="moveRight"><i></i></div>
                        <div class="right" :class="{'active': isActiveMoveLeft}" @click="moveLeft"><i></i></div>
                    </div>

                    <!-- s: 받는 사람 -->
                    <div class="item">
                        <div class="list-top">
                            <p>받는 사람 (<span>{{ $number(selectedUserList.length) }}</span>)</p>
                        </div>
                        <div class="table-form">
                            <div class="scroll">
                                <table class="horizontal">
                                    <thead>
                                        <tr>
                                            <th><KSACheckBox class="checkbox" v-model="isAllCheckedRight" /></th>
                                            <th>유형</th>
                                            <th>회원코드</th>
                                            <th>이름</th>
                                            <th>아이디</th>
                                            <th>이메일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in selectedUserList">
                                            <td><KSACheckBox v-model="user.checked" /></td>
                                            <td>{{ $userGbKR(user.userGb) }}</td>
                                            <td>{{ user.userCd }}</td>
                                            <td>{{ user.userName }}</td>
                                            <td>{{ user.userLoginId }}</td>
                                            <td>{{ user.email }}</td>
                                        </tr>
                                        <tr v-if="selectedUserList.length === 0">
                                            <td colspan="6">추가된 사용자가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- e: 받는 사람 -->
                </div>
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" text="선택" @click="confirm" />
            </div>
        </div>
    </Modal>
</template>

<style lang="scss">
    .modal-inner {
        .modal-body {
            .grid {
                .item {
                    .check-box {
                        padding-left: 20px;
                        .checkmark {
                            top: 0;
                        }
                    }
                }
            }
        }
    }

</style>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";
    @import "../../../../../assets/scss/admin";

    .modal-inner {
        .modal-body {
            .grid {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .item {
                    width: calc(100% / 2 - 40px);
                    .table-form {
                        border-bottom: $border_line;
                        margin-bottom: 0;
                        .scroll {
                            border: $border_line;
                            border-bottom: 0;
                            overflow-y: auto;
                            overflow-x: hidden;
                            height: 288px;
                            thead {
                                position: sticky;
                                top: -1px;
                                z-index: 1;
                            }
                        }
                        table {
                            border-top: 0;
                            width: 100%;
                            @include font-size_xs;
                            tr {
                                th,td {
                                    padding: 8px;
                                }
                            }
                        }
                    }
                    .pagination {
                        margin: 20px auto;
                    }
                }
                .control {
                    background: #fff;
                    margin-top: 160px;
                    div {
                        position: relative;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        border: $border_line;
                        margin-bottom: 10px;
                        background-color: $gray-thin_color;
                        i {
                            display: block;
                            width: 9px;
                            height: 16px;
                            background-image: url(/src/assets/images/icon-main.png);
                            background-size: 200px 200px;
                            margin: auto;
                        }
                        &.left {
                            border-left: $border_line;
                            border-radius: 4px 0 0 4px;
                            i {
                                background-position: -112px -26px;
                                -webkit-transform: rotate(-180deg);
                                transform: rotate(-180deg);
                            }
                            &.active {
                                background-color: #fff;
                                cursor: pointer;
                                i {
                                    background-position: -132px -26px;
                                    -webkit-transform: rotate(0deg);
                                    transform: rotate(0deg);
                                }
                            }
                        }
                        &.right {
                            margin-bottom: 0;
                            i {
                                background-position: -112px -26px;
                            }
                            &.active {
                                background-color: #fff;
                                cursor: pointer;
                                i {
                                    background-position: -132px -26px;
                                    -webkit-transform: rotate(-180deg);
                                    transform: rotate(-180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

</style>