import moment from 'moment'
import _ from 'lodash'
import fileService from "../services/fileService";

export default {
    install: (app, options) => {
        app.config.globalProperties.$userGbKR = (userGb) => {
            switch (userGb) {
                case '01':
                case 'STUDENT':
                    return "학생/취업준비생";
                case '02':
                case 'COMPANY':
                    return "재직자/기업";
                case '03':
                case 'SCHOOL':
                    return "대학/연구실";
                case '04':
                case 'TEACHER':
                    return "강사/전문가";
                case '91':
                case 'SUPER_ADMIN':
                    return "슈퍼 관리자";
                case '92':
                case 'ADMIN':
                    return "관리자";
                case '99':
                case 'SYSTEM_ADMIN':
                    return "시스템 관리자";
                case 'ALL':
                    return "전체";
                default:
                    return userGb;
            }
        }
        app.config.globalProperties.$gbNameKR = (gbName, gbValue) => {
            if(!gbName || !gbValue) return '-';
            const gb  = app.config.globalProperties.$gb;
            const targetGbGroup = gb[gbName];
            if(targetGbGroup) {
                const result = _.find(targetGbGroup, { value: gbValue});
                return result ? result.label : gbValue;
            } else {
                return gbValue;
            }
        }
        app.config.globalProperties.$phoneNum = (phoneNum) => { // 핸드폰 번호
            if(!phoneNum) return '-';
            else if(phoneNum.length !== 11) return phoneNum;
            else return phoneNum.substring(0,3) + '-' + phoneNum.substring(3,7) + '-' + phoneNum.substring(7);
        }
        app.config.globalProperties.$bizNum = (bizNum) => { // 사업자등록번호
            if(!bizNum) return '-';
            else if(bizNum.length !== 10) return bizNum;
            else return bizNum.substring(0,3) + '-' + bizNum.substring(3,5) + '-' + bizNum.substring(5);
        }
        app.config.globalProperties.$corpNum = (corpNum) => { // 법인등록번호
            if(!corpNum) return '-';
            else if(corpNum.length !== 13) return corpNum;
            else return corpNum.substring(0,6) + '-' + corpNum.substring(6);
        }
        app.config.globalProperties.$dateToWeekDay = (date) => {
            const WEEKDAYS = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
            return WEEKDAYS[moment(date).day()];
        }
        app.config.globalProperties.$yyyymm = (yyyymm) => {
            if(!yyyymm) {
                return '-';
            } else if (yyyymm.length < 6) {
                return yyyymm;
            } else {
                const result = moment(yyyymm).format('YYYY.MM');
                if(result === 'Invalid date') {
                    return `${yyyymm.substring(0,4)}.${yyyymm.substring(4)}`;
                } else {
                    return result;
                }
            }
        }
        app.config.globalProperties.$yyyymmdd = (yyyymmdd) => {
            if(!yyyymmdd) {
                return '-';
            } else if (yyyymmdd.length < 8) {
                return yyyymmdd;
            } else {
                const result = moment(yyyymmdd).format('YYYY.MM.DD');
                if(result === 'Invalid date') {
                    return `${yyyymmdd.substring(0,4)}.${yyyymmdd.substring(4)}`;
                } else {
                    return result;
                }
            }
        }
        app.config.globalProperties.$yyyymmddHHmm = (date) => {
            if(!date) {
                return '-';
            } else {
                return moment(date).format('YYYY.MM.DD HH:mm');
            }
        }
        app.config.globalProperties.$yyyymmddHHmmss = (date) => {
            if(!date) {
                return '-';
            } else {
                return moment(date).format('YYYY.MM.DD. HH:mm:ss');
            }
        }
        app.config.globalProperties.$yyyymmddOrHHmm = (date) => {
            if(!date) {
                return '-';
            }else if (date.length < 8) {
                return date;
            }

            const now = moment();
            const newDate = moment(date);
            if(now.format('YYYYMMDD') !== newDate.format('YYYYMMDD')) {
                return newDate.format('YYYY.MM.DD');
            }  else {
                return newDate.format('HH:mm');

            }
        }
        app.config.globalProperties.$number = (number) => {
            if(number === undefined || number === null) return '';
            return Intl.NumberFormat().format(number);
        }
        app.config.globalProperties.$numberZero = (number) => {
            if(number === undefined || number === null) return '0';
            return Intl.NumberFormat().format(number);
        }
        app.config.globalProperties.$fileSize = (size) => {
            return app.config.globalProperties.$number((size / (1024)).toFixed(2)) + 'kb';
        }
        app.config.globalProperties.$movPlayHm = (movPlayHm) => { // movPlayHm = 00:00:00
            if(movPlayHm === undefined || movPlayHm === null) return '';
            const temp = movPlayHm.split(":");
            if(temp.length !== 3) return movPlayHm;
            else {
                let arr = ['', '', ''];
                if(temp[0] !== "00") {
                    const hh = temp[0][0] === '0' ? temp[0][1] : temp[0];
                    arr[0] = `${hh}시간`;
                }
                if(temp[1] !== "00") {
                    const hh = temp[1][0] === '0' ? temp[1][1] : temp[1];
                    arr[1] = `${hh}분`;
                }
                if(temp[2] !== "00") {
                    const hh = temp[2][0] === '0' ? temp[2][1] : temp[2];
                    arr[2] = `${hh}초`;
                }
                return arr.join('');
            }
        }
        app.config.globalProperties.$download = (fileDir) => { // 파일다운로드
            if(fileDir === undefined || fileDir === null) return '';
            const a = document.createElement('a');
            a.href = fileDir;
            a.target = '_blank';
            a.click();
        }
        app.config.globalProperties.$downloadForPrivate = (attach) => { // private 파일다운로드
            const { fileDir, fileName } = attach;

            if(!fileDir || !fileName) {
                return false;
            }

            let paramObj = {
                keyName: fileDir,
                originalFilename: fileName,
            };

            fileService.downloadFile(paramObj)
                .then( async (res) => {
                    // console.log(res);
                    try {
                        const href = URL.createObjectURL(res.data);
                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', fileName); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    } catch(e) {
                        console.log(e);
                    }
                }).catch( (error) =>  {
                // console.log(error);
            });
        }
        app.config.globalProperties.$pageListBoardNo = (list, totalCount, pageSize, currentPage) => { // 페이징처리된 목록의 게시물번호
            _.each(list, (el, index) => {
                el.boardNo = (totalCount - (pageSize*(currentPage-1))) - index;
            });
            return list || [];
        }
        app.config.globalProperties.$pageListPorpId = (list, totalCount, pageSize, currentPage) => { // 페이징처리된 목록의 게시물번호
            _.each(list, (el, index) => {
                el.propId = (totalCount - (pageSize*(currentPage-1))) - index;
            });
            return list || [];
        }
        app.config.globalProperties.$pageListUserId = (list, totalCount, pageSize, currentPage) => { // 페이징처리된 목록의 게시물번호
            _.each(list, (el, index) => {
                el.userId = (totalCount - (pageSize*(currentPage-1))) - index;
            });
            return list || [];
        }

    }
}