<script setup>
    import { ref, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import SNB from '../../../../layouts/nav/SNB.vue'
    import mypageUserService from "../../services/mypage/mypageUserService";
    import { usePageLoadingStore } from "../../../../stores/pageLoading";

    const { $toast } = getCurrentInstance().appContext.config.globalProperties;

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    // let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    let reg = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    const input = ref({
        oldPassword: '',
        password: '',
        passwordVerify: '',
    })

    const inputError = ref({
        oldPassword: '',
        password: '',
        passwordVerify: '',
    })

    const changePassword = () => {
        const { oldPassword, password, passwordVerify } = input.value;
        let isInvalid = false;

        inputError.value.oldPassword = '';
        inputError.value.password = '';
        inputError.value.passwordVerify = '';

        if(!oldPassword) {
            inputError.value.oldPassword = '현재 비밀번호를 입력하세요.';
            isInvalid = true;
        }
        if(!password || !reg.test(password)) {
            inputError.value.password = '대/소문자,숫자,기호를 사용하여 8자 이상 입력하세요.';
            isInvalid = true;
        }
        if(!passwordVerify) {
            inputError.value.passwordVerify = '새 비밀번호를 입력해 주세요.';
            isInvalid = true;
        } else if(password !== passwordVerify) {
            inputError.value.passwordVerify = '입력한 값이 새 비밀번호와 일치하지 않습니다. 다시 입력해 주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                oldUserLoginPw: oldPassword,
                userLoginPw: password,
                userLoginPwVerify: passwordVerify,
            }
            loadingStore.loading = true;
            mypageUserService.changePassword(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', "변경하였습니다.")

                        input.value.oldPassword = '';
                        input.value.password = '';
                        input.value.passwordVerify = '';
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content">
                    <div class="sub-title">
                        <h3>내 정보</h3>
                    </div>
                    <div class="sign-wrap">
                        <div class="sign-inner">
                            <div class="sign-content">
                                <KSAInput class="text-box" v-model="input.oldPassword" label="현재 비밀번호" placeholder="현재 사용중인 비밀번호를 입력하세요." type="password" max-length="30" :msg="inputError.oldPassword" />
                                <KSAInput class="text-box" v-model="input.password" label="새 비밀번호" placeholder="비밀번호를 입력하세요." type="password" max-length="30" :msg="inputError.password" />
                                <KSAInput class="text-box" v-model="input.passwordVerify" label="새 비밀번호 확인" placeholder="비밀번호를 입력하세요." type="password" :msg="inputError.passwordVerify" :msg-color="input.passwordVerify && input.password === input.passwordVerify ? 'black' : 'red'" />
                            </div>

                            <div class="btn-wrap">
                                <KSASecondaryButton class="full-btn" text="취소" @click="router.push({name:'MyPageProfile'})" />
                                <KSAPrimaryButton class="full-btn" text="변경" @click="changePassword" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .sign-wrap {
        padding: 0;
    }
</style>