<template>
    <Modal ref="baseModal">
        <div class="modal-inner">
            <div class="modal-body">
                <h3>수강 신청이 완료되었습니다.</h3>
                <p v-if="lecture.ticketOfferGb !== '01'">선발 후 안내 예정입니다.<br>
                  별도 연락을 드리겠습니다.</p>
                <div class="box">
                    <dl>
                        <dt>강의명</dt>
                        <dd>{{ lecture.lectName }}</dd>
                    </dl>
                    <dl v-if="lecture.ticketOfferGb !== '01'">
                        <dt>신청일</dt>
                        <dd>{{ $yyyymmddHHmmss(regDt) }}</dd>
                    </dl>
                    <dl>
                        <dt>교육 기간</dt>
                        <dd>
                            <span v-if="lecture.lectTermGb === '01'">신청일로부터 {{ lecture.lectTerm }}일</span>
                            <span v-else>{{ $yyyymmdd(lecture.lectStartYmd) }} ~ {{ $yyyymmdd(lecture.lectEndYmd) }}</span>
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="modal-footer">
                <KSASecondaryButton class="full-btn" text="계속 구경하기" @click="cancel" />
                <KSAPrimaryButton class="full-btn" text="내 강의실로 이동하기" @click="confirm" />
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, toRefs, getCurrentInstance } from "vue";
import { useRouter } from 'vue-router'
import {useUserStore} from "../../../stores/user";
import {useMyPageLectDetailStore} from "../../../stores/mypage/mypageLectDetailStore";
import _ from 'lodash';

export default {
    name: "LectureRegCompleteModal",
    components: {
        Modal,
    },
    props: {
        lecture: Object,
    },
    setup(props) {
        const { $yyyymmddHHmmss } = getCurrentInstance().appContext.config.globalProperties;
        const baseModal = ref(null);
        const resolvePromise = ref(null);
        const regDt = ref(null);
        const { lecture } = toRefs(props);
        const { user } = useUserStore()
        const lectureStore = useMyPageLectDetailStore()

        const router = useRouter()

        const show = () => {
            baseModal.value.open();
            return new Promise((resolve, _) => {
                resolvePromise.value = resolve;
            });
        };

        const confirm = () => {
            baseModal.value.close();
            resolvePromise.value(true);
            //router.push({name: 'MyPageLecture'});
            const newLecture = _.cloneDeep(lecture.value);
            newLecture.regDt = regDt.value;
            newLecture.userId = user.userCd;
            lectureStore.setLecture(newLecture)
            router.push({name: 'MyPageLectureDetail', params:{id: lecture.value.lectId}})
        };

        const cancel = () => {
            baseModal.value.close();
            resolvePromise.value(false);
        };

        const init = (date) => {
          regDt.value = date;
        };

        return { baseModal, show, confirm, cancel, init, regDt, $yyyymmddHHmmss, lecture };
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.modal-inner {
    width: 564px !important;
    padding: 60px 30px !important;
    .modal-body {
        h3 {
            font-size: $font-size_xl !important;
            line-height: $line-height_lg !important;
        }
        .box {
            margin-top: 20px;
            padding: 20px;
            background: $gray-thin_color;
            dl {
                display: flex;
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
                dt {
                    color: $gray_color;
                    width: 104px;
                    text-align: left;
                }
                dd {
                    width: calc(100% - 104px);
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }
}
</style>