<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, defineExpose } from "vue";
import { useRouter } from 'vue-router'

import mypageUserResumeService from "../../../services/mypage/mypageUserResumeService";
import { usePageLoadingStore } from "../../../../../stores/pageLoading";
import UserMatchingResumeCreateModal from "../mypage/UserMatchingResumeCreateModal.vue";

const router = useRouter();
const loadingStore = usePageLoadingStore()

const baseModal = ref(null);
const resolvePromise = ref(null);

const loaded = ref(false)
const content = ref(null);

const confirmModal = ref(null);
const userMatchingResumeCreateModal = ref(null);
const modalContent = ref([]);
const okButtonText = ref("동의");
const cancelButtonText = ref("동의하지 않음");

const show = () => {
  baseModal.value.open();
  return new Promise((resolve, _) => {
    resolvePromise.value = resolve;
  });
};

const cancel = () => {
  baseModal.value.close();
  resolvePromise.value(false);
  localStorage.setItem('isModalVisible', 'false');
};

defineExpose({
  show,
})
// 이력서 작성 모달 실행
const showUserMatchingResumeCreateModal = async () => {
  userMatchingResumeCreateModal.value.init();
  const result = await userMatchingResumeCreateModal.value.show();
  cancel();
}

// 제3자 정보 제공 동의처리 의사 확인
const confirmAgreeInfoProvYnChange = async () => {
  modalContent.value = ["개인정보 제공에 동의하나요?", "동의하지 않을 시 이력서 작성 후 전송을 할 수 없습니다."];
  okButtonText.value = "동의";
  cancelButtonText.value = "동의하지 않음";
  const ok = await confirmModal.value.show();
  if ( ok ) { // 동의
    changeAgreeInfoProvYn();
  } else {    // 동의하지 않음
  }
}

// 제3자 정보 제공 동의여부 변경
const changeAgreeInfoProvYn = () => {
  loadingStore.loading = true;

  let paramObj = {
    agreeInfoProvYn: "Y",
  }

  mypageUserResumeService.changeAgreeInfoProvYnState(paramObj)
      .then( (res) => {
        loadingStore.loading = false;
        if(res.data.code.toLowerCase() === 'success') {
          let result = res.data.result;
          console.log(result);
          showUserMatchingResumeCreateModal();
        }
      }).catch( (error) =>  {
    loadingStore.loading = false;
    console.log(error);
  });
}

// 이력서 작성 버튼
const onClickCreateUserMatchingResume = async () => {
  localStorage.setItem('isModalVisible', 'false');
  mypageUserResumeService.retrieveAgreeInfoProvYn()
      .then( (res) => {
        if ( res.data.result.agreeInfoProvYn == "Y" ) {
          showUserMatchingResumeCreateModal();
        } else {
          confirmAgreeInfoProvYnChange();
        }
      }).catch( (res) =>  {
    $toast('error', '오류가 발생하였습니다.');
  });
}
</script>

<template>
  <Modal ref="baseModal" class="vld-parent">
    <div class="modal-inner m-size">
      <button class="close" @click="cancel"></button>
      <div class="modal-body">
        <h1>실시간 채용 지원 시스템</h1>
        <p>맞춤형 매칭 서비스를 이용해보세요.<br>
          <strong>기업으로부터 상시 채용 제안을 받을 수 있습니다.</strong></p>
        <div class="vertical-process-container">
          <div class="step first">
            <div>이력서 작성 / 전송</div>
          </div>
          <div class="step">
            <div>인재 정보 등록 / 노출</div>
          </div>
          <div class="step important">
            <div>[기업]<br>실시간 이력서 확인 / 채용 제안</div>
          </div>
          <div class="step last">
            <div>매칭 완료</div>
          </div>
        </div>
      </div>
      <div class="modal-footer btn-wrap">
        <button class="full-btn" @click="onClickCreateUserMatchingResume()">입사 지원하기</button>
      </div>
    </div>
  </Modal>
  <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" :cancelButtonText="cancelButtonText" />
  <UserMatchingResumeCreateModal ref="userMatchingResumeCreateModal"/>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
  margin-top: 50px;
  .close {
    top: -20px !important;
    right: -10px !important;;
  }
  .modal-footer {
    display: block;
    padding: 0 30px;
  }
}
</style>