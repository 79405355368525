// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useSysAdminMenuStore = defineStore('sysAdminMenuStore', () => {

    const menuList = ref([
        {
            menuName: '스케쥴러 관리',
            menuUrl: '/sysadmin/scheduler',
            open: false,
            child: [],
        },
    ])
    return {
        menuList,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSysAdminMenuStore, import.meta.hot))
}
