// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useLectureStore = defineStore('lectureStore', () => {
    const lectGb = ref(null)
    const lectId = ref(null)

    return {
        lectGb,
        lectId,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLectureStore, import.meta.hot))
}
