<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import insightsFAQService from "../../../../user/services/insights/insightsFAQService";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";

    const route = useRoute();
    const router = useRouter();
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name: 'CSNotifyList'})
        } else {
            retrieveDetail(id)
        }
    })

    const input = ref({
        boardGroup: '',
        title: '',
        content: '',
    })

    const inputError = ref({
        title: '',
        content: '',
    })

    const boardGroupRef = ref(null)
    const editorRef = ref(null)

    const modal = ref(null)
    const modalContent = ref([])

    const retrieveDetail = (id) => {
        loadingStore.loading = true;
        insightsFAQService.retrieveDetail(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    input.value.boardGroup = result.boardGroup;
                    input.value.title = result.title;
                    input.value.content = result.content

                    boardGroupRef.value.initSelectedOption(result.boardGroup)
                    editorRef.value.init(result.content);
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            // console.log(error);
        });
    }

    const onSelectBoardGroup = (option) => {
        input.value.boardGroup = option.value;
    }

    const update = () => {
        const { boardGroup, title, content } = input.value;
        let isInvalid = false;

        inputError.value = {
            title: '',
            content: '',
        };

        if(!title) {
            inputError.value.title = '자주하는 질문을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            inputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }


        if(!isInvalid) {
            const { id } = route.params;
            let paramObj = {
                boardId: id,
                boardGroup,
                title,
                content,
            }

            loadingStore.loading = true;
            insightsFAQService.updateFAQ(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        goBack();
                    } else {
                        modalContent.value = ["저장을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = ["저장을 실패하였습니다."];
                modal.value.show();
            });
        }
    }

    const goBack = () => {
        const { id } = route.params;
        router.push({name: 'CSFAQDetail', params:{id}})
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>FAQ 작성</h3>
                </div>
                <div class="table-form">
                    <p>모든 정보는 필수입력 사항입니다.</p>
                    <table class="vertical">
                        <colgroup>
                            <col width="200px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>구분</th>
                            <td>
                                <KSASelect ref="boardGroupRef" :options="$gb['BOARD_GROUP_FAQ']" class="select" @selectOption="onSelectBoardGroup"/>
                            </td>
                        </tr>
                        <tr>
                            <th>자주하는 질문</th>
                            <td>
                                <KSAInput v-model="input.title" :msg="inputError.title" placeholder="자주하는 질문을 입력하세요."/>
                            </td>
                        </tr>
                        <tr>
                            <th>답변</th>
                            <td>
                                <KSACKEditor ref="editorRef" v-model="input.content"/>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.answer }}</small>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="취소" @click="goBack" />
                    <KSAPrimaryButton text="저장" @click="update" />
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .textarea-box {
        height: 300px;
    }

</style>