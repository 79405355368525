<script setup>
    import { ref, onMounted, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import { useUserStore } from "../../pages/user/stores/user"
    import _ from 'lodash'
    import {useSiteMenuStore} from "../../stores/sitemenu";

    const route = useRoute()
    const siteMenuStore = useSiteMenuStore();
    const { user, isAuthenticated } = useUserStore();

    const accessMenu = user.accessMenu;

    onMounted(()=>{
        // console.log(menuList.value);
    })

    const menuList = computed(()=> {
        return _.filter(siteMenuStore.menuList, { menuAreaGb: '03'});
    })

    const onClickMenu = (e, menu) => {
        if(e.target.closest('.sub-title') === null) {
            _.each(menuList.value, m => {
                if(m.menuUrl === menu.menuUrl) {
                    m.open = !m.open;
                } else {
                    m.open = false;
                }
            })
        }
    }
    const isActiveMenu = (menu) => {
        return route.fullPath.indexOf(menu.menuUrl) > -1;
    }

    const isDisplayMenu = (menu) => {
        if ( menu.menuName === '회원 관리' ) {
            return accessMenu.indexOf('us') < 0;

        } else if ( menu.menuName === '강의 관리' ) {
            return accessMenu.indexOf('lt') < 0;

        } else if ( menu.menuName === '수강 관리' ) {
            return accessMenu.indexOf('cr') < 0;

        } else if ( menu.menuName === '관리자 관리' ) {
            return accessMenu.indexOf('am') < 0;

        } else if ( menu.menuName === '사이트 관리' ) {
            return accessMenu.indexOf('st') < 0;

        } else if ( menu.menuName === '메일/메시지 관리' ) {
            return accessMenu.indexOf('mg') < 0;

        } else if ( menu.menuName === '고객센터' ) {
            return accessMenu.indexOf('cs') < 0;

        } else if ( menu.menuName === '인재채용현황' ) {
            return accessMenu.indexOf('hr') < 0;

        } else if ( menu.menuName === '장비수용현황' ) {
            return accessMenu.indexOf('eq') < 0;

        } else if ( menu.menuName === '통계자료' ) {
            return accessMenu.indexOf('sc') < 0;

        } else {
            return accessMenu.indexOf('noresult') < 0;

        }
    }
</script>

<template>
    <div>
        <ul>
            <li :class="{'sub': menu.child.length > 0, 'on': isActiveMenu(menu), 'open': menu.open, 'displaynone': isDisplayMenu(menu)}" v-for="menu in menuList" @click="onClickMenu($event, menu)"> <!-- 1차 메뉴 -->
                <RouterLink v-if="menu.child.length === 0" :to="menu.menuUrl">{{ menu.menuName }}</RouterLink>
                <span v-if="menu.child.length > 0">{{ menu.menuName }}</span>
                <ul v-show="menu.open && menu.child.length > 0">
                    <li class="sub-title sub" :class="{'on': isActiveMenu(menu2)}" v-for="menu2 in menu.child"> <!-- 2차 메뉴 -->
                        <RouterLink v-if="menu2.child.length === 0" :to="menu2.menuUrl">{{ menu2.menuName }}</RouterLink>
                        <span v-if="menu2.child.length > 0"><RouterLink :to="menu2.menuUrl">{{ menu2.menuName }}</RouterLink></span>
                        <p :class="{'on': isActiveMenu(menu3)}" v-for="menu3 in menu2.child" > <!-- 3차 메뉴 -->
                            <RouterLink :to="menu3.menuUrl">- {{ menu3.menuName }}</RouterLink>
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    .snb {
        float: left;
        width: 220px;
        min-height: calc(100vh - 140px);
        border-right: $border_line;
        ul {
            li {
                @include font-size_md;
                border-bottom: $border_line;
                padding: 16px 30px;
                &.on {
                    color: $blue_color;
                    font-weight: 700;
                }
                &.open {
                    &.sub {
                        span {
                            &:before {
                                top: 45% !important;
                                -webkit-transform: translateY(-50%) rotate(225deg);
                                transform: translateY(-50%) rotate(225deg);
                            }
                        }
                    }
                }
                &.sub {
                    padding: 0;
                    span {
                        position: relative;
                        display: block;
                        padding: 16px 30px;
                        cursor: pointer;
                        &:before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-top: 1px solid #000000;
                            border-left: 1px solid #000000;
                            -webkit-transform: translateY(-50%) rotate(135deg);
                            transform: translateY(-50%) rotate(135deg);
                            transition-duration: 0.2s;
                        }
                    }
                }
                ul {
                    border: 0;
                    border-top: $border_line;
                    background: $gray-thin_color;
                    li {
                        @include font-size_sm;
                        color: $black_color;
                        font-weight: 400;
                        border: 0;
                        padding: 10px 0 10px 40px;
                        &.on {
                            font-weight: 400;
                        }
                        &.sub-title {
                            &.sub {
                                padding: 10px 0 10px 40px;
                                &.on {
                                    span {
                                        font-weight: 700;
                                    }
                                    p {
                                        display: block;
                                        &.on {
                                            color: $blue_color;
                                        }
                                    }
                                }
                                span {
                                    padding: 0;
                                    &:before {
                                        display: none;
                                    }
                                }
                                p {
                                    display: none;
                                    color: initial;
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>