<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>원장님 소개</h3>
            </div>
            <div class="director-wrap">
                <div class="img">
                    <div>
                        <img src="/src/assets/images/ksa_ceo02.jpg" alt="2대 원장 홍성주" style="width: 418px;"/>
                    </div>
                    <p><span>2대 원장 홍성주</span></p>
                </div>
                <div class="txt">
                    <div class="greeting">안녕하십니까? <br>
                        <strong>한국반도체아카데미 원장 홍성주</strong>입니다.</div>
                    <p>반도체 산업은 미래 산업의 핵심이자 국가 안보 자산으로, 반도체 기술의 초격차를 확보할 전문 인재 양성이 필수적입니다. 뿐만 아니라, 4차 산업혁명과 맞물려 가장 빠르게 변화하는 기술 분야로 이에 대응하기 위해서는 산업에서 필요로 하는 직무 기반의 인력 양성 활성화가 필요합니다.</p>
                    <p>그러나, 반도체 기업의 인력 확보 상황과 교육 여건은 그리 녹록치 만은 않습니다.</p>
                    <p>반도체 산업계는 대학을 통해 탄탄하게 쌓여진 기초를 토대로 산업별 특성에 맞는 직무 기반의 인재를 선호하고 있지만 이 부분에 대한 지원이 미흡하며, 제조공정 뿐만 아니라 패키징, 테스트와 같은 후공정이나, 설계 및 소재·부품·장비 등의 산업군에서도 많은 인력을 필요로 하고 있지만 해당 직무와 연계된 특성화된 인력을 양성하는데 많은 어려움을 겪고 있기 때문입니다.</p>
                    <p>이에 한국반도체아카데미는 산업계 수요 주도의 교육과정을 단계별로 운영하고, 현장에서 필요로 하는 인력을 신속히 양성하기 위해 출범하게 되었습니다. 특히 ‘연대’, ‘협력’, ‘교류’, ‘생태계’라는 한국반도체아카데미의 4대 추진방향에 맞춰 산업계의 요구에 부합하는 역량 있는 인재를 육성하고자 합니다. 이를 위해 한국반도체아카데미 원장으로서 맡은 바 최선을 다할 것을 약속드립니다.</p>
                    감사합니다.
                    <b><small><strong>한국반도체아카데미</strong> 원장</small><strong>홍 성 주</strong></b>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .director-wrap {
        display: flex;
        .img {
            position: relative;
            width: 418px;
            div {
                height: 460px;
                background: #D9D9D9;
            }
            p {
                position: absolute;
                left: 0;
                top: 548px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
                color: #fff;
                text-align: center;
                @include font-size_xs;
                background: $black_color;
                opacity: 0.7;
            }
        }
        .txt {
            width: 650px;
            margin-left: 40px;

            b {
                display: block;
                text-align:right;
                @include font-size_xl;
                margin-top: 20px;
                small {
                    @include font-size_md;
                    margin-right: 20px;
                    font-size:19px;
                    line-height:30px;
                    strong{
                        font-family: 'Gmarket Sans';
                        color:#3843af;
                          font-size:20.5px;
                        line-height:30px;
                        margin-right:4px;
                    }
                }
            }
            .greeting{
                margin-top:-5px;
                color:#1e1e1e;
                font-weight:400;
                font-size:24px;
                line-height:38px;
                strong{
                    color:#3843af;
                    font-weight:600;
                }
            }
            p{
                color:#1e1e1e;
                font-size:16px;
                font-weight:400;
                font-style:normal;
                text-align:justify;
                line-height:28px;
                margin-bottom:26px;
                margin-top:26px;
               }
        }
    }

    h1 {
        @include font-size_xxl;
    }
</style>
<script setup>
</script>