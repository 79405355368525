import http from '../../../../api/http'

function retrieveHomeworkByLectId(lectId) { // 과제 목록 조회
    return http.get(`/lecture/homework/retrieveHomeworkByLectId?lectId=${lectId}`)
}
function retrieveHomeworkByLectIdForAdmin(lectId) { // 과제 목록 조회 (관리자용)
    return http.get(`/lecture/homework/retrieveHomeworkByLectIdForAdmin?lectId=${lectId}`)
}

function retrieveHomeworkSummary(lectId) { // 과제 요약 목록 조회
    return http.get(`/lecture/homework/retrieveHomeworkSummary?lectId=${lectId}`)
}

function retrieveAllHomeworkPaging(paramObj) { // 과제 제출 목록 조회
    return http.post('/lecture/homework/retrieveAllHomeworkPaging', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 30,
        lectId: paramObj.lectId,
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string",
        searchDurationGb: paramObj.searchDurationGb, // "R",
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string"
    })
}

function createHomework(paramObj) { // 과제 생성
    return http.post('/lecture/homework/createHomework', {
        lectId: paramObj.lectId, // 0,
        title: paramObj.title, // "string",
        fullMarks: paramObj.fullMarks, // "string",
        attachList: paramObj.attachList, // [],
    })
}

function saveHomeworkScore(paramObj) { // 과제 채점 저장
    return http.post('/lecture/homework/saveHomeworkScore', {
        userId: paramObj.userId, // 0,
        lectId: paramObj.lectId, // 0,
        regDt: paramObj.regDt, // 0,
        hwId: paramObj.hwId, // 0,
        score: paramObj.score, // 0,
        markIp: paramObj.markIp, // 0,
    })
}

function createUserHomework(paramObj) { // 과제 제출
    return http.post('/lecture/homework/createUserHomework', {
        userId: paramObj.userId, // 0,
        lectId: paramObj.lectId, // 0,
        regDt: paramObj.regDt, // 0,
        hwId: paramObj.hwId, // 0,
        attachList: paramObj.attachList, // 0,
    })
}

export default {
    retrieveHomeworkByLectId,
    retrieveHomeworkByLectIdForAdmin,
    retrieveHomeworkSummary,
    retrieveAllHomeworkPaging,
    createHomework,
    saveHomeworkScore,
    createUserHomework
}