<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, defineProps, defineExpose, toRefs, onMounted } from "vue";
    import { useRouter } from 'vue-router'
    import mypageRecordService from "../../../services/mypage/mypageRecordService";
    import _ from "lodash";
    import Loading from 'vue3-loading-overlay';
    import MyPageResumeModalBody from "../mypage/MyPageResumeModalBody.vue";
    import lectureUserService from "../../../services/lecture/lectureUserService";
    import LectureRegCompleteModal from "./LectureRegCompleteModal.vue";

    const router = useRouter();

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const modalBody = ref(null)
    const alertModal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])
    const lectureRegCompleteModal = ref(null);

    const loaded = ref(false)

    const props = defineProps({
        showButtons: {
            type: Boolean,
            default: true,
        },
    });

    const { showButtons } = toRefs(props);

    const offlineLecture = ref(0);
    const resumeRequired = ref(null);
    const lectDetail = ref({});
    const isDisabled = ref(false);

    const retrieveRecordDetail = () => {
        loaded.value = false;
        mypageRecordService.retrieveRecord()
            .then( (res) => {
                loaded.value = true;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    modalBody.value.setRecord(result);
                    // 오프라인 강의 이력서의 경우, 데이터 만들어서 전달
                    if(offlineLecture.value > 0 ) {
                        isDisabled.value = false;
                        let lectureResumeInfo = {
                          intro: 0, // 소개
                          eduHist: 0, // 학력
                          portfolio: 0, // 포트폴리오
                          cert: 0, // 자격증 및 외국어
                          jobHist: 0, // 업무 경험
                          mil: 0, // 병역
                          introComplete: true, // 소개 완성유무
                          eduHistComplete: true, // 학력 완성유무
                          portfolioComplete: true, // 포트폴리오 완성유무
                          certComplete: true, // 자격증 및 외국어 완성유무
                          jobHistComplete: true, // 업무 경험 완성유무
                          milComplete: true, // 병역 완성유무
                        }
                        // 이력서 필수 항목 체크
                        const arrayResumeRequired = resumeRequired.value.split(',');

                        if (arrayResumeRequired.includes('소개')) {
                            lectureResumeInfo.intro = 1;
                        }
                        if (arrayResumeRequired.includes('학력')) {
                            lectureResumeInfo.eduHist = 1;
                        }
                        if (arrayResumeRequired.includes('포트폴리오')) {
                            lectureResumeInfo.portfolio = 1;
                        }
                        if (arrayResumeRequired.includes('자격증 및 외국어')) {
                            lectureResumeInfo.cert = 1;
                        }
                        if (arrayResumeRequired.includes('업무 경험')) {
                            lectureResumeInfo.jobHist = 1;
                        }
                        if (arrayResumeRequired.includes('병역')) {
                            lectureResumeInfo.mil = 1;
                        }
                        // 미완성, 비공개 체크
                        // 소개
                        if (lectureResumeInfo.intro > 0) {
                            if(result.introOpenYn === 'N') {
                                isDisabled.value = true;
                            }
                            if(!(result.introAline && result.selfIntro && result.desiredSalary !== '')) { // 하나라도 공백이면
                                isDisabled.value = true;
                                lectureResumeInfo.introComplete = false;
                            }
                        }
                        // 학력
                        if (lectureResumeInfo.eduHist > 0) {
                            if(result.eduHistOpenYn === 'N') {
                                isDisabled.value = true;
                            }
                            if(!(result.eduHistoris.length > 0)) {
                                isDisabled.value = true;
                                lectureResumeInfo.eduHistComplete = false;
                            }
                        }
                        // 포트폴리오
                        if (lectureResumeInfo.portfolio > 0) {
                            if(result.portfolioOpenYn === 'N') {
                                isDisabled.value = true;
                            }
                            if(result.portfolios.length > 0) {
                                for (let i = 0; i < result.portfolios.length; i++) {
                                    const { completed } = result.portfolios[i]
                                    if( completed === 'N') {
                                        isDisabled.value = true;
                                        lectureResumeInfo.portfolioComplete = false;
                                    }
                                }
                            } else {
                                isDisabled.value = true;
                                lectureResumeInfo.portfolioComplete = false;
                            }
                        }
                        // 자격증 및 외국어
                        if (lectureResumeInfo.cert > 0) {
                            if(result.certForeignOpenYn === 'N') {
                                isDisabled.value = true;
                            }
                            if(result.certificates.length > 0 && result.languageTests.length > 0 && result.foreignLanguages.length > 0) {
                                for (let i = 0; i < result.certificates.length; i++) {
                                    const { completed } = result.certificates[i];
                                    if( completed === 'N') {
                                        isDisabled.value = true;
                                        lectureResumeInfo.certComplete = false;
                                    }
                                }
                                for (let i = 0; i < result.languageTests.length; i++) {
                                    const { completed } = result.languageTests[i];
                                    if( completed === 'N') {
                                        isDisabled.value = true;
                                        lectureResumeInfo.certComplete = false;
                                    }
                                }
                            } else {
                                isDisabled.value = true;
                                lectureResumeInfo.certComplete = false;
                            }
                        }
                        // 업무 경험
                        if (lectureResumeInfo.jobHist > 0) {
                            if (result.jobHistOpenYn === 'N') {
                                isDisabled.value = true;
                            }
                            if (result.jobHistorys.length > 0) {
                                for (let i = 0; i < result.jobHistorys.length; i++) {
                                    const { completed } = result.jobHistorys[i]
                                    if( completed === 'N') {
                                        isDisabled.value = true;
                                        lectureResumeInfo.jobHistComplete = false;
                                    }
                                }
                            } else {
                                isDisabled.value = true;
                                lectureResumeInfo.jobHistComplete = false;
                            }
                        }
                        // 병역
                        if (lectureResumeInfo.mil > 0) {
                            if(result.milOpenYn === 'N') {
                                isDisabled.value = true;
                            }
                            if (!result.milServiceGb) {
                                isDisabled.value = true;
                                lectureResumeInfo.milComplete = false;
                            }
                        }
                        modalBody.value.setOfflineLecture(offlineLecture.value, lectureResumeInfo);
                    }
                }
            }).catch( (error) =>  {
            loaded.value = true;
            console.log(error);
        });
    }

    const setOfflineLecture = (val1, val2, val3) => {
        offlineLecture.value = val1; // 오프라인 강의 1
        resumeRequired.value = val2; // 이력서필수 항목
        lectDetail.value = val3; // 강의 상세 정보
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const goRecordView = () => {
        cancel();
        router.push({name: 'MyPageRecord'});
    }

    const confirm = async () => {
        modalContent.value = ["제출 후에는 수정이 불가능합니다.", "계속 하시겠습니까?"];
        const ok = await confirmModal.value.show()
        if(ok){
            baseModal.value.close();
            requestUserLectureSel();
        }
    };

    const requestUserLectureSel = () => {
      loaded.value = false;
      lectureUserService.requestUserLectureSelByLectId(lectDetail.value.lectId)
          .then( (res) => {
            loaded.value = true;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              const regDt = result.regDt;
              // console.log(result)
              lectureRegCompleteModal.value.init(regDt);
              lectureRegCompleteModal.value.show();
            }
          })
          .catch( (res) =>  {
            // console.log(res);
            loaded.value = true;
            modalContent.value = [res.response.data.result.message];
            modal.value.show();
          });
    }

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    defineExpose({
        show,
        retrieveRecordDetail,
        setOfflineLecture,
    })
</script>

<template>
    <Modal ref="baseModal" class="vld-parent">
        <loading
            :active="!loaded"
            loader="dots"
            color="#3843AF"
            opacity="0.5"
            width="35"
            :is-full-page="false"></loading>
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-body" v-show="loaded">
                <MyPageResumeModalBody ref="modalBody"/>
            </div>
            <div v-if="showButtons" class="modal-footer btn-wrap">
                <KSASecondaryButton text="수정하기" @click="goRecordView" />
                <KSAPrimaryButton text="신청완료" class="ml-xs" @click="confirm" :disabled="isDisabled"/>
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="네" cancelButtonText="아니오" />
    <LectureRegCompleteModal ref="lectureRegCompleteModal" :lecture="lectDetail"/>

</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
    margin-top: 50px;
    .close {
        top: -20px !important;
    }
    .modal-footer {
        display: block;
        padding: 0 30px;
    }
}
</style>