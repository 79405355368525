<script setup>
    import { ref, onMounted, getCurrentInstance } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { useLectureStore } from "../../stores/lecture/lectureStore";
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import lectureService from "../../services/lecture/lectureService";
    import kollusVideoService from "../../../../services/kollusVideoService";
    import _ from "lodash";
    import KSAInputWithBytes from "../../../../components/ui-components/input/KSAInputWithBytes.vue";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const lectureStore = useLectureStore()

    const { $toast } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        if(lectureStore.lectGb) {
            searchInput.value.lectGb = lectureStore.lectGb;
            lectureStore.lectGb = '';
        }
        if(lectureStore.lectId) {
            searchInput.value.lectId = lectureStore.lectId;
            lectureStore.lectId = '';
            title.value = '수정';
        }
        retrieveLectureList();
    })

    const title = ref("등록")

    const searchInput = ref({
        lectGb: "01",
        lectId: "",
    })

    const lectGbRef = ref(null)
    const lectureListRef = ref(null)

    const lectureList = ref([
        { label: '강의 선택', value: '', }
    ])

    const lectDetail = ref({
        lectureIndexs: [],
    })

    const deletedIndexs = ref([]); // 삭제된거 따로 관리

    const inputErrors = ref([])

    const lectGbGroup = ref([
        { label:"온라인", value: "01" },
        { label:"오프라인", value: "02" },
    ]);

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])

    const retrieveLectureList = () => {

        let paramObj = {
            lectGb: searchInput.value.lectGb,
            lectOpenYn: 'A',
            repreLectYn: 'N',
            lectName: '',
            searchDurationGb: 'C',
            startYmd: '',
            endYmd: '',
            pageNo: 1,
            size: 100,
        }

        lectureList.value = [];
        lectureListRef.value.initSelectedOption();
        lectDetail.value = {};

        loadingStore.loading = true;
        lectureService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    lectureList.value = [
                        { label: '강의 선택', value: '',},
                        ..._.map(result.content, lect => {
                            return { label: lect.lectName, value: lect.lectId };
                        })
                    ]

                    if(searchInput.value.lectId) {
                        retrieveLectureDetail();
                    }

                    lectGbRef.value.initSelectedOption(searchInput.value.lectGb);
                    setTimeout(()=>{
                        lectureListRef.value.initSelectedOption(searchInput.value.lectId);
                    }, 100)

                    // console.log(lectureList.value);
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const retrieveLectureDetail = () => {
        const { lectId } = searchInput.value;

        if(lectId) {
            loadingStore.loading = true;
            lectureService.retrieveLectureDetail(lectId)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        lectDetail.value = result;
                        retrieveLectureIndexList();
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    const retrieveLectureIndexList = () => { // 차시 목록 조회
        const { lectId } = searchInput.value;

        if(lectId) {
            loadingStore.loading = true;
            lectureService.retrieveAllIndexByLectId(lectId)
                .then( (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        _.each(result, lectIndex => {
                            lectIndex.idxYmdYn = lectIndex.idxYmdYn === 'Y';
                            lectIndex.teacherName = lectIndex.teacher && lectIndex.teacher.userName ? lectIndex.teacher.userName : '';
                        });
                        lectDetail.value.lectureIndexs = _.orderBy(result, 'idxOrder');
                        deletedIndexs.value = [];
                        // console.log(result);
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                $toast('error', '오류가 발생하였습니다.');
            });
        }
    }


    const addIndex = () => {
        lectDetail.value.lectureIndexs.push({
            lecIdx: 0,
            lectId: lectDetail.value.lectId,
            idxNumSub: '',
            idxNum: '',
            idxYmdYn: true, // 일시여부
            idxYmd: "",
            teacher: null,
            teacherName: '', // 화면에서만 쓰는값
            idxName: "",
            // idxOrder: 0,
            uploadFileKey: '',
            movName: "",
            movSize: "",
            movDir: "",
            movPlayHm: "",
            idxHms: 0,
            practiceYn: "Y",
            supplies: "",
            deleteYn: "N"
        })
    }

    const copyIndex = (lectIndex) => {
        const copyLectIndex = _.cloneDeep(lectIndex);
        copyLectIndex.lecIdx = 0;
        lectDetail.value.lectureIndexs.push(copyLectIndex);
        $toast('', '차시가 복사되었습니다.');
    }

    const moveUpIndex = (index) => {
        if(index > 0) {
            const temp = lectDetail.value.lectureIndexs[index-1];
            lectDetail.value.lectureIndexs[index-1] = lectDetail.value.lectureIndexs[index];
            lectDetail.value.lectureIndexs[index] = temp;
            // initSelectGbRefs();
        }
    }

    const moveDownIndex = (index) => {
        if(index < lectDetail.value.lectureIndexs.length-1) {
            const temp = lectDetail.value.lectureIndexs[index+1];
            lectDetail.value.lectureIndexs[index+1] = lectDetail.value.lectureIndexs[index];
            lectDetail.value.lectureIndexs[index] = temp;
            // initSelectGbRefs();
        }
    }

    const deleteIndex = async (lectIndex, index) => {
        modalContent.value = ["작성한 정보를 삭제할까요?"];
        const ok = await confirmModal.value.show();

        if(ok) {
            if(lectDetail.value.lectureIndexs.length > 1) {
                if(lectIndex.lecIdx !== 0) {
                    lectIndex.deleteYn = 'Y';
                    deletedIndexs.value.push(lectDetail.value.lectureIndexs[index]);
                }
                lectDetail.value.lectureIndexs.splice(index, 1);
            } else {
                lectDetail.value.lectureIndexs[0].idxNumSub = "";
                lectDetail.value.lectureIndexs[0].idxNum = "";
                lectDetail.value.lectureIndexs[0].idxYmdYn = true;
                lectDetail.value.lectureIndexs[0].teacher = null;
                lectDetail.value.lectureIndexs[0].teacherName = '';
                lectDetail.value.lectureIndexs[0].idxName = "";
                lectDetail.value.lectureIndexs[0].idxYmd = "";
                lectDetail.value.lectureIndexs[0].uploadFileKey = "";
                lectDetail.value.lectureIndexs[0].movName = "";
                lectDetail.value.lectureIndexs[0].movSize = "";
                lectDetail.value.lectureIndexs[0].movDir = "";
                lectDetail.value.lectureIndexs[0].movPlayHm = "";
                lectDetail.value.lectureIndexs[0].idxHms = 0;
                lectDetail.value.lectureIndexs[0].practiceYn = "Y";
                lectDetail.value.lectureIndexs[0].supplies = "";
            }
        }
    }

    const uploadFile = (lectIndex) => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            const file = event.target.files[0];
            // console.log(file);
            loadingStore.loading = true;
            let paramObj = {
                title: file.name || '',
                file,
            }
            kollusVideoService.upload(paramObj)
                .then((res) => {
                    // console.log(res);
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        lectIndex.uploadFileKey = result.uploadFileKey;
                        lectIndex.movName = result.fullFilename;
                        lectIndex.movSize = '';
                        lectIndex.movDir = '';
                        lectIndex.movPlayHm = '';
                    }
                })
                .catch( (error) =>  {
                    console.log(error);
                    loadingStore.loading = false;
                });
        });
    }

    const deleteMovName = (lectIndex) => {
        lectIndex.uploadFileKey = '';
        lectIndex.movName = '';
        lectIndex.movSize = '';
        lectIndex.movDir = '';
        lectIndex.movPlayHm = '';
    }

    const save = () => {
        const { lectGb, lectId, lectureIndexs } = lectDetail.value;
        let isInvalid = false;

        let lectureIndexList = _.cloneDeep(lectureIndexs);

        inputErrors.value = [];

        for(let i=0; i<lectureIndexList.length; i++) {
            const lectIndex = lectureIndexList[i];
            inputErrors.value[i] = {};
            if(lectIndex.deleteYn !== 'Y') {
                if(lectIndex.idxNum === undefined || lectIndex.idxNum === null || lectIndex.idxNum === '') {
                    inputErrors.value[i].idxNum = `${lectGb === '01' ? '차시' : '주차'}를 입력하세요.`;
                    isInvalid = true;
                }
                if(lectGb === '02' && (lectIndex.idxNumSub === undefined || lectIndex.idxNumSub === null || lectIndex.idxNumSub === '')) {
                    inputErrors.value[i].idxNumSub = '차시를 입력하세요.';
                    isInvalid = true;
                }
                if(lectGb === '01' && !lectIndex.movName) {
                    inputErrors.value[i].movName = '영상을 첨부하세요.';
                    isInvalid = true;
                }
                if(lectGb === '02' && !lectIndex.idxHms) {
                    inputErrors.value[i].idxHms = '시간을 입력하세요.';
                    isInvalid = true;
                }
                if(!lectIndex.teacher || !lectIndex.teacher.userName) {
                    inputErrors.value[i].teacherName = '강사를 입력하고 선택하세요.';
                    isInvalid = true;
                }
                if(!lectIndex.idxName) {
                    inputErrors.value[i].idxName = `${lectGb === '01' ? '차시' : '교육'}명을 입력하세요.`;
                    isInvalid = true;
                }
                // 온라인 강의는 idxNumSub에 idxNum값 넣어 줌.
                if(lectGb === '01') {
                    lectIndex.idxNumSub = lectIndex.idxNum;
                }
            }
            lectIndex.idxOrder = i;
            lectIndex.idxYmdYn = lectIndex.idxYmdYn ? 'Y' : 'N';

        }
        // console.log(lectureIndexList);

        if(!isInvalid) {
            lectureIndexList = [...lectureIndexList, ...deletedIndexs.value]; // 삭제 대상 추가
            _.each(lectureIndexList, index => delete index.teacherName);
            let paramObj = {
                lectId,
                lectureIndexList,
            };

            loadingStore.loading = true;
            lectureService.updateIndex(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        if(title.value === '수정') {
                            modalContent.value = ["변경사항을 저장했습니다."];
                            const ok = await modal.value.show();
                            if(ok) {
                                goBack();
                            }
                        } else {
                            retrieveLectureDetail();
                            modalContent.value = [`차시를 ${title.value}했습니다.`];
                            modal.value.show();
                        }
                    } else {
                        modalContent.value = [`차시 ${title.value}을 실패하였습니다.`];
                        modal.value.show();
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
                modalContent.value = [`차시 ${title.value}을 실패하였습니다.`];
                modal.value.show();
            });
        }
    }

    const goBack = () => {
        if(title.value === '수정') {
            router.push({name: 'LectureDetail', params:{id: lectDetail.value.lectId}});
        } else {
            router.push({name: 'LectureList'});
        }
    }

    const isDisabled = (lectIndex) => {
        return lectIndex.movName === '' && (lectIndex.teacher === null || !lectIndex.teacher.userName) && lectIndex.idxName === '' && lectIndex.idxNum === '';
    }

    const onSelectedLectGb = (option) => {
        // console.log(option.value)
        const reload = searchInput.value.lectGb !== option.value;
        searchInput.value.lectGb = option.value;
        searchInput.value.lectId = '';
        if(reload) {
            retrieveLectureList();
        }
    }

    const onSelectedLectId = (option) => {
        const reload = searchInput.value.lectId !== option.value;
        searchInput.value.lectId = option.value;
        if(reload) {
            retrieveLectureDetail();
        }
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>차시 {{ title }}</h3>
                    <p><span>* 표시는 필수 입력사항</span> 입니다.</p>
                </div>

                <!-- s: 강의 정보 -->
                <div class="content-inner">
                    <div class="content-title" style="padding-top: 0;">
                        <h4>강의 정보</h4>
                    </div>
                    <div class="content-item">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th class="mark">강의명</th>
                                    <td>
                                        <div class="grid half">
                                            <KSASelect ref="lectGbRef" :options="lectGbGroup" class="select" @selectOption="onSelectedLectGb"  style="width: 220px;" />
                                            <KSASelect ref="lectureListRef" :options="lectureList" class="select ml-xs" @selectOption="onSelectedLectId"/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>신청 기간</th>
                                    <td>
                                        <span v-if="!lectDetail.lectId">-</span>
                                        <span v-else-if="lectDetail.regTermGb === '01'">상시</span>
                                        <span v-else>{{ $yyyymmdd(lectDetail.regStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>교육 기간</th>
                                    <td>
                                        <span v-if="!lectDetail.lectId">-</span>
                                        <span v-else-if="lectDetail.lectTermGb === '01'">신청일로부터 {{ lectDetail.lectTerm }}일</span>
                                        <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- e: 강의 정보 -->

                <!-- s: 차시 정보 (온라인)-->
                <div class="content-inner" v-if="lectDetail.lectGb === '01'">
                    <div class="content-title grid space-between">
                        <h4>차시 정보 {{  lectDetail.lectureIndexs && lectDetail.lectureIndexs.length ? `(${lectDetail.lectureIndexs.length})` : '' }}</h4>
                        <KSASecondaryButton class="gray_button add_btn" text="추가" @click="addIndex" />
                    </div>
                    <div class="content-item gray-bg" v-for="(lectIndex, idx) in lectDetail.lectureIndexs">
                        <div class="content-sub-title">
                            <h5>차시</h5>
                        </div>
                        <div class="control">
                            <KSASecondaryButton class="gray_button" text="차시 복사" @click="copyIndex(lectIndex)" :disabled="isDisabled(lectIndex)"/>
                            <div class="up" :class="{'active': idx > 0 && lectDetail.lectureIndexs.length > 1 }" @click="moveUpIndex(idx)"><i></i></div>
                            <div class="down" :class="{'active': idx < lectDetail.lectureIndexs.length-1 && lectDetail.lectureIndexs.length > 1 }" @click="moveDownIndex(idx)"><i></i></div>
                            <div class="delete" @click="deleteIndex(lectIndex, idx)"><i></i></div>
                        </div>
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th class="mark">차시</th>
                                    <td class="grid">
                                        <KSAInput class="text-box" v-model="lectIndex.idxNum" :msg="inputErrors[idx] && inputErrors[idx].idxNum" max-length="2"/> 차시
                                    </td>
                                    <th>일시</th>
                                    <td class="align-center">
                                        <KSADatepicker v-model="lectIndex.idxYmd" :disabled="lectIndex.idxYmdYn"/>
                                        <KSACheckBox class="ml-xs" label="자유" v-model="lectIndex.idxYmdYn" />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="mark">영상</th>
                                    <td class="text-box">
                                        <div class="grid file">
                                            <KSAPrimaryButton text="파일 선택" @click="uploadFile(lectIndex)" />
                                            <KSAInput class="input icon" placeholder="영상 파일 선택" v-model="lectIndex.movName" disabled />
                                            <button class="delete" @click="deleteMovName(lectIndex)" :disabled="!lectIndex.movName"><i></i></button>
                                        </div>
                                        <div v-if="inputErrors[idx] && inputErrors[idx].movName">
                                            <small style="color:#E71414;">{{ inputErrors[idx].movName }}</small>
                                        </div>
                                    </td>
                                    <th>시간</th>
                                    <td>
                                        <span v-if="lectIndex.movPlayHm">{{ $movPlayHm(lectIndex.movPlayHm) }}</span>
                                        <span v-else>{{ lectIndex.uploadFileKey ? '인코딩 중' : '-' }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="mark">강사명</th>
                                    <td>
                                        <KSAInputTeacherSelect v-model="lectIndex.teacherName" @select="(teacher) => lectIndex.teacher = teacher" :msg="inputErrors[idx] && inputErrors[idx].teacherName"/>
                                    </td>
                                    <th class="mark">차시명</th>
                                    <td>
                                        <KSAInputWithBytes class="text-box" v-model="lectIndex.idxName" :msg="inputErrors[idx] && inputErrors[idx].idxName" max-bytes="38"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- e: 차시 정보 (온라인)-->

                <!-- s: 차시 정보 (오프라인)-->
                <div class="content-inner" v-if="lectDetail.lectGb === '02'">
                    <div class="content-title grid space-between">
                        <h4>차시 정보 {{  lectDetail.lectureIndexs && lectDetail.lectureIndexs.length ? `(${lectDetail.lectureIndexs.length})` : '' }}</h4>
                        <KSASecondaryButton class="gray_button add_btn" text="추가" @click="addIndex" />
                    </div>
                    <div class="content-item gray-bg" v-for="(lectIndex, idx) in lectDetail.lectureIndexs">
                        <div class="content-sub-title">
                            <h5>차시</h5>
                        </div>
                        <div class="control">
                            <KSASecondaryButton class="gray_button" text="차시 복사" @click="copyIndex(lectIndex)" :disabled="isDisabled(lectIndex)"/>
                            <div class="up" :class="{'active': idx > 0 && lectDetail.lectureIndexs.length > 1 }" @click="moveUpIndex(idx)"><i></i></div>
                            <div class="down" :class="{'active': idx < lectDetail.lectureIndexs.length-1 && lectDetail.lectureIndexs.length > 1 }" @click="moveDownIndex(idx)"><i></i></div>
                            <div class="delete" @click="deleteIndex(lectIndex, idx)"><i></i></div>
                        </div>
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th class="mark">주차</th>
                                    <td class="grid">
                                        <KSAInput class="text-box" v-model="lectIndex.idxNum" :msg="inputErrors[idx] && inputErrors[idx].idxNum" max-length="2"/> 주차
                                    </td>
                                    <th class="mark">차시</th>
                                    <td class="grid">
                                        <KSAInput class="text-box" v-model="lectIndex.idxNumSub" :msg="inputErrors[idx] && inputErrors[idx].idxNumSub" max-length="2"/> 차시
                                    </td>
                                </tr>
                                <tr>
                                    <th>일시</th>
                                    <td class="align-center">
                                        <KSADatepicker v-model="lectIndex.idxYmd" :disabled="lectIndex.idxYmdYn"/>
                                        <KSACheckBox class="ml-xs" label="자유" v-model="lectIndex.idxYmdYn" />
                                    </td>
                                    <th>시간</th>
                                    <td class="grid">
                                        <KSAInput class="text-box" v-model="lectIndex.idxHms" :msg="inputErrors[idx] && inputErrors[idx].idxHms" max-length="3"/> 시간
                                    </td>
                                </tr>
                                <tr>
                                    <th class="mark">강사명</th>
                                    <td>
                                        <KSAInputTeacherSelect v-model="lectIndex.teacherName" @select="(teacher) => lectIndex.teacher = teacher" :msg="inputErrors[idx] && inputErrors[idx].teacherName"/>
                                    </td>
                                    <th>실습 여부</th>
                                    <td>
                                        <KSARadioButton class="mr-md" :name="'practiceYn'+idx" label="O" v-model="lectIndex.practiceYn" value="Y" />
                                        <KSARadioButton class="mr-md" :name="'practiceYn'+idx" label="X" v-model="lectIndex.practiceYn" value="N" />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="mark">교육명</th>
                                    <td>
                                        <KSAInputWithBytes class="text-box" v-model="lectIndex.idxName" :msg="inputErrors[idx] && inputErrors[idx].idxName" max-bytes="38"/>
                                    </td>
                                    <th>필요 기자재</th>
                                    <td>
                                        <KSAInput class="text-box" v-model="lectIndex.supplies" max-length="50"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- e: 차시 정보 -->
                <div class="btn-wrap space-between mt-xs">
                    <KSASecondaryButton class="gray_button" text="취소" @click="goBack" />
                    <KSAPrimaryButton v-if="lectDetail.lectId" :text="title" @click="save" />
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>