<script setup>
    import { defineEmits, toRefs } from "vue";

    const props = defineProps({
        modelValue: { type: String },
        label: { type: String },
        placeholder: { type: String },
        maxLength: { type: String },
        displayMaxLenYn: {
            type: Boolean,
            default: true,
        }
    });
    const { modelValue, label, placeholder, maxLength, displayMaxLenYn } = toRefs(props);
    const emit = defineEmits(["update:modelValue"]);
</script>

<template>
    <div>
        <p v-if="label">{{ label }}</p>
        <textarea
            :value="modelValue"
            @input="event => emit('update:modelValue', event.target.value)"
            :placeholder="placeholder"
            :maxlength="maxLength"
        />
        <span v-if="displayMaxLenYn && modelValue && maxLength">{{ modelValue.length }}자<i>/{{ $number(maxLength) }}자</i></span>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    p {
        @include font-size_xs;
        color: $gray_color;
        margin-bottom: 10px;
    }
    textarea {
        width: 100%;
        height: 100%;
        border: $border_line;
        padding: 10px 12px;
        &::placeholder {
            color: $gray_color;
        }
        resize: none;
    }
    span {
        display: block;
        text-align: right;
        i {
            font-style: normal;
            color: $gray_color;
        }
    }
</style>