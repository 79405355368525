<script setup>
  import SNB from "../../../../../layouts/nav/SNB.vue";
  import { ref, onMounted, getCurrentInstance, inject } from 'vue'
  import { useRouter} from 'vue-router'
  import Loading from 'vue3-loading-overlay';
  import {usePageLoadingStore} from "../../../../../stores/pageLoading";
  import mypageProposalService from "../../../services/mypage/mypageProposalService";
  import KSASecondaryButton from "../../../../../components/ui-components/button/KSASecondaryButton.vue";
  import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
  import MyPageResumeModal from "../../../components/modal/mypage/MyPageResumeModal.vue";
  import _ from 'lodash'
  import mypageMatchingService from "../../../services/mypage/mypageMatchingService";
  import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";

  const globals = getCurrentInstance().appContext.config.globalProperties;
  const { $toast, $gb, $gbNameKR, $pageListBoardNo } = globals;

  const loaded = ref(false);
  const getSystemCodeValues = inject('systemCodeValues');

  const interests = ref(globals.$interests)
  const searchMonths = ref(globals.$searchMonths)
  const corpState = ref(globals.$corpState)
  //const corpState = ref(globals.$gb.MATCHING_JOB_STATE_CORP_GB)

  const router = useRouter()
  const loadingStore = usePageLoadingStore()
  const matchingUpdateStore = useMatchingUpdateStore()

  const snbKey = ref(0);

  onMounted(()=>{
    retrieveList()
  })

  const userMatchingResumeModal = ref(null);

  const modal = ref(null);
  const confirmModal = ref(null);
  const modalContent = ref([]);
  const okButtonText = ref("확인");

  const list = ref([])
  const currentPage = ref(1);
  const pageSize = ref(10);
  const totalPages = ref(1);
  const totalCount = ref(0);
  const searchOpen = ref(true)

  const searchInputs = ref({
    interestsList: [],
    resStateList: [],
    searchMonthType: 1,
  })
  const interestsListRef = ref(null)
  const resStateListRef = ref(null)

  const pageSizeGroup = ref([
    { label:"10개씩 보기", value: "10" },
    { label:"30개씩 보기", value: "30" },
    { label:"50개씩 보기", value: "50" },
    { label:"100개씩 보기", value: "100" },
  ])

  const getParamObj = () => {
    return {
      pageNumber: currentPage.value || 0,
      size: pageSize.value || 10,
      searchInterests: _.map(searchInputs.value.interestsList).join(','),
      searchResStateGbs: _.map(searchInputs.value.resStateList).join(','),
      searchMonths: searchInputs.value.searchMonthType,
    };
  }

  // 조건에 맞는 데이터 불러오는 함수
  const retrieveList = async () => {
    let paramObj = getParamObj();
    //loadingStore.loading = true;
    loaded.value = false;
    await getSystemCodeValues().then( async () => {
      await mypageProposalService.retrieveResumeCorporationList(paramObj)
          .then( async (res) => {
            //loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              // console.log(result);
              totalPages.value = result.totalPages;
              totalCount.value = result.totalElements;
              list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
              // 매칭 상태값 업데이트
              await retrieveMatchingUpdate();
            } else {
            }
            loaded.value = true;
          }).catch( (res) =>  {
            modalContent.value = ["데이터를 받아오지 못했습니다.기업코드 등록여부를 확인해주세요."];
            modal.value.show();
            console.log(res);
            loaded.value = true;
            //loadingStore.loading = false;
          });
    });
  }

  // 페이지당 개수 변경
  const onSelectPageSize = (option) => {
    pageSize.value = option.value;
    currentPage.value = 1;
    retrieveList();
  }

  // 페이지 클릭
  const onSelectPage = (pageNo) => {
    currentPage.value = pageNo;
    retrieveList();
  }

  // 검색 버튼
  const onClickSearch = () => {
    currentPage.value = 1;
    retrieveList();
  }

  // 초기화 버튼
  const onClickClearSearchInput = () => {
    searchInputs.value = {
      interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
      resStateList: ['01', '02', '03', '04', '05', '06', '07', '08'],
      searchMonthType: 1,
    };
    interestsListRef.value.initOptions()
    resStateListRef.value.initOptions()
  }

  // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
  const retrieveMatchingUpdate = async () => {
    await mypageMatchingService.retrieveMatchingUpdate()
        .then( async (res) => {
          loadingStore.loading = false;
          if(res.data.code.toLowerCase() === 'success') {
            const result = res.data.result;
            matchingUpdateStore.matchingUpdate = result;
            await matchingUpdateStore.setMatchingUpdate( result );
            matchingUpdateStore.value = await useMatchingUpdateStore();
            // SNB.vue도 같이 동적으로 리로드 되도록
            snbKey.value += 1;
          }
        }).catch( (error) => {
          loadingStore.loading = false;
          console.log(error);
        });
  }

  const onClickChangeState = async (resumeId, stateGb) => {
    if(stateGb === '02') {
      modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
    } else if(stateGb === '05') {
      modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
    } else if(stateGb === '06') {
      modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
    } else if(stateGb === '07') {
      modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
    } else if(stateGb === '08') {
      modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
    }
    okButtonText.value = "변경";
    const ok = await confirmModal.value.show();
    if(!ok) {
      return -1;
    } else {
      let paramObj2 = {
        resumeId: resumeId,
        resStateGb: stateGb,
      }
      mypageProposalService.changeResumeCorporationState(paramObj2)
          .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              //const result = res.data.result;
              modalContent.value = ["변경하였습니다."];
              modal.value.show();
              retrieveList();
            } else {
              console.log(res);
            }
          }).catch( (res) =>  {
            loadingStore.loading = false;
            console.log(res);
          });
    }
  }

  // 채용 이력서 보기
  const onClickViewResume = (resumeId) => {
    userMatchingResumeModal.value.init(false);
    userMatchingResumeModal.value.retrieveUserMatchingResumeDetail(resumeId, 1);
    userMatchingResumeModal.value.show();
  }

</script>
<!-- MailSMSHistoryView.vue 참고-->
<template>
  <loading
      :active="!loaded"
      loader="dots"
      color="#3843AF"
      opacity="0.5"
      width="35"
      :is-full-page="false"></loading>
  <div class="container">
    <div class="inner" v-show="loaded">
      <div class="flex-wrap admin">
        <SNB :key="snbKey" class="snb" />
        <div class="content">
          <div class="sub-title">
            <h3>인재 채용 현황</h3>
          </div>
          <div class="filter-wrap" :class="{'open': searchOpen}">
            <p @click="searchOpen = !searchOpen">검색</p>
            <div class="filter">
              <dl>
                <dt>관심 카테고리</dt>
                <dd>
                  <KSACheckBoxGroup
                      ref="interestsListRef"
                      className="mr-md"
                      name="interestsList"
                      v-model="searchInputs.interestsList"
                      :options="interests" />
                </dd>
              </dl>
              <dl>
                <dt>상태</dt>
                <dd>
                  <KSACheckBoxGroup
                      ref="resStateListRef"
                      className="mr-md"
                      name="resStateList"
                      v-model="searchInputs.resStateList"
                      :options="corpState" />
                </dd>
              </dl>
              <dl>
                <dt>
                  이력서 전송일
                </dt>
                <dd class="text-box">
                  <div class="grid">
                    <template v-for="group in searchMonths">
                      <KSARadioButton class="mr-md" name="searchMonthGb" :label="group.label" v-model="searchInputs.searchMonthType" :value="group.value" />
                    </template>
                  </div>
                </dd>
              </dl>
              <div class="btn-wrap">
                <KSASecondaryButton class="gray_button" text="초기화" @click="onClickClearSearchInput" />
                <KSAPrimaryButton text="검색" @click="onClickSearch" />
              </div>
            </div>
          </div>
          <div class="list-top">
            <p>총 <span>{{ $number(totalCount) }}</span>개</p>
            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
          </div>
          <div class="table-form board-mobile-vertical">
            <table>
              <tr>
                <th>번호</th>
                <th>이름</th>
                <th>관심 카테고리</th>
                <th>이력서 전송일</th>
                <th>이력서 보기</th>
                <th>상태</th>
                <th>상태 변경</th>
              </tr>
              <tr v-for="resumeCorpList in list">
                <td data-title="번호 : ">{{ resumeCorpList.boardNo }}</td>
                <td data-title="이름 : ">{{ resumeCorpList.userName }}</td>
                <td data-title="관심 카테고리 : ">{{ resumeCorpList.interests }}</td>
                <td data-title="이력서 전송일 : ">{{ $yyyymmddHHmmss(resumeCorpList.modifyDate) }}</td>
                <td data-title="이력서 보기 : ">
                  <KSATextButton class="clickable resume" text="이력서 보기" @click="onClickViewResume(resumeCorpList.resumeId)"/>
                </td>
                <td data-title="상태 : ">
                  <span>
                    <span>
                      <span v-if="resumeCorpList.resStateGb === '03' || resumeCorpList.resStateGb === '05'" class="red-dot"></span>
                      {{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB', resumeCorpList.resStateGb) }}
                    </span>
                    <br>
                    <small style="color: #86889A">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB2', resumeCorpList.resStateGb) }}</small>
                  </span>
                </td>

                <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                <td v-if="resumeCorpList.resStateGb === '01'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '02')" @click="onClickChangeState(resumeCorpList.resumeId, '02')" class="gray_button md_btn"/>
                </td>
                <td v-else-if="resumeCorpList.resStateGb === '03'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="onClickChangeState(resumeCorpList.resumeId, '05')" class="green_button md_btn"/>
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '06')" @click="onClickChangeState(resumeCorpList.resumeId, '06')" class="orange_button md_btn"/>
                </td>
                <td v-else-if="resumeCorpList.resStateGb === '05'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="onClickChangeState(resumeCorpList.resumeId, '07')" class="blue_button md_btn"/>
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="onClickChangeState(resumeCorpList.resumeId, '08')" class="red_button md_btn"/>
                </td>
                <td v-else-if="resumeCorpList.resStateGb === '06'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="onClickChangeState(resumeCorpList.resumeId, '05')" class="green_button md_btn"/>
                </td>
                <td v-else-if="resumeCorpList.resStateGb === '07'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="onClickChangeState(resumeCorpList.resumeId, '08')" class="red_button md_btn"/>
                </td>
                <td v-else-if="resumeCorpList.resStateGb === '08'" data-title="상태 변경 : ">
                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="onClickChangeState(resumeCorpList.resumeId, '07')" class="blue_button md_btn"/>
                </td>
                <td v-else data-title="상태 변경 : ">
                  -
                </td>
              </tr>
            </table>
            <div class="result-none" v-if="list.length === 0">
              검색 결과가 없습니다.
            </div>
          </div>
          <Paginate
              v-if="list.length > 0"
              v-model="currentPage"
              :page-count="totalPages"
              :click-handler="onSelectPage"
          />
        </div>
      </div>
    </div>
  </div>
  <AlertModal ref="modal" :content="modalContent" />
  <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText"/>
  <MyPageResumeModal ref="userMatchingResumeModal"/>
</template>

<style scoped lang="scss">
  @import "../../../../../assets/scss/theme";

  .tab {
    grid-template-columns: repeat(2, 1fr);
  }

  .clickable {
    cursor: pointer;
    &.resume {
      color: #3C72FF;
      &:hover {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }

</style>

