// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, reactive, watch } from 'vue'

export const useNavigationStore = defineStore('navigation', () => {
    const localNavigation = reactive({
        menuLevel1: '',
        menuLevel2: '',
        menuLevel3: '',
        menuLevel4: '',
    })

    const lastAccessPage = ref(''); // 마지막 접근한 페이지 (로그인후 이동하기위해)
    const isAdminView = ref(false);
    const isSysAdminView = ref(false);
    const isMobileMenuView = ref(false);

    const localNavInStorage = sessionStorage.getItem('navigation')
    if (localNavInStorage) {
        const { menuLevel1, menuLevel2, menuLevel3, menuLevel4 } = JSON.parse(localNavInStorage)
        localNavigation.menuLevel1 = menuLevel1
        localNavigation.menuLevel2 = menuLevel2
        localNavigation.menuLevel3 = menuLevel3
        localNavigation.menuLevel4 = menuLevel4
    }

    watch(() => localNavigation, (state) => {
        sessionStorage.setItem('navigation', JSON.stringify(state))
    }, { deep: true })

    return {
        localNavigation,
        lastAccessPage,
        isAdminView,
        isSysAdminView,
        isMobileMenuView,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNavigationStore, import.meta.hot))
}
