<script setup>
    import { ref, onMounted, onUnmounted } from 'vue'


    onMounted(()=>{
        document.body.addEventListener('click', clickEventHandler)
    })
    onUnmounted(()=>{
        document.body.removeEventListener('click', clickEventHandler)
    })

    const options = [
        "직접 입력",
        "gmail.com",
        "naver.com",
        "hanmail.net",
        "nate.com",
        "hotmail.com"
    ]

    const selected = ref(options[0])

    const openOption = ref(false);

    const emit = defineEmits(['selectOption']);

    const emitSelectedOption = (option) => {
        openOption.value = false;
        selected.value = option;
        emit("selectOption", option);
    }

    const clickEventHandler = (event) => {
        if(!event.target.closest('.custom-select')) open.value = false;
    }
</script>

<template>
    <div class="custom-select" @blur="openOption = false">
        <div class="selected" :class="{'open': openOption }" @click="openOption = !openOption">
            {{ selected }}
        </div>
        <div class="items" :class="{'selectHide': !openOption }">
            <div v-for="option in options" @click="emitSelectedOption(option);">
                {{ option }}
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
@import "../../../assets/scss/ui-components/ksa_select";
</style>
