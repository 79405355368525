<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, defineExpose, onMounted } from "vue";
import { useRouter } from 'vue-router'
import { useCookies } from 'vue3-cookies'
import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";

const { cookies } = useCookies();

// 로그인한 구직자 채용 업데이트 데이터 받아오기.
const matchingUpdateStore = useMatchingUpdateStore()
const propStateCnt = ref(0);
const resStateCnt = ref(0);
if(matchingUpdateStore) {
  propStateCnt.value = matchingUpdateStore.matchingUpdate.propStateCnt;
  resStateCnt.value = matchingUpdateStore.matchingUpdate.resStateCnt;
}

const router = useRouter();

const baseModal = ref(null);
const resolvePromise = ref(null);

const modalContent = ref([])

const loaded = ref(false)
const content = ref(null);

const show = () => {
  baseModal.value.open();
  return new Promise((resolve, _) => {
    resolvePromise.value = resolve;
  });
};

const cancel = () => {
  baseModal.value.close();
  resolvePromise.value(false);
};

const noView = () => {
  cookies.set('noUserViewToday', true, { expires: 1}); // 1일 동안 유지
  cancel();
}

onMounted(() => {
  // 로그인 후 모달 띄우기
  const noUserViewToday = cookies.get('noUserViewToday');
  if(noUserViewToday !== 'true') {
    show();
  }
});

defineExpose({
  show,
})
</script>
<template>
  <Modal ref="baseModal" class="vld-parent">
    <div class="modal-inner m-size">
      <button class="close" @click="cancel"></button>
      <div class="modal-body">
        <h2>채용 제안서 응답 안내</h2>
        <p>기업으로부터 받은 채용 제안서가 확인되었습니다.<br>
          <em>제안에 응답(수락/거절)하시면,<br>
            기업별 채용 전형이 진행됩니다.</em>
        </p>
        <div class="status-container">
          <div class="status">
            <h4>매칭 중 :</h4>
            <p>기업으로부터 채용 제안서 받음</p>
          </div>
          <div class="status">
            <h4>전형 진행</h4>
            <span class="status-arrow"></span>
            <span>제안 수락 or 제안 거절</span>
          </div>
          <div class="warning">※ 14일 동안 응답 없을 시 '제안 거절' 처리됩니다</div>
        </div>
      </div>
      <div v-if="resStateCnt > 0" class="modal-footer btn-wrap" >
        <button class="full-btn" @click="router.push({name:'UserMatchingResumeProposalList'});cancel();">입사 지원 현황</button>
      </div>
      <div v-if="propStateCnt > 0" class="modal-footer btn-wrap" >
        <button class="full-btn" @click="router.push({name:'UserProposalUser'});cancel();">받은 채용 제안</button>
      </div>
    </div>
    <div class="check-not-today" @click="noView"><span class="check"></span>오늘 하루 보지 않기</div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
  margin-top: 50px;
  .close {
    top: -20px !important;
    right: -10px !important;;
  }
  .modal-footer {
    display: block;
  }
}
</style>