<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>심사 결과 변경</h3>
            </div>
            <div class="modal-body">
                <div class="list-top" style="justify-content: left; margin: 0">
                    <p>총 <span>{{ userList.length }}</span>명</p>
                    <p v-for="evalStateObj in evalStateNameList">
                        | {{ evalStateObj.label }} <i class="red">{{evalStateObj.count}}</i>명
                    </p>
                </div>
                <div class="list-top">
                    <p class="text-left">
                        <b>{{ $gbNameKR('EVAL_STATE_GB2', evalStateGb) }}</b>으로 변경합니다.
                    </p>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th><KSACheckBox v-model="isSelectedAll" /></th>
                            <th>심사 상태</th>
                            <th>회원코드</th>
                            <th>이름</th>
                            <th>아이디</th>
                        </tr>
                        <tr v-for="user in userList">
                            <td><KSACheckBox v-model="user.checked" /></td>
                            <td>{{ $gbNameKR('EVAL_STATE_GB2', user.evalStateGb) }}</td>
                            <td>{{ user.userId }}</td>
                            <td>{{ user.userName }}</td>
                            <td>{{ user.userLoginId }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" :text="'변경(' + checkedUserList.length + ')'" @click="confirm" />
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
</template>


<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, onMounted,  computed, defineExpose, getCurrentInstance } from "vue";
    import _ from 'lodash'

    const { $gbNameKR } = getCurrentInstance().appContext.config.globalProperties;

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const isSelectedAll = ref(false)

    const userList = ref([])
    const evalStateGb = ref('')

    const alertModal = ref(null)
    const modalContent = ref([])

    let disabledWatchIsSelectedAll = false;
    let disabledWatachList = false;

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);
    })

    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(userList.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatachList = true;
            userList.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    const checkedUserList = computed(()=>{
        return _.filter(userList.value, { checked: true});
    })

    const evalStateNameList = computed(()=>{
        const checkedUserList =  _.filter(userList.value, { checked: true});
        const uniqEvalStateGbs = _.uniq(_.map(checkedUserList, 'evalStateGb'));

        return _.map(uniqEvalStateGbs, evalStateGb => {
            return {
                label: $gbNameKR('EVAL_STATE_GB2', evalStateGb),
                count: _.filter(checkedUserList, { evalStateGb }).length,
            }
        });
    })

    watch(userList, (newValue)=>{
        if(!disabledWatachList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
        } else {
            disabledWatachList = false;
        }
    }, { deep: true})

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        baseModal.value.close();
        resolvePromise.value(checkedUserList.value);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value();
    };

    const init = (list, _evalStateGb) => {
        isSelectedAll.value = false;
        userList.value = _.cloneDeep(list);
        evalStateGb.value = _evalStateGb;
    }

    defineExpose({
        show,
        init
    })

</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.list-top {
    p {
        margin-right: 0;
        padding-left: 2px;
        &:first-of-type {
            padding-left: 0;
        }
    }
}

</style>