<script setup>
import { ref, onMounted, getCurrentInstance, inject } from 'vue'
import { RouterLink } from 'vue-router'
import adminMatchingService from "../../services/matching/adminMatchingService";
import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
import {usePageLoadingStore} from "../../../../stores/pageLoading";
import _ from "lodash";

const loadingStore = usePageLoadingStore()
const { $pageListBoardNo, $toast, $gb, $gbNameKR, $yyyymmddHHmmss, $corpState } = getCurrentInstance().appContext.config.globalProperties;
const getSystemCodeValues = inject('systemCodeValues');

onMounted(()=>{
  retrieveList()
  retrieveCount()
})

const list = ref([])
const resultCount = ref({})

const currentPage = ref(1);
const pageSize = ref(10);
const totalPages = ref(0);
const totalCount = ref(0);

const corpState = ref($corpState)
const searchOpen = ref(true)
const stateGbListRef = ref(null)
const searchInputs = ref({
  searchStateGbs: [],
  searchDurationType: 'all',
  startYmd: '',
  endYmd: '',
  searchInput: '',
})

const searchDurationTypeGroup = ref([
  { label:"모든 기간", value: "all" },
  { label:"기간 검색", value: "term" },
])

const pageSizeGroup = ref([
  { label:"10개씩 보기", value: "10" },
  { label:"30개씩 보기", value: "30" },
  { label:"50개씩 보기", value: "50" },
  { label:"100개씩 보기", value: "100" },
])

const retrieveList = async () => {
  let paramObj = {
    searchStateGbs: _.map(searchInputs.value.searchStateGbs).join(','),
    searchInput: searchInputs.value.searchInput,
    startYmd: searchInputs.value.searchDurationType === 'all' ? "" : searchInputs.value.startYmd,
    endYmd: searchInputs.value.searchDurationType === 'all' ? "" : searchInputs.value.endYmd,
    pageNumber: currentPage.value,
    size: pageSize.value,
  }
  loadingStore.loading = true;
  await getSystemCodeValues();
  adminMatchingService.retrieveMatchingList(paramObj)
      .then( async (res) => {
        loadingStore.loading = false;
        // console.log(res);
        if(res.data.code.toLowerCase() === 'success') {
          const result = res.data.result;
          totalPages.value = result.totalPages;
          totalCount.value = result.totalElements;
          list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
        } else {
        }
      }).catch( (res) =>  {
    // console.log(res);
    loadingStore.loading = false;
  });
}

const retrieveCount = () => {
  loadingStore.loading = true;
  let paramObj = {
    corpId: 0,
    corpUserId: 0,
  }
  adminMatchingService.retrieveMatchingTotalCount(paramObj)
      .then( (res) => {
        loadingStore.loading = false;
        if(res.data.code.toLowerCase() === 'success') {
          resultCount.value = res.data.result;
        } else {
        }
      }).catch( (res) => {
        loadingStore.loading = false;
  });
}

const clearSearchInput = () => {
  searchInputs.value = {
    searchStateGbs : ['01', '02', '03', '04', '05', '06', '07', '08'],
    searchDurationType : 'all',
    startYmd : '',
    endYmd : '',
    searchInput : '',
  };
  stateGbListRef.value.initOptions();
}

const onClickSearch = () => {
  currentPage.value = 1;
  retrieveList();
}

const onSelectPage = (pageNo) => {
  currentPage.value = pageNo;
  retrieveList();
}

const onSelectPageSize = (option) => {
  pageSize.value = option.value;
  currentPage.value = 1;
  retrieveList();
}

</script>

<template>
  <div class="container">
    <div class="flex-wrap admin">
      <SNBAdmin class="snb" />
      <div class="content">
        <div class="sub-title">
          <h3>기업별 채용 현황</h3>
        </div>

        <div class="table-form">
          <table class="horizontal all-border">
            <tr class="tr-focus-top tr-focus-side">
              <th rowspan="2">전체 현황</th>
              <th class="total" >전체</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
            </tr>
            <tr class="tr-focus-side tr-focus-bottom">
              <td>{{ resultCount.totalState }}</td>
              <td>{{ resultCount.totalState01 }}</td>
              <td>{{ resultCount.totalState02 }}</td>
              <td>{{ resultCount.totalState03 }}</td>
              <td>{{ resultCount.totalState04 }}</td>
              <td>{{ resultCount.totalState05 }}</td>
              <td>{{ resultCount.totalState06 }}</td>
              <td>{{ resultCount.totalState07 }}</td>
              <td>{{ resultCount.totalState08 }}</td>
            </tr>
            <tr>
              <th rowspan="2">인재 채용 현황</th>
              <th class="total">전체</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
            </tr>
            <tr>
              <td>{{ resultCount.totalResState }}</td>
              <td>{{ resultCount.resState01 }}</td>
              <td>{{ resultCount.resState02 }}</td>
              <td>{{ resultCount.resState03 }}</td>
              <td>{{ resultCount.resState04 }}</td>
              <td>{{ resultCount.resState05 }}</td>
              <td>{{ resultCount.resState06 }}</td>
              <td>{{ resultCount.resState07 }}</td>
              <td>{{ resultCount.resState08 }}</td>
            </tr>
            <tr>
              <th rowspan="2">채용 제안 현황</th>
              <th class="total">전체</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
            </tr>
            <tr>
              <td>{{ resultCount.totalPropState }}</td>
              <td>{{ resultCount.propState01 }}</td>
              <td>{{ resultCount.propState02 }}</td>
              <td>{{ resultCount.propState03 }}</td>
              <td>{{ resultCount.propState04 }}</td>
              <td>{{ resultCount.propState05 }}</td>
              <td>{{ resultCount.propState06 }}</td>
              <td>{{ resultCount.propState07 }}</td>
              <td>{{ resultCount.propState08 }}</td>
            </tr>
          </table>
        </div>
        <br>
        <div class="filter-wrap" :class="{'open': searchOpen}">
          <p @click="searchOpen = !searchOpen">검색</p>
          <div class="filter">
            <dl>
              <dt>상태</dt>
              <dd>
                <KSACheckBoxGroup
                    ref="stateGbListRef"
                    className="mr-md"
                    name="searchStateGbs"
                    v-model="searchInputs.searchStateGbs"
                    :options="corpState" />
              </dd>
            </dl>
            <dl>
              <dt>채용제안일</dt>
              <dd class="text-box">
                <div class="grid">
                  <template v-for="group in searchDurationTypeGroup" key="item.value" >
                    <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="searchInputs.searchDurationType" :value="group.value" />
                  </template>
                  <div style="display: inline-block;" v-show="searchInputs.searchDurationType !== 'all'">
                    <KSADatepickerTerms
                        :startDate="searchInputs.startYmd"
                        :endDate="searchInputs.endYmd"
                        @update:startDate="(value)=> searchInputs.startYmd = value"
                        @update:endDate="(value)=> searchInputs.endYmd = value"
                    />
                  </div>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>기업명</dt>
              <dd>
                <KSAInput class="text-box" v-model="searchInputs.searchInput"  placeholder="기업명을 입력해 주세요." />
              </dd>
            </dl>
            <div class="btn-wrap">
              <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
              <KSAPrimaryButton text="검색" @click="onClickSearch" />
            </div>
          </div>
        </div>
        <div class="list-top">
          <p>총 게시물 <span>{{ $number(totalCount)}}</span>개</p>
          <div>
            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
          </div>
        </div>
        <div class="table-form">
          <table class="horizontal all-border">
            <tr>
              <th rowspan="2">NO</th>
              <th rowspan="2">기업명</th>
              <th rowspan="2">최초 가입일</th>
              <th colspan="8">채용 현황</th>
            </tr>
            <tr>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
              <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
              <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
              <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
            </tr>
            <tr v-for="matching in list">
              <td>{{ matching.boardNo }}</td>
              <td class="link"><RouterLink :to="{name: 'MatchingCorpDetail', params:{id: matching.corpId}}">{{ matching.corpName }}</RouterLink></td>
              <td>{{ $yyyymmddHHmmss(matching.createDate) }}</td>
              <td>{{ matching.state01 }}</td>
              <td>{{ matching.state02 }}</td>
              <td>{{ matching.state03 }}</td>
              <td>{{ matching.state04 }}</td>
              <td>{{ matching.state05 }}</td>
              <td>{{ matching.state06 }}</td>
              <td>{{ matching.state07 }}</td>
              <td>{{ matching.state08 }}</td>
            </tr>
          </table>
        </div>
        <div class="result-none" v-if="list.length === 0">
          검색 결과가 없습니다.
        </div>
        <Paginate
            v-if="list.length > 0"
            v-model="currentPage"
            :page-count="totalPages"
            :click-handler="onSelectPage"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/theme";

</style>