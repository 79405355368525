<script setup>
    import { ref, defineEmits, defineExpose } from "vue";
    import KSAInput from './input/KSAInput.vue'
    import KSASelectEmail from './select/KSASelectEmail.vue'

    const e1 = ref('')
    const e2 = ref('')

    const props = defineProps({
        email1: { type: String },
        email2: { type: String },
    });
    const emit = defineEmits(["update:email1", "update:email2", 'blur']);

    const inputRef = ref(null)
    const inputRef2 = ref(null)

    const onInputEmail1 = () => {
        emit('update:email1', e1.value);
    }

    const onInputEmail2 = () => {
        emit('update:email2', e2.value);
    }

    const onSelectedEmail = (value) => {
        if(!(value.indexOf('직접') > -1)) {
            e2.value = value;
            emit('update:email2', value);
        }
    }

    function init(email1, email2) {
        e1.value = email1;
        e2.value = email2;
    }

    const focus = (index) => {
        if(!index) {
            inputRef.value.focus();
        } else {
            inputRef2.value.focus();
        }
    }

    defineExpose({
        init,
        focus,
    })

    const onBlur = () => {
        emit('blur');
    }

</script>

<template>
    <div class="e-mail">
        <KSAInput ref="inputRef" v-model="e1" @input="onInputEmail1" @blur="onBlur"/>
        <span>@</span>
        <KSAInput ref="inputRef2" v-model="e2" @input="onInputEmail2" @blur="onBlur"/>
        <KSASelectEmail @selectOption="onSelectedEmail" />
    </div>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    .e-mail {
        display: flex;
        align-items: center;
        div {
            width: 100%;
            &:last-child {
                margin-left: 10px;
            }
        }
        span {
            margin: 0 4px;
        }
    }
</style>