import InterfaceView from '../pages/sysadmin/views/guide/InterfaceView.vue'
import SigninView from '../pages/sysadmin/views/signin/SigninView.vue'
import SchedulerManageView from '../pages/sysadmin/views/scheduler/SchedulerManageView.vue'
import SchedulerManageCreateView from '../pages/sysadmin/views/scheduler/SchedulerManageCreateView.vue'
import SchedulerManageDetailView from '../pages/sysadmin/views/scheduler/SchedulerManageDetailView.vue'

export default [
    {
        path: '/sysadmin',
        redirect: '/sysadmin/signin',
        children: [
            {
                path: 'signin',
                name: 'SigninSysAdmin',
                component: SigninView,
            },
            {
                path: 'scheduler',
                meta: {
                    title: '스케쥴러 관리',
                    requiresAuth: true,
                    admin: true,
                    sysadmin: true,
                },
                children: [
                    {
                        path: '',
                        name: 'SchedulerManage',
                        component: SchedulerManageView,
                    },
                    {
                        path: 'create',
                        name: 'SchedulerManageCreate',
                        component: SchedulerManageCreateView,
                    },
                    {
                        path: 'detail',
                        name: 'SchedulerManageDetail',
                        component: SchedulerManageDetailView,
                    }
                ]
            },
            {
                path: 'interface',
                name: 'Interface',
                component: InterfaceView
            },
        ]
    },
]