<script setup>
    import { ref, onMounted, computed, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import {useMyPageLectDetailStore} from "../../../stores/mypage/mypageLectDetailStore";
    import lectureUserService from "../../../services/lecture/lectureUserService";
    import _ from 'lodash'

    const router = useRouter()
    const getSystemCodeValues = inject('systemCodeValues');
    const loadingStore = usePageLoadingStore()
    const lectureStore = useMyPageLectDetailStore()

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    const selectedLectStateGb = ref('');
    const lectStateGbList = [
        { label:"전체", value: "" },
        { label:"신청완료", value: "01" },
        { label:"수강중", value: "07" },
        { label:"수강종료", value: "08" },
    ];

    const alertModal = ref(null);
    const confirmModal = ref(null);
    const modalContent = ref([]);

    onMounted(() => {
        retrieveList();
    })

    // 온라인
    const letureOnlineList = computed(()=>{
        return _.filter(list.value, (lect) => {
            return lect.lectStateGb !== '10' && lect.lectStateGb !== '09' && lect.lectGb === '01';
        });
    })

    // 오프라인
    const letureOfflineList = computed(()=>{
        return _.filter(list.value, (lect) => {
            return lect.lectStateGb !== '10' && lect.lectStateGb !== '09' && lect.lectStateGb.indexOf(selectedLectStateGb.value) > -1 && lect.lectGb === '02';
        });
    })

    const retrieveList = async () => {
        loadingStore.loading = true;
        await getSystemCodeValues();
        lectureUserService.retrieveMyLectureListByLectGb()
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result)
                    list.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectLectStateGb = (option) => {
        selectedLectStateGb.value = option.value;
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const goLectureDetailView = (lecture) => {
        lectureStore.setLecture(lecture)
        router.push({name: 'MyPageLectureDetail', params:{id: lecture.lectId}})
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content">
                    <div class="lecture-wrap">
                        <div class="sub-title">
                            <h3>수강 현황</h3>
                        </div>
                        <div class="content-title">
                            온라인 강의<span v-if="letureOnlineList.length > 0">({{ letureOnlineList.length }})</span>
                        </div>
                        <ul class="lecture-list">
                            <li v-for="lecture in letureOnlineList" @click="goLectureDetailView(lecture)">
                                <div class="lecture-img">
                                    <KSAImage :src="lecture.lectThombDir"/>
                                </div>
                                <div class="lecture-text">
                                    <span class="badge" :class="{'state1': ['01','02','03','04','05'].indexOf(lecture.lectStateGb) > -1, 'state2': lecture.lectStateGb === '06', 'state3': lecture.lectStateGb === '07', 'state4': (lecture.lectStateGb === '08' || lecture.lectStateGb === '09' || lecture.lectStateGb === '10')}">
                                        {{ $gbNameKR('LECT_STATE_GB1', lecture.lectStateGb) }}
                                    </span>
                                    <h4>{{ lecture.lectName }}</h4>
                                    <div class="lecture-info">
                                        <p class="date">
                                            <i>
                                                <b>진도율</b>
                                                <span>{{ lecture.progRate || 0 }}%</span>
                                            </i>
                                        </p>
                                        <p class="date">
                                            <i>
                                                <b>교육기간</b>
                                                <span v-if="lecture.lectTermGb === '01'">{{ lecture.lectTerm}}일</span>
                                                <span v-else>{{ $yyyymmdd(lecture.lectStartYmd) }} ~ {{ $yyyymmdd(lecture.lectEndYmd) }}</span>
                                            </i>
                                        </p>
                                        <p class="date">
                                            <i><b>상태</b>
                                            <span>{{ lecture.finYn === 'Y' ? '수료'
                                                   : lecture.finYn === 'N' ? 'Fail'
                                                   : lecture.finYn === 'D' ? '평가전'
                                                   : '-' }}</span></i>
                                        </p>
                                    </div>
<!--                                    <div class="lecture-content">-->
<!--                                        {{ lecture.lectDesc }}-->
<!--                                    </div>-->
                                </div>
                            </li>
                        </ul>
                        <div class="result-none lecture-none" v-if="letureOnlineList.length === 0">
                            수강중인 강의가 없습니다.
                        </div>
                        <div class="content-title grid space-between">
                            <div>
                                오프라인 강의<span v-if="letureOfflineList.length > 0">({{ letureOfflineList.length }})</span>
                            </div>
                            <KSASelect :options="lectStateGbList" class="select" @selectOption="onSelectLectStateGb"/>
                        </div>
                        <ul class="lecture-list">
                            <li v-for="lecture in letureOfflineList" @click="goLectureDetailView(lecture)">
                                <div class="lecture-img">
                                    <KSAImage :src="lecture.lectThombDir"/>
                                </div>
                                <div class="lecture-text">
                                    <span class="badge" :class="{'state1': ['01','02','03','04','05'].indexOf(lecture.lectStateGb) > -1, 'state2': lecture.lectStateGb === '06', 'state3': lecture.lectStateGb === '07', 'state4': (lecture.lectStateGb === '08' || lecture.lectStateGb === '09' || lecture.lectStateGb === '10')}">
                                        {{ $gbNameKR('LECT_STATE_GB1', lecture.lectStateGb) }}
                                    </span>
                                    <h4>{{ lecture.lectName }}</h4>
                                    <div class="lecture-info">
<!--                                        <p class="date">-->
<!--                                            <i>-->
<!--                                                <b>상태</b>&nbsp;-->
<!--                                                <span>{{ $gbNameKR('LECT_STATE_GB', lecture.lectStateGb) }}</span>-->
<!--                                            </i>-->
<!--                                        </p>-->
                                        <p class="date" v-if="['01','02','03','04','05'].indexOf(lecture.lectStateGb) > -1">
                                            <i>
                                                <b>면접기간</b>&nbsp;
                                                <span v-if="lecture.intvTermGb === '01'">상시</span>
                                                <span v-else>{{ $yyyymmdd(lecture.intvStartYmd) }} ~ {{ $yyyymmdd(lecture.intvEndYmd) }}</span>
                                            </i>
                                        </p>
                                        <p class="date" v-if="!(['01','02','03','04','05'].indexOf(lecture.lectStateGb) > -1)">
                                            <i>
                                                <b>교육기간</b>&nbsp;
                                                <span v-if="lecture.lectTermGb === '01'">신청일로부터 {{ lecture.lectTerm }}일</span>
                                                <span v-else>{{ $yyyymmdd(lecture.lectStartYmd) }} ~ {{ $yyyymmdd(lecture.lectEndYmd) }}</span>
                                            </i>
                                        </p>
                                        <p class="date" v-if="!(['01','02','03','04','05'].indexOf(lecture.lectStateGb) > -1)">
                                            <i>
                                                <b>교육장소</b>&nbsp;
                                                <span>{{ lecture.lectPlace }}</span>
                                            </i>
                                        </p>
                                        <p class="date" v-if="['01','02','03','04','05'].indexOf(lecture.evalStateGb) > -1">
                                            <i>
                                                <b>상태</b>
                                                <span>{{ $gbNameKR('EVAL_STATE_GB1', lecture.evalStateGb) }}</span>
                                            </i>
                                        </p>
                                    </div>
<!--                                    <div class="lecture-content">-->
<!--                                        {{ lecture.lectDesc }}-->
<!--                                    </div>-->
                                </div>
                            </li>
                        </ul>
                        <div class="result-none lecture-none" v-if="letureOfflineList.length === 0">
                            수강중인 강의가 없습니다.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="삭제" cancelButtonText="취소" />
</template>