// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useMailTemplateStore = defineStore('mailTemplateStore', () => {
    const msgGb = ref('')

    return {
        msgGb,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMailTemplateStore, import.meta.hot))
}
