<script setup>
    import { ref, onMounted, inject } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import surveyService from "../../../services/lecture/surveyService";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    const getSystemCodeValues = inject('systemCodeValues');

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name: 'LectureSurveyQuestionList'});
        } else {
            retrieveSurveyQuestionDetail(id);
        }
    })

    const surveyQuestionDetail = ref({
        surveyQId: route.params.id || '',
        surveyQGb: '',
        surveyQType: '',
        surveyQTitle: '',
        etcYn: '',
        useYn: '',
        choiceList: [],
        createDate: '',
        modifyDate: '',
    });

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])
    const deletable = ref(false);

    const retrieveSurveyQuestionDetail = async (id) => {
        loadingStore.loading = true;
        await getSystemCodeValues();
        surveyService.retrieveSurveyQuestion(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    surveyQuestionDetail.value = result;
                }
                retrieveSurveyQuestionDeletable(id);
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const retrieveSurveyQuestionDeletable = (id) => {
        loadingStore.loading = true;
        surveyService.retrieveSurveyQuestionDeletable(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    deletable.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const deleteSurveyQuestion = async () => {
        modalContent.value = ["문항을 삭제할까요?", "삭제 시 해당 문항은 만족도 평가에 노출되지 않으며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show()
        if(ok){
            _deleteSurveyQuestion()
        }
    }

    function _deleteSurveyQuestion() {
        loadingStore.loading = true;
        surveyService.deleteSurveyQuestion(surveyQuestionDetail.value.surveyQId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    goBack();
                } else {
                    modalContent.value = ["삭제를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            console.log(res);
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
        });
    }

    const copySurveyQuestion = () => {
        router.push({name:'LectureSurveyQuestionCreate', params:{id: surveyQuestionDetail.value.surveyQId}})
    }

    const goBack = () => {
        router.push({name:'LectureSurveyQuestionList'})
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>문항 상세</h3>
                </div>
                <div class="btn-wrap space-between mb-xs">
                    <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                    <div>
                        <KSASecondaryButton class="gray_button" text="문항 복사" @click="copySurveyQuestion" />
                        <KSASecondaryButton class="red_button" text="삭제" @click="deleteSurveyQuestion" :disabled="!deletable" />
                    </div>
                </div>

                <div class="table-form mb-sm">
                    <table class="vertical">
                        <colgroup>
                            <col width="200px" />
                            <col />
                            <col width="200px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>사용 여부</th>
                            <td colspan="3">{{ surveyQuestionDetail.useYn === 'Y' ? '사용' : '미사용' }}</td>
                        </tr>
                        <tr>
                            <th>문항 구분</th>
                            <td colspan="3">{{ $gbNameKR('SURVEY_Q_GB', surveyQuestionDetail.surveyQGb) }}</td>
                        </tr>
                        <tr>
                            <th>문항 유형</th>
                            <td colspan="3">{{ $gbNameKR('SURVEY_Q_TYPE', surveyQuestionDetail.surveyQType) }}</td>
                        </tr>
                        <tr>
                            <th>문항</th>
                            <td colspan="3">{{ surveyQuestionDetail.surveyQTitle }}</td>
                        </tr>
                        <tr v-if="surveyQuestionDetail.surveyQType === '03'">
                            <th>다중응답형 보기</th>
                            <td colspan="3">
                                <ul>
                                    <li v-for="(choice, index) in surveyQuestionDetail.choiceList">
                                        {{ index+1 }}. {{ choice.surveyChoiceContent }}
                                    </li>
                                    <li v-if="surveyQuestionDetail.etcYn === 'Y'">
                                        {{ surveyQuestionDetail.choiceList.length+1 }}. 기타
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr v-if="surveyQuestionDetail.surveyQType === '04'">
                          <th>5점 척도 답변 보기</th>
                          <td colspan="3">
                            <ul>
                              <li v-for="(point, index) in surveyQuestionDetail.pointOptions">
                                {{ index+1 }}. {{ point.surveyChoiceContent }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                            <th>등록일</th>
                            <td>{{ $yyyymmddOrHHmm(surveyQuestionDetail.createDate) }}</td>
                            <th>최근 수정일</th>
                            <td>{{ $yyyymmddOrHHmm(surveyQuestionDetail.modifyDate) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="삭제" />
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.table-form {
  table {
    &.vertical {
      td {
        .horizontal {
          th {
            text-align: center;
          }
        }
      }
    }
    &.horizontal {
      tr:hover {
        background: transparent;
      }
    }
  }
}
</style>