<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>학점인정교육</h3>
            </div>
            <img src="/src/assets/images/CreditAcceptableLecture_info.jpg" alt="학점인정교육이란" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .container .inner img { width: 100%; }
</style>