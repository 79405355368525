<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, defineExpose, onMounted, computed, nextTick } from "vue";
import KSAInputResidenceSelect from "../../../../../components/ui-components/input/KSAInputResidenceSelect.vue";
import KSACheckBoxGroup from "../../../../../components/ui-components/KSACheckBoxGroup.vue";

const baseModal = ref(null);
const resolvePromise = ref(null);
const residenceRef = ref(null) // residence2 체크박스

const area0 = ["서울특별시","인천광역시","대전광역시","광주광역시","대구광역시","울산광역시","부산광역시","경기도","강원도","충청북도","충청남도","전라북도","전라남도","경상북도","경상남도","제주도"];
const area1 = ["강남구","강동구","강북구","강서구","관악구","광진구","구로구","금천구","노원구","도봉구","동대문구","동작구","마포구","서대문구","서초구","성동구","성북구","송파구","양천구","영등포구","용산구","은평구","종로구","중구","중랑구"];
const area2 = ["계양구","남구","남동구","동구","부평구","서구","연수구","중구","강화군","옹진군"];
const area3 = ["대덕구","동구","서구","유성구","중구"];
const area4 = ["광산구","남구","동구", "북구","서구"];
const area5 = ["남구","달서구","동구","북구","서구","수성구","중구","달성군"];
const area6 = ["남구","동구","북구","중구","울주군"];
const area7 = ["강서구","금정구","남구","동구","동래구","부산진구","북구","사상구","사하구","서구","수영구","연제구","영도구","중구","해운대구","기장군"];
const area8 = ["고양시","과천시","광명시","광주시","구리시","군포시","김포시","남양주시","동두천시","부천시","성남시","수원시","시흥시","안산시","안성시","안양시","양주시","오산시","용인시","의왕시","의정부시","이천시","파주시","평택시","포천시","하남시","화성시","가평군","양평군","여주군","연천군"];
const area9 = ["강릉시","동해시","삼척시","속초시","원주시","춘천시","태백시","고성군","양구군","양양군","영월군","인제군","정선군","철원군","평창군","홍천군","화천군","횡성군"];
const area10 = ["제천시","청주시","충주시","괴산군","단양군","보은군","영동군","옥천군","음성군","증평군","진천군","청원군"];
const area11 = ["계룡시","공주시","논산시","보령시","서산시","아산시","천안시","금산군","당진군","부여군","서천군","연기군","예산군","청양군","태안군","홍성군"];
const area12 = ["군산시","김제시","남원시","익산시","전주시","정읍시","고창군","무주군","부안군","순창군","완주군","임실군","장수군","진안군"];
const area13 = ["광양시","나주시","목포시","순천시","여수시","강진군","고흥군","곡성군","구례군","담양군","무안군","보성군","신안군","영광군","영암군","완도군","장성군","장흥군","진도군","함평군","해남군","화순군"];
const area14 = ["경산시","경주시","구미시","김천시","문경시","상주시","안동시","영주시","영천시","포항시","고령군","군위군","봉화군","성주군","영덕군","영양군","예천군","울릉군","울진군","의성군","청도군","청송군","칠곡군"];
const area15 = ["거제시","김해시","마산시","밀양시","사천시","양산시","진주시","진해시","창원시","통영시","거창군","고성군","남해군","산청군","의령군","창녕군","하동군","함안군","함양군","합천군"];
const area16 = ["서귀포시","제주시","남제주군","북제주군"];

const areaObj = {
    "서울특별시": area1,
    "인천광역시": area2,
    "대전광역시": area3,
    "광주광역시": area4,
    "대구광역시": area5,
    "울산광역시": area6,
    "부산광역시": area7,
    "경기도": area8,
    "강원도": area9,
    "충청북도": area10,
    "충청남도": area11,
    "전라북도": area12,
    "전라남도": area13,
    "경상북도": area14,
    "경상남도": area15,
    "제주도": area16,
}

// parent에 선택된 지역 데이터 보내기 위해 emit 선언.
const emit = defineEmits(['submitSelection']);

const selected = ref({
    residence1: area0[0] || '',
    residence2: [],
})

const selectedResult = ref([]); // 최종 선택된 지역들
const first = ref(true); // 창 처음 열렸을 때, updateSelectedResidences 의 체크 안된 항목 삭제 안되게 하기 위한 선언.

onMounted( async ()=>{
    await nextTick();
})

// residence1 클릭 시,
const clickSelectedResidence1 = (option) => {
    selected.value.residence1 = option;
    selected.value.residence2 = []; // residence2 데이터 초기화
    //residenceRef.value.initOptionsWithValues([]); // residence2 체크박스 초기화

    // 현재 지역에서 기체크된 항목을 다시 체크박스에 체크 해 줌.
    selectedResult.value.forEach((res) => {  // filter
        const [area, district] = res.split(" ");
        // 같은 지역인 경우에
        if (area === option) {
            if(district === '전체') {
                selected.value.residence2 = areaObj[option];
            } else {
                selected.value.residence2.push(district);
            }
        }
    });
    setTimeout(async () => {
        residenceRef.value.initOptionsWithValues(selected.value.residence2);
    }, 20);
    //residenceRef.value.initOptionsWithValues(selected.value.residence2);
}

// residence1에 따른 residence2 전체 리스트 생성
const residence2Options = computed(() => {
    const options = areaObj[selected.value?.residence1] || [];
    return options.map(option => ({ label: option, value: option }));
});

// residence2 체크/체크해제 시 최종 목록(selectedResult)에 추가/삭제
const updateSelectedResidences = (checkedOptions) => {
    const currentArea = selected.value.residence1;
    const allText = `${currentArea} 전체`;

    if(checkedOptions.length === areaObj[currentArea].length) {
        // 전체 체크된 경우
        // 전체 체크된 residence1에 해당하는 모든 항목 제거
        selectedResult.value = selectedResult.value.filter(res => !res.startsWith(currentArea));
        // "residence1 전체" text 추가.
        selectedResult.value.push(allText);
    } else {
        checkedOptions.forEach((option) => {
            const residenceText = `${currentArea} ${option}`;
            // 선택된 지역을 누적 저장
            if (!selectedResult.value.includes(residenceText)) {
                selectedResult.value.push(residenceText);
            }
      });

      // 현재 residence2가 열린 residence1 지역에서만 체크 해제된 항목을 필터링
      if(!first.value) {  // 첫 진입땐 안 타게
          selectedResult.value = selectedResult.value.filter((res) => {
              const [area, district] = res.split(" ");
              // 같은 지역인 경우에만 필터링 적용
              if (area === currentArea) {
                  return checkedOptions.includes(district);
              }
              return true; // 다른 지역은 유지
          });
      }
    }
    first.value = false;
};

// 삭제 버튼
const deleteResidence = (option) => {
    // 1. selectedResult 에서 제거
    selectedResult.value = selectedResult.value.filter(
        (res) => res !== option
    );
    const [area1] = option.split(" ");
    const currentArea = selected.value.residence1;

    // 2. 현재 열린 지역과 같은 지역의 데이터를 삭제했다면, 체크박스 변경 처리
    if(area1 === currentArea) {
        selected.value.residence2 = [];
        selectedResult.value.forEach((res) => {
            const [area2, district2] = res.split(" ");
            // 같은 지역인 경우에
            if (area1 === area2) {
                if(district2 === '전체') {
                    selected.value.residence2 = areaObj[currentArea];
                } else {
                    selected.value.residence2.push(district2);
                }
            }
        });
        // residenceRef.value.initOptionsWithValues(selected.value.residence2);
        setTimeout(async () => {
            residenceRef.value.initOptionsWithValues(selected.value.residence2);
        }, 20);
    }
};

// 검색에서 추가하고자하는 지역을 클릭했을 경우.
const handleResidenceUpdate = (residence) => {
    //받은 residence 데이터로 필요한 처리 수행
    const currentArea = selected.value.residence1;
    const [area, district] = residence.split(" ");
    const allText = `${area} 전체`;

    // 1. selectedResult에 area의 district가 전체 중 하나만 제외된 상태라면,
    // 모두 삭제하고 "area 전체"로 변경
    const areaDistricts = areaObj[area] || [];
    // 전체중 하나만 제외된 상태면 true
    const isAlmostAllSelected = areaDistricts.filter(d => selectedResult.value.includes(`${area} ${d}`)).length === areaDistricts.length - 1; //

    if (isAlmostAllSelected && !selectedResult.value.includes(allText)) {
        selectedResult.value = selectedResult.value.filter(res => !res.startsWith(area)); // 기존 area의 항목 삭제
        selectedResult.value.push(allText); // "area 전체" 추가
    }
    // 2. residence로 들어온 area값이 이미 "area 전체"로 selectedResult에 있다면 추가하지 않음
    else if (!selectedResult.value.includes(allText) && !selectedResult.value.includes(residence)) {
        selectedResult.value.push(residence);
    }

    // 3. residenceRef 업데이트는 현재 열려있는 페이지의 area에 대해서만 수행
    if(area === currentArea) {
        selectedResult.value.forEach((res) => {
            const [area2, district2] = res.split(" ");
            // 같은 지역인 데이터만
            if(area2 === currentArea) {
                if(district2 === '전체') {
                    selected.value.residence2 = areaObj[currentArea];
                } else {
                    selected.value.residence2.push(district2);
                }
            }
        });
        residenceRef.value.initOptionsWithValues(selected.value.residence2);
    }
}

// selectedResult에 선택된 area일 경우 class on: true
const isOptionSelected = (option) => {
    return selectedResult.value?.some(res => res.startsWith(option)) ?? false;
};

// 초기화 버튼
const reset = () => {
    selectedResult.value = [];
    selected.value.residence1 = area0[0] || '';
    selected.value.residence2 = [];
    residenceRef.value.initOptionsWithValues([]);
};

const show = async (initialResidences = []) => {
    selectedResult.value = [...initialResidences];

    // DOM 로딩 기다림.
    await nextTick();

    setTimeout(async () => {
        // 현재 지역에서 체크된 항목을 다시 체크 해 줌.
        const currentArea = selected.value.residence1; // 현재 선택된 지역
        selectedResult.value.forEach((res) => {
            const [area, district] = res.split(" ");
            // 같은 지역인 경우에
            if (area === currentArea) {
                if(district === '전체') {
                    selected.value.residence2 = areaObj[currentArea];
                } else {
                    selected.value.residence2.push(district);
                }
            }
        });
        await residenceRef.value.initOptionsWithValues(selected.value.residence2);
    }, 500);

    baseModal.value.open();

    return new Promise((resolve, _) => {
        resolvePromise.value = resolve;
    });
};

const cancel = () => {
    baseModal.value.close();
    resolvePromise.value(false);

    selected.value.residence1 = area0[0] || '';
    selected.value.residence2 = [];
    residenceRef.value.initOptionsWithValues([]);
    first.value = true;
};

// 저장 버튼 클릭 시 부모로 데이터 전달
const confirm = () => {
    baseModal.value.close();
    emit('submitSelection', selectedResult.value); // 부모에게 데이터 전달

    selected.value.residence1 = area0[0] || '';
    selected.value.residence2 = [];
    residenceRef.value.initOptionsWithValues([]);
    first.value = true;
};

defineExpose({
  show,
})

</script>
<template>
    <Modal ref="baseModal" class="vld-parent">
        <div class="modal-inner lg-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h2>지역 선택</h2>
            </div>
            <div class="modal-body">
                <KSAInputResidenceSelect
                    ref="residenceRef"
                    class=text-box
                    placeholder="지역을 입력해 주세요"
                    msg-color="#E71414"
                    @update:modelValue="handleResidenceUpdate"
                />
                <div class="residence-wrapper">
                    <ul class="residence1">
                        <li v-for="option in area0"
                            :key="option"
                            @click="clickSelectedResidence1(option);"
                            :class="{ on: isOptionSelected(option) }"
                        >
                            {{ option }}
                        </li>
                    </ul>
                    <div class="residence2">
                        <KSACheckBoxGroup
                            ref="residenceRef"
                            className="flex-item mb-xs"
                            v-model="selected.residence2"
                            :options="residence2Options"
                            @update:modelValue="updateSelectedResidences"
                            :allUnchecked="true"
                        />
                    </div>
                </div>
                <div class="residence-selected">
                    <span
                        v-for="option in selectedResult"
                        :key="option"
                    >
                        {{ option }}<i @click="deleteResidence(option)" >삭제</i>
                    </span>
                </div>
            </div>
            <small class="btn-reset" @click="reset">초기화</small>
            <div class="modal-footer" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" text="저장" @click="confirm" />
            </div>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
  text-align: left;
  .residence-wrapper {
    display: flex;
    align-items: center;
    .residence1 {
      width: 200px;
      border: 1px solid #E4E5F0;
      height: 308px;
      padding: 20px;
      margin-top: 10px;
      overflow: auto;
      li {
        margin-bottom: 4px;
        cursor: pointer;
        &.on {
          color: #3843AF;
        }
      }
    }
    .residence2 {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      border: 1px solid #E4E5F0;
      height: 308px;
      margin-top: 10px;
      width: 600px;
      border-left: 0;
      padding: 20px;
      overflow: auto;
    }
  }
  .residence-selected {
    border: 1px solid #E4E5F0;
    padding: 20px;
    margin-top: 10px;
  }
  .btn-reset {
    position: relative;
    float: right;
    color: #86889A;
    font-size: 16px;
    margin: 6px 30px 0 0;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      left: -24px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(/src/assets/images/icon-reset.png) no-repeat center;
      background-size: cover;
    }
  }
  .modal-footer {
    display: block;
    padding: 0 30px;
  }
}
</style>