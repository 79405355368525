import axios from 'axios'
import { useRouter } from 'vue-router'
import { useUserStore } from '../pages/user/stores/user.js'

const dClassApiUrl = import.meta.env.VITE_DCLASSAPI_URL;

// axios 인스턴스를 생성합니다.
const instance = axios.create({
    baseURL: dClassApiUrl,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    }
})

/*
    1. 요청 인터셉터
    2개의 콜백 함수를 받습니다.
*/
instance.interceptors.request.use(
    function (config) {
        // 요청 성공 직전 호출됩니다.
        // axios 설정값을 넣습니다. (사용자 정의 설정도 추가 가능)
        const { user } = useUserStore()
        // console.log("interceptors request");
        // console.log("token");
        // console.log(user.token);
        // console.log("refreshToken");
        // console.log(user.refreshToken);

        // console.log(config.headers);
        if(config.headers === undefined || config.headers["Content-Type"].indexOf('multipart/form-data') === -1) {
            config.headers["Content-Type"] = "application/json; charset=utf-8";
        }
        if(user.token) config.headers["Authorization"] = user.token;
        if(user.refreshToken) config.headers["X-Danbee-Refresh-Header"] = user.refreshToken;
        return config;
    },
    function (error) {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error);
    }
);

/*
    2. 응답 인터셉터
    2개의 콜백 함수를 받습니다.
*/
instance.interceptors.response.use(
    function (response) {
        /*
            http status가 200인 경우
            응답 성공 직전 호출됩니다.
            .then() 으로 이어집니다.
        */
        // console.log("interceptors response");
        // console.log(response);

        const { setToken, setRefreshToken } = useUserStore()
        const authorization = response.headers['authorization'];
        const x_danbee_refresh_header = response.headers['x_danbee_refresh_header'];
        // console.log("token");
        // console.log(authorization);
        // console.log("refreshToken");
        // console.log(x_danbee_refresh_header);
        setToken(authorization);
        setRefreshToken(x_danbee_refresh_header);

        return response;
    },

    function (error) {
        /*
            http status가 200이 아닌 경우
            응답 에러 직전 호출됩니다.
            .catch() 으로 이어집니다.
        */
        // console.log(error);
        const data = error.response;
        const status = data.status? data.status:data.result.status;
        if(status === 401 || status === 409) {
            const { isAdmin, setUser } = useUserStore()
            setUser({});

            if(status === 409){
                localStorage.setItem("ERR409", "");
            }

            if(location.href.indexOf('signin') === -1)  {
                if(isAdmin) {
                    location.href = "/admin/signin";
                } else {
                    location.href = "/signin";
                }
            }
        }
        return Promise.reject(error);
    }
);

export default instance;