<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import {useCertificateStore} from "../../../stores/mypage/certificateStore";
    import { storeToRefs } from 'pinia';
    import moment from 'moment';
    import _ from 'lodash';

    const certStore = useCertificateStore()
    const { certificate } = storeToRefs(certStore);

    const certNum = ref('');
    const lectDetail = ref({})
    const lectIndexes = ref([])
    const userDetail = ref({})

    onMounted(()=>{
        // console.log(certificate.value);
        try {
            let header = document.querySelector('header');
            let footer = document.querySelector('footer');
            let globalNav = document.querySelector('.global-nav');
            header.style.display = "none";
            footer.style.display = "none";
            globalNav.style.display = "none";
        } catch(e){}

        const newCertificate = _.cloneDeep(certificate.value);

        lectDetail.value = newCertificate.lectDetail;
        lectIndexes.value = newCertificate.lectIndexes;
        userDetail.value = newCertificate.userDetail;
        userDetail.value.birthYmd = userDetail.value.birthYmd.substring(0,4) + '-' + userDetail.value.birthYmd.substring(4,6) + '-' + userDetail.value.birthYmd.substring(6);
        // console.log(lectDetail.value);
        // console.log(lectIndexes.value);
        // console.log(userDetail.value);
        certNum.value = `제 ${moment().format('YYYY')}-${lectDetail.value.lectId}-${userDetail.value.userCd}호`;
        // console.log(certNum.value);
        setTimeout(()=>{
            print();
        }, 100);
    })

    onBeforeUnmount(()=>{
    })

    const print = () => {
        window.print();
    }

    window.onbeforeprint = function () {
    }

    window.onafterprint = function () {
    }

</script>

<template>
    <div id="cert-wrap">
        <div class="print print-cer1">
            <img class="bg" src="/src/assets/images/certificates_bg1.svg" alt="수료증">
            <p class="code">{{ certNum }}</p>
            <div class="user">
                <p class="name">{{ userDetail.userName }}</p>
                <p class="agency">
                  <span v-if="userDetail.userGb === 'STUDENT' || userDetail.userGb === 'SCHOOL'">({{ userDetail.school}}, {{ userDetail.birthYmd }})</span>
                  <span v-else-if="userDetail.userGb === 'COMPANY'" >({{ userDetail.corporation.corpName}}, {{ userDetail.birthYmd }})</span>
                  <span v-else >({{ userDetail.birthYmd }})</span>
                </p>
            </div>
            <div class="lecture">
                <p class="subject">{{ lectDetail.lectName }} (
                    <span v-if="lectDetail.lectGb === '01'">온라인, {{ lectDetail.lectureIndexMovPlayTimeSumHoursString === '0' ? '' : `${lectDetail.lectureIndexMovPlayTimeSumHoursString}시간` }}</span>
                    <span v-else>오프라인, {{ lectDetail.lectHour ? `${lectDetail.lectHour}시간` : '' }}</span>
                    )
                </p>
                <p class="date">
                    <span v-if="lectDetail.lectGb === '01'">{{ $yyyymmdd(lectDetail.userLectStartYmd) }}~{{ $yyyymmdd(lectDetail.userLectEndYmd) }}</span>
                    <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }}~{{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                </p>
            </div>
        </div>
        <div class="print print-cer2">
            <img class="bg" src="/src/assets/images/certificates_bg2.svg" alt="수료증">
            <p class="code">{{ certNum }}</p>
            <div class="lecture">
                <p class="subject">{{ lectDetail.lectName }}</p>
                <p class="agency">
                  <span v-if="userDetail.userGb === 'STUDENT' || userDetail.userGb === 'SCHOOL'">{{ userDetail.school }}</span>
                  <span v-else-if="userDetail.userGb === 'COMPANY'" >{{ userDetail.corporation.corpName }}</span>
                  <span v-else ></span>
                </p>
                <p class="name">{{ userDetail.userName }}</p>
                <p class="date">{{ userDetail.birthYmd}}</p>
            </div>
            <div class="lecture-list">
                <ul>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[0] && lectIndexes[0].idxName">{{ lectIndexes[0].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[1] && lectIndexes[1].idxName">{{ lectIndexes[1].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[2] && lectIndexes[2].idxName">{{ lectIndexes[2].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[3] && lectIndexes[3].idxName">{{ lectIndexes[3].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[4] && lectIndexes[4].idxName">{{ lectIndexes[4].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[5] && lectIndexes[5].idxName">{{ lectIndexes[5].idxName }}</span></li>
                </ul>
                <ul>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[6] && lectIndexes[6].idxName">{{ lectIndexes[6].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[7] && lectIndexes[7].idxName">{{ lectIndexes[7].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[8] && lectIndexes[8].idxName">{{ lectIndexes[8].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[9] && lectIndexes[9].idxName">{{ lectIndexes[9].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[10] && lectIndexes[10].idxName">{{ lectIndexes[10].idxName }}</span></li>
                    <li><span v-if="lectIndexes.length > 0 && lectIndexes[11] && lectIndexes[11].idxName">{{ lectIndexes[11].idxName }}</span></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.print {
    position: relative;
    width: 843px;
    height: 596px;
    font-family: 'KoPubWorld Dotum';
    font-size: 15px;
    &.print-cer1 {
        .user {
            position: absolute;
            top: 178px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            .name {
                font-size: 50px;
                font-weight: 700;
                letter-spacing: 12px;
                margin-bottom: -10px;
            }
            .agency {
                font-size: 14px;
                width: 700px;
            }
        }
        .lecture {
            position: absolute;
            top: 365px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            .subject {
                width: 460px;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 14px;
            }
            .date {
                font-size: 17px;
            }
        }
    }
    &.print-cer2 {
        margin-top: 30px;
        .lecture {
            position: absolute;
            width: 100%;
            top: 114px;
            font-size: 21px;
            text-align: left;
            p {
                width: 320px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .subject {
                position: absolute;
                left: 168px;
                letter-spacing: -0.4px;
            }
            .name {
                position: absolute;
                left: 571px;
                letter-spacing: -0.4px;
            }
            .agency {
                position: absolute;
                top: 34px;
                left: 168px;

            }
            .date {
                position: absolute;
                top: 32px;
                left: 608px;
                letter-spacing: -0.4px;
            }
        }
        .lecture-list {
            position: absolute;
            top: 220px;
            left: 133px;
            width: 100%;
            font-size: 16px;
            display: flex;
            ul {
                li {
                    width: 280px;
                    text-align: left;
                    letter-spacing: -0.4px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin-bottom: 10.4px;
                }
                &:last-child {
                    margin-left: 53px;
                }
            }
        }
    }
    .code {
        position: absolute;
        top: 30px;
        right: 40px;
        font-size: 10px;
        font-weight: 700;
    }
}
</style>