import http from '../../../../api/http'
import {useSasTokenStore} from "../../../../stores/sasTokenStore";

function retrieveList(paramObj) { // 목록 조회
    return http.post('/lecture/qna/retrieveAllPaging', {
        lectId: paramObj.lectId, //  0,
        boardGb: paramObj.boardGb || "QNA",
        myPostGb: paramObj.myPostGb, // "string",
        createId: paramObj.createId, // 0,
        pageNumber: paramObj.pageNo, // 0,
        size: paramObj.size || 30,
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string"
    })
}

function retrieveDetail(paramObj) { // 상세 조회
    return http.post('/lecture/qna/retrieve', {
        lectId: paramObj.lectId,
        boardId: paramObj.boardId,
    })
}

function createQNA(paramObj) { // 생성
    const store = useSasTokenStore();
    const sasToken = store.sasToken;
    store.sasToken = '';

    return http.post('/lecture/qna/create', {
        boardId: paramObj.boardId, // 0,
        lectId: paramObj.lectId, // 0,
        boardGb: paramObj.boardGb || "QNA",
        title: paramObj.title, // "string",
        content: paramObj.content, // "string",
        secretYn: paramObj.secretYn, // "string",
        answer: paramObj.answer, // "string",
        hitCount: paramObj.hitCount, // 0,
        createId: paramObj.createId, // 0,
        attachs: paramObj.attachs, // [],
        sasToken,
    })
}

function updateQNA(paramObj) { // 수정
    const store = useSasTokenStore();
    const sasToken = store.sasToken;
    store.sasToken = '';

    return http.post('/lecture/qna/update', {
        boardId: paramObj.boardId, // 0,
        lectId: paramObj.lectId, // 0,
        boardGb: paramObj.boardGb || "QNA",
        title: paramObj.title, // "string",
        content: paramObj.content, // "string",
        secretYn: paramObj.secretYn, // "string",
        answer: paramObj.answer, // "string",
        hitCount: paramObj.hitCount, // 0,
        createId: paramObj.createId, // 0,
        attachs: paramObj.attachs, // [],
        sasToken,
    })
}

function deleteQNA(paramObj) { // 삭제
    return http.post('/lecture/qna/delete', {
        lectId: paramObj.lectId,
        boardId: paramObj.boardId,
    })
}


function updateAnswer(paramObj) { // 답변 저장
    return http.post('/lecture/qna/answer', {
        boardId: paramObj.boardId,
        lectId: paramObj.lectId,
        answer: paramObj.answer,
    })
}

export default {
    retrieveList,
    retrieveDetail,
    createQNA,
    updateQNA,
    deleteQNA,
    updateAnswer
}