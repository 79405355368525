import http from '../../../../api/http';

function retrieveRecord() {
    return http.get('/mypage/record/retrieve');
}

function retrieveUserLectureRecord(userId, lectId, regDt) {
    return http.get('/mypage/record/retrieveUserLecture?userId=${userId}&lectId=${lectId}&regDt=${regDt}');
}

function updateDesired({ interests, desiredJob, desiredSalary, residenceArea1, residenceArea2 }) {
    return http.post('/mypage/record/updateDesired', {
        // "userId": 0,
        interests, // 관심카테고리
        desiredJob, // 희망직무
        desiredSalary, // 희망연봉
        residenceArea1, // 거주 지역(시/도)
        residenceArea2 // 거주 지역(시/구/군)
    });
}

function updateIntro(introOpenYn, introAline, selfIntro) {
    return http.post('/mypage/record/updateIntro', {
        // "userId": 0,
        introOpenYn: introOpenYn ? 'Y' : 'N', // 공개여부
        introAline, // 한줄소개
        selfIntro // 자기소개
    });
}

function updateEduHistory(eduHistOpenYn, eduHistoris) {
    return http.post('/mypage/record/updateEduHistory', {
        eduHistOpenYn: eduHistOpenYn ? 'Y' : 'N',  // 공개여부
        eduHistoris,
    });
}

function updatePortfolio(portfolioOpenYn, portfolios) {
    return http.post('/mypage/record/updatePortfolio', {
        portfolioOpenYn: portfolioOpenYn ? 'Y' : 'N',  // 공개여부
        portfolios,
    });
}

function updateCertForeign(certForeignOpenYn, certificates, languageTests, foreignLanguages) {
    return http.post('/mypage/record/updateCertForeign', {
        certForeignOpenYn: certForeignOpenYn ? 'Y' : 'N',  // 공개여부
        certificates,
        languageTests,
        foreignLanguages,
    });
}

function updateJobHistory(jobHistOpenYn, jobHistorys) {
    return http.post('/mypage/record/updateJobHistory', {
        jobHistOpenYn: jobHistOpenYn ? 'Y' : 'N',  // 공개여부
        jobHistorys,
    });
}

function updateMilService(milOpenYn, milServiceGb) {
    return http.post('/mypage/record/updateMilService', {
        milOpenYn: milOpenYn ? 'Y' : 'N',  // 공개여부
        milServiceGb,
    });
}

export default {
    retrieveRecord,
    retrieveUserLectureRecord,
    updateDesired,
    updateIntro,
    updateEduHistory,
    updatePortfolio,
    updateCertForeign,
    updateJobHistory,
    updateMilService
};