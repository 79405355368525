<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, onMounted,  computed, defineExpose, getCurrentInstance } from "vue";
    import { usePageLoadingStore} from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
    import surveyService from "../../../services/lecture/surveyService";

    const loadingStore = usePageLoadingStore();
    const { $gbNameKR, $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const list = ref([])

    const alertModal = ref(null)
    const modalContent = ref([])

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);
        retrieveSurveyPaperList();
    })

    const searchInput = ref({
        title: ''
    })

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const pageSizeGroup = ref([
        { label:"10개씩 보기", value: "10" },
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
    ])

    const retrieveSurveyPaperList = () => {

        let paramObj = {
            title: searchInput.value.title,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        surveyService.retrieveAllSurveyPaper(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        searchInput.value = {
            title: ''
        }
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveSurveyPaperList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveSurveyPaperList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveSurveyPaperList();
    }

    const show = () => {
        baseModal.value.open();
        retrieveSurveyPaperList();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = (result) => {
        baseModal.value.close();
        resolvePromise.value(result);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value();
    };

    const init = () => {
        clearSearchInput();
    }

    defineExpose({
        show,
        init,
    })

</script>

<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>만족도 평가 검색</h3>
            </div>
            <div class="modal-body">
                <div class="filter-wrap open">
                    <p>검색</p>
                    <div class="filter">
                        <dl>
                            <dt>만족도 평가 제목</dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <KSAInput style="max-width: calc(100% - 80px;); width: 100%;" class="text-box" v-model="searchInput.title" />
                                    <KSAPrimaryButton class="ml-xs" text="검색" @click="onClickSearch" />
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div class="list-top">
                    <p>총 <span>{{ totalCount }}</span>개</p>
                    <div>
                        <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>NO</th>
                            <th>만족도 평가 제목</th>
                            <th>문항 분포</th>
                            <th>선택</th>
                        </tr>
                        <tr v-for="surveyPaper in list">
                            <td>{{ surveyPaper.boardNo }}</td>
                            <td class="text-left">{{ surveyPaper.title }}</td>
                          <td class="text-left">주관식 {{ surveyPaper.surveyQType2 }}, 다중응답형 {{ surveyPaper.surveyQType3 }}, 5점 척도 {{ surveyPaper.surveyQType4 }}<span v-if="surveyPaper.surveyQType1 !== 0">, 7점 척도 {{ surveyPaper.surveyQType1 }}</span></td>
                            <td><KSATextButton text="선택" @click="confirm(surveyPaper)" /></td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

</style>