<script setup>
    import { ref, defineProps, toRefs, onMounted } from 'vue'
    import { VideoPlayer } from '@videojs-player/vue'
    import 'video.js/dist/video-js.css'

    const props = defineProps({
        src: {
            type: String,
            default: '',
        },
        poster: {
            type: String,
            default: '',
        }
    })

    const { src } = toRefs(props)

    onMounted(()=>{
        const videoPlayer = document.querySelector('.video');
        try {
            const pDiv = videoPlayer.closest('div');
            if(pDiv) {
                width.value = pDiv.clientWidth;
                height.value = pDiv.clientHeight;
            }
        } catch(e){}
    })

    const width = ref(0)
    const height = ref(0)

    const DOMAIN = 'https:\/\/academy.ksia.or.kr';
</script>

<template>
    <video-player
        :width="width"
        :height="height"
        :src="DOMAIN + src"
        :poster="poster ? (DOMAIN + poster) : ''"
        controls
        autoplay
        :playsinline="false"
        :loop="false"
        :volume="0.6"
    />
</template>

<style lang="scss">

    .video-js {
        top: 0;
        .vjs-big-play-button {
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            .vjs-icon-placeholder {
                &:before {
                    top: 8px;
                }
            }
        }
    }
</style>