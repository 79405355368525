<script setup>
    import { ref, reactive, defineEmits, defineProps, toRefs, onMounted } from 'vue'
    import Datepicker from 'vue3-datepicker'
    import { ko } from 'date-fns/locale'
    import moment from 'moment';


    const props = defineProps({
        startDate: {
            type: String,
            default: '',
        },
        endDate: {
            type: String,
            default: '',
        },
    })

    const { startDate, endDate } = toRefs(props)


    const emit = defineEmits(['update:startDate', 'update:endDate'])

    const now = new Date();
    const date = ref(new Date());
    const date2 = ref(new Date(now.setDate(now.getDate() + 30)));
    const datepicker = ref(null);
    const datepicker2 = ref(null);

    const startDateUpperLimit = ref(date2.value);
    const endDateLowerLimit = ref(date.value);

    const locale = reactive(ko)
    const inputFormat = ref('yyyy.MM.dd')

    onMounted(()=>{
        if(startDate.value) {
            date.value = new Date(startDate.value.substring(0,4) + '-' + startDate.value.substring(4,6) + '-' + startDate.value.substring(6));
            endDateLowerLimit.value = date.value;
        } else {
            emit('update:startDate', moment(date.value).format('YYYYMMDD'));
        }

        if(endDate.value) {
            date2.value = new Date(endDate.value.substring(0,4) + '-' + endDate.value.substring(4,6) + '-' + endDate.value.substring(6));
            startDateUpperLimit.value = date2.value;
        } else {
            emit('update:endDate', moment(date2.value).format('YYYYMMDD'));
        }
    })

    const handleStartDate = (modelData) => {
        date.value = modelData;
        endDateLowerLimit.value = modelData;
        emit('update:startDate', moment(date.value).format('YYYYMMDD'));
    }

    const handleEndDate = (modelData) => {
        date2.value = modelData;
        startDateUpperLimit.value = modelData;
        emit('update:endDate', moment(date2.value).format('YYYYMMDD'));
    }
</script>

<template>
    <div class="date-wrap">
        <Datepicker
            v-model="date"
            :locale="locale"
            :weekStartsOn="0"
            :inputFormat="inputFormat"
            :clearable="true"
            @update:model-value="handleStartDate"
            :ref="datepicker"
            :upper-limit="startDateUpperLimit"
        />
        <span class="ml-xs mr-xs">~</span>
        <Datepicker
            v-model="date2"
            :locale="locale"
            :weekStartsOn="0"
            :inputFormat="inputFormat"
            :clearable="true"
            @update:model-value="handleEndDate"
            :ref="datepicker2"
            :lower-limit="endDateLowerLimit"
        />
    </div>
</template>


<style lang="scss" scoped>
    @import "../../assets/scss/theme";
    .date-wrap {
        display: flex;
        align-items: center;
    }
</style>