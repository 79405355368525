import http from '../../../../api/http';
import { useUserStore } from "../../stores/user";

function retrieveUserInfo() {
    return http.get('/mypage/user/retrieve')
}

function updateUserInfo(paramObj) {
    return http.post('/mypage/user/update', paramObj)
}

function checkPassword(userPasswd) {
    return http.post('/mypage/user/checkPassword', {
        userPasswd,
    })
}

function changePassword({ oldUserLoginPw, userLoginPw, userLoginPwVerify}) {
    return http.post('/mypage/user/changePassword', {
        oldUserLoginPw,
        userLoginPw,
        userLoginPwVerify,
        // "modifyId": 0
    })
}

function withdraw() {
    return http.post('/mypage/user/withdraw')
}

function createUserGbHistory(paramObj) { // 회원 유형 변경 신청
    return http.post('/mypage/user/createUserGbHistory', paramObj)
}

function retrieveUserGbHistoryListByUserId(paramObj) {
    return http.post('/mypage/user/retrieveUserGbHistoryByUserId', paramObj)
}

function changeUserGbHistoryState(paramObj) {
    return http.post('/mypage/user/changeUserGbHistoryState', paramObj)
}

function countUserGbHistory() {
    return http.post('/mypage/user/countUserGbHistory')
}

function checkUserGbHistoryApprove() {
    return http.post('/mypage/user/checkUserGbHistoryApprove')
}

function changeUserGbHistoryModalYn(paramObj) {
    return http.post('/mypage/user/changeUserGbHistoryModalYn', paramObj)
}

export default {
    retrieveUserInfo,
    updateUserInfo,
    checkPassword,
    changePassword,
    withdraw,
    createUserGbHistory,
    retrieveUserGbHistoryListByUserId,
    changeUserGbHistoryState,
    countUserGbHistory,
    checkUserGbHistoryApprove,
    changeUserGbHistoryModalYn,
};