<script setup>
    import { ref, computed } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import insightsNotifyService from "../../../../user/services/insights/insightsNotifyService";
    import fileService from "../../../../../services/fileService";
    import _ from "lodash";

    const router = useRouter();
    const loadingStore = usePageLoadingStore()


    const MAX_SIZE = 200;
    const input = ref({
        title: '',
        content: '',
        attachList: [
            {
                attachSeq: 0,
                fileName: "",
                fileSize: "",
                fileDir: "",
                deleteYn: "N"
            }
        ],
    })

    const inputError = ref({
        title: '',
        content: '',
        attachList: '',
    })

    const isNotify = ref(false);

    const modal = ref(null)
    const modalContent = ref([])

    const uploadFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];
            // console.log(file);
            if(file.size > (MAX_SIZE*1024*1024)) {
                modalContent.value = [`파일은 최대 ${MAX_SIZE}MB까지 첨부할 수 있습니다.`];
                modal.value.show();
            } else {
                let paramObj = {
                    container: 'public',
                    folder: 'cs_notice',
                    file,
                }

                fileService.uploadFile(paramObj)
                    .then((res) => {
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            input.value.attachList[0].fileName = result.originalFilename;
                            input.value.attachList[0].fileSize = result.fileSize;
                            input.value.attachList[0].fileDir = result.blobUrl;
                            // console.log(input.value)
                        }
                    })
                    .catch( (error) =>  {
                        console.log(error);
                    });
            }
        });
    }

    const removeFile = () => {
        input.value.attachList[0].fileName = '';
        input.value.attachList[0].fileSize =  '';
        input.value.attachList[0].fileDir =  '';
    }

    const create = () => {
        const { title, content, attachList } = input.value;
        let isInvalid = false;


        inputError.value = {
            title: '',
            content: '',
            attachList: '',
        };

        if(!title) {
            inputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            inputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }


        if(!isInvalid) {
            let paramObj = {
                title,
                content,
                notifyYn: isNotify.value ? "Y" : "N",
                attachList: _.filter(attachList, attach => {
                    return attach.fileName !== null && attach.fileName !== '';
                }),
            }

            loadingStore.loading = true;
            insightsNotifyService.createNotice(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        router.push({name: 'CSNotifyList'})
                    } else {
                        modalContent.value = ["등록을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = ["등록을 실패하였습니다."];
                modal.value.show();
            });
        }
    }

    const cancel = () => {
        router.push({ name: 'CSNotifyList'});
    };
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="lecture-wrap">
                    <div class="sub-title">
                        <h3>공지사항 작성</h3>
                    </div>
                    <div class="table-form">
                        <p>모든 정보는 필수입력 사항입니다.</p>
                        <table class="vertical">
                            <colgroup>
                                <col width="110px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th>제목</th>
                                <td>
                                    <KSAInput v-model="input.title" :msg="inputError.title" placeholder="제목을 입력하세요."/>
                                    <KSACheckBox label="상단 공지사항으로 설정합니다." v-model="isNotify"  />
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <KSACKEditor ref="editorRef" v-model="input.content"/>
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.content }}</small>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>첨부</th>
                                <td class="text-box">
                                    <div class="grid file" v-for="attach in input.attachList">
                                        <KSAPrimaryButton text="파일 선택" @click="uploadFile" />
                                        <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                        <button class="delete" @click="removeFile" :disabled="!attach.fileName"><i></i></button>
                                    </div>
                                    <small>최대 {{ MAX_SIZE }}MB 까지 첨부 가능</small>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="btn-wrap space-between">
                        <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                        <KSAPrimaryButton text="등록" :disabled="!input.title || !input.content " @click="create" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .textarea-box {
        height: 300px;
    }
    small {
        @include font-size_xs;
        color: $gray_color;
    }
</style>