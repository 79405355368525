<script setup>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from "../../stores/user";
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import SNB from '../../../../layouts/nav/SNB.vue'
    import mypageUserService from "../../services/mypage/mypageUserService";

    const router = useRouter()
    const userStore = useUserStore()
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        const { userLoginId } = userStore.user;
        if(userLoginId) {
            input.value.userLoginId = userLoginId;
        }
    })

    const isAuthenticated = ref(false);

    const input = ref({
        userLoginId: '',
        password: '',
    })

    const inputError = ref({
        userLoginId: '',
        password: '',
    })

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([]);


    const auth = () => {
        const { password } = input.value;
        let isInvalid = false;

        inputError.value.password = ''
        if(!password) {
            inputError.value.password = '비밀번호를 입력해주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            loadingStore.loading = true;
            mypageUserService.checkPassword(password)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        isAuthenticated.value = true;
                    } else {
                        modalContent.value = ["비밀번호가 올바르지 않습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                console.log(res);
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    const deleteUser = async () => {
        modalContent.value = ["회원탈퇴 안내를 꼭 확인해주세요.", "정말 한국반도체아카데미 회원을 탈퇴할까요?"];
        const ok = await confirmModal.value.show()
        if(ok){
            loadingStore.loading = true;
            mypageUserService.withdraw()
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        modalContent.value = ["회원탈퇴되었습니다."];
                        modal.value.show();
                        userStore.setUser({});
                        setTimeout(()=>{
                            router.push({name:'Home'});
                        },500)
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content">
                    <div class="sub-title">
                        <h3>회원탈퇴</h3>
                    </div>
                    <div class="sign-wrap" v-if="!isAuthenticated">
                        <div class="sign-inner">
                            <div class="text text-center">
                                <p>회원님의 정보를 안전하게 보호하기 위해 비밀번호를 입력해주세요</p>
                            </div>
                            <div class="sign-content">
                                <KSAInput class="text-box" v-model="input.userLoginId" label="아이디" placeholder="아이디 입력하세요." :disabled="true"/>
                                <KSAInput type="password" class="text-box" v-model="input.password" label="비밀번호" placeholder="비밀번호 입력하세요." :msg="inputError.password" max-length="30"/>

                                <KSAPrimaryButton class="full-btn" text="확인" @click="auth" :disabled="!input.password"/>
                            </div>
                        </div>
                    </div>
                    <div class="withdraw-wrap" v-if="isAuthenticated">
                        <h5>회원탈퇴 안내</h5>
                        <div class="text-content">
                            한국반도체아카데미 회원을 탈퇴하시면 강의 및 기타 컨텐츠 제공 서비스를 곧바로 이용할 수 없습니다.<br>
                            <span>회원탈퇴 전에 아래 내용을 꼭 확인해주시기 바랍니다.</span>
                            <ul>
                                <li>
                                    <b>1.사용중인 아이디는 재사용 및 복구가 불가능합니다.</b>
                                    탈퇴시 해당 아이디는 즉시 탈퇴 처리 되며,탈퇴한 아이디는 <span>본인과 타인 모두 재사용 및 복구가 불가</span> 하오니 신중하게 선택하시기 바랍니다.
                                </li>
                                <li>
                                    <b>2.탈퇴 후 회원정보는 모두 삭제됩니다.</b>
                                    탈퇴 시 <span>아이디를 제외한 회원정보와 이력정보는 모두 삭제</span>되며,삭제된 데이터는 복구되지 않습니다.
                                    삭제되는 내용을 확인하세요
                                </li>
                                <li>
                                    <b>3.홈페이지 탈퇴 시 이력관리와 수강 현황 정보도 같이 삭제됩니다.</b>
                                    홈페이지 탈퇴 시 <span>이력 정보와 수강이력,수료증</span> 등이 삭제되오니 미리 확인하시기 바랍니다.
                                </li>
                                <li>
                                    <b>4.탈퇴 후에도 게시판형 서비스에 등록한 게시물은 그대로 남아 있습니다.</b>
                                    홈페이지에 작성한 Q&A나 강의 후기는 탈퇴 시 자동 삭제되지 않고 그대로 남아 있으므로 삭제를 원하는 게시글이 있다면 반드시 <span>탈퇴 전 삭제하시기 바랍니다.</span>
                                </li>
                            </ul>
                        </div>
                        <div class="btn-wrap space-between">
                            <KSASecondaryButton text="취소" @click="router.push({name:'MyPageProfile'})" />
                            <KSAPrimaryButton text="탈퇴" @click="deleteUser" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ConfirmModal ref="confirmModal" :content="modalContent" ok-button-text="탈퇴"/>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .sign-wrap {
        padding: 0;
    }

    .withdraw-wrap {
        h5 {
            @include font-size_lg;
            color: $gray_color;
            margin: 20px 0;
        }
        span {
            color: $red_color;
            font-weight: 700;
        }
        .text-content {
            border-bottom: $border_line;
            padding-bottom: 20px;
            margin-bottom: 40px;
            ul {
                margin-top: 30px;
                li {
                    margin-bottom: 30px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    b {
                        display: block;
                    }
                }
            }
        }
    }
</style>