// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { reactive, watch } from 'vue'

export const useSignupStore = defineStore('signupStore', () => {

    const signupRequest = reactive({
        userGb: "", // STUDENT, COMPANY, SCHOOL, TEACHER
        // userLoginId: '',
        // userLoginPw: '',
        // userLoginPwVerify: '',
        // school: '',
        // eduHistGb: '',
        userName: '',
        phoneNum: '',
        // email: '',
        // birthYmd: '',
        // sex: '',
        // corpId: 0,
        // residenceArea1: '',
        // residenceArea2: '',
        // interests: '',
        // desiredJob: '',
        // professorName: '',
        // labName: '',
        // ableClass: '',
        // expertField: '',
        agreeTermYn: 'N',
        agreePrivacyYn: 'N',
        agreeInfoProvYn: 'N',
    })

    const signupRequestInStorage = sessionStorage.getItem('signupRequest')
    if (signupRequestInStorage) {
        const { userGb, userName, phoneNum, agreeTermYn, agreePrivacyYn, agreeInfoProvYn} = JSON.parse(signupRequestInStorage)
        signupRequest.userGb = userGb
        signupRequest.userName = userName
        signupRequest.phoneNum = phoneNum
        signupRequest.agreeTermYn = agreeTermYn
        signupRequest.agreePrivacyYn = agreePrivacyYn
        signupRequest.agreeInfoProvYn = agreeInfoProvYn
    }

    watch(() => signupRequest, (state) => {
        sessionStorage.setItem('signupRequest', JSON.stringify(state))
    }, { deep: true })

    function setSignupRequest({ userGb, userName, phoneNum, agreeTermYn, agreePrivacyYn, agreeInfoProvYn }) {
        signupRequest.userGb = userGb
        signupRequest.userName = userName
        signupRequest.phoneNum = phoneNum
        signupRequest.agreeTermYn = agreeTermYn
        signupRequest.agreePrivacyYn = agreePrivacyYn
        signupRequest.agreeInfoProvYn = agreeInfoProvYn
    }

    function clearStore() {
        signupRequest.userGb = '';
        signupRequest.userName = '';
        signupRequest.phoneNum = '';
        signupRequest.agreeTermYn = 'N';
        signupRequest.agreePrivacyYn = 'N';
        signupRequest.agreeInfoProvYn = 'N';
    }

    return {
        signupRequest,
        setSignupRequest,
        clearStore
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSignupStore, import.meta.hot))
}