<script setup>
    import { ref, watch, onMounted, getCurrentInstance } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import _ from "lodash";
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import surveyService from "../../../services/lecture/surveyService";
    import KSAPrimaryButton from "../../../../../components/ui-components/button/KSAPrimaryButton.vue";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const { $toast, $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveSurveyPaperList();
    })

    let disabledWatchIsSelectedAll = false; // 개별 checkbox 클릭시 watch disabled
    let disabledWatchList = false;          // all checkbox 클릭시 watch disabled

    const isSelectedAll = ref(false);
    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(list.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            list.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    const list = ref([])
    watch(list, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    const searchOpen = ref(true)

    const searchInput = ref({
        title: '',
        searchDurationGb: 'C',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    })

    const searchDurationGbRef = ref(null)

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const searchDurationGbGroup = ref([
        { label:"등록일", value: "C" },
        { label:"최근 수정일", value: "U" },
    ])

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const retrieveSurveyPaperList = () => {

        let paramObj = {
            title: searchInput.value.title,
            searchDurationGb: searchInput.value.searchDurationGb,
            startYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.startYmd,
            endYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        surveyService.retrieveAllSurveyPaper(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        searchInput.value = {
            title: '',
            searchDurationGb: 'C',
            searchDurationType: 'all',
            startYmd: '',
            endYmd: '',
        }
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveSurveyPaperList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveSurveyPaperList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
    }

    const onSelectSearchDurationGbGroup = (option) => {
        searchInput.value.searchDurationGb = option.value;
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>평가 관리</h3>
                </div>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen = !searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>만족도 평가명</dt>
                            <dd><KSAInput class="text-box" v-model="searchInput.title" placeholder="만족도 평가명을 입력해주세요." /></dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchDurationGbRef" :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                        <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="searchInput.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="searchInput.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                            :startDate="searchInput.startYmd"
                                            :endDate="searchInput.endYmd"
                                            @update:startDate="(value)=> searchInput.startYmd = value"
                                            @update:endDate="(value)=> searchInput.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <KSAPrimaryButton text="만족도 평가 등록" @click="router.push( { name:'LectureSurveyPaperCreate', params:{id: 0} } )" />
                </div>
                <div class="list-top">
                    <p>총 <span>{{ totalCount }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onclick"/>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>NO</th>
                            <th>사용</th>
                            <th>만족도 평가 제목</th>
                            <th>참여 인원</th>
                            <th>최근 설문일</th>
                            <th>등록일</th>
                        </tr>
                        <tr v-for="surveyPaper in list" @click="router.push({name: 'LectureSurveyPaperDetail', params:{id: surveyPaper.surveyId } })">
                            <td>{{ surveyPaper.boardNo }}</td>
                            <td>{{ surveyPaper.useYn === 'Y' ? '사용' : '미사용' }}</td>
                            <td>{{ surveyPaper.title }}</td>
                            <td>{{ $number(surveyPaper.totalSubmit) }}명</td>
                            <td>{{ $yyyymmddHHmmss(surveyPaper.recentSurveyDate) }}</td>
                            <td>{{ $yyyymmddHHmmss(surveyPaper.createDate) }}</td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                      검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                        v-if="list.length > 0"
                        v-model="currentPage"
                        :page-count="totalPages"
                        :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.textarea-box {
  height: 100px;
}
</style>