<script setup>
    import { ref, computed, onMounted, defineEmits, defineExpose, toRefs } from "vue";
    const props = defineProps({
        modelValue: { type: String },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: "YYYY.MM",
        },
        msg: {
            type: String,
            default: '',
        },
        msgColor: {
            type: String,
            default: '#ff0000'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: 'YYYYMM'
        }
    });

    const { modelValue, label, placeholder, msg, msgColor, disabled, format } = toRefs(props)

    onMounted(()=> {
        onInput();
    })

    const inputRef = ref(null)

    const emit = defineEmits(["update:modelValue"]);

    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const onInput = () => {
        let res = getFormattedString()
        // console.log(res);
        emit("update:modelValue", res);
    }

    const getFormattedString = ( ) => {
        let str = props.modelValue
        str = str.replace(/[^0-9]/g, '');
        // console.log(str);
        var tmp = '';

        if(format.value === 'YYYYMMDD') {
            if( str.length < 5){
                return str;
            }else if(str.length < 8){
                tmp += str.substr(0, 4);
                tmp += '.';
                tmp += str.substr(4);
                return tmp;
            }else{
                tmp += str.substr(0, 4);
                tmp += '.';
                tmp += str.substr(4, 2);
                tmp += '.';
                tmp += str.substr(6);
                return tmp;
            }
        } else {
            if( str.length < 5){
                return str;
            } else{
                tmp += str.substring(0, 4);
                tmp += '.';
                tmp += str.substring(4);
                return tmp;
            }
        }
    }

    const setValue = (val) => {
        inputRef.value.value = val;
    }

    defineExpose({
        setValue
    })

</script>

<template>
    <div>
        <label>
            <p v-if="label">{{ label }}</p>
            <input
                ref="inputRef"
                type="text"
                v-model="model"
                @input="onInput"
                :placeholder="format !== 'YYYYMMDD' ? placeholder : 'YYYY.MM.DD'"
                :maxlength="format !== 'YYYYMMDD' ? 7 : 10"
                :disabled="disabled"
            />
        </label>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/ksa_input";

    label {
        p {
            @include font-size_xs;
            color: $gray_color;
        }
    }
    input {
        &:disabled {
            color: $black_color;
            background: $gray-thin_color;
        }
    }
</style>