<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, defineExpose, onMounted } from "vue";
import { useRouter } from 'vue-router'
import { useCookies } from 'vue3-cookies'
import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";

const { cookies } = useCookies();

// 로그인한 기업마스터 채용 업데이트 데이터 받아오기.
const matchingUpdateStore = useMatchingUpdateStore()
const delayResStateCnt = ref(0);
const delayPropStateCnt = ref(0);
if(matchingUpdateStore) {
  delayResStateCnt.value = matchingUpdateStore.matchingUpdate.delayResStateCnt;
  delayPropStateCnt.value = matchingUpdateStore.matchingUpdate.delayPropStateCnt;
}

const router = useRouter();

const baseModal = ref(null);
const resolvePromise = ref(null);

const modalContent = ref([])

const loaded = ref(false)
const content = ref(null);

const show = () => {
  baseModal.value.open();
  return new Promise((resolve, _) => {
    resolvePromise.value = resolve;
  });
};

const cancel = () => {
  baseModal.value.close();
  resolvePromise.value(false);
};

// 오늘 하루 보지 않기
const noView = () => {
  cookies.set('noCorpViewToday', true, { expires: 1}); // 1일 동안 유지
  cancel();
}

onMounted(() => {
  // 로그인 후 모달 띄우기
  const noCorpViewToday = cookies.get('noCorpViewToday');
  if(noCorpViewToday !== 'true') {
    show();
  }
});

defineExpose({
  show,
})
</script>
<template>
  <Modal ref="baseModal" class="vld-parent">
    <div class="modal-inner m-size">
      <button class="close" @click="cancel"></button>
      <div class="modal-body">
        <h2>채용 진행 상태 업데이트 안내</h2>
        <p>2개월(60일) 동안 업데이트되지 않은<br>
          채용 진행 건이 확인되었습니다.<br>
          <em>기업별 채용 진행 상태를 최신 정보로 업데이트해 주세요.</em>
        </p>
        <div class="status-container">
          <div class="status">
            <h4>매칭완료</h4>
            <span class="status-arrow"></span>
            <span>전형 진행 or 전형 중단</span>
          </div>
          <div class="status">
            <h4>전형 진행</h4>
            <span class="status-arrow"></span>
            <span>합격 or 불합격</span>
          </div>
          <div class="footnote">*전형: 실기/면접 등</div>
        </div>
      </div>
      <div v-if="delayPropStateCnt > 0" class="modal-footer btn-wrap">
        <button class="full-btn" @click="router.push({name:'MypageProposalListView'});cancel();">채용 제안 현황</button>
      </div>
      <div v-if="delayResStateCnt > 0" class="modal-footer btn-wrap">
        <button class="full-btn" @click="router.push({name:'MypageResumeCorporation'});cancel();;">인재 채용 현황</button>
      </div>
    </div>
    <div class="check-not-today" @click="noView"><span class="check"></span>오늘 하루 보지 않기</div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
  margin-top: 50px;
  .close {
    top: -20px !important;
    right: -10px !important;;
  }
  .modal-footer {
    display: block;
    padding: 0 30px;
  }
}
</style>