<script setup>
import { ref, defineEmits, defineExpose, toRefs, onMounted, onUnmounted } from "vue";

const props = defineProps({
    modelValue: {
        type: String
    },
    placeholder: {
        type: String,
        default: '지역을 입력해 주세요',
    },
    msg: {
        type: String
    },
    msgColor: {
        type: String
    },
    maxLength: {
        type: String,
        default: '30'
    },
    disabled: {
        type: Boolean,
        default: false
    },
});

const area0 = ["서울특별시","인천광역시","대전광역시","광주광역시","대구광역시","울산광역시","부산광역시","경기도","강원도","충청북도","충청남도","전라북도","전라남도","경상북도","경상남도","제주도"];
const area1 = ["강남구","강동구","강북구","강서구","관악구","광진구","구로구","금천구","노원구","도봉구","동대문구","동작구","마포구","서대문구","서초구","성동구","성북구","송파구","양천구","영등포구","용산구","은평구","종로구","중구","중랑구"];
const area2 = ["계양구","남구","남동구","동구","부평구","서구","연수구","중구","강화군","옹진군"];
const area3 = ["대덕구","동구","서구","유성구","중구"];
const area4 = ["광산구","남구","동구", "북구","서구"];
const area5 = ["남구","달서구","동구","북구","서구","수성구","중구","달성군"];
const area6 = ["남구","동구","북구","중구","울주군"];
const area7 = ["강서구","금정구","남구","동구","동래구","부산진구","북구","사상구","사하구","서구","수영구","연제구","영도구","중구","해운대구","기장군"];
const area8 = ["고양시","과천시","광명시","광주시","구리시","군포시","김포시","남양주시","동두천시","부천시","성남시","수원시","시흥시","안산시","안성시","안양시","양주시","오산시","용인시","의왕시","의정부시","이천시","파주시","평택시","포천시","하남시","화성시","가평군","양평군","여주군","연천군"];
const area9 = ["강릉시","동해시","삼척시","속초시","원주시","춘천시","태백시","고성군","양구군","양양군","영월군","인제군","정선군","철원군","평창군","홍천군","화천군","횡성군"];
const area10 = ["제천시","청주시","충주시","괴산군","단양군","보은군","영동군","옥천군","음성군","증평군","진천군","청원군"];
const area11 = ["계룡시","공주시","논산시","보령시","서산시","아산시","천안시","금산군","당진군","부여군","서천군","연기군","예산군","청양군","태안군","홍성군"];
const area12 = ["군산시","김제시","남원시","익산시","전주시","정읍시","고창군","무주군","부안군","순창군","완주군","임실군","장수군","진안군"];
const area13 = ["광양시","나주시","목포시","순천시","여수시","강진군","고흥군","곡성군","구례군","담양군","무안군","보성군","신안군","영광군","영암군","완도군","장성군","장흥군","진도군","함평군","해남군","화순군"];
const area14 = ["경산시","경주시","구미시","김천시","문경시","상주시","안동시","영주시","영천시","포항시","고령군","군위군","봉화군","성주군","영덕군","영양군","예천군","울릉군","울진군","의성군","청도군","청송군","칠곡군"];
const area15 = ["거제시","김해시","마산시","밀양시","사천시","양산시","진주시","진해시","창원시","통영시","거창군","고성군","남해군","산청군","의령군","창녕군","하동군","함안군","함양군","합천군"];
const area16 = ["서귀포시","제주시","남제주군","북제주군"];

const areaObj = {
    "서울특별시": area1,
    "인천광역시": area2,
    "대전광역시": area3,
    "광주광역시": area4,
    "대구광역시": area5,
    "울산광역시": area6,
    "부산광역시": area7,
    "경기도": area8,
    "강원도": area9,
    "충청북도": area10,
    "충청남도": area11,
    "전라북도": area12,
    "전라남도": area13,
    "경상북도": area14,
    "경상남도": area15,
    "제주도": area16,
}

const { modelValue, placeholder, msg, msgColor, maxLength, disabled } = toRefs(props);

onMounted(()=>{
    document.body.addEventListener('click', clickEventHandler)
})
onUnmounted(()=>{
    document.body.removeEventListener('click', clickEventHandler)
})

const emit = defineEmits(["update:modelValue"]);

const inputRef = ref(props.modelValue)
const residenceList = ref([])
const open = ref(false)

// 입력값 변경 시마다 검색 함수 실행
const getResidenceList = () => {
    const input = inputRef.value.value.trim();
    open.value = input.length > 0;
    // 2글자 이상일 경우
    if(input.length > 1) {
        const results = [];
        for (let [area, districts] of Object.entries(areaObj)) {
            districts.forEach(district => {
                if (area.includes(input) || district.includes(input)) {
                    results.push(`${area} > ${district}`);
                }
            });
        }
        residenceList.value = results;
    } else {
        residenceList.value = [];
        open.value = true;
    }
}

// 검색 결과 항목 선택 시 부모 컴포넌트로 데이터 전송
const onSelectResidence = async (residence) => {
    // " > "를 제거하고 부모 컴포넌트로 데이터 전송
    await emit("update:modelValue", residence.replace(" > ", " "));
    open.value = false;
    // 검색창 초기화
    modelValue.value = '';
}

// 외부 클릭 시 검색창 닫기
const clickEventHandler = (event) => {
    if(!event.target.closest('.input-select')) open.value = false;
}

// 검색 결과 리스트에서 검색한 단어 파란색으로 표시
function highlightSearch(text, search) {
    if (!search) return text;
    const regex = new RegExp(`(${search})`, 'gi');
    return text.replace(regex, '<span style="color: #3843AF;\n;">$1</span>');
}

// 검색창 옆 x 클릭
const inputClose = () => {
  inputRef.value.value = '';
  modelValue.value = '';
}

defineExpose({
})
</script>

<template>
    <div style="position: relative;">
        <label>
            <div class="icon-text">
                <input
                    ref="inputRef"
                    v-model="modelValue"
                    @input="event => inputRef.value = event.target.value"
                    @keyup="getResidenceList"
                    :placeholder="placeholder"
                    :maxlength="maxLength"
                    :disabled="disabled"
                >
                <span @click="inputClose">닫기</span>
            </div>
        </label>
        <div class="list-wrapper" v-show="open">
            <div class="list input-select">
                <div v-for="residence in residenceList"
                    :key="residence"
                    @click="onSelectResidence(residence)"
                >
                    <span v-html="highlightSearch(residence, inputRef.value)"></span>
                </div>
                <div class="none" v-show="residenceList.length === 0">검색결과가 없습니다.</div>
            </div>
            <div class="list-bottom">
                <button>닫기<i></i></button>
            </div>
        </div>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}">
            <small>{{ msg }}</small>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/ui-components/ksa_input";

.icon-text {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #E4E5F0;
  input {
    width: calc(100% - 70px);
    margin-left: 32px;
    border: none;
    &:disabled {
      color: $black_color;
      background: $gray-thin_color;
    }
  }
  span {
    position: relative;
    text-indent: -9999px;
    background: #D9D9D9;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 2px;
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      display: block;
      width: 12px;
      height: 12px;
      background: url(/src/assets/images/icon-cross.png) no-repeat center;
      background-size: cover;
      filter: invert(1);
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 12px;
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../../../assets/images/icon-main.png);
    background-size: 200px 200px;
    background-position: -92px -52px;
    transform: translateY(-50%);
  }
}
.list-wrapper {
  position: absolute;
  z-index: 2;
  width: 100%;
  border: $border_line;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .list {
    height: 150px;
    overflow: auto;
    margin-top: 4px;
    div {
      padding: 10px 12px;
      cursor: pointer;
      &:hover {
        background: $blue-light_color;
      }
      .on {
        color: #3843AF;
      }
      &.none {
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .list-bottom {
    background: #F2F3F8;
    text-align: right;
    padding: 10px 20px;
    button {
      border: none;
      background: none;
      font-size: 16px;
      cursor: pointer;
      i {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background: url(/src/assets/images/icon-cross.png) no-repeat center;
          background-size: cover;
          margin: 8px 0 0 4px;
        }
      }
    }
  }
}
</style>