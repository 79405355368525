<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, defineProps, defineExpose, toRefs, onMounted } from "vue";
    import { useRouter } from 'vue-router'
    import _ from "lodash";

    import Loading from 'vue3-loading-overlay';
    import mypageProposalService from "../../../services/mypage/mypageProposalService";

    const router = useRouter();

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const modalContent = ref([])

    const loaded = ref(false)
    const content = ref(null);
    const id = ref(null);
    const showButtons = ref(false);

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const init = (showButton) => {
      showButtons.value = showButton;
    }

    const retrieveProposalContent = (propId) => {
        loaded.value = false;

        mypageProposalService.retrieveMatchingProposal(propId)
            .then( (res) => {
                loaded.value = true;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result.content;
                    const propId = res.data.result.propId;
                    // console.log(result);
                    content.value = result;
                    id.value = propId;
                } else {
                    const resultMsg = res.data.msg;
                    console.log(resultMsg);
                    content.value = resultMsg;
                }
            }).catch( (error) =>  {
            loaded.value = true;
            console.log(error);
            content.value = error;
        });
    }

    const onClickChangeProposalStep0 = () => {
      router.push({ name: 'MypageProposalCreateView', query : { id: id.value, step:'0' }})
    }

    const onClickChangeProposalStep1 = () => {
      router.push({ name: 'MypageProposalCreateView', query : { id: id.value, step:'1' }})
    }

    defineExpose({
        init,
        show,
        retrieveProposalContent,
    })
</script>

<template>
    <Modal ref="baseModal" class="vld-parent">
        <loading
            :active="!loaded"
            loader="dots"
            color="#3843AF"
            opacity="0.5"
            width="35"
            :is-full-page="false"></loading>
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-body" v-show="loaded">
                <div class="text img-container" v-html="content"></div>
            </div>
            <div v-if="showButtons" class="modal-footer btn-wrap">
                <KSAPrimaryButton text="제안서 수정" @click="onClickChangeProposalStep0" />
                <KSAPrimaryButton text="인재 검색" @click="onClickChangeProposalStep1" />
            </div>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
    margin-top: 50px;
    .close {
        top: -20px !important;
    }
    .modal-footer {
        display: block;
        padding: 0 30px;
    }
}
</style>