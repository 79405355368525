<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import insightsQNAService from "../../services/insights/insightsQNAService";
    import { useUserStore } from "../../stores/user";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import _ from 'lodash'

    const router = useRouter();
    const route = useRoute();
    const userStore = useUserStore();
    const loadingStore = usePageLoadingStore()

    const qnaDetail = ref({
        boardId: route.params.id,
    })
    const qnaDetailTemp = ref({})

    const isEdit = ref(false);
    const isSecret = ref(false);

    const isDisabled = computed(()=> {
        return !qnaDetailTemp.value || !qnaDetailTemp.value.title || !qnaDetailTemp.value.content;
    });

    const modal = ref(null);
    const modalContent = ref([]);

    onMounted(() => {
        retrieveNoticeDetail();
    })

    const retrieveNoticeDetail = () => {
        const { boardId } = qnaDetail.value;
        if(!boardId) {
            router.push({name: 'InsightsQNA'})
            return false;
        }
        loadingStore.loading = true;
        insightsQNAService.retrieveQNADetail(boardId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    if(qnaDetail.value.secretYn === 'Y' && userStore.user.userLoginId !== qnaDetail.value.createUserName ) {
                        router.push({name: 'InsightsQNA'});
                    }
                    qnaDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const editQNA = () => {
        qnaDetailTemp.value = _.cloneDeep(qnaDetail.value);
        isSecret.value = qnaDetailTemp.value.secretYn === 'Y';
        isEdit.value = !isEdit.value;
        // console.log(qnaDetailTemp.value);
    }

    const cancelEditQNA = () => {
        qnaDetailTemp.value = {};
        isEdit.value = false;
    }

    const updateQNA = () => {
        const { boardId, title, content } = qnaDetailTemp.value;
        if(isDisabled.value) {
            return false;
        }

        let paramObj = {
            boardId,
            title,
            content,
            secretYn: isSecret.value ? "Y" : "N",
        }

        loadingStore.loading = true;
        insightsQNAService.updateQNA(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    isEdit.value = !isEdit.value;
                    qnaDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const deleteQNA = async () => {
        modalContent.value = ["문의글을 삭제할까요?"];
        const ok = await modal.value.show();

        if (ok) {
            const { boardId } = qnaDetail.value;

            loadingStore.loading = true;
            insightsQNAService.deleteQNA(boardId)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        router.push({ name: 'InsightsQNA'});
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }

</script>

<template>
    <div class="container" v-if="!isEdit">
        <div class="inner">
            <div class="title page-title">
                <h3>Q&amp;A</h3>
            </div>
            <div class="board-detail">
                <div class="header" style="position: relative">
                    <div class="board-title">
                        <span :class="{'complete': qnaDetail.answer}">답변 {{ qnaDetail.answer ? '완료' : '대기' }}</span>
                        <p>{{ qnaDetail.title}} <img v-if="qnaDetail.secretYn === 'Y'" src="../../../../assets/images/icon-lock.png" /></p>
                    </div>
                    <span>작성일 <i>{{ $yyyymmddOrHHmm(qnaDetail.createDate) }}</i></span>
                    <span>작성자 <i>{{ qnaDetail.createUserName || '작성자' }}</i></span>
                    <span>조회수 <i>{{ $number(qnaDetail.hitCount) }}</i></span>
                </div>
                <div class="body">
                    <div class="text" v-html="qnaDetail.content"></div>
                    <div class="answer" v-if="qnaDetail.answer">
                        <span>답변</span>
                        <div class="text">
                            {{ qnaDetail.answer }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-wrap space-between">
                <KSASecondaryButton class="gray_button" text="목록" @click="router.push({name: 'InsightsQNA'})" />
                <div v-if="qnaDetail.createUserLoginId && userStore.user.userLoginId === qnaDetail.createUserLoginId">
                    <KSASecondaryButton class="red_button" text="삭제" @click="deleteQNA" />
                    <KSAPrimaryButton text="수정" @click="editQNA" />
                </div>
            </div>
        </div>
    </div>

    <div class="container" v-if="isEdit">
        <div class="inner">
            <div class="title page-title">
                <h3>Q&amp;A 작성</h3>
                <p>
                    - 질문을 작성하시면 영업일 기준 1~2일 이내에 답변해드립니다.<br>
                    - 모든 정보는 필수입력 사항입니다.
                </p>
            </div>
            <div class="table-form">
                <table class="vertical">
                    <colgroup>
                        <col width="110px" />
                        <col />
                    </colgroup>
                    <tr>
                        <th>제목</th>
                        <td>
                            <KSAInput class="text-box mb-xs" v-model="qnaDetailTemp.title" placeholder="제목을 입력하세요."/>
                            <KSACheckBox label="비밀글로 설정합니다." v-model="isSecret"  />
                        </td>
                    </tr>
                    <tr>
                        <th>내용</th>
                        <td>
<!--                            <KSATextArea class="textarea-box" v-model="qnaDetailTemp.content" placeholder="내용을 입력하세요."></KSATextArea>-->
                            <KSACKEditor v-model="qnaDetailTemp.content" />
                        </td>
                    </tr>
                </table>
            </div>
            <div class="btn-wrap space-between">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancelEditQNA" />
                <KSAPrimaryButton text="수정" @click="updateQNA" :disabled="isDisabled" />
            </div>
        </div>
    </div>

    <ConfirmModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";
    .textarea-box {
        height: 300px;
    }
</style>
