// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, watch } from 'vue'

/*
 * @description 
 * 강의목록에서 강의상세로 이동할때 강의상세 조회시 필요한 정보를 사용하기위함
 */

export const useMyPageLectDetailStore = defineStore('myPageLectDetailStore', () => {

    const lecture = ref({});

    const lectureInStorage = sessionStorage.getItem('lecture')
    if (lectureInStorage) {
        lecture.value = JSON.parse(lectureInStorage)
    }

    watch(() => lecture, (state) => {
        sessionStorage.setItem('lecture', JSON.stringify(lecture.value))
    }, { deep: true })

    const setLecture = (lect) => {
        lecture.value = lect;
    }

    return {
        lecture,
        setLecture,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMyPageLectDetailStore, import.meta.hot))
}