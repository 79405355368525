<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import {useUserStore} from "../../../../user/stores/user";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import insightsQNAService from "../../../../user/services/insights/insightsQNAService";

    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const loadingStore = usePageLoadingStore()

    onMounted(() => {
        const { id } = route.params;
        if(!id) {
            goBack();
        } else {
            qnaDetail.value.boardId = id;
            retrieveDetail(id);
        }
    })

    const qnaDetail = ref({})
    const isEdit = ref(false)

    const input = ref({
        answer: '',
    });

    const inputError = ref({
        answer: '',
    });

    const retrieveDetail = () => {
        loadingStore.loading = true;
        insightsQNAService.retrieveQNADetail(qnaDetail.value.boardId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    qnaDetail.value = result;

                    if(!qnaDetail.value.answer) {
                        isEdit.value = true;
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickRegAnswer = () => {
        const { answer } = input.value;
        const { boardId } = qnaDetail.value;

        let paramObj = {
            boardId,
            answer,
        }

        loadingStore.loading = true;
        insightsQNAService.updateAnswer(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    qnaDetail.value = result;
                    isEdit.value = false;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickEditCancel = () => {
        input.value.answer = qnaDetail.value.answer;
        isEdit.value = false;
    }

    const onClickEdit = () => {
        input.value.answer = qnaDetail.value.answer;
        isEdit.value = true;
    }

    const goBack = () => {
        router.push({name: 'CSQNAList'})
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>Q&amp;A</h3>
                </div>
                <div class="btn-wrap text-left mb-xs">
                    <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                </div>

                <div class="board-detail">
                    <div class="header" style="position: relative">
                        <div class="board-title">
                            <span :class="{'complete': qnaDetail.answer}">답변 {{ qnaDetail.answer ? '완료' : '대기' }}</span>
                            <p>{{ qnaDetail.title }} <img v-if="qnaDetail.secretYn === 'Y'" src="/src/assets/images/icon-lock.png" /></p>
                        </div>
                        <span>작성일 <i>{{ $yyyymmddOrHHmm(qnaDetail.createDate) }}</i></span>
                        <span>작성자 <i>{{ qnaDetail.createUserName || '작성자' }}<span v-if="qnaDetail.createUserLoginId">({{ qnaDetail.createUserLoginId }})</span></i></span>
                        <span>조회수 <i>{{ $number(qnaDetail.hitCount) }}</i></span>
                    </div>
                    <div class="body">
                        <div class="text" v-html="qnaDetail.content"></div>
                        <div class="answer">
                            <span>답변</span>
                            <p>
                                {{ qnaDetail.answererUserName }}
                                <span v-if="qnaDetail.answererUserLoginId">({{ qnaDetail.answererUserLoginId }})</span>
                            </p>
                            <div v-if="isEdit">
                                <KSATextArea class="textarea-box" v-model="input.answer" :msg="inputError.answer" :displayMaxLenYn="false" placeholder="답변을 입력하세요." max-length="500"/>
                                <div class="text-right">
                                    <KSATextButton v-if="qnaDetail.answer" class="btn save" text="취소" @click="onClickEditCancel" />&nbsp;
                                    <KSATextButton class="btn save" text="저장하기" @click="onClickRegAnswer" :disabled="!input.answer" />
                                </div>
                            </div>
                            <div v-else>
                                <div class="text">
                                    <div v-html="qnaDetail.answer"></div>
                                    <p>{{ $yyyymmddOrHHmm(qnaDetail.answerDate) }}</p>
                                </div>
                                <KSATextButton class="btn edit black_button" text="수정하기" @click="onClickEdit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .textarea-box {
        height: 100px;
    }
</style>