import http from '../../../../api/http';

function retrieveAllPaging(paramObj) {
    return http.post('/insights/qna/retrieveAllPaging', {
        boardGb: paramObj.boardGb || "QNA",
        pageNumber: paramObj.pageNo,
        myPostGb: paramObj.myPostGb || 'N',
        size: paramObj.size,
        searchType: paramObj.searchType,
        searchInput: paramObj.searchInput,
        startYmd: paramObj.startYmd,
        endYmd: paramObj.endYmd,
        answerStateList: paramObj.answerStateList || ["Y","N"],
    });
}

function retrieveQNADetail(boardId) {
    return http.post('/insights/qna/retrieve', {
        boardId,
    })
}

function createQNA({ title, content, secretYn}) {
    return http.post('/insights/qna/create', {
        boardGb: "QNA",
        // "boardGroup": "qa",
        title,
        content,
        secretYn,
    })
}

function updateQNA(paramObj) {
    return http.post('/insights/qna/update', {
        boardId: paramObj.boardId,
        boardGb: "QNA",
        boardGroup: "qa",
        title: paramObj.title,
        content: paramObj.content,
        secretYn: paramObj.secretYn || 'N',
        answer: paramObj.answer || '',
        // "hitCount": 0,
        // "createId": 0
    })
}

function deleteQNA(boardId) {
    return http.post('/insights/qna/delete', {
        boardId,
    })
}

function updateAnswer(paramObj) { // 답변 저장
    return http.post('/insights/qna/answer', {
        boardId: paramObj.boardId,
        answer: paramObj.answer,
    })
}

export default {
    retrieveAllPaging,
    createQNA,
    retrieveQNADetail,
    updateQNA,
    deleteQNA,
    updateAnswer,
};