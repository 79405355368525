<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import insightsNotifyService from "../../../../user/services/insights/insightsNotifyService";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import fileService from "../../../../../services/fileService";

    const route = useRoute();
    const router = useRouter();
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name: 'CSNotifyList'})
        } else {
            retrieveNoticeDetail(id)
        }
    })

    const MAX_SIZE = 200;
    const noticeDetail = ref({
        boardId: route.params.id || '',
        title: '',
        notifyYn: 'N',
        content: '',
        answer: '',
        attachList: [],
    });

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])

    const retrieveNoticeDetail = (id) => {
        loadingStore.loading = true;
        insightsNotifyService.retrieveNotifyDetail(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    noticeDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const deleteNotice = async () => {
        modalContent.value = ["공지사항을 삭제할까요?", "삭제 시 사용자 사이트에 즉시 적용되며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show()
        if(ok){
            _deleteNotice()
        }
    }

    function _deleteNotice() {
        loadingStore.loading = true;
        insightsNotifyService.deleteNotice(noticeDetail.value.boardId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    goBack();
                } else {
                    modalContent.value = ["삭제를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            console.log(res);
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
        });
    }

    const updateNotice = () => {
        router.push({name:'CSNotifyUpdate', params:{id: noticeDetail.value.boardId}})
    }

    const goBack = () => {
        router.push({name:'CSNotifyList'})
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>공지사항</h3>
                </div>
                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                    <div>
                        <KSASecondaryButton text="수정" @click="updateNotice" />
                        <KSASecondaryButton class="red_button" text="삭제" @click="deleteNotice" />
                    </div>
                </div>
                <div class="board-detail">
                    <div class="header">
                        <div class="board-title">
                            <p v-if="noticeDetail.notifyYn === 'Y'">{{ noticeDetail.title }}📣<img v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></p>
                            <p v-else>{{ noticeDetail.title }}<img v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></p>
                        </div>
                        <span>작성일<i>{{ $yyyymmddOrHHmm(noticeDetail.createDate) }}</i></span>
                        <span>작성자 <i>{{ noticeDetail.createUserName }}<span v-if="noticeDetail.createUserLoginId">({{ noticeDetail.createUserLoginId}})</span></i></span>
                        <span>조회수 <i>{{ $number(noticeDetail.hitCount)}}</i></span>
                    </div>
                    <div class="body">
                        <div class="text" v-html="noticeDetail.content"></div>
                    </div>
                    <div class="control">
                        <dl class="download" v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName">
                            <dt>첨부</dt>
                            <dd>
                                <div v-for="attach in noticeDetail.attachList">
                                    {{ attach.fileName }}
                                    <KSATextButton v-if="attach.fileDir" class="ml-xs" text="다운로드" @click="$download(attach.fileDir)" />
                                </div>
                            </dd>
                        </dl>
                        <div v-if="noticeDetail.prevBoard && noticeDetail.prevBoard.boardId">이전글<RouterLink :to="{name:'CSNotifyDetail', params:{id: noticeDetail.prevBoard.boardId}}"><span>{{ noticeDetail.prevBoard.title }}</span></RouterLink></div>
                        <div v-if="noticeDetail.nextBoard && noticeDetail.nextBoard.boardId">다음글<RouterLink :to="{name:'CSNotifyDetail', params:{id: noticeDetail.nextBoard.boardId}}"><span>{{ noticeDetail.nextBoard.title }}</span></RouterLink></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="삭제" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .textarea-box {
        height: 300px;
    }
    small {
        @include font-size_xs;
        color: $gray_color;
    }

</style>