<script setup>
    import { ref, onMounted } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import insightsNotifyService from "../../services/insights/insightsNotifyService";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";

    const loadingStore = usePageLoadingStore()

    const router = useRouter();
    const route = useRoute();

    const noticeDetail = ref({
        boardId: route.params.id || '',
        title: '',
        notifyYn: 'N',
        content: '',
        answer: '',
        createDate: null,
        modifyDate: null,
        hitCount: 0,
        attachList: [],
    });

    onMounted(() => {
        retrieveNoticeDetail();
    })

    const retrieveNoticeDetail = () => {
        const { boardId } = noticeDetail.value;
        if(!boardId) {
            router.push({name: 'InsightsNotify'})
            return false;
        }
        loadingStore.loading = true;
        insightsNotifyService.retrieveNotifyDetail(boardId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    noticeDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>공지사항</h3>
            </div>
            <div class="board-detail">
                <div class="header">
                    <div class="board-title">
                        <p v-if="noticeDetail.notifyYn === 'Y'">{{noticeDetail.title }}📣<img v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName" src="/src/assets/images/icon-file.png" /></p>
                        <p v-else>{{ noticeDetail.title }}<img v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName" src="/src/assets/images/icon-file.png" /></p>
                    </div>
                    <span>작성일 <i>{{ $yyyymmddOrHHmm(noticeDetail.createDate) }}</i></span>
                    <span>조회수 <i>{{ $number(noticeDetail.hitCount) }}</i></span>
                </div>
                <div class="body">
                    <div class="text" v-html="noticeDetail.content"></div>
                </div>
                <div class="control">
                    <dl class="download" v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName">
                        <dt>첨부</dt>
                        <dd>
                            <div v-for="attach in noticeDetail.attachList">
                                {{ attach.fileName }}
                                <KSATextButton v-if="attach.fileDir" class="ml-xs" text="다운로드" @click="$download(attach.fileDir)" />
                            </div>
                        </dd>
                    </dl>
                    <div v-if="noticeDetail.prevBoard && noticeDetail.prevBoard.boardId">이전글<RouterLink :to="{name:'InsightsNotifyDetail', params:{id: noticeDetail.prevBoard.boardId}}"><span>{{ noticeDetail.prevBoard.title }}</span></RouterLink></div>
                    <div v-if="noticeDetail.nextBoard && noticeDetail.nextBoard.boardId">다음글<RouterLink :to="{name:'InsightsNotifyDetail', params:{id: noticeDetail.nextBoard.boardId}}"><span>{{ noticeDetail.nextBoard.title }}</span></RouterLink></div>
                </div>
            </div>
            <div class="btn-wrap text-left">
                <KSASecondaryButton class="gray_button" text="목록" @click="router.push({name: 'InsightsNotify'})" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    img {
        width: 14px;
        vertical-align: middle;
        margin: 0 0 0 4px;
    }
</style>
