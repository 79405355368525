<script setup>
    import { ref, defineProps, defineEmits } from 'vue'
    const { placeholder } = defineProps(['placeholder'])

    const text = ref('')

    const emit = defineEmits(['search']);

    const emitSearch = () => {
        emit("search", text.value);
    }
</script>

<template>
    <div>
        <input type="text" v-model="text" :placeholder="placeholder">
        <button @click="emitSearch">검색</button>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    div {
        display: flex;
        justify-content: right;
        input {
            height: 48px;
            border: $border_line;
            border-radius: 4px;
            padding: 10px 12px;
            margin-right: 10px;
            &::placeholder {
                color: $gray_color;
            }
        }
        button {
            height: 48px;
            background: $black_color;
            color: #fff;
            font-size: $font-size_sm;
            line-height: normal;
            border-radius: 4px;
            padding: 10px 20px;
            cursor:pointer;
        }
    }
</style>