<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import insightsNewsletterService from "../../../../user/services/insights/insightsNewsletterService";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveList()
    })

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(1);
    const totalCount = ref(0);

    const searchOpen = ref(true)

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const retrieveList = () => {
        let paramObj = {
            pageNo: currentPage.value,
            size: pageSize.value,
            searchType: '', //searchInputs.value.searchType,
            searchInput: '', //searchInputs.value.searchInput,
        };

        loadingStore.loading = true;
        insightsNewsletterService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalCount.value = result.totalElements;
                    totalPages.value = result.totalPages;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveList();
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>뉴스레터</h3>
                </div>

                <div class="list-top">
                    <div class="grid">
                        <KSASecondaryButton class="gray_button" text="뉴스레터 작성" @click="router.push({name:'CSNewsletterCreate'})" />
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ $number(totalCount) }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>No</th>
                            <th>제목</th>
                            <th>작성자</th>
                            <th>아이디</th>
                            <th>작성일</th>
                        </tr>
                        <tr v-for="newsletter in list">
                            <td>{{ newsletter.boardNo }}</td>
                            <td class="link text-left"><RouterLink :to="{name:'CSNewsletterDetail', params:{id: newsletter.newsletterId}}">{{ newsletter.title }}</RouterLink></td>
                            <td>{{ newsletter.createUserName }}</td>
                            <td>
                                <span v-if="newsletter.creator && newsletter.creator.userLoginId">{{ newsletter.creator.userLoginId }}</span>
                                <span v-else>-</span>
                            </td>
                            <td>{{ $yyyymmddHHmmss(newsletter.createDate) }}</td>
                        </tr>
                        <tr v-if="list.length === 0">
                            <td colspan="5">검색 결과가 없습니다.</td>
                        </tr>
                    </table>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

</style>