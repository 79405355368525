<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import mypageUserService from "../../../services/mypage/mypageUserService";
import { ref, defineExpose } from "vue";

const baseModal = ref(null);
const resolvePromise = ref(null);

const beforeUserGb = ref(null);
const afterUserGb = ref(null);
const seq = ref(0);

const show = () => {
  baseModal.value.open();
  return new Promise((resolve, _) => {
    resolvePromise.value = resolve;
  });
};

const init = (sequence, beforeUser, afterUser) => {
  seq.value = sequence;
  beforeUserGb.value = beforeUser;
  afterUserGb.value = afterUser;
}

const cancel = () => {
  // 모달 닫을 때, USER_GB_HISTORY 테이블 MODAL_YN N으로 변경
  changeModalYn();
  baseModal.value.close();
  resolvePromise.value(false);
};

const changeModalYn = () => {
  let paramObj = {
    seq: seq.value,
  }
  mypageUserService.changeUserGbHistoryModalYn(paramObj)
      .then( async (res) => {
        if(res.data.code.toLowerCase() === 'success') {
          // console.log("change modalYn success")
        } else {
          console.log("change modalYn fail")
        }
      }).catch( (error) =>  {
          console.log("change modalYn fail")
      });
}

defineExpose({
  show,
  init,
})

</script>
<template>
  <Modal ref="baseModal" class="vld-parent">
    <div class="modal-inner m-size">
      <button class="close" @click="cancel"></button>
      <div class="modal-body">
        <h2>회원 유형 <span style="color: #3344D9;">변경 안내</span></h2>
        <br>
        <p>회원 유형 변경 신청건이<br>
          <span style="font-weight: bold; text-decoration: underline;">정상적으로 승인 처리</span> 되었습니다.<br>
        </p>
        <div class="status-container-user-gb">
          <div class="status">
            <div class="left">
              <h4>이전 유형</h4><br>
              <p>{{ $userGbKR(beforeUserGb) }}</p>
            </div>
            <div class="center">
              <span class="status-arrow"></span>
            </div>
            <div class="right">
              <h4>변경 유형</h4><br>
              <p style="font-weight: bold; color: #3344D9;">{{ $userGbKR(afterUserGb) }}</p>
            </div>
          </div>
        </div>
        <p>현 시간부터 변경된 회원 유형으로<br>
          서비스 이용이 가능합니다.<br>
        </p>
      </div>
      <div class="modal-footer btn-wrap">
        <button class="full-btn" @click="cancel">확인</button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
  margin-top: 50px;
  .close {
    top: -20px !important;
    right: -10px !important;;
  }
  .modal-footer {
    display: block;
    padding: 0 30px;
  }
}
</style>