<script setup>
    import { ref, computed, isProxy, toRaw, watch, provide, onMounted, readonly } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { useSiteMenuStore } from '../../stores/sitemenu'
    import { useMatchingUpdateStore } from '../../pages/user/stores/matching/matchingUpdateStore'
    import _ from 'lodash'

    const route = useRoute()
    const router = useRouter()
    const siteMenuStore = useSiteMenuStore();
    const userInStorage = sessionStorage.getItem('user')
    const matchingUpdateStore = useMatchingUpdateStore();

    const corpMasterYn = ref('');
    const propStateCnt = ref(0);
    const resStateCnt = ref(0);
    const userGb = ref('');
    if(userInStorage) {
      const { userInfo } = JSON.parse(userInStorage)
      corpMasterYn.value = userInfo.corpMasterYn;
      userGb.value = userInfo.userGb;
    }
    if(matchingUpdateStore) {
      propStateCnt.value = matchingUpdateStore.matchingUpdate.propStateCnt;
      resStateCnt.value = matchingUpdateStore.matchingUpdate.resStateCnt;
    }

    const masterMenuId = [61,62,63,64];

    const alertModal = ref(null)

    // 기업마스터인지 아닌지 확인하여 메뉴 리스트 만들기
    const myPageMenu = computed( ()=> {
        const originalMenu =  _.find(siteMenuStore.menuList, { menuAreaGb: '02', menuId: 29});
        // originalMenu가 없거나 child 프로퍼티가 없거나 배열이 아닌 경우
        if(!originalMenu || !originalMenu.child || !Array.isArray(originalMenu.child)) {
          //console.log('Menu data is invalid.');
          return [];
        }

        const filterMenu = [];
        for(let i=0; i < originalMenu.child.length; i++) {
          if(masterMenuId.indexOf(originalMenu.child[i].menuId) > -1) {
            if(corpMasterYn.value === 'Y'){
              filterMenu.push(originalMenu.child[i]);
            }
          } else {
            filterMenu.push(originalMenu.child[i]);
          }
        }
        filterMenu.menuName = originalMenu.menuName;
        return filterMenu;
    })

    const onClickMenu = (menu) => {
        const closeMenuId = [33, 34];
        if(closeMenuId.indexOf(menu.menuId) > -1) {
            alertModal.value.show();
        } else{
            router.push(menu.menuUrl);
        }
    }

    const isCurrentRoute = (menuUrl) => {
        const _route = isProxy(route) ? toRaw(route) : route;
        return _route.fullPath.value.indexOf(menuUrl) > -1;

    }
</script>

<template>
<!--    <div v-if="navigationStore.localNavigation.menuLevel1.startsWith('HomeMyPage')">-->
    <div>
        <div class="title page-title">
            <h3>{{ myPageMenu.menuName }}</h3>
        </div>
        <ul>
            <li :class="{'on': isCurrentRoute(childMenu.menuUrl)}" v-for="childMenu in myPageMenu">
                <span v-if="corpMasterYn === 'Y'">
                  <span v-if="propStateCnt > 0 && childMenu.menuId === 61" class="red-dot"></span>
                  <span v-else-if="resStateCnt > 0 && childMenu.menuId === 62" class="red-dot"></span>
                </span>
                <span v-else-if="userGb === 'STUDENT'">
                  <span v-if="resStateCnt > 0 && childMenu.menuId === 65" class="red-dot"></span>
                  <span v-else-if="propStateCnt > 0 && childMenu.menuId === 66" class="red-dot"></span>
                </span>
                <a href="javascript:void(0);" @click="onClickMenu(childMenu)">{{ childMenu.menuName }}</a>
<!--                <i>1</i>-->
            </li>
        </ul>
        <AlertModal ref="alertModal" :content="['준비중 입니다.']" />
    </div>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    .snb {
        float: left;
        width: 272px;
        ul {
            border: $border_line;
            padding: 24px;
            li {
                display: flex;
                align-items: center;
                @include font-size_md;
                border-bottom: $border_line;
                padding: 16px 0;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                &.on {
                    color: $blue_color;
                    font-weight: 700;
                }
                i {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 18px;
                    height: 18px;
                    font-style: normal;
                    @include font-size_xs;
                    background: $red_color;
                    color: #fff;
                    border-radius: 50%;
                    text-align: center;
                    margin-left: 4px;
                }
            }
        }
    }
</style>