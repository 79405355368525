<script setup>
    import { ref, watch, computed, onMounted, getCurrentInstance } from 'vue'
    import { RouterLink } from 'vue-router'
    import adminUserService from "../../services/user/adminUserService";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import _ from "lodash";
    import moment from "moment";

    const loadingStore = usePageLoadingStore()
    const { $pageListBoardNo, $toast } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveList()
    })

    const userGbListRef = ref(null)
    const searchTypeRef = ref(null)
    const searchDurationGbRef = ref(null)
    const searchOrderByRef = ref(null);  // 정렬
    const searchPageSizeRef = ref(null);  // 페이지 사이즈

    const list = ref([])

    const searchOpen = ref(true)
    const _searchInput = ref({
        selectedUserGb: '',
        userGbList: ["STUDENT","COMPANY","SCHOOL","TEACHER"],
        corpMasterYn: false,
        searchType: 'name', // code 회원코드,name 이름, id 아이디, email 이메일
        searchInput: '',
        searchDurationGb: 'L', // (L 최근 접속일 C 가입일
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
        orderProperty: '',
        orderDirection: '',
    })

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);


    const userGbGroup = ref([
        { label:"학생/취업준비생", value: "STUDENT" },
        { label:"재직자/기업", value: "COMPANY" },
        // { label:"재직자/기업-마스터", value: "COMPANY_MASTER" },
        { label:"대학/연구실", value: "SCHOOL" },
        { label:"강사/전문가", value: "TEACHER" },
    ]);

    const searchTypeGroup = ref([
        { label:"이름", value: "name", placeholder: "이름을 입력해주세요."  },
        { label:"회원코드", value: "code", placeholder: "회원코드를 입력해주세요." },
        { label:'핸드폰번호', value: 'phone', placeholder: '"-"를 제외한 핸드폰번호 뒷4자리를 입력해 주세요.' },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        { label:"이메일", value: "email", placeholder: "이메일을 입력해주세요."  },
    ]);

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: _searchInput.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    const searchDurationGbGroup = ref([
        { label:"최근 접속일", value: "L" },
        { label:"가입일", value: "C" },
    ])

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    // 검색조건 - 정렬
    const orderByList = [
      { label: '정렬', value: ''},
      { label: '회원코드 빠른순', value: 'userId ASC'},
      { label: '회원코드 느린순', value: 'userId DESC'},
      { label: '이름 오름차순', value: 'userName ASC'},
      { label: '이름 내림차순', value: 'userName DESC'},
      { label: '최근 접속일 빠른순', value: 'lastLoginDate ASC'},
      { label: '최근 접속일 느린순', value: 'lastLoginDate DESC'},
      { label: '가입일 빠른순', value: 'createDate ASC'},
      { label: '가입일 느린순', value: 'createDate DESC'},
    ]

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])
    const okButtonText = ref("메일발송")

    const retrieveList = () => {
        let paramObj = {
            userGbList: _searchInput.value.userGbList,
            searchCorpMasterYn: _searchInput.value.corpMasterYn ? 'Y' : 'N',
            searchType: _searchInput.value.searchType,
            searchInput: _searchInput.value.searchInput,
            searchDurationGb: _searchInput.value.searchDurationGb,
            startYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.startYmd,
            endYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
            orderProperty: _searchInput.value.orderProperty,
            orderDirection: _searchInput.value.orderDirection,
        }

        loadingStore.loading = true;
        adminUserService.retrieveAllUsers(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);

                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const onSelectSearchTypeGroup = (option) => {
        _searchInput.value.searchType = option.value;
    }

    const onSelectSearchDurationGbGroup = (option) => {
        _searchInput.value.searchDurationGb = option.value;
    }

    const clearSearchInput = () => {
        _searchInput.value.userGb = '';
        _searchInput.value.userGbList = _.map(userGbGroup.value, 'value');
        _searchInput.value.corpMasterYn = false;
        _searchInput.value.searchType = 'name';
        _searchInput.value.searchInput = '';
        _searchInput.value.searchDurationGb = 'L';
        _searchInput.value.searchDurationType = 'all';

        userGbListRef.value.initOptions();
        searchTypeRef.value.initSelectedOption(_searchInput.value.searchType);
        searchDurationGbRef.value.initSelectedOption(_searchInput.value.searchDurationGb);
        searchOrderByRef.value.initSelectedOption(''); // 정렬
        searchPageSizeRef.value.initSelectedOption(30);  // 페이지 사이즈
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    // 정렬 셀렉트 박스 선택
    const onSelectOrderBy = (option) => {
      loadingStore.loading = true;
      const [orderProperty, orderDirection] = option.value.split(" ");
      currentPage.value = 1;
      _searchInput.value.orderProperty = orderProperty;
      _searchInput.value.orderDirection = orderDirection;
      retrieveList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveList();
    }

    const onClickExcelDownload = async () => { // 엑셀 다운로드
        modalContent.value = ["회원목록을 이메일로 발송할까요?"];
        okButtonText.value = "메일발송";
        const ok = await confirmModal.value.show();
        if(ok){
            sendEmailAllUsers();
        }
    }

    const sendEmailAllUsers = () => {
        let paramObj = {
            userGbList: _searchInput.value.userGbList,
            searchType: _searchInput.value.searchType,
            searchInput: _searchInput.value.searchInput,
            searchDurationGb: _searchInput.value.searchDurationGb,
            startYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.startYmd,
            endYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }
        adminUserService.sendEmailAllUsers(paramObj)
            .then( (res) => {
                $toast('', `이메일 발송 데이터 생성을 시작합니다.`);
            }).catch( (err) =>  {
                let errorMessage = '이메일 발송 중 오류가 발생했습니다.';
                try {
                    const responseData = err.response?.data;
                    if (responseData?.result?.message) {
                        errorMessage = responseData.result.message;
                    }
                } catch (parseError) {
                    console.error('에러 메시지 추출 중 오류가 발생했습니다.:', parseError);
                }
                // Toast에 에러 메시지 표시
                $toast('error', errorMessage);
        });
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>회원 목록</h3>
                </div>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen = !searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>회원 유형</dt>
                            <dd>
                                <KSACheckBoxGroup
                                    ref="userGbListRef"
                                    className="mr-md"
                                    name="userGbList"
                                    v-model="_searchInput.userGbList"
                                    :options="userGbGroup" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>마스터 회원</dt>
                            <dd>
                                <KSACheckBox label="마스터 회원 보기" v-model="_searchInput.corpMasterYn" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchTypeRef" :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
                            </dt>
                            <dd>
                                <KSAInput class="text-box" v-model="_searchInput.searchInput" :placeholder="searchInputPlaceholder" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchDurationGbRef" :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                        <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="_searchInput.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="_searchInput.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                            :startDate="_searchInput.startYmd"
                                            :endDate="_searchInput.endYmd"
                                            @update:startDate="(value)=> _searchInput.startYmd = value"
                                            @update:endDate="(value)=> _searchInput.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <div class="grid">
                        <KSASecondaryButton class="gray_button" text="엑셀 다운로드" @click="onClickExcelDownload" />
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ $number(totalCount)}}</span>개</p>
                    <div class="grid">
                      <KSASelect ref="searchOrderByRef" :options="orderByList" class="select" @selectOption="onSelectOrderBy"/>
                      <KSASelect ref="searchPageSizeRef" :options="pageSizeGroup" class="select ml-xs" @selectOption="onSelectPageSize"/>
                    </div>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>No</th>
                            <th>회원 유형</th>
                            <th>회원 유형 변경 내역</th>
                            <th>회원코드</th>
                            <th>이름</th>
                            <th>아이디</th>
                            <th>핸드폰 번호</th>
                            <th>이메일</th>
                            <th>최근 접속일</th>
                            <th>가입일</th>
                        </tr>
                        <tr v-for="user in list">
                            <td>{{ user.boardNo }}</td>
                            <td>
                              {{ $userGbKR(user.userGb) }}<span v-if="user.corpMasterYn === 'Y' && user.userGb === 'COMPANY'">(마스터)</span>
                            </td>
                            <td>
                                <span v-if="user.userGbHistoryCount === 0">-</span>
                                <span v-else>{{ user.userGbHistoryCount }}</span>
                            </td>
                            <td>{{ user.userCd }}</td>
                            <td class="link"><RouterLink :to="{name: 'MemberDetail', params:{id: user.userId}}">{{ user.userName }}</RouterLink></td>
                            <td>{{ user.userLoginId }}</td>
                            <td>{{ $phoneNum(user.phoneNum) }}</td>
                            <td class="text-left">{{ user.email }}</td>
                            <td>{{ $yyyymmddHHmmss(user.lastLoginDate) }}</td>
                            <td>{{ $yyyymmddHHmmss(user.createDate) }}</td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>