<template>
    <div class="container">
        <div class="inner">
            <h1>잘못된 경로입니다.</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style lang="scss" scoped>
    @import "../assets/scss/theme";

    .container {
        .inner {
            h1 {
                position: absolute;
                top: 50%;
                left: 50%;
                text-align: center;
                @include font-size_xxl;
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }
</style>