<script setup>
    import { ref, watch, computed, onMounted, getCurrentInstance, inject } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import _ from "lodash";
    import moment from "moment";
    import lectureService from "../../services/lecture/lectureService";
    import lectureHomeworkService from "../../services/lecture/lectureHomeworkService";
    import LectureSendMailModal from '../../components/modal/lecture/LectureSendMailModal.vue'
    import LectureSendSMSModal from '../../components/modal/lecture/LectureSendSMSModal.vue'
    import mailMsgServcie from "../../services/mail/mailMsgServcie";
    import MyPageResumeModal from "../../../user/components/modal/mypage/MyPageResumeModal.vue";
    import LectureEvalStateChangeModal from "../../../admin/components/modal/lecture/LectureEvalStateChangeModal.vue";
    import LectureLearningStatusModal from "../../components/modal/lecture/LectureLearningStatusModal.vue";

    const route = useRoute()
    const router = useRouter()
    const getSystemCodeValues = inject('systemCodeValues');
    const loadingStore = usePageLoadingStore()
    const { $toast, $pageListBoardNo, $gb : { USER_GB }, $lectStateGb2, $evalStateGb2 } = getCurrentInstance().appContext.config.globalProperties;


    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            goBack();
        } else {
            retrieveDetail(id)
            retrieveDownloadCount(id)
        }
    })

    // 강의정보
    const lectDetail = ref({})
    const downloadCount = ref(0)
    const defaultDownloadCount = 100  // 100
    const tabIndex = ref(0)

    // 팝업정보
    const modal = ref(null)
    const confirmModal = ref(null)
    const sendMailModal = ref(null)
    const sendSMSModal = ref(null)
    const resumeModal = ref(null)
    const evalStateChangeModal = ref(null)
    const lectureLearningStatusModal = ref(null)
    const modalContent = ref([])
    const okButtonText = ref("삭제")

    const lectStateGb2 = ref($lectStateGb2)
    const evalStateGb2 = ref($evalStateGb2)

    // 심사 상태2 EVAL_STATE_GB2
    const evalStateGbList = [
      { label: '서류 접수', value: '01'},
      { label: '서류 심사중', value: '02'},
      { label: '서류 탈락', value: '03'},
      { label: '서류 합격(면접 심사중)', value: '04'},
      { label: '면접 탈락', value: '05'},
      { label: '최종 합격(수강대기)', value: '06'},
      { label: '최종 합격(수강중)', value: '07'},
      { label: '최종 합격(수강 종료)', value: '08'},
      // { label: '수강 신청 취소(사용자)', value: '09'},
      { label: '수강 취소(Admin)', value: '10'},
    ]

    // 오프라인 수강 상태2 LECT_STATE_GB2 (구)lectStateGBList
    const offlineLectStateGBList = [
      { label: '신청 완료', value: '01'},
      { label: '신청 심사(서류)', value: '02'},
      { label: '신청 반려(서류)', value: '03'},
      { label: '신청 심사(면접)', value: '04'},
      { label: '신청 반려(면접)', value: '05'},
      { label: '수강 대기(확정)', value: '06'},
      { label: '수강중', value: '07'},
      { label: '수강 종료', value: '08'},
      //{ label: '수강 신청 취소(사용자)', value: '09'},
      { label: '수강 취소(Admin)', value: '10'},
    ]

    // 온라인 수강 상태 (구)lectStateGBList
    const onlineLectStateGBList = [
      { label: '신청 완료', value: '01'},
      { label: '수강 대기(확정)', value: '06'},
      { label: '수강중', value: '07'},
      { label: '수강 종료', value: '08'},
      { label: '수강 취소', value: '10'},
    ]

    const evalMessageList = [
        { label: '서류 합격 메시지 전송', value: '04'},
        { label: '최종 메시지 전송', value: '06'},
    ]

    // 데이터
    const homeworkSummaryList = ref([]);  // 과제요약정보 목록
    const userHomeworkList = ref([]); // 과제제출정보 목록


    // s : 강의신청
    let disabledWatchIsSelectedAll = false; // 개별 checkbox 클릭시 watch disabled
    let disabledWatchList = false; // all checkbox 클릭시 watch disabled

    const isSelectedAll = ref(false);
    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(userLectureList.value);
            _.each(newList, (el)=>{
                if(el.lectStateGb !== '10' || el.lectStateGb !== '09') { // 리스트에서 수강 취소 체크박스는 disabled
                    el.checked = newValue;
                } else {
                    el.checked = false;
                }
            });
            disabledWatchList = true;
            userLectureList.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    const userLectureList = ref([]); // 강의신청 목록
    watch(userLectureList, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    // e : 강의신청

    // s : 수강생

    const isSelectedAll2 = ref(false);
    watch(isSelectedAll2, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(userLectureSelList.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            userLectureSelList.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    const userLectureSelList = ref([]); // 수강생 목록
    watch(userLectureSelList, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll2.value = true;
            } else {
                if(isSelectedAll2.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll2.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    // e : 수강생

    // s : 검색관련
    const searchOpen1 = ref(true); // 강의신청 tab 검색창
    const searchOpen2 = ref(true); // 수강생 tab 검색창
    const searchOpen3 = ref(true); // TEST tab 검색창
    const searchOpen4 = ref(true); // 과제 tab 검색창

    // 강의신청탭
    const searchLectStateGbRef1 = ref(null);  // 수강 상태
    const searchEvalStateGbRef1 = ref(null);  // 심사 상태
    const searchUserGbRef1 = ref(null);  // 유형
    const searchTypeRef1 = ref(null);  // 검색 name 이름,code 회원코드,phone 핸드폰뒷4자리,id 아이디
    const searchOrderByRef1 = ref(null);  // 정렬
    const searchPageSizeRef1 = ref(null);  // 페이지 사이즈

    // 수강생탭
    const searchUserGbRef2 = ref(null);  // 유형
    const searchFinYnRef2 = ref(null);  // Pass/Fail
    const searchTypeRef2 = ref(null);  // 검색 name 이름,code 회원코드,phone 핸드폰뒷4자리,id 아이디
    const searchOrderByRef2 = ref(null);  // 정렬
    const searchPageSizeRef2 = ref(null);  // 페이지 사이즈

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const searchInput1 = ref({ // 강의신청 tab 검색조건
        lectStateGbList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],  // 01:신청 완료,02:신청 심사,03:신청 반려,04:신청 심사,05:신청 반려,06:수강 대기,07:수강중,08:수강 종료,09:수강 신청 취소,10:수강 취소
        evalStateGbList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],  // 01:서류 접수,02:서류 심사중,03:서류 탈락,04:서류 합격,05:면접 탈락,06:최종 합격,07:최종 합격,08:최종 합격,09:수강 신청 취소,10:수강 취소
        userGbList: ["STUDENT","COMPANY","SCHOOL","TEACHER"],
        searchType: 'name', // name 이름,code 회원코드,phone 핸드폰뒷4자리,id 아이디
        searchInput: '',
        searchDurationGb: 'R',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
        orderProperty: '',
        orderDirection: '',
    });

    const searchInput2 = ref({ // 수강생 tab 검색조건
        userGbList: ["STUDENT","COMPANY","SCHOOL","TEACHER"],
        progRate: 100,
        finYnList: ["Y","N","D"],
        searchType: 'name', // name 이름,code 회원코드,phone 핸드폰뒷4자리,id 아이디
        searchInput: '',
        searchDurationGb: 'R',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
        orderProperty: '',
        orderDirection: '',
    });

    const searchInput4 = ref({ // 과제 tab 검색조건
        searchType: 'submitName', // submitName 제출자명, submitId 제출자아이디, markName 채점자명
        searchInput: '',
        searchDurationGb: 'R',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    });

    // 검색조건 - 유형. 강의신청 tab, 수강생 tab
    const userGbGroup = ref([
        { label:"학생/취업준비생", value: "STUDENT" },
        { label:"재직자/기업", value: "COMPANY" },
        // { label:"재직자/기업-마스터", value: "COMPANY_MASTER" },
        { label:"대학/연구실", value: "SCHOOL" },
        { label:"강사/전문가", value: "TEACHER" },
    ]);

    // 검색조건 - 검색기준 (회원코드, 이름, 아이디). 강의신청 tab, 수강생 tab
    const searchTypeGroup = ref([
        { label:"이름", value: "name", placeholder: "이름을 입력해주세요."  },
        { label:"회원코드", value: "code", placeholder: "회원코드를 입력해주세요." },
        { label:'핸드폰번호', value: 'phone', placeholder: '"-"를 제외한 핸드폰번호 뒷4자리를 입력해 주세요.' },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        // { label:"이메일", value: "email", placeholder: "이메일을 입력해주세요."  },
    ]);

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: searchInput1.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    });

    const searchInputPlaceholder2 = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: searchInput2.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    });

    // 검색조건 - 검색기준 (submitName 제출자명, submitId 제출자아이디, markName 채점자명). 과제 tab
    const searchTypeGroup4 = ref([
        { label:"제출자명", value: "submitName", placeholder: "제출자명을 입력해주세요." },
        { label:"제출자아이디", value: "submitId", placeholder: "제출자아이디를 입력해주세요."  },
        { label:"채점자명", value: "markName", placeholder: "채점자명을 입력해주세요."  }
    ]);

    const searchInputPlaceholder4 = computed(()=>{
        const option = _.find(searchTypeGroup4.value, { value: searchInput4.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    // 검색조건 - 기간 (S 제출일, M 채점일). 과제 tab.
    const searchDurationGbGroup = ref([
        { label:"제출일", value: "S" },
        { label:"채점일", value: "M" },
    ])

    // 검색조건 - 날짜검색기준 (all 모든 기간, term 기간 검색). 강의신청 tab > 수강신청일, 수강생 tab > 수강신청일, 과제 tab
    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    // 검색조건 - 정렬
    const orderByList = [
      { label: '정렬', value: ''},
      { label: '회원코드 빠른순', value: 'userId ASC'},
      { label: '회원코드 느린순', value: 'userId DESC'},
      { label: '이름 오름차순', value: 'userName ASC'},
      { label: '이름 내림차순', value: 'userName DESC'},
      { label: '수강 신청일 빠른순', value: 'regDt ASC'},
      { label: '수강 신청일 느린순', value: 'regDt DESC'},
    ]

    // 검색조건 - 페이지 크기
    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    // 검색조건 - 진도율. 수강생 tab
    const prograteGroup = ref([
        { label:"전체", value: 100 },
        { label:"30% 이하", value: 30 },
        { label:"50% 이하", value: 50 },
        { label:"80% 이하", value: 80 },
    ])

    // 검색조건 - Pass/Fail (Y Pass, N Fail, D 평가전). 수강생 tab
    const finYnGroup = ref([
        { label:"Pass", value: "Y" },
        { label:"Fail", value: "N" },
        { label:"평가전", value: "D" },
    ])

    const searchTypeOptions = [
        { label:'전체', value:'all', },
        { label:'제목', value:'title', },
        { label:'내용', value:'content', },
    ]

    // e : 검색관련

    // 강의데이터 조회
    const retrieveDetail = (lectId) => {
        loadingStore.loading = true;
        lectureService.retrieveLectureDetail(lectId)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    lectDetail.value = result;
                    // console.log(result);
                    retrieveAllUserLectureByLectId();
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // 이력서 전체 다운로드 수
    const retrieveDownloadCount = (id) => {
      let paramObj = {
        lectId: id,
      }
      loadingStore.loading = true;
      lectureService.retrieveUserLectureCount(paramObj)
          .then( (res) => {
            loadingStore.loading = false;
            // console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              downloadCount.value = res.data.result;
            }
          }).catch( (res) =>  {
        // console.log(res);
        loadingStore.loading = false;
        $toast('error', '오류가 발생하였습니다.');
      });
    }

    const getLectureUserSelCount = () => {
        lectureService.retrieveLectureDetail(lectDetail.value.lectId)
            .then( (res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    lectDetail.value.userLectureCount = result.userLectureCount;
                    lectDetail.value.userLectureSelCount = result.userLectureSelCount;
                }
            }).catch( (res) =>  {
            // console.log(res);
        });
    }

    // 강의신청 목록 조회
    const retrieveAllUserLectureByLectId = async () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            lectStateGbList: searchInput1.value.lectStateGbList,
            evalStateGbList: lectDetail.value.lectGb === '02' ? searchInput1.value.evalStateGbList : undefined,
            userGbList: searchInput1.value.userGbList,
            searchType: searchInput1.value.searchType,
            searchInput: searchInput1.value.searchInput,
            searchDurationGb: searchInput1.value.searchDurationGb,
            startYmd: searchInput1.value.searchDurationType === 'all' ? "" : searchInput1.value.startYmd,
            endYmd: searchInput1.value.searchDurationType === 'all' ? "" : searchInput1.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
            orderProperty: searchInput1.value.orderProperty,
            orderDirection: searchInput1.value.orderDirection,
        }
        await getSystemCodeValues();
        loadingStore.loading = true;
        lectureService.retrieveAllUserLectureByLectId(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    userLectureList.value = result.content;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // 수강생 목록 조회
    const retrieveAllUserLectureSelByLectId = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            userGbList: searchInput2.value.userGbList,
            progRate: searchInput2.value.progRate,
            finYnList: searchInput2.value.finYnList,
            searchType: searchInput2.value.searchType,
            searchInput: searchInput2.value.searchInput,
            searchDurationGb: searchInput2.value.searchDurationGb,
            startYmd: searchInput2.value.searchDurationType === 'all' ? "" : searchInput2.value.startYmd,
            endYmd: searchInput2.value.searchDurationType === 'all' ? "" : searchInput2.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
            orderProperty: searchInput2.value.orderProperty,
            orderDirection: searchInput2.value.orderDirection,
        }

        loadingStore.loading = true;
        lectureService.retrieveAllUserLectureSelByLectId(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    userLectureSelList.value = result.content;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // 검색조건 - 검색기준 매핑
    const onSelectSearchTypeGroup = (option) => {
        if(tabIndex.value === 0) { // 강의신청
            searchInput1.value.searchType = option.value;
        } else if(tabIndex.value === 1) { // 수강생
            searchInput2.value.searchType = option.value;
        } else if(tabIndex.value === 3) { // 과제
            searchInput4.value.searchType = option.value;
        }
    }

    // 검색조건 - 기간 매핑
    const onSelectSearchDurationGbGroup = (option) => {
        searchInput4.value.searchDurationGb = option.value;
    }

    // 검색조건 - 초기화 이벤트
    const clearSearchInput = () => {
        if(tabIndex.value === 0) { // 강의신청
            searchInput1.value = {
                lectStateGbList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],  // 01:신청 완료,02:신청 심사,03:신청 반려,04:신청 심사,05:신청 반려,06:수강 대기,07:수강중,08:수강 종료,09:수강 신청 취소,10:수강 취소
                evalStateGbList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],  // 01:서류 접수,02:서류 심사중,03:서류 탈락,04:서류 합격,05:면접 탈락,06:최종 합격,07:최종 합격,08:최종 합격,09:수강 신청 취소,10:수강 취소
                userGbList: ["STUDENT","COMPANY","SCHOOL","TEACHER"],
                searchType: 'name', // name 이름,code 회원코드,phone 핸드폰뒷4자리,id 아이디
                searchInput: '',
                searchDurationGb: 'R', // (L 최근 접속일 C 가입일
                searchDurationType: 'all',
                startYmd: '',
                endYmd: '',
                orderProperty: '',
                orderDirection: '',
            }
            searchLectStateGbRef1.value.initOptions()  // 수강 상태
            searchUserGbRef1.value.initOptions()  //유형
            searchTypeRef1.value.initSelectedOption(searchInput1.value.searchType); // 검색어
            searchOrderByRef1.value.initSelectedOption(''); // 정렬
            searchPageSizeRef1.value.initSelectedOption(30);  // 페이지 사이즈
            if(lectDetail.value.lectGb === '02') { // 오프라인
              searchEvalStateGbRef1.value.initOptions()  // 심사 상태
            }
        } else if(tabIndex.value === 1) { // 수강생
            searchInput2.value = {
                userGbList: ["STUDENT","COMPANY","SCHOOL","TEACHER"],
                progRate: 100,
                finYnList: ["Y","N","D"],
                searchType: 'name', // name 이름,code 회원코드,phone 핸드폰뒷4자리,id 아이디
                searchInput: '',
                searchDurationGb: 'R',
                searchDurationType: 'all',
                startYmd: '',
                endYmd: '',
                orderProperty: '',
                orderDirection: '',
            }
            searchUserGbRef2.value.initOptions()  //유형
            searchFinYnRef2.value.initOptions()  //Pass/Fail
            searchTypeRef2.value.initSelectedOption(searchInput2.value.searchType); // 검색어
            searchOrderByRef2.value.initSelectedOption(''); // 정렬
            searchPageSizeRef2.value.initSelectedOption(30);  // 페이지 사이즈
        } else if(tabIndex.value === 3) { // 과제
            searchInput4.value = {
                searchType: 'submitName', // submitName 제출자명, submitId 제출자아이디, markName 채점자명
                searchInput: '',
                searchDurationGb: 'R',
                searchDurationType: 'all',
                startYmd: '',
                endYmd: '',
            }
        }
    }

    // 검색조건 - 검색 이벤트
    const onClickSearch = () => {
        currentPage.value = 1;
        if(tabIndex.value === 0) { // 강의 신청
            retrieveAllUserLectureByLectId()
        } else if(tabIndex.value === 1) { // 수강생
            retrieveAllUserLectureSelByLectId();
        } else if(tabIndex.value === 3) { // 과제
            retrieveAllHomeworkPaging();
        }
    }

    // 페이지 선택
    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
       if(tabIndex.value === 0) { // 강의신청
            retrieveAllUserLectureByLectId();
       } else if(tabIndex.value === 1) { // 수강생
           retrieveAllUserLectureSelByLectId();
       } else if(tabIndex.value === 3) { // 과제
           retrieveAllHomeworkPaging();
       }
    }

    // 검색조건 - 페이지 크기 변경 이벤트
    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;

       if(tabIndex.value === 0) { // 강의신청
            retrieveAllUserLectureByLectId();
       } else if(tabIndex.value === 1) { // 수강생
            retrieveAllUserLectureSelByLectId();
       } else if(tabIndex.value === 3) { // 과제
           retrieveAllHomeworkPaging();
       }
    }

    // 정렬 셀렉트 박스 선택
    const onSelectOrderBy = (option) => {
      const [orderProperty, orderDirection] = option.value.split(" ");
      currentPage.value = 1;

      if(tabIndex.value === 0) { // 강의신청
        searchInput1.value.orderProperty = orderProperty;
        searchInput1.value.orderDirection = orderDirection;
        retrieveAllUserLectureByLectId();
      } else if(tabIndex.value === 1) { // 수강생
        searchInput2.value.orderProperty = orderProperty;
        searchInput2.value.orderDirection = orderDirection;
        retrieveAllUserLectureSelByLectId();
      } else if(tabIndex.value === 3) { // 과제
        retrieveAllHomeworkPaging();
      }
    }

    // 탭 선택 이벤트
    const onSelectTab = (option) => {
        tabIndex.value = option.value;
        currentPage.value = 1;
       if(tabIndex.value === 0) { // 강의 신청
            retrieveAllUserLectureByLectId();
            isSelectedAll.value = false;
            disabledWatchIsSelectedAll = false;
            disabledWatchList = false;
        } else if(tabIndex.value === 1) { // 수강생
            retrieveAllUserLectureSelByLectId();
            isSelectedAll2.value = false;
            disabledWatchIsSelectedAll = false;
            disabledWatchList = false;
       } else if(tabIndex.value === 3) { // 과제
           retrieveHomeworkSummary();
       }
    }

    // 강의신청 - 회원정보/이력서 전체 다운로드 버튼 이벤트
    const onClickAllUserDownload = async () => {
        if(downloadCount.value > 0 && downloadCount.value < defaultDownloadCount) { // 100건 미만. 다운로드
            modalContent.value = ["'신청완료'수강 상태의 모든 회원정보와 이력서를 내 PC로 다운로드할까요?"];
            okButtonText.value = "다운로드";
            const ok = await confirmModal.value.show();
            if(ok){
                downloadLectureAllUser();
            }
        } else if(downloadCount.value >= defaultDownloadCount){ // 100건 이상. 이메일 발송
            modalContent.value = ["[100건 이상]'신청완료'수강 상태의 모든 회원정보와 이력서를 이메일로 발송할까요?"];
            okButtonText.value = "메일발송";
            const ok = await confirmModal.value.show();
            if(ok){
                sendEmailLectureAllUser();
            }
        } else {
            modalContent.value = ["'신청완료'수강 상태의 회원정보가 존재하지 않습니다."];
            modal.value.show();
            return false;
        }
    }

    // 회원정보/이력서 다운로드
    const downloadLectureAllUser = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            lectStateGbList: searchInput1.value.lectStateGbList,
            evalStateGbList: searchInput1.value.evalStateGbList,
            userGbList: searchInput1.value.userGbList,
            searchType: searchInput1.value.searchType,
            searchInput: searchInput1.value.searchInput,
            searchDurationGb: searchInput1.value.searchDurationGb,
            startYmd: searchInput1.value.searchDurationType === 'all' ? "" : searchInput1.value.startYmd,
            endYmd: searchInput1.value.searchDurationType === 'all' ? "" : searchInput1.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }
        $toast('', `다운로드 데이터 생성을 시작합니다.`);
        loadingStore.loading = true;
        lectureService.downloadUserLectureList(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                try {
                    const href = URL.createObjectURL(res.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `${lectDetail.value.lectName}_수강신청자_${moment().format("YYYYMMDDHHmmss")}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } catch(e) {
                    console.error('다운로드 파일 처리 중 오류가 발생했습니다:', e);
                }
            }).catch( (err) =>  {
                loadingStore.loading = false;
                let errorMessage = '다운로드 데이터 생성 중 오류가 발생했습니다.';
                // Blob 응답 처리
                err.response.data.text()  // Blob을 텍스트로 변환
                    .then(text => {
                        try {
                            // 텍스트를 JSON으로 파싱
                            const responseData = JSON.parse(text);

                            // 메시지 추출
                            if (responseData?.result?.message) {
                                errorMessage = responseData.result.message;
                            }
                        } catch (parseError) {
                            console.error('에러 메시지 파싱 중 오류가 발생했습니다.:', parseError);
                        }
                        // Toast에 에러 메시지 표시
                        $toast('error', errorMessage);
                    });
        });
    }

    // 회원정보/이력서 이메일 발송
    const sendEmailLectureAllUser = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            lectStateGbList: searchInput1.value.lectStateGbList,
            evalStateGbList: lectDetail.value.lectGb === '02' ? searchInput1.value.evalStateGbList : undefined,
            userGbList: searchInput1.value.userGbList,
            searchType: searchInput1.value.searchType,
            searchInput: searchInput1.value.searchInput,
            searchDurationGb: searchInput1.value.searchDurationGb,
            startYmd: searchInput1.value.searchDurationType === 'all' ? "" : searchInput1.value.startYmd,
            endYmd: searchInput1.value.searchDurationType === 'all' ? "" : searchInput1.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }
        lectureService.sendEmailUserLectureList(paramObj)
            .then( (res) => {
                // console.log(res);
                $toast('', `이메일 발송 데이터 생성을 시작합니다.`);
            }).catch( (err) =>  {
                //console.log( err );
                let errorMessage = '이메일 발송 중 오류가 발생했습니다.';
                try {
                    const responseData = err.response?.data;
                    if (responseData?.result?.message) {
                        errorMessage = responseData.result.message;
                    }
                } catch (parseError) {
                    console.error('에러 메시지 추출 중 오류가 발생했습니다.:', parseError);
                }
                // Toast에 에러 메시지 표시
                $toast('error', errorMessage);
        });
    }

    // 강의신청 tab - 심사 상태 변경 (심사 결과 셀렉트박스 선택 이벤트)
    const onClickEvalStatus = async (index) => {
        const checkedUsers = _.filter(userLectureList.value, (user)=>{
            return user.checked && user.lectStateGb !== '10' && user.lectStateGb !== '09';
        });
        if(checkedUsers.length === 0) {
            const content = "심사 결과를 입력할 신청인을 선택하세요.";
            modalContent.value = [content];
            modal.value.show();
            return false;
        } else {
            evalStateChangeModal.value.init(checkedUsers, evalStateGbList[index].value);
            const result = await evalStateChangeModal.value.show();
            // console.log(result);
            if(result.length > 0) { // 심사상태 업데이트
                updateEvalStateWithUserIds(result, evalStateGbList[index].value);
            }
        }
    }

    // 강의신청 tab - 합격 메시지 메일/SMS 전송 (합격 메시지 전송 셀렉트박스 선택 이벤트)
    const onClickSendEvalMessage = async (index) => {
        const checkedUsers = _.filter(userLectureList.value, {checked: true});
        if(checkedUsers.length === 0) {
            const content = "합격 메시지를 전송할 신청인을 선택하세요.";
            modalContent.value = [content];
            modal.value.show();
            return false;
        }

        modalContent.value = [`${checkedUsers.length}명에게 ${index === 0 ? '서류':'최종'} 합격 메시지를 전송할까요?`];
        okButtonText.value = "전송";
        const ok = await confirmModal.value.show();
        if(ok) {
            let paramObj = {
                lectId: lectDetail.value.lectId,
                userLectureIds: _.map(checkedUsers, (user)=>{
                    return {
                        regDt: user.regDt, // "2023-04-13T07:54:52.804Z",
                        userId: user.userId,
                        lectId: lectDetail.value.lectId,
                    }
                }),
                evalStateGb: evalMessageList[index].value,
            }
            loadingStore.loading = true;
            lectureService.sendMessageOfEvalStateGb(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        $toast('', "메시지를 전송했습니다.");
                        retrieveAllUserLectureByLectId();
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                $toast('error', res.response.data.result.message);
            });
        }
    }

    // 강의신청 tab - 수강상태 변경(수락/수강 취소 버튼 이벤트)
    const onClickUserSel = async (index) => {
        const checkedUsers = _.filter(userLectureList.value, {checked: true});
        if(checkedUsers.length === 0) {
            const content = "수강 상태를 변경할 신청인을 선택하세요.";
            modalContent.value = [content];
            modal.value.show();
            return false;
        }
        modalContent.value = ["수강 상태를 변경할까요?"];
        okButtonText.value = "변경";
        const ok = await confirmModal.value.show();
        if(ok){
          updateUserSel(checkedUsers, index);
        }
    }

    // 강의신청 tab - 수강상태 변경(수락 버튼 이벤트)
    const updateEvalStateWithUserIds = (checkedUsers, evalStateGb) => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            userLectureIds: _.map(checkedUsers, (user)=>{
                return {
                    regDt: user.regDt, // "2023-04-13T07:54:52.804Z",
                    userId: user.userId,
                    lectId: lectDetail.value.lectId,
                }
            }),
            evalStateGb,
        }
        loadingStore.loading = true;
        lectureService.updateUserLectureEvalStateGbByLectId(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    const msg = '심사 상태를 변경하였습니다.';
                    $toast('', msg);
                    retrieveAllUserLectureByLectId();
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', res.response.data.result.message);
        });
    }

    // 심사 상태 변경
    const updateUserSel = (checkedUsers, index) => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            userLectureIds: _.map(checkedUsers, (user)=>{
                return {
                    regDt: user.regDt, // "2023-04-13T07:54:52.804Z",
                    userId: user.userId,
                    lectId: lectDetail.value.lectId,
                }
            }),
            lectStateGb: lectDetail.value.lectGb === '01' ? onlineLectStateGBList[index].value : offlineLectStateGBList[index].value,
        }
        loadingStore.loading = true;
        lectureService.updateUserLectureStateGbByLectId(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    const msg = '수강 상태를 변경했습니다.';
                    $toast('', msg);
                    retrieveAllUserLectureByLectId();
                    getLectureUserSelCount();
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', res.response.data.result.message);
        });
    }

    // 수강생 tab - 수료 상태 변경(Pass/Fail 셀렉트박스 선택 이벤트)
    const onSelectPassFail = (index) => {
        const checkedUsers = _.filter(userLectureSelList.value, {checked: true});
        if(checkedUsers.length === 0) {
            modalContent.value = ["Pass/Fail 설정할 수강생을 선택하세요."];
            modal.value.show();
            return false;
        }

        let paramObj = {
            lectId: lectDetail.value.lectId,
            userIds: _.map(checkedUsers, 'userId'),
            finYn: index === 0 ? 'Y' : 'N',
        }
        loadingStore.loading = true;
        lectureService.updateUserLectureFinYnByLectId(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    $toast('', `${index === 0 ? 'Pass':'Fail'}로 변경하였습니다.`);
                    retrieveAllUserLectureSelByLectId();
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // 수강생 tab - 메일 쓰기 팝업 열기 (메일 쓰기 버튼 이벤트)
    const onClickMailWrite = async () => {
        const checkedUsers = _.filter(userLectureSelList.value, {checked: true});
        if(checkedUsers.length === 0) {
            modalContent.value = ["메일을 전송할 수강생을 선택하세요."];
            modal.value.show();
            return false;
        }
        // console.log(checkedUsers);
        sendMailModal.value.init(checkedUsers)
        const result = await sendMailModal.value.show();
        if(result && result.userIds) {
            requestMsg('MAIL', result);
        }
    }

    // 수강생 tab - 메시지 쓰기 팝업 열기 (메시지 쓰기 버튼 이벤트)
    const onClickMessageWrite = async () => {
        const checkedUsers = _.filter(userLectureSelList.value, {checked: true});
        if(checkedUsers.length === 0) {
            modalContent.value = ["메시지를 전송할 수강생을 선택하세요."];
            modal.value.show();
            return false;
        }
        // console.log(checkedUsers);
        sendSMSModal.value.init(checkedUsers)
        const result = await sendSMSModal.value.show();
        if(result && result.userIds) {
            requestMsg('SMS', result);
        }
    }

    // 수강생 tab - 강습 독려 메시지(강습독려 안내 3) SMS 전송 (강습 독려 메시지 전송 버튼 이벤트)
    const onClickSendMessage = () => {
        const checkedUsers = _.filter(userLectureSelList.value, {checked: true});
        if(checkedUsers.length === 0) {
            modalContent.value = ["메시지를 전송할 수강생을 선택하세요."];
            modal.value.show();
            return false;
        } else {
            let paramObj = {
                userSelGb: 'sel',
                userIds: _.map(checkedUsers, 'userId'),
            }
            requestMsg('ENCOURAGE', paramObj);
        }
    }

    // s : 과제

    // 과제 tab - 과제요약정보 조회
    const retrieveHomeworkSummary = () => {
        loadingStore.loading = true;
        lectureHomeworkService.retrieveHomeworkSummary(lectDetail.value.lectId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    homeworkSummaryList.value = result;
                }
                if( homeworkSummaryList.length !== 0 ) {
                    retrieveAllHomeworkPaging();
                }
            }).catch( (error) =>  {
            console.log(error);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // 과제 tab - 과제 제출 목록 조회
    const retrieveAllHomeworkPaging = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            searchType: searchInput4.value.searchType,
            searchInput: searchInput4.value.searchInput,
            searchDurationGb: searchInput4.value.searchDurationGb,
            startYmd: searchInput4.value.searchDurationType === 'all' ? "" : searchInput4.value.startYmd,
            endYmd: searchInput4.value.searchDurationType === 'all' ? "" : searchInput4.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        lectureHomeworkService.retrieveAllHomeworkPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    userHomeworkList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                }
            }).catch( (error) =>  {
            console.log(error);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // e : 과제

    // 메일/SMS 전송 처리
    function requestMsg(msgGbName, paramObj) {
        if(msgGbName === 'MAIL') {
            loadingStore.loading = true;
            mailMsgServcie.requestSendEmail(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', '메일 전송 배치에 등록되었습니다.');
                    } else {
                    }
                }).catch( (error) =>  {
                console.log(error);
                loadingStore.loading = false;
            });
        } else if(msgGbName === 'SMS') {
            loadingStore.loading = true;
            mailMsgServcie.requestSendSMS(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', '메시지 전송 배치에 등록되었습니다.');
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        } else if(msgGbName === 'ENCOURAGE'){
            loadingStore.loading = true;
            paramObj.lectId = lectDetail.value.lectId;
            mailMsgServcie.requestSendSMSEncourage(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', '메시지 전송 배치에 등록되었습니다.');
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    // 회원관리 페이지 새창으로 열기
    const onClickUserId = (user) => {
        window.open(`/admin/member/detail/${user.userId}`, '_blank');

    }

    // 강의 신청 tab - 수강신청 시점 이력서 팝업 열기
    const onClickResumeDetail = (userId, lectId, regDt) => {
        resumeModal.value.retrieveUserLectureRecordDetail(userId, lectId, regDt);
        resumeModal.value.show();
    }

    // 수강생 tab - 학습현황 팝업 열기
    const onClickLearningStatus = (userId, lectId, regDt) => {
        lectureLearningStatusModal.value.retrieveLectureLearningStatus(userId, lectId, regDt);
        lectureLearningStatusModal.value.retrieveUserLectureAllIndex(lectId, userId);
        lectureLearningStatusModal.value.show();
    }

    // 목록 버튼
    const goBack = () => {
        router.push({name:'LectureCourseList'})
    }

    // 강의 상세 새 창 보기 버튼
    const goLectureDetail = () => {
        window.open(`/admin/lecture/manage/detail/${lectDetail.value.lectId}`, '_blank');
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3><span v-if="lectDetail.repreLectYn === 'Y'" class="badge">대표</span>{{ lectDetail.lectName }}</h3>
                </div>
                <div class="list-top end">
                    <div>
                        <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                        <KSASecondaryButton class="gray_button ml-xs" text="강의 상세 새 창 보기" @click="goLectureDetail" />
                    </div>
                </div>
                <KSATab style="grid-template-columns: repeat(4, 1fr);" class="mb-lg"
                        :options="[
                            {label:`강의 신청${lectDetail.userLectureCount > 0 ? ('(' + lectDetail.userLectureCount + ')') : ''}`, value:0},
                            {label:`수강생${lectDetail.userLectureSelCount > 0 ? ('(' + lectDetail.userLectureSelCount + ')') : ''}`, value:1},
                            {label:'TEST', value:2},
                            {label:'과제', value:3},
                        ]"
                        @change="onSelectTab"
                />
                <!-- s: 강의 신청 -->
                <div v-show="tabIndex === 0">
                    <div class="filter-wrap" :class="{'open': searchOpen1}">
                        <p @click="searchOpen1 = !searchOpen1">검색</p>
                        <div class="filter">
                            <dl>
                                <dt>수강 상태</dt>
                                <dd v-if="lectDetail.lectGb === '01'">
                                    <KSACheckBoxGroup
                                        ref="searchLectStateGbRef1"
                                        className="mr-md"
                                        name="lectStateGbList"
                                        v-model="searchInput1.lectStateGbList"
                                        :options="onlineLectStateGBList" />
                                </dd>
                                <dd v-else>
                                    <KSACheckBoxGroup
                                        ref="searchLectStateGbRef1"
                                        className="mr-md"
                                        name="lectStateGbList"
                                        v-model="searchInput1.lectStateGbList"
                                        :options="lectStateGb2" />
                                </dd>
                            </dl>
                            <dl v-if="lectDetail.lectGb === '02'">
                                <dt>심사 상태</dt>
                                <dd>
                                    <KSACheckBoxGroup
                                        ref="searchEvalStateGbRef1"
                                        className="mr-md"
                                        name="evalStateGbList"
                                        v-model="searchInput1.evalStateGbList"
                                        :options="evalStateGb2" />
                                </dd>
                            </dl>
                            <dl>
                                <dt>유형</dt>
                                <dd>
                                    <KSACheckBoxGroup
                                        ref="searchUserGbRef1"
                                        className="mr-md"
                                        name="userGbList"
                                        v-model="searchInput1.userGbList"
                                        :options="userGbGroup" />
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <KSASelect ref="searchTypeRef1" :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
                                </dt>
                                <dd>
                                    <KSAInput class="text-box" v-model="searchInput1.searchInput" :placeholder="searchInputPlaceholder" />
                                </dd>
                            </dl>
                            <dl>
                                <dt>수강 신청일</dt>
                                <dd class="text-box">
                                    <div class="grid">
                                        <template v-for="group in searchDurationTypeGroup">
                                            <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="searchInput1.searchDurationType" :value="group.value" />
                                        </template>
                                        <div v-show="searchInput1.searchDurationType !== 'all'" style="display: inline-block;">
                                            <KSADatepickerTerms
                                                :startDate="searchInput1.startYmd"
                                                :endDate="searchInput1.endYmd"
                                                @update:startDate="(value)=> searchInput1.startYmd = value"
                                                @update:endDate="(value)=> searchInput1.endYmd = value"
                                            />
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                            <div class="btn-wrap">
                                <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                <KSAPrimaryButton  text="검색" @click="onClickSearch" />
                            </div>
                        </div>
                    </div>
                    <div class="list-top">
                        <div class="grid">
                            <KSAPrimaryButton text="회원정보/이력서 전체 다운로드" @click="onClickAllUserDownload" />
                            <KSADropdownButton v-if="lectDetail.lectGb === '02'" class="gray_button ml-xs" text="심사 결과" :options="evalStateGbList" @click="onClickEvalStatus" />
                            <KSADropdownButton v-if="lectDetail.lectGb === '02'" class="gray_button ml-xs" text="합격 메시지 전송" :options="evalMessageList" @click="onClickSendEvalMessage" />
                            <KSADropdownButton v-if="lectDetail.lectGb === '01'" class="gray_button ml-xs" text="수강 상태 변경" :options="onlineLectStateGBList" @click="onClickUserSel" />
                            <KSADropdownButton v-else-if="lectDetail.lectGb === '02'" class="gray_button ml-xs" text="수강 상태 변경" :options="offlineLectStateGBList" @click="onClickUserSel" />
                        </div>
                        <div class="grid">
                            <KSASelect ref="searchOrderByRef1" :options="orderByList" class="select" @selectOption="onSelectOrderBy"/>
                            <KSASelect ref="searchPageSizeRef1" :options="pageSizeGroup" class="select ml-xs" @selectOption="onSelectPageSize"/>
                        </div>
                    </div>
                    <div class="table-form">
                        <table class="horizontal">
                            <tr>
                                <th><KSACheckBox v-model="isSelectedAll" /></th>
                                <th>수강 상태</th>
                                <th v-if="lectDetail.lectGb === '02'">심사 상태</th>
                                <th>유형</th>
                                <th>회원코드</th>
                                <th>이름</th>
                                <th>아이디</th>
                                <th>수강 신청일</th>
                                <th v-if="lectDetail.lectGb === '01'">교육 기간</th>
                                <th>수강 취소일</th>
                                <th>이력서</th>
                            </tr>
                            <tr v-for="user in userLectureList">
                                <td><KSACheckBox v-model="user.checked" :disabled="(user.lectStateGb === '10'||user.lectStateGb === '09')"/></td>
                                <td>{{ $gbNameKR('LECT_STATE_GB2', user.lectStateGb) }}</td>
                                <td v-if="lectDetail.lectGb === '02'">{{ $gbNameKR('EVAL_STATE_GB2', user.evalStateGb) }}</td>
                                <td>{{ $userGbKR(user.userGb)}}</td>
                                <td>{{ user.userCd }}</td>
                                <td><KSATextButton :text="user.userName" @click="onClickUserId(user)" /></td>
                                <td>{{ user.userLoginId }}</td>
                                <td>{{ $yyyymmddHHmmss(user.regDt) }}</td>
                                <td v-if="user.lectGb === '01'">
                                    <span v-if="(user.lectStateGb === '06' || user.lectStateGb === '07' || user.lectStateGb === '08')">{{ $yyyymmdd(user.lectStartYmd) }} ~ {{ $yyyymmdd(user.lectEndYmd) }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span v-if="(user.lectStateGb === '10' || user.lectStateGb === '09')">{{ $yyyymmddHHmmss(user.cancelDt) }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <KSATextButton v-if="user.userGb === 'STUDENT'" text="이력서" @click="onClickResumeDetail(user.userId, user.lectId, user.regDt)" />
                                    <span v-else>-</span>
                                </td>
                            </tr>
                            <tr v-if="userLectureList.length === 0">
                                <td :colspan="lectDetail.lectGb === '02' ? 10 : 9">강의를 신청한 학생이 없습니다.</td>
                            </tr>
                        </table>
                        <Paginate
                            v-if="userLectureList.length > 0"
                            v-model="currentPage"
                            :page-count="totalPages"
                            :click-handler="onSelectPage"
                        />
                    </div>
                </div>
                <!-- e: 강의 신청 -->

                <!-- s: 수강생-->
                <div v-show="tabIndex === 1">
                    <div class="filter-wrap" :class="{'open': searchOpen2}">
                        <p @click="searchOpen2 = !searchOpen2">검색</p>
                        <div class="filter">
                            <dl>
                                <dt>유형</dt>
                                <dd>
                                    <KSACheckBoxGroup
                                        ref="searchUserGbRef2"
                                        className="mr-md"
                                        name="userGbList"
                                        v-model="searchInput2.userGbList"
                                        :options="userGbGroup" />
                                </dd>
                            </dl>
                            <dl>
                                <dt>진도율</dt>
                                <dd>
                                    <template v-for="group in prograteGroup">
                                        <KSARadioButton class="mr-md" name="prograte" :label="group.label" v-model="searchInput2.progRate" :value="group.value" />
                                    </template>
                                </dd>
                            </dl>
                            <dl>
                                <dt>Pass/Fail</dt>
                                <dd>
                                    <KSACheckBoxGroup
                                        ref="searchFinYnRef2"
                                        className="mr-md"
                                        name="finYnList"
                                        v-model="searchInput2.finYnList"
                                        :options="finYnGroup" />
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <KSASelect ref="searchTypeRef2" :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
                                </dt>
                                <dd>
                                    <KSAInput class="text-box" v-model="searchInput2.searchInput" :placeholder="searchInputPlaceholder2" />
                                </dd>
                            </dl>
                            <dl>
                                <dt>수강 신청일</dt>
                                <dd class="text-box">
                                    <div class="grid">
                                        <template v-for="group in searchDurationTypeGroup">
                                            <KSARadioButton class="mr-md" name="searchDurationGb2" :label="group.label" v-model="searchInput2.searchDurationType" :value="group.value" />
                                        </template>
                                        <div v-show="searchInput2.searchDurationType !== 'all'" style="display: inline-block;">
                                            <KSADatepickerTerms
                                                :startDate="searchInput2.startYmd"
                                                :endDate="searchInput2.endYmd"
                                                @update:startDate="(value)=> searchInput2.startYmd = value"
                                                @update:endDate="(value)=> searchInput2.endYmd = value"
                                            />
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                            <div class="btn-wrap">
                                <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                <KSAPrimaryButton text="검색" @click="onClickSearch" />
                            </div>
                        </div>
                    </div>
                    <div class="list-top">
                        <div class="grid">
                            <KSASecondaryButton class="gray_button" text="메일 쓰기" @click="onClickMailWrite" />
                            <KSASecondaryButton class="gray_button ml-xs" text="메시지 쓰기" @click="onClickMessageWrite" />
                            <KSASecondaryButton class="gray_button ml-xs" text="강습 독려 메시지 전송" @click="onClickSendMessage" />
                            <KSADropdownButton class="gray_button ml-xs" text="Pass/Fail" :options="['Pass', 'Fail']" @click="onSelectPassFail" />
                        </div>
                        <div class="grid">
                            <KSASelect ref="searchOrderByRef2" :options="orderByList" class="select" @selectOption="onSelectOrderBy"/>
                            <KSASelect ref="searchPageSizeRef2" :options="pageSizeGroup" class="select ml-xs" @selectOption="onSelectPageSize"/>
                        </div>
                    </div>
                    <div class="table-form">
                        <table class="horizontal">
                            <tr>
                                <th><KSACheckBox v-model="isSelectedAll2" /></th>
                                <th>유형</th>
                                <th>회원코드</th>
                                <th>이름</th>
                                <th>아이디</th>
                                <th>수강 신청일</th>
                                <th>교육 기간</th>
                                <th>진도율</th>
                                <th>Pass/Fail</th>
                                <th>학습 현황</th>
                            </tr>
                            <tr v-for="user in userLectureSelList">
                                <td><KSACheckBox v-model="user.checked" /></td>
                                <td>{{ $userGbKR(user.userGb)}}</td>
                                <td>{{ user.userId }}</td>
                                <td><KSATextButton :text="user.userName" @click="onClickUserId(user)" /></td>
                                <td>{{ user.userLoginId }}</td>
                                <td>{{ $yyyymmddHHmmss(user.regDt) }}</td>
                                <td>{{ $yyyymmdd(user.lectStartYmd) }} ~ {{ $yyyymmdd(user.lectEndYmd) }}</td>
                                <td>{{ user.lectureIndexFinishCount }}/{{ user.lectureIndexCount }}({{ user.progRate }}%)</td>
                                <td>
                                    <span v-if="user.finYn">{{ user.finYn === 'Y' ? 'Pass'
                                                             : user.finYn === 'N' ? 'Fail'
                                                             : user.finYn === 'D' ? '평가전'
                                                             : '-'}}</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <KSATextButton text="학습 현황" @click="onClickLearningStatus(user.userId, user.lectId, user.regDt)"/>
                                </td>
                            </tr>
                            <tr v-if="userLectureSelList.length === 0">
                                <td colspan="9">수강중인 학생이 없습니다.</td>
                            </tr>
                        </table>
                        <Paginate
                            v-if="userLectureSelList.length > 0"
                            v-model="currentPage"
                            :page-count="totalPages"
                            :click-handler="onSelectPage"
                        />
                    </div>
                </div>
                <!-- e: 수강생 -->

                <!-- s : TEST -->
                <div v-show="tabIndex === 2">
                    <div class="result-none">
                        준비중입니다.
                    </div>
                </div>
                <!-- e: TEST -->

                <!-- s : 과제 -->
                <div v-show="tabIndex === 3">
                    <div v-if="homeworkSummaryList.length !== 0">
                        <div class="table-form mb-lg">
                            <table class="horizontal">
                                <tr>
                                    <th>제목</th>
                                    <th>전체 대상자</th>
                                    <th>제출자</th>
                                    <th>제출율</th>
                                    <th>평균점수</th>
                                </tr>
                                <tr v-for="summary in homeworkSummaryList">
                                    <td>{{ summary.title }}</td>
                                    <td>{{ summary.totalStudent }}명</td>
                                    <td>{{ summary.submitCount }}명</td>
                                    <td>{{ summary.submitCount / summary.totalStudent * 100 }}%</td>
                                    <td>
                                        <span v-if="summary.avgScore">
                                            {{ summary.avgScore }}점
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="filter-wrap" :class="{'open': searchOpen4}">
                            <p @click="searchOpen4 = !searchOpen4">검색</p>
                            <div class="filter">
                                <dl>
                                    <dt>
                                        <KSASelect :options="searchTypeGroup4" class="select" @selectOption="onSelectSearchTypeGroup"/>
                                    </dt>
                                    <dd>
                                        <KSAInput class="text-box" v-model="searchInput4.searchInput" :placeholder="searchInputPlaceholder4" />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <KSASelect :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                                    </dt>
                                    <dd class="text-box">
                                        <div class="grid">
                                            <template v-for="group in searchDurationTypeGroup">
                                                <KSARadioButton class="mr-md" name="searchDurationGb4" :label="group.label" v-model="searchInput4.searchDurationType" :value="group.value" />
                                            </template>
                                            <div v-show="searchInput4.searchDurationType !== 'all'" style="display: inline-block;">
                                                <KSADatepickerTerms
                                                    :startDate="searchInput4.startYmd"
                                                    :endDate="searchInput4.endYmd"
                                                    @update:startDate="(value)=> searchInput4.startYmd = value"
                                                    @update:endDate="(value)=> searchInput4.endYmd = value"
                                                />
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <div class="btn-wrap">
                                    <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                    <KSAPrimaryButton text="검색" @click="onClickSearch" />
                                </div>
                            </div>
                        </div>
                        <div class="list-top">
                            <p>총 <span>{{ $number(totalCount)}}</span>명</p>
                            <div>
                                <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                            </div>
                        </div>
                        <div class="table-form">
                            <table class="horizontal">
                                <tr>
                                    <th>No</th>
                                    <th>채점 상태</th>
                                    <th>제목</th>
                                    <th>제출자명</th>
                                    <th>제출자 아아디</th>
                                    <th>점수</th>
                                    <th>제출일시</th>
                                    <th>제출과제</th>
                                    <th>채점자명</th>
                                    <th>채점일시</th>
                                    <th>채점자 IP</th>
                                </tr>
                                <tr v-for="homework in userHomeworkList">
                                    <td>{{ homework.boardNo }}</td>
                                    <td>
                                        <span v-if="homework.markDt">채점 완료</span>
                                        <span v-else>제출 완료</span>
                                    </td>
                                    <td>{{ homework.title }}</td>
                                    <td>{{ homework.userName }}</td>
                                    <td>{{ homework.userLoginId }}</td>
                                    <td>
                                        <span v-if="homework.score">
                                            {{ homework.score }}점
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>{{ $yyyymmddHHmm(homework.submitDt) }}</td>
                                    <td><KSATextButton v-if="homework.fileName" class="ml-xs" :text=homework.fileName @click="$download(homework.fileDir)" /></td>
                                    <td>
                                        <span v-if="homework.markUserName">
                                            {{ homework.markUserName }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>
                                        <span v-if="homework.markDt">
                                            {{ $yyyymmddHHmm(homework.markDt) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>
                                        <span v-if="homework.markIp">
                                            {{ homework.markIp }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr v-if="userHomeworkList.length === 0">
                                    <td colspan="9">제출된 과제가 없습니다.</td>
                                </tr>
                            </table>
                            <Paginate
                                v-if="userHomeworkList.length > 0"
                                v-model="currentPage"
                                :page-count="totalPages"
                                :click-handler="onSelectPage"
                            />
                        </div>
                    </div>
                    <div class="result-none" v-if="homeworkSummaryList.length === 0">
                        등록된 과제가 없습니다.
                    </div>
                </div>
                <!-- e: 과제 -->
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
    <LectureSendMailModal ref="sendMailModal"/>
    <LectureSendSMSModal ref="sendSMSModal"/>
    <MyPageResumeModal ref="resumeModal"/>
    <LectureEvalStateChangeModal ref="evalStateChangeModal"/>
    <LectureLearningStatusModal ref="lectureLearningStatusModal"/>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .textarea-box {
        height: 100px;
    }
</style>