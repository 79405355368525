<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>History</h3>
            </div>
            <div class="history-wrap">
                <div class="img">
                    <img src="/src/assets/images/history_bg.png" />
                </div>
                <div class="txt">
                    <dl>
                        <dt>2024년</dt>
                        <dd>
                            <p><span>1월</span> 반도체아카데미 2024 신규 교육 프로그램 운영 시작</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>2023년</dt>
                        <dd>
                            <p><span>9월</span> 심화과정 수료식 개최 및 장비社 연계 취업 프로그램 운영</p>
                            <p><span>6월</span> 심화과정 3개 교육(파운드리향 Design, 장비설계, 장비제어) 개강 및 입교식 개최</p>
                            <p><span>4월</span> 한국반도체아카데미 교육 프로그램 운영 시작</p>
                            <p><span>2월</span> 설계/공정/PnT/소부장 분과위 구성 및 운영</p>
                            <p><span>1월</span> 산업계 수요 기반의 반도체 인재양성 강화 워크숍</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>2022년</dt>
                        <dd>
                            <p><span>12월</span> 한국반도체아카데미 출범식</p>
                            <p><span>7월</span> 반도체 초강대국 달성 전략 발표 – 산학협력 4대 人프라</p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .history-wrap {
        display: flex;
        dl {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: start;
            padding: 0 0 40px 50px;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 20px;
                width: 1px;
                height: 100%;
                background: $gray-regular_color;
            }
            &:last-child {
                padding-bottom: 0;
                &:before {
                    height: 18px;
                }
            }
            dt {
                position: relative;
                display: flex;
                margin-right: 40px;
                @include font-size_xl;
                font-weight: 700;
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -32px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: $blue_color;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
            dd {
                p {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        width: 30px;
                        margin-right: 20px;
                        display: inline-block;
                    }
                }
            }
        }
    }
</style>
<script setup>
</script>