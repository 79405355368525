<script setup>
    import { defineProps, defineEmits } from 'vue'
    import _ from "lodash";
    const { text, color, disabled } = defineProps(['text', 'color', 'disabled'])

    const emit = defineEmits(['click'])

    const sendEmit = () => {
        emit('click')
    }
    const onClick = _.debounce(sendEmit, 100);
</script>

<template>
    <button @click="onClick" :disabled="disabled" :style="color && {'border-color': color}">{{ text }}</button>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    button {
        height: 48px;
        background: #fff;
        color: $blue_color;
        border: 1px solid $blue_color;
        cursor:pointer;
        border-radius: 4px;
        @include font-size_sm;
        padding: 10px 20px;
        &.sm_btn {
            height: 30px;
            padding: 1px 10px;
        }
      &.md_btn{
            height: 38px;
            padding: 4px 10px 5px 10px;
            margin-right:4px;
            margin-left:4px;
      }
        &.gray_button {
            color: $black_color;
            border: $border_line;
        }
        &.red_button {
          color: $red_color;
          border-color: $red_color;
        }
        &.green_button {
          color: $green_color;
          border-color: $green_color;
        }
        &.orange_button {
          color: $orange_color;
          border-color: $orange_color;
        }
        &.blue_button {
          color: $blue_color;
          border-color: $blue_color;
        }
      &:disabled {
            cursor: default;
            background: $gray-light_color;
            color: $gray-regular_color;
            border: 0;
        }
    }
</style>