import http from '../../../../api/http'
import { useUserStore } from "../../../user/stores/user";

function retrieveAllMsgHistoryList(paramObj) {
    return http.post('/message/msg/retrieveAllMsgHistoryPaging', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 30,
        msgGb: paramObj.msgGb,
        tempateTypeList: paramObj.tempateTypeList, // ["string"],
        sendStatus: paramObj.sendStatus, // "string",
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string",
        searchDurationGb: paramObj.searchDurationGb || 'S', // S 전송일
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string"
    })
}

function downloadMsgHistoryList(paramObj) { // 엑셀 다운로드
    return http.post('/message/msg/downloadMsgHistoryList', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 30,
        msgGb: paramObj.msgGb,
        tempateTypeList: paramObj.tempateTypeList, // ["string"],
        sendStatus: paramObj.sendStatus, // "string",
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string",
        searchDurationGb: paramObj.searchDurationGb || 'S', // S 전송일
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string"
    })
}


function requestAllMsgReSend(paramObj) { // 전체 재전송
    return http.post('/message/msg/requestMsgHistoryForReSend', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 30,
        msgGb: paramObj.msgGb,
        tempateTypeList: paramObj.tempateTypeList, // ["string"],
        sendStatus: paramObj.sendStatus, // "string",
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string",
        searchDurationGb: paramObj.searchDurationGb || 'S', // S 전송일
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string"
    })
}

function requestSendEmail(paramObj) {
    return http.post('/message/msg/sendEmailRequest', {
        // templateId: 0,
        // lectId: 0,
        msgGb: paramObj.msgGb || '01',
        userSelGb: paramObj.userSelGb, // all, sel,
        userIds: paramObj.userIds, // [0],
        title: paramObj.title, // "string",
        content: paramObj.content, // "string",
        // createId: 0
    })
}

function requestSendSMS(paramObj) {
    return http.post('/message/msg/sendSMSRequest', {
        // templateId: 0,
        // lectId: 0,
        msgGb: paramObj.msgGb || '02',
        userSelGb: paramObj.userSelGb, // all, sel,
        userIds: paramObj.userIds, // [0],
        title: paramObj.title, // "string",
        content: paramObj.content, // "string",
        // createId: 0
    })
}

function requestSendSMSEncourage(paramObj) {
    return http.post('/message/msg/sendSMSEncourageRequest', {
        // templateId: 0,
        lectId: paramObj.lectId,
        msgGb: paramObj.msgGb || '02',
        userSelGb: paramObj.userSelGb, // all, sel,
        userIds: paramObj.userIds, // [0],
        title: paramObj.title, // "string",
        content: paramObj.content, // "string",
        // createId: 0
    })
}

function requestSendEmailNewsletter(newsletterId) {
    return http.post('/message/msg/sendEmailNewsLetterRequest', {
        lectId: newsletterId,
        userSelGb: 'all',
    })
}


function requestMsgHistoryIdForReSend(paramObj) {
    const { user } = useUserStore()
    return http.post('/message/msg/requestMsgHistoryIdForReSend', {
        date: paramObj.date, // "2023-03-29T06:55:38.908Z",
        userId: paramObj.userId, // 0,
        sendUserId: user.userId, // 0
    })
}


export default {
    retrieveAllMsgHistoryList, // 전송이력 목록 조회
    downloadMsgHistoryList, // 엑셀 다운로드
    requestAllMsgReSend, // 전체 재전송
    requestSendEmail, // 메일 쓰기
    requestSendSMS, // 메시지 쓰기
    requestSendSMSEncourage, // 강습 독력 메시지 전송
    requestSendEmailNewsletter, // 뉴스레터 전송
    requestMsgHistoryIdForReSend, // 메시지 단건 재전송
}