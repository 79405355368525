<script setup>
    import { ref, computed, getCurrentInstance } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import MailSMSHistoryCreateContactsModal from '../../components/modal/mail/MailSMSHistoryCreateContactsModal.vue'
    import mailMsgServcie from "../../services/mail/mailMsgServcie";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import * as XLSX from 'xlsx';

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const {$toast} = getCurrentInstance().appContext.config.globalProperties;

    const msgGb = "02";
    let userIdList = [];
    const input = ref({
        names: '', // 받는사람 텍스트
        content: '',
    })
    const inputError = ref({
        content: '',
    })

    const modal = ref(null)
    const mailSMSHistoryCreateContactsModal = ref(null)
    const modalContent = ref([])

    const onClickReceiver = async () => {
        const { names, userIds} = await mailSMSHistoryCreateContactsModal.value.show();
        if(names) {
            input.value.names = names;
        }
        if(userIds) {
            userIdList = userIds;
        }
        inputError.value.names = '';

    }

    const uploadFile = () => {
      const fileInput = document.createElement('input');
      fileInput.type= 'file';
      fileInput.click();

      fileInput.addEventListener('change', function(event){
        const file = event.target.files[0];

        input.value.names = file.name; // 파일명 input에 넣기

        const reader = new FileReader();
        reader.onload = () => {
          const data = reader.result;
          try {
            const workBook = XLSX.read(data, { type: 'binary' });
            workBook.SheetNames.forEach(sheetName => {
              const rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], {header: 1, defval: ''});

              let rowsList = [];

              if (rows.length > 1) {
                rows.shift();
                rows.forEach((row) => {
                  if (row[0] !== '') {
                    console.log("===========" + row[0]);
                    rowsList.push(row[0]);
                  }
                })

                userIdList = rowsList;
              } else {
                modalContent.value = ["엑셀의 내용을 확인하십시오."];
                modal.value.show();
              }

              return true;
            })
          } catch (error) {
            console.log(error);
            modalContent.value = ["엑셀의 내용을 확인하십시오."];
            modal.value.show();
          }
        };
        reader.readAsBinaryString(file);
      });
    }

    const sendEmail = () => {
        const { content } = input.value;
        let isInvalid = false;

        inputError.value = {
            content: '',
        };

        if(userIdList.length === 0) {
            modalContent.value = ["받는 사람을 선택하세요."];
            modal.value.show()
            return false;
        }
        // if(!content) {
        //     inputError.value.content = '내용을 입력하세요.';
        //     isInvalid = true;
        // }

        if(!isInvalid) {
            let paramObj = {
                msgGb,
                userSelGb: 'sel',
                userIds: userIdList,
                content: content || '내용 없음',
            }

            loadingStore.loading = true;
            mailMsgServcie.requestSendSMS(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', '메시지 전송 배치에 등록되었습니다.');
                        goListView()
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    const goListView = () => {
        router.push({name: 'MailSMSHistory'})
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content mail">
                <div class="sub-title">
                    <h3>메시지 쓰기</h3>
                </div>
                <div class="content-inner">
                    <div class="table-form">
                        <table class="vertical">
                            <colgroup>
                                <col width="200px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th>받는 사람</th>
                                <td>
                                    <KSAInput v-model="input.names" :msg="inputError.names" disabled />
                                    <KSATextButton class="mt-xs" text="받는 사람 선택" @click="onClickReceiver" />
                                    <KSATextButton class="mt-xs ml-xs" text="엑셀 업로드" @click="uploadFile" />
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <KSATextArea class="textarea-box" v-model="input.content" placeholder="내용을 입력하세요." max-length="2000"/>
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.content }}</small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="취소" @click="goListView" />
                    <KSAPrimaryButton text="보내기" @click="sendEmail" />
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <MailSMSHistoryCreateContactsModal ref="mailSMSHistoryCreateContactsModal" />
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/theme";

</style>
