<script setup>
    import { ref, computed, defineEmits, defineExpose, onMounted } from "vue";
    import http from '../../../api/http'
    import signupService from "../../../pages/user/services/signup/signupService";

    const props = defineProps({
        modelValue: { type: String },
        label: {
            type: String,
            default: '사업자등록번호',
        },
        placeholder: {
            type: String,
            default: "'-' 입력 제외",
        },
        msg: {
            type: String,
            default: '',
        },
        msgColor: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    });
    const emit = defineEmits(["update:modelValue", "update:corp", 'blur']);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const inputRef = ref(null)

    const getPhoneMask = () => {
        let res = getMask()
        emit("update:modelValue", res);
    }

    const getMask = ( ) => {
        let str = props.modelValue
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';
        if(str.length < 4){
            return str;
        }
        else if(str.length < 6){
            tmp += str.substr(0,3);
            tmp += '-';
            tmp += str.substr(3,2);
            return tmp;
        }
        else if(str.length < 11){
            tmp += str.substr(0,3);
            tmp += '-';
            tmp += str.substr(3,2);
            tmp += '-';
            tmp += str.substr(5);
            return tmp;
        }
        else{
            tmp += str.substr(0,3);
            tmp += '-';
            tmp += str.substr(3,2);
            tmp += '-';
            tmp += str.substr(5);
            return tmp;
        }
    }

    const onBlur = () => {
        if(inputRef.value.value) {
            signupService.searchCorp(inputRef.value.value.replace('-','').replace('-',''))
                .then( (res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        if(result.length > 0){
                            emit("update:corp", result[0]);
                        } else {
                            emit("update:corp", {
                                corpId: 0,
                                corpName: '검색 결과가 없습니다.',
                            });
                        }
                    }
                }).catch( (error) =>  {
                console.log(error);
            });
        }
        emit('blur');
    }


    const focus = () => {
        inputRef.value.focus();
    }

    // Watch for changes in the modelValue and trigger onBlur when it changes
    onMounted(() => {
      if (props.modelValue) {
        onBlur();
      }
    });

    defineExpose({
        focus
    })
</script>

<template>
    <div>
        <label>
            <p v-if="label">{{ label }}</p>
            <input ref="inputRef" type="text" v-model="model" @input="getPhoneMask" @blur="onBlur" :placeholder="placeholder" :maxlength="12" :disabled="disabled">
        </label>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/ksa_input";

    label {
        p {
            @include font-size_xs;
            color: $gray_color;
        }
    }
    input {
        &:disabled {
            color: $black_color;
            background: $gray-thin_color;
        }
    }
</style>