<script setup>
    import { ref, computed, defineEmits } from "vue";
    import http from '../../../api/http'
    import signupService from "../../../pages/user/services/signup/signupService";

    const props = defineProps({
        modelValue: { type: String },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: "",
        },
        msg: {
            type: String,
            default: '',
        },
        msgColor: {
            type: String,
            default: ''
        },
        maxLength: { type: String },
        disabled: {
            type: Boolean,
            default: false
        },
    });
    const emit = defineEmits(["update:modelValue"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const inputRef = ref(null)

    const onInput = () => {
        let res = getStr()
        emit("update:modelValue", res);
    }

    const getStr = ( ) => {
        let str = props.modelValue
        str = str.replace(/[^0-9]/g, '');
        return str;
    }
</script>

<template>
    <div>
        <label>
            <p v-if="label">{{ label }}</p>
            <input ref="inputRef" type="text" v-model="model" @input="onInput" :placeholder="placeholder" :maxlength="maxLength" :disabled="disabled">
        </label>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/ksa_input";

    label {
        p {
            @include font-size_xs;
            color: $gray_color;
        }
    }
    input {
        &:disabled {
            color: $black_color;
            background: $gray-thin_color;
        }
    }
</style>