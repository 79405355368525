import http from '../../../../api/http';

function retrieveAllPaging(paramObj) {
    return http.post('/insights/newsletter/retrieveAllPaging', {
        pageNumber: paramObj.pageNo,
        size: paramObj.size,
        searchType: paramObj.searchType,
        searchInput: paramObj.searchInput,
    })
}

function retrieveNewsletterDetail(newsletterId) {
    return http.post('/insights/newsletter/retrieve', {
        newsletterId
    });
}

function createNewsletter(paramObj) {
    return http.post('/insights/newsletter/create', {
        newsletterId: paramObj.newsletterId, // 0,
        title: paramObj.title, // "string",
        fileName: paramObj.fileName,
        fileSize: paramObj.fileSize,
        fileDir: paramObj.fileDir,
        hitCount: paramObj.hitCount, // 0,
        content: paramObj.content, // "string",
        createId: paramObj.createId, // 0
    });
}

function updateNewsletter(paramObj) {
    return http.post('/insights/newsletter/update', {
        newsletterId: paramObj.newsletterId, // 0,
        title: paramObj.title, // "string",
        fileName: paramObj.fileName,
        fileSize: paramObj.fileSize,
        fileDir: paramObj.fileDir,
        hitCount: paramObj.hitCount, // 0,
        content: paramObj.content, // "string",
        createId: paramObj.createId, // 0
    });
}

function deleteNewsletter(newsletterId) {
    return http.post('/insights/newsletter/delete', {
        newsletterId
    });
}

export default {
    retrieveAllPaging,
    retrieveNewsletterDetail,
    createNewsletter,
    updateNewsletter,
    deleteNewsletter,
};