import http from '../api/http'
import { useSasTokenStore } from "../stores/sasTokenStore";
import {useUserStore} from "../pages/user/stores/user";

function uploadFile(paramObj) {
    if(!paramObj.folder || !paramObj.file) {
        return false;
    }

    let formData = new FormData();
    formData.append('file', paramObj.file)

    let container = paramObj.container || 'public';
    let folder = paramObj.folder;

    return http.post(`resource/file/azure/upload?container=${container}&folder=${folder}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
}

function uploadSecurePDF(file) {
    if(!file) {
        return false;
    }

    let formData = new FormData();
    formData.append('file', file)

    let container = 'private';
    let folder = 'securePDF';

    return http.post(`resource/file/azure/uploadSecurePDF?container=${container}&folder=${folder}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
}

function downloadFile(paramObj) {
    if(!paramObj.keyName  || !paramObj.originalFilename ) {
        return false;
    }

    let keyName = paramObj.keyName;
    let originalFilename = paramObj.originalFilename;

    return http.get(`resource/file/azure/download?keyName=${encodeURI(keyName)}&originalFilename=${originalFilename}`,{
        responseType: 'blob'
    });
}


function securePdfDownload(page) {
    const pdfviewerInStorage = sessionStorage.getItem('pdfviewer');
    const { keyName, lectId } = JSON.parse(pdfviewerInStorage);
    if(!keyName || !lectId) {
        return false;
    }

    const { user, isAdmin } = useUserStore();
    const isTeacher = user.userGb === 'TEACHER';
    return http.get(`resource/file/azure/${(isAdmin || isTeacher) ? 'securePdfDownloadForAdmin' : 'securePdfDownload'}?keyName=${encodeURI(keyName)}&lectId=${lectId}&page=${page || 1}`);
}

function generateAccountSas() { // 이미지 업로드 토큰 생성
    http.get('resource/file/azure/generateAccountSas')
        .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                const store = useSasTokenStore();
                store.sasToken = result;
            }
        }).catch( (res) =>  {
        });
}

export default {
    uploadFile,
    uploadSecurePDF,// 보안 PDF 업로드
    downloadFile,
    securePdfDownload, // 보안 PDF 다운로드
    generateAccountSas,
};