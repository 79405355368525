<script setup>
    import { ref, onMounted, getCurrentInstance, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import mypageUserService from "../../../services/mypage/mypageUserService";

    const globals = getCurrentInstance().appContext.config.globalProperties;
    const { $yyyymmddHHmmss, $toast, $gbNameKR, $userGbKR, $number, $pageListBoardNo } = globals;

    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    const getSystemCodeValues = inject('systemCodeValues');

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(1);
    const totalCount = ref(0);

    const pageSizeGroup = ref([
      { label:"10개씩 보기", value: "10" },
      { label:"30개씩 보기", value: "30" },
      { label:"50개씩 보기", value: "50" },
      { label:"100개씩 보기", value: "100" },
    ])

    // 모달
    const confirmModal = ref(null);
    const modalContent = ref([]);
    const okButtonText = ref("확인");
    const cancelButtonText = ref("취소");
    const modal = ref(null);

    onMounted( async () => {
      await retrieveList();
    })

    const retrieveList = async () => {
      let paramObj = {
        userId: 0,
        pageNumber: currentPage.value,
        size: pageSize.value,
      }
      loadingStore.loading = true;
      //loaded.value = false;
      await getSystemCodeValues();
      mypageUserService.retrieveUserGbHistoryListByUserId(paramObj)
          .then( async (res) => {
            loadingStore.loading = false;
            //loaded.value = true;
            //console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              totalPages.value = result.totalPages;
              totalCount.value = result.totalElements;
              list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
            } else {
            }
          }).catch( (res) =>  {
        console.log(res);
        loadingStore.loading = false;
        //loaded.value = true;
      });
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    // 페이지당 개수 변경
    const onSelectPageSize = (option) => {
      pageSize.value = option.value;
      currentPage.value = 1;
      retrieveList();
    }

    // 회원 유형 변경 상태 변경
    const changeUserGbHistoryState = async (seq, stateGb) => {
      if ( stateGb === '02' ) {
        modalContent.value = ["신청건이 취소 처리됩니다.", "상태를 ‘취소’로 변경할까요?"];
      }
      okButtonText.value = "변경";

      const ok = await confirmModal.value.show();

      if(!ok) {
        return -1;
      } else {
        let paramObj = {
          seq: seq,
          stateGb: stateGb
        }
        mypageUserService.changeUserGbHistoryState(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveList();
              } else {
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    }

    const createUserGbHistory =  () => {
      // 이미 회원 유형 변경 신청한 이력이 있을 경우
      countUserGbHistory();
    }

    const countUserGbHistory = () => {
      mypageUserService.countUserGbHistory()
          .then( (res) => {
            loadingStore.loading = false;
            // console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              if(result > 0) {
                modalContent.value = ["현재 진행 중인 변경 신청건이 확인되었습니다.", "결과 반영 이후 다시 신청해 주세요."];
                const ok = modal.value.show();
              } else if (result === -1) {
                $toast('', [res.data.msg])
              } else {
                router.push({name: 'MypageUserGbHistoryCreate'});
              }
            }
          }).catch( (res) =>  {
        loadingStore.loading = false;
        // console.log(res);
      });
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content">
                    <div class="sub-title">
                        <h3>회원 유형 변경</h3>
                    </div>
                    <div class="process-wrap">
                       <h4>변경 절차</h4>
                      <div class="process-layout">
                        <div class="process-steps-scroll">
                          <div class="process-steps">
                            <div class="step"><div>변경<br>신청하기</div></div>
                            <div class="step-arrow"><div></div></div>
                            <div class="step"><div>변경 할<br>회원 유형<br>선택</div></div>
                            <div class="step-arrow"><div></div></div>
                            <div class="step"><div>신청 완료</div></div>
                            <div class="step-arrow"><div></div></div>
                            <div class="step"><div><span class="tip-admin"></span>승인</div></div>
                            <div class="step-arrow active"><div></div></div>
                            <div class="step large active"><div>회원 유형<br>변경 완료</div></div>
                          </div>
                        </div>
                        <div class="button-with-info">
                          <p>회원 유형을 변경하고 유형별<br>맞춤서비스를 다양하게 이용해 보세요.</p>
                          <div class="button-with-info-cta" @click="createUserGbHistory">변경 신청하기<span class="icon-button-right"></span></div>
                        </div>
                      </div>
                    </div>
                    <!-- 검색 자리 -->
                    <div class="list-top">
                        <p>신청 <span>{{ $number(totalCount) }}</span>건</p>
                        <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                    <div class="table-form board-mobile-vertical">
                        <table>
                            <tr>
                                <th>NO</th>
                                <th>신청일</th>
                                <th>현재 회원 유형</th>
                                <th>추가/변경 회원 유형</th>
                                <th>상태</th>
                                <th>상태 변경</th>
                            </tr>
                            <tr v-for="userGbHistory in list"
                                :key="userGbHistory.seq"
                                :style="{ color: userGbHistory.stateGb !== '01' ? '#86889A' : 'inherit'}"
                            >
                              <td>{{ userGbHistory.boardNo }}</td>
                              <td>{{ $yyyymmddHHmmss(userGbHistory.createDate) }}</td>
                              <td>{{ $userGbKR(userGbHistory.beforeUserGb) }}</td>
                              <td>{{ $userGbKR(userGbHistory.afterUserGb) }}</td>
                              <td>{{ $gbNameKR('USER_GB_STATE_GB', userGbHistory.stateGb) }}</td><!-- 01 신청 02 취소 03 승인 04 거절 -->
                              <td>
                                <span v-if="userGbHistory.stateGb === '01'" data-title="신청 : ">
                                    <KSATextButton text="신청 취소" @click="changeUserGbHistoryState(userGbHistory.seq, '02')" class="clickable cancel"/>
                                </span>
                                <span v-else> - </span>
                              </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="list.length === 0">
                            신청건이 없습니다.
                        </div>
                    </div>
                    <Paginate
                        v-if="list.length > 0"
                        v-model="currentPage"
                        :page-count="totalPages"
                        :click-handler="onSelectedPage"
                    />
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" :cancelButtonText="cancelButtonText" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    /* Filter */
    .filter-wrap {
        margin-bottom: 40px;
        &.open {
            p {
                &:before {
                    transition-duration: 0.2s;
                    -webkit-transform: translateY(-50%) rotate(225deg);
                    transform: translateY(-50%) rotate(225deg);
                }
            }
            .filter {
                display: block;
                border-bottom: $border_line;
            }
        }
        p {
            position: relative;
            @include font-size_md;
            background: $gray-light_color;
            border: $border_line;
            font-weight: 700;
            cursor: pointer;
            padding: 10px 20px;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 20px;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 1px solid #000000;
                border-left: 1px solid #000000;
                -webkit-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg);
                transition-duration: 0.2s;
            }
        }
        .filter {
            display: none;
            border-left: $border_line;
            border-right: $border_line;
            dl {
                display: flex;
                align-items: center;
                dt {
                    display: flex;
                    align-items: center;
                    width: 180px;
                    height: 48px;
                    font-weight: 700;
                    padding: 10px 10px 10px 20px;
                    .select {
                        font-weight: initial;
                    }
                }
                dd {
                    width: calc(100% - 180px);
                    padding: 10px 20px 10px 0;
                    .grid {
                        span {
                            margin: 0 4px;
                        }
                    }
                }
                &:first-of-type {
                    padding-top: 10px;
                }
                &:last-of-type {
                    padding-bottom: 10px;
                }
            }
            .btn-wrap {
                border-top: $border_line;
                padding: 10px 20px;
                margin: 0;
                button {
                    margin: 0 5px;
                }
            }
            .text-box {
                margin-top: 0;
            }
        }
    }

    .clickable {
      cursor: pointer;
      &.cancel {
        color: #E71414;
        text-decoration: underline;
        &:hover {
          font-weight: 700;
        }
      }


    }

    .white-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }

    .blueTextButton { color: $blue_color; }
</style>