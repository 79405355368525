<script setup>
    import { ref, onMounted } from 'vue'
    import { useRoute } from 'vue-router'
    import { useSysAdminMenuStore } from "../../pages/sysadmin/stores/menuStore";
    import _ from "lodash";

    const route = useRoute()

    onMounted(()=>{
        const menuStore = useSysAdminMenuStore()
        menuList.value = menuStore.menuList;
    })
    const menuList = ref([])

    const onClickMenu = (e, menu) => {
        if(e.target.closest('.sub-title') === null) {
            _.each(menuList.value, m => {
                if(m.menuUrl === menu.menuUrl) {
                    m.open = !m.open;
                } else {
                    m.open = false;
                }
            })
        }
    }
    const isActiveMenu = (menu) => {
        return route.fullPath.indexOf(menu.menuUrl) > -1;
    }
</script>

<template>
    <div>
        <ul>
            <li :class="{'sub': menu.child.length > 0, 'on': isActiveMenu(menu), 'open': menu.open}" v-for="menu in menuList" @click="onClickMenu($event, menu)">
                <RouterLink v-if="menu.child.length === 0" :to="menu.menuUrl">{{ menu.menuName }}</RouterLink>
                <span v-if="menu.child.length > 0">{{ menu.menuName }}</span>
                <ul v-show="menu.open && menu.child.length > 0">
                    <li class="sub-title" :class="{'on': isActiveMenu(childMenu)}" v-for="childMenu in menu.child">
                        <RouterLink :to="childMenu.menuUrl">{{ childMenu.menuName }}</RouterLink>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/theme";

.snb {
    float: left;
    width: 220px;
    min-height: calc(100vh - 140px);
    border-right: $border_line;
    ul {
        li {
            @include font-size_md;
            border-bottom: $border_line;
            padding: 16px 30px;
            &.on {
                color: $blue_color;
                font-weight: 700;
            }
            &.open {
                &.sub {
                    span {
                        &:before {
                            top: 45% !important;
                            -webkit-transform: translateY(-50%) rotate(225deg);
                            transform: translateY(-50%) rotate(225deg);
                        }
                    }
                }
            }
            &.sub {
                padding: 0;
                span {
                    position: relative;
                    display: block;
                    padding: 16px 30px;
                    cursor: pointer;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-top: 1px solid #000000;
                        border-left: 1px solid #000000;
                        -webkit-transform: translateY(-50%) rotate(135deg);
                        transform: translateY(-50%) rotate(135deg);
                        transition-duration: 0.2s;
                    }
                }
            }
            ul {
                border: 0;
                border-top: $border_line;
                background: $gray-thin_color;
                li {
                    @include font-size_sm;
                    color: $black_color;
                    font-weight: 400;
                    border: 0;
                    padding: 10px 40px;
                    &.on {
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>