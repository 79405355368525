<script setup>
    import { ref, onMounted, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import lectureUserService from "../../../services/lecture/lectureUserService";
    import _ from 'lodash'
    import moment from 'moment'
    import {useMyPageLectDetailStore} from "../../../stores/mypage/mypageLectDetailStore";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const lectureStore = useMyPageLectDetailStore()

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    onMounted(() => {
        retrieveList();
    })

    const letureOnlineList = computed(()=>{
        return _.filter(list.value, { lectGb: '01'});
    })

    const letureOfflineList = computed(()=>{
        return _.filter(list.value, { lectGb: '02'});
    })

    const retrieveList = () => {
        loadingStore.loading = true;
        lectureUserService.retrieveMyLectureListByCreatId()
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result)
                    list.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const lectureImgClass = (lecture) => {
        const regStartYmd = moment(lecture.regStartYmd.replaceAll('.', '')).format('YYYYMMDD');
        const regEndYmd = moment(lecture.regEndYmd.replaceAll('.', '')).format('YYYYMMDD');
        const nowYmd = moment().format('YYYYMMDD');
        if(Number(nowYmd) > Number(regEndYmd)) {
            return 'close';
        } else if(Number(nowYmd) >= Number(regStartYmd) && Number(nowYmd) <= Number(regEndYmd)) {
            return 'open';
        } else {
            return ''
        }
    }

    const goLectureDetailView = (lecture) => {
        lectureStore.setLecture(lecture)
        router.push({name: 'MyPageMyClassDetail', params:{id: lecture.lectId}})
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content">
                    <div class="lecture-wrap">
                        <div class="sub-title">
                            <h3>개설 강의 현황</h3>
                        </div>
                        <div class="content-title">
                            온라인 강의<span v-if="letureOnlineList.length > 0">({{ letureOnlineList.length }})</span>
                        </div>
                        <ul class="lecture-list">
                            <li v-for="lecture in letureOnlineList" @click="goLectureDetailView(lecture)">
                                <div class="lecture-img">
                                    <KSAImage :src="lecture.lectThombDir"/>
                                </div>
                                <div class="lecture-text">
                                    <h4>{{ lecture.lectName }}</h4>
                                    <div class="lecture-info">
                                        <p>
                                            <i>
                                                <b>교육기간</b>&nbsp;
                                                <span v-if="lecture.lectTermGb === '01'">{{ lecture.lectTerm}}일</span>
                                                <span v-else>{{ $yyyymmdd(lecture.lectStartYmd) }} ~ {{ $yyyymmdd(lecture.lectEndYmd) }}</span>
                                            </i>
                                        </p>
                                        <p>
                                            <i><b>총 {{ lecture.lectureIndexCount }}강</b></i>
                                        </p>
                                    </div>
                                    <div class="lecture-content">
                                        {{ lecture.lectDesc }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="result-none lecture-none" v-if="letureOnlineList.length === 0">
                            개설한 강의가 없습니다.
                        </div>
                        <div class="content-title">
                            오프라인 강의<span v-if="letureOfflineList.length > 0">({{ letureOfflineList.length }})</span>
                        </div>
                        <ul class="lecture-list">
                            <li v-for="lecture in letureOfflineList" @click="goLectureDetailView(lecture)">
                                <div class="lecture-img" :class="lectureImgClass(lecture)">
                                    <KSAImage :src="lecture.lectThombDir"/>
                                </div>
                                <div class="lecture-text">
                                    <h4>{{ lecture.lectName }}</h4>
                                    <div class="lecture-info">
                                        <p class="date">
                                            <i>
                                                <b>신청기간</b>&nbsp;
                                                <span v-if="lecture.regTermGb === '01'">상시</span>
                                                <span v-else>{{ $yyyymmdd(lecture.regStartYmd) }} ~ {{ $yyyymmdd(lecture.regEndYmd) }}</span>
                                            </i>
                                        </p>
                                        <p class="date">
                                            <i>
                                                <b>교육기간</b>&nbsp;
                                                <span v-if="lecture.lectTermGb === '01'">신청일로부터 {{ lecture.lectTerm }}일</span>
                                                <span v-else>{{ $yyyymmdd(lecture.lectStartYmd) }} ~ {{ $yyyymmdd(lecture.lectEndYmd) }}</span>
                                            </i>
                                        </p>
                                        <p>
                                            <i><b>총 {{ lecture.lectureIndexCount }}강</b></i>
                                        </p>
                                    </div>
                                    <div class="lecture-content">
                                        {{ lecture.lectDesc }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="result-none lecture-none" v-if="letureOfflineList.length === 0">
                            개설한 강의가 없습니다.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>