<script setup>
    import { ref, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import _ from "lodash";
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import surveyService from "../../../services/lecture/surveyService";
    import KSAInput from "../../../../../components/ui-components/input/KSAInput.vue";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        const { id } = route.params;
        if(id !== '0') { // 문항 복사. 문항등록인 경우 id가 0
            isCopy = true; // 복사여부
            retrieveSurveyQuestionDetail(id);
        } else {
            initializeEmptyPointOptions(5);
        }
    })

    const input = ref({
        useYn: "Y",        // 사용여부 (Y: 사용, N: 미사용)
        surveyQGb: "01",   // 만족도문항구분(139) - 01: 교육 전후 역량 변화, 02: 교육 만족도, 03: 현업 활용도, 04: 수요 조사, 05: 교육과정 안내 경로 선택
        surveyQType: "04", // 만족도문항유형(140) - 04: 5점 척도, 02: 주관식, 03: 다중응답형, 01: 7점 척도(삭제)
        surveyQTitle: "",  // 문항 제목
        etcYn: "Y",        // 보기기타여부 (Y: 사용, N: 미사용)
        choiceList: [],    // 보기리스트
        pointOptions: [],    // 5점 척도 답변 리스트
    })

    const pointOptionsError = ref(null);

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref('');
    const confirmModalContent = ref('');

    let isCopy = false; // 복사여부

    // asnwerList.length가 0일 경우, 5개로 초기화 시켜주기. 그래야 input 이 5개 생김.
    const initializeEmptyPointOptions = (size) => {
      input.value.pointOptions = [];
      for (let i=0; i<size; i++) {
        input.value.pointOptions.push({
          surveyChoiceId: 0,
          surveyQId: 0,
          surveyChoiceContent: '',
          placeholder: getPlaceholder(i),
          order: i+1,
          deleteYn: "N",
        });
      }
    };

    const getPlaceholder = (index) => {
      const placeholders = ['매우 낮음', '낮음', '보통', '높음', '매우 높음'];
      return placeholders[index % placeholders.length];
    }

    const retrieveSurveyQuestionDetail = (id) => {
        loadingStore.loading = true;
        surveyService.retrieveSurveyQuestion(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    input.value = result;
                    input.value.choiceList.forEach(choice => {
                      choice.surveyChoiceId = 0;
                      choice.surveyQId = 0;
                    });
                    input.value.pointOptions.forEach(point => {
                      point.surveyChoiceId = 0;
                      point.surveyQId = 0;
                    })
                    if(input.value.pointOptions.length < 5) {
                      initializeEmptyPointOptions(5 - input.value.pointOptions.length);
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const create = () => {
        const { useYn, surveyQGb, surveyQType, surveyQTitle, etcYn, choiceList, pointOptions } = input.value;
        let isInvalid = false;

        if( !useYn ) {
            modalContent.value = [`사용여부를 입력하세요.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }
        if( !surveyQGb ) {
            modalContent.value = [`문항구분을 입력하세요.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }
        if( !surveyQType ) {
            modalContent.value = [`문항유형을 입력하세요.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }
        if( !surveyQTitle ) {
            modalContent.value = [`문항을 입력하세요.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }
        if( surveyQType === '03' ) {
            for( let i = 0; i < choiceList.length; i++ ) {
                choiceList[i].order = i+1;
                if( !choiceList[i].surveyChoiceContent ){
                    modalContent.value = [`다중응답형 보기를 입력하세요.`];
                    modal.value.show();
                    isInvalid = true;
                    return false;
                }
            }
        }

        // 5점척도 답변 리스트 validation
        if( surveyQType === '04' ) {
            for ( let i = 0; i < pointOptions.length; i++ ) {
              pointOptions[i].order = i+1;
              if( !pointOptions[i].surveyChoiceContent ){
                  modalContent.value = [`보기 문구를 입력하세요.`];
                  modal.value.show();
                  isInvalid = true;
                  return false;
              } else {
                 if( !validatePointOption(pointOptions[i].surveyChoiceContent) ) {
                     modalContent.value = [`영문, 한글, 숫자를 사용하여 2~12자 이내로 입력하세요.`];
                     modal.value.show();
                     isInvalid = true;
                     return false;
                 }
              }
            }
        }

        if(!isInvalid) {
            let paramObj = {
                useYn,
                surveyQGb,
                surveyQType,
                surveyQTitle,
                etcYn,
                choiceList: _.filter(choiceList, choice => {
                    return choice.surveyQTitle !== null && choice.surveyQTitle !== '';
                }),
                pointOptions: _.filter(pointOptions, point => {
                    return point.surveyQTitle !== null && point.surveyQTitle !== '';
                }),
            }

            loadingStore.loading = true;
            surveyService.createSurveyQuestion(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;

                        if ( isCopy ) { // 문항 복사
                            modalContent.value = ["문항을 복사했습니다."];
                        } else {        // 문항 등록
                            modalContent.value = ["문항을 등록했습니다."];
                        }

                        modal.value.show();
                        router.push({name: 'LectureSurveyQuestionList'});

                    } else {
                        modalContent.value = ["등록을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                console.log(res);
                loadingStore.loading = false;
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    const validatePointOption = (point) => {
      const regex = /^[A-Za-z0-9가-힣\s]{2,12}$/;
      if (point && !regex.test(point)) {
        pointOptionsError.value = `영문, 한글, 숫자를 사용하여 2~12자 이내로 입력하세요.`;
      } else {
        pointOptionsError.value = null;
      }
      return regex.test(point);
    }

    const onAddChoice = () => {
        input.value.choiceList.push({
            surveyChoiceId: 0,
            surveyQId: 0,
            surveyChoiceContent: "",
            order: 0,
            deleteYn: "N"
        })
    }

    const onDeleteChoice = (choice, index) => {
        const { choiceList } = input.value;
        choice.deleteYn = 'Y';
        choiceList.splice(index, 1);
        if(_.isEmpty(choiceList)) {
            onAddChoice();
        }
    }

    // 7점척도 수정시 답안 저장
    const onChangeQType = (surveyQType) => {

        if ( surveyQType === '03' ) { // 다중응답형
            // 5점척도 답변 데이터 초기화
            initializeEmptyPointOptions(5);
            // 다중응답형 보기가 한개도 없으면 1개의 빈보기 생성
            if ( input.value.choiceList.length <= 0 ) {
                onAddChoice();
            }
        } else if( surveyQType === '02' ) {
            // 5점척도 답변 데이터 초기화
            initializeEmptyPointOptions(5);
            // 다중응답형 보기가 있다면 모두 삭제
            if ( input.value.choiceList.length > 0 ) {
                input.value.choiceList = [];
            }
        } else { // 5점 척도 주관식
            // 다중응답형 보기가 있다면 모두 삭제
            if ( input.value.choiceList.length > 0 ) {
              input.value.choiceList = [];
            }
        }
    };

    const onCancel = async () => {
        confirmModalContent.value = ["페이지를 나가겠습니까?", "페이지 이동 시 작성한 내용은 저장되지 않습니다"];
        const ok = await confirmModal.value.show();
        if (ok) { // 나가기
            router.push({name: 'LectureSurveyQuestionList'});
        } else { // 취소

        }
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3 v-if="isCopy">문항 복사</h3>
                    <h3 v-else>문항 등록하기</h3>
                    <p><span>* 표시는 필수 입력사항</span> 입니다.</p>
                </div>

                <div class="table-form mb-sm">
                    <table class="vertical">
                        <colgroup>
                            <col width="200px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>사용 여부</th>
                            <td>
                                <KSARadioButton class="mr-md" label="사용" v-model="input.useYn" value="Y" />
                                <KSARadioButton class="mr-md" label="미사용" v-model="input.useYn" value="N" />
                            </td>
                        </tr>
                        <tr>
                            <th>문항 구분</th>
                            <td>
                                <KSARadioButton class="mr-md" label="교육 전후 역량 변화" v-model="input.surveyQGb" value="01" />
                                <KSARadioButton class="mr-md" label="교육 만족도" v-model="input.surveyQGb" value="02" />
                                <KSARadioButton class="mr-md" label="현업 활용도" v-model="input.surveyQGb" value="03" />
                                <KSARadioButton class="mr-md" label="수요 조사" v-model="input.surveyQGb" value="04" />
                                <KSARadioButton class="mr-md" label="교육과정 안내 경로" v-model="input.surveyQGb" value="05" />
                            </td>
                        </tr>
                        <tr>
                            <th>문항 유형</th>
                            <td>
                                <KSARadioButton class="mr-md" label="5점 척도"  v-model="input.surveyQType" value="04" @change="onChangeQType('04')" />
                                <KSARadioButton class="mr-md" label="주관식"    v-model="input.surveyQType" value="02" @change="onChangeQType('02')" />
                                <KSARadioButton class="mr-md" label="다중응답형" v-model="input.surveyQType" value="03" @change="onChangeQType('03')" />
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">문항</th>
                            <td><KSAInput class="text-box" v-model="input.surveyQTitle" placeholder="문항을 입력해주세요." /></td>
                        </tr>
                        <tr v-if="input.surveyQType === '03'">
                            <th class="mark">다중응답형 보기</th>
                            <td class="text-box">
                                <div class="grid file no-btn mt-xs" v-for="(choice, index) in input.choiceList">
                                    <KSAInput class="input icon" v-model="choice.surveyChoiceContent" />
                                    <button class="delete" @click="onDeleteChoice(choice, index)"><i></i></button>
                                </div>
                                <button class="text-btn" @click="onAddChoice">+ 보기 추가</button>
                            </td>
                        </tr>
                        <tr v-if="input.surveyQType === '03'">
                            <th>다중응답형 보기 - 기타</th>
                            <td>
                                <KSARadioButton class="mr-md" label="있음" v-model="input.etcYn" value="Y" />
                                <KSARadioButton class="mr-md" label="없음" v-model="input.etcYn" value="N" />
                            </td>
                        </tr>
                        <tr v-if="input.surveyQType === '04'">
                            <th class="mark">5점 척도<br>답변 보기</th>
                            <td>
                              <input type="hidden" :value="input.etcYn = 'N'">
                              <div class="grid file no-btn mt-xs">
                                <div  v-for="(point, index) in input.pointOptions" :key="index" style="text-align: center">
                                  <span v-if="index === 0" style="position:relative;left:-10px;">①</span>
                                  <span v-if="index === 1" style="position:relative;left:-10px;">②</span>
                                  <span v-if="index === 2" style="position:relative;left:-10px;">③</span>
                                  <span v-if="index === 3" style="position:relative;left:-10px;">④</span>
                                  <span v-if="index === 4" style="position:relative;left:-10px;">⑤</span>
                                  <br>
                                  <KSAInput class="input icon"
                                              v-model="point.surveyChoiceContent"
                                              :placeholder="point.placeholder"
                                              @blur="validatePointOption(point.surveyChoiceContent)"
                                              style="display:inline-block;"/>
                                  <span v-if="index !== input.pointOptions.length - 1">-</span>
                                  <div v-if="index === 0" style="position:relative;">
                                    <img src="/src/assets/images/arrow-negative.svg" style="position:absolute;top:23px;left:0;">
                                  </div>
                                  <div v-if="index === 4" style="position:relative;">
                                    <img src="/src/assets/images/arrow-positive.svg" style="position:absolute;top:23px;right:0;">
                                  </div>
                                </div>
                              </div>
                              <!-- 검증 에러메시지-->
                              <small v-if="pointOptionsError" style="color: red;">{{ pointOptionsError }}</small>
                              <br><br><br>
                              <div class="info-container">
                                ※ 작성 시 유의 사항<br>
                                · 부정과 긍정의 보기 형식 기준은 코드화되어 있어 평점 처리됩니다.<br>
                                · 작성 시 보기를 5점 척도 기준에 맞게 명확히 구분하여 작성해 주세요.
                              </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="btn-wrap space-between mt-xs">
                    <KSASecondaryButton class="gray_button" text="취소" @click="onCancel" />
                    <KSAPrimaryButton text="등록" @click="create" />
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="confirmModalContent" okButtonText="나가기" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.table-form table.horizontal {
  tr:hover {
    background: transparent;
  }
}

.text-box {
    .grid {
        &:first-child {
            margin-top: 0 !important;
        }
    }
}

.info-container{
  background-color:#FFF6D6;
  border:1px solid #EEE5C4;
  padding:15px 10px;
  color:#6d5215;
}
</style>