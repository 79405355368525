<script setup>
    import { ref, computed, onMounted, getCurrentInstance, inject } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import _ from "lodash";
    import Loading from 'vue3-loading-overlay';
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import { useMatchingUpdateStore} from "../../../user/stores/matching/matchingUpdateStore";
    import adminMatchingService from "../../services/matching/adminMatchingService";
    import mypageMatchingService from "../../../user/services/mypage/mypageMatchingService";
    import mypageProposalService from "../../../user/services/mypage/mypageProposalService";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import KSASecondaryButton from "../../../../components/ui-components/button/KSASecondaryButton.vue";
    import MyPageResumeModal from "../../../user/components/modal/mypage/MyPageResumeModal.vue";
    import MypageProposalContentModal from "../../../user/views/mypage/proposal/MypageProposalContentModal.vue";

    const getSystemCodeValues = inject('systemCodeValues');

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const matchingUpdateStore = useMatchingUpdateStore()
    const loaded = ref(false);
    const { $desiredJob, $interests, $corpState, $bizNum, $phoneNum, $gbNameKR, $toast, $number, $pageListBoardNo, $yyyymmddHHmmss, $gb } = getCurrentInstance().appContext.config.globalProperties;

    const interests = ref($interests)
    const desiredJob = ref($desiredJob)
    const corpState = ref($corpState)

    const desiredJobListRef = ref(null)
    const interestsListRef2 = ref(null)
    const interestsListRef3 = ref(null)
    const stateGbsListRef = ref(null)
    const matchingGbListRef = ref(null)

    // 데이터
    const corporation = ref({
      corpId: '',
    })  // 기업 기본 정보
    const corpEmpList = ref([]);  // 재직자 목록
    const corpMatchingList = ref([]);  // 채용 현황 목록
    const resultCount = ref({}); // 채용 진행 현황 수

    const checkMasterYn = ref(false);

    onMounted(()=>{
        loaded.value = false;
        const { id } = route.params;
        if(!id) {
            goBack();
        } else {
            corporation.value.corpId = id;
            retrieveCorpDetail();
            //retrieveCorpEmpList();
            //retrieveCorpMatchingList();
        }
    })

    const tabIndex = ref(1)

    // 팝업정보
    const modal = ref(null)
    const confirmModal = ref(null)
    const userMatchingResumeModal = ref(null);
    const proposalContentModal = ref(null);
    const modalContent = ref([])
    const okButtonText = ref('삭제')

    // s : 검색관련
    const searchOpen2 = ref(true); // 재직자 가입 현황 tab 검색창
    const searchOpen3 = ref(true); // 채용 진행 현황 tab 검색창

    const searchDurationGbRef = ref([]);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const searchInput2 = ref({ // 재직자 가입 현황 tab 검색조건
        desiredJobList: ['회로/시스템 설계','장비 운영/유지보수/개선','장비/부품 개발','소재 개발 및 평가','공정 개발 및 평가','패키지 개발','제품 평가 및 분석','반도체 SW 개발','기타'],
        interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
        sex: '00',
        corpMasterYn: false,
        searchType: 'name', // code 회원코드,name 이름, id 아이디, email 이메일
        searchInput: '',
        searchDurationGb: 'C', // (L 최근 접속일 C 가입일)
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    });

    const searchInput3 = ref({ // 채용 진행 현황 tab 검색조건
        stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
        interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
        matchingGbList: ['01','02'],
        searchType: 'userName', // userName 구직자명  masterName 마스터명
        searchInput: '',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    });

    // 검색조건 - 성별. 재직자 가입 현황 tab
    const sexGroup = ref([
      { label:'전체', value: '00' },
      { label:'남자', value: '01' },
      { label:'여자', value: '02' },
    ]);

    // 검색조건 - 인재 채용, 채용 제안. 채용 진행 현황 tab
    const GroupMatching = ref([
      { label:'인재 채용', value: '02' },
      { label:'채용 제안', value: '01' },
    ]);

    // 검색조건 - 검색기준 (재직자명, 아이디, 핸드폰번호 뒷4자리, 이메일). 재직자 가입 현황 tab
    const searchTypeGroup2 = ref([
      { label:'재직자명', value: 'name', placeholder: '이름을 입력해 주세요.' },
      { label:'아이디', value: 'id', placeholder: '아이디를 입력해 주세요.' },
      { label:'핸드폰번호', value: 'phone', placeholder: '"-"를 제외한 핸드폰번호 뒷4자리를 입력해 주세요.' },
      { label:'이메일', value: 'email', placeholder: '이메일을 입력해 주세요.' },
    ]);

    // 검색조건 - 검색기준 (구직자명, 마스터명). 채용 진행 현황 tab
    const searchTypeGroup3 = ref([
      { label:'구직자명', value: 'userName', placeholder: '이름을 입력해 주세요.'  },
      { label:'마스터명', value: 'masterName', placeholder: '이름을 입력해 주세요.'  },
    ]);

    // 검색조건 - 기간 (C 가입일, C 최근 접속일). 재직자 가입 현황 tab.
    const searchDurationGbGroup = ref([
      { label:'가입일', value: 'C' },
      { label:'최근 접속일', value: 'L' },
    ])

    // 검색조건 - 날짜검색기준 (all 모든 기간, term 기간 검색). 재직자 가입 현황 tab
    const searchDurationTypeGroup = ref([
      { label:'모든 기간', value: 'all' },
      { label:'기간 검색', value: 'term' },
    ])

    // 검색조건 - 페이지 크기, 재직자 가입 현황 tab, 채용 진행 현황 tab
    const pageSizeGroup = ref([
      { label:'10개씩 보기', value: '10' },
      { label:'30개씩 보기', value: '30' },
      { label:'50개씩 보기', value: '50' },
      { label:'100개씩 보기', value: '100' },
    ])

    const searchInputPlaceholder2 = computed(()=>{
        const option = _.find(searchTypeGroup2.value, { value: searchInput2.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    });

    const searchInputPlaceholder3 = computed(()=>{
        const option = _.find(searchTypeGroup3.value, { value: searchInput3.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    });
    // e : 검색관련

    // 검색조건 - 검색기준 매핑
    const onSelectSearchTypeGroup = (option) => {
        if(tabIndex.value === 2) { // 재직자 가입 현황
            searchInput2.value.searchType = option.value;
        } else if(tabIndex.value === 3) { // 채용 진행 현황
            searchInput3.value.searchType = option.value;
        }
    }

    // 검색조건 - 기간 매핑(C:가입일, L:최근 접속일), 재직자 가입 현황 tab
    const onSelectSearchDurationGbGroup = (option) => {
        searchInput2.value.searchDurationGb = option.value;
    }

    // 검색조건 - 초기화 이벤트
    const clearSearchInput = () => {
        if(tabIndex.value === 2) { // 재직자 가입 현황
            searchInput2.value = {
                desiredJobList: ['회로/시스템 설계','장비 운영/유지보수/개선','장비/부품 개발','소재 개발 및 평가','공정 개발 및 평가','패키지 개발','제품 평가 및 분석','반도체 SW 개발','기타'],
                interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
                sex: '00',
                corpMasterYn: false,
                searchType: 'name', // code 회원코드,name 이름, id 아이디, email 이메일
                searchInput: '',
                searchDurationGb: 'C', // (C 가입일 L 최근 접속일 )
                searchDurationType: 'all',
                startYmd: '',
                endYmd: '',
            }
            desiredJobListRef.value.initOptions();
            interestsListRef2.value.initOptions();
        } else if(tabIndex.value === 3) { // 채용 진행 현황
            searchInput3.value = {
                stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
                interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
                matchingGbList: ['01','02'],
                searchType: 'userName', // userName 구직자명  masterName 마스터명
                searchInput: '',
                searchDurationType: 'all',
                startYmd: '',
                endYmd: '',
            }
            stateGbsListRef.value.initOptions();
            interestsListRef3.value.initOptions();
            matchingGbListRef.value.initOptions();
        }
    }

    // 검색조건 - 검색 이벤트
    const onClickSearch = () => {
        currentPage.value = 1;
        if(tabIndex.value === 2) { // 재직자 가입 현황
            retrieveCorpEmpList();
        } else if(tabIndex.value === 3) { // 채용 진행 현황
            retrieveCorpMatchingList();
        }
    }

    // 페이지 선택
    const onSelectPage = (pageNo) => {
       currentPage.value = pageNo;
       if(tabIndex.value === 2) { // 재직자 가입 현황
          retrieveCorpEmpList();
       } else if(tabIndex.value === 3) { // 채용 진행 현황
          retrieveCorpMatchingList();
       }
    }

    // 검색조건 - 페이지 크기 변경 이벤트
    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;

       if(tabIndex.value === 2) { // 재직자 가입 현황
          retrieveCorpEmpList();
       } else if(tabIndex.value === 3) { // 채용 진행 현황
          retrieveCorpMatchingList();
       }
    }

    // 탭 선택 이벤트
    const onSelectTab = (option) => {
       tabIndex.value = option.value;
       currentPage.value = 1;
       if(tabIndex.value === 1) { // 기본 정보
         retrieveCorpDetail();
       } else if(tabIndex.value === 2) { // 재직자 가입 현황
         retrieveCorpEmpList();
       } else if(tabIndex.value === 3) { // 채용 진행 현황
         retrieveCorpMatchingList();
       }
    }

    // s: 기본 정보
    // 기업 기본 정보 조회
    const retrieveCorpDetail = async () => {
      loaded.value = false;
      //loadingStore.loading = true;
      await getSystemCodeValues();
      adminMatchingService.retrieveCorp(corporation.value.corpId)
          .then( async (res) => {
            //loadingStore.loading = false;
            loaded.value = true;
            // console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              corporation.value = res.data.result;
            } else {
            }
          }).catch( (res) =>  {
        console.log(res);
        //loadingStore.loading = false;
        loaded.value = true;
      });
    }
    // e: 기본 정보

    // s: 재직자 가입 현황
    // 재직자 가입 현황 조회
    const retrieveCorpEmpList = () => {
      let paramObj = {
        corpId: corporation.value.corpId,
        searchDesiredJobs: _.map(searchInput2.value.desiredJobList).join(','),
        searchInterests: _.map(searchInput2.value.interestsList).join(','),
        searchSex: searchInput2.value.sex,
        searchCorpMasterYn: searchInput2.value.corpMasterYn ? 'Y' : 'N',
        searchType: searchInput2.value.searchType,
        searchInput: searchInput2.value.searchInput,
        searchDurationGb: searchInput2.value.searchDurationGb,
        startYmd: searchInput2.value.searchDurationType === 'all' ? '' : searchInput2.value.startYmd,
        endYmd: searchInput2.value.searchDurationType === 'all' ? '' : searchInput2.value.endYmd,
        pageNumber: currentPage.value,
        size: pageSize.value,
      }
      //loadingStore.loading = true;
      loaded.value = false;
      adminMatchingService.retrieveCorpEmpList(paramObj)
          .then(async (res) => {
            //loadingStore.loading = false;
            loaded.value = true;
            // console.log(res);
            if (res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              totalPages.value = result.totalPages;
              totalCount.value = result.totalElements;
              corpEmpList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
            } else {
            }
          }).catch((res) => {
        console.log(res);
        //loadingStore.loading = false;
        loaded.value = true;
      });
    }

    // 마스터 권한 변경
    const changeAdminCorpMasterYn = async (userId, masterYn) => {
      // 마스터가 있을 경우, 마스터 권한 부여 못함.
      if(masterYn === 'Y') {
        await checkCorpMasterYn();
        if(checkMasterYn.value) {
          modalContent.value = ["이미 마스터 권한을 가진 회원이 있습니다."];
          modal.value.show();
          return -1;
        }
      }
      if(masterYn === 'Y') {
        okButtonText.value = '권한 부여';
        modalContent.value = ["회원에게 마스터 권한을 부여할까요?", "마스터 회원은 일자리/장비 매칭을 할 수 있습니다."];
      } else {
        okButtonText.value = '권한 취소';
        modalContent.value = ["마스터 권한을 취소 시킬까요?", "취소하더라도 매칭 내역 정보는 삭제되지 않습니다."];
      }
      const ok = await confirmModal.value.show();

      if(!ok) {
        return -1;
      } else {
        let paramObj = {
          userId: userId,
          masterYn: masterYn,
        }
        loadingStore.loading = true;
        adminMatchingService.updateAdminCorpMasterYn(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
              if (res.data.code.toLowerCase() === 'success') {
                modalContent.value = ["마스터 권한 변경을 하였습니다."];
                modal.value.show();
                retrieveCorpEmpList();
              } else {
                console.log(res);
              }
            }).catch( (res) => {
              console.log(res)
        });
      }
    }

    // 해당 기업에 마스터가 있는지 확인
    const checkCorpMasterYn = async () => {
      loadingStore.loading = true;
      await adminMatchingService.retrieveMasterYnCount(corporation.value.corpId)
          .then( (res) => {
            loadingStore.loading = false;
            if (res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              checkMasterYn.value = (result > 0);
            } else {
            }
          }).catch( (res) => {
            console.log(res);
      });
    }
    // e: 재직자 가입 현황

    // s: 채용 진행 현황
    // 채용 진행 현황 조회
    const retrieveCorpMatchingList = () => {
      let paramObj = {
        corpId: corporation.value.corpId,
        searchStateGbs: _.map(searchInput3.value.stateGbsList).join(','),
        searchInterests: _.map(searchInput3.value.interestsList).join(','),
        searchMatchingGb: _.map(searchInput3.value.matchingGbList).join(','),
        searchType: searchInput3.value.searchType,
        searchInput: searchInput3.value.searchInput,
        startYmd: searchInput3.value.searchDurationType === 'all' ? '' : searchInput3.value.startYmd,
        endYmd: searchInput3.value.searchDurationType === 'all' ? '' : searchInput3.value.endYmd,
        pageNumber: currentPage.value,
        size: pageSize.value,
      }
      //loadingStore.loading = true;
      loaded.value = false;
      adminMatchingService.retrieveCorpMatchingList(paramObj)
          .then( async (res) => {
            //loadingStore.loading = false;
            await retrieveCount();
            loaded.value = true;
            // console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              totalPages.value = result.totalPages;
              totalCount.value = result.totalElements;
              corpMatchingList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
            } else {
            }
          }).catch( (res) =>  {
        console.log(res);
        //loadingStore.loading = false;
        loaded.value = true;
      });
    }

    const retrieveCount = () => {
      let paramObj = {
        corpId: corporation.value.corpId,
        corpUserId: 0,
      }
      adminMatchingService.retrieveMatchingTotalCount(paramObj)
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              resultCount.value = res.data.result;
            } else {
            }
          }).catch( (res) => {
            console.log(res);
      });
    }

    // 채용 상태 변경
    const changeState = (matchingGb, userId, stateGb, propId, corpId, resumeId) => {
      if(matchingGb === '01') {  // 채용 제안
        changePropState(userId, propId, stateGb);
      } else if(matchingGb === '02') {  // 인재 채용
        changeResState(resumeId, corpId, stateGb)
      }
    }

    // 채용 제안 상태 변경
    const changePropState = async (userId, propId, stateGb) => {
      if(stateGb === '02') {
        modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
      } else if(stateGb === '05') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
      } else if(stateGb === '06') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
      } else if(stateGb === '07') {
        modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
      } else if(stateGb === '08') {
        modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
      }
      okButtonText.value = "변경";

      const ok = await confirmModal.value.show();
      if(!ok) {
        return false;
      } else {
        let paramObj = {
          propId: propId,
          userId: userId,
          propStateGb: stateGb
        };
        //console.log(paramObj);

        loadingStore.loading = true;
        mypageProposalService.changeProposalUserState(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                // const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveCorpMatchingList();
                // 매칭 상태 값 업데이트
                retrieveMatchingUpdate();
              } else {
                console.log(res);
              }
            }).catch( (res) => {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    };

    // 인재 채용 상태 변경
    const changeResState = async (resumeId, corpId, stateGb) => {
      if(stateGb === '02') {
        modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
      } else if(stateGb === '05') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
      } else if(stateGb === '06') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
      } else if(stateGb === '07') {
        modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
      } else if(stateGb === '08') {
        modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
      }
      okButtonText.value = "변경";
      const ok = await confirmModal.value.show();
      if(!ok) {
        return -1;
      } else {
        let paramObj2 = {
          resumeId: resumeId,
          corpId: corpId,
          resStateGb: stateGb,
        }
        mypageProposalService.changeResumeCorporationState(paramObj2)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                //const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveCorpMatchingList();
                // 매칭 상태값 업데이트
                retrieveMatchingUpdate();
              } else {
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    }

    // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
    const retrieveMatchingUpdate = async () => {
      await mypageMatchingService.retrieveMatchingUpdate()
          .then( async (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              matchingUpdateStore.matchingUpdate = result;
              await matchingUpdateStore.setMatchingUpdate( result );
              matchingUpdateStore.value = await useMatchingUpdateStore();
            }
          }).catch( (error) => {
            loadingStore.loading = false;
            console.log(error);
          });
    }

    // 기본 이력서 보기
    const showUserResumeModal = (userId) => {
      userMatchingResumeModal.value.init(false);
      userMatchingResumeModal.value.retrieveRecordDetail(userId);
      userMatchingResumeModal.value.show();
    }

    // 채용 이력서 보기
    const showMatchingResumeModal = (resumeId) => {
      userMatchingResumeModal.value.init(false);
      userMatchingResumeModal.value.retrieveUserMatchingResumeDetail(resumeId, 0);
      userMatchingResumeModal.value.show();
    }

    // 채용 제안서 보기
    const showProposalContentModal = async (propId) => {
      proposalContentModal.value.retrieveProposalContent(propId);
      await proposalContentModal.value.show();
    };

    // e: 채용 진행 현황

    // 목록 버튼
    const goBack = () => {
        //router.push({name:'LectureCourseList'})
      router.go(-1);
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>{{ corporation.corpName }} 기업 정보</h3>
                </div>
                <div class="btn-wrap space-between mb-xs">
                    <div>
                        <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                    </div>
                </div>
                <KSATab style="grid-template-columns: repeat(8, 1fr);" class="mb-lg"
                        :options="[
                            {label:'기본 정보', value:1},
                            {label:'재직자 가입 현황', value:2},
                            {label:'채용 진행 현황', value:3},
                        ]"
                        @change="onSelectTab"
                />

                <!-- s: 기본 정보 -->
                <loading
                    :active="!loaded"
                    loader="dots"
                    color="#3843AF"
                    opacity="0.5"
                    width="35"
                    :is-full-page="false"></loading>
                <div v-show="tabIndex === 1 && loaded">
                    <div class="table-form">
                        <table class="vertical">
                          <colgroup>
                            <col width="200px" />
                            <col />
                          </colgroup>
                          <tr>
                            <th>기업명</th>
                            <td>{{ corporation.corpName }}</td>
                          </tr>
                          <tr>
                            <th>사업자등록번호</th>
                            <td>{{ $bizNum(corporation.bizNum) }}</td>
                          </tr>
                          <tr>
                            <th>최초 가입일</th>
                            <td>{{ $yyyymmddHHmmss(corporation.createDate) }}</td>
                          </tr>
                        </table>
                    </div>
                </div>
                <!-- e: 기본 정보 -->

                <!-- s: 재직자 가입 현황-->
                <loading
                    :active="!loaded"
                    loader="dots"
                    color="#3843AF"
                    opacity="0.5"
                    width="35"
                    :is-full-page="false"></loading>
                <div v-show="tabIndex === 2 && loaded">
                    <!-- s: 검색 -->
                    <div class="filter-wrap" :class="{'open': searchOpen2}">
                        <p @click="searchOpen2 = !searchOpen2">검색</p>
                        <div class="filter">
                          <dl >
                            <dt style="display:block;"><span>직무</span><br><small style="font-weight: normal;">복수 선택 가능</small></dt>
                            <dd>
                              <KSACheckBoxGroup
                                  ref="desiredJobListRef"
                                  className="mr-md"
                                  name="desiredJobList"
                                  v-model="searchInput2.desiredJobList"
                                  :options="desiredJob" />
                            </dd>
                          </dl>
                          <dl>
                            <dt style="display:block;"><span>관심 카테고리</span><br><small style="font-weight: normal;">복수 선택 가능</small></dt>
                            <dd>
                              <KSACheckBoxGroup
                                  ref="interestsListRef2"
                                  className="mr-md"
                                  name="interestsList"
                                  v-model="searchInput2.interestsList"
                                  :options="interests" />
                            </dd>
                          </dl>
                          <dl>
                            <dt>성별</dt>
                            <dd>
                              <template v-for="group in sexGroup" key="item.value" >
                                <KSARadioButton class="mr-md" :label="group.label" v-model="searchInput2.sex" :value="group.value" />
                              </template>
                            </dd>
                          </dl>
                          <dl>
                            <dt>마스터 회원</dt>
                            <dd>
                              <KSACheckBox label="마스터 회원 보기" v-model="searchInput2.corpMasterYn" />
                            </dd>
                          </dl>
                          <!-- 재직자명,아이디,핸드폰번호뒷자리,이메일 검색 -->
                          <dl>
                            <dt>
                              <KSASelect :options="searchTypeGroup2" class="select" @selectOption="onSelectSearchTypeGroup"/>
                            </dt>
                            <dd>
                              <KSAInput class="text-box" v-model="searchInput2.searchInput" :placeholder="searchInputPlaceholder2" />
                            </dd>
                          </dl>
                          <!-- 가입일,최근 접속일,모든기간,기간검색 -->
                          <dl>
                            <dt>
                              <KSASelect ref="searchDurationGbRef" :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                            </dt>
                            <dd class="text-box">
                              <div class="grid">
                                <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                  <KSARadioButton class="mr-md" name="searchDurationGb2" :label="group.label" v-model="searchInput2.searchDurationType" :value="group.value" />
                                </template>
                                <div style="display: inline-block;" v-show="searchInput2.searchDurationType !== 'all'">
                                  <KSADatepickerTerms
                                      :startDate="searchInput2.startYmd"
                                      :endDate="searchInput2.endYmd"
                                      @update:startDate="(value)=> searchInput2.startYmd = value"
                                      @update:endDate="(value)=> searchInput2.endYmd = value"
                                  />
                                </div>
                              </div>
                            </dd>
                          </dl>
                          <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                          </div>
                        </div>
                    </div>
                    <!-- e: 검색 -->
                    <div class="list-top">
                      <p>전체 <span>{{ $number(totalCount)}}</span>명</p>
                      <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                    <div class="table-form">
                        <table class="horizontal" v-if="corpEmpList !== null">
                          <tr>
                            <th>NO</th>
                            <th>가입일</th>
                            <th>최근 접속일</th>
                            <th>재직자명</th>
                            <th>아이디</th>
                            <th>핸드폰번호</th>
                            <th>이메일</th>
                            <th>마스터구분</th>
                            <th>마스터 권한 변경</th>
                          </tr>
                          <tr v-for="corpEmp in corpEmpList">
                            <td>{{ corpEmp.boardNo }}</td>
                            <td>{{ $yyyymmddHHmmss(corpEmp.createDate) }}</td>
                            <td>{{ $yyyymmddHHmmss(corpEmp.lastLoginDate) }}</td>
                            <td class="link text"><RouterLink :to="{name:'MemberDetail', params:{id: corpEmp.userId}}">{{ corpEmp.userName }}</RouterLink></td>
                            <td>{{ corpEmp.userLoginId }}</td>
                            <td>{{ $phoneNum(corpEmp.phoneNum) }}</td>
                            <td>{{ corpEmp.email }}</td>
                            <td>
                              <span v-if="corpEmp.corpMasterYn === 'Y'">마스터</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="corpEmp.corpMasterYn === 'Y'" @click="changeAdminCorpMasterYn(corpEmp.userId, '')" class="link text" style="color: red;">[마스터 권한 취소]</span>
                              <span v-else @click="changeAdminCorpMasterYn(corpEmp.userId, 'Y')" class="link text">[마스터 권한 부여]</span>
                            </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="corpEmpList.length === 0">
                          가입한 재직자가 없습니다.
                        </div>
                        <Paginate
                            v-if="corpEmpList.length > 0"
                            v-model="currentPage"
                            :page-count="totalPages"
                            :click-handler="onSelectPage"
                        />
                    </div>
                </div>
                <!-- e: 재직자 가입 현황 -->

                <!-- s : 채용 진행 현황 -->
                <loading
                    :active="!loaded"
                    loader="dots"
                    color="#3843AF"
                    opacity="0.5"
                    width="35"
                    :is-full-page="false"></loading>
                <div v-show="tabIndex === 3 && loaded">
                  <!-- s: 전체 현황 -->
                  <div class="table-form">
                    <table class="horizontal all-border">
                      <tr class="tr-focus-top tr-focus-side">
                        <th rowspan="2">전체 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr class="tr-focus-side tr-focus-bottom">
                        <td>{{ resultCount.totalState }}</td>
                        <td>{{ resultCount.totalState01 }}</td>
                        <td>{{ resultCount.totalState02 }}</td>
                        <td>{{ resultCount.totalState03 }}</td>
                        <td>{{ resultCount.totalState04 }}</td>
                        <td>{{ resultCount.totalState05 }}</td>
                        <td>{{ resultCount.totalState06 }}</td>
                        <td>{{ resultCount.totalState07 }}</td>
                        <td>{{ resultCount.totalState08 }}</td>
                      </tr>
                      <tr>
                        <th rowspan="2">인재 채용 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr>
                        <td>{{ resultCount.totalResState }}</td>
                        <td>{{ resultCount.resState01 }}</td>
                        <td>{{ resultCount.resState02 }}</td>
                        <td>{{ resultCount.resState03 }}</td>
                        <td>{{ resultCount.resState04 }}</td>
                        <td>{{ resultCount.resState05 }}</td>
                        <td>{{ resultCount.resState06 }}</td>
                        <td>{{ resultCount.resState07 }}</td>
                        <td>{{ resultCount.resState08 }}</td>
                      </tr>
                      <tr>
                        <th rowspan="2">채용 제안 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr>
                        <td>{{ resultCount.totalPropState }}</td>
                        <td>{{ resultCount.propState01 }}</td>
                        <td>{{ resultCount.propState02 }}</td>
                        <td>{{ resultCount.propState03 }}</td>
                        <td>{{ resultCount.propState04 }}</td>
                        <td>{{ resultCount.propState05 }}</td>
                        <td>{{ resultCount.propState06 }}</td>
                        <td>{{ resultCount.propState07 }}</td>
                        <td>{{ resultCount.propState08 }}</td>
                      </tr>
                    </table>
                  </div>
                  <!-- e: 전체 현황 -->
                  <br>
                  <!-- s: 검색 -->
                  <div class="filter-wrap" :class="{'open': searchOpen3}">
                    <p @click="searchOpen3 = !searchOpen3">검색</p>
                    <div class="filter">
                      <dl>
                        <dt style="display:block;"><span>상태</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="stateGbsListRef"
                              className="mr-md"
                              name="stateGbsList"
                              v-model="searchInput3.stateGbsList"
                              :options="corpState" />
                        </dd>
                      </dl>
                      <dl>
                        <dt style="display:block;"><span>관심 카테고리</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="interestsListRef3"
                              className="mr-md"
                              name="interestsList"
                              v-model="searchInput3.interestsList"
                              :options="interests" />
                        </dd>
                      </dl>
                      <dl>
                        <dt>경로 구분</dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="matchingGbListRef"
                              className="mr-md"
                              name="matchingGbList"
                              v-model="searchInput3.matchingGbList"
                              :options="GroupMatching" />
                        </dd>
                      </dl>
                      <dl>
                        <dt>채용제안일</dt>
                        <dd class="text-box">
                          <div class="grid">
                            <template v-for="group in searchDurationTypeGroup" key="item.value" >
                              <KSARadioButton class="mr-md" name="searchDurationGb3" :label="group.label" v-model="searchInput3.searchDurationType" :value="group.value" />
                            </template>
                            <div style="display: inline-block;" v-show="searchInput3.searchDurationType !== 'all'">
                              <KSADatepickerTerms
                                  :startDate="searchInput3.startYmd"
                                  :endDate="searchInput3.endYmd"
                                  @update:startDate="(value)=> searchInput3.startYmd = value"
                                  @update:endDate="(value)=> searchInput3.endYmd = value"
                              />
                            </div>
                          </div>
                        </dd>
                      </dl>
                      <!-- 구직자명, 마스터명 검색 -->
                      <dl>
                        <dt>
                          <KSASelect :options="searchTypeGroup3" class="select" @selectOption="onSelectSearchTypeGroup"/>
                        </dt>
                        <dd>
                          <KSAInput class="text-box" v-model="searchInput3.searchInput" :placeholder="searchInputPlaceholder3" />
                        </dd>
                      </dl>
                      <div class="btn-wrap">
                        <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                        <KSAPrimaryButton text="검색" @click="onClickSearch" />
                      </div>
                    </div>
                  </div>
                  <!-- e: 검색 -->
                  <div class="list-top">
                    <p>전체 <span>{{ $number(totalCount)}}</span>건</p>
                    <div>
                      <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                  </div>

                  <div class="table-form">
                    <table class="horizontal" v-if="corpMatchingList !== null">
                      <tr>
                        <th>NO</th>
                        <th>채용 제안일</th>
                        <th>경로 구분</th>
                        <th>제안 마스터</th>
                        <th>구직자명</th>
                        <th>관심 카테고리</th>
                        <th>이력서 보기</th>
                        <th>채용 제안서 보기</th>
                        <th>상태</th>
                        <th>상태 변경</th>
                      </tr>
                      <tr v-for="corpMatching in corpMatchingList">
                        <td>{{ corpMatching.boardNo }}</td>
                        <td>{{ $yyyymmddHHmmss(corpMatching.createDate) }}</td>
                        <td>
                          <span v-if="corpMatching.matchingGb === '01'">채용 제안</span>
                          <span v-else>인재 채용</span>
                        </td>
                        <td class="link text"><RouterLink :to="{name:'MemberDetail', params:{id: corpMatching.corpUserId}}">{{ corpMatching.corpUserName }}</RouterLink></td>
                        <td class="link text"><RouterLink :to="{name:'MemberDetail', params:{id: corpMatching.userId}}">{{ corpMatching.userName }}</RouterLink></td>
                        <td>{{ corpMatching.interests }}</td>
                        <td class="link text">
                          <span v-if="corpMatching.resumeId !== 0" @click="showMatchingResumeModal(corpMatching.resumeId)">이력서 보기</span>
                          <span v-else @click="showUserResumeModal(corpMatching.userId)">이력서 보기</span>
                        </td>
                        <td>
                          <span v-if="corpMatching.propId !== 0" @click="showProposalContentModal(corpMatching.propId)" class="link text">제안서 보기</span>
                          <span v-else>-</span>
                        </td>
                        <td>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB', corpMatching.stateGb) }}</td>
                        <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                        <td>
                          <span v-if="corpMatching.stateGb === '01'" data-title="상태 변경 : ">
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '02')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '02', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="gray_button md_btn"/>
                          </span>
                          <span v-else-if="corpMatching.stateGb === '03'" data-title="상태 변경 : ">
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '05', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="green_button md_btn"/>
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '06')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '06', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="orange_button md_btn"/>
                          </span>
                          <span v-else-if="corpMatching.stateGb === '05'" data-title="상태 변경 : ">
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '07', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="blue_button md_btn"/>
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '08', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="red_button md_btn"/>
                          </span>
                          <span v-else-if="corpMatching.stateGb === '06'" data-title="상태 변경 : ">
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '05', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="green_button md_btn"/>
                          </span>
                          <span v-else-if="corpMatching.stateGb === '07'" data-title="상태 변경 : ">
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '08', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="red_button md_btn"/>
                          </span>
                          <span v-else-if="corpMatching.stateGb === '08'" data-title="상태 변경 : ">
                            <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '07', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="blue_button md_btn"/>
                          </span>
                          <span v-else data-title="상태 변경 : ">
                            -
                          </span>
                        </td>
                      </tr>
                    </table>
                    <div class="result-none" v-if="corpMatchingList.length === 0">
                      진행중인 채용 건이 없습니다.
                    </div>
                  </div>
                  <Paginate
                      v-if="corpMatchingList.length > 0"
                      v-model="currentPage"
                      :page-count="totalPages"
                      :click-handler="onSelectPage"
                  />
                </div>
                <!-- e: 채용 진행 현황 -->
            </div>
        </div>
    </div>
    <MypageProposalContentModal ref="proposalContentModal"/>
    <MyPageResumeModal ref="userMatchingResumeModal"/>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .textarea-box {
        height: 100px;
    }
</style>