<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>KSA란?</h3>
            </div>
            <div class="ksa-wrap">
                <div class="ksa-item1">
                    <img src="/src/assets/images/ksa_bg.png" />
                    <div class="item-inner">
                        <div>
                            <h5>VISION</h5>
                            <p>
                                반도체 초격차를 위한<br>
                                산업계 주도의<br>
                                인력 양성 컨트롤 타워
                            </p>
                        </div>
                        <div>
                            <h5>목표</h5>
                            <p>
                                산업계 수요 기반 교육과정<br>
                                단계별로 운영,<br>
                                현장에 필요한 인력을<br>
                                신속히 양성
                            </p>
                        </div>
                    </div>
                </div>
                <div class="ksa-item2">
                    <h4>한국 반도체 아카데미 4대 추진방향</h4>
                    <div class="item-inner">
                        <div>
                            <img src="/src/assets/images/ksa_img1.png" />
                            <b>연대</b>
                            <p>
                                소자 기업과 소부장,<br>
                                설계 기업이 함께 인력을 양성
                            </p>
                        </div>
                        <div>
                            <img src="/src/assets/images/ksa_img2.png" />
                            <b>협력</b>
                            <p>
                                대학과 연계하여 산업계를<br>
                                이끌 수 있는 인재 육성
                            </p>
                        </div>
                        <div>
                            <img src="/src/assets/images/ksa_img3.png" />
                            <b>교류</b>
                            <p>
                                우수 역량 확보와<br>
                                핵심 인재 유입 체계 마련
                            </p>
                        </div>
                        <div>
                            <img src="/src/assets/images/ksa_img4.png" />
                            <b>생태계</b>
                            <p>
                                반도체 인적자원의<br>
                                Value Chain 확립
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          <br><br><br>
            <div class="title page-title pb-lg">
                <h4>아카데미 교육 개요</h4>
            </div>

            <img width="100%" src="/src/assets/images/ksa_academy.jpg" alt="아카데미 교육 개요" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .ksa-item1 {
        position: relative;
        margin-bottom: 80px;
        .item-inner {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            top: 50%;
            left: 50%;
            max-width: 640px;
            width: 100%;
            transform: translate3d(-50%, -50%, 0);
            div {
                width: 280px;
                height: 280px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.2);
                border: 1px solid rgba(255, 255, 255, 0.5);
                color: #fff;
                text-align: center;
                h5 {
                    position: relative;
                    @include font-size_xl;
                    padding-bottom: 10px;
                    margin-top: 58px;
                    margin-bottom: 10px;
                    &:after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: 0px;
                        width: 30px;
                        height: 1px;
                        background-color: #fff;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
    .ksa-item2 {
        h4 {
            text-align: center;
            @include font-size_xl;
            margin-bottom: 38px;
        }
        .item-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            div {
                margin-left:140px;
                &:first-child {
                    margin-left: 0;
                }
                b {
                    display: block;
                    @include font-size_md;
                    margin: 30px 0 10px;
                }
            }
        }
    }

</style>