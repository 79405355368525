<script setup>
    import { ref, onMounted, computed, getCurrentInstance, inject } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import mypageUserResumeService from "../../../services/mypage/mypageUserResumeService";
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import UserMatchingResumeCreateModal from "../../../components/modal/mypage/UserMatchingResumeCreateModal.vue";
    import MyPageResumeModal from "../../../components/modal/mypage/MyPageResumeModal.vue";
    import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
    import KSASecondaryButton from "../../../../../components/ui-components/button/KSASecondaryButton.vue";
    import mypageMatchingService from "../../../services/mypage/mypageMatchingService";
    import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";

    const globals = getCurrentInstance().appContext.config.globalProperties;
    const getSystemCodeValues = inject('systemCodeValues');

    const { $pageListPorpId, $gb, $gbNameKR } = globals;
    const userState = ref(globals.$userState)

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const matchingUpdateStore = useMatchingUpdateStore()

    const resStateGbListRef = ref(null)
    const list = ref([]);
    const resumeDetail = ref({})
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    const snbKey = ref(0);

    const searchOpen = ref(true)
    const _searchInput = ref({
        searchResStateGbs: [],
    });

    const resumeId = ref(0);

    // 모달
    const modal = ref(null);
    const confirmModal = ref(null);
    const userMatchingResumeCreateModal = ref(null);
    const userMatchingResumeModal = ref(null);
    const modalContent = ref([]);
    const okButtonText = ref("동의");
    const cancelButtonText = ref("동의하지 않음");


    onMounted(() => {
        const { id } = route.params;
        resumeId.value = id;

        if(!id) {
            router.push({name: 'UserMatchingResumeProposalList'})
        } else {
            retrieveMatchingResume(id, 0);
            retrieveList(id);
        }
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1;
    });

    const retrieveMatchingResume = (id, hidden) => {
        loadingStore.loading = true;
      let paramObj = {
        resumeId: id,
        hidden: hidden,
      }
        mypageUserResumeService.retrieveMatchingResume(paramObj)
            .then(( res ) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                resumeDetail.value = res.data.result;
              }
            }).catch( (error) => {
              loadingStore.loading = false;
              console.log(error);
        });
    }

    // 초기화 버튼
    // 검색조건 - 이력서 전송일 (0 전체 기간, 1 최근 1개월, 2 최근 2개월, 3 최근 3개월)
    const clearSearchInput = () => {
      resStateGbListRef.value.initOptions()
      _searchInput.value = {
        searchResStateGbs: ['01', '03', '04', '05', '06', '07', '08'],
      };
    }

    const retrieveList =async (id) => {

        const resStateGbs = _.map(_searchInput.value.searchResStateGbs).join(',');

        let paramObj = {
            resumeId: id,
            pageNumber: currentPage.value,
            size: pageSize,
            searchResStateGbs: resStateGbs
        }
        loadingStore.loading = true;
        await getSystemCodeValues()
        mypageUserResumeService.retrieveUserMatchingCorporationList(paramObj)
            .then (( res ) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              // console.log(result);
              totalPages.value = result.totalPages;
              totalCount.value = result.totalElements;
              list.value = $pageListPorpId(result.content, totalCount.value, pageSize, currentPage.value);
            } else {
              console.log(res);
            }
            return res;
        }).catch( (error) => {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList(resumeId);
    }

    // 이력서 보기
    const onClickUserMatchingResumeDetail = (resumeId) => {
        userMatchingResumeModal.value.init(false);
        userMatchingResumeModal.value.retrieveUserMatchingResumeDetail(resumeId, 0);
        userMatchingResumeModal.value.show();
    }

    // 노출여부 변경
    const onClickChangeResOpenYn = async (resumeId, resOpenYn) => {

        if ( resOpenYn === "Y" ) {
            modalContent.value = ["인재 노출할까요?", "노출 시 기업 인사 담당자에게 이력서가 노출됩니다."];
            okButtonText.value = "노출";
            cancelButtonText.value = "취소";
        } else {
            modalContent.value = ["인재 노출을 중지할까요?", "중지 시 기업 인사 담당자에게 이력서가 노출되지 않습니다."];
            okButtonText.value = "중지";
            cancelButtonText.value = "취소";
        }

        const ok = await confirmModal.value.show();

        if ( ok ) {

            let paramObj = {
                resumeId: resumeId,
                resOpenYn: resOpenYn
            }

            loadingStore.loading = true;
            mypageUserResumeService.changeResOpenYnState(paramObj)
                .then((res) => {
                    loadingStore.loading = false;
                    if (res.data.code.toLowerCase() === 'success') {
                        resumeDetail.value.resOpenYn = resOpenYn;
                        retrieveList(resumeId);
                    }
                }).catch((error) => {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }

    // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
    const retrieveMatchingUpdate = async () => {
      await mypageMatchingService.retrieveMatchingUpdate()
          .then( async (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              matchingUpdateStore.matchingUpdate = result;
              await matchingUpdateStore.setMatchingUpdate( result );
              matchingUpdateStore.value = await useMatchingUpdateStore();
              // SNB.vue도 같이 동적으로 리로드 되도록
              snbKey.value += 1;
            }
          }).catch( (error) => {
            loadingStore.loading = false;
            console.log(error);
          });
    }

    // 채용제안 수락/거절
    const onClickChangeResState = async (resumeId, corpId, resStateGb) => {

        if ( resStateGb === "03" ) {
            modalContent.value = ["채용 제안을 수락하시겠습니까?", "‘매칭완료’로 상태가 변경됩니다."];
            okButtonText.value = "제안 수락";
        } else if ( resStateGb === "04" ) {
            modalContent.value = ["채용 제안을 거절하시겠습니까?", "‘매칭중단’으로 상태가 변경됩니다."];
            okButtonText.value = "제안 거절";
        }
        cancelButtonText.value = "취소";

        const ok = await confirmModal.value.show();

        if ( ok ) {

            let paramObj2 = {
                resumeId: resumeId,
                corpId: corpId,
                resStateGb: resStateGb
            }

            loadingStore.loading = true;
            mypageUserResumeService.changeResumeCorporationStateForStudent(paramObj2)
                .then( async (res) => {
                    if(res.data.code.toLowerCase() === 'success') {
                        modalContent.value = ["변경하였습니다."];
                        modal.value.show();
                        retrieveList(resumeId);
                        // 매칭 상태값 업데이트
                        await retrieveMatchingUpdate();
                    } else {
                      console.log(res);
                    }
                    loadingStore.loading = false;
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });

        }

    }

</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB :key="snbKey" class="snb" />
                <div class="content">
                    <KSATextButton text="입사 지원 현황" @click="router.push({name:'UserMatchingResumeProposalList'})" style="color: blue;margin-bottom: 20px;"/>
                    <div class="sub-title">
                        <h3>매칭 희망 기업</h3>
                    </div>
                    <div class="filter-wrap" :class="{'open': searchOpen}">
                        <p @click="searchOpen = !searchOpen">검색</p>
                        <div class="filter">
                            <dl>
                                <dt>상태</dt>
                                <dd>
                                    <KSACheckBoxGroup
                                        ref="resStateGbListRef"
                                        className="mr-md"
                                        name="searchResStateGbs"
                                        v-model="_searchInput.searchResStateGbs"
                                        :options="userState" />
                                </dd>
                            </dl>
                            <div class="btn-wrap">
                                <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                <KSAPrimaryButton text="검색" @click="retrieveList(resumeId)" />
                            </div>
                        </div>
                    </div>
                    <div class="list-top">
                        <p>총 <span>{{ $number(totalCount) }}</span>개의 기업이 매칭 희망합니다.</p>
                        <div class="search-box">
                            <img v-if="resumeDetail.resOpenYn === 'Y'" src="../../../../../assets/images/toggle_on.png" @click="onClickChangeResOpenYn(resumeDetail.resumeId, 'N')" style="cursor:pointer; height:24px; margin: 12px 0;" />
                            <img v-else src="../../../../../assets/images/toggle_off.png" @click="onClickChangeResOpenYn(resumeDetail.resumeId, 'Y')" style="cursor:pointer; height:24px; margin: 12px 0;" />
                            <span style="margin: 10px 20px 10px 10px;">인재노출</span>
                            <KSAPrimaryButton text="이력서 보기" @click="onClickUserMatchingResumeDetail(resumeId)" />
                        </div>
                    </div>
                    <div class="table-form board-mobile-vertical">
                        <table>
                            <tr>
                                <th>기업명</th>
                                <th>담당자</th>
                                <th>상태</th>
                                <th>제안 수락/거절</th>
                            </tr>
                            <tr v-for="(matchingCorp) in list">
                                <td data-title="기업명 : ">{{ matchingCorp.corpName }}</td>
                                <td data-title="담당자 : ">{{ matchingCorp.userName }}</td>
                                <td data-title="상태 : ">
                                  <span>
                                    <span>
                                      <span v-if="matchingCorp.resStateGb === '01'" class="red-dot"></span>
                                      {{ $gbNameKR('MATCHING_JOB_STATE_USER_GB', matchingCorp.resStateGb) }}
                                    </span>
                                    <br>
                                    <small style="color: #86889A">{{ $gbNameKR('MATCHING_JOB_STATE_USER_GB2', matchingCorp.resStateGb) }}</small>
                                  </span>
                                </td>
                                <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                                <td v-if="matchingCorp.resStateGb === '01'" data-title="제안 수락/거절 : ">
                                  <KSASecondaryButton text="제안수락" @click="onClickChangeResState(matchingCorp.resumeId, matchingCorp.corpId, '03')" class="blue_button md_btn"/>
                                  <KSASecondaryButton text="제안거절" @click="onClickChangeResState(matchingCorp.resumeId, matchingCorp.corpId, '04')" class="red_button md_btn"/>
                                </td>
                                <td v-else-if="matchingCorp.resStateGb === '03'" data-title="제안 수락/거절 : ">
                                  <KSASecondaryButton text="제안거절" @click="onClickChangeResState(matchingCorp.resumeId, matchingCorp.corpId, '04')" class="red_button md_btn"/>
                                </td>
                                <td v-else-if="matchingCorp.resStateGb === '04'" data-title="제안 수락/거절 : ">
                                  <KSASecondaryButton text="제안수락" @click="onClickChangeResState(matchingCorp.resumeId, matchingCorp.corpId, '03')" class="blue_button md_btn"/>
                                </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="list.length === 0">
                            검색 결과가 없습니다.
                        </div>
                    </div>
                    <Paginate
                        v-if="list.length > 0"
                        v-model="currentPage"
                        :page-count="totalPages"
                        :click-handler="onSelectedPage"
                    />
                </div>
            </div>
        </div>
    </div>
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" :cancelButtonText="cancelButtonText" />
    <UserMatchingResumeCreateModal ref="userMatchingResumeCreateModal"/>
    <MyPageResumeModal ref="userMatchingResumeModal"/>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    /* Filter */
    .filter-wrap {
        margin-bottom: 40px;
        &.open {
            p {
                &:before {
                    transition-duration: 0.2s;
                    -webkit-transform: translateY(-50%) rotate(225deg);
                    transform: translateY(-50%) rotate(225deg);
                }
            }
            .filter {
                display: block;
                border-bottom: $border_line;
            }
        }
        p {
            position: relative;
            @include font-size_md;
            background: $gray-light_color;
            border: $border_line;
            font-weight: 700;
            cursor: pointer;
            padding: 10px 20px;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 20px;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 1px solid #000000;
                border-left: 1px solid #000000;
                -webkit-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg);
                transition-duration: 0.2s;
            }
        }
        .filter {
            display: none;
            border-left: $border_line;
            border-right: $border_line;
            dl {
                display: flex;
                align-items: center;
                dt {
                    display: flex;
                    align-items: center;
                    width: 180px;
                    height: 48px;
                    font-weight: 700;
                    padding: 10px 10px 10px 20px;
                    .select {
                        font-weight: initial;
                    }
                }
                dd {
                    width: calc(100% - 180px);
                    padding: 10px 20px 10px 0;
                    .grid {
                        span {
                            margin: 0 4px;
                        }
                    }
                }
                &:first-of-type {
                    padding-top: 10px;
                }
                &:last-of-type {
                    padding-bottom: 10px;
                }
            }
            .btn-wrap {
                border-top: $border_line;
                padding: 10px 20px;
                margin: 0;
                button {
                    margin: 0 5px;
                }
            }
            .text-box {
                margin-top: 0;
            }
        }
    }

    .blueTextButton { color: $blue_color; }
    .redTextButton { color: $red_color; }
    .grayTextButton { color: $gray_color; }
</style>