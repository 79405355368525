<script setup>
    import { ref, watch, onMounted, getCurrentInstance } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import lectureService from "../../services/lecture/lectureService";
    import _ from 'lodash'
    import moment from "moment";
    import {useLectureStore} from "../../stores/lecture/lectureStore";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const lectureStore = useLectureStore()
    const { $toast, $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveLectureList()
    })

    const defaultDownloadCount = 500;  // 500

    let disabledWatchIsSelectedAll = false;
    let disabledWatchList = false;

    const isSelectedAll = ref(false);
    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(list.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            list.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    const list = ref([])
    watch(list, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    const searchOpen = ref(true)

    const searchInput = ref({
        lectGb: '00',
        lectOpenYn: 'A',
        repreLectYn: false,
        lectName: '',
        searchDurationGb: 'C',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    })

    const searchDurationGbRef = ref(null)

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const lectGbGroup = ref([
        { label:"전체", value: "00" },
        { label:"온라인", value: "01" },
        { label:"오프라인", value: "02" },
    ]);

    const lectOpenYnGroup = ref([
        { label:"전체", value: "A" },
        { label:"공개", value: "Y" },
        { label:"비공개", value: "N" },
    ]);

    const searchDurationGbGroup = ref([
        { label:"등록일", value: "C" },
        { label:"최근 수정일", value: "U" },
    ])

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])
    const okButtonText = ref("메일발송")

    const retrieveLectureList = () => {

        let paramObj = {
            lectGb: searchInput.value.lectGb,
            lectOpenYn: searchInput.value.lectOpenYn,
            repreLectYn: searchInput.value.repreLectYn ? 'Y' : 'N',
            lectName: searchInput.value.lectName,
            searchDurationGb: searchInput.value.searchDurationGb,
            startYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.startYmd,
            endYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        lectureService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                    _.each(list.value, lect => {
                        lect.checked = false;
                    })
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        searchInput.value.lectGb = '00';
        searchInput.value.lectOpenYn = 'A';
        searchInput.value.repreLectYn = false;
        searchInput.value.lectName = '';
        searchInput.value.searchDurationGb = 'C';
        searchInput.value.searchDurationType = 'all';

        searchDurationGbRef.value.initSelectedOption(searchInput.value.searchDurationGb)
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveLectureList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveLectureList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveLectureList();
    }

    const onClickLectOpenYn = (index) => {
        const checkedList =_.filter(list.value, { checked: true});
        const lectIds = _.map(checkedList, 'lectId');
        // console.log(lectIds);
        if(lectIds.length > 0) {
            const lectOpenYn = index === 0 ? 'Y' : 'N';
            loadingStore.loading = true;
            lectureService.updateLectOpenYn(lectIds, lectOpenYn)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        $toast('', `${lectOpenYn === 'Y' ? '공개' : '비공개'}로 변경하였습니다.`);

                        _.each(checkedList, lect => {
                            lect.lectOpenYn = lectOpenYn
                            lect.checked = false;
                        });
                    } else {
                        $toast('error', '상태 변경을 실패하였습니다.');
                    }


                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                $toast('error', '상태 변경을 실패하였습니다.');

            });
        } else {
            modalContent.value = ['상태를 변경할 강의를 선택하세요.'];
            modal.value.show();
        }
    }

    // 강의목록 - 엑셀 다운로드 버튼 이벤트
    const onClickExcelDownload = async () => {
        if(totalCount.value > 0 && totalCount.value < defaultDownloadCount) {
            modalContent.value = ["강의목록을 내 PC로 다운로드할까요?"];
            okButtonText.value = "다운로드";
            const ok = await confirmModal.value.show();
            if(ok){
                downloadLectureList();
            }
        } else if(totalCount.value >= defaultDownloadCount){
            modalContent.value = ["[500건 이상] 강의목록을 이메일로 발송할까요?"];
            okButtonText.value = "메일발송";
            const ok = await confirmModal.value.show();
            if(ok){
                sendEmailLectureList();
            }
        } else {
            modalContent.value = ["다운로드 받을 '강의목록'이 존재하지 않습니다."];
            modal.value.show();
            return false;
        }
    }

    // 강의 목록 다운로드
    const downloadLectureList = () => {
        let paramObj = {
            lectGb: searchInput.value.lectGb,
            lectOpenYn: searchInput.value.lectOpenYn,
            repreLectYn: searchInput.value.repreLectYn ? 'Y' : 'N',
            lectName: searchInput.value.lectName,
            searchDurationGb: searchInput.value.searchDurationGb,
            startYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.startYmd,
            endYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }
        $toast('', `다운로드 데이터 생성을 시작합니다.`);
        loadingStore.loading = true;
        lectureService.downloadLectureList(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                try {
                    const href = URL.createObjectURL(res.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `${moment().format("YYYYMMDD")}_강의리스트.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } catch(e) {
                    console.error('다운로드 파일 처리 중 오류가 발생했습니다:', e);
                }
            }).catch( (err) =>  {
                loadingStore.loading = false;
                let errorMessage = '다운로드 데이터 생성 중 오류가 발생했습니다.';
                // Blob 응답 처리
                err.response.data.text()  // Blob을 텍스트로 변환
                    .then(text => {
                        try {
                            // 텍스트를 JSON으로 파싱
                            const responseData = JSON.parse(text);
                            // 메시지 추출
                            if (responseData?.result?.message) {
                                errorMessage = responseData.result.message;
                            }
                        } catch (parseError) {
                            console.error('에러 메시지 파싱 중 오류가 발생했습니다.:', parseError);
                        }
                        // Toast에 에러 메시지 표시
                        $toast('error', errorMessage);
                    });
        });
    }

    // 강의 목록 이메일 발송
    const sendEmailLectureList = () => {
        let paramObj = {
            lectGb: searchInput.value.lectGb,
            lectOpenYn: searchInput.value.lectOpenYn,
            repreLectYn: searchInput.value.repreLectYn ? 'Y' : 'N',
            lectName: searchInput.value.lectName,
            searchDurationGb: searchInput.value.searchDurationGb,
            startYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.startYmd,
            endYmd: searchInput.value.searchDurationType === 'all' ? "" : searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }
        lectureService.sendEmailLectureList(paramObj)
            .then( (res) => {
              $toast('', `이메일 발송 데이터 생성을 시작합니다.`);
            }).catch( (err) =>  {
              let errorMessage = '이메일 발송 중 오류가 발생했습니다.';
              try {
                  const responseData = err.response?.data;
                  if (responseData?.result?.message) {
                      errorMessage = responseData.result.message;
                  }
              } catch (parseError) {
                  console.error('에러 메시지 추출 중 오류가 발생했습니다.:', parseError);
              }
              // Toast에 에러 메시지 표시
              $toast('error', errorMessage);
        });
    }

    const onClickAddLecture = (index) => {
        if(index === 0) {
            addLecture('01');
        } else if(index === 1) {
            addLecture('02');
        } else {
            router.push({name:'LectureCourseCreate'});
        }
    }

    const addLecture = (lectGb) => {
        lectureStore.lectGb = lectGb;
        router.push({name: 'LectureCreate'});
    }

    const onSelectSearchDurationGbGroup = (option) => {
        searchInput.value.searchDurationGb = option.value;
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>강의 목록</h3>
                </div>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen = !searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>구분</dt>
                            <dd>
                                <template v-for="group in lectGbGroup" key="person.name" >
                                    <KSARadioButton class="mr-md" :label="group.label" v-model="searchInput.lectGb" :value="group.value" />
                                </template>
                            </dd>
                        </dl>
                        <dl>
                            <dt>상태</dt>
                            <dd>
                                <template v-for="group in lectOpenYnGroup" key="person.name" >
                                    <KSARadioButton class="mr-md" :label="group.label" v-model="searchInput.lectOpenYn" :value="group.value" />
                                </template>
                            </dd>
                        </dl>
                        <dl>
                            <dt>대표 강의</dt>
                            <dd>
                                <KSACheckBox label="대표 강의 보기" v-model="searchInput.repreLectYn" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>강의명</dt>
                            <dd>
                                <KSAInput class="text-box" v-model="searchInput.lectName" placeholder="강의명을 입력해주세요." />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchDurationGbRef" :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                        <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="searchInput.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="searchInput.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                                :startDate="searchInput.startYmd"
                                                :endDate="searchInput.endYmd"
                                                @update:startDate="(value)=> searchInput.startYmd = value"
                                                @update:endDate="(value)=> searchInput.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <div class="grid">
                        <KSASecondaryButton class="gray_button" text="엑셀 다운로드" @click="onClickExcelDownload" />
                        <KSADropdownButton class="gray_button ml-xs" text="상태 변경" :options="['공개로 변경', '비공개로 변경']" @click="onClickLectOpenYn" />
                        <KSADropdownButton class="gray_button ml-xs" text="등록하기" :options="['온라인 강의 등록하기', '오프라인 강의 등록하기', '차시 등록하기']" @click="onClickAddLecture" />
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ totalCount }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th><KSACheckBox v-model="isSelectedAll"/></th>
                            <th>No</th>
                            <th>강의ID</th>
                            <th>구분</th>
                            <th>상태</th>
                            <th>강의명</th>
                            <th>차시</th>
                            <th>신청인원</th>
                            <th>선발인원</th>
                            <th>수강생수</th>
                            <th>수료자수</th>
                            <th>신청 기간</th>
                            <th>교육 기간</th>
                            <th>최근 수정일</th>
                            <th>등록일</th>
                        </tr>
                        <tr v-for="lect in list">

                            <th><KSACheckBox v-model="lect.checked"/></th>
                            <td>{{ lect.boardNo }}</td>
                            <td>{{ lect.lectId }}</td>
                            <td>{{ lect.lectGb === '01' ? '온라인' : '오프라인' }}</td>
                            <td>{{ lect.lectOpenYn === 'Y' ? '공개' : '비공개' }}</td>
                            <td class="link text-left"><RouterLink :to="{name: 'LectureDetail', params:{id: lect.lectId }}"><p class="text-over">{{ lect.lectName }}</p><span v-if="lect.repreLectYn === 'Y'" class="badge">대표</span></RouterLink><span></span></td>
                            <td>{{ lect.lectureIndexCount }}</td>
                            <td>{{ lect.userLectureCount }}</td>
                            <td>{{ lect.userLectureSelCount }}</td>
                            <td>{{ lect.userLectureStudyCount }}</td>
                            <td>{{ lect.userLecturePassCount }}</td>
                            <td>
                                <span v-if="lect.regTermGb === '01'">상시</span>
                                <span v-else>{{ $yyyymmdd(lect.regStartYmd) }} ~ {{ $yyyymmdd(lect.regEndYmd) }}</span>
                            </td>
                            <td>
                                <span v-if="lect.lectTermGb === '01'">신청일로부터 {{ lect.lectTerm }}일</span>
                                <span v-else>{{ $yyyymmdd(lect.lectStartYmd) }} ~ {{ $yyyymmdd(lect.lectEndYmd) }}</span>
                            </td>
                            <td>{{ $yyyymmddHHmmss(lect.modifyDate) }}</td>
                            <td>{{ $yyyymmddHHmmss(lect.createDate) }}</td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    table {
        td {
            .badge {
                margin-top: -16px;
            }
        }
    }

</style>
