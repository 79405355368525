<script setup>
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import insightsQNAService from "../../services/insights/insightsQNAService";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";

    const router = useRouter();
    const loadingStore = usePageLoadingStore()

    const input = ref({
        title: '',
        content: '',
    });
    const isSecret = ref(false);

    const modal = ref(null)
    const modalContent = ref([])

    const isDisabled = computed(()=> {
        return !input.value.title || !input.value.content;
    });

    const create = () => {
        const { title, content } = input.value;
        if(isDisabled.value) {
            return false;
        }

        let paramObj = {
            title,
            content,
            secretYn: isSecret.value ? "Y" : "N",
        };

        loadingStore.loading = true;
        insightsQNAService.createQNA(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    router.push({ name: 'InsightsQNA'});
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            // console.log(error);
            modalContent.value = ["등록을 실패하였습니다."];
            modal.value.show();
        });
    };

    const cancel = () => {
        router.push({ name: 'InsightsQNA'});
    };
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>Q&amp;A 작성</h3>
                <p>
                    - 질문을 작성하시면 영업일 기준 1~2일 이내에 답변해드립니다.<br>
                    - 모든 정보는 필수입력 사항입니다.
                </p>
            </div>
            <div class="table-form">
                <table class="vertical">
                    <colgroup>
                        <col width="110px" />
                        <col />
                    </colgroup>
                    <tr>
                        <th>제목</th>
                        <td>
                            <KSAInput class="text-box mb-xs" v-model="input.title" placeholder="제목을 입력하세요."/>
                            <KSACheckBox label="비밀글로 설정합니다." v-model="isSecret"  />
                        </td>
                    </tr>
                    <tr>
                        <th>내용</th>
                        <td>
<!--                            <KSATextArea class="textarea-box" v-model="input.content" placeholder="내용을 입력하세요."/>-->
                            <KSACKEditor v-model="input.content" />
                        </td>
                    </tr>
                </table>
            </div>
            <div class="btn-wrap space-between">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton text="등록" @click="create" :disabled="isDisabled"/>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>


</style>
