<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { RouterLink } from 'vue-router'
    import adminUserService from "../../services/user/adminUserService";
    import mypageUserService from "../../../user/services/mypage/mypageUserService";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import _ from "lodash";

    const loadingStore = usePageLoadingStore()
    const { $pageListBoardNo, $toast, $yyyymmddHHmmss, $userGbKR, $gbNameKR, $phoneNum } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveList()
    })

    const searchUserGbTypeRef = ref(null)
    const userGbListRef = ref(null)
    const searchStateGbsRef = ref(null)
    const searchTypeRef = ref(null)

    const list = ref([])

    const searchOpen = ref(true)
    const _searchInput = ref({
        userGbList: ["STUDENT","COMPANY","SCHOOL","TEACHER"],
        searchUserGbType: 'before',
        searchStateGbs: ['01','02','03','04'],
        searchType: 'name', // name 이름, id 아이디, phone 핸드폰뒷4자리, email 이메일, code 회원코드
        searchInput: '',
        searchDurationGb: 'C', // (C 신청일)
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    })

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const searchUserGbTypeGroup = ref([
      { label:"현재 회원 유형", value: "before" },
      { label:"변경 회원 유형", value: "after" },
    ])

    const userGbGroup = ref([
        { label:"학생/취업준비생", value: "STUDENT" },
        { label:"재직자/기업", value: "COMPANY" },
        { label:"대학/연구실", value: "SCHOOL" },
        { label:"강사/전문가", value: "TEACHER" },
    ]);

    const stateGbGroup = ref([
      { label:"신청", value: "01" },
      { label:"취소", value: "02" },
      { label:"승인", value: "03" },
      { label:"거절", value: "04" },
    ])

    const searchTypeGroup = ref([
        { label:"이름", value: "name", placeholder: "이름을 입력해주세요."  },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        { label:"핸드폰번호", value: "phone", placeholder: "‘-’를 제외한 핸드폰번호 뒷4자리를 입력해 주세요."  },
        { label:"이메일", value: "email", placeholder: "이메일을 입력해주세요."  },
        { label:"회원코드", value: "code", placeholder: "회원코드를 입력해주세요." },
    ]);

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: _searchInput.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    // 현재 회원 유형 before  변경 회원 유형 after
    const onSelectSearchUserGbTypeGroup = (option) => {
      _searchInput.value.searchUserGbType = option.value;
    }

    // 이름 name 아이디 id 핸드폰번호 phone 이메일 email 회원코드 code
    const onSelectSearchTypeGroup = (option) => {
      _searchInput.value.searchType = option.value;
    }

    const pageSizeGroup = ref([
        { label:"10개씩 보기", value: "10" },
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
    ])

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])
    const okButtonText = ref("변경")

    const retrieveList = () => {
        let paramObj = {
            searchUserGbType: _searchInput.value.searchUserGbType,
            userGbList: _searchInput.value.userGbList,
            searchStateGbs: _searchInput.value.searchStateGbs,
            searchType: _searchInput.value.searchType,
            searchInput: _searchInput.value.searchInput,
            searchDurationGb: 'C',
            startYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.startYmd,
            endYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.endYmd,
            pageNumber: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        adminUserService.retrieveAllUserGbHistory(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                } else {
                    console.log(res);
                }
            }).catch( (error) =>  {
                console.log(error);
                loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        _searchInput.value.searchUserGbType = 'before'
        _searchInput.value.userGbList = _.map(userGbGroup.value, 'value');
        _searchInput.value.searchStateGbs = _.map(stateGbGroup.value, 'value');
        _searchInput.value.searchType = 'name';
        _searchInput.value.searchInput = '';
        _searchInput.value.searchDurationType = 'all';  // radio button

        searchUserGbTypeRef.value.initSelectedOption(_searchInput.value.searchUserGbType)  // select
        userGbListRef.value.initOptions();  // checkbox
        searchStateGbsRef.value.initOptions();  // checkbox
        searchTypeRef.value.initSelectedOption(_searchInput.value.searchType);  // select
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveList();
    }

    // 회원 유형 변경 상태 변경  - 승인일 경우, user 테이블 usergb도 변경해야함.
    const changeUserGbHistoryState = async (seq, stateGb) => {
      if ( stateGb === '03' ) {
        modalContent.value = ["신청건이 승인 처리됩니다.", "상태를 ‘승인’으로 변경할까요?"];
      } else if ( stateGb === '04' ) {
        modalContent.value = ["신청건이 거절 처리됩니다.", "상태를 ‘거절’로 변경할까요?"];
      }
      okButtonText.value = "변경";

      const ok = await confirmModal.value.show();

      if(!ok) {
        return -1;
      } else {
        let paramObj = {
          seq: seq,
          stateGb: stateGb
        }
        mypageUserService.changeUserGbHistoryState(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveList();
              } else {
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>회원 유형 변경 현황</h3>
                </div>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen = !searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>
                              <KSASelect ref="searchUserGbTypeRef" :options="searchUserGbTypeGroup" class="select" @selectOption="onSelectSearchUserGbTypeGroup"/>
                            </dt>
                            <dd>
                                <KSACheckBoxGroup
                                    ref="userGbListRef"
                                    className="mr-md"
                                    name="userGbList"
                                    v-model="_searchInput.userGbList"
                                    :options="userGbGroup" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                              상태
                            </dt>
                            <dd>
                                <KSACheckBoxGroup
                                    ref="searchStateGbsRef"
                                    className="mr-md"
                                    name="searchStateGbs"
                                    v-model="_searchInput.searchStateGbs"
                                    :options="stateGbGroup" />
                              </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchTypeRef" :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
                            </dt>
                            <dd>
                                <KSAInput class="text-box" v-model="_searchInput.searchInput" :placeholder="searchInputPlaceholder" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                신청일
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                        <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="_searchInput.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="_searchInput.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                            :startDate="_searchInput.startYmd"
                                            :endDate="_searchInput.endYmd"
                                            @update:startDate="(value)=> _searchInput.startYmd = value"
                                            @update:endDate="(value)=> _searchInput.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <p>전체 <span>{{ $number(totalCount)}}</span>건</p>
                    <div>
                        <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>No</th>
                            <th>신청일</th>
                            <th>현재 회원 유형</th>
                            <th>변경 회원 유형</th>
                            <th>회원코드</th>
                            <th>이름</th>
                            <th>아이디</th>
                            <th>핸드폰 번호</th>
                            <th>이메일</th>
                            <th>상태</th>
                            <th>상태 변경</th>
                        </tr>
                        <tr v-for="userGbHistory in list">
                            <td>{{ userGbHistory.boardNo }}</td>
                            <td>{{ $yyyymmddHHmmss(userGbHistory.createDate) }}</td>
                            <td>{{ $userGbKR(userGbHistory.beforeUserGb) }}</td>
                            <td>{{ $userGbKR(userGbHistory.afterUserGb) }}</td>
                            <td>{{ userGbHistory.userCd }}</td>
                            <td class="link"><RouterLink :to="{name: 'MemberDetail', params:{id: userGbHistory.userId}}">{{ userGbHistory.userName }}</RouterLink></td>
                            <td>{{ userGbHistory.userLoginId }}</td>
                            <td>{{ $phoneNum(userGbHistory.phoneNum) }}</td>
                            <td>{{ userGbHistory.email }}</td>
                            <td>{{ $gbNameKR('USER_GB_STATE_GB', userGbHistory.stateGb) }}</td><!-- 01 신청 02 취소 03 승인 04 거절 -->
                            <td>
                              <span v-if="userGbHistory.stateGb === '01'" data-title="신청 : ">
                                  <KSATextButton text="[승인]" @click="changeUserGbHistoryState(userGbHistory.seq, '03')"/>
                                  &nbsp;
                                  <KSATextButton text="[거절]" class="red_button" @click="changeUserGbHistoryState(userGbHistory.seq, '04')"/>
                              </span>
                              <span v-else>
                                -
                              </span>
                            </td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        신청건이 없습니다.
                    </div>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>