<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, defineExpose, onMounted } from "vue";
    import Loading from 'vue3-loading-overlay';
    import { VideoPlayer } from '@videojs-player/vue'
    import 'video.js/dist/video-js.css'

    const DOMAIN = 'https:\/\/academy.ksia.or.kr';

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const playerWrapRef = ref(null)

    const loadedVideo = ref(false)
    const lectDetail = ref({})

    const width = ref(0)
    const height = ref(0)

    const show = (_lectDetail) => {
        lectDetail.value = _lectDetail;
        loadedVideo.value = false;
        baseModal.value.open();

        setTimeout(()=>{
            width.value = playerWrapRef.value.clientWidth;
            height.value = playerWrapRef.value.clientHeight;
        }, 100)

        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    defineExpose({
        show,
    })
</script>

<template>
    <Modal ref="baseModal" class="vld-parent">
        <loading
            :active="!loadedVideo"
            loader="dots"
            color="#3843AF"
            opacity="0.5"
            width="35"
            :is-full-page="false"></loading>
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3><span class="badge">{{ lectDetail.lectGb === '01' ? '온라인' : '오프라인' }} 강의</span>{{ lectDetail.lectName }}</h3>
            </div>
            <div class="modal-body">
                <div ref="playerWrapRef" class="player-wrap">
                    <video-player
                        :width="width"
                        :height="height"
                        :src="DOMAIN + lectDetail.oneMovDir"
                        :poster="lectDetail.lectThombDir ? (DOMAIN + lectDetail.lectThombDir) : ''"
                        controls
                        :loop="false"
                        :volume="0.6"
                        @ready="loadedVideo=true"
                    />
                </div>
            </div>
<!--            <div class="modal-footer btn-wrap">-->
<!--                <KSASecondaryButton text="취소" @click="cancel" />-->
<!--                <KSAPrimaryButton text="확인" @click="confirm" />-->
<!--            </div>-->
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .modal-inner {
        .modal-body {
            .player-wrap {
                position: relative;
                height: 612px;
                overflow: hidden;
            }
        }
    }
</style>