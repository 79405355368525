<script setup>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from '../../../user/stores/user'
    import { useCookies } from "vue3-cookies"
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import {useSignupStore} from "../../../user/stores/signup";
    import signinService from "../../../user/services/signin/signinService"
    import {useSiteMenuStore} from "../../../../stores/sitemenu";

    const { cookies } = useCookies();
    const router = useRouter()
    const userStore = useUserStore()
    const signupStore = useSignupStore()
    const loadingStore = usePageLoadingStore()
    const siteMenuStore = useSiteMenuStore()

    onMounted(()=>{
        signupStore.setSignupRequest({});
    })

    const input = ref({
        userLoginId: '',
        password: '',
    });

    const inputError = ref({
        userLoginId: '',
        password: '',
    })

    const checkedSaveId = ref(true);

    const modal = ref(null)
    const modalContent = ref([]);

    const cookie_user_id = cookies.get("userId");
    // console.log(cookie_user_id);
    if(cookie_user_id) {
        input.value.userLoginId = cookie_user_id;
    }

    const _login = async () => {
        const { userLoginId, password } = input.value;
        let isInvalid = false;

        inputError.value.userLoginId = '';
        inputError.value.password = '';

        if(!userLoginId) {
            inputError.value.userLoginId = '아이디를 입력해주세요.';
            isInvalid = true;
        }
        if(!password) {
            inputError.value.password = '비밀번호를 입력해주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            loadingStore.loading = true;
            signinService.signin({loginType: 'sysadmin', userLoginId, password})
                .then( (res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        userStore.setUser({
                            userGb: result.userGb,
                            userCd: result.userCd,
                            userLoginId: result.userLoginId,
                            userName: result.userName,
                            token: result.token,
                            refreshToken: result.refreshToken,
                            accessMenu: result.accessMenu,
                        });
                        siteMenuStore.getSiteMenu();

                        // console.log(checkedSaveId.value);
                        if(checkedSaveId.value) {
                            cookies.set("userId", input.value.userLoginId);
                        } else {
                            cookies.remove("userId");
                        }

                        loadingStore.loading = false;
                        router.push({name: 'SchedulerManage'});

                    } else {
                        modalContent.value = ["아이디가 존재하지 않거나 아이디 또는 비밀번호가 올바르지 않습니다."]
                        modal.value.show();
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
                modalContent.value = ["아이디가 존재하지 않거나 아이디 또는 비밀번호가 올바르지 않습니다."]
                modal.value.show();
            });
        }
    }

    const goView = (routeName) => {
        router.push({name: routeName});
    }

    const onKeyDown = (e)  => {
        if(e.keyCode === 13) { // enter
            console.log("enter");
            _login();
        }
    }
</script>

<template>
    <div class="sign-wrap" @keydown="onKeyDown" >
        <div class="sign-inner">
            <div class="title">
                <h3>시스템 관리자 로그인</h3>
            </div>
            <div class="sign-content">
                <KSAInput class="text-box" v-model="input.userLoginId" label="아이디" placeholder="아이디 입력하세요." max-length="20" :msg="inputError.userLoginId"/>
                <KSAInput type="password" class="text-box" v-model="input.password" label="비밀번호" placeholder="비밀번호 입력하세요." max-length="30" :msg="inputError.password"/>
                <p class="save"><KSACheckBox label="아이디 저장" v-model="checkedSaveId"/></p>
                <KSAPrimaryButton class="full-btn" text="로그인" @click="_login" />
            </div>
            <ul class="member">
<!--                <li><span @click="goView('SigninSearchID')">아이디 찾기</span></li>-->
<!--                <li><span @click="goView('SigninSearchPW')">비밀번호 찾기</span></li>-->
<!--                <li><span @click="goView('Signup')">회원가입</span></li>-->
            </ul>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style scoped>

</style>