import http from '../../../../api/http';

function signin({loginType, userLoginId, password}) {
    return http.post(`/signin/${loginType}`, {
        username: userLoginId,
        password
    });
}

function requestUnsubscribe(unsubscribeId) {
    return http.post('/signin/email/unsubscribe', {
        unsubscribeId: encodeURIComponent(unsubscribeId),
    });
}

export default {
    signin,
    requestUnsubscribe, // 이메일 수신거부
};