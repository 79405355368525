<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import insightsNewsletterService from "../../../../user/services/insights/insightsNewsletterService";
    import mailMsgServcie from "../../../services/mail/mailMsgServcie";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const {$toast} = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            goBack()
        } else {
            retrieveDetail(id)
        }
    })

    const newsletter = ref({})

    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])
    const okButtonText = ref('')

    const retrieveDetail = (id) => {
        loadingStore.loading = true;
        insightsNewsletterService.retrieveNewsletterDetail(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    newsletter.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            // console.log(error);
        });
    }

    const onClickDelete = async () => {
        modalContent.value = ["뉴스레터를 삭제할까요?", "삭제 시 사용자 사이트에 즉시 적용되며 되돌릴 수 없습니다."];
        okButtonText.value = '삭제';
        const ok = await confirmModal.value.show()
        if(ok){
            deleteNewsletter()
        }
    }

    function deleteNewsletter() {
        loadingStore.loading = true;
        insightsNewsletterService.deleteNewsletter(newsletter.value.newsletterId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    goBack();
                } else {
                    modalContent.value = ["삭제를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            // console.log(res);
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
        });
    }

    const onClickSend = async () => {
        modalContent.value = ["뉴스레터를 전송할까요?"];
        okButtonText.value = '전송';
        const ok = await confirmModal.value.show();
        if(ok) {
            sendNewsletter();
        }
    }

    function sendNewsletter() {
        loadingStore.loading = true;
        mailMsgServcie.requestSendEmailNewsletter(newsletter.value.newsletterId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    $toast('', "뉴스레터를 전송하였습니다.<br/>'메일/메시지 관리' > '전송 내역'에서 확인할 수 있습니다.");
                } else {
                    modalContent.value = ["전송을 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            // console.log(res);
            modalContent.value = ["전송을 실패하였습니다."];
            modal.value.show();
        });
    }

    const onClickUpdate = () => {
        router.push({name:'CSNewsletterUpdate', params:{id: newsletter.value.newsletterId}})
    }

    const goBack = () => {
        router.push({name:'CSNewsletterList'})
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>뉴스레터</h3>
                </div>
                <div class="btn-wrap space-between mb-xs">
                    <div>
                        <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                        <KSAPrimaryButton text="뉴스레터 전송" @click="onClickSend" />
                    </div>
                    <div>
                        <KSASecondaryButton text="수정" @click="onClickUpdate" />
                        <KSASecondaryButton class="red_button" text="삭제" @click="onClickDelete" />
                    </div>
                </div>
                <div class="board-detail">
                    <div class="header">
                        <div class="board-title">
                            <p>{{ newsletter.title }}</p>
                        </div>
                        <span>작성일 <i>{{ $yyyymmddOrHHmm(newsletter.createDate) }}</i></span>
                        <span>작성자
                            <i>
                                {{ newsletter.createUserName }}<span v-if="newsletter.createUserLoginId">({{ newsletter.createUserLoginId }})</span>
                            </i>
                        </span>
                        <span>조회수 <i>{{ $number(newsletter.hitCount)}}</i></span>
                    </div>
                    <div class="body">
                        <div class="text">
                            <p v-html="newsletter.content"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";
    img {
        max-width: 600px;
        width: 100%;
    }
</style>