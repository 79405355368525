<script setup>
    import axios from 'axios'
    import { ref, onMounted, getCurrentInstance } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import { useLectureStore } from "../../stores/lecture/lectureStore";
    import { useSasTokenStore } from "../../../../stores/sasTokenStore";
    import lectureService from "../../services/lecture/lectureService";
    import _ from "lodash";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import LectureSendMailModal from '../../components/modal/lecture/LectureSendMailModal.vue'
    import LectureSendSMSModal from '../../components/modal/lecture/LectureSendSMSModal.vue'
    import lectureQnaService from "../../services/lecture/lectureQnaService";
    import lectureNoticeService from "../../services/lecture/lectureNoticeService";
    import fileService from "../../../../services/fileService";
    import surveyService from "../../services/lecture/surveyService";
    import LecturePlayerModal from "../../../user/components/modal/mypage/LecturePlayerModal.vue";
    import LecturePlayerPreviewModal from "../../../user/components/modal/mypage/LecturePlayerPreviewModal.vue";
    import MyPageResumeModal from "../../../user/components/modal/mypage/MyPageResumeModal.vue";
    import LectureEvalStateChangeModal from "../../../admin/components/modal/lecture/LectureEvalStateChangeModal.vue";
    import lectureHomeworkService from "../../services/lecture/lectureHomeworkService";
    import lectureUserService from "../../../user/services/lecture/lectureUserService";

    const isSelected= ref(true); // KSACheckBox - checkbox 1개 일때

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const sasTkStore = useSasTokenStore();
    const { $toast, $pageListBoardNo, $gb : { USER_GB } } = getCurrentInstance().appContext.config.globalProperties;

    const currentURL = window.location.host;

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name:'LectureList'});
        } else {
            retrieveDetail(id)
        }

        // 보안PDF 접속 가능 여부 확인
        // 접속가능IP인지 확인
        checkClientIp(id);
        //checkIp();
    })

    // 보안PDF 접속 가능 IP
    const allowIpList = ["121.134.157.2",
      "221.140.5.121",  // 11층 유선
      "218.49.117.228", // 9층 무선
      "218.49.117.238", // 11층 무선
      "121.167.5.85",   // SG 유선
      "121.167.5.76",   // SG 무선
      "221.140.5.66",   // 2023.06.28 추가
      "221.140.10.80",  // 9층 무선 (2023.07.07 추가)
      "221.140.10.100", // 9층 무선 (2023.07.07 추가)
      "210.95.145.164", // 2024.01.09 추가
      "114.206.169.54"  // 2024.05.17 추가
    ];

    let myIp = '';

    // 보안 PDF 접속 가능 IP 체크
    const checkIp = () => {
      lectureUserService.getClientIp()
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              myIp = res.data.result;
              if( myIp.indexOf(',') > 0  ) { // 경로상 IP 제거
                myIp = myIp.substring(0,myIp.indexOf(','));
              }
              if( myIp.indexOf(':') > 0  ) { // 포트 제거
                myIp = myIp.substring(0,myIp.indexOf(':'));
              }
              if ( allowIpList.indexOf(myIp) < 0 ) { // 허용IP목록에 없다면 불가
                accessOk = false;
              } else { // 허용IP목록에 있다면 허용
                accessOk = true;
              }
              //accessOk = true; // 임시허용
            }
          }).catch( (error) =>  {
        console.log(error);
      });
    }

    const MAX_SIZE = 200;
    const lectDetail = ref({})
    const lectIndexList = ref([])
    const surveyDetail = ref({})

    const tabIndex = ref(0)

    const modal = ref(null)
    const confirmModal = ref(null)
    const sendMailModal = ref(null)
    const sendSMSModal = ref(null)
    const resumeModal = ref(null)
    const lecturePlayerModal = ref(null)
    const lecturePlayerPreviewModal = ref(null)
    const evalStateChangeModal = ref(null)
    const modalContent = ref([])
    const okButtonText = ref("삭제")

    let accessOk = false;

    const viewMode = ref({
        qna: 'R',//  R 조회 D 상세(Detail)
        notice: 'R', //  R 조회 C 생성 U 수정 D 상세(Detail)
    })
    const editorRef = ref(null)

    const homeworkList = ref([]);  // 과제 목록

    const qnaList = ref([]) // Q&A 목록
    const qnaDetail = ref({})
    const qnaInput = ref({})
    const qnaInputError = ref({})

    const noticeList = ref([]) // 공지사항 목록
    const noticeInput = ref({
        title: '',
        content: '',
        attachList: [],
        securePdfList: [],
    })
    const noticeInputError = ref({})
    const noticeDetail = ref({
        title: '',
        content: '',
        answer: '',
        attachList: [],
        securePdfList: [],
    })

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const searchInputQnA = ref({
        searchType: 'all',
        searchInput: '',
        pageSize: 30,
    })

    const searchInputNotice = ref({
        pageSize: 30,
    })

    const searchTypeGroup = ref([
        { label:"이름", value: "name", placeholder: "이름을 입력해주세요."  },
        { label:"회원코드", value: "code", placeholder: "회원코드를 입력해주세요." },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        // { label:"이메일", value: "email", placeholder: "이메일을 입력해주세요."  },
    ]);

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const searchTypeOptions = [
        { label:'전체', value:'all', },
        { label:'제목', value:'title', },
        { label:'내용', value:'content', },
    ]

    const initValuesByTab = () => {
        if(tabIndex.value === 3) { // qna
            viewMode.value.qna = 'R';
            qnaInput.value = {
                answer: '',
                isEdit: false,
            }
            qnaDetail.value = {};
        } else if(tabIndex.value === 4) { // 공지사항
            viewMode.value.notice = 'R';
            noticeInput.value = {
                title: '',
                content: '',
                attachList: [
                    {
                        attachSeq: 0,
                        lectId: lectDetail.value.lectId,
                        fileName: "",
                        fileSize: "",
                        fileDir: "",
                        deleteYn: "N"
                    }
                ],
                securePdfList: [
                    {
                        attachSeq: 0,
                        lectId: lectDetail.value.lectId,
                        fileName: "",
                        pageCount: 0,
                        fileDir: "",
                        deleteYn: "N"
                    }
                ],
            };
            noticeDetail.value = {};
        }
    }

    const retrieveDetail = (lectId) => {
        loadingStore.loading = true;
        lectureService.retrieveLectureDetail(lectId)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    lectDetail.value = result;
                    if( lectDetail.value.survey !== null ) {
                        retrieveSurveyDetail(lectDetail.value.survey.surveyId, lectId);
                    }
                    retrieveLectureIndexList();
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    const retrieveSurveyDetail = (surveyId, lectId) => {
        loadingStore.loading = true;
        surveyService.retrieveSurveyPaper(surveyId, lectId)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    surveyDetail.value = result;
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

/*    const getLectureUserSelCount = () => {
        lectureService.retrieveLectureDetail(lectDetail.value.lectId)
            .then( (res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    lectDetail.value.userLectureCount = result.userLectureCount;
                    lectDetail.value.userLectureSelCount = result.userLectureSelCount;
                }
            }).catch( (res) =>  {
            // console.log(res);
        });
    }*/

    const retrieveLectureIndexList = () => { // 차시 목록 조회
        if(_.isEmpty(lectIndexList.value)) {
            loadingStore.loading = true;
            lectureService.retrieveAllIndexByLectId(lectDetail.value.lectId)
                .then( (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        lectIndexList.value = _.orderBy(result, 'idxOrder');
                        // console.log(result);

                        if(lectIndexList.value.length > 0) {
                            const teacherNames = _.uniq(_.map(lectIndexList.value, (lectIndex) => {
                                if(lectIndex.teacher && lectIndex.teacher.userName) {
                                    return lectIndex.teacher.userName;
                                } else {
                                    return false;
                                }
                            }));

                            if(teacherNames.length > 0) {
                                lectDetail.value.teacherNameText = teacherNames[0];
                                if(teacherNames.length > 1) {
                                    lectDetail.value.teacherNameText += ` 외 ${teacherNames.length-1}명`;
                                }
                            }

                        }
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                $toast('error', '오류가 발생하였습니다.');
            });
        }
    }

    const onClickSurveyId = (survey) => {
        window.open(`/admin/lecture/survey/surveyPaper/detail/${survey.surveyId}`, '_blank');
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;

        if(tabIndex.value === 1) {  // 차시 정보

        } else if(tabIndex.value === 2) { // 과제
            retrieveLectureHomeworkList();
        } else if(tabIndex.value === 3) { // QNA
            retrieveLectureQnAList();
        } else if(tabIndex.value === 4) { // 공지사항
            retrieveLectureNoticeList();
        }
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;

        if(tabIndex.value === 1) { // 차시 정보

        } else if(tabIndex.value === 2) { // 과제
            retrieveLectureHomeworkList();
        } else if(tabIndex.value === 3) { // QNA
            retrieveLectureQnAList();
        } else if(tabIndex.value === 4) { // 공지사항
            retrieveLectureNoticeList();
        }
    }

    const onSelectTab = (option) => {
        tabIndex.value = option.value;
        currentPage.value = 1;
        if(tabIndex.value === 1) { // 차시 정보
            retrieveLectureIndexList();
        } else if(tabIndex.value === 2) { // 과제
            retrieveLectureHomeworkList();
        } else if(tabIndex.value === 3) { // Q&A
            retrieveLectureQnAList();
        } else if(tabIndex.value === 4) { // 공지사항
            retrieveLectureNoticeList();
        }
        initValuesByTab();
    }

    const deleteLecture = async () => {
        modalContent.value = ["강의를 삭제할까요?", "강의 삭제시 차시 정보 및 수강생 목록 등 모든 강의 정보가 삭제되며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show();
        if(ok) {
            loadingStore.loading = true;
            lectureService.deleteLecture(lectDetail.value.lectId)
                .then( (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        goBack();
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                $toast('error', '오류가 발생하였습니다.');
            });
        }
    }

    // 강의 복사(비공개로 변환,강의명_복사)
    const copyLecture = async () => {
        // 강의 복사
        modalContent.value = ["강의를 복사할까요?"];
        okButtonText.value = "복사";
        const ok = await confirmModal.value.show();
        if(ok) {
            loadingStore.loading = true;
            lectureService.copyLecture(lectDetail.value.lectId)
                .then( (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const copyLectId = res.data.result;
                        router.push({name:'LectureDetail', params:{id: copyLectId}})
                    }
                }).catch( (res) =>  {
                    // console.log(res);
                    loadingStore.loading = false;
                    $toast('error', '오류가 발생하였습니다.');
          });
        }
    }

    const editLecture = () => {
        router.push({name:'LectureUpdate', params:{id: lectDetail.value.lectId}})
    }

    const goUpdateIndexs = () => {
        const { lectGb, lectId } = lectDetail.value;
        const lectureStore = useLectureStore()
        lectureStore.lectGb = lectGb;
        lectureStore.lectId = lectId;
        router.push({name: 'LectureCourseCreate'});
    }

    const deleteLectureIndex = async (lectIndex, idx) => {
        modalContent.value = ["차시를 삭제할까요?", "차시 삭제시 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show();
        if(ok) {
            loadingStore.loading = true;
            lectureService.deleteIndex(lectDetail.value.lectId, lectIndex.lecIdx)
                .then( (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        $toast('', '차시를 삭제하였습니다.');
                        lectIndexList.value.splice(idx, 1);
                        lectDetail.value.lectureIndexCount--;
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                $toast('error', '오류가 발생하였습니다.');
            });
        }
    }

    const onClickPreviewPlay = () => {
        lecturePlayerPreviewModal.value.show(lectDetail.value);
    }

    const onClickVideoPlay = (lectIndex) => {
        let paramObj = {
            uploadFileKey: lectIndex.uploadFileKey,
            lectId: lectDetail.value.lectId,
            lectIdx: lectIndex.lecIdx,
        }
        lecturePlayerModal.value.show(lectDetail.value, paramObj, true);
    }

    // s: 차시 정보

    // 주차 rowspan을 위한 함수
    // acc에 주차를 key값으로 같은주차의 모든 차시item을 push 해서 같은 key값에 담아 줌.
    const groupByIdxNum = (lectIndexList) => {
      return lectIndexList.reduce((groupedLectIndexes, lectIndex) => {
        if (!groupedLectIndexes[lectIndex.idxNum]) {
          groupedLectIndexes[lectIndex.idxNum] = [];
        }
        groupedLectIndexes[lectIndex.idxNum].push(lectIndex); // 주차에 차시 추가
        return groupedLectIndexes;
      }, {});
    }

    // e: 차시 정보

    // s: 과제

    const retrieveLectureHomeworkList = () => {
        loadingStore.loading = true;
        lectureHomeworkService.retrieveHomeworkByLectIdForAdmin(lectDetail.value.lectId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    homeworkList.value = result;
                }
            }).catch( (error) =>  {
                console.log(error);
                loadingStore.loading = false;
                $toast('error', '오류가 발생하였습니다.');
            });
    }

    // e: 과제

    // s: Q&A

    const retrieveLectureQnAList = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            pageNo: currentPage.value,
            size: searchInputQnA.value.pageSize,
            searchType: searchInputQnA.value.searchType,
            searchInput: searchInputQnA.value.searchInput,
        }
        loadingStore.loading = true;
        lectureQnaService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    qnaList.value = $pageListBoardNo(result.content, totalCount.value, searchInputQnA.value.pageSize, currentPage.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const onSelectQnaSearchTypeOption = (option) => {
        searchInputQnA.value.searchType = option.value
    }

    const onClickQnaSearch = (value) => {
        searchInputQnA.value.searchInput = value;
        currentPage.value = 1;
        retrieveLectureQnAList();
    }

    const onClickQnADetail = (boardId) => {
        retrieveQnADetail(boardId);
        viewMode.value.qna = 'D';
    }

    const retrieveQnADetail = (id) => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: id,
        }

        qnaInput.value.isEdit = false;

        loadingStore.loading = true;
        lectureQnaService.retrieveDetail(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    qnaDetail.value = result;

                    if(!qnaDetail.value.answer) {
                        qnaInput.value.isEdit = true;
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }


    const onClickSaveAnswer = () => {
        const { answer } = qnaInput.value;
        const { boardId, title, content } = qnaDetail.value;

        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId,
            // title,
            // content,
            answer,
        }

        loadingStore.loading = true;
        lectureQnaService.updateAnswer(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    qnaDetail.value = result;
                    qnaInput.value.isEdit = false;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickEditCancel = () => {
        qnaInput.value.answer = qnaDetail.value.answer;
        qnaInput.value.isEdit = false;
    }

    const onClickEdit = () => {
        qnaInput.value.answer = qnaDetail.value.answer;
        qnaInput.value.isEdit = true;
    }

    const goQnAListView = () => {
        retrieveLectureQnAList()
        viewMode.value.qna = 'R';
        qnaDetail.value = {};
    }

    // e: Q&A

    // s: 공지사항

    const retrieveLectureNoticeList = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            pageNo: currentPage.value,
            size: searchInputNotice.value.pageSize,
            searchType: searchInputNotice.value.searchType,
            searchInput: searchInputNotice.value.searchInput,
        }
        loadingStore.loading = true;
        lectureNoticeService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    noticeList.value = $pageListBoardNo(result.content, totalCount.value, searchInputNotice.value.pageSize, currentPage.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const onClickCreateNotice = () => { // 공지사항 등록
        noticeInput.value = {
            title: '',
            content: '',
            attachList: [
                {
                    attachSeq: 0,
                    lectId: lectDetail.value.lectId,
                    fileName: "",
                    fileSize: "",
                    fileDir: "",
                    deleteYn: "N"
                }
            ],
            securePdfList: [
                {
                    attachSeq: 0,
                    lectId: lectDetail.value.lectId,
                    fileName: "",
                    pageCount: 0,
                    fileDir: "",
                    deleteYn: "N"
                }
            ],
        }
        viewMode.value.notice = 'C';
        generateAccountSas();
    }

    const uploadNoticeFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];
            // console.log(file);
            if(file.size > (MAX_SIZE*1024*1024)) {
                modalContent.value = [`파일은 최대 ${MAX_SIZE}MB까지 첨부할 수 있습니다.`];
                modal.value.show();
            } else {
                let paramObj = {
                    container: 'private',
                    folder: 'lecture_notice',
                    file,
                }

                fileService.uploadFile(paramObj)
                    .then((res) => {
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            noticeInput.value.attachList[0].fileName = result.originalFilename;
                            noticeInput.value.attachList[0].fileSize = result.fileSize;
                            noticeInput.value.attachList[0].fileDir = result.blobUrl;
                            // console.log(input.value)
                        }
                    })
                    .catch( (error) =>  {
                        console.log(error);
                    });
            }
        });
    }

    const removeNoticeFile = () => {
        noticeInput.value.attachList[0].fileName = '';
        noticeInput.value.attachList[0].fileSize =  '';
        noticeInput.value.attachList[0].fileDir =  '';
    }

    const uploadSecurePdfFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];
            // console.log(file);
            if(file.size > (MAX_SIZE*1024*1024)) {
                modalContent.value = [`파일은 최대 ${MAX_SIZE}MB까지 첨부할 수 있습니다.`];
                modal.value.show();
            } else {
                fileService.uploadSecurePDF(file)
                    .then((res) => {
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            noticeInput.value.securePdfList[0].fileName = result.originalFilename;
                            noticeInput.value.securePdfList[0].pageCount = result.pageCount;
                            noticeInput.value.securePdfList[0].fileDir = result.blobUrl;
                            // console.log(input.value)
                        }
                    })
                    .catch( (error) =>  {
                        console.log(error);
                    });
            }
        });
    }

    const removeSecurePdfFile = () => {
        noticeInput.value.securePdfList[0].fileName = '';
        noticeInput.value.securePdfList[0].pageCount = 0;
        noticeInput.value.securePdfList[0].fileDir =  '';
    }

    const createNotice = () => {
        const { title, content, attachList, securePdfList } = noticeInput.value;
        let isInvalid = false;

        noticeInputError.value = {
            title: '',
            content: '',
            attachList: '',
            securePdfList: '',
        };

        if(!title) {
            noticeInputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            noticeInputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                lectId: lectDetail.value.lectId,
                title,
                content,
                attachList: _.filter(attachList, attach => {
                    return attach.fileName !== null && attach.fileName !== '';
                }),
                securePdfList: _.filter(securePdfList, attach => {
                    return attach.fileName !== null && attach.fileName !== '';
                }),
            }

            loadingStore.loading = true;
            lectureNoticeService.createNotice(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        goNoticeListView();
                    } else {
                        modalContent.value = ["등록을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = ["등록을 실패하였습니다."];
                modal.value.show();
            });
        }
    }

    const onClickNoticeDetail = (boardId) => { // 공지사항 상세
        retrieveNoticeDetail(boardId);
        viewMode.value.notice = 'D';
    }

    const retrieveNoticeDetail = (id) => {
        noticeDetail.value = {
            title: '',
            content: '',
            attachList: [],
            securePdfList: [],
        };


        loadingStore.loading = true;
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: id,
        }
        lectureNoticeService.retrieveDetail(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    noticeDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickUpdateNotice = () => {
        viewMode.value.notice = 'U';
        sasTkStore.sasToken = noticeDetail.value.sasToken;

        noticeInput.value = {
            title: noticeDetail.value.title,
            content: noticeDetail.value.content,
            attachList: noticeDetail.value.attachList,
            securePdfList: noticeDetail.value.securePdfList,
        }

        if(_.isEmpty(noticeInput.value.attachList)) {
            noticeInput.value.attachList = [
                {
                    attachSeq: 0,
                    lectId: lectDetail.value.lectId,
                    fileName: "",
                    fileSize: "",
                    fileDir: "",
                    deleteYn: "N"
                }
            ]
        }
        if(_.isEmpty(noticeInput.value.securePdfList)) {
            noticeInput.value.securePdfList = [
                {
                    attachSeq: 0,
                    lectId: lectDetail.value.lectId,
                    fileName: "",
                    pageCount: 0,
                    fileDir: "",
                    deleteYn: "N"
                }
            ]
        }

        setTimeout(()=>{
            editorRef.value.init(noticeInput.value.content);
        },100);
    }

    const updateNotice = () => {
        const { title, content, attachList, securePdfList } = noticeInput.value;
        let isInvalid = false;

        noticeInputError.value = {
            title: '',
            content: '',
            attachList: '',
            securePdfList: '',
        };

        if(!title) {
            noticeInputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            noticeInputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        _.each(attachList, attach => {
            attach.deleteYn = !attach.fileName ? 'Y' : 'N';
        });
        _.each(securePdfList, attach => {
            attach.deleteYn = !attach.fileName ? 'Y' : 'N';
        });

        if(!isInvalid) {
            let paramObj = {
                lectId: lectDetail.value.lectId,
                boardId: noticeDetail.value.boardId,
                title,
                content,
                attachList,
                securePdfList,
            }

            loadingStore.loading = true;
            lectureNoticeService.updateNotice(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        noticeDetail.value = result;
                        viewMode.value.notice = 'D';
                    } else {
                        modalContent.value = ["저장을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = ["저장을 실패하였습니다."];
                modal.value.show();
            });
        }
    }

    const onClickDeleteNotice = async () => {
        modalContent.value = ["공지사항을 삭제할까요?", "삭제 시 사용자 사이트에 즉시 적용되며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show()
        if(ok){
            deleteNotice()
        }
    }

    function deleteNotice() {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: noticeDetail.value.boardId,
        }
        loadingStore.loading = true;
        lectureNoticeService.deleteNotice(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    goNoticeListView();
                } else {
                    modalContent.value = ["삭제를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            console.log(res);
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
        });
    }

    const goNoticeListView = () => {
        retrieveLectureNoticeList();
        viewMode.value.notice = 'R';
        noticeDetail.value = {};
    }

    const onClickViewer = (attach) => {
        if ( accessOk ) {
            sessionStorage.setItem('pdfviewer', JSON.stringify({
                keyName: attach.fileDir,
                lectId: lectDetail.value.lectId,
                pageCount: attach.pageCount,
            }));
            window.open('/mypage/lecture/viewer', '_blank');
        } else {
            const content = "보안PDF 조회가 허가된 IP가 아닙니다.";
            modalContent.value = [content];
            modal.value.show();
            return false;
        }
    }

    const generateAccountSas = () => {
        fileService.generateAccountSas();
    }

    // 보안 PDF 접속 가능 IP 인지 확인
    const checkClientIp = (lectId) => {
      lectureUserService.checkClientIp(lectId)
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              accessOk = res.data.result;
            }
          }).catch( (error) =>  {
        console.log(error);
      });
    }

    // e : 공지사항

    const goBack = () => {
        router.push({name:'LectureList'})
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3><span v-if="lectDetail.repreLectYn === 'Y'" class="badge">대표</span>{{ lectDetail.lectName }}</h3>
                </div>
                <div class="list-top end">
                    <div>
                        <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                        <KSASecondaryButton class="gray_button ml-xs" text="강의 복사" @click="copyLecture" />
                        <KSASecondaryButton class="gray_button ml-xs" text="강의 수정" @click="editLecture" />
                        <KSASecondaryButton class="red_button ml-xs" text="강의 삭제" @click="deleteLecture" />
                    </div>
                </div>
                <KSATab style="grid-template-columns: repeat(5, 1fr);" class="mb-lg"
                        :options="[
                            {label:'강의 정보', value:0},
                            {label:`차시 정보${lectDetail.lectureIndexCount > 0 ? ('(' + lectDetail.lectureIndexCount + ')') : ''}`, value:1},
                            {label:'과제', value:2},
                            {label:'Q&A', value:3},
                            {label:'공지사항', value:4}
                        ]"
                        @change="onSelectTab"
                />

               <!-- s: 강의 정보 -->
               <div v-show="tabIndex === 0">
                   <div class="table-form">
                       <table class="vertical">
                           <colgroup>
                               <col width="200px" />
                               <col />
                               <col width="200px" />
                               <col />
                           </colgroup>
                           <tr>
                               <th>이미지</th>
                               <td colspan="3">
                                   <div v-if="lectDetail.lectThombDir" style="width: 160px; height: 160px;">
                                       <KSAImage :src="lectDetail.lectThombDir"/>
                                   </div>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '01'">
                               <th>미리보기 영상</th>
                               <td colspan="3">
                                   <a class="link underline" v-if="lectDetail.oneMovName" href="javascript:void(0);" @click="onClickPreviewPlay">{{ lectDetail.oneMovName }}</a>
                                   <span v-else>-</span>
                               </td>
                           </tr>
                           <tr>
                               <th>구분</th>
                               <td>{{ lectDetail.lectGb === '01' ? '온라인' : '오프라인'}} 강의</td>
                               <th>강의 공개 여부</th>
                               <td>
                                    {{ lectDetail.lectOpenYn === 'Y' ? '공개' : '비공개'}}
                                    <small v-if="(lectDetail.lectOpenYn === 'N' && lectDetail.lectGb === '01')">
                                        ({{ currentURL }}/lecture/online/sdetail/{{ lectDetail.uuid }})
                                    </small>
                                    <small v-if="(lectDetail.lectOpenYn === 'N' && lectDetail.lectGb === '02')">
                                        ({{ currentURL }}/lecture/offline/sdetail/{{ lectDetail.uuid }})
                                    </small>
                               </td>
                           </tr>
                           <tr>
                               <th>등록일</th>
                               <td>{{ $yyyymmddHHmm(lectDetail.createDate) }}</td>
                               <th>최근 수정일</th>
                               <td>{{ $yyyymmddHHmm(lectDetail.modifyDate) }}</td>
                           </tr>
                           <tr>
                               <th>강의명</th>
                               <td>{{ lectDetail.lectName }}<span class="badge" v-if="lectDetail.repreLectYn === 'Y'">대표</span></td>
                               <th>강사명</th>
                               <td>
                                   <span v-if="lectDetail.lectureIndexCount === 0">-</span>
                                   <span v-else>{{ lectDetail.teacherNameText }}</span>
                               </td>
                           </tr>
                           <tr>
                               <th>차시</th>
                               <td>
                                   <span v-if="lectDetail.lectureIndexCount === 0">-</span>
                                   <span v-else>{{ lectDetail.lectureIndexCount }}차시</span>
                               </td>
                               <th>총 시간</th>
                               <td>
                                   <span v-if="lectDetail.lectGb === '01'">
                                       {{ lectDetail.lectureIndexMovPlayTimeSumString || '-' }}
                                   </span>
                                   <span v-else>
                                       <span v-if="lectDetail.lectHour || lectDetail.lectMin">
                                            {{ lectDetail.lectHour ? `${lectDetail.lectHour}시간` : '' }}{{ lectDetail.lectMin ? ` ${lectDetail.lectMin}분` : '' }}
                                       </span>
                                       <span v-else>-</span>
                                   </span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '01'">
                               <th>신청 기간</th>
                               <td>
                                   <span v-if="lectDetail.regTermGb === '01'">상시</span>
                                   <span v-else>{{ $yyyymmdd(lectDetail.regStartYmd) }} ~ {{ $yyyymmdd(lectDetail.regEndYmd) }}</span>
                               </td>
                               <th>교육 기간</th>
                               <td>
                                   <span v-if="lectDetail.lectTermGb === '01'">신청일로부터 {{ lectDetail.lectTerm }}일</span>
                                   <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '02'">
                               <th>신청 기간</th>
                               <td>
                                   <span v-if="lectDetail.regTermGb === '01'">상시</span>
                                   <span v-else>{{ $yyyymmdd(lectDetail.regStartYmd) }} ~ {{ $yyyymmdd(lectDetail.regEndYmd) }}</span>
                               </td>
                               <th>면접 기간</th>
                               <td>
                                   <span v-if="lectDetail.intvTermGb === '01'">상시</span>
                                   <span v-else>{{ $yyyymmdd(lectDetail.intvStartYmd) }} ~ {{ $yyyymmdd(lectDetail.intvEndYmd) }}</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '01'">
                               <th>신청 인원</th>
                               <td>
                                   <span v-if="lectDetail.userLectureCount === null">-</span>
                                   <span v-else>{{ lectDetail.userLectureCount }}명</span>
                               </td>
                               <th>선발 인원</th>
                               <td>
                                   <span v-if="lectDetail.userLectureSelCount === null">-</span>
                                   <span v-else>{{ lectDetail.userLectureSelCount }}명</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '02'">
                               <th>교육 기간</th>
                               <td>
                                   <span v-if="lectDetail.lectTermGb === '01'">신청일로부터 {{ lectDetail.lectTerm }}일</span>
                                   <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                               </td>
                               <th>신청 인원</th>
                               <td>
                                   <span v-if="lectDetail.userLectureCount === null">-</span>
                                   <span v-else>{{ lectDetail.userLectureCount }}명</span>
                                   <span>({{ lectDetail.regMemOpenYn === 'Y' ? '공개' : '비공개' }})</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '01'">
                               <th>교육 인원</th>
                               <td colspan="3">
                                   <span v-if="lectDetail.limitMemYn === 'Y'">{{ lectDetail.limitMemNum }}명</span>
                                   <span v-else>없음</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '02'">
                               <th>선발 인원</th>
                               <td>
                                   <span v-if="lectDetail.userLectureSelCount === null">-</span>
                                   <span v-else>{{ lectDetail.userLectureSelCount }}명</span>
                                   <span>({{ lectDetail.selMemOpenYn === 'Y' ? '공개' : '비공개' }})</span>
                               </td>
                               <th>교육 인원</th>
                               <td>
                                   <span v-if="lectDetail.limitMemYn === 'Y'">{{ lectDetail.limitMemNum }}명</span>
                                   <span v-else>없음</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '02'">
                               <th>선발 기준</th>
                               <td>
                                   <span v-if="lectDetail.ticketOfferGb === '01'">없음(선착순)</span>
                                   <span v-else-if="lectDetail.ticketOfferGb === '02'">서류전형</span>
                                   <span v-else-if="lectDetail.ticketOfferGb === '03'">서류+면접전형</span>
                                  <span v-else>-</span>
                               </td>
                               <th>이력서 필수 항목</th>
                               <td>
                                   <span>{{ lectDetail.resumeRequired }}</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '02'">
                               <th>교육 장소</th>
                               <td colspan="3">
                                   <span>{{ lectDetail.lectPlace }}</span>
                               </td>
                           </tr>
                           <tr>
                               <th>교육 대상자</th>
                               <td colspan="3">
                                   <span v-if="lectDetail.lectTrgt === 'ALL'">전체</span>
                                   <span v-else>{{ $userGbKR(lectDetail.lectTrgt) }}</span>
                               </td>
                           </tr>
                           <tr>
                               <th>수료 기준</th>
                               <td colspan="3">
                                   <span v-if="lectDetail.finCriteriaGb === '01'">없음</span>
                                   <span v-else-if="lectDetail.finCriteriaGb === '02'">교육기간 내 진도율 {{ lectDetail.finishCriteria }}% 이상</span>
                                   <span v-else-if="lectDetail.finCriteriaGb === '03'">Pass/Fail</span>
                                   <span v-else>-</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '01'">
                               <th>선발 기준</th>
                               <td colspan="3">
                                   <span v-if="lectDetail.ticketOfferGb === '01'">없음(선착순)</span>
                                   <span v-else>대상자 선정(수강 신청 인원 중)</span>
                               </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '01'">
                             <th>교육 인원</th>
                             <td colspan="3">
                               {{ lectDetail.limitMemOpenYn === 'Y' ? '공개' : '비공개' }}
                             </td>
                           </tr>
                           <tr v-if="lectDetail.lectGb === '01'">
                               <th>수강중 인원</th>
                               <td colspan="3">
                                   {{ lectDetail.selMemOpenYn === 'Y' ? '공개' : '비공개' }}
                               </td>
                           </tr>
                           <tr>
                               <th>허용IP</th>
                               <td colspan="3">
                                 {{ lectDetail.allowIpList }}
                               </td>
                           </tr>
                           <tr>
                               <th>참고 사항<br><span>(상세출력)</span></th>
                               <td colspan="3">
                                   {{ lectDetail.note }}
                               </td>
                           </tr>
                           <tr>
                               <th>강의 설명</th>
                               <td colspan="3">
                                   {{ lectDetail.lectDesc }}
                               </td>
                           </tr>
                           <tr>
                               <th>과정 소개</th>
                               <td colspan="3" v-html="lectDetail.lectIntro" class="editor-wrap">
                               </td>
                           </tr>
                           <tr>
                               <th>만족도 평가</th>
                               <td colspan="3">
                                   <KSATextButton :text="surveyDetail.title" @click="onClickSurveyId(surveyDetail)" />
                               </td>
                           </tr>
                           <tr>
                               <th>설문 결과</th>
                               <td>
                                   {{ surveyDetail.avgResult }}
                               </td>
                               <th>설문 제출자/수강생</th>
                               <td>
                                   {{ surveyDetail.totalSubmit }} / {{ lectDetail.userLectureSelCount }}
                               </td>
                           </tr>
                       </table>
                   </div>
               </div>
                <!-- e: 강의 정보 -->

                <!-- s: 차시 정보 -->
                <div v-show="tabIndex === 1">
                    <div class="list-top">
                        <div class="grid">
<!--                            <KSASelect :options="[{label:'30개씩 보기', value:''}]" class="select" @selectOption="onOptionSelected"/>-->
                        </div>
                        <div>
                            <KSAPrimaryButton text="차시 수정" @click="goUpdateIndexs" />
                        </div>
                    </div>
                    <div class="table-form">
                        <table class="horizontal" v-if="lectDetail.lectGb === '01'">
                            <tr>
                                <th>차시</th>
                                <th>일시</th>
                                <th>요일</th>
                                <th>영상</th>
                                <th>시간</th>
                                <th>강사명</th>
                                <th>차시명</th>
                                <th>삭제</th>
                            </tr>
                            <tr v-for="(lectIndex, idx) in lectIndexList">
                                <td>{{ lectIndex.idxNum }}차시</td>
                                <td>
                                    <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                    <span v-else>{{ $yyyymmdd(lectIndex.idxYmd) }}</span>
                                </td>
                                <td>
                                    <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                    <span v-else>{{ $dateToWeekDay(lectIndex.idxYmd) }}</span>
                                </td>
                                <td class="text-left">
                                    <KSATextButton :text="lectIndex.movName" @click="onClickVideoPlay(lectIndex)" />
                                </td>
                                <td>
                                    <span v-if="lectIndex.movPlayHm">{{ $movPlayHm(lectIndex.movPlayHm) }}</span>
                                    <span v-else>{{ lectIndex.uploadFileKey ? '인코딩 중' : '-' }}</span>
                                </td>
                                <td>
                                    <span v-if="!lectIndex.teacher">-</span>
                                    <span v-else>{{ lectIndex.teacher.userName }}</span>
                                </td>
                                <td class="text-left">
                                    {{ lectIndex.idxName }}
                                </td>
                                <td>
                                    <KSASecondaryButton class="red_button" text="삭제" @click="deleteLectureIndex(lectIndex, idx)" />
                                </td>
                            </tr>
                            <tr v-if="lectIndexList.length === 0">
                                <td colspan="8">등록된 차시가 없습니다.</td>
                            </tr>
                        </table>
                        <table class="horizontal row-table" v-else>
                            <tr>
                                <th>주차</th>
                                <th>차시</th>
                                <th>일시</th>
                                <th>요일</th>
                                <th>시간</th>
                                <th>강사명</th>
                                <th>교육명</th>
                                <th>실습 여부</th>
                                <th>필수 기자재</th>
                                <th>삭제</th>
                            </tr>
                            <template v-for="(lectIndexesByIdxNum, idxNum) in groupByIdxNum(lectIndexList)">
                            <tr v-for="(lectIndex, idx) in lectIndexesByIdxNum" :key="lectIndex.id">
                                <td v-if="idx === 0" :rowspan="lectIndexesByIdxNum.length">
                                    {{ idxNum }}주차
                                </td>
                                <td>
                                    {{ lectIndex.idxNumSub }}차시
                                </td>
                                <td>
                                    <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                    <span v-else>{{ $yyyymmdd(lectIndex.idxYmd) }}</span>
                                </td>
                                <td>
                                    <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                    <span v-else>{{ $dateToWeekDay(lectIndex.idxYmd) }}</span>
                                </td>
                                <td>
                                    <span v-if="lectIndex.idxHms">{{ lectIndex.idxHms }}시간</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span v-if="!lectIndex.teacher">-</span>
                                    <span v-else>{{ lectIndex.teacher.userName }}</span>
                                </td>
                                <td class="text-left">
                                    {{ lectIndex.idxName }}
                                </td>
                                <td>
                                    {{ lectIndex.practiceYn === 'Y' ? 'O' : 'X' }}
                                </td>
                                <td class="text-left">
                                    {{ lectIndex.supplies }}
                                </td>
                                <td>
                                    <KSASecondaryButton class="red_button" text="삭제" @click="deleteLectureIndex(lectIndex, idx)" />
                                </td>
                            </tr>
                            </template>
                            <tr v-if="lectIndexList.length === 0">
                                <td colspan="9">등록된 차시가 없습니다.</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- e: 차시 정보 -->

                <!-- s : 과제 -->
                <div v-show="tabIndex === 2">
                    <div class="list-top">
                        <p>총 <span>{{ $number(homeworkList.length)}}</span>개</p>
                    </div>
                    <div class="table-form">
                        <table class="horizontal">
                            <tr>
                                <th>제목</th>
                                <th>파일</th>
                                <th>제출기한</th>
                                <th>작성자</th>
                                <th>등록일</th>
                            </tr>
                            <tr v-for="homework in homeworkList">
                                <td>{{ homework.title }}</td>
                                <td>
                                    <KSATextButton v-if="homework.hwFileDir" class="ml-xs" :text=homework.hwFileName @click="$download(homework.hwFileDir)" />
                                    <span v-else>-</span>
                                </td>
                                <td>{{ $yyyymmdd(lectDetail.lectEndYmd) }}까지</td>
                                <td>{{ homework.userName }}</td>
                                <td>{{ $yyyymmdd(homework.createDate) }}</td>
                            </tr>
                            <tr v-if="homeworkList.length === 0">
                                <td colspan="9">둥록된 과제가 없습니다.</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- e: 과제 -->

                <!-- s : Q&A -->
                <div v-show="tabIndex === 3">
                    <div v-if="viewMode.qna === 'R'">
                        <div class="list-top">
                            <p>총 게시물 <span>{{ $number(totalCount) }}</span>건</p>
                            <div class="search-box">
                                <KSASelect :options="searchTypeOptions" class="search-select" @selectOption="onSelectQnaSearchTypeOption"/>
                                <KSASearchInputBox placeholder="검색어를 입력하세요." class="search-input" @search="onClickQnaSearch" />
                            </div>
                        </div>
                        <div class="table-form">
                            <table class="horizontal">
                                <tr>
                                    <th>번호</th>
                                    <th>제목</th>
                                    <th>작성자</th>
                                    <th>작성일</th>
                                    <th>상태</th>
                                </tr>
                                <tr v-for="board in qnaList">
                                    <td>{{ board.boardNo }}</td>
                                    <td class="text-left"><a href="javascript:void(0);" @click="onClickQnADetail(board.boardId)">{{ board.title }}</a></td>
                                    <td>{{ board.createUserName }}</td>
                                    <td>{{ $yyyymmddOrHHmm(board.createDate) }}</td>
                                    <td :class="{'wait': !board.answer, 'complete': board.answer}">답변 {{ board.answer ? '완료' : '대기'}}</td>
                                </tr>
                            </table>
                            <div class="result-none" v-if="qnaList.length === 0">
                                등록된 질문이 없습니다.
                            </div>
                        </div>
                        <Paginate
                            v-if="qnaList.length > 0"
                            v-model="currentPage"
                            :page-count="totalPages"
                            :click-handler="onSelectPage"
                        />
                    </div>
                    <div v-else-if="viewMode.qna === 'D'">
                        <div class="board-detail">
                            <div class="header" style="position: relative">
                                <div class="board-title">
                                    <span :class="{'complete': qnaDetail.answer}">답변 {{ qnaDetail.answer ? '완료' : '대기' }}</span>
                                    <p>{{ qnaDetail.title }} <img v-if="qnaDetail.secretYn === 'Y'" src="/src/assets/images/icon-lock.png" /></p>
                                </div>
                                <span>작성일 <i>{{ $yyyymmddOrHHmm(qnaDetail.createDate) }}</i></span>
                                <span>작성자 <i>{{ qnaDetail.createUserName || '작성자' }}<span v-if="qnaDetail.createUserLoginId">({{ qnaDetail.createUserLoginId }})</span></i></span>
                                <span>조회수 <i>{{ $number(qnaDetail.hitCount) }}</i></span>
                            </div>
                            <div class="body">
                                <div class="text" v-html="qnaDetail.content"></div>
                                <div class="answer">
                                    <span>답변</span>
                                    <p>
                                        {{ qnaDetail.answererUserName }}
                                        <span v-if="qnaDetail.answererUserLoginId">({{ qnaDetail.answererUserLoginId }})</span>
                                    </p>
                                    <div v-if="qnaInput.isEdit">
                                        <KSATextArea class="textarea-box" v-model="qnaInput.answer" :msg="qnaInputError.answer" :displayMaxLenYn="false" placeholder="답변을 입력하세요." max-length="500"/>
                                        <div class="text-right">
                                            <KSATextButton v-if="qnaDetail.answer" class="btn save" text="취소" @click="onClickEditCancel" />&nbsp;
                                            <KSATextButton class="btn save" text="저장하기" @click="onClickSaveAnswer" :disabled="!qnaInput.answer" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="text">
                                            <div v-html="qnaDetail.answer"></div>
                                            <p>{{ $yyyymmddOrHHmm(qnaDetail.answerDate) }}</p>
                                        </div>
                                        <KSATextButton class="btn edit black_button" text="수정하기" @click="onClickEdit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap text-left mb-xs">
                        <KSASecondaryButton class="gray_button" text="목록" @click="goQnAListView" />
                    </div>
                </div>
                <!-- e : Q&A -->

                <!-- s : 공지사항 -->
                <div v-show="tabIndex === 4">
                    <div v-if="viewMode.notice === 'R'">
                        <div class="list-top">
                            <KSASecondaryButton class="gray_button" text="등록하기" @click="onClickCreateNotice" />
                        </div>
                        <div class="list-top">
                            <p>총 게시물 <span>{{ $number(totalCount) }}</span>건</p>
                        </div>
                        <div class="table-form">
                            <table class="horizontal">
                                <tr>
                                    <th>번호</th>
                                    <th>제목</th>
                                    <th>작성자</th>
                                    <th>작성일</th>
                                    <th>조회수</th>
                                </tr>
                                <tr v-for="board in noticeList">
                                    <td>{{ board.boardNo }}</td>
                                    <td class="text-left"><a href="javascript:void(0);" @click="onClickNoticeDetail(board.boardId)">{{ board.title}} <img v-if="board.attachList.length > 0 && board.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></a></td>
                                    <td>{{ board.createUserName }}</td>
                                    <td>{{ $yyyymmddOrHHmm(board.createDate) }}</td>
                                    <td>{{ $number(board.hitCount) }}</td>
                                </tr>
                            </table>
                            <div class="result-none" v-if="noticeList.length === 0">
                                등록된 공지사항이 없습니다.
                            </div>
                        </div>
                        <Paginate
                            v-if="noticeList.length > 0"
                            v-model="currentPage"
                            :page-count="totalPages"
                            :click-handler="onSelectPage"
                        />
                    </div>
                    <div v-else-if="viewMode.notice === 'C'">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="110px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>제목</th>
                                    <td>
                                        <KSAInput v-model="noticeInput.title" :msg="noticeInputError.title" placeholder="제목을 입력하세요."/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td>
                                        <KSACKEditor ref="editorRef" v-model="noticeInput.content" container="private"/>
                                        <div>
                                            <small style="color:#E71414;">{{ noticeInputError.content }}</small>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부</th>
                                    <td class="text-box">
                                        <div class="grid file" v-for="attach in noticeInput.attachList">
                                            <KSAPrimaryButton text="파일 선택" @click="uploadNoticeFile" />
                                            <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                            <button class="delete" @click="removeNoticeFile" :disabled="!attach.fileName"><i></i></button>
                                        </div>
                                        <small>최대 {{ MAX_SIZE || 200 }}MB 까지 첨부 가능</small>
                                    </td>
                                </tr>
                                <tr>
                                    <th>보안 PDF 업로드</th>
                                    <td class="text-box">
                                        <div class="grid file" v-for="attach in noticeInput.securePdfList">
                                            <KSAPrimaryButton text="파일 선택" @click="uploadSecurePdfFile" />
                                            <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                            <button class="delete" @click="removeSecurePdfFile" :disabled="!attach.fileName"><i></i></button>
                                        </div>
                                        <small>최대 {{ MAX_SIZE || 200 }}MB 까지 첨부 가능</small>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="btn-wrap space-between">
                            <KSASecondaryButton class="gray_button" text="취소" @click="viewMode.notice = 'R'" />
                            <KSAPrimaryButton text="등록" :disabled="!noticeInput.title || !noticeInput.content " @click="createNotice" />
                        </div>
                    </div>
                    <div v-else-if="viewMode.notice === 'U'">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="110px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>제목</th>
                                    <td>
                                        <KSAInput v-model="noticeInput.title" :msg="noticeInputError.title" placeholder="제목을 입력하세요."/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td>
                                        <KSACKEditor ref="editorRef" v-model="noticeInput.content" container="private"/>
                                        <div>
                                            <small style="color:#E71414;">{{ noticeInputError.content }}</small>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부</th>
                                    <td class="text-box">
                                        <div class="grid file" v-for="attach in noticeInput.attachList">
                                            <KSAPrimaryButton text="파일 선택" @click="uploadNoticeFile" />
                                            <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                            <button class="delete" @click="removeNoticeFile" :disabled="!attach.fileName"><i></i></button>
                                        </div>
                                        <small>최대 {{ MAX_SIZE || 200 }}MB 까지 첨부 가능</small>
                                    </td>
                                </tr>
                                <tr>
                                    <th>보안 PDF 업로드</th>
                                    <td class="text-box">
                                        <div class="grid file" v-for="attach in noticeInput.securePdfList">
                                            <KSAPrimaryButton text="파일 선택" @click="uploadSecurePdfFile" />
                                            <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                            <button class="delete" @click="removeSecurePdfFile" :disabled="!attach.fileName"><i></i></button>
                                        </div>
                                        <small>최대 {{ MAX_SIZE || 200 }}MB 까지 첨부 가능</small>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="btn-wrap space-between">
                            <KSASecondaryButton class="gray_button" text="취소" @click="viewMode.notice = 'D'" />
                            <KSAPrimaryButton text="저장" :disabled="!noticeInput.title || !noticeInput.content " @click="updateNotice" />
                        </div>
                    </div>
                    <div v-else-if="viewMode.notice === 'D'">
                        <div class="board-detail">
                            <div class="header">
                                <div class="board-title">
                                    <p>{{ noticeDetail.title }}<img v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></p>
                                </div>
                                <span>작성일<i>{{ $yyyymmddOrHHmm(noticeDetail.createDate) }}</i></span>
                                <span>작성자 <i>{{ noticeDetail.createUserName }}<span v-if="noticeDetail.createUserLoginId">({{ noticeDetail.createUserLoginId}})</span></i></span>
                                <span>조회수 <i>{{ $number(noticeDetail.hitCount)}}</i></span>
                            </div>
                            <div class="body">
                                <div class="text" v-html="noticeDetail.content"></div>
                            </div>
                            <div class="control">
                                <dl class="download" v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName">
                                    <dt>첨부</dt>
                                    <dd>
                                        <div v-for="attach in noticeDetail.attachList">
                                            {{ attach.fileName }}
                                            <KSATextButton v-if="attach.fileDir" class="ml-xs" text="다운로드" @click="$downloadForPrivate(attach)" />
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="download" v-if="noticeDetail.securePdfList.length > 0 && noticeDetail.securePdfList[0].fileName">
                                    <dt>보안 PDF</dt>
                                    <dd>
                                        <div v-for="attach in noticeDetail.securePdfList">
                                            {{ attach.fileName }}
                                            <KSATextButton v-if="attach.fileDir" class="ml-xs" text="뷰어로 보기" @click="onClickViewer(attach)" />
                                        </div>
                                    </dd>
                                </dl>
<!--                                <div v-if="noticeDetail.prevBoard && noticeDetail.prevBoard.boardId">이전글<RouterLink :to="{name:'CSNotifyDetail', params:{id: noticeDetail.prevBoard.boardId}}"><span>{{ noticeDetail.prevBoard.title }}</span></RouterLink></div>-->
<!--                                <div v-if="noticeDetail.nextBoard && noticeDetail.nextBoard.boardId">다음글<RouterLink :to="{name:'CSNotifyDetail', params:{id: noticeDetail.nextBoard.boardId}}"><span>{{ noticeDetail.nextBoard.title }}</span></RouterLink></div>-->
                            </div>
                        </div>
                        <div class="btn-wrap space-between">
                            <KSASecondaryButton class="gray_button" text="목록" @click="goNoticeListView" />
                            <div>
                                <KSASecondaryButton text="수정" @click="onClickUpdateNotice" />
                                <KSASecondaryButton class="red_button" text="삭제" @click="onClickDeleteNotice" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- e : 공지사항 -->
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
    <LectureSendMailModal ref="sendMailModal"/>
    <LectureSendSMSModal ref="sendSMSModal"/>
    <LecturePlayerModal ref="lecturePlayerModal"/>
    <LecturePlayerPreviewModal ref="lecturePlayerPreviewModal"/>
    <MyPageResumeModal ref="resumeModal"/>
    <LectureEvalStateChangeModal ref="evalStateChangeModal"/>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .textarea-box {
        height: 100px;
    }
</style>