// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const usePageLoadingStore = defineStore('pageLoadingStore', () => {

    const loading = ref(false);

    return {
        loading,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePageLoadingStore, import.meta.hot))
}