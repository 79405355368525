// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { reactive, watch } from 'vue'

export const useCertificateStore = defineStore('certifiateStore', () => {
    const certificate = reactive({
        lectDetail: {},
        lectIndexes: [],
        userDetail: {},
    });

    const certInStorage = localStorage.getItem('data-lect-cert')
    if (certInStorage) {
        const { lectDetail, lectIndexes, userDetail } = JSON.parse(certInStorage)
        certificate.lectDetail = lectDetail || '';
        certificate.lectIndexes = lectIndexes || '';
        certificate.userDetail = userDetail || '';
    }

    watch(() => certificate, (state) => {
        localStorage.setItem('data-lect-cert', JSON.stringify(state))
    }, { deep: true })

    return {
        certificate,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCertificateStore, import.meta.hot))
}
