<script setup>
    import { ref, watch, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import systemSchedulerService from "../../services/scheduler/systemSchedulerService";
    import SNBSysAdmin from '../../../../layouts/nav/SNBSysAdmin.vue'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useSchedulerDetailStore} from "../../stores/schedulerDetailStore";
    import _ from "lodash";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        retrieveList()
    })

    const list = ref([])

    const _searchInput = ref({
        selectedUserGb: '',
        userGbList: [],
        searchType: 'name', // code 회원코드,name 이름, id 아이디, email 이메일
        searchInput: '',
        searchDurationGb: 'L', // (L 최근 접속일 C 가입일
        searchDurationType: 'A',
        startYmd: '',
        endYmd: '',
    })

    const currentPage = ref(1);
    const size = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const userGbGroup = ref([
        { label:"학생/취업준비생", value: "STUDENT" },
        { label:"재직자/기업", value: "COMPANY" },
        // { label:"재직자/기업-마스터", value: "COMPANY_MASTER" },
        { label:"대학/연구실", value: "SCHOOL" },
        { label:"강사/전문가", value: "TEACHER" },
    ]);

    const searchTypeGroup = ref([
        { label:"이름", value: "name", placeholder: "이름을 입력해주세요."  },
        { label:"회원코드", value: "code", placeholder: "회원코드를 입력해주세요." },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        { label:"이메일", value: "email", placeholder: "이메일을 입력해주세요."  },
    ]);

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: _searchInput.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    const searchDurationGbGroup = ref([
        { label:"최근 접속일", value: "L" },
        { label:"가입일", value: "C" },
    ])

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "A" },
        { label:"기간 검색", value: "B" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const modal = ref(null)
    const modalContent = ref([])

    const retrieveList = () => {
        let paramObj = {
            pageNo: currentPage.value,
            size: size.value,
            searchType: _searchInput.value.searchType,
            searchInput: _searchInput.value.searchInput,
        }

        loadingStore.loading = true;
        systemSchedulerService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    list.value = result.content;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        _searchInput.value.userGb = '';
        _searchInput.value.userGbList = _.map(userGbGroup.value, 'value');
        _searchInput.value.searchType = 'code';
        _searchInput.value.searchInput = '';
        _searchInput.value.searchDurationGb = 'L';
        _searchInput.value.searchDurationType = 'A';
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const onSelectPageSize = (option) => {
        size.value = option.value;
        retrieveList();
    }

    const onClickExcelDownload = () => {

    }

    const onSelectSearchTypeGroup = (option) => {
        _searchInput.value.searchType = option.value;
    }

    const onSelectSearchDurationGbGroup = (option) => {
        _searchInput.value.searchDurationGb = option.value;
    }

    const addScheduler = () => {
        router.push({name: 'SchedulerManageCreate'})
    }

    const goDetail = (trigger) => {
        const schedulerDetailStore = useSchedulerDetailStore();
        schedulerDetailStore.trigger= trigger;
        router.push({name: 'SchedulerManageDetail'})
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBSysAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>스케쥴러 관리</h3>
                </div>
                <div class="filter-wrap open">
                    <p>검색</p>
                    <div class="filter">
                        <dl>
                            <dt>유형</dt>
                            <dd>
                                <KSACheckBoxGroup
                                        className="mr-md"
                                        name="hiyo"
                                        v-model="_searchInput.userGbList"
                                        :options="userGbGroup" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
                            </dt>
                            <dd>
                                <KSAInput class="text-box" v-model="_searchInput.searchInput" :placeholder="searchInputPlaceholder" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                        <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="_searchInput.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;">
                                        <KSADatepickerTerms
                                                :startDate="_searchInput.startYmd"
                                                :endDate="_searchInput.endYmd"
                                                @update:startDate="(value)=> _searchInput.startYmd = value"
                                                @update:endDate="(value)=> _searchInput.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap text-right">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="retrieveList" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <div class="grid">
                        <p>총 게시물 <span>{{ $number(totalCount)}}</span>개</p>
                        <div>
                            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                        </div>
                    </div>
                    <div>
                        <KSASecondaryButton class="gray_button" text="+ 생성" @click="addScheduler" />
                    </div>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>트리거 ID</th>
                            <th>트리거 분류</th>
                            <th>트리거명</th>
                            <th>taskId</th>
                            <th>taskData</th>
                            <th>시작일자</th>
                            <th>작업표현시간(cron)</th>
                            <th>반복표현시간(text)</th>
                            <th>작업시분초</th>
                            <th>반복구분</th>
                            <th>반복주기</th>
                            <th>반복된횟수</th>
                            <th>생성일</th>
                            <th>수정일</th>
                        </tr>
                        <tr v-for="trigger in list" @click="goDetail(trigger)">
                            <td>{{ trigger.triggerId }}</td>
                            <td>{{ trigger.triggerClsf }}</td>
                            <td>{{ trigger.triggerName }}</td>
                            <td>{{ trigger.taskId }}</td>
                            <td>{{ trigger.taskData }}</td>
                            <td>{{ trigger.startYmd }}</td>
                            <td>{{ trigger.expression }}</td>
                            <td>{{ trigger.expressionTxt }}</td>
                            <td>{{ trigger.taskHms }}</td>
                            <td>{{ trigger.repeatGb }}</td>
                            <td>{{ trigger.repeatNum }}</td>
                            <td>{{ trigger.repeatCount }}</td>
                            <td>{{ $yyyymmdd(trigger.createDate) }}</td>
                            <td>{{ $yyyymmdd(trigger.modifyDate) }}</td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>