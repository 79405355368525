<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import fileService from "../../../../../services/fileService";

    onMounted(()=>{
        hidePageLayout();

        const div = document.getElementById("full-container");
        div.addEventListener("contextmenu", (e) => {e.preventDefault()});

        document.removeEventListener('keydown', onKeyDown);
        document.addEventListener('keydown', onKeyDown);

        document.removeEventListener('keyup', onKeyUp);
        document.addEventListener('keyup', onKeyUp);

        try{
            const pdfviewerInStorage = sessionStorage.getItem('pdfviewer');
            const pdfviewer = JSON.parse(pdfviewerInStorage);
            pageCount.value = pdfviewer.pageCount;
        }catch(e){

        }

        downloadPdf();

        document.addEventListener("fullscreenchange", fullscreenchanged);
    })

    function fullscreenchanged(event) {
        // document.fullscreenElement will point to the element that
        // is in fullscreen mode if there is one. If there isn't one,
        // the value of the property is null.
        if (document.fullscreenElement) {
            // console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
        } else {
            // console.log("Leaving fullscreen mode.");
        }
    }


    onBeforeUnmount(()=>{
        sessionStorage.removeItem('pdfviewer')
    })

    const currentPage = ref(1)
    const pageCount = ref(1);

    const imgSrc = ref('')
    const isInActive = ref(true)

    const isFitScreen = ref(false);

    const VIEW = {
        "FIRST": 'first',
        "CURSOR": 'cursor',
        "KEYBOARD": 'keyboard',
        "LOADING": 'loading',
        "WATERMARK": 'watermark',
    };

    const viewStatus = ref(VIEW.FIRST);

    const modal = ref(null)
    const modalContent = ref([]);

    let timer;

    const downloadPdf = () => {
        fileService.securePdfDownload(currentPage.value)
            .then( async (res) => {
                // console.log(res);
                imgSrc.value = `data:image/png;base64,${res.data}`;
                drawImage();
            }).catch( (error) =>  {
            // console.log(error);
            // loadingStore.loading = false;
        });
    }

    const drawImage = () => {
        const canvas = document.getElementById('myCanvas');
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = imgSrc.value;
        img.onload = function(event){
            URL.revokeObjectURL(event.target.src)
            // console.log(`width: ${img.width}, height: ${img.height}`)
            canvas.setAttribute('width', img.width);
            canvas.setAttribute('height', img.height);
            ctx.drawImage(img,0, 0, img.width, img.height);
        }
    }

    const onClickPrev = () => {
        if(currentPage.value > 1) {
            currentPage.value--;
            downloadPdf();
        }
    }

    const onClickNext = () => {
        if(currentPage.value < pageCount.value) {
            currentPage.value++;
            downloadPdf();
        }
    }

    const onClickFirstPage = () => {
        currentPage.value = 1;
        downloadPdf();
    }

    const onClickLastPage = () => {
        currentPage.value = pageCount.value;
        downloadPdf();
    }

    const hidePageLayout = () => {
        try {
            let header = document.querySelector('header');
            let footer = document.querySelector('footer');
            let globalNav = document.querySelector('.global-nav');
            header.style.display = "none";
            footer.style.display = "none";
            globalNav.style.display = "none";
        } catch(e){}
    }

    const onMouseEnter = () => {
        // console.log("mouse enter");
        //isInActive.value = false;
    }

    const onMouseLeave = () => {
        // console.log("mouse leave");
        if ( timer ) {
            clearTimeout(timer);
        }

        if(isInActive.value) {
            viewStatus.value = VIEW.CURSOR;
            return false;
        }

        isInActive.value = true;
        viewStatus.value = VIEW.CURSOR;
    }

    const onKeyDown = (e)  => {
        // console.log(e.keyCode);
        if(e.keyCode === 122) { // f11
        } else if(e.keyCode === 27) { // esc
        } else {
            if ( timer ) {
                clearTimeout(timer);
            }

            if(isInActive.value) {
                viewStatus.value = VIEW.KEYBOARD;
                return false;
            }

            isInActive.value = true;
            viewStatus.value = VIEW.KEYBOARD;

            e.cancelBubble = true;
            e.preventDefault();
            e.stopImmediatePropagation();
            e.returnValue = false;
            return false;
        }
    }

    const onKeyUp = (e)  => {
        if(e.keyCode === 122) {
        } else if(e.keyCode === 27) { // esc
        } else {
            if ( timer ) {
                clearTimeout(timer);
            }

            if(isInActive.value) {
                viewStatus.value = VIEW.KEYBOARD;
                return false;
            }
            isInActive.value = true;
            viewStatus.value = VIEW.KEYBOARD;
            navigator.clipboard.writeText('');
            e.preventDefault();
            e.returnValue = false;
        }
    }

    const showPdf = () => {
        viewStatus.value = VIEW.LOADING;
        timer = setTimeout(()=>{
            viewStatus.value = VIEW.WATERMARK;
            timer = setTimeout(()=>{
                isInActive.value = false;
            }, 1000);
        }, 1000);
    }

    const onClickFullScreen = () => {
        const fullScreenElement = document.querySelector('.viewer-wrap');
        if (document.fullscreenElement) {
            // exitFullscreen is only available on the Document object.
            document.exitFullscreen();
        } else {
            fullScreenElement.requestFullscreen();
        }
    }
</script>

<template>
    <div class="viewer-wrap" id="full-container" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" style="height:100vh;">
        <div class="screen-wrap">
            <div class="screen" id="container" :class="{'inactive': isInActive, 'scroll-y': isFitScreen }">
                <div class="guide-box">
                    <img v-if="viewStatus === VIEW.CURSOR" src="/src/assets/images/pdf/icon-cursor.png" /> <!-- 커서 이탈 감지 -->
                    <img v-else-if="viewStatus === VIEW.KEYBOARD" src="/src/assets/images/pdf/icon-keyboard.png" /> <!-- 키보드 사용 감지 -->
                    <img v-else-if="viewStatus === VIEW.FIRST" src="/src/assets/images/pdf/icon-document.png" /> <!-- 보안 문서 열기 -->
                    <img v-else-if="viewStatus === VIEW.LOADING" src="/src/assets/images/pdf/icon-privacy.png" /> <!-- 보안 문서 여는 중 -->
                    <img v-else-if="viewStatus === VIEW.WATERMARK" src="/src/assets/images/pdf/icon-watermark.png" /> <!-- 워터마크 적용중 -->
                    <h1>
                        <span v-if="viewStatus === VIEW.FIRST">보안 문서 열기</span>
                        <span v-if="viewStatus === VIEW.CURSOR">커서 이탈 감지</span>
                        <span v-if="viewStatus === VIEW.KEYBOARD">키보드 사용 감지</span>
                        <span v-if="viewStatus === VIEW.LOADING">보안 문서 여는중</span>
                        <span v-if="viewStatus === VIEW.WATERMARK">워터마크 적용중</span>
                    </h1>
                    <p v-if="viewStatus !== VIEW.LOADING && viewStatus !== VIEW.WATERMARK">문서를 보려면 버튼을 누르세요.</p>
                    <p v-else-if="viewStatus === VIEW.LOADING || viewStatus === VIEW.WATERMARK">보안 처리 중입니다.<br/>잠시만 기다려 주세요.</p>
                    <button v-if="viewStatus !== VIEW.LOADING && viewStatus !== VIEW.WATERMARK" class="btn" @click="showPdf">문서 보기</button>
                </div>
                <canvas id="myCanvas"></canvas>
            </div>
        </div>
        <div class="control-bar">
            <div class="pdf-mark">
                <span>PDF</span>
                <span>Security</span>
                <p v-if="isInActive && (viewStatus === VIEW.CURSOR || viewStatus === VIEW.KEYBOARD)"><img src="/src/assets/images/pdf/mark-policy.png" />
                    {{ viewStatus === VIEW.CURSOR ? '커서 이탈 감지되었습니다.' : '키보드 사용이 감지되었습니다.'}}
                </p>
                <p v-if="!isInActive"><img src="/src/assets/images/pdf/mark-verified.png" />워터마크 적용중. 커서가 화면 밖으로 나가거나 키보드를 조작하면 화면이 차단됩니다.</p>
            </div>
            <div class="page-control">
                <button @click="onClickFirstPage">
                    <img src="/src/assets/images/pdf/arrow-first.png"/>
                </button>
                <button class="prev" @click="onClickPrev" :disabled="currentPage === 1">
                    <img src="/src/assets/images/pdf/arrow-prev.png" />
                </button>
                <p>{{ currentPage }} of {{ pageCount }}</p>
                <button class="next" @click="onClickNext" :disabled="currentPage === pageCount">
                    <img src="/src/assets/images/pdf/arrow-next.png" />
                </button>
                <button @click="onClickLastPage">
                    <img src="/src/assets/images/pdf/arrow-last.png" />
                </button>
            </div>
            <div class="screen-control">
                <button @click="isFitScreen = !isFitScreen;"><img src="/src/assets/images/pdf/icon-fitscreen.png" /></button>
                <button @click="onClickFullScreen"><img src="/src/assets/images/pdf/icon-fullscreen.png" /></button>
            </div>
        </div>
    </div>
<!--    <AlertModal ref="modal" :content="modalContent" />-->
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
    .viewer-wrap {
        position: relative;
        width: 100%;
    }
    .screen-wrap {
        width: 100%;
        height: calc(100vh - 50px);
        display: flex;
        align-items: center;
        justify-items: center;
        overflow: auto;
        .screen {
            position: relative;
            top: 0;
            left: 0;
            width: fit-content;
            height: fit-content;
            margin: auto;
            &.scroll-y {
                canvas {
                    width: 100%;
                    height: 100%;
                }
            }
            &.inactive {
                &:after {
                    content: "";
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: calc(100vh - 50px);
                    background-color: $black_color;
                }
                canvas {
                    width: 100vw;
                    height: calc(100vh - 50px);
                }
                .guide-box {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100vw;
                    height: 100vh;
                    color: #fff;
                    z-index: 1;
                    text-align: center;
                    transform: translate3d(-50%, -50%, 0);
                    h1 {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 38px;
                    }
                    p {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 27px;
                        margin-top: 8px;
                    }
                    button {
                        color: #383838;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 27px;
                        background: #fff;
                        border-radius: 5px;
                        border: 0;
                        cursor: pointer;
                        padding: 10px 28px;
                        margin-top: 25px;
                        &:hover {
                            color: #333E9D;
                        }
                    }
                }
            }
            .guide-box {
                display: none;
            }
            canvas {
                display: block;
                width: fit-content;
                height: calc(100vh - 50px);
                margin: auto;
            }
        }
    }
.control-bar {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #3D3D3D;
    color: #fff;
    padding: 0 20px;
    > div {
        display: flex;
        align-items: center;
        img {
            vertical-align: middle;
        }
        &.pdf-mark {
            span {
                border: 1px dashed #FFFFFF;
                font-size: 18px;
                line-height: 25px;
                padding: 0 5px;
                &:first-child {
                    background: #fff;
                    font-weight: 600;
                    color: #3D3D3D;
                }
            }
            p {
                font-weight: 300;
                font-size: 14px;
                line-height: 19px;
                margin-left: 8px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                img {
                    vertical-align: bottom;
                }
            }
        }
        &.page-control {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            p {
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                margin: 0 12px;
            }
            button {
                width: 30px;
                margin: 0 2px;
            }
        }
    }
    button {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        border-radius: 5px;
        padding: 0;
        &:hover {
            background: #292929;
        }
    }
}

@media screen and (max-width: 1540px) {
    .control-bar {
        > div {
            &.pdf-mark {
                max-width: 630px;
                p {
                    max-width: 510px;
                }
            }
        }
    }
}
</style>