import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useMypageProposalStore = defineStore('mypageProposalStore', () => {

    const proposalList= ref([]);
    const proposalUserList= ref([]);

    const setProposalList = (list) => {
        proposalList.value = list;
    }

    const setProposalUserList = (list) => {
        proposalUserList.value = list;
    }

    return {
        proposalList,
        setProposalList,
        proposalUserList,
        setProposalUserList
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMypageProposalStore, import.meta.hot))
}