<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, onMounted, defineExpose } from "vue";
import {usePageLoadingStore} from "../../../../../stores/pageLoading";
import KSAPrimaryButton from "../../../../../components/ui-components/button/KSAPrimaryButton.vue";
import surveyService from "../../../../admin/services/lecture/surveyService";

const loadingStore = usePageLoadingStore()

const baseModal = ref(null);
const resolvePromise = ref(null);

const modal = ref(null);
const modalContent = ref('');

const totalCount = ref(0);
let isDisabled = false;

onMounted(()=>{
    setTimeout(()=>{
        baseModal.value.isAdminWrap = true;
    }, 500);
})

const surveyPaperQuestionList = ref([]); // 만족도 평가 평가문항
const userSurveyAnswerList = ref([]);    // 기작성한 만족도 평가 답안
const surveyAnswer = ref({ // 임시저장용 만족도 답변
    userId: 0,       // 사용자아이디
    lectId: 0,       // 강의아이디
    regDt: "",       // 신청일시
    surveyId: 0,     // 만족도평가아이디
    surveyQId: 0,    // 만족도문항아이디
    surveyQType: "", // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
    answer: "",      // 작성답안 - 7점,5점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
    answerEtc: ""   // 기타작성답안(다중응답형)
});
const input = ref({    // 제출용 만족도 답변
    userId: 0,     // 사용자아이디
    lectId: 0,     // 강의아이디
    regDt: "",     // 신청일시
    surveyId: 0,   // 만족도평가아이디
    answerList: [  // 답변리스트
        /* 예시
        {
            surveyQId: 1,    // 만족도문항아이디
            surveyQType: "01", // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
            answer: "3",      // 작성답안 - 7점,5점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
            answerEtc: "4"   // 기타작성답안(다중응답형)
        }
        */
    ],
})

const retrieveSurveyPaperQuestionDetail = (id) => {
    loadingStore.loading = true;
    surveyService.retrievePaperQuestion(id)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                surveyPaperQuestionList.value = result;

                if ( surveyPaperQuestionList.value.length > 0 ) {
                    for( let i = 0; i < surveyPaperQuestionList.value.length; i++ ) {
                        let multiAnswerList= [];
                        //console.log("surveyPaperQuestionList.value[i].choiceList[0].surveyChoiceContent : " + surveyPaperQuestionList.value[i].choiceList[0].surveyChoiceContent);
                        input.value.answerList.push({
                            surveyQId: surveyPaperQuestionList.value[i].surveyQId,
                            surveyQType: surveyPaperQuestionList.value[i].surveyQType, // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
                            answer: "",      // 작성답안 - 7점,5점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
                            checkEtc: false,   // 기타작성답안 체크여부
                            answerEtc: "",   // 기타작성답안(다중응답형)
                            multiAnswerList: multiAnswerList, // 다중응답형 작성답안리스트
                        });
                    }
                }
                // 기작성한 내용 조회
                retrieveUserSurveyAnswer();
            }
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

const retrieveUserSurveyAnswer = () => {
    if ( !isDisabled ) {
        loadingStore.loading = true;
        surveyService.retrieveUserSurveyAnswerByUserLecture(input.value.userId, input.value.lectId, input.value.regDt)
            .then((res) => {
                loadingStore.loading = false;
                if (res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    userSurveyAnswerList.value = result;

                    if (userSurveyAnswerList.value.length > 0) {
                        for (let i = 0; i < userSurveyAnswerList.value.length; i++) {
                            for (let j = 0; j < input.value.answerList.length; j++) {
                                //console.log("input.value.answerList["+j+"].surveyQType : " + input.value.answerList[j].surveyQType);
                                if (input.value.answerList[j].surveyQId === userSurveyAnswerList.value[i].surveyQId) {
                                    if (input.value.answerList[j].surveyQType === '01') {
                                        input.value.answerList[j].answer = userSurveyAnswerList.value[i].answer1;
                                    } else if (input.value.answerList[j].surveyQType === '04') {
                                      input.value.answerList[j].answer = userSurveyAnswerList.value[i].answer1;
                                    } else if (input.value.answerList[j].surveyQType === '02') {
                                        input.value.answerList[j].answer = userSurveyAnswerList.value[i].answer2;
                                    } else if (input.value.answerList[j].surveyQType === '03') {
                                        input.value.answerList[j].multiAnswerList = userSurveyAnswerList.value[i].answer3.split(',');
                                        //console.log("input.value.answerList["+j+"].multiAnswerList : " + input.value.answerList[j].multiAnswerList)
                                        if (userSurveyAnswerList.value[i].answerEtc !== null && userSurveyAnswerList.value[i].answerEtc !== '') {
                                            input.value.answerList[j].checkEtc = true;
                                            input.value.answerList[j].answerEtc = userSurveyAnswerList.value[i].answerEtc;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }).catch((error) => {
            loadingStore.loading = false;
            console.log(error);
        });
    }
}

const createUserSurvey = () => {
    if ( !isDisabled ) {
        const {userId, lectId, regDt, surveyId, answerList} = input.value;
        let isInvalid = false;
        let answerSum = 0; // 7점,5점 척도 총점수
        let answerCnt = 0; // 7점,5점 척도 문항수
        let result = 0;    // 결과(7점,5점 척도 평균)

        // 필수파라미터 확인
        if (userId === null || userId === ""
            || lectId === null || lectId === ""
            || regDt === null || regDt === ""
            || surveyId === null || surveyId === "") {
            modalContent.value = [`오류가 발생하였습니다. 새로고침 후 다시 진행해주시기 바랍니다.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }

        // 모든 문항에 답을 입력했는지 확인
        // 모든 답 다시 한번 저장
        for (let i = 0; i < answerList.length; i++) {
            //console.log("answerList["+i+"].surveyQId : " + answerList[i].surveyQId);
            if (answerList[i].surveyQType === '01') {
                if (answerList[i].answer === null || answerList[i].answer === '') {
                    modalContent.value = [`모든 문항에 답을 입력해주세요.`];
                    modal.value.show();
                    isInvalid = true;
                    return false;
                } else {
                    onClickAnswer1(i, answerList[i].surveyQId, answerList[i].surveyQType, answerList[i].answer);
                    answerSum = answerSum + answerList[i].answer;
                    answerCnt = answerCnt + 1;
                }
            } else if (answerList[i].surveyQType === '04') {
              if (answerList[i].answer === null || answerList[i].answer === '') {
                modalContent.value = [`모든 문항에 답을 입력해주세요.`];
                modal.value.show();
                isInvalid = true;
                return false;
              } else {
                onClickAnswer1(i, answerList[i].surveyQId, answerList[i].surveyQType, answerList[i].answer);
                answerSum = answerSum + answerList[i].answer;
                answerCnt = answerCnt + 1;
              }
            } else if (answerList[i].surveyQType === '02') {
                if (answerList[i].answer === null || answerList[i].answer === '') {
                    modalContent.value = [`모든 문항에 답을 입력해주세요.`];
                    modal.value.show();
                    isInvalid = true;
                    return false;
                } else {
                    onChangeAnswer2(i, answerList[i].surveyQId, answerList[i].surveyQType);
                }
            } else if (answerList[i].surveyQType === '03') {

                if (answerList[i].multiAnswerList === null || answerList[i].multiAnswerList.length === 0) {
                    modalContent.value = [`모든 문항에 답을 입력해주세요.`];
                    modal.value.show();
                    isInvalid = true;
                    return false;
                } else if (answerList[i].checkEtc && (answerList[i].answerEtc === null || answerList[i].answerEtc === '')) {
                    modalContent.value = [`기타에 체크한 경우 답을 입력해주세요.`];
                    modal.value.show();
                    isInvalid = true;
                    return false;
                } else {
                    onChangeAnswer3(i, answerList[i].surveyQId, answerList[i].surveyQType);
                }
            }
        }

        if (!isInvalid) {
            result = answerSum / answerCnt;

            let paramObjSurvey = {
                userId, lectId, regDt, surveyId, result
            }

            loadingStore.loading = true;
            surveyService.createUserSurvey(paramObjSurvey)
                .then(async (res) => {
                    loadingStore.loading = false;
                    if (res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        modalContent.value = ["만족도 평가를 등록했습니다."];
                        const ok = await modal.value.show();
                        if(ok) {
                            confirm();
                        }
                    } else {
                        modalContent.value = ["등록을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch((res) => {
                console.log(res);
                loadingStore.loading = false;
            });
        }
    }
}

// 7점척도, 5점척도 수정시 답안 저장
const onClickAnswer1 = (index, surveyQId, surveyQType, answer) => {
    surveyAnswer.value.surveyQId = surveyQId;
    surveyAnswer.value.surveyQType = surveyQType; // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
    surveyAnswer.value.answer = answer;          // 작성답안 - 7점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
    surveyAnswer.value.answerEtc = "";          // 기타작성답안(다중응답형)

    input.value.answerList[index].answer = answer;

    let paramObj1 = {
        userId: surveyAnswer.value.userId,
        lectId: surveyAnswer.value.lectId,
        regDt: surveyAnswer.value.regDt,
        surveyId: surveyAnswer.value.surveyId,
        surveyQId: surveyAnswer.value.surveyQId,
        answer1: surveyAnswer.value.answer
    };

    if ( !isDisabled ) {
        saveSurveyAnswer(paramObj1);
    }
};

const onChangeAnswer2 = (index, surveyQId, surveyQType) => {
    surveyAnswer.value.surveyQId = surveyQId;
    surveyAnswer.value.surveyQType = surveyQType; // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
    surveyAnswer.value.answer = input.value.answerList[index].answer;          // 작성답안 - 7점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
    surveyAnswer.value.answerEtc = "";          // 기타작성답안(다중응답형)

    let paramObj2 = {
        userId: surveyAnswer.value.userId,
        lectId: surveyAnswer.value.lectId,
        regDt: surveyAnswer.value.regDt,
        surveyId: surveyAnswer.value.surveyId,
        surveyQId: surveyAnswer.value.surveyQId,
        answer2: surveyAnswer.value.answer
    };

    if ( !isDisabled ) {
        saveSurveyAnswer(paramObj2);
    }
};

const onChangeAnswer3 = (index, surveyQId, surveyQType) => {
    surveyAnswer.value.surveyQId = surveyQId;
    surveyAnswer.value.surveyQType = surveyQType; // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
    surveyAnswer.value.answer = input.value.answerList[index].multiAnswerList.toString();          // 작성답안 - 7점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
    if (input.value.answerList[index].checkEtc) {
        surveyAnswer.value.answerEtc = input.value.answerList[index].answerEtc;          // 기타작성답안(다중응답형)
    } else {
        surveyAnswer.value.answerEtc = "";          // 기타작성답안(다중응답형)
    }

    let paramObj3 = {
        userId: surveyAnswer.value.userId,
        lectId: surveyAnswer.value.lectId,
        regDt: surveyAnswer.value.regDt,
        surveyId: surveyAnswer.value.surveyId,
        surveyQId: surveyAnswer.value.surveyQId,
        answer3: surveyAnswer.value.answer,
        answerEtc: surveyAnswer.value.answerEtc
    };

    if ( !isDisabled ) {
        saveSurveyAnswer(paramObj3);
    }
};

const saveSurveyAnswer = (paramObj) => {
    if ( !isDisabled ) {
        loadingStore.loading = true;
        surveyService.saveUserSurveyAnswer(paramObj)
            .then(async (res) => {
                loadingStore.loading = false;
                if (res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                }
            }).catch((res) => {
            //console.log(res);
            loadingStore.loading = false;
        });
    }
};

const saveUserSurvey = (paramObj) => {
    if ( !isDisabled ) {
        loadingStore.loading = true;
        surveyService.saveUserSurveyAnswer(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                }
            }).catch( (res) =>  {
            //console.log(res);
            loadingStore.loading = false;
        });
    }
};

const show = () => {
    baseModal.value.open();
    return new Promise((resolve, _) => {
        resolvePromise.value = resolve;
    });
};

const confirm = () => {
    baseModal.value.close();
    resolvePromise.value(true);
};

const cancel = () => {
    baseModal.value.close();
    resolvePromise.value(false);
};

const init = (userId, lectId, regDt, surveyId) => {
    // 기본값 저장
    input.value = {
        userId: userId,
        lectId: lectId,
        regDt: regDt,
        surveyId: surveyId,
        answerList: [],
    }
    surveyAnswer.value = {
        userId: userId,
        lectId: lectId,
        regDt: regDt,
        surveyId: surveyId,
    }

    if ( userId === 0 && lectId === 0 && regDt === 0 ) { // 관리자 > 만족도 평가관리 > 미리보기
        // 모든 이벤트, 제출하기 비활성화
        isDisabled = true;
    }

    // 만족도 평가 문항 조회
    retrieveSurveyPaperQuestionDetail(surveyId);
}

defineExpose({
    show,
    init,
})

</script>
<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size request-modal">
            <div class="modal-title">
                <h3>온라인 강의 만족도 평가</h3>
                <button class="close" @click="cancel"></button>
            </div>
            <div class="modal-body">
                <div class="question-wrap" v-for="(surveyPaperQuestion, index) in surveyPaperQuestionList">
                    <div class="item question-number" v-if="surveyPaperQuestion.surveyQType === '01'">
                        <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                        <ul>
                            <li :class="{'answer': input.answerList[index].answer === 1}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, 1)">
                                <i>1</i><span>매우 낮음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 2}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, 2)">
                                <i>2</i><span>낮음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 3}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, 3)">
                                <i>3</i><span>다소 낮음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 4}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, 4)">
                                <i>4</i><span>보통</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 5}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, 5)">
                                <i>5</i><span>다소 높음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 6}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, 6)">
                                <i>6</i><span>높음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 7}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, 7)" >
                                <i>7</i><span>매우 높음</span>
                            </li>
                        </ul>
                    </div>
                    <div class="item question-number" v-if="surveyPaperQuestion.surveyQType === '04'">
                      <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                      <ul v-for="(point, index2) in surveyPaperQuestion.pointOptions">
                        <li :class="{'answer': input.answerList[index].answer === index2+1}" @click="onClickAnswer1(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType, index2+1)">
                          <i>{{ index2+1 }}</i><span>{{ point.surveyChoiceContent }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="item question-text" v-if="surveyPaperQuestion.surveyQType === '02'">
                        <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                        <KSATextArea style="height: 100px" class="textarea-box" v-model="input.answerList[index].answer" placeholder="답변을 입력하세요." @focusout="onChangeAnswer2(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType)" />
                    </div>
                    <div class="item question-checkbox" v-if="surveyPaperQuestion.surveyQType === '03'">
                        <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                        <KSACheckBox
                            class="ml-xs"
                            v-for="choice in surveyPaperQuestion.choiceList"
                            v-model="input.answerList[index].multiAnswerList"
                            :name="`checkedAnswers${index}`"
                            :label="choice.surveyChoiceContent"
                            :value="choice.surveyChoiceId"
                            @change="onChangeAnswer3(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType)"
                        />
                        <div class="etc" v-if="surveyPaperQuestion.etcYn === 'Y'">
                            <KSACheckBox class="ml-xs" label="기타" v-model="input.answerList[index].checkEtc" @change="onChangeAnswer3(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType)" />
                            <KSAInput v-model="input.answerList[index].answerEtc" @change="onChangeAnswer3(index, surveyPaperQuestion.surveyQId, surveyPaperQuestion.surveyQType)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <KSAPrimaryButton text="제출하기" @click="createUserSurvey" :disabled="isDisabled" />
            </div>
        </div>
        <AlertModal ref="modal" :content="modalContent" />
    </Modal>
</template>
<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
    .close {
        top: 23px !important;
    }
}
</style>