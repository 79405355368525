import http from '../../../../api/http'

function retrieveList(paramObj) {
    return http.post('/admin/user/retrieveAllPagingForUserPicker', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 20,
        searchInput: paramObj.searchInput || ''
    })
}

function retrieveListByUserGb(paramObj) {
    return http.post('/admin/user/retrieveUserGbPagingForUserPicker', {
        pageNumber: paramObj.pageNo || 0,
        userGb: paramObj.userGb || 'STUDENT', // STUDENT, TEACHER, ...
        size: paramObj.size || 20,
        searchInput: paramObj.searchInput || ''
    })
}

function retrieveAllUsers(paramObj) {
    return http.post('/admin/user/retrieveAllUsers', {
        pageNumber: paramObj.pageNo || 0,
        userGbList: paramObj.userGbList || [],
        size: paramObj.size || 30,
        searchCorpMasterYn: paramObj.searchCorpMasterYn || "N",
        searchType: paramObj.searchType || "",
        searchInput: paramObj.searchInput || "",
        searchDurationGb: paramObj.searchDurationGb || "L",
        startYmd: paramObj.startYmd || "",
        endYmd:paramObj.endYmd ||  "",
        orderProperty: paramObj.orderProperty ||  "",
        orderDirection: paramObj.orderDirection ||  "",
    })
}

function retrieveUser(userId) {
    return http.post('/admin/user/retrieve', {
        userId
    })
}

function updateCorpMasterYn({userId, corpMasterYn}) {
    return http.post('/admin/user/updateCorpMasterYn', {
        userId,
        corpMasterYn
    })
}

function retrieveUserMyPage(paramObj) {
    return http.get(`/admin/user/retrieveMypage?userId=${paramObj.userId}&propId=${paramObj.propId}&hidden=${paramObj.hidden}`)
}

function retrieveUserLectureMypage(userId, lectId, regDt) {
    return http.get(`/admin/user/retrieveUserLectureMypage?userId=${userId}&lectId=${lectId}&regDt=${regDt}`)
}

function deleteUser(userId) {
    return http.post('/admin/user/withdrawUser', {
        userId
    })
}


function retrieveUserLectureList(userId) {
    return http.get(`/admin/user/retrieveUserLectureList?userId=${userId}`)
}


function retrieveUserLectureListByCreateId(userId) {
    return http.get(`/admin/user/retrieveUserLectureListByCreateId?userId=${userId}`)
}


function downloadAllUsers(paramObj) {
    return http.post('/admin/user/downloadAllUsers', {
        pageNumber: paramObj.pageNo || 0,
        userGbList: paramObj.userGbList || [],
        size: paramObj.size || 30,
        searchType: paramObj.searchType || "",
        searchInput: paramObj.searchInput || "",
        searchDurationGb: paramObj.searchDurationGb || "L",
        startYmd: paramObj.startYmd || "",
        endYmd:paramObj.endYmd ||  ""
    }, {
        responseType: 'blob',
    })
}

function sendEmailAllUsers(paramObj) {
    return http.post('/admin/user/sendEmailAllUsers', {
        pageNumber: paramObj.pageNo || 0,
        userGbList: paramObj.userGbList || [],
        size: paramObj.size || 30,
        searchType: paramObj.searchType || "",
        searchInput: paramObj.searchInput || "",
        searchDurationGb: paramObj.searchDurationGb || "L",
        startYmd: paramObj.startYmd || "",
        endYmd:paramObj.endYmd ||  ""
    })
}

function retrieveAdminDetail(userId) {
    return http.get(`/admin/user/retrieveUserForAdmin?userId=${userId}`)
}

function createAdminAccount(paramObj) {
    return http.post('/admin/user/signupForAdmin', {
        userGb: paramObj.userGb || "92",
        userLoginId: paramObj.userLoginId,
        userLoginPw: paramObj.userLoginPw,
        userName: paramObj.userName,
        phoneNum: paramObj.phoneNum,
        email: paramObj.email,
        accessMenu: paramObj.accessMenu, // string
    })
}

function updateAdminAccount(paramObj) {
    return http.post('/admin/user/updateForAdmin', {
        userGb: paramObj.userGb || "92",
        userId: paramObj.userId,
        userLoginId: paramObj.userLoginId,
        userLoginPw: paramObj.userLoginPw,
        userName: paramObj.userName,
        phoneNum: paramObj.phoneNum,
        email: paramObj.email,
        accessMenu: paramObj.accessMenu, // string
    })
}

function deleteAdminAccount(userId) {
    return http.post('/admin/user/withdrawForAdmin', {
        userId
    })
}

function retrieveAllUserGbHistory(paramObj) {
    return http.post('/admin/user/retrieveAllUserGbHistory', paramObj)
}

export default {
    retrieveList,
    retrieveListByUserGb,
    retrieveAllUsers,
    retrieveUser,
    updateCorpMasterYn,
    retrieveUserMyPage,
    retrieveUserLectureMypage,
    deleteUser,
    retrieveUserLectureList,
    retrieveUserLectureListByCreateId,
    downloadAllUsers,
    sendEmailAllUsers,
    retrieveAdminDetail,
    createAdminAccount,
    updateAdminAccount,
    deleteAdminAccount,
    retrieveAllUserGbHistory,
}