// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useSasTokenStore = defineStore('sasTokenStore', () => {

    const sasToken = ref('');

    return {
        sasToken,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSasTokenStore, import.meta.hot))
}