import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '../pages/user/stores/user'
import { useNavigationStore } from '../stores/navigation'

import UserRoutes from './user'
import AdminRoutes from './admin'
import SyadminRoutes from './sysadmin'

import NotFoundView from '../pages/NotFound.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...UserRoutes,
    ...AdminRoutes,
    ...SyadminRoutes,
    {
      path: "/404",
      name: "NotFound",
      component: NotFoundView,
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
    },
  ]
})


const public_pages = [
    "Home",
  "AboutCompany",
  "AboutDirector",
  "AboutWithus",
  "AboutHistory",
  "Signin",
  "SigninSearchID",
  "SigninSearchIDComplete",
  "SigninSearchPW",
  "SigninSearchPWReset",
  "SigninJoinCertify",
  "SigninJoinMemberType",
  "SigninJoinTerms",
  "SigninJoinMemberInfoStudent",
  "SigninJoinComplete",
  "SignupCertify",
  "SignupMemberType",
  "SignupTerms",
  "SignupMemberInfo",
  "SignupComplete",
  "InsightsNotify",
  "InsightsNotifyDetail",
  "InsightsFAQ",
  "InsightsQNA",
  "InsightsQNADetail",
  "InsightsNewsletter",
  "InsightsNewsletterDetail",
];

router.beforeEach(async (to, from, next) => {
  const { isAdmin, isAuthenticated } = useUserStore();
  const userStore = useUserStore();
  const navigationStore = useNavigationStore();

  if(['Signup', 'Signin', 'SigninAdmin', 'SigninSysAdmin'].indexOf(to.name) > -1) {
    userStore.setUser({});
  }

  if(to.matched.some((record) => record.meta.admin)) { // 관리자 페이지 진입시
    navigationStore.isAdminView = true;
    navigationStore.isSysAdminView = to.matched.some((record) => record.meta.sysadmin); // 시스템관리자 view 체크

    if(isAdmin) {
      if(!isAuthenticated) {
        next({name: navigationStore.isSysAdminView ? 'SigninSysAdmin' : 'SigninAdmin'});
      }
    } else { // 일반 사용자
      next({name: 'Home'});
    }
  } else {
    navigationStore.isAdminView = false;
    navigationStore.isSysAdminView = false;
  }

  // console.log(to);
  // console.log(navigationStore.isAdminView);
  const num = navigationStore.isAdminView ? 1 : 0;
  // console.log(num);
  try{
    if(to.matched[0+num]) navigationStore.localNavigation.menuLevel1 = to.matched[0+num].meta.title;
    if(to.matched[1+num]) navigationStore.localNavigation.menuLevel2 = to.matched[1+num].meta.title;
    if(to.matched[2+num]) navigationStore.localNavigation.menuLevel3 = to.matched[2+num].meta.title;
    if(to.matched[3+num]) navigationStore.localNavigation.menuLevel4 = to.matched[3+num].meta.title;
  } catch(e){}
  // console.log(navigationStore.localNavigation)

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if(!isAuthenticated) {
      // console.log(to);
      navigationStore.lastAccessPage = to.fullPath;
      next({name: navigationStore.isAdminView ? 'SigninAdmin' : 'Signin'});
    } else {
      next();
    }
  } else {
    next();
  }

  document.title = to.meta.title || '한국반도체아카데미';
  window.scrollTo(0,0);
})

export default router
