// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useHomeStore = defineStore('homeStore', () => {

    const mainBanners = ref([]);
    const corpBanners = ref([]);
    const noticeList = ref([]);
    const lectureList = ref([]);

    const setMainBanners = (list) => {
        mainBanners.value = list;
    }

    const setCorpBanners = (list) => {
        corpBanners.value = list;
    }

    const setNoticeList = (list) => {
        noticeList.value = list;
    }

    const setLectureList = (list) => {
        lectureList.value = list;
    }

    return {
        mainBanners,
        corpBanners,
        noticeList,
        lectureList,
        setMainBanners,
        setCorpBanners,
        setNoticeList,
        setLectureList
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useHomeStore, import.meta.hot))
}