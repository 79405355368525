<script setup>
    import { ref, reactive, defineEmits, defineExpose, toRefs  } from 'vue';
    import CKEditor from '@ckeditor/ckeditor5-vue'
    // import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
    // import './ckeditor'; // index.html에 script선언함
    import UploadAdapter from "./UploadAdapter";

    const props = defineProps({
        modelValue: { type: String },
        placeholder: {
            type: String,
            default: '내용을 입력하세요.',
        },
        container: {
            type: String,
            default: 'public'
        }
    });
    const { modelValue, placeholder } = toRefs(props);
    const emit = defineEmits(["update:modelValue"]);

    function MyCustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader, props.container)
        }
    }

    const editor = ClassicEditor
    const ckeditor = CKEditor.component
    // ✅ :textarea Data

    const editorData = ref(modelValue.value || '<p></p>');
    // ✅ :config
    const editorConfig = ref({
        toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'subscript',
            'superscript',
            '|',
            'fontBackgroundColor',
            'fontColor',
            'fontSize',
            'highlight',
            '|',
            'alignment',
            'bulletedList',
            'numberedList',
            'indent',
            'outdent',
            '|',
            'imageUpload',
            'insertTable',
            'link',
            // 'specialCharacters',
            '|',
            'horizontalLine',
            'blockQuote',
            'code',
            'codeBlock',
            '|',
            'sourceEditing',
            // 'mediaEmbed',
            // '|',
            // 'undo',
            // 'redo',
        ],
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
        },
        image: {
            resize: true,
            toolbar: ['imageStyle:alignLeft', 'imageStyle:alignRight', 'imageStyle:inline', 'imageStyle:side'],
        },
        extraPlugins: [
            MyCustomUploadAdapterPlugin,
        ],
        title: {
            placeholder: ''
        },
        placeholder: placeholder.value,
        htmlSupport: {
            allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
        },
        link: {
            decorators: {
                openInNewTab: {
                    mode: 'manual',
                    label: '새 창에서 열기',
                    defaultValue: true,			// This option will be selected by default.
                    attributes: {
                        target: '_blank',
                        rel: 'noopener noreferrer'
                    }
                }
            }
        }
    });

    const handleInput = () => {
        // console.log(editorData.value)
        emit('update:modelValue',editorData.value);
    }

    const init = (content) => {
        editorData.value = content;
    }

    defineExpose({
        init
    })
</script>

<template>
    <div>
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" @input="handleInput"></ckeditor>
    </div>
</template>

<style lang="scss" scoped>

</style>