<script setup>
    import { ref, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import surveyService from "../../../services/lecture/surveyService";
    import KSAPrimaryButton from "../../../../../components/ui-components/button/KSAPrimaryButton.vue";
    import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
    import LectureSurveyPaperSearchModal from "../../../components/modal/lecture/LectureSurveyPaperSearchModal.vue";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        const { id } = route.params;
        if(id !== '0') { // 문항 복사. 문항등록인 경우 id가 0
            isCopy = true; // 복사여부
            retrieveSurveyPaperDetail(id);
            retrieveSurveyPaperQuestionDetail(id);
        }
    })

    const surveyPaperSearchModal = ref(null)
    const modal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref('');
    const confirmModalContent = ref('');

    let isCopy = false; // 복사여부

    const input = ref({
        useYn: "Y",        // 사용여부 (Y: 사용, N: 미사용)
        title: "",         // 평가 제목
        surveyQuestionList: [] // 만족도평가 문항 리스트
    })

    // 만족도 평가 기본정보 > 기본정보 조회
    const retrieveSurveyPaperDetail = (id) => {
        loadingStore.loading = true;
        surveyService.retrieveSurveyPaper(id, 0)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    input.value = result;
                    input.value.title = input.value.title + "_복사본";
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    // 만족도 평가 기본정보 > 평가문항 조회
    const retrieveSurveyPaperQuestionDetail = (id) => {
        loadingStore.loading = true;
        surveyService.retrievePaperQuestion(id)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    input.value.surveyQuestionList = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const create = () => {
        const { useYn, title, surveyQuestionList } = input.value;
        let isInvalid = false;

        if( !useYn ) {
            modalContent.value = [`사용여부를 입력하세요.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }
        if( !title ) {
            modalContent.value = [`만족도 평가 제목을 입력하세요.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }
        if( !surveyQuestionList || surveyQuestionList.length === 0 ) {
            modalContent.value = [`만족도 평가 문항을 검색하고 선택하세요.`];
            modal.value.show();
            isInvalid = true;
            return false;
        }

        for( let i = 0; i < surveyQuestionList.length; i++ ) {
            surveyQuestionList[i].order = i+1;
        }

        if(!isInvalid) {
            let paramObj = {
                useYn,
                title,
                surveyPaperQuestionList: surveyQuestionList
            }

            loadingStore.loading = true;
            surveyService.createSurveyPaper(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;

                        if ( isCopy ) { // 만족도 평가 복사
                            modalContent.value = ["만족도 평가를 복사했습니다."];
                        } else {        // 만족도 평가 등록
                            modalContent.value = ["만족도 평가를 등록했습니다."];
                        }

                        modal.value.show();
                        router.push({name: 'LectureSurveyPaperList'});
                    } else {
                        modalContent.value = ["등록을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                console.log(res);
                loadingStore.loading = false;
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    const onClickSearchModal = async () => {
        surveyPaperSearchModal.value.init();
        const result = await surveyPaperSearchModal.value.show();

        //console.log("result.length : " + result.length);
        const { surveyQuestionList } = input.value;
        if( result.length > 0 ) { // 문항추가
            for( let i = 0; i < result.length; i++ ) {
                //console.log("result["+i+"] : " + result[i].surveyQId);
                let duplicated = false; // 기존 리스트와 중복되는지 확인
                for( let j = 0; !duplicated && j < surveyQuestionList.length; j++ ) {
                    //console.log("surveyQuestionList["+j+"] : " + surveyQuestionList[j].surveyQId);
                    if( result[i].surveyQId === surveyQuestionList[j].surveyQId ) {
                        //console.log("duplicated");
                        duplicated = true;
                    }

                    //console.log("result["+i+"].choiceList["+j+"] : " + result[i].choiceList[j].surveyChoiceContent);
                }
                if ( !duplicated ) {
                    surveyQuestionList.push({
                        surveyId: 0,
                        surveyQId: result[i].surveyQId,
                        order: 0,
                        deleteYn: "N",
                        surveyQGb: result[i].surveyQGb,
                        surveyQType: result[i].surveyQType,
                        surveyQTitle: result[i].surveyQTitle,
                        choiceList: result[i].choiceList,
                        etcYn: result[i].etcYn
                    });
                }
            }
        }
        //console.log("surveyQuestionList : " + surveyQuestionList );
        //input.value.surveyPaperQuestionList = surveyQuestionList;
    }

    const onDeleteQuestion = (surveyQuestion, index) => {
        console.log("onDeleteChoice : " + index);
        const { surveyQuestionList } = input.value;
        surveyQuestion.deleteYn = 'Y';
        surveyQuestionList.splice(index, 1);
    }

    const moveUpQuestion = (index) => {
        if(index > 0) {
            const { surveyQuestionList } = input.value;
            const temp = surveyQuestionList[index-1];
            surveyQuestionList[index-1] = surveyQuestionList[index];
            surveyQuestionList[index] = temp;
        }
    }

    const moveDownQuestion = (index) => {
        const { surveyQuestionList } = input.value;
        if(index < surveyQuestionList.length-1) {
            const temp = surveyQuestionList[index+1];
            surveyQuestionList[index+1] = surveyQuestionList[index];
            surveyQuestionList[index] = temp;
        }
    }

    const onCancel = async () => {
        confirmModalContent.value = ["페이지를 나가겠습니까?", "페이지 이동 시 작성한 내용은 저장되지 않습니다"];
        const ok = await confirmModal.value.show();
        if (ok) { // 나가기
            router.push({name: 'LectureSurveyPaperList'});
        } else { // 취소

        }
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3 v-if="isCopy">평가 복사</h3>
                    <h3 v-else>평가 등록하기</h3>
                    <p><span>* 표시는 필수 입력사항</span> 입니다.</p>
                </div>

                <div class="table-form mb-sm">
                    <table class="vertical">
                        <colgroup>
                            <col width="200px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>사용 여부</th>
                            <td>
                                <KSARadioButton class="mr-md" label="사용" v-model="input.useYn" value="Y" />
                                <KSARadioButton class="mr-md" label="미사용" v-model="input.useYn" value="N" />
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">만족도 평가 제목</th>
                            <td><KSAInput class="text-box" v-model="input.title" placeholder="제목을 입력해주세요." /></td>
                        </tr>
                    </table>
                </div>
                <div class="content-inner">
                    <div class="content-title">
                        <h4>문항 추가(4)</h4>
                    </div>
                    <div class="list-top">
                        <KSASecondaryButton class="gray_button" text="+ 문항 추가" @click="onClickSearchModal" />
                    </div>
                    <div class="table-form">
                        <table class="horizontal">
                            <colgroup>
                                <col />
                                <col width="140px" />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <tr>
                                <th></th>
                                <th>문항 구분</th>
                                <th>문항 유형</th>
                                <th>문항</th>
                                <th>다중응답형 보기</th>
                                <th>순서 변경</th>
                                <th>삭제</th>
                            </tr>
                            <tr v-for="(surveyQuestion, index) in input.surveyQuestionList" >
                                <td>{{ index+1 }}</td>
                                <td>
                                    <div class="icon">
                                        <img src="/src/assets/images/icon-question.png" />
                                        {{ $gbNameKR('SURVEY_Q_TYPE', surveyQuestion.surveyQType) }}
                                    </div>
                                </td>
                                <td>{{ $gbNameKR('SURVEY_Q_GB', surveyQuestion.surveyQGb) }}</td>
                                <td class="text-left">{{ surveyQuestion.surveyQTitle }}</td>
                                <td class="text-left">
                                    <span v-for="choice in surveyQuestion.choiceList">
                                        {{ choice.surveyChoiceContent }}
                                    <br /></span>
                                    <span v-for="point in surveyQuestion.pointOptions">
                                        {{ point.surveyChoiceContent }}
                                    <br /></span>
                                    <span v-if="surveyQuestion.etcYn === 'Y'">
                                        기타
                                    </span>
                                </td>
                                <td>
                                    <span @click="moveUpQuestion(index)" style="display:inline-block; margin-right:5px; cursor: pointer;">▲</span>
                                    <span @click="moveDownQuestion(index)" style="display:inline-block; margin-right:5px; cursor: pointer;">▼</span>
                                </td>
                                <td><KSATextButton class="red_button" text="삭제" @click="onDeleteQuestion(surveyQuestion, index)" /></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="btn-wrap space-between mt-xs">
                    <KSASecondaryButton class="gray_button" text="취소" @click="onCancel" />
                    <KSAPrimaryButton text="등록" @click="create" />
                </div>
            </div>
        </div>
    </div>
    <LectureSurveyPaperSearchModal ref="surveyPaperSearchModal"/>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="confirmModalContent" okButtonText="나가기" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.table-form table {
    &.horizontal {
        tr:hover {
            background: transparent;
        }
        td {
            .icon {
                display: flex;
                align-items: center;
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}
.text-box {
  .grid {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}
</style>