<script setup>
    import { ref, onBeforeMount } from 'vue'
    import { useRouter, onBeforeRouteLeave } from 'vue-router'
    import { useSignupStore } from "../../stores/signup";
    import SignupProgressBar from '../../../../components/signup/SignupProgressBar.vue'

    const router = useRouter()
    const signupStore = useSignupStore()
    const userGb = ref('');

    onBeforeMount(()=>{
        if(!signupStore.signupRequest.userName || !signupStore.signupRequest.phoneNum) {
            router.push({name: 'Signup'})
        }
        if(signupStore.signupRequest.userGb) {
            userGb.value = signupStore.signupRequest.userGb;
        }
    })

    const confirmModal = ref(null)
    const modalContent = ref([])

    onBeforeRouteLeave(async (to, from, next) => {
        if(to.name !== "Signup" && to.name.startsWith("Signup")) {
            next();
        } else {
            modalContent.value = ["페이지를 나가겠습니까?", "페이지 이동 시 입력한 정보는 저장되지 않습니다"];
            const ok = await confirmModal.value.show();
            if(ok) {
                next();
            }
        }
    })

    const onClickMemberType = (userGbValue) => {
        userGb.value = userGbValue;
        signupStore.signupRequest.userGb = userGbValue;
    }

    const prev = () => {
        router.push({name: 'SignupCertify'});
    }

    const next = () => {
        router.push({name: 'SignupTerms'});
    }
</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <SignupProgressBar :activeIndex="1"/>
            <div class="txt">
                - 가입하려는 회원의 유형을 선택해 주세요.<br>
                - 구인활동 및 장비를 지원할 기업회원은 회원가입 후 고객센터로 문의 바랍니다.
            </div>
            <div class="member-type">
                <div class="type" :class="{'on': userGb === 'STUDENT'}" @click="onClickMemberType('STUDENT')">
                    <div class="img">
                        <img src="../../../../assets/images/icon-student.png" />
                    </div>
                    <b>{{ $userGbKR('STUDENT')}}</b>
                    <p>강의 수강 및 구직활동을<br> 하고 싶습니다.</p>
                </div>
                <div class="type" :class="{'on': userGb === 'COMPANY'}" @click="onClickMemberType('COMPANY')">
                    <div class="img">
                        <img src="../../../../assets/images/icon-company.png" />
                    </div>
                    <b>{{ $userGbKR('COMPANY')}}</b>
                    <p>현재 재직중이며<br>강의를 수강하고 싶습니다.</p>
                </div>
                <div class="type" :class="{'on': userGb === 'SCHOOL'}" @click="onClickMemberType('SCHOOL')">
                    <div class="img">
                        <img src="../../../../assets/images/icon-university.png" />
                    </div>
                    <b>{{ $userGbKR('SCHOOL')}}</b>
                    <p>장비를 지원받으려고 하는<br>대학/연구실의 담당자입니다.</p>
                </div>
                <div class="type" :class="{'on': userGb === 'TEACHER'}" @click="onClickMemberType('TEACHER')">
                    <div class="img">
                        <img src="../../../../assets/images/icon-teacher.png" />
                    </div>
                    <b>{{ $userGbKR('TEACHER')}}</b>
                    <p>강좌를 개설하여<br>강의를 하고 싶습니다.</p>
                </div>
            </div>
            <div class="btn-wrap">
                <KSASecondaryButton class="full-btn" text="이전" @click="prev" />
                <KSAPrimaryButton class="full-btn" text="다음" @click="next"  :disabled="userGb === ''"/>
            </div>
        </div>
    </div>
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="나가기" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .member-type {
        overflow: auto;
        .type {
            float: left;
            width: calc(100% / 2 - 10px);
            text-align: center;
            background: #fff;
            border-radius: 4px;
            border: $border_line;
            cursor: pointer;
            padding: 20px;
            margin: 0 0 20px 20px;
            &:first-child, &:nth-child(3) {
                margin-left: 0;
            }
            &:nth-child(3), &:nth-child(4) {
                margin-bottom: 0;
            }
            &.on {
                background: $blue-light_color;
                border: 1px solid $blue_color;
            }
            .img {
                width: 100px;
                height: 100px;
                background: $gray-thin_color;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto auto 20px;
            }
            b {
                display: block;
                margin-bottom: 6px;
            }
        }
    }
</style>