<script setup>
    import { ref, onMounted, computed, getCurrentInstance } from 'vue'
    import insightsFAQService from "../../services/insights/insightsFAQService";
    import _ from 'lodash'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import { useUserInsightsStore} from "../../stores/insights/userInsightsStore";

    const loadingStore = usePageLoadingStore()
    const userInsightsStore = useUserInsightsStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    const searchBoardGroup = ref('all');
    const boardGroupOptions = [
        { label:'전체', value:'all', },
        { label:'회원', value:'21', },
        { label:'온라인 수강', value:'22', },
        { label:'오프라인 수강', value:'23', },
        { label:'수강', value:'24', },
        { label:'취업연계', value:'25', },
    ];

    const searchType = ref('all');
    const searchTypeOptions = [
        { label:'전체', value:'all', },
        { label:'제목', value:'title', },
        { label:'내용', value:'content', },
    ]
    const input = ref({
        search: ''
    });

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    onMounted(() => {
        retrieveList();
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1 && searchBoardGroup.value === 'all' && searchType.value === 'all' && input.value.search === '';
    });

    const retrieveList = () => {
        let paramObj = {
            boardGroup: searchBoardGroup.value === 'all' ? '' : searchBoardGroup.value,
            pageNo: currentPage.value,
            size: pageSize,
            searchType: searchType.value,
            searchInput: input.value.search || ""
        };

        loadingStore.loading = true;
        insightsFAQService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalCount.value = result.totalElements;
                    totalPages.value = result.totalPages;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                    if(isInitView.value) {
                        userInsightsStore.setFaqList(list.value);
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const toggleContents = (faq) => {
        _.forEach(list.value, (item) => {
            item.showContents = item.boardId === faq.boardId ? !item.showContents : false;
        })
    }

    const findBoardGroupTitle = (boardGroup) => {
        const boardGroupOption = _.find(boardGroupOptions, {value: boardGroup});
        return boardGroupOption ? boardGroupOption.label : '기타';
    }

    const onSearchInput = (value) => {
        input.value.search = value;
        currentPage.value = 1;
        retrieveList();
    }

    const onChangeTab = (selectedTab) => {
        searchBoardGroup.value = selectedTab.value;
        currentPage.value = 1;
        retrieveList();
    }

    const onOptionSelected = (option) => {
        searchType.value = option.value
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>FAQ</h3>
            </div>
            <div class="tab-wrap">
                <KSATab :options="boardGroupOptions" @change="onChangeTab"/>
            </div>
            <div class="list-top">
                <p>총 게시물 <span>{{ totalCount }}</span>건</p>
                <div class="search-box">
                    <KSASelect :options="searchTypeOptions" class="search-select" @selectOption="onOptionSelected"/>
                    <KSASearchInputBox placeholder="검색어를 입력하세요." class="search-input" @search="onSearchInput"/>
                </div>
            </div>
            <div class="faq-list ">
                <div class="header">
                    <div class="num">번호</div>
                    <div class="part">구분</div>
                    <div class="txt">자주하는 질문</div>
                </div>
                <ul>
                    <li class="link" v-for="(faq, index) in (isInitView ? userInsightsStore.faqList : list)">
                        <div class="question" @click="toggleContents(faq)">
                            <div class="num">{{ faq.boardNo }}</div>
                            <div class="part">
                                {{ findBoardGroupTitle(faq.boardGroup) }}
                            </div>
                            <div class="txt">{{ faq.title }}</div>
                        </div>
                        <div class="answer" v-if="faq.showContents" v-html="faq.content"></div>
                    </li>
                </ul>
            </div>
            <div class="result-none" v-if="list.length === 0">
                검색 결과가 없습니다.
            </div>
            <Paginate
                v-if="list.length > 0"
                v-model="currentPage"
                :page-count="totalPages"
                :click-handler="onSelectedPage"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tab-wrap {
    margin-bottom: 40px;
    ul {
        grid-template-columns: repeat(6, 1fr);
    }
}
</style>
