// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useUserLectureStore = defineStore('userLectureStore', () => {

    const lectureOnlineList = ref([]);
    const lectureOfflineList = ref([]);

    const setLectureOnlineList = (list) => {
        lectureOnlineList.value = list;
    }

    const setLectureOfflineList = (list) => {
        lectureOfflineList.value = list;
    }

    return {
        lectureOnlineList,
        lectureOfflineList,
        setLectureOnlineList,
        setLectureOfflineList,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserLectureStore, import.meta.hot))
}