<script setup>
    import { ref, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from "../../stores/user";
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import { storeToRefs } from "pinia";
    import signupService from "../../services/signup/signupService";

    const router = useRouter()
    const userStore = useUserStore()
    const { isAdmin } = storeToRefs(userStore)
    const loadingStore = usePageLoadingStore()

    const phoneNumInput = ref(null)
    const verifyCheckInputRef = ref(null)

    const { $regex } = getCurrentInstance().appContext.config.globalProperties;

    const input = ref({
        name: '',
        phoneNum: '',
        authCode: '',
    })

    const inputError = ref({
        name: '',
        phoneNum: '',
        authCode: '',
    })

    let phoneVerifyId;
    let token;
    let refreshToken;
    const isAuthenticated = ref(false);

    const modal = ref(null)
    const modalContent = ref([]);

    const sendAuthCode = () => {
        const { name } = input.value;
        let isInvalid = false;

        inputError.value.name = '';
        inputError.value.phoneNum = '';

        if(!name) {
            inputError.value.name = '이름을 입력하세요.';
            isInvalid = true;
        }
        const phoneNum = phoneNumInput.value.getValue();
        input.value.phoneNum = phoneNum;
        if(!phoneNum) {
            inputError.value.phoneNum = '핸드폰 번호를 입력하세요';
            isInvalid = true;
        } else if(phoneNum.length !== 13 || !$regex.phoneNum.test(phoneNum)){ // 영문/국문 입력시, 11자리 아닌경우
            inputError.value.phoneNum = '핸드폰 번호를 올바르게 입력하세요';
            isInvalid = true;
        }

        if(!isInvalid) {
            loadingStore.loading = true;
            signupService.phoneVerify({ verifyGubun: 'changepw', name, phoneNum })
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        phoneVerifyId = result.phoneVerifyId;
                        verifyCheckInputRef.value.startTimer()

                        modalContent.value = ["인증번호를 전송하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    const verifyCheck = () => {
        const { phoneNum, authCode } = input.value;
        let isInvalid = false;

        inputError.value.authCode = '';
        if(!authCode || authCode.length !== 6) {
            inputError.value.authCode = '인증번호 6자리를 입력해주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                verifyGubun: "changepw",
                phoneVerifyId,
                phoneNum,
                verifyNum: authCode
            }

            loadingStore.loading = true;
            signupService.phoneVerifyCheck(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    const { code, msg } = res.data;
                    if(code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        if(result.token) userStore.setToken(result.token);
                        if(result.refreshToken) userStore.setRefreshToken(result.refreshToken);
                        inputError.value.authCode = '인증번호를 확인하였습니다.';
                        isAuthenticated.value = true;
                        verifyCheckInputRef.value.clearTimer()
                    } else { // fail
                        if(msg.indexOf('PhoneVerify Number different') > -1) {
                            inputError.value.authCode = '인증번호가 올바르지 않습니다.'; // msg;
                        } else if (msg.indexOf('Not Found User') > -1) {
                            modalContent.value = [
                                "입력한 정보와 일치하는 회원정보가 없습니다.",
                                "다시 입력하여 시도하거나 회원가입을 해주세요"
                            ];
                            modal.value.show();
                            input.value.authCode = '';
                            verifyCheckInputRef.value.clearTimer()
                        }
                        isAuthenticated.value = false;
                    }
                }).catch( (res) =>  {
                    loadingStore.loading = false;
                    // console.log(res);
                    const msg = res.response.data.msg || '';
                    modalContent.value = msg.split("\n");
                    modal.value.show();
            });
        }
    }

    const onUpdateErrorMsg = (value) => {
        inputError.value.authCode = value;
    }

    const findPassword = () => {
        if(isAuthenticated.value) {
            router.push({ name: 'SigninSearchPWReset'})
        }
    }
</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <div class="title">
                <h3>비밀번호 찾기</h3>
                <p>가입 시 입력했던 정보를 입력해주세요.</p>
            </div>
            <div class="sign-content">
                <KSAInput class="text-box" label="이름" v-model="input.name" placeholder="이름을 입력하세요." :msg="inputError.name" max-length="16"/>
                <KSAInputPhoneNum ref="phoneNumInput" class="text-box" label="핸드폰 번호" v-model="input.phoneNum" placeholder="'-' 입력 제외" :msg="inputError.phoneNum"/>

                <KSASecondaryButton class="full-btn" text="인증번호 전송" @click="sendAuthCode" />

                <KSAInputVerifyCheck ref="verifyCheckInputRef" label="인증번호" v-model="input.authCode" @update:msg="onUpdateErrorMsg" :msg="inputError.authCode" :msg-color="isAuthenticated && 'black'"/>
                <KSASecondaryButton v-if="!isAuthenticated" class="full-btn" text="인증번호 확인" @click="verifyCheck" :disabled="input.authCode.length !== 6"/>

                <KSAPrimaryButton class="full-btn" text="비밀번호 찾기" @click="findPassword()" :disabled="!isAuthenticated"/>
            </div>
            <ul class="member">
                <li><RouterLink :to="{name: 'SigninSearchID'}">아이디 찾기</RouterLink></li>
                <li v-if="!isAdmin"><RouterLink :to="{name: 'Signup'}">회원가입</RouterLink></li>
            </ul>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style scoped>
</style>