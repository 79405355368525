<script setup>
    import { defineProps, defineEmits } from 'vue'
    import _ from 'lodash'
    const { text, color, disabled } = defineProps(['text', 'color', 'disabled'])

    const emit = defineEmits(['click'])

    const sendEmit = () => {
        emit('click')
    }

    const onClick = _.debounce(sendEmit, 100);

</script>

<template>
    <button @click="onClick" :disabled="disabled" :style="color && {'background-color': color}">{{ text }}</button>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    button {
        height: 48px;
        border:none;
        cursor:pointer;
        border-radius: 4px;
        padding: 10px 20px;
        background: $blue_color;
        @include font-size_sm;
        color: #fff;
        &.sm_btn {
            height: 30px;
            padding: 1px 10px;
        }
        &.black_button {
            background: $black_color;
            color: #fff;
        }
        &:disabled {
            cursor: default !important;
            background: $gray-light_color !important;
            color: $gray-regular_color !important;
        }
    }
</style>