<script setup>
    import { ref, onMounted, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import mypageUserService from "../../services/mypage/mypageUserService";
    import _ from 'lodash'

    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    const { $toast, $regex } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveUserInfo();
    })

    const user = ref({});
    const corporation = ref({
        corpId : 0,
        corpName : "",
        corpNum : "",
        corpNum1 : "",
        corpNum2 : "",
        bizNum : "",
        ceoName : "",
        foundYm : "",
        employeeNumGb : "",
        industryGb : "",
        job : "",
        relateClass : "",
        bizStateGb : "",
        bizStateName : "",
        taxTypeGb : "",
        taxTypeName : "",
        expireYmd : "",
    });

    const inputError = ref({
        corpNum: '',
    })

    const modal = ref(null)
    const modalContent = ref([]);

    const retrieveUserInfo = () => {
        loadingStore.loading = true;
        mypageUserService.retrieveUserInfo()
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    user.value = result;
                    corporation.value = result.corporation;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const updateCropInfo = () => {
        const _corp = _.cloneDeep(corporation.value)
        const { corpNum1, corpNum2 } = _corp;
        let isInvalid = false;

        inputError.value.corpNum = '';

        if(!corpNum1 || !corpNum2) {
            inputError.value.corpNum = '법인등록번호를 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid){
            user.value.corporation = {
                corpId : _corp.corpId,
                corpName : _corp.corpName,
                corpNum : _corp.corpNum1 + '' + _corp.corpNum2,
                bizNum : _corp.bizNum,
                ceoName : _corp.ceoName,
                foundYm : _corp.foundYm,
                employeeNumGb : _corp.employeeNumGb,
                industryGb : _corp.industryGb,
                job : _corp.job,
                relateClass : _corp.relateClass,
                bizStateGb : _corp.bizStateGb,
                bizStateName :_corp.bizStateName,
                taxTypeGb : _corp.taxTypeGb,
                taxTypeName :_corp.taxTypeName,
                expireYmd : _corp.expireYmd.replace('.','').replace('.',''),
            };

            loadingStore.loading = true;
            mypageUserService.updateUserInfo(user.value)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', '저장하였습니다.');
                        router.push({name:'Home'});
                    } else {
                        modalContent.value = ["저장을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
                modalContent.value = ["오류가 발생했습니다. 잠시 후 다시 시도해주세요."];
                modal.value.show();
            });
        }

    }

    const onBlurCorpNum = () => {
        const { corpNum1, corpNum2 } = corporation.value;
        const corpNum = `${corpNum1}-${corpNum2}`;
        if(!$regex.corpNum.test(corpNum)) {
            inputError.value.corpNum = '올바른 법인등록번호 형식이 아닙니다. 다시 입력해 주세요.';
        } else {
            inputError.value.corpNum = '';
        }
    }

</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <div class="title">
                <h3>기업 정보 입력</h3>
            </div>
            <div class="sign-content">
                <div class="txt">
                    - 기업 정보를 입력해주세요.<br>
                    - <span class="red">*표시는 필수 입력사항</span> 입니다.
                </div>
                <KSAInput label="기업명" class="text-box" v-model="corporation.corpName" :disabled="true" />
                <div class="text-box">
                    <p class="label mark">법인등록번호</p>
                    <div class="grid half">
                        <KSAInput class="input" v-model="corporation.corpNum1" @blur="onBlurCorpNum" placeholder="6자리 숫자 입력" max-length="6"/>
                        <span class="ml-xs mr-xs">-</span>
                        <KSAInput class="input" v-model="corporation.corpNum2" @blur="onBlurCorpNum" placeholder="7자리 숫자 입력" max-length="7"/>
                    </div>
                    <div>
                        <small style="color:#E71414;">{{ inputError.corpNum }}</small>
                    </div>
                </div>
                <KSAInput class="text-box" v-model="corporation.ceoName" label="대표명" placeholder="대표명을 입력하세요." />
                <KSAInputDateYYYYMM class="text-box" v-model="corporation.expireYmd" label="창립연월" placeholder="YYYYMMDD 6자리 숫자 입력" format="YYYYMMDD" max-length="8"/>
                <div class="text-box">
                    <p class="label">인원</p>
                    <template v-for="option in $gb.EMPLOYEE_NUM_GB">
                        <KSARadioButton class="check-box mr-lg" name="employeeNumGb" :label="option.label" v-model="corporation.employeeNumGb" :value="option.value" />
                    </template>
                </div>
                <div class="text-box">
                    <p class="label">산업군</p>
                    <template v-for="option in $gb.INDUSTRY_GB">
                        <KSARadioButton class="check-box mr-lg" name="industryGb" :label="option.label" v-model="corporation.industryGb" :value="option.value" />
                    </template>
                </div>
                <KSAPrimaryButton class="full-btn" text="저장" @click="updateCropInfo" />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    .check-box {
        &:last-child {
            margin-right: 0 !important;
        }
    }
</style>