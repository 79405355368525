<script setup>
    import { ref, watch, computed, onBeforeMount, getCurrentInstance } from 'vue'
    import { useRouter, onBeforeRouteLeave } from 'vue-router'
    import { useSignupStore } from "../../stores/signup";
    import { useUserStore } from "../../stores/user";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import SignupProgressBar from '../../../../components/signup/SignupProgressBar.vue'
    import signupService from "../../services/signup/signupService";
    import _ from "lodash";

    const router = useRouter()
    const signupStore = useSignupStore()
    const loadingStore = usePageLoadingStore()

    const globals = getCurrentInstance().appContext.config.globalProperties;
    const { $regex, $userGbKR, $gb : { USER_GB, SCHOOL_GB, EDU_TENURE_GB } } = globals;


    const confirmModal = ref(null)

    onBeforeRouteLeave(async (to, from, next) => {
        if(to.name !== "Signup" && to.name.startsWith("Signup")) {
            next();
        } else {
            modalContent.value = ["페이지를 나가겠습니까?", "페이지 이동 시 입력한 정보는 저장되지 않습니다"];
            const ok = await confirmModal.value.show();
            if(ok) {
                next();
            }
        }
    })

    const userGbName = ref('')
    const signupRequest = ref({
        "userGb": "STUDENT",
        "userLoginId": "",
        "userLoginPw": "",
        "userLoginPwVerify": "",
        "school": "",
        "schoolGb": "", // 학력
        "eduTenureGb": "", // 상태
        "userName": signupStore.signupRequest.userName,
        "phoneNum": signupStore.signupRequest.phoneNum,
        "email": "",
        "email1": "",
        "email2": "",
        "birthYmd": "",
        "sex": "01",
        "companyNum": "", // 사업자등록번호
        "corpId": 0, // 기업아이디
        "corpName": '사업자등록번호 입력 시 자동 입력됩니다.', // 기업명
        "residenceArea1": "", // 거주지역_시도
        "residenceArea2": "", // 거주지역_시군구
        "interests": "", // 관심카테고리
        "desiredJob": "", // 학생 희망직무 재직자 본인직무
        "professorName": "", // 교수님이름
        "labName": "", // 연구실이름
        "ableClass": "", // 교육가능과목
        "expertField": "", // 전문분야
        "agreeTermYn": signupStore.signupRequest.agreeTermYn, // 회원약관동의여부
        "agreePrivacyYn": signupStore.signupRequest.agreePrivacyYn, // 개인정보이용동의여부
        "agreeInfoProvYn": signupStore.signupRequest.agreeInfoProvYn, // 제3자
    })

    const inputError = ref({
        userLoginId: '',
        userLoginPw: '',
        userLoginPwVerify: '',
        email: '',
        birthYmd: '',
        school: '',
        schoolGb: '',
        eduTenureGb: '',
        residenceArea: '',
        companyNum: '',
        corpName: '',
    })

    const modal =ref(null)
    const modalContent = ref([])

    const data_interests = ref(globals.$interests)
    const data_desiredJob = ref(globals.$desiredJob)

    const userLoginIdRef = ref(null)
    const userLoginPwRef = ref(null)
    const userLoginPwVerifyRef = ref(null)
    const emailRef = ref(null)
    const birthYmdRef = ref(null)
    const schoolRef = ref(null)
    const companyNumRef = ref(null)
    const eduTenureGbRef = ref(null)

    const isSchoolSelectDisabled = ref(false);

    // 고졸(05)일 경우, 학교(대학) & 상태 필수표시(*) 제거.
    const schoolInputClass = computed(() => {
      return signupRequest.value.schoolGb === '05' ? '' : 'mark';
    });

    // 고졸(05)일 경우, 학교(대학) & 상태 비활성화.
    watch( () => signupRequest.value.schoolGb, (newValue) => {
      if( newValue === '05') {
        isSchoolSelectDisabled.value = true;
        signupRequest.value.school = '고졸';
        eduTenureGbRef.value.initSelectedOption('01');
      } else {
        isSchoolSelectDisabled.value = false;
        signupRequest.value.school = '';
        eduTenureGbRef.value.initSelectedOption('');
      }
    });

    onBeforeMount(() => {

        if(!signupStore.signupRequest.userName || !signupStore.signupRequest.phoneNum) {
            router.push({name: 'Signup'});
            return false;
        } else if(!signupStore.signupRequest.userGb) {
            router.push({name: 'SignupMemberType'});
            return false;
        } else if(signupStore.signupRequest.agreeTermYn !== 'Y' || signupStore.signupRequest.agreePrivacyYn !== 'Y' ) {
            router.push({name: 'SignupTerms'});
            return false;
        }

        signupRequest.value.userGb = signupStore.signupRequest.userGb;
        userGbName.value = $userGbKR(signupRequest.value.userGb);

        /*if(signupRequest.value.userGb === 'STUDENT' || signupRequest.value.userGb === 'SCHOOL') {
            signupRequest.value.eduHistGb = EDU_HIST_GB ? EDU_HIST_GB[0].value : '';
        }*/

        _.each(data_interests.value, el => {el.on = false;})
        _.each(data_desiredJob.value, el => {el.on = false;})
    })

    const onSelectedArea1Option = (option) => {
        signupRequest.value.residenceArea1 = option;
    }

    const onSelectedArea2Option = (option) => {
        signupRequest.value.residenceArea2 = option;
    }

    const submit = () => {
        const req = signupRequest.value;
        // console.log(req);
        let isInvalid = false;
        let focused = false;
        clearErrorMessage();
        if(!req.userLoginId || (req.userLoginId.length < 6 || req.userLoginId.length > 20)) {
            inputError.value.userLoginId = '영문 소문자, 숫자 사용하여 6~20자 이내로 입력하세요.';
            isInvalid = true;
            if(!focused) {
                userLoginIdRef.value.focus();
                focused = true;
            }
        }
        if(!req.userLoginPw || req.userLoginPw.length < 8) {
            inputError.value.userLoginPw = '영문 대소문자, 숫자, 기호 사용하여 8자 이상으로 입력하세요.';
            isInvalid = true;
            if(!focused) {
                userLoginPwRef.value.focus();
                focused = true;
            }
        }
        if(!req.userLoginPwVerify) {
            inputError.value.userLoginPwVerify = '비밀번호를 다시 입력하세요.';
            isInvalid = true;
            if(!focused) {
                userLoginPwVerifyRef.value.focus();
                focused = true;
            }
        } else if(req.userLoginPwVerify.length < 8) {
            inputError.value.userLoginPwVerify = '영문 대소문자, 숫자, 기호 사용하여 8자 이상으로 입력하세요.';
            isInvalid = true;
            if(!focused) {
                userLoginPwVerifyRef.value.focus();
                focused = true;
            }
        } else if(req.userLoginPw !== req.userLoginPwVerify) {
            inputError.value.userLoginPwVerify = '비밀번호가 일치하지 않습니다.';
            isInvalid = true;
            if(!focused) {
                userLoginPwVerifyRef.value.focus();
                focused = true;
            }
        }

        if(!req.email1 || !req.email2) {
            inputError.value.email = '이메일을 입력하세요.';
            isInvalid = true;
            if(!focused) {
                emailRef.value.focus(!!req.email1);
                focused = true;
            }
        }
        if(!req.birthYmd || req.birthYmd.length !== 8) {
            inputError.value.birthYmd = !req.birthYmd ? '생년월일을 입력하세요.' : '생년월일을 YYYYMMDD형식으로 입력하세요.';
            isInvalid = true;
            if(!focused) {
                birthYmdRef.value.focus();
                focused = true;
            }
        }
        if((req.residenceArea1 === '' || req.residenceArea1 === '시/도 선택') ||
            (req.residenceArea2 === '' || req.residenceArea2 === '시/구/군 선택') ) {
            inputError.value.residenceArea = (req.residenceArea1 === '' || req.residenceArea1 === '시/도 선택') ? '시/도를 선택하세요.' : '시/구/군을 선택하세요.';
            isInvalid = true;
        }

        if(req.userGb === 'STUDENT' || req.userGb === 'SCHOOL'){
            if(!req.schoolGb) {
                inputError.value.schoolGb = '학력을 선택하세요.';
                isInvalid = true;
            }
            if(req.schoolGb !== '05' && !req.school) {
                inputError.value.school = '학교를 입력하세요..';
                isInvalid = true;
                if(!focused) {
                    schoolRef.value.focus();
                    focused = true;
                }
            }
            if(req.schoolGb !== '05' && !req.eduTenureGb) {
                inputError.value.eduTenureGb = '상태를 선택하세요.';
                isInvalid = true;
            }
        } else if(req.userGb === 'COMPANY') {
            if(!req.companyNum || req.companyNum.length !== 12) {
                inputError.value.companyNum = !req.companyNum ? '사업자등록번호를 입력하세요.' : '10자리 숫자를 입력하세요.';
                isInvalid = true;
                if(!focused) {
                    companyNumRef.value.focus();
                    focused = true;
                }
            } else if(req.corpId === 0) {
                inputError.value.corpName = '사업자등록번호가 올바르지 않습니다. 다시 입력하세요.';
                isInvalid = true;
            }
        }

        const interests = _.map(_.filter(data_interests.value, {'on': true}), 'value').join(',');
        const desiredJob = _.map(_.filter(data_desiredJob.value, {'on': true}), 'value').join(',');
        if(req.userGb === 'STUDENT' || req.userGb === 'COMPANY'){
            req.interests = interests;
            req.desiredJob = desiredJob;
        } else if(req.userGb === 'TEACHER') {
            req.ableClass = interests;
            req.expertField = desiredJob;
        }

        // console.log(isInvalid);
        // console.log(req);
        if(!isInvalid) {
            let paramObj = {
                userGb: req.userGb,
                userLoginId: req.userLoginId,
                userLoginPw: req.userLoginPw,
                userLoginPwVerify: req.userLoginPwVerify,
                school: req.school,
                schoolGb: req.schoolGb,
                eduTenureGb: req.eduTenureGb,
                userName: req.userName,
                phoneNum: req.phoneNum.replace('-', '').replace('-', ''),
                email: req.email1 + '@' + req.email2,
                birthYmd: req.birthYmd,
                sex: req.sex,
                corpId: req.userGb === 'COMPANY' ? req.corpId : undefined,
                residenceArea1: req.residenceArea1,
                residenceArea2: req.residenceArea2,
                interests: req.interests,
                desiredJob: req.desiredJob,
                professorName: req.professorName,
                labName: req.labName,
                ableClass: req.ableClass,
                expertField: req.expertField,
                agreeTermYn: req.agreeTermYn,
                agreePrivacyYn: req.agreePrivacyYn,
                agreeInfoProvYn: req.agreeInfoProvYn,
            }

            loadingStore.loading = true;
            signupService.signup(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        const userStore = useUserStore();
                        userStore.setUser({
                            userGb: result.userGb,
                            userCd: result.userCd,
                            userLoginId: result.userLoginId,
                            userName: result.userName,
                            token: result.token,
                            refreshToken: result.refreshToken,
                            accessMenu: result.accessMenu,
                        });

                        router.push({name: 'SignupComplete'});
                    } else {
                        modalContent.value = [res.data.msg];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    const prev = () => {
        router.push({name: 'SignupTerms'})
    }

    const clearErrorMessage = () => {
        inputError.value = {
            userLoginId: '',
            userLoginPw: '',
            userLoginPwVerify: '',
            email: '',
            birthYmd: '',
            school: '',
            schoolGb: '',
            eduTenureGb: '',
            residenceArea: '',
            companyNum: '',
            corpName: '',
        }
    }

    const onUpdateCorp = (corp) => {
        signupRequest.value.corpId = corp.corpId;
        signupRequest.value.corpName = corp.corpName;

        inputError.value.corpName = '';
        if(corp.corpId === 0) {
            inputError.value.corpName = '사업자등록번호가 올바르지 않습니다. 다시 입력하세요.';
        }
    }

    const onBlurId = () => {
        const { userLoginId } = signupRequest.value;
        if(!userLoginId) {
            return false;
        }
        inputError.value.userLoginId = '';
        if(!$regex.id.test(userLoginId)) {
            inputError.value.userLoginId = '영문 소문자, 숫자 사용하여 6~20자 이내로 입력하세요.';
        } else {
            signupService.loginIdCheck(userLoginId)
                .then( (res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        const { userLoginIdCount } = result;
                        if(userLoginIdCount > 0) {
                            inputError.value.userLoginId = '이미 사용중인 아이디입니다.';
                        } else if(userLoginIdCount === 0) {
                            inputError.value.userLoginId = '사용할 수 있는 아이디입니다.';
                        }
                    }
                }).catch( (res) =>  {
                // console.log(res);
            });
        }
    }

    const onBlurPassword = () => {
        const { userLoginPw, userLoginPwVerify } = signupRequest.value;
        if(userLoginPw && !$regex.pw.test(userLoginPw)) {
            inputError.value.userLoginPw = '영문 대소문자, 숫자, 기호 사용하여 8자 이상으로 입력하세요.';
        } else {
            inputError.value.userLoginPw = '';
        }

        if(userLoginPw === userLoginPwVerify) {
            inputError.value.userLoginPwVerify = '';
        }
    }

    const onBlurPasswordVerify = () => {
        const { userLoginPw, userLoginPwVerify } = signupRequest.value;
        if(!userLoginPwVerify) {
            inputError.value.userLoginPwVerify = '비밀번호를 다시 입력하세요.';
        } else if(userLoginPwVerify && !$regex.pw.test(userLoginPwVerify)) {
            inputError.value.userLoginPwVerify = '영문 대소문자, 숫자, 기호 사용하여 8자 이상으로 입력하세요.';
        } else {
            if(userLoginPw !== userLoginPwVerify) {
                inputError.value.userLoginPwVerify = '입력한 값이 비밀번호와 일치하지 않습니다. 다시 입력해 주세요.'
            } else {
                inputError.value.userLoginPwVerify = '';
            }
        }
    }

    const onBlurEmail = () => {
        const { email1, email2 } = signupRequest.value;
        const email = `${email1}@${email2}`;
        // console.log(email);
        if(!$regex.email.test(email)) {
            inputError.value.email = '올바른 이메일 형식이 아닙니다. 다시 입력해 주세요.';
        } else {
            inputError.value.email = '';
        }
    }

    const onBlurCompNum = () => {
        const { companyNum } = signupRequest.value;
        if(!$regex.compNum.test(companyNum)) {
            inputError.value.companyNum = '올바른 사업자등록번호 형식이 아닙니다. 다시 입력해 주세요.';
        } else {
            inputError.value.companyNum = '';
        }

    }


    const interestCount = computed(()=>{
        return _.filter(data_interests.value, {'on': true}).length || 0;
    })

    const onClickInterest = (interest) => {
        inputError.value.interests = '';
        if(interest.on) {
            interest.on  = false;
        } else {
            if(interestCount.value < 3) {
                interest.on = true;
            } else {
                inputError.value.interests = '최대 3개까지 선택할 수 있습니다.';
            }
        }
    }

    const desiredJobCount = computed(()=>{
        return _.filter(data_desiredJob.value, {'on': true}).length || 0;
    })

    const onClickDesiredJob = (desiredJob) => {
        inputError.value.desiredJob = '';
        if(desiredJob.on) {
            desiredJob.on  = false;
        } else {
            if(desiredJobCount.value < 3) {
                desiredJob.on = true;
            } else {
                inputError.value.desiredJob = '최대 3개까지 선택할 수 있습니다.';
            }
        }
    }
</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <SignupProgressBar :activeIndex="3"/>
            <div class="sign-content">
                <div class="txt">
                    - 회원가입을 위한 정보를 입력해 주세요.<br>
                    - <span class="red">*표시는 필수 입력사항</span> 입니다.
                </div>

                <KSAInput label="회원유형" class="text-box" v-model="userGbName" :disabled="true" />
                <KSAInput ref="userLoginIdRef" label="아이디" class="text-box mark" v-model="signupRequest.userLoginId" @blur="onBlurId" placeholder="아이디를 입력해주세요" max-length="20" :msg="inputError.userLoginId" :msg-color="inputError.userLoginId.indexOf('있는') > -1 ? '#34d100': '#E71414'"/>
                <KSAInput ref="userLoginPwRef" label="비밀번호" class="text-box mark" type="password" v-model="signupRequest.userLoginPw" @blur="onBlurPassword" placeholder="비밀번호를 입력해주세요." :msg="inputError.userLoginPw"/>
                <KSAInput ref="userLoginPwVerifyRef" label="비밀번호 확인" class="text-box mark" type="password" v-model="signupRequest.userLoginPwVerify" @blur="onBlurPasswordVerify" placeholder="비밀번호를 입력해주세요." :msg="inputError.userLoginPwVerify"/>

                <!-- 학생/취업준비생 -->
                <template v-if="signupRequest.userGb === 'STUDENT'">
                    <KSAInput label="이름" class="text-box" v-model="signupRequest.userName" :disabled="true" />
                    <KSAInput label="핸드폰 번호" class="text-box" v-model="signupRequest.phoneNum" :disabled="true"/>
                    <div class="text-box">
                        <p class="label mark">이메일</p>
                        <KSAFormEmail
                            ref="emailRef"
                            :email1="signupRequest.email1"
                            :email2="signupRequest.email2"
                            @update:email1="(value)=>{signupRequest.email1 = value}"
                            @update:email2="(value)=>{signupRequest.email2 = value}"
                            @blur="onBlurEmail"
                        />
                        <div>
                            <small style="color:#E71414;">{{ inputError.email }}</small>
                        </div>
                    </div>
                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="signupRequest.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                    <div class="text-box">
                        <p class="label mark">성별</p>
                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="signupRequest.sex" value="01" />
                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="signupRequest.sex" value="02" />
                    </div>

                    <div class="text-box">
                        <p class="label mark">학력</p>
                        <KSASelect class="select" v-model="signupRequest.schoolGb" :options="$gb.SCHOOL_GB"/>
                        <div>
                            <small style="color:#E71414;">{{ inputError.schoolGb }}</small>
                        </div>
                    </div>
                    <KSAInputSchoolSelect
                        ref="schoolRef"
                        label="학교"
                        class = "text-box"
                        :class="schoolInputClass"
                        v-model="signupRequest.school"
                        placeholder="학교를 입력하세요."
                        :msg="inputError.school"
                        msg-color="#E71414"
                        :disabled="isSchoolSelectDisabled"
                    />
                    <div class="text-box">
                        <p class="label" :class="schoolInputClass">상태</p>
                        <KSASelect
                            ref="eduTenureGbRef"
                            class="select"
                            :options="$gb.EDU_TENURE_GB"
                            v-model="signupRequest.eduTenureGb"
                            :disabled="isSchoolSelectDisabled"
                        />
                        <div>
                            <small style="color:#E71414;">{{ inputError.eduTenureGb }}</small>
                        </div>
                    </div>
                    <div class="text-box">
                        <p class="label mark">거주 지역</p>
                        <KSASelectResidenceArea class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                        <div>
                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                        </div>
                    </div>
                    <div class="text-box">
                        <p class="label" :class="{'mark': false}" style="margin: 0">희망 직무 (최대 3개)</p>
                        <ul class="grid category-wrap">
                            <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                {{ desiredJob.label }}
                            </li>
                        </ul>
                        <p>
                            <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                        </p>
                    </div>
                    <div class="text-box">
                        <p class="label" style="margin: 0">관심 카테고리 (최대 3개)</p>
                        <ul class="grid category-wrap">
                            <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                {{ interest.label }}
                            </li>
                        </ul>
                        <p>
                            <small style="color:#E71414;">{{ inputError.interests }}</small>
                        </p>
                    </div>
                </template>

                <!-- 재직자/기업 -->
                <template v-if="signupRequest.userGb === 'COMPANY'">
                    <KSAInput label="이름" class="text-box" v-model="signupRequest.userName" :disabled="true" />
                    <KSAInput label="핸드폰 번호" class="text-box" v-model="signupRequest.phoneNum" :disabled="true"/>
                    <div class="text-box">
                        <p class="label mark">이메일</p>
                        <KSAFormEmail
                            ref="emailRef"
                            :email1="signupRequest.email1"
                            :email2="signupRequest.email2"
                            @update:email1="(value)=>{signupRequest.email1 = value}"
                            @update:email2="(value)=>{signupRequest.email2 = value}"
                            @blur="onBlurEmail"
                        />
                        <div>
                            <small style="color:#E71414;">{{ inputError.email }}</small>
                        </div>
                    </div>
                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="signupRequest.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                    <div class="text-box">
                        <p class="label mark">성별</p>
                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="signupRequest.sex" value="01" />
                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="signupRequest.sex" value="02" />
                    </div>

                    <KSAInputCompNum
                        ref="companyNumRef"
                        class="text-box mark"
                                     v-model="signupRequest.companyNum"
                                     @update:corp="onUpdateCorp"
                                     @blur="onBlurCompNum"
                                     :msg="inputError.companyNum"
                                     placeholder="10자리 숫자 입력"
                    />
                    <KSAInput label="기업명" class="text-box" v-model="signupRequest.corpName" :msg="inputError.corpName" :disabled="true" />

                    <div class="text-box">
                        <p class="label mark">거주 지역</p>
                        <KSASelectResidenceArea class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                        <div>
                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                        </div>
                    </div>
                    <div class="text-box">
                        <p class="label" :class="{'mark': false}" style="margin: 0">본인 직무 (최대 3개)</p>
                        <ul class="grid category-wrap">
                            <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                {{ desiredJob.label }}
                            </li>
                        </ul>
                        <p>
                            <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                        </p>
                    </div>
                    <div class="text-box">
                        <p class="label" style="margin: 0">관심 카테고리 (최대 3개)</p>
                        <ul class="grid category-wrap">
                            <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                {{ interest.label }}
                            </li>
                        </ul>
                        <p>
                            <small style="color:#E71414;">{{ inputError.interests }}</small>
                        </p>
                    </div>
                </template>

                <!-- 대학/연구실 -->
                <template v-if="signupRequest.userGb === 'SCHOOL'">
                    <div class="text-box">
                        <p class="label mark">학력</p>
                            <KSASelect class="select" v-model="signupRequest.schoolGb" :options="$gb.SCHOOL_GB"/>
                        <div>
                            <small style="color:#E71414;">{{ inputError.schoolGb }}</small>
                        </div>
                    </div>
                    <KSAInputSchoolSelect
                        ref="schoolRef"
                        label="학교"
                        class="text-box"
                        :class="schoolInputClass"
                        v-model="signupRequest.school"
                        placeholder="학교를 입력하세요."
                        :msg="inputError.school"
                        msg-color="#E71414"
                        :disabled="isSchoolSelectDisabled"
                    />
                    <div class="text-box">
                        <p class="label" :class="schoolInputClass">상태</p>
                        <KSASelect
                            ref="eduTenureGbRef"
                            class="select"
                            :options="$gb.EDU_TENURE_GB"
                            v-model="signupRequest.eduTenureGb"
                            :disabled="isSchoolSelectDisabled"
                        />
                        <div>
                            <small style="color:#E71414;">{{ inputError.eduTenureGb }}</small>
                        </div>
                    </div>
                    <KSAInput label="담당자 이름" class="text-box" v-model="signupRequest.userName" :disabled="true" />
                    <KSAInput label="핸드폰 번호" class="text-box" v-model="signupRequest.phoneNum" :disabled="true"/>
                    <div class="text-box">
                        <p class="label mark">이메일</p>
                        <KSAFormEmail
                            ref="emailRef"
                            :email1="signupRequest.email1"
                            :email2="signupRequest.email2"
                            @update:email1="(value)=>{signupRequest.email1 = value}"
                            @update:email2="(value)=>{signupRequest.email2 = value}"
                            @blur="onBlurEmail"
                        />
                        <div>
                            <small style="color:#E71414;">{{ inputError.email }}</small>
                        </div>
                    </div>
                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="signupRequest.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                    <div class="text-box">
                        <p class="label mark">성별</p>
                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="signupRequest.sex" value="01" />
                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="signupRequest.sex" value="02" />
                    </div>
                    <div class="text-box">
                        <p class="label mark">거주 지역</p>
                        <KSASelectResidenceArea class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                        <div>
                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                        </div>
                    </div>
                    <KSAInput label="교수님 이름" class="text-box" v-model="signupRequest.professorName" placeholder="교수님 이름을 입력하세요." max-length="30" />
                    <KSAInput label="연구실 이름" class="text-box" v-model="signupRequest.labName" placeholder="연구실 이름을 입력하세요." max-length="30" />
                </template>

                <!-- 강사/전문가 -->
                <template v-if="signupRequest.userGb === 'TEACHER'">
                    <KSAInput label="이름" class="text-box" v-model="signupRequest.userName" :disabled="true" />
                    <KSAInput label="핸드폰 번호" class="text-box" v-model="signupRequest.phoneNum" :disabled="true"/>
                    <div class="text-box">
                        <p class="label mark">이메일</p>
                        <KSAFormEmail
                            ref="emailRef"
                            :email1="signupRequest.email1"
                            :email2="signupRequest.email2"
                            @update:email1="(value)=>{signupRequest.email1 = value}"
                            @update:email2="(value)=>{signupRequest.email2 = value}"
                            @blur="onBlurEmail"
                        />
                        <div>
                            <small style="color:#E71414;">{{ inputError.email }}</small>
                        </div>
                    </div>
                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="signupRequest.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                    <div class="text-box">
                        <p class="label mark">성별</p>
                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="signupRequest.sex" value="01" />
                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="signupRequest.sex" value="02" />
                    </div>
                    <div class="text-box">
                        <p class="label mark">거주 지역</p>
                        <KSASelectResidenceArea class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                        <div>
                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                        </div>
                    </div>
                    <div class="text-box">
                        <p class="label" style="margin: 0">교육 가능 과목 (최대 3개)</p>
                        <ul class="grid category-wrap">
                            <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                {{ interest.label }}
                            </li>
                        </ul>
                        <p>
                            <small style="color:#E71414;">{{ inputError.interests }}</small>
                        </p>
                    </div>
                    <div class="text-box">
                        <p class="label" :class="{'mark': false}" style="margin: 0">전문 분야 (최대 3개)</p>
                        <ul class="grid category-wrap">
                            <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                {{ desiredJob.label }}
                            </li>
                        </ul>
                        <p>
                            <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                        </p>
                    </div>
                </template>
            </div>
            <div class="btn-wrap">
                <KSASecondaryButton class="full-btn" text="이전" @click="prev" />
                <KSAPrimaryButton class="full-btn" text="다음" @click="submit" />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="나가기" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";


</style>