<script setup>
    import { ref, onMounted, computed, getCurrentInstance } from 'vue'
    import { RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import LectureMainSettingModal from '../../components/modal/lecture/LectureMainSettingModal.vue'
    import lectureService from "../../services/lecture/lectureService";
    import _ from 'lodash'

    const globals = getCurrentInstance().appContext.config.globalProperties;
    const { $toast } = globals;

    const loadingStore = usePageLoadingStore()

    onMounted(()=>{
        retrieveList();
    })

    const MAX_LEN = 4;

    const list = ref([])
    const original_list = ref([])

    const repreLectureList = computed(()=>{
        return _.filter(list.value, {repreLectYn: 'Y'});
    })

    const isSelected= ref(true); // KSACheckBox - checkbox 1개 일때

    const modal = ref(null)
    const lectureMainSettingModal = ref(null)
    const modalContent = ref('')

    const retrieveList = () => {
        let paramObj = {
            lectGb: "00",
            lectOpenYn: 'Y',
            repreLectYn: 'A',
            pageNo: 1,
            size: 100,
        }

        loadingStore.loading = true;
        lectureService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    list.value = result.content;
                    _.each(list.value, lect => {
                        lect.checked = false;
                    })
                    original_list.value = _.cloneDeep(list.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const remove = () => {
        const checekdList = _.filter(list.value, {checked: true});
        if(checekdList.length === 0) {
            modalContent.value = ["대표 강의로 삭제할 강의를 선택하세요."];
            modal.value.show();
            return false;
        }

        // remove
        _.each(checekdList, lect => {
            lect.repreLectYn = 'N';
            lect.checked = false;
        })
    }

    const onButtonClick = async () => {
        const newList = _.cloneDeep(list.value);
        _.each(newList, lect => {
            lect.repreLectYn = lect.repreLectYn === 'Y';
        })
        const params = {
            'list': newList,
            'limit': MAX_LEN,
            'count': repreLectureList.value.length,
        }
        lectureMainSettingModal.value.init(params)
        const result = await lectureMainSettingModal.value.show();
        // console.log(result);
        if(result && result.length > 0) {
            _.each(result, lect => {
                lect.repreLectYn = lect.repreLectYn ? 'Y' : 'N';
            })
            list.value = result;
        }
    }

    const reset = () => {
        list.value = original_list.value;
    }

    const save = () => {
        const lectIds = _.map(repreLectureList.value, 'lectId');
        if(lectIds.length !== MAX_LEN) {
            modalContent.value = [`대표 강의 ${MAX_LEN}개를 설정하고 저장하세요.`];
            modal.value.show();
            return false;
        }
        loadingStore.loading = true;
        lectureService.updateRepreLectYnY(lectIds)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    $toast('', "대표 강의를 설정하였습니다.");
                    original_list.value = _.cloneDeep(list.value);
                } else {
                    $toast('', "대표 강의 설정을 실패하였습니다.");
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>대표 강의 설정</h3>
                </div>
                <div class="btn-wrap space-between">
                    <div>
                        <KSASecondaryButton text="대표 강의 설정" @click="onButtonClick" />
                    </div>
                    <KSASecondaryButton class="red_button mr-xs" text="삭제" @click="remove" />
                </div>
                <div class="main-wrap">
                    <div class="item" v-for="lecture in repreLectureList">
                        <div class="img">
                            <KSAImage :src="lecture.lectThombDir" />
                            <KSACheckBox class="checkbox" v-model="lecture.checked"  />
                        </div>
                        <div class="text">
                            <span class="type">{{ lecture.lectGb === '01' ? '온라인' : '오프라인' }} 강의</span>
                            <p>{{ lecture.lectName }}</p>
                            <small>{{ lecture.lectureIndexCount }}차시</small>
                        </div>
                    </div>
                </div>
                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="취소" @click="reset" />
                    <div>
                        <KSAPrimaryButton text="저장" @click="save" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <LectureMainSettingModal ref="lectureMainSettingModal" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .main-wrap {
        margin: 20px 0 10px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: $border_line;
        .item {
            width: 390px;
            margin: 0 30px 70px 0;
            .img {
                position: relative;
                height: 278px;
                img {
                    border-radius: 4px;
                    object-position: top;
                }
                .checkbox {
                    position: absolute;
                    top: 14px;
                    left: 20px;
                }
            }
            .text {
                padding: 10px 0;
                .type {
                    background: $black_color;
                    color: #fff;
                    padding: 1px 10px;
                    border-radius: 4px;
                }
                p {
                    font-weight: 700;
                    @include font-size_lg;
                    margin: 10px 0;
                }
                small {
                    color: $gray_color;
                }
            }
        }
    }
</style>