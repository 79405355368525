import fileService from "../../services/fileService";

const dClassApiUrl = import.meta.env.VITE_DCLASSAPI_URL;
import { useUserStore } from "../../pages/user/stores/user";
import { useSasTokenStore } from "../../stores/sasTokenStore";

export default class UploadAdapter {
    constructor(loader, container) {
        this.loader = loader;
        this.container = container || 'public';
    }

    upload() {
        return this.loader.file.then( file => new Promise(((resolve, reject) => {
            this._initRequest();
            this._initListeners( resolve, reject, file );
            this._sendRequest( file );
        })))
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', `${dClassApiUrl}/resource/file/azure/upload?container=${this.container}&folder=editorImage`, true);
        xhr.responseType = 'json';

        const { user } = useUserStore();
        xhr.setRequestHeader("Authorization", user.token);
        xhr.setRequestHeader("X-Danbee-Refresh-Header", user.refreshToken);
    }

    _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = '파일을 업로드 할 수 없습니다.'

        xhr.addEventListener('error', () => {reject(genericErrorText)})
        xhr.addEventListener('abort', () => reject())
        xhr.addEventListener('load', () => {
            const response = xhr.response
            if(!response || response.error) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }
            // console.log(response)
            if(this.container === 'private') {
                const store = useSasTokenStore();
                const src = `https://academy.ksia.or.kr/platformfile/private/${response.result.blobName}?${store.sasToken}`;
                resolve({
                    default: src,
                })
            } else { // public
                resolve({
                    default: `https://academy.ksia.or.kr${response.result.blobUrl}` //업로드된 파일 주소
                })
            }
        })
    }

    _sendRequest(file) {
        const data = new FormData()
        data.append('file',file)

        const { user } = useUserStore();
        if(!user.token || !user.refreshToken) { //토큰없으면 리턴
            return false;
        }
        this.xhr.send(data)
    }
}