<script setup>
    import { ref, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import insightsNewsletterService from "../../services/insights/insightsNewsletterService";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";

    const route = useRoute();
    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    const newsletterDetail = ref({
        boardId: route.params.id || '',
    })

    onMounted(()=>{
        retrieveNewsletterDetail()
    })

    const retrieveNewsletterDetail = () => {
        loadingStore.loading = true;
        insightsNewsletterService.retrieveNewsletterDetail(newsletterDetail.value.boardId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    newsletterDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            // console.log(error);
        });
    }

</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>뉴스레터</h3>
            </div>
            <div class="board-detail">
                <div class="header">
                    <div class="board-title">
                        <p>{{ newsletterDetail.title }}</p>
                    </div>
                    <span>작성일 <i>{{ $yyyymmddOrHHmm(newsletterDetail.createDate )}}</i></span>
                    <span>조회수 <i>{{ $number(newsletterDetail.hitCount) }}</i></span>
                </div>
                <div class="body">
                    <div class="text">
                        <p v-html="newsletterDetail.content"></p>
                    </div>
                </div>
                <div class="control">
                    <div v-if="newsletterDetail.prevNewsletter && newsletterDetail.prevNewsletter.newsletterId">이전글<RouterLink :to="{name: 'InsightsNewsletterDetail', params:{id: newsletterDetail.prevNewsletter.newsletterId}}"><span>{{ newsletterDetail.prevNewsletter.title }}</span></RouterLink></div>
                    <div v-if="newsletterDetail.nextNewsletter && newsletterDetail.nextNewsletter.newsletterId">다음글<RouterLink :to="{name: 'InsightsNewsletterDetail', params:{id: newsletterDetail.nextNewsletter.newsletterId}}"><span>{{ newsletterDetail.nextNewsletter.title }}</span></RouterLink></div>
                </div>
            </div>
            <div class="btn-wrap text-left">
                <KSASecondaryButton text="목록" class="gray_button" @click="router.push({name: 'InsightsNewsletter'})" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">

</style>
