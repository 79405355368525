<script setup>
    import { ref, onMounted, computed } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import adminUserService from "../../services/user/adminUserService";

    const route = useRoute();
    const router = useRouter();
    const loadingStore = usePageLoadingStore();

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            goListView();
        } else {
            retrieveAdminDetail(id)
        }
    })

    const user = ref({})

    const confirmModal = ref(null)
    const modalContent = ref([])

    const retrieveAdminDetail = (id) => {
        loadingStore.loading = true;
        adminUserService.retrieveAdminDetail(id)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    result.accessMenu = result.accessMenu
                        .replace('us', '회원 관리')
                        .replace('lt', '강의 관리')
                        .replace('cr', '수강 관리')
                        .replace('am', '관리자 관리')
                        .replace('hr', '인재 채용 현황')
                        .replace('eq', '장비 수요 현황')
                        .replace('sc', '통계 자료')
                        .replace('st', '사이트 관리')
                        .replace('mg', '메일/메시지 관리')
                        .replace('cs', '고객센터')
                    result.accessMenu = result.accessMenu.split(',').join(', ');
                    user.value = result;
                } else {
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            modalContent.value = res.response.data.result.message.split("\n");
            modal.value.show();
        });
    }

    const updateUser = () => {
        router.push({name: 'ManagerUpdate', params:{id: user.value.userId}});
    }

    const deleteUser = async () => {
        modalContent.value = ["관리자를 삭제할까요?", "삭제 시 모든 관리자 정보는 삭제되며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show()

        if(ok) {
            loadingStore.loading = true;
            adminUserService.deleteAdminAccount(user.value.userId)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        goListView();
                    } else {
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalContent.value = [res.response.data.result.message].split("\n");
                modal.value.show();
            });
        }
    }

    const goListView = () => {
        router.push({name: 'ManagerList'})
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>{{ user.userName }}님 관리자 정보</h3>
                </div>
                <div class="btn-wrap space-between mb-xs">
                    <KSASecondaryButton class="gray_button" text="목록" @click="goListView" />
                    <div>
                        <KSASecondaryButton text="수정" @click="updateUser" />
                        <KSASecondaryButton class="red_button" text="삭제" @click="deleteUser" />
                    </div>
                </div>
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="200px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>유형</th>
                            <td>{{ $userGbKR(user.userGb) }}</td>
                        </tr>
                        <tr>
                            <th>아이디</th>
                            <td>{{ user.userLoginId }}</td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>{{ user.userName }}</td>
                        </tr>
                        <tr>
                            <th>핸드폰 번호</th>
                            <td>{{ $phoneNum(user.phoneNum) }}</td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>{{ user.email }}</td>
                        </tr>
                        <tr>
                            <th>접근 가능한 메뉴</th>
                            <td>{{ user.accessMenu }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="삭제" cancelButtonText="취소" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>