<script setup>
    import { ref, watch, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from "../../stores/user";
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import { storeToRefs } from "pinia";
    import mypageUserService from "../../services/mypage/mypageUserService";

    const router = useRouter()
    const userStore = useUserStore()
    const { isAdmin } = storeToRefs(userStore)
    const loadingStore = usePageLoadingStore()

    const { $regex } = getCurrentInstance().appContext.config.globalProperties;

    const input = ref({
        password: '',
        passwordVerify: '',
    })

    const inputError = ref({
        password: '',
        passwordVerify: '',
    })

    const modal = ref(null)
    const modalContent = ref([])

    watch(() => input.value.password, (newValue, oldValue) => {
        inputError.value.password = $regex.pw.test(newValue) ? '' : '영문 대소문자, 숫자, 기호 사용하여 8자 이상으로 입력하세요.';
    })

    watch(() => input.value.passwordVerify, (newValue, oldValue) => {
        inputError.value.passwordVerify = input.value.password === newValue ? '새 비밀번호와 일치합니다.' : '입력한 값이 새 비밀번호와 일치하지 않습니다. 다시 입력해 주세요.';
    })

    const resetPassword = () => {
        const { password, passwordVerify } = input.value;
        let isInvalid = false;

        inputError.value.password = '';
        inputError.value.passwordVerify = '';

        if(!password || !$regex.pw.test(password)) {
            inputError.value.password = '대/소문자,숫자,기호를 사용하여 8자 이상 입력하세요.';
            isInvalid = true;
        }
        if(!passwordVerify) {
            inputError.value.passwordVerify = '새 비밀번호를 다시 입력해 주세요.';
            isInvalid = true;
        } else if(password !== passwordVerify) {
            inputError.value.passwordVerify = '입력한 값이 새 비밀번호와 일치하지 않습니다. 다시 입력해 주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                oldUserLoginPw: "UNKNOWN",
                userLoginPw: password,
                userLoginPwVerify: passwordVerify,
                // "modifyId": 0
            }

            loadingStore.loading = true;
            mypageUserService.changePassword(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        userStore.setToken('');
                        userStore.setRefreshToken('');
                        modalContent.value = ["비밀번호가 변경되었습니다."];
                        const ok = await modal.value.show();
                        if(ok) {
                            router.push({name: 'Signin'});
                        }
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }
</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <div class="title">
                <h3>비밀번호 재설정</h3>
            </div>
            <div class="sign-content">
                <KSAInput class="text-box" v-model="input.password" label="새 비밀번호" placeholder="비밀번호를 입력하세요." type="password" max-length="30" :msg="inputError.password" />
                <KSAInput class="text-box" v-model="input.passwordVerify" label="새 비밀번호 확인" placeholder="비밀번호를 입력하세요." type="password" :msg="inputError.passwordVerify" :msg-color="input.passwordVerify && input.password === input.passwordVerify ? 'black' : 'red'" />

                <KSAPrimaryButton class="full-btn" text="비밀번호 변경하기" @click="resetPassword" />
            </div>
            <ul class="member">
                <li><RouterLink :to="{name: 'SigninSearchID'}">아이디 찾기</RouterLink></li>
                <li v-if="!isAdmin"><RouterLink :to="{name: 'Signup'}">회원가입</RouterLink></li>
            </ul>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style scoped>
</style>