<script setup>
    import { ref, onMounted, computed, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import insightsNewsletterService from "../../services/insights/insightsNewsletterService";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useUserInsightsStore} from "../../stores/insights/userInsightsStore";

    const loadingStore = usePageLoadingStore()
    const userInsightsStore = useUserInsightsStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    const searchType = ref('all');
    const searchTypeOptions = [
        { label:'전체', value:'all', },
        { label:'제목', value:'title', },
        { label:'내용', value:'content', },
    ]
    const input = ref({
        search: '',
    })

    const list = ref([])
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    onMounted(()=>{
        retrieveList();
    });

    const isInitView = computed(()=> {
        return currentPage.value === 1 && searchType.value === 'all' && input.value.search === '';
    });

    const retrieveList = () => {
        let paramObj = {
            pageNo: currentPage.value,
            size: pageSize,
            searchType: searchType.value,
            searchInput: input.value.search || ""
        }
        loadingStore.loading = true;
        insightsNewsletterService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                    if(isInitView.value) {
                        userInsightsStore.setNewsletterList(list.value);
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>뉴스레터</h3>
            </div>
            <div class="list-top">
                <p>총 게시물 <span>{{ $number(totalCount) }}</span>건</p>
            </div>
            <div class="table-form board-mobile-vertical">
                <table>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>작성일</th>
                        <th>조회수</th>
                    </tr>
                    <tr v-for="(newsletter, index) in (isInitView ? userInsightsStore.newsletterList : list)">
                        <td data-title="번호 : ">{{ newsletter.boardNo }}</td>
                        <td class="text-left" data-title="제목 : ">
                            <RouterLink :to="{ name: 'InsightsNewsletterDetail', params: {id: newsletter.newsletterId }}">{{ newsletter.title }}</RouterLink>
                        </td>
                        <td data-title="작성일 : ">{{ $yyyymmddOrHHmm(newsletter.createDate)}}</td>
                        <td data-title="조회수 : ">{{ $number(newsletter.hitCount) }}</td>
                    </tr>
                </table>
                <div class="result-none" v-if="list.length === 0">
                    검색 결과가 없습니다.
                </div>
            </div>
            <Paginate
                v-if="list.length > 0"
                v-model="currentPage"
                :page-count="totalPages"
                :click-handler="onSelectedPage"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>