<script setup>
    import { ref, onMounted, getCurrentInstance, inject } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import _ from "lodash";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useMatchingUpdateStore} from "../../../user/stores/matching/matchingUpdateStore";
    import adminUserService from "../../services/user/adminUserService";
    import mypageUserService from "../../../user/services/mypage/mypageUserService";
    import adminMatchingService from "../../services/matching/adminMatchingService";
    import mypageProposalService from "../../../user/services/mypage/mypageProposalService";
    import mypageMatchingService from "../../../user/services/mypage/mypageMatchingService";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import ResumeModal from "../../../user/components/modal/mypage/MyPageResumeModal.vue"
    import KSASecondaryButton from "../../../../components/ui-components/button/KSASecondaryButton.vue";
    import MypageProposalContentModal from "../../../user/views/mypage/proposal/MypageProposalContentModal.vue";
    import MyPageResumeModal from "../../../user/components/modal/mypage/MyPageResumeModal.vue";


    const getSystemCodeValues = inject('systemCodeValues');
    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const matchingUpdateStore = useMatchingUpdateStore()
    //const loaded = ref(false);
    const { $interests, $corpState, $gbNameKR, $toast, $number, $pageListBoardNo, $yyyymmddHHmmss, $gb } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=> {
        const { id } = route.params;
        if(!id) {
            goBack()
        } else {
          setTimeout(function(){
            user.value.userId = id;
            retrieveUserDetail();
            retrieveRecordDetail();
            retrieveMatchHistoryList();
            retrieveUserMatchHistoryList();
            retrieveUserGbHistoryList();
          },1000); // 1초 1000
        }
    })

    const BASIC = 'BASIC';
    const LECT_HIST = 'LECT_HIST';
    const MATCH_HIST = 'MATCH_HIST';
    const MATCH_USER_HIST = 'MATCH_USER_HIST';
    const LECT_CREATE_HIST = 'LECT_CREATE_HIST';
    const USER_GB_HIST = 'USER_GB_HIST';

    const tabIndex = ref(BASIC)

    const tabOptions = ref([
      {label: '기본 정보', value: BASIC},
      {label: '수강 내역', value: LECT_HIST},
      {label: '회원 유형 변경 내역', value: USER_GB_HIST},
    ])

    const tabOptionsTeacher = ref([
        {label: '기본 정보', value: BASIC},
        {label: '수강 내역', value: LECT_HIST},
        {label: '강의 개설 내역', value: LECT_CREATE_HIST},
        {label: '회원 유형 변경 내역', value: USER_GB_HIST},
    ])

    const tabOptionsCompany = ref([
      {label: '기본 정보', value: BASIC},
      {label: '수강 내역', value: LECT_HIST},
      {label: '채용 진행 현황', value: MATCH_HIST},
      {label: '회원 유형 변경 내역', value: USER_GB_HIST},
    ])

    const tabOptionsStudent = ref([
      {label: '기본 정보', value: BASIC},
      {label: '수강 내역', value: LECT_HIST},
      {label: '매칭 내역', value: MATCH_USER_HIST},
      {label: '회원 유형 변경 내역', value: USER_GB_HIST},
    ])

    const user = ref({
        userId: '',
    })

    // s: 채용 진행 현황
    const interests = ref($interests)
    const corpState = ref($corpState)
    const interestsListRef3 = ref(null)
    const stateGbsListRef3 = ref(null)
    const matchingGbListRef3 = ref(null)
    const interestsListRef4 = ref(null)
    const stateGbsListRef4 = ref(null)
    const matchingGbListRef4 = ref(null)
    const searchOpen3 = ref(true); // 채용 진행 현황 tab 검색창
    const searchOpen4 = ref(true); // 매칭 내역 tab 검색창

    const resultCount = ref({}); // 채용 진행 현황 수
    const resultUserCount = ref({}); // 매칭 내역 수

    const currentPage3_master = ref(1);
    const currentPage3_user = ref(1);
    const currentPage4 = ref(1);
    const pageSize3_master = ref(10);
    const pageSize3_user = ref(10);
    const pageSize4 = ref(10);
    const totalPages3_master = ref(0);
    const totalPages3_user = ref(0);
    const totalPages4 = ref(0);
    const totalCount3_master = ref(0);
    const totalCount3_user = ref(0);
    const totalCount4 = ref(0);

    const searchInput3 = ref({ // 채용 진행 현황 tab 검색조건
      stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
      interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
      matchingGbList: ['01','02'],
      searchType: 'userName', // userName 구직자명
      searchInput: '',
      searchDurationType: 'all',
      startYmd: '',
      endYmd: '',
    });

    const searchInput4 = ref({ // 채용 진행 현황 tab 검색조건
      stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
      interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
      matchingGbList: ['01','02'],
      searchType: 'corpName', // corpName 기업명
      searchInput: '',
      searchDurationType: 'all',
      startYmd: '',
      endYmd: '',
    });

    // 검색조건 - 인재 채용, 채용 제안. 채용 진행 현황 tab
    const GroupMatching3 = ref([
      { label:'인재 채용', value: '02' },
      { label:'채용 제안', value: '01' },
    ]);

    // 검색조건 - 인재 채용, 채용 제안. 채용 진행 현황 tab
    const GroupMatching4 = ref([
      { label:'입사 지원', value: '02' },
      { label:'받은 채용', value: '01' },
    ]);

    // 검색조건 - 날짜검색기준 (all 모든 기간, term 기간 검색). 재직자 가입 현황 tab
    const searchDurationTypeGroup = ref([
      { label:'모든 기간', value: 'all' },
      { label:'기간 검색', value: 'term' },
    ])

    // 검색조건 - 페이지 크기, 재직자 가입 현황 tab, 채용 진행 현황 tab
    const pageSizeGroup = ref([
      { label:'10개씩 보기', value: '10' },
      { label:'30개씩 보기', value: '30' },
      { label:'50개씩 보기', value: '50' },
      { label:'100개씩 보기', value: '100' },
    ])

    // e: 채용 진행 현황

    const userRecordDetail = ref(null);
    const corpMatchingList = ref([]);  // 채용 현황 목록
    const userMatchingList = ref([]);  // 매칭 내역
    const userGbHistoryList = ref([]);  // 회원 유형 변경 내역
    const lectureHistories = ref(null)

    const lectureCreateHistories = ref(null)

    const modal = ref(null)
    const confirmModal = ref(null)
    const resumeModal = ref(null)
    const userMatchingResumeModal = ref(null);
    const proposalContentModal = ref(null);
    const modalContent = ref([]);
    const okButtonText = ref('');

    const retrieveUserDetail = () => {
        loadingStore.loading = true;
        adminUserService.retrieveUser(user.value.userId)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    user.value = res.data.result;
                } else {
                }
            }).catch( (res) =>  {
            console.log(res);
            loadingStore.loading = false;
        });
    }

    const retrieveRecordDetail = () => {
      let paramObj = {
        userId: user.value.userId,
        propId: 0,
        hidden: 0,
      }
        adminUserService.retrieveUserMyPage(paramObj)
            .then( async (res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    result.introOpenYn = result.introOpenYn === 'Y';
                    result.eduHistOpenYn = result.eduHistOpenYn === 'Y';
                    result.portfolioOpenYn = result.portfolioOpenYn === 'Y';
                    result.certForeignOpenYn = result.certForeignOpenYn === 'Y';
                    result.jobHistOpenYn = result.jobHistOpenYn === 'Y';
                    result.milOpenYn = result.milOpenYn === 'Y';
                    userRecordDetail.value = result;
                } else {
                }
            }).catch( (res) =>  {
            console.error(res);
            // modalContent.value = res.response.data.result.message.split("\n");
            // modal.value.show();
        });
    }

    const retrieveLectHistoryList = () => {
        loadingStore.loading = true;
        adminUserService.retrieveUserLectureList(user.value.userId)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    lectureHistories.value = result;
                } else {
                }
            }).catch( (res) =>  {
            console.log(res);
            loadingStore.loading = false;
        });
    }

    // s: 매칭 내역
    // 매칭 내역 조회
    const retrieveUserMatchHistoryList = async () => {
      let paramObj = {
        userId: user.value.userId,  // userId 추가
        searchStateGbs: _.map(searchInput4.value.stateGbsList).join(','),
        searchInterests: _.map(searchInput4.value.interestsList).join(','),
        searchMatchingGb: _.map(searchInput4.value.matchingGbList).join(','),
        searchType: searchInput4.value.searchType,
        searchInput: searchInput4.value.searchInput,
        startYmd: searchInput4.value.searchDurationType === 'all' ? '' : searchInput4.value.startYmd,
        endYmd: searchInput4.value.searchDurationType === 'all' ? '' : searchInput4.value.endYmd,
        pageNumber: currentPage3_user.value,
        size: pageSize3_user.value,
      }
      loadingStore.loading = true;
      //loaded.value = false;
      await getSystemCodeValues();
      adminMatchingService.retrieveUserMatchingList(paramObj)
          .then( async (res) => {
            loadingStore.loading = false;
            await retrieveUserCount();
            //loaded.value = true;
            //console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              totalPages3_user.value = result.totalPages;
              totalCount3_user.value = result.totalElements;
              userMatchingList.value = $pageListBoardNo(result.content, totalCount3_user.value, pageSize3_user.value, currentPage3_user.value);
            } else {
            }
          }).catch( (res) =>  {
        console.log(res);
        loadingStore.loading = false;
        //loaded.value = true;
      });
    }

    const retrieveUserCount = () => {
      adminMatchingService.retrieveMatchingUserTotalCount(user.value.userId)
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              resultUserCount.value = res.data.result;
            } else {
            }
          }).catch( (res) => {
      });
    }

    // 채용 상태 변경
    const changeUserState = (matchingGb, userId, stateGb, propId, corpId, resumeId) => {
      if(matchingGb === '01') {  // 받은 채용 제안
        changeUserPropState(userId, propId, stateGb);
      } else if(matchingGb === '02') {  // 입사 지원 현황(인재 채용)
        changeUserResState(resumeId, corpId, stateGb)
      }
    }

    // 인재 채용 상태 변경
    const changeUserResState = async (resumeId, corpId, stateGb) => {
      if ( stateGb === "03" ) {
        modalContent.value = ["채용 제안을 수락하시겠습니까?", "‘매칭완료’로 상태가 변경됩니다."];
        okButtonText.value = "제안 수락";
      } else if ( stateGb === "04" ) {
        modalContent.value = ["채용 제안을 거절하시겠습니까?", "‘매칭중단’으로 상태가 변경됩니다."];
        okButtonText.value = "제안 거절";
      }
      okButtonText.value = "변경";

      const ok = await confirmModal.value.show();

      if(!ok) {
        return -1;
      } else {
        let paramObj2 = {
          resumeId: resumeId,
          corpId: corpId,
          resStateGb: stateGb,
        }
        mypageProposalService.changeResumeCorporationState(paramObj2)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveUserMatchHistoryList();
                // 매칭 상태값 업데이트
                retrieveMatchingUpdate();
              } else {
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    }

    // 채용 제안 상태 변경(받은 채용)
    const changeUserPropState = async (userId, propId, stateGb) => {
      if( stateGb === '03' ) {
        modalContent.value = ["채용 제안을 수락하시겠습니까?", "'매칭완료'로 상태가 변경됩니다."];
        okButtonText.value = "제안 수락";
      } else if ( stateGb === '04' ) {
        modalContent.value = ["채용 제안을 거절하시겠습니까?", "'매칭중단'으로 상태가 변경됩니다."];
        okButtonText.value = "제안 거절";
      }
      okButtonText.value = "변경";
      const ok = await confirmModal.value.show();
      if(!ok) {
        return false;
      } else {
        let paramObj = {
          propId: propId,
          userId: userId,
          propStateGb: stateGb
        };
        //console.log(paramObj);

        loadingStore.loading = true;
        mypageProposalService.changeProposalUserState(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveUserMatchHistoryList();
                // 매칭 상태 값 업데이트
                retrieveMatchingUpdate();
              } else {
                console.log(res);
              }
            }).catch( (res) => {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    }

    // e: 매칭 내역

    // s: 채용 진행 현황 - corpMaster
    // 채용 진행 현황 조회
    const retrieveMatchHistoryList = async () => {
      let paramObj = {
        corpUserId: user.value.userId,  // userId 추가
        searchStateGbs: _.map(searchInput3.value.stateGbsList).join(','),
        searchInterests: _.map(searchInput3.value.interestsList).join(','),
        searchMatchingGb: _.map(searchInput3.value.matchingGbList).join(','),
        searchType: searchInput3.value.searchType,
        searchInput: searchInput3.value.searchInput,
        startYmd: searchInput3.value.searchDurationType === 'all' ? '' : searchInput3.value.startYmd,
        endYmd: searchInput3.value.searchDurationType === 'all' ? '' : searchInput3.value.endYmd,
        pageNumber: currentPage3_master.value,
        size: pageSize3_master.value,
      }
      loadingStore.loading = true;
      //loaded.value = false;
      await getSystemCodeValues();
      adminMatchingService.retrieveCorpMatchingList(paramObj)
          .then( async (res) => {
            loadingStore.loading = false;
            await retrieveCount();
            //loaded.value = true;
            //console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              totalPages3_master.value = result.totalPages;
              totalCount3_master.value = result.totalElements;
              corpMatchingList.value = $pageListBoardNo(result.content, totalCount3_master.value, pageSize3_master.value, currentPage3_master.value);
            } else {
            }
          }).catch( (res) =>  {
        console.log(res);
        loadingStore.loading = false;
        //loaded.value = true;
      });
    }

    const retrieveCount = () => {
      let paramObj = {
        corpId: 0,
        corpUserId: user.value.userId,
      }
      adminMatchingService.retrieveMatchingTotalCount(paramObj)
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              resultCount.value = res.data.result;
            } else {
            }
          }).catch( (res) => {
            console.log(res)
      });
    }

    // 채용 상태 변경
    const changeState = (matchingGb, userId, stateGb, propId, corpId, resumeId) => {
      if(matchingGb === '01') {  // 채용 제안
        changePropState(userId, propId, stateGb);
      } else if(matchingGb === '02') {  // 인재 채용
        changeResState(resumeId, corpId, stateGb)
      }
    }

    // 채용 제안 상태 변경
    const changePropState = async (userId, propId, stateGb) => {
      if(stateGb === '02') {
        modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
      } else if(stateGb === '05') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
      } else if(stateGb === '06') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
      } else if(stateGb === '07') {
        modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
      } else if(stateGb === '08') {
        modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
      }
      okButtonText.value = "변경";

      const ok = await confirmModal.value.show();
      if(!ok) {
        return false;
      } else {
        let paramObj = {
          propId: propId,
          userId: userId,
          propStateGb: stateGb
        };
        //console.log(paramObj);

        loadingStore.loading = true;
        mypageProposalService.changeProposalUserState(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                // const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveMatchHistoryList();
                // 매칭 상태 값 업데이트
                retrieveMatchingUpdate();
              } else {
                console.log(res);
              }
            }).catch( (res) => {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    };

    // 인재 채용 상태 변경
    const changeResState = async (resumeId, corpId, stateGb) => {
      if(stateGb === '02') {
        modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
      } else if(stateGb === '05') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
      } else if(stateGb === '06') {
        modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
      } else if(stateGb === '07') {
        modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
      } else if(stateGb === '08') {
        modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
      }
      okButtonText.value = "변경";
      const ok = await confirmModal.value.show();
      if(!ok) {
        return -1;
      } else {
        let paramObj2 = {
          resumeId: resumeId,
          corpId: corpId,
          resStateGb: stateGb,
        }
        mypageProposalService.changeResumeCorporationState(paramObj2)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                //const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveMatchHistoryList();
                // 매칭 상태값 업데이트
                retrieveMatchingUpdate();
              } else {
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    }

    // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
    const retrieveMatchingUpdate = async () => {
      await mypageMatchingService.retrieveMatchingUpdate()
          .then( async (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result1 = res.data.result;
              matchingUpdateStore.matchingUpdate = result1;
              await matchingUpdateStore.setMatchingUpdate( result1 );
              matchingUpdateStore.value = await useMatchingUpdateStore();
            }
          }).catch( (error) => {
            loadingStore.loading = false;
            console.log(error);
          });
    }

    // 기본 이력서 보기
    const showUserResumeModal = (userId) => {
      userMatchingResumeModal.value.init(false);
      userMatchingResumeModal.value.retrieveRecordDetail(userId);
      userMatchingResumeModal.value.show();
    }

    // 채용 이력서 보기
    const showMatchingResumeModal = (resumeId) => {
      userMatchingResumeModal.value.init(false);
      userMatchingResumeModal.value.retrieveUserMatchingResumeDetail(resumeId, 0);
      userMatchingResumeModal.value.show();
    }

    // 채용 제안서 보기
    const showProposalContentModal = async (propId) => {
      proposalContentModal.value.retrieveProposalContent(propId);
      await proposalContentModal.value.show();
    };

    // 검색조건 - 초기화 이벤트
    const clearSearchInput = () => {
      if(tabIndex.value === 'MATCH_HIST') { // 채용 진행 현황
        searchInput3.value = {
          stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
          interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
          matchingGb: ['01','02'],
          searchInput: '',
          searchDurationType: 'all',
          startYmd: '',
          endYmd: '',
        }
        stateGbsListRef3.value.initOptions();
        interestsListRef3.value.initOptions();
        matchingGbListRef3.value.initOptions();
      } else if(tabIndex.value === 'MATCH_USER_HIST') { // 매칭 내역
        searchInput4.value = {
          stateGbsList: ['01', '02', '03', '04', '05', '06', '07', '08'],
          interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
          matchingGb: ['01','02'],
          searchInput: '',
          searchDurationType: 'all',
          startYmd: '',
          endYmd: '',
        }
        stateGbsListRef4.value.initOptions();
        interestsListRef4.value.initOptions();
        matchingGbListRef4.value.initOptions();
      }
    }

    // 검색조건 - 검색 이벤트
    const onClickSearch = () => {
      if(tabIndex.value === 'MATCH_HIST') { // 채용 진행 현황
        currentPage3_master.value = 1;
        retrieveMatchHistoryList();
      } else if(tabIndex.value === 'MATCH_USER_HIST') { // 매칭 내역
        currentPage3_user.value = 1;
        retrieveUserMatchHistoryList();
      }
    }

    // 페이지 선택
    const onSelectPage = (pageNo) => {
      if(tabIndex.value === 'MATCH_HIST') { // 채용 진행 현황
        currentPage3_master.value = pageNo;
        retrieveMatchHistoryList();
      } else if(tabIndex.value === 'MATCH_USER_HIST') { // 매칭 내역
        currentPage3_user.value = pageNo;
        retrieveUserMatchHistoryList();
      } else if(tabIndex.value === 'USER_GB_HIST') { // 회원 유형 변경 내역
        currentPage4.value = pageNo;
        retrieveUserGbHistoryList();
      }
    }

    // 검색조건 - 페이지 크기 변경 이벤트
    const onSelectPageSize = (option) => {
      if(tabIndex.value === 'MATCH_HIST') { // 채용 진행 현황
        pageSize3_master.value = option.value;
        currentPage3_master.value = 1;
        retrieveMatchHistoryList();
      } else if(tabIndex.value === 'MATCH_USER_HIST') { // 매칭 내역
        pageSize3_user.value = option.value;
        currentPage3_user.value = 1;
        retrieveUserMatchHistoryList();
      } else if(tabIndex.value === 'USER_GB_HIST') { // 회원 유형 변경 내역
        pageSize4.value = option.value;
        currentPage4.value = 1;
        retrieveUserGbHistoryList();
      }
    }
    // e: 채용 진행 현황


    // s: 회원 유형 변경 내역

    // 회원 유형 변경 내역 조회
    const retrieveUserGbHistoryList = async () => {
      let paramObj = {
        userId: user.value.userId,
        pageNumber: currentPage4.value,
        size: pageSize4.value,
      }
      loadingStore.loading = true;
      //loaded.value = false;
      await getSystemCodeValues();
      mypageUserService.retrieveUserGbHistoryListByUserId(paramObj)
          .then( async (res) => {
            loadingStore.loading = false;
            //loaded.value = true;
            //console.log(res);
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              totalPages4.value = result.totalPages;
              totalCount4.value = result.totalElements;
              userGbHistoryList.value = $pageListBoardNo(result.content, totalCount4.value, pageSize4.value, currentPage4.value);
            } else {
            }
          }).catch( (res) =>  {
        console.log(res);
        loadingStore.loading = false;
        //loaded.value = true;
      });
    }

    // 회원 유형 변경 상태 변경
    const changeUserGbHistoryState = async (seq, stateGb) => {
      if ( stateGb === '02' ) {
        modalContent.value = ["신청건이 취소 처리됩니다.", "상태를 ‘취소’로 변경할까요?"];
      }
      okButtonText.value = "변경";

      const ok = await confirmModal.value.show();

      if(!ok) {
        return -1;
      } else {
        let paramObj = {
          seq: seq,
          stateGb: stateGb
        }
        mypageUserService.changeUserGbHistoryState(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveUserGbHistoryList();
              } else {
                console.log(res);
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          console.log(res);
        });
      }
    }
    // e: 회원 유형 변경 내역

    const retrieveLectCreateHistoryList = () => {
        loadingStore.loading = true;
        adminUserService.retrieveUserLectureListByCreateId(user.value.userId)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    lectureCreateHistories.value = res.data.result;
                } else {
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
        });
    }


    const goBack = () => {
        //router.push({name:'MemberList'})
        router.go(-1);
    }

    const deleteUser = async () => {
        okButtonText.value = '탈퇴';
        modalContent.value = ["회원을 탈퇴 시킬까요?", "탈퇴 시 모든 회원 정보는 삭제되며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show();

        if(ok) {
            loadingStore.loading = true;
            adminUserService.deleteUser(user.value.userId)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        console.log(result);
                        goBack();
                    } else {
                        modalContent.value = ["사용자 탈퇴를 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalContent.value = res.response.data.result.message.split("\n");
                modal.value.show();
            });
        }
    }

    const onChangeTab = ({ value }) => {
        tabIndex.value = value;

        if(tabIndex.value === LECT_HIST) { // 수강 내역
            if(lectureHistories.value === null) {
                retrieveLectHistoryList()
            }
        } else if(tabIndex.value === MATCH_HIST) { // 채용 진행 현황
            if(corpMatchingList.value === null) {
                retrieveMatchHistoryList()
            }
        } else if(tabIndex.value === MATCH_USER_HIST) { // 매칭 내역
          if(userMatchingList.value === null) {
            retrieveUserMatchHistoryList()
          }
        } else if(tabIndex.value === LECT_CREATE_HIST) { // 강의개설 내역
            if(lectureCreateHistories.value === null) {
                retrieveLectCreateHistoryList()
            }
        } else if(tabIndex.value === USER_GB_HIST) { // 회원 유형 변경 내역
            if(userGbHistoryList.value === null) {
                retrieveUserGbHistoryList()
            }
        }
    }

    const onClickUpdateCorpMasterYn = async () => {
        const corpMasterYn = user.value.corpMasterYn === 'Y' ? 'N' : 'Y';

        if(corpMasterYn === 'Y') {
            okButtonText.value = '권한 부여';
            modalContent.value = ["회원에게 마스터 권한을 부여할까요?", "마스터 회원은 일자리/장비 매칭을 할 수 있습니다."];
        } else {
            okButtonText.value = '권한 취소';
            modalContent.value = ["마스터 권한을 취소 시킬까요?", "취소하더라도 매칭 내역 정보는 삭제되지 않습니다."];
        }
        const ok = await confirmModal.value.show();

        if(ok) {
            let paramObj = {
                userId: user.value.userId,
                corpMasterYn,
            }
            loadingStore.loading = true;
            adminUserService.updateCorpMasterYn(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        user.value = res.data.result;
                    } else {
                        modalContent.value = ["마스터 권한 변경을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalContent.value = res.response.data.result.message.split("\n");
                modal.value.show();
            });
        }
    }

    const onClickResumeView = () => {
        // 이력서 팝업 오픈
        resumeModal.value.init(false);
        resumeModal.value.retrieveRecordDetail(user.value.userId);
        resumeModal.value.show();
    }

    const goLectureCourseDetail = (lectId) => {
        window.open(`/admin/course/${lectId}`, '_blank');
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title space-between">
                    <h3>{{ user.userName }}님 회원정보</h3>
                </div>

                <div class="btn-wrap space-between mb-xs">
                    <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                    <KSASecondaryButton class="red_button" text="탈퇴" @click="deleteUser" />
                </div>

                <KSATab v-if="user.userGb === 'STUDENT'" style="grid-template-columns: repeat(8, 1fr);" class="mb-lg" :options="tabOptionsStudent" @change="onChangeTab"/>
                <KSATab v-else-if="user.userGb === 'COMPANY'" style="grid-template-columns: repeat(8, 1fr);" class="mb-lg" :options="tabOptionsCompany" @change="onChangeTab"/>
                <KSATab v-else-if="user.userGb === 'TEACHER'" style="grid-template-columns: repeat(8, 1fr);" class="mb-lg" :options="tabOptionsTeacher" @change="onChangeTab"/>
                <KSATab v-else style="grid-template-columns: repeat(8, 1fr);" class="mb-lg" :options="tabOptions" @change="onChangeTab"/>

                <!-- s: 기본 정보 -->
                <div v-if="tabIndex === BASIC">
                    <!-- s: 학생/취업준비생 -->
                    <div v-if="user.userGb === 'STUDENT'">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>회원유형</th>
                                    <td>{{ $userGbKR(user.userGb) }}</td>
                                    <th>회원코드</th>
                                    <td>{{ user.userCd }}</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{{ user.userLoginId }}</td>
                                    <th>이름</th>
                                    <td>{{ user.userName }}</td>
                                </tr>
                                <tr>
                                    <th>핸드폰 번호</th>
                                    <td>{{ $phoneNum(user.phoneNum) }}</td>
                                    <th>이메일</th>
                                    <td>{{ user.email }}</td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td>{{ $yyyymmdd(user.birthYmd) }}</td>
                                    <th>성별</th>
                                    <td>{{ user.sex === '01' ? '남자' : '여자' }}</td>
                                </tr>
                                <tr>
                                    <th>학교</th>
                                    <td>{{ user.school }}</td>
                                    <th>학력/상태</th>
                                    <td>{{ $gbNameKR('SCHOOL_GB', user.schoolGb) }}/{{ $gbNameKR('EDU_TENURE_GB', user.eduTenureGb) }}</td>
                                </tr>
                                <tr>
                                    <th>거주 지역</th>
                                    <td colspan="3">{{ user.residenceArea1 }} {{ user.residenceArea2 }}</td>
                                </tr>
                                <tr>
                                    <th>희망 직무</th>
                                    <td colspan="3">{{ user.desiredJob || '-' }}</td>
                                </tr>
                                <tr>
                                    <th>관심 카테고리</th>
                                    <td colspan="3">{{ user.interests || '-' }}</td>
                                </tr>
                                <tr>
                                    <th>이력서</th>
                                    <td colspan="3">
                                        <KSATextButton class="underline" text="이력서 보기" @click="onClickResumeView" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>가입일</th>
                                    <td>{{ $yyyymmdd(user.regYmd) }}</td>
                                    <th>최근 접속일</th>
                                    <td>{{ $yyyymmdd(user.lastLoginDate) }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- e: 학생/취업준비생 -->

                    <!-- s:  재직자/기업 -->
                    <div v-else-if="user.userGb === 'COMPANY' && user.corpMasterYn !== 'Y'">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>회원유형</th>
                                    <td>{{ $userGbKR(user.userGb) }} <KSASecondaryButton class="sm-size ml-xs" text="마스터 권한 부여" @click="onClickUpdateCorpMasterYn" /></td>
                                    <th>회원코드</th>
                                    <td>{{ user.userCd }}</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{{ user.userLoginId }}</td>
                                    <th>이름</th>
                                    <td>{{ user.userName }}</td>
                                </tr>
                                <tr>
                                    <th>핸드폰 번호</th>
                                    <td>{{ $phoneNum(user.phoneNum) }}</td>
                                    <th>이메일</th>
                                    <td>{{ user.email }}</td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td>{{ $yyyymmdd(user.birthYmd) }}</td>
                                    <th>성별</th>
                                    <td>{{ user.sex === '01' ? '남자' : '여자' }}</td>
                                </tr>
                                <tr>
                                    <th>사업자등록번호</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.bizNum">{{ $bizNum(user.corporation.bizNum) }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <th>기업명</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.corpName">{{ user.corporation.corpName }}</span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>거주 지역</th>
                                    <td colspan="3">{{ user.residenceArea1 }} {{ user.residenceArea2 }}</td>
                                </tr>
                                <tr>
                                    <th>본인 직무</th>
                                    <td colspan="3">{{ user.desiredJob || '-' }}</td>
                                </tr>
                                <tr>
                                    <th>관심 카테고리</th>
                                    <td colspan="3">{{ user.interests || '-' }}</td>
                                </tr>
                                <tr>
                                    <th>이력서</th>
                                    <td colspan="3">
                                        <KSATextButton class="underline" text="이력서 보기" @click="onClickResumeView" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>가입일</th>
                                    <td>{{ $yyyymmdd(user.regYmd) }}</td>
                                    <th>최근 접속일</th>
                                    <td>{{ $yyyymmdd(user.lastLoginDate) }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- e: 재직자/기업 -->

                    <!-- s:  재직자/기업-마스터 -->
                    <div v-else-if="user.userGb === 'COMPANY' && user.corpMasterYn === 'Y'">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>회원유형</th>
                                    <td>{{ $userGbKR(user.userGb) }}-마스터 <KSASecondaryButton class="sm-size ml-xs" text="마스터 권한 취소" @click="onClickUpdateCorpMasterYn" /></td>
                                    <th>회원코드</th>
                                    <td>{{ user.userCd }}</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{{ user.userLoginId }}</td>
                                    <th>이름</th>
                                    <td>{{ user.userName }}</td>
                                </tr>
                                <tr>
                                    <th>핸드폰 번호</th>
                                    <td>{{ $phoneNum(user.phoneNum) }}</td>
                                    <th>이메일</th>
                                    <td>{{ user.email }}</td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td>{{ $yyyymmdd(user.birthYmd) }}</td>
                                    <th>성별</th>
                                    <td>{{ user.sex === '01' ? '남자' : '여자' }}</td>
                                </tr>
                                <tr>
                                    <th>사업자등록번호</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.bizNum">{{ $bizNum(user.corporation.bizNum) }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <th>기업명</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.corpName">{{ user.corporation.corpName }}</span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>거주 지역</th>
                                    <td colspan="3">{{ user.residenceArea1 }} {{ user.residenceArea2 }}</td>
                                </tr>
                                <tr>
                                    <th>이력서</th>
                                    <td colspan="3">
                                        <KSATextButton class="underline" text="이력서 보기" @click="onClickResumeView" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>가입일</th>
                                    <td>{{ $yyyymmdd(user.regYmd) }}</td>
                                    <th>최근 접속일</th>
                                    <td>{{ $yyyymmdd(user.lastLoginDate) }}</td>
                                </tr>
                            </table>
                            <div class="content-title">
                                <h4>기업 정보</h4>
                            </div>
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>법인등록번호</th>
                                    <td colspan="3">
                                        <span v-if="user.corporation && user.corporation.corpNum">{{ $corpNum(user.corporation.corpNum) }}</span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>대표명</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.ceoName">{{ user.corporation.ceoName }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <th>창립연월</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.expireYmd">{{ $yyyymmdd(user.corporation.expireYmd) }}</span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>인원</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.employeeNumGb">{{ $gbNameKR('EMPLOYEE_NUM_GB', user.corporation.employeeNumGb) }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <th>산업군</th>
                                    <td>
                                        <span v-if="user.corporation && user.corporation.industryGb">{{ $gbNameKR('INDUSTRY_GB', user.corporation.industryGb) }}</span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
<!--                                <tr>-->
<!--                                    <th>해당 직무</th>-->
<!--                                    <td colspan="3">-</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <th>연관 과목</th>-->
<!--                                    <td colspan="3">-</td>-->
<!--                                </tr>-->
                            </table>
                        </div>
                    </div>
                    <!-- e: 재직자/기업-마스터 -->

                    <!-- s:  대학/연구실 -->
                    <div v-else-if="user.userGb === 'SCHOOL'">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>회원유형</th>
                                    <td>{{ $userGbKR(user.userGb) }}</td>
                                    <th>회원코드</th>
                                    <td>{{ user.userCd }}</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{{ user.userLoginId }}</td>
                                    <th>담당자 이름</th>
                                    <td>{{ user.userName }}</td>
                                </tr>
                                <tr>
                                    <th>핸드폰 번호</th>
                                    <td>{{ $phoneNum(user.phoneNum) }}</td>
                                    <th>이메일</th>
                                    <td>{{ user.email }}</td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td>{{ $yyyymmdd(user.birthYmd) }}</td>
                                    <th>성별</th>
                                    <td>{{ user.sex === '01' ? '남자' : '여자' }}</td>
                                </tr>
                                <tr>
                                    <th>거주 지역</th>
                                    <td colspan="3">{{ user.residenceArea1 }} {{ user.residenceArea2 }}</td>
                                </tr>
                                <tr>
                                    <th>학교</th>
                                    <td>{{ user.school }}</td>
                                    <th>학력/상태</th>
                                    <td>{{ $gbNameKR('SCHOOL_GB', user.schoolGb) }}/{{ $gbNameKR('EDU_TENURE_GB', user.eduTenureGb) }}</td>
                                </tr>
                                <tr>
                                    <th>교수님 이름</th>
                                    <td>{{ user.professorName}}</td>
                                    <th>연구실 이름</th>
                                    <td>{{ user.labName }}</td>
                                </tr>
                                <tr>
                                    <th>가입일</th>
                                    <td>{{ $yyyymmdd(user.regYmd) }}</td>
                                    <th>최근 접속일</th>
                                    <td>{{ $yyyymmdd(user.lastLoginDate) }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- e: 대학/연구실 -->

                    <!-- s:  강사/전문가 -->
                    <div v-else-if="user.userGb === 'TEACHER'">
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="200px" />
                                    <col />
                                    <col width="200px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>회원유형</th>
                                    <td>{{ $userGbKR(user.userGb) }}</td>
                                    <th>회원코드</th>
                                    <td>{{ user.userCd }}</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{{ user.userLoginId }}</td>
                                    <th>이름</th>
                                    <td>{{ user.userName }}</td>
                                </tr>
                                <tr>
                                    <th>핸드폰 번호</th>
                                    <td>{{ $phoneNum(user.phoneNum) }}</td>
                                    <th>이메일</th>
                                    <td>{{ user.email }}</td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td>{{ $yyyymmdd(user.birthYmd) }}</td>
                                    <th>성별</th>
                                    <td>{{ user.sex === '01' ? '남자' : '여자' }}</td>
                                </tr>
                                <tr>
                                    <th>교육 가능 과목</th>
                                    <td colspan="3">{{ user.ableClass || '-' }}</td>
                                </tr>
                                <tr>
                                    <th>전문 분야</th>
                                    <td colspan="3">{{ user.expertField || '-' }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- e: 강사/전문가 -->
                </div>
                <!-- e: 기본 정보 -->

                <!-- s: 수강내역 -->
                <div v-if="tabIndex === LECT_HIST">
                    <div class="table-form">
                        <table class="horizontal" v-if="lectureHistories !== null">
                            <tr>
                                <th>구분</th>
                                <th>강의명</th>
                                <th>상태</th>
                                <th>수강 신청일</th>
                                <th>교육 기간</th>
                                <th>수강 취소일</th>
                                <th>진도율</th>
                                <th>Pass/Fail</th>
                                <th>수강현황</th>
                            </tr>
                            <tr v-for="lectHist in lectureHistories">
                                <td>{{ lectHist.lectGb === '01' ? '온라인' : '오프라인' }}</td>
                                <td class="link text-left"><RouterLink :to="{name:'LectureDetail', params:{id: lectHist.lectId}}">{{ lectHist.lectName }}</RouterLink></td>
                                <td>
                                    <span>{{ $gbNameKR('LECT_STATE_GB2', lectHist.lectStateGb)}}</span>
                                </td>
                                <td>{{ $yyyymmdd(lectHist.regYmd) }}</td>
                                <td>
                                    <span v-if="(lectHist.lectStateGb === '09' || lectHist.lectStateGb === '10')">-</span> <!-- 09: 수강 신청 취소(사용자) 10: 수강 취소(Admin) -->
                                    <span v-else-if="lectHist.lectTermGb === '01'">신청일로부터 {{lectHist.lectTerm}}일</span>
                                    <span v-else-if="true">{{ $yyyymmdd(lectHist.lectStartYmd)}} ~ {{ $yyyymmdd(lectHist.lectEndYmd) }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span v-if="(lectHist.lectStateGb === '09' || lectHist.lectStateGb === '10')">{{ $yyyymmdd(lectHist.cancelDt) }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span v-if="(lectHist.lectStateGb === '09' || lectHist.lectStateGb === '10')">-</span>
                                    <span v-else>{{ lectHist.lectureIndexFinishCount }}/{{ lectHist.lectureIndexCount }}({{ lectHist.progRate }}%)</span>
                                </td>
                                <td>
                                    <span v-if="(lectHist.lectStateGb === '09' || lectHist.lectStateGb === '10')">-</span>
                                    <span v-else-if="lectHist.finYn">{{ lectHist.finYn === 'Y' ? 'Pass'
                                                                      : lectHist.finYn === 'N' ? 'Fail'
                                                                      : lectHist.finYn === 'D' ? '평가전'
                                                                      : '-' }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td :class="{'wait': (lectHist.lectStateGb === '09' || lectHist.lectStateGb === '10'), 'complete': (lectHist.lectStateGb !== '09' && lectHist.lectStateGb !== '10')}">
                                    <KSATextButton v-if="(lectHist.lectStateGb !== '09' && lectHist.lectStateGb !== '10')" text="수강현황" @click="goLectureCourseDetail(lectHist.lectId)" />
                                    <span v-else>수강현황</span>
                                </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="lectureHistories === null || lectureHistories.length === 0">
                            수강 내역이 없습니다.
                        </div>
                    </div>
                </div>
                <!-- e: 수강내역 -->

                <!-- s : 채용 진행 현황 기업  -->
<!--                <loading
                    :active="!loaded"
                    loader="dots"
                    color="#3843AF"
                    opacity="0.5"
                    width="35"
                    :is-full-page="false"></loading>-->
                <div v-if="tabIndex === MATCH_HIST && user.userGb === 'COMPANY'">
                  <!-- s: 전체 현황 -->
                  <div class="table-form">
                    <table class="horizontal all-border">
                      <tr class="tr-focus-top tr-focus-side">
                        <th rowspan="2">전체 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr class="tr-focus-side tr-focus-bottom">
                        <td>{{ resultCount.totalState }}</td>
                        <td>{{ resultCount.totalState01 }}</td>
                        <td>{{ resultCount.totalState02 }}</td>
                        <td>{{ resultCount.totalState03 }}</td>
                        <td>{{ resultCount.totalState04 }}</td>
                        <td>{{ resultCount.totalState05 }}</td>
                        <td>{{ resultCount.totalState06 }}</td>
                        <td>{{ resultCount.totalState07 }}</td>
                        <td>{{ resultCount.totalState08 }}</td>
                      </tr>
                      <tr>
                        <th rowspan="2">인재 채용 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr>
                        <td>{{ resultCount.totalResState }}</td>
                        <td>{{ resultCount.resState01 }}</td>
                        <td>{{ resultCount.resState02 }}</td>
                        <td>{{ resultCount.resState03 }}</td>
                        <td>{{ resultCount.resState04 }}</td>
                        <td>{{ resultCount.resState05 }}</td>
                        <td>{{ resultCount.resState06 }}</td>
                        <td>{{ resultCount.resState07 }}</td>
                        <td>{{ resultCount.resState08 }}</td>
                      </tr>
                      <tr>
                        <th rowspan="2">채용 제안 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr>
                        <td>{{ resultCount.totalPropState }}</td>
                        <td>{{ resultCount.propState01 }}</td>
                        <td>{{ resultCount.propState02 }}</td>
                        <td>{{ resultCount.propState03 }}</td>
                        <td>{{ resultCount.propState04 }}</td>
                        <td>{{ resultCount.propState05 }}</td>
                        <td>{{ resultCount.propState06 }}</td>
                        <td>{{ resultCount.propState07 }}</td>
                        <td>{{ resultCount.propState08 }}</td>
                      </tr>
                    </table>
                  </div>
                  <!-- e: 전체 현황 -->
                  <br>
                  <!-- s: 검색 -->
                  <div class="filter-wrap" :class="{'open': searchOpen3}">
                    <p @click="searchOpen3 = !searchOpen3">검색</p>
                    <div class="filter">
                      <dl>
                        <dt style="display:block;"><span>상태</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="stateGbsListRef3"
                              className="mr-md"
                              name="stateGbsList"
                              v-model="searchInput3.stateGbsList"
                              :options="corpState" />
                        </dd>
                      </dl>
                      <dl>
                        <dt style="display:block;"><span>관심 카테고리</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="interestsListRef3"
                              className="mr-md"
                              name="interestsList"
                              v-model="searchInput3.interestsList"
                              :options="interests" />
                        </dd>
                      </dl>
                      <dl>
                        <dt>경로 구분</dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="matchingGbListRef3"
                              className="mr-md"
                              name="matchingGbList"
                              v-model="searchInput3.matchingGbList"
                              :options="GroupMatching3" />
                        </dd>
                      </dl>
                      <dl>
                        <dt>채용제안일</dt>
                        <dd class="text-box">
                          <div class="grid">
                            <template v-for="group in searchDurationTypeGroup" key="item.value" >
                              <KSARadioButton class="mr-md" name="searchDurationGb3" :label="group.label" v-model="searchInput3.searchDurationType" :value="group.value" />
                            </template>
                            <div style="display: inline-block;" v-show="searchInput3.searchDurationType !== 'all'">
                              <KSADatepickerTerms
                                  :startDate="searchInput3.startYmd"
                                  :endDate="searchInput3.endYmd"
                                  @update:startDate="(value)=> searchInput3.startYmd = value"
                                  @update:endDate="(value)=> searchInput3.endYmd = value"
                              />
                            </div>
                          </div>
                        </dd>
                      </dl>
                      <dl>
                        <dt>구직자명</dt>
                        <dd>
                          <KSAInput class="text-box" v-model="searchInput3.searchInput" placeholder="채용 제안을 한 구직자명을 입력해 주세요." />
                        </dd>
                      </dl>
                      <div class="btn-wrap">
                        <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                        <KSAPrimaryButton text="검색" @click="onClickSearch" />
                      </div>
                    </div>
                  </div>
                  <!-- e: 검색 -->
                  <div class="list-top">
                    <p>전체 <span>{{ $number(totalCount3_master)}}</span>건</p>
                    <div>
                      <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                  </div>

                  <div class="table-form">
                    <table class="horizontal" v-if="corpMatchingList !== null">
                      <tr>
                        <th>NO</th>
                        <th>채용 제안일</th>
                        <th>경로 구분</th>
                        <th>구직자명</th>
                        <th>관심 카테고리</th>
                        <th>이력서 보기</th>
                        <th>채용 제안서 보기</th>
                        <th>상태</th>
                        <th>상태 변경</th>
                      </tr>
                      <tr v-for="corpMatching in corpMatchingList">
                        <td>{{ corpMatching.boardNo }}</td>
                        <td>{{ $yyyymmddHHmmss(corpMatching.createDate) }}</td>
                        <td>
                          <span v-if="corpMatching.matchingGb === '01'">채용 제안</span>
                          <span v-else>인재 채용</span>
                        </td>
                        <td class="link text"><RouterLink :to="{name:'MemberDetail', params:{id: corpMatching.userId}}">{{ corpMatching.userName }}</RouterLink></td>
                        <td>{{ corpMatching.interests }}</td>
                        <td class="link text">
                          <span v-if="corpMatching.resumeId !== 0" @click="showMatchingResumeModal(corpMatching.resumeId)">이력서 보기</span>
                          <span v-else @click="showUserResumeModal(corpMatching.userId)">이력서 보기</span>
                        </td>
                        <td>
                          <span v-if="corpMatching.propId !== 0" @click="showProposalContentModal(corpMatching.propId)" class="link text">제안서 보기</span>
                          <span v-else>-</span>
                        </td>
                        <td>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB', corpMatching.stateGb) }}</td>
                        <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                        <td>
                            <span v-if="corpMatching.stateGb === '01'" data-title="상태 변경 : ">
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '02')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '02', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="gray_button md_btn"/>
                            </span>
                          <span v-else-if="corpMatching.stateGb === '03'" data-title="상태 변경 : ">
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '05', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="green_button md_btn"/>
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '06')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '06', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="orange_button md_btn"/>
                            </span>
                          <span v-else-if="corpMatching.stateGb === '05'" data-title="상태 변경 : ">
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '07', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="blue_button md_btn"/>
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '08', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="red_button md_btn"/>
                            </span>
                          <span v-else-if="corpMatching.stateGb === '06'" data-title="상태 변경 : ">
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '05', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="green_button md_btn"/>
                            </span>
                          <span v-else-if="corpMatching.stateGb === '07'" data-title="상태 변경 : ">
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '08', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="red_button md_btn"/>
                            </span>
                          <span v-else-if="corpMatching.stateGb === '08'" data-title="상태 변경 : ">
                              <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(corpMatching.matchingGb, corpMatching.userId, '07', corpMatching.propId, corpMatching.corpId, corpMatching.resumeId)" class="blue_button md_btn"/>
                            </span>
                          <span v-else data-title="상태 변경 : ">
                              -
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="result-none" v-if="corpMatchingList.length === 0">
                    진행중인 채용 건이 없습니다.
                  </div>
                  <Paginate
                      v-if="corpMatchingList.length > 0"
                      v-model="currentPage3_master"
                      :page-count="totalPages3_master"
                      :click-handler="onSelectPage"
                  />
                </div>

                <!-- e: 채용 진행 현황 - 기업 -->

                <!-- s : 매칭 내역 - 구직자 -->
                <!--                <loading
                                    :active="!loaded"
                                    loader="dots"
                                    color="#3843AF"
                                    opacity="0.5"
                                    width="35"
                                    :is-full-page="false"></loading>-->
                <div v-if="tabIndex === MATCH_USER_HIST && user.userGb === 'STUDENT'">
                  <!-- s: 전체 현황 -->
                  <div class="table-form">
                    <table class="horizontal all-border">
                      <tr class="tr-focus-top tr-focus-side">
                        <th rowspan="2">전체 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr class="tr-focus-side tr-focus-bottom">
                        <td>{{ resultUserCount.totalState }}</td>
                        <td>{{ resultUserCount.totalState01 }}</td>
                        <td>{{ resultUserCount.totalState02 }}</td>
                        <td>{{ resultUserCount.totalState03 }}</td>
                        <td>{{ resultUserCount.totalState04 }}</td>
                        <td>{{ resultUserCount.totalState05 }}</td>
                        <td>{{ resultUserCount.totalState06 }}</td>
                        <td>{{ resultUserCount.totalState07 }}</td>
                        <td>{{ resultUserCount.totalState08 }}</td>
                      </tr>
                      <tr>
                        <th rowspan="2">인재 채용 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr>
                        <td>{{ resultUserCount.totalResState }}</td>
                        <td>{{ resultUserCount.resState01 }}</td>
                        <td>{{ resultUserCount.resState02 }}</td>
                        <td>{{ resultUserCount.resState03 }}</td>
                        <td>{{ resultUserCount.resState04 }}</td>
                        <td>{{ resultUserCount.resState05 }}</td>
                        <td>{{ resultUserCount.resState06 }}</td>
                        <td>{{ resultUserCount.resState07 }}</td>
                        <td>{{ resultUserCount.resState08 }}</td>
                      </tr>
                      <tr>
                        <th rowspan="2">채용 제안 현황</th>
                        <th class="total">전체</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','01') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','02') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','03') }}</th>
                        <th class="th-blue">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','04') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','05') }}</th>
                        <th class="th-red">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','06') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','07') }}</th>
                        <th>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB','08') }}</th>
                      </tr>
                      <tr>
                        <td>{{ resultUserCount.totalPropState }}</td>
                        <td>{{ resultUserCount.propState01 }}</td>
                        <td>{{ resultUserCount.propState02 }}</td>
                        <td>{{ resultUserCount.propState03 }}</td>
                        <td>{{ resultUserCount.propState04 }}</td>
                        <td>{{ resultUserCount.propState05 }}</td>
                        <td>{{ resultUserCount.propState06 }}</td>
                        <td>{{ resultUserCount.propState07 }}</td>
                        <td>{{ resultUserCount.propState08 }}</td>
                      </tr>
                    </table>
                  </div>
                  <!-- e: 전체 현황 -->
                  <br>
                  <!-- s: 검색 -->
                  <div class="filter-wrap" :class="{'open': searchOpen4}">
                    <p @click="searchOpen4 = !searchOpen4">검색</p>
                    <div class="filter">
                      <dl>
                        <dt style="display:block;"><span>상태</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="stateGbsListRef4"
                              className="mr-md"
                              name="stateGbsList"
                              v-model="searchInput4.stateGbsList"
                              :options="corpState" />
                        </dd>
                      </dl>
                      <dl>
                        <dt style="display:block;"><span>관심 카테고리</span><br/><small style="font-weight: normal;">복수 선택 가능</small></dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="interestsListRef4"
                              className="mr-md"
                              name="interestsList"
                              v-model="searchInput4.interestsList"
                              :options="interests" />
                        </dd>
                      </dl>
                      <dl>
                        <dt>경로 구분</dt>
                        <dd>
                          <KSACheckBoxGroup
                              ref="matchingGbListRef4"
                              className="mr-md"
                              name="matchingGbList"
                              v-model="searchInput4.matchingGbList"
                              :options="GroupMatching4" />
                        </dd>
                      </dl>
                      <dl>
                        <dt>채용제안일</dt>
                        <dd class="text-box">
                          <div class="grid">
                            <template v-for="group in searchDurationTypeGroup" key="item.value" >
                              <KSARadioButton class="mr-md" name="searchDurationGb3" :label="group.label" v-model="searchInput4.searchDurationType" :value="group.value" />
                            </template>
                            <div style="display: inline-block;" v-show="searchInput4.searchDurationType !== 'all'">
                              <KSADatepickerTerms
                                  :startDate="searchInput4.startYmd"
                                  :endDate="searchInput4.endYmd"
                                  @update:startDate="(value)=> searchInput4.startYmd = value"
                                  @update:endDate="(value)=> searchInput4.endYmd = value"
                              />
                            </div>
                          </div>
                        </dd>
                      </dl>
                      <dl>
                        <dt>기업명</dt>
                        <dd>
                          <KSAInput class="text-box" v-model="searchInput4.searchInput" placeholder="기업명을 입력해 주세요." />
                        </dd>
                      </dl>
                      <div class="btn-wrap">
                        <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                        <KSAPrimaryButton text="검색" @click="onClickSearch" />
                      </div>
                    </div>
                  </div>
                  <!-- e: 검색 -->
                  <div class="list-top">
                    <p>전체 <span>{{ $number(totalCount3_user)}}</span>건</p>
                    <div>
                      <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                  </div>

                  <div class="table-form">
                    <table class="horizontal" v-if="userMatchingList !== null">
                      <tr>
                        <th>NO</th>
                        <th>채용 제안일</th>
                        <th>경로 구분</th>
                        <th>기업명</th>
                        <th>제안 마스터명</th>
                        <th>관심 카테고리</th>
                        <th>이력서 보기</th>
                        <th>채용 제안서 보기</th>
                        <th>상태</th>
                        <th>상태 변경</th>
                      </tr>
                      <tr v-for="userMatching in userMatchingList">
                        <td>{{ userMatching.boardNo }}</td>
                        <td>{{ $yyyymmddHHmmss(userMatching.createDate) }}</td>
                        <td>
                          <span v-if="userMatching.matchingGb === '01'">받은 채용</span>
                          <span v-else style="display:block;"><span>입사 지원</span><br/><span>(인재 채용)</span></span>
                        </td>
                        <td class="link text"><RouterLink :to="{name:'MatchingCorpDetail', params:{id: userMatching.corpId}}">{{ userMatching.corpName }}</RouterLink></td>
                        <td class="link text"><RouterLink :to="{name:'MemberDetail', params:{id: userMatching.corpUserId}}">{{ userMatching.corpUserName }}</RouterLink></td>
                        <td>{{ userMatching.interests }}</td>
                        <td class="link text">
                          <span v-if="userMatching.resumeId !== 0" @click="showMatchingResumeModal(userMatching.resumeId)">이력서 보기</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="userMatching.propId !== 0" @click="showProposalContentModal(userMatching.propId)" class="link text">제안서 보기</span>
                          <span v-else>-</span>
                        </td>
                        <td>{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB', userMatching.stateGb) }}</td>
                        <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                        <td>
                          <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                          <span v-if="userMatching.stateGb === '01'" data-title="제안 수락/거절 : ">
                            <KSASecondaryButton text="제안수락" @click="changeUserState(userMatching.matchingGb, userMatching.userId, '03', userMatching.propId, userMatching.corpId, userMatching.resumeId)" class="blue_button md_btn"/>
                            <KSASecondaryButton text="제안거절" @click="changeUserState(userMatching.matchingGb, userMatching.userId, '04', userMatching.propId, userMatching.corpId, userMatching.resumeId)" class="red_button md_btn"/>
                          </span>
                          <span v-else-if="userMatching.stateGb === '03'" data-title="제안 수락/거절 : ">
                            <KSASecondaryButton text="제안거절" @click="changeUserState(userMatching.matchingGb, userMatching.userId, '04', userMatching.propId, userMatching.corpId, userMatching.resumeId)" class="red_button md_btn"/>
                          </span>
                          <span v-else-if="userMatching.stateGb === '04'" data-title="제안 수락/거절 : ">
                            <KSASecondaryButton text="제안수락" @click="changeUserState(userMatching.matchingGb, userMatching.userId, '03', userMatching.propId, userMatching.corpId, userMatching.resumeId)" class="blue_button md_btn"/>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="result-none" v-if="userMatchingList.length === 0">
                    진행중인 채용 건이 없습니다.
                  </div>
                  <Paginate
                      v-if="userMatchingList.length > 0"
                      v-model="currentPage3_user"
                      :page-count="totalPages3_user"
                      :click-handler="onSelectPage"
                  />
                </div>
                <!-- e: 매칭 내역 - 구직자 -->

                <!-- s: 강의 개설 내역 -->
                <div v-if="tabIndex === LECT_CREATE_HIST && user.userGb === 'STUDENT'">
                    <div class="table-form">
                        <table class="horizontal" v-if="lectureCreateHistories !== null">
                            <tr>
                                <th>구분</th>
                                <th>강의명</th>
                                <th>차시</th>
                                <th>신청인원</th>
                                <th>선발인원</th>
                                <th>신청기간</th>
                                <th>교육기간</th>
                            </tr>
                            <tr v-for="lectCreateHist in lectureCreateHistories">
                                <td>{{ lectCreateHist.lectGb === '01' ? '온라인' : '오프라인' }}</td>
                                <td class="link text-left"><RouterLink :to="{name:'LectureDetail', params:{id: lectCreateHist.lectId}}">{{ lectCreateHist.lectName }}</RouterLink></td>
                                <td>{{ lectCreateHist.lectureIndexCount }}</td>
                                <td>{{ lectCreateHist.userLectureCount }}</td>
                                <td>{{ lectCreateHist.userLectureSelCount }}</td>
                                <td>
                                    <span v-if="lectCreateHist.regTermGb === '01'">상시</span>
                                    <span v-else>{{ $yyyymmdd(lectCreateHist.regStartYmd) }} ~ {{ $yyyymmdd(lectCreateHist.regEndYmd) }}</span>
                                </td>
                                <td>
                                    <span v-if="lectCreateHist.lectTermGb === '01'">신청일로부터 {{ lectCreateHist.lectTerm }}일</span>
                                    <span v-else>{{ $yyyymmdd(lectCreateHist.lectStartYmd) }} ~ {{ $yyyymmdd(lectCreateHist.lectEndYmd) }}</span>
                                </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="lectureCreateHistories === null || lectureCreateHistories.length === 0">
                            개설한 강의가 없습니다.
                        </div>
                    </div>
                </div>
                <!-- e: 강의 개설 내역 -->

                <!-- s: 회원 유형 변경 내역 -->
                <div v-if="tabIndex === USER_GB_HIST">
                  <div class="list-top">
                    <p>신청 <span>{{ $number(totalCount4)}}</span>건</p>
                    <div>
                      <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                    </div>
                  </div>

                  <div class="table-form">
                    <table class="horizontal" v-if="userGbHistoryList !== null">
                      <tr>
                        <th>NO</th>
                        <th>신청일</th>
                        <th>현재 회원 유형</th>
                        <th>추가/변경 회원 유형</th>
                        <th>상태</th>
                        <th>상태 변경</th>
                      </tr>
                      <tr v-for="userGbHistory in userGbHistoryList">
                        <td>{{ userGbHistory.boardNo }}</td>
                        <td>{{ $yyyymmddHHmmss(userGbHistory.createDate) }}</td>
                        <td>{{ $userGbKR(userGbHistory.beforeUserGb) }}</td>
                        <td>{{ $userGbKR(userGbHistory.afterUserGb) }}</td>
                        <td>{{ $gbNameKR('USER_GB_STATE_GB', userGbHistory.stateGb) }}</td><!-- 01 신청 02 취소 03 승인 04 거절 -->
                        <td>
                            <span v-if="userGbHistory.stateGb === '01'" data-title="신청 : ">
                                <KSATextButton text="[신청 취소]" @click="changeUserGbHistoryState(userGbHistory.seq, '02')"/>
                            </span>
                            <span v-else>
                              -
                            </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="result-none" v-if="userGbHistoryList.length === 0">
                    신청건이 없습니다.
                  </div>
                  <Paginate
                      v-if="userMatchingList.length > 0"
                      v-model="currentPage4"
                      :page-count="totalPages4"
                      :click-handler="onSelectPage"
                  />
                </div>
                <!-- e: 회원 유형 변경 내역 -->
              </div>
        </div>
    </div>
    <MypageProposalContentModal ref="proposalContentModal"/>
    <MyPageResumeModal ref="userMatchingResumeModal"/>
    <AlertModal ref="modal" :content="modalContent"  />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" />
    <ResumeModal ref="resumeModal"/>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    h1 {
        @include font-size_lg;
        margin: 60px 0 30px;
    }
</style>