<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>함께하는 분들</h3>
            </div>
            <h4>소자업체</h4>
            <div class="company-wrap">
                <div><a href="https://semiconductor.samsung.com/kr/" target="_blank"><img src="/src/assets/images/ci/samsung.png" alt="삼성전자" /></a></div>
                <div><a href="https://www.skhynix.com/" target="_blank"><img src="/src/assets/images/ci/sk_hynix.png" alt="하이닉스" /></a></div>
            </div>
            <h4>테스트/패키징업체</h4>
            <div class="company-wrap">
                <div><a href="http://www.snpt.co.kr/" target="_blank"><img src="/src/assets/images/ci/snpt.png" alt="SNPT" /></a></div>
                <div><a href="https://www.yikcorp.com/kr/index.php" target="_blank"><img src="/src/assets/images/ci/yikc.png" alt="와이아이케이" /></a></div>
            </div>
            <h4>설계업체</h4>
            <div class="company-wrap">
                <div><a href="http://gaonchips.com/" target="_blank"><img src="/src/assets/images/ci/gaonchips.png" alt="가온칩스" /></a></div>
                <div><a href="https://www.adtek.co.kr/" target="_blank"><img src="/src/assets/images/ci/adtechnology.png" alt="에이디테크놀로지" /></a></div>
                <div><a href="https://www.coasiasemi.com/" target="_blank"><img src="/src/assets/images/ci/coasiasemi.png" alt="코아시아세미코리아" /></a></div>
                <div><a href="https://www.asicland.com/intro.php" target="_blank"><img src="/src/assets/images/ci/asicland.png" alt="에이직랜드" /></a></div>
                <div><a href="http://www.newlinktek.com/" target="_blank"><img src="/src/assets/images/ci/newlinktek.png" alt="뉴링크테크놀로지" /></a></div>
                <div><a href="http://www.ednc.com/" target="_blank"><img src="/src/assets/images/ci/ednc.png" alt="이디앤씨" /></a></div>
                <div><a href="https://www.enabledesign.io/" target="_blank"><img src="/src/assets/images/ci/enable.png" alt="인에이블디자인" /></a></div>
                <div><a href="http://www.kornic.co.kr/kr/" target="_blank"><img src="/src/assets/images/ci/kornicautomation.png" alt="코닉오토메이션" /></a></div>
            </div>
            <h4>장비업체</h4>
            <div class="company-wrap">
                <div><a href="https://www.ips.co.kr/ko/" target="_blank"><img src="/src/assets/images/ci/wonikips.png" alt="원익IPS" /></a></div>
                <div><a href="https://www.exicon.co.kr/kr/index.php" target="_blank"><img src="/src/assets/images/ci/exicon.png" alt="엑시콘" /></a></div>
                <div><a href="https://www.surplusglobal.com/SG/About/SuccessStory/ko/" target="_blank"><img src="/src/assets/images/ci/surplusglobal.png" alt="서플러스글로벌" /></a></div>
            </div>
            <h4>외국업체</h4>
            <div class="company-wrap">
                <div><a href="https://www.microsoft.com/ko-kr" target="_blank"><img src="/src/assets/images/ci/microsoft.png" alt="마이크로소프트" /></a></div>
                <div><a href="https://www.infineon.com/cms/korea/kr/" target="_blank"><img src="/src/assets/images/ci/infineon.png" alt="인피니언테크놀로지스코리아" /></a></div>
            </div>
            <h4>대학교</h4>
            <div class="company-wrap">
                <div><a href="https://www.kopo.ac.kr/semi/index.do" target="_blank"><img src="/src/assets/images/ci/kopo.png" alt="한국폴리텍대학교" /></a></div>
                <div><a href="https://ssu.ac.kr/" target="_blank"><img src="/src/assets/images/ci/ssu.png" alt="숭실대학교" /></a></div>
                <div><a href="https://www.cau.ac.kr/index.do" target="_blank"><img src="/src/assets/images/ci/cau.png" alt="중앙대학교" /></a></div>
                <div><a href="https://www.skku.edu/skku/index.do" target="_blank"><img src="/src/assets/images/ci/skku.png" alt="성균관대학교" /></a></div>

            </div>
            <h4>정부/전문기관</h4>
            <div class="company-wrap">
                <div><a href="http://www.motie.go.kr/www/main.do" target="_blank"><img src="/src/assets/images/ci/motie.png" alt="산업통상자원부" /></a></div>
                <div><a href="https://www.kiat.or.kr/front/user/main.do" target="_blank"><img src="/src/assets/images/ci/kiat.png" alt="KIAT" /></a></div>
            </div>
            <h4>기타</h4>
            <div class="company-wrap">
                <div><img src="/src/assets/images/ci/scop.png" alt="반도체기술인협동조합" /></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    h4 {
        @include font-size_xl;
        margin: 20px 0 10px;
        &:first-of-type {
            margin-top: 0;
        }
    }

</style>
<script setup>
</script>