<script setup>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, onMounted, defineExpose, getCurrentInstance } from "vue";
import {usePageLoadingStore} from "../../../../../stores/pageLoading";
import KSAPrimaryButton from "../../../../../components/ui-components/button/KSAPrimaryButton.vue";
import surveyService from "../../../services/lecture/surveyService";

const loadingStore = usePageLoadingStore()
const { $toast, $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

const baseModal = ref(null);
const resolvePromise = ref(null);

const totalCount = ref(0);
const totalPages = ref(0);
const currentPage = ref(1); // 설문결과 현재 페이지
const pageSize = ref(30);   // 설문결과 페이지별 크기

onMounted(()=>{
    setTimeout(()=>{
        baseModal.value.isAdminWrap = true;
    }, 500);
})

const userSurveyList = ref(null);            // 만족도 평가 기본정보
const surveyPaperQuestionList = ref([]); // 만족도 평가 평가문항
const userSurveyAnswerList = ref([]);    // 기작성한 만족도 평가 답안
const surveyAnswer = ref({ // 임시저장용 만족도 답변
    userId: 0,       // 사용자아이디
    lectId: 0,       // 강의아이디
    regDt: "",       // 신청일시
    surveyId: 0,     // 만족도평가아이디
    surveyQId: 0,    // 만족도문항아이디
    surveyQType: "", // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
    answer: "",      // 작성답안 - 7점,5점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
    answerEtc: ""   // 기타작성답안(다중응답형)
});
const input = ref({    // 제출용 만족도 답변
    userId: 0,     // 사용자아이디
    lectId: 0,     // 강의아이디
    regDt: "",     // 신청일시
    surveyId: 0,   // 만족도평가아이디
    answerList: [  // 답변리스트
        /* 예시
        {
            surveyQId: 1,    // 만족도문항아이디
            surveyQType: "01", // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
            answer: "3",      // 작성답안 - 7점,5점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
            answerEtc: "4"   // 기타작성답안(다중응답형)
        }
        */
    ],
})

const pageSizeGroup = ref([
    { label:"10개씩 보기", value: "10" },
    { label:"30개씩 보기", value: "30" },
    { label:"50개씩 보기", value: "50" },
])

const questionGroup = ref([
    { label:"교육 전후 역량 변화", value: "01" },
    { label:"교육 만족도", value: "02" },
    { label:"현업 활용도", value: "03" },
    { label:"수요 조사", value: "04" },
    { label:"교육과정 안내 경로", value: "05" },
])


const retrieveSurveyPaperQuestionDetail = (id) => {
    loadingStore.loading = true;
    surveyService.retrievePaperQuestion(id)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                surveyPaperQuestionList.value = result;

                if ( surveyPaperQuestionList.value.length > 0 ) {
                    for( let i = 0; i < surveyPaperQuestionList.value.length; i++ ) {
                        let multiAnswerList= [];
                        //console.log("surveyPaperQuestionList.value[i].choiceList[0].surveyChoiceContent : " + surveyPaperQuestionList.value[i].choiceList[0].surveyChoiceContent);
                        input.value.answerList.push({
                            surveyQId: surveyPaperQuestionList.value[i].surveyQId,
                            surveyQType: surveyPaperQuestionList.value[i].surveyQType, // 만족도문항유형(140) - 01: 7점 척도, 02: 주관식, 03: 다중응답형
                            answer: "",      // 작성답안 - 7점,5점 척도는 숫자, 주관식은 문장, 다중응답형은 선택한 모든 보기("1,5,6" 형태)
                            checkEtc: false,   // 기타작성답안 체크여부
                            answerEtc: "",   // 기타작성답안(다중응답형)
                            multiAnswerList: multiAnswerList, // 다중응답형 작성답안리스트
                        });
                    }
                }
                retrieveUserSurvey(id);       // 만족도 평가 기본정보 조회
                retrieveUserSurveyAnswer(); // 작성한 내용 조회
            }
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

// 작성한 내용 조회
const retrieveUserSurveyAnswer = () => {
    loadingStore.loading = true;
    surveyService.retrieveUserSurveyAnswerByUserLecture(input.value.userId, input.value.lectId, input.value.regDt)
        .then((res) => {
            loadingStore.loading = false;
            if (res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                userSurveyAnswerList.value = result;

                if (userSurveyAnswerList.value.length > 0) {
                    for (let i = 0; i < userSurveyAnswerList.value.length; i++) {
                        for (let j = 0; j < input.value.answerList.length; j++) {
                            //console.log("input.value.answerList["+j+"].surveyQType : " + input.value.answerList[j].surveyQType);
                            if (input.value.answerList[j].surveyQId === userSurveyAnswerList.value[i].surveyQId) {
                                if (input.value.answerList[j].surveyQType === '01') {
                                    input.value.answerList[j].answer = userSurveyAnswerList.value[i].answer1;
                                } else if (input.value.answerList[j].surveyQType === '04') {
                                    input.value.answerList[j].answer = userSurveyAnswerList.value[i].answer1;
                                } else if (input.value.answerList[j].surveyQType === '02') {
                                    input.value.answerList[j].answer = userSurveyAnswerList.value[i].answer2;
                                } else if (input.value.answerList[j].surveyQType === '03') {
                                    input.value.answerList[j].multiAnswerList = userSurveyAnswerList.value[i].answer3.split(',');
                                    //console.log("input.value.answerList["+j+"].multiAnswerList : " + input.value.answerList[j].multiAnswerList)
                                    if (userSurveyAnswerList.value[i].answerEtc !== null && userSurveyAnswerList.value[i].answerEtc !== '') {
                                        input.value.answerList[j].checkEtc = true;
                                        input.value.answerList[j].answerEtc = userSurveyAnswerList.value[i].answerEtc;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }).catch((error) => {
        loadingStore.loading = false;
        console.log(error);
    });
}

//만족도 평가 기본정보 조회
const retrieveUserSurvey = (surveyId) => {
    let paramObj = {
        surveyId: surveyId,
        pageNo: 0,
        size: 30,
        searchType: "",
        searchInput: "",
        userId: input.value.userId,
        lectId: input.value.lectId,
        regDt: input.value.regDt,
    }

    loadingStore.loading = true;
    surveyService.retrieveUserSubmitAllPaging(paramObj)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                totalPages.value = result.totalPages;
                totalCount.value = result.totalElements;
                userSurveyList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
            }
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

const onClickLectId = (lectId) => {
    window.open(`/admin/lecture/manage/detail/${lectId}`, '_blank');
}

const show = () => {
    baseModal.value.open();
    return new Promise((resolve, _) => {
        resolvePromise.value = resolve;
    });
};

const confirm = () => {

};

const cancel = () => {
    baseModal.value.close();
    resolvePromise.value(false);
};

const init = (userId, lectId, regDt, surveyId) => {
    // 기본값 저장
    input.value = {
        userId: userId,
        lectId: lectId,
        regDt: regDt,
        surveyId: surveyId,
        answerList: [],
    }
    surveyAnswer.value = {
        userId: userId,
        lectId: lectId,
        regDt: regDt,
        surveyId: surveyId,
    }

    // 만족도 평가 문항 조회
    retrieveSurveyPaperQuestionDetail(surveyId);
}

defineExpose({
    show,
    init,
})

</script>
<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size request-modal">
            <div class="modal-title">
                <h3>만족도 평가</h3>
            </div>
            <div class="modal-body">
                <h4>참여자 정보</h4>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>강의명</th>
                            <th>참여자</th>
                            <th>참여자 아이디</th>
                            <th>설문 결과</th>
                            <th>설문일</th>
                        </tr>
                        <tr v-for="userSurvey in userSurveyList">
                            <td><KSATextButton :text="userSurvey.lectName" @click="onClickLectId(userSurvey.lectId)" /></td>
                            <td>{{ userSurvey.userName }}</td>
                            <td>{{ userSurvey.userLoginId }}</td>
                            <td>{{ $number(userSurvey.result) }}점</td>
                            <td>{{ $yyyymmddHHmm(userSurvey.surveyDt) }}</td>
                        </tr>
                    </table>
                </div>
                <h4>만족도 평가 결과</h4>
                <div class="question-wrap disabled" v-for="(surveyPaperQuestion, index) in surveyPaperQuestionList">
                    <div class="item question-number" v-if="surveyPaperQuestion.surveyQType === '01'">
                        <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                        <ul>
                            <li :class="{'answer': input.answerList[index].answer === 1}" >
                                <i>1</i><span>매우 낮음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 2}" >
                                <i>2</i><span>낮음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 3}" >
                                <i>3</i><span>다소 낮음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 4}" >
                                <i>4</i><span>보통</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 5}" >
                                <i>5</i><span>다소 높음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 6}" >
                                <i>6</i><span>높음</span>
                            </li>
                            <li :class="{'answer': input.answerList[index].answer === 7}" >
                                <i>7</i><span>매우 높음</span>
                            </li>
                        </ul>
                    </div>
                    <div class="item question-number" v-if="surveyPaperQuestion.surveyQType === '04'">
                      <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                      <ul v-for="(point, index2) in surveyPaperQuestion.pointOptions">
                        <li :class="{'answer': input.answerList[index].answer === index2+1}" >
                          <i>{{ index2+1 }}</i><span>{{ point.surveyChoiceContent }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="item question-text" v-if="surveyPaperQuestion.surveyQType === '02'">
                        <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                        <KSATextArea style="height: 100px" class="textarea-box" v-model="input.answerList[index].answer" placeholder="답변을 입력하세요." />
                    </div>
                    <div class="item question-checkbox" v-if="surveyPaperQuestion.surveyQType === '03'">
                        <p><b>질문 {{ index+1 }}.</b> {{ surveyPaperQuestion.surveyQTitle }}</p>
                        <KSACheckBox
                            class="ml-xs"
                            v-for="choice in surveyPaperQuestion.choiceList"
                            v-model="input.answerList[index].multiAnswerList"
                            :name="`checkedAnswers${index}`"
                            :label="choice.surveyChoiceContent"
                            :value="choice.surveyChoiceId"
                        />
                        <div class="etc" v-if="surveyPaperQuestion.etcYn === 'Y'">
                            <KSACheckBox class="ml-xs" label="기타" v-model="input.answerList[index].checkEtc" />
                            <KSAInput v-model="input.answerList[index].answerEtc" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <KSAPrimaryButton text="닫기" @click="cancel" />
            </div>
        </div>
    </Modal>
</template>
<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

</style>