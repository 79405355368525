<script setup>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useSignupStore } from '../../stores/signup'
    import { useUserStore } from "../../stores/user";
    import SignupProgressBar from '../../../../components/signup/SignupProgressBar.vue'

    const router = useRouter()
    const userStore = useUserStore()
    const userName = ref('');
    const userCd = ref('');

    onMounted(() => {
        const { signupRequest, setSignupRequest } = useSignupStore();
        userName.value = signupRequest.userName;
        if(!userName.value) {
            router.push({name: 'Home'})
        }
        setSignupRequest({});

        const { user } = userStore;
        if(user.userCd) {
            userCd.value = user.userCd;
        }
    })
</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <SignupProgressBar :activeIndex="4"/>
            <div class="join-complete">
                <img src="../../../../assets/images/icon-complete.png" />
                <h4>{{ userName }}님! 환영합니다.</h4>
                <p>
                    한국반도체아카테미의 회원이 되신 것을 환영합니다.<br>
                    강의를 수강하거나 구인/구직 활동을 해보세요.
                </p>
                <p>
                    *회원님의 회원코드는 {{ userCd }}입니다.<br>
                    회원코드는 &lt;마이페이지&gt;에서 확인할 수 있습니다.
                </p>
            </div>
            <KSAPrimaryButton class="full-btn" text="홈으로 이동" @click="router.push({name: 'Home'})" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .join-complete {
        text-align: center;
        h4 {
            @include font-size_xl;
            margin: 40px 0;
        }
        p {
            margin-top: 20px;
            &:last-child {
                color: $blue_color;
            }
        }
    }
</style>