<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, onMounted, defineProps, defineExpose, getCurrentInstance } from "vue";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import mailMsgServcie from "../../../services/mail/mailMsgServcie";
    import _ from 'lodash'

    const loadingStore = usePageLoadingStore()
    const {$toast} = getCurrentInstance().appContext.config.globalProperties;

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const msgGb = "02";
    const input = ref({
        targetText: '',
        content: '',
    })

    const inputError = ref({
        content: '',
    })

    const users = ref([])

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);
    })

    watch(users, (newValue)=>{
        if(newValue !== undefined && newValue !== null) {
            if(newValue.length > 0 ) {
                if(newValue.length === 1 ) {
                    input.value.targetText = newValue[0].userName;
                } else {
                    input.value.targetText = `${newValue[0].userName} 외 ${newValue.length-1}명`;
                }
            }
        }
    }, { deep: true})

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        const { content } = input.value;
        let isInvalid = false;

        inputError.value = {
            content: '',
        };

        if(!content) {
            inputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                msgGb,
                userSelGb: 'sel',
                userIds: _.map(users.value, 'userId'),
                content: content || '내용 없음',
            }
            baseModal.value.close();
            resolvePromise.value(paramObj);
        }
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const init = (_users) => {
        users.value = _users;
        input.value = {
            targetText: '',
            content: '',
        }
        inputError.value = {
            content: '',
        }
    }

    defineExpose({
        show,
        init,
    })

</script>
<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>메시지 쓰기</h3>
            </div>
            <div class="modal-body">
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="150px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>받는 사람</th>
                            <td>
                                <KSAInput v-model="input.targetText" disabled />
                            </td>
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td>
                                <KSATextArea class="textarea-box" v-model="input.content" placeholder="내용을 입력하세요." max-length="2000"/>
                                <p>
                                    <small style="color:#E71414;">{{ inputError.content }}</small>
                                </p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" text="보내기" @click="confirm" />
            </div>
        </div>
    </Modal>
</template>
<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .textarea-box {
        height: 260px;
    }
</style>
