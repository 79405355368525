import http from '../../../../api/http';

function loginIdCheck(loginId) {
    return http.get(`/signup/loginId/search?loginId=${loginId}`)
}

function phoneVerify({verifyGubun, name, phoneNum}) {
    return http.post('/signup/phone/verify', {
        verifyGubun: verifyGubun || '', // 인증구분 signup, findid, changepw, changenum
        name,
        phoneNum
    })
}

function phoneVerifyCheck({ verifyGubun, phoneVerifyId, phoneNum, verifyNum}) {
    return http.post('/signup/phone/verifycheck', {
        verifyGubun: verifyGubun || '', // 인증구분 signup, findid, changepw, changenum
        phoneVerifyId,
        phoneNum,
        verifyNum
    })
}

function signup(paramObj) {
    return http.post('/signup/user', JSON.stringify(paramObj))
}


function searchSchool(searchInput) {
    return http.get(`signup/school/search?searchInput=${searchInput}`)

}

function searchCorp(searchInput) {
    return http.get(`signup/corp/search?searchInput=${searchInput}`)
}

export default {
    loginIdCheck, // 아이디 중복확인
    phoneVerify,
    phoneVerifyCheck,
    signup,
    searchSchool,
    searchCorp,
};