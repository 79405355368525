// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useSchedulerDetailStore = defineStore('schedulerDetailStore', () => {
    const trigger = ref({})

    return {
        trigger,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSchedulerDetailStore, import.meta.hot))
}
