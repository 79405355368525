<script setup>
    import { ref } from 'vue'

    import KSAImage from '../../../../components/ui-components/KSAImage.vue'
    import KSASelect from '../../../../components/ui-components/select/KSASelect.vue'
    import KSAInput from '../../../../components/ui-components/input/KSAInput.vue'
    import KSATextArea from '../../../../components/ui-components/KSATextArea.vue'
    import KSATab from '../../../../components/ui-components/KSATab.vue'
    import paginate from '../../../../components/ui-components/pagination/Paginate.vue'
    import KSACheckBox from '../../../../components/ui-components/KSACheckBox.vue'
    import KSARadioButton from '../../../../components/ui-components/KSARadioButton.vue'
    import KSASearchInputBox from '../../../../components/ui-components/KSASearchInputBox.vue'
    import KSADatepicker from '../../../../components/ui-components/KSADatepicker.vue'
    import KSATextButton from '../../../../components/ui-components/button/KSATextButton.vue'
    import KSAPrimaryButton from '../../../../components/ui-components/button/KSAPrimaryButton.vue'
    import KSASecondaryButton from '../../../../components/ui-components/button/KSASecondaryButton.vue'

    const userId = ref(''); // KSAInput - 입력창(ID)
    const userPw = ref(''); // KSAInput - 입력창(Password)
    const textAreaValue = ref(''); // KSATextArea - textarea 1
    const textAreaValue2 = ref(''); // KSATextArea - textarea 2

    const selectedOption = ref('Usopp') // KSASelect - select
    const selectedTabIndex = ref(0) // KSATab - Tab
    const pageNo = ref(1); // paginate - 페이지네이션

    // s: KSACheckBox - checkbox 2개 이상일때
    const people = ref([
        {
            name:"William",
            age: 30,
            sex: "Male"
        },
        {
            name:"Thomas",
            age: 24,
            sex: "Male"
        },
        {
            name:"Jennifer",
            age: 20,
            sex: "Female"
        }
    ]);
    const selectedPeople = ref([]);
    // e: KSACheckBox - checkbox 2개 이상일때


    const isSelected= ref(true); // KSACheckBox - checkbox 1개 일때

    // s: KSARadioButton - radio
    const sex = ref([
        {
            name:"남성",
            value: "male"
        },
        {
            name:"여성",
            value: "female"
        },
    ]);
    const selectedSex = ref('male');
    // e: KSARadioButton - radio

    const showModal = ref(false)  // KSAModal - modal
    const showConfirmModal = ref(false)  // KSAConfirmModal - confirm modal

    const onOptionSelected = (option) => {
        console.log("EMITTED EVENT", option);
        selectedOption.value = option;
    }

    const onChangeTab = (tabIndex) => {
        console.log(`tabIndex is ${tabIndex}`);
        selectedTabIndex.value = tabIndex;
    }

    const onSelectedPage = (pageNumber) => {
        console.log(`pageNo is ${pageNumber}`);
        pageNo.value = pageNumber;
    }

    const onSearchInput = (text) => {
        console.log("EMITTED EVENT", text);
    }

    const onChangeDatepicker = (value) => {
        console.log("EMITTED EVENT", value);
    }

    const onButtonClick = () => {
        console.log('click!');
    }
</script>

<template>
    <h3>Image</h3>
    <div style="width: 100px; height: 100px;">
        <KSAImage src="https://github.com/Moonkil/danbeedemo2/blob/master/bob_welcome.png?raw=true"/>
    </div>
    <div style="width: 100px; height: 100px;">
        <KSAImage src="https://github.com/Moonkil/danbeedemo2/blob/master/bob_welcome.png?raw=true" :circle="true"/>
    </div>
    <hr/>

    <h3>Drop - Down Box</h3>
    <KSASelect :options="['Raphael', 'Mike', 'Donny', 'Tommy', 'Ella', 'Blue', 'Usopp']"
               :selected="selectedOption"
               class="select"
               @selectOption="onOptionSelected"/>

    선택한 값: {{ selectedOption }}
    <hr/>

    <h3>Input Box - Default</h3>
    <KSAInput v-model="userId" label="ID" placeholder="ID 입력하세요." msg="사용할 수 없는 ID입니다." msg-color="blue"/>
    <KSAInput type="password" v-model="userPw" label="PW" placeholder="PW 입력하세요." msg="사용할 수 없는 PW입니다."/>

    입력결과 id: {{ userId }} / pw: {{ userPw }}
    <hr/>


    <h3>Text Area</h3>
    <KSATextArea v-model="textAreaValue" label="Label 있음" placeholder="Nickname을 입력하세요." max-length="1000" />
    <KSATextArea v-model="textAreaValue2" placeholder="Nickname을 입력하세요." max-length="50"/>
    <hr/>

    <h3>Tab</h3>
    <KSATab :options="['Tab 1', 'Tab 2', 'Tab 3']" @change="onChangeTab"/>
    <br/>선택한 탭 인덱스: {{ selectedTabIndex }}
    <hr/>

    <h3>Dot Indicator</h3>
    <hr/>

    <h3>Pagination</h3>
    <paginate
        id="unit"
        :page-count="30"
        :page-range="10"
        :margin-pages="0"
        :initial-page="2"
        :prev-class="'ignore'"
        :next-class="'ignore'"
        :disabled-class="'ignore'"
        :click-handler="onSelectedPage"
    ></paginate>
    <br/>PageNo : {{ pageNo }}
    <hr/>

    <h3>Check Box</h3>
    <p>  1) Using the custom checkbox with an array</p>
    <p>{{ selectedPeople }}</p>
    <template v-for="person in people" key="person.name" >
        <KSACheckBox :label="person.name" v-model="selectedPeople" :value="person" />
    </template>
    <br/>
    <br>
    <p>  2) Using the custom checkbox as stand alone </p>
    <KSACheckBox  :label="`I am checked: ${isSelected}`" v-model="isSelected"  />
    <hr/>

    <h3>Radio Button</h3>
    <p>{{ selectedSex }}</p>
    <template v-for="item in sex" key="item.value" >
        <KSARadioButton name="sex" :label="item.name" v-model="selectedSex" :value="item.value" />
    </template>
    <hr/>

    <h3>Search - Input Box</h3>
    <KSASearchInputBox
        placeholder="검색어를 입력하세요."
        @search="onSearchInput"
    />
    <hr/>

    <h3>Datepicker</h3>
    <KSADatepicker @change="onChangeDatepicker"/>
    <hr/>

    <h3>Alert / Confirm</h3>
    <button id="show-modal" @click="showModal = true">Show Modal</button>
    <button id="show-confirm-modal" @click="showConfirmModal = true">Show Confirm Modal</button>
    <hr/>

    <Teleport to="body">
        <!-- use the modal component, pass in the prop -->
        <KSAConfirmModal :show="showConfirmModal" @close="showConfirmModal = false">
            <template #header>
                <h3>custom header</h3>
            </template>
        </KSAConfirmModal>
    </Teleport>

    <h3>Text Button</h3>
    <KSATextButton
        text="Button"
        @click="onButtonClick"
    />
    <KSATextButton
        text="Button"
        :disabled="true"
        @click="onButtonClick"
    />
    <hr/>

    <h3>Primary Button</h3>
    <KSAPrimaryButton
        text="Button"
        @click="onButtonClick"
    />
    <KSAPrimaryButton
        text="Button"
        color="red"
        @click="onButtonClick"
    />
    <KSAPrimaryButton
        text="Button"
        :disabled="true"
        @click="onButtonClick"
    />
    <hr/>

    <h3>Secondary Button</h3>
    <KSASecondaryButton
        text="Button"
        @click="onButtonClick"
    />
    <KSASecondaryButton
        text="Button"
        color="red"
        @click="onButtonClick"
    />
    <KSASecondaryButton
        text="Button"
        :disabled="true"
        @click="onButtonClick"
    />
    <hr/>


</template>


<style scoped>

</style>