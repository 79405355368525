<script setup>
    import { ref, computed, onMounted, getCurrentInstance, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import _ from 'lodash'
    import moment from 'moment'
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import { useUserStore } from "../../stores/user";
    import mypageRecordService from '../../services/mypage/mypageRecordService'
    import fileService from "../../../../services/fileService";
    import ResumeModal from "../../components/modal/mypage/MyPageResumeModal.vue"
    import SNB from '../../../../layouts/nav/SNB.vue'
    import KSAInputNumber from "../../../../components/ui-components/input/KSAInputNumber.vue";

    const router = useRouter()
    const globals = getCurrentInstance().appContext.config.globalProperties;
    const { $toast } = globals;
    const loadingStore = usePageLoadingStore()
    const userStore = useUserStore()
    const isStudent = ref(true);
    const residenceAreaRef = ref(null);

    const resumeModal = ref(null)

    const tabList = ref([
        { title: '기본 정보', active: true, completed: false, closed: false },
        { title: '소개', active: false, completed: false, closed: false },
        { title: '학력', active: false, completed: false, closed: false },
        { title: '포트폴리오', active: false, completed: false, closed: false },
        { title: '자격증 및 외국어', active: false, completed: false, closed: false },
        { title: '업무 경험', active: false, completed: false, closed: false },
        { title: '병역', active: false, completed: false, closed: false },
    ])

    const saveIsDisabled = ref(false)

    onMounted(()=> {
        retrieveRecordDetail();

        if(userStore.user.userGb !== '01' && userStore.user.userGb !== 'STUDENT') {
            isStudent.value = false;
        }

        _.each(data_interests.value, el => {el.on = false;})
        _.each(data_desiredJob.value, el => {el.on = false;})


        // 저장 비활성화
        //saveIsDisabled.value = Number(moment().format('YYYYMMDD')) >= 20230527;
    })

    const currentTabIndex = computed(()=>{
        return _.findIndex(tabList.value, {'active': true});
    })

    const changeTab = (index) => {
        inputErrors.value = [];
        if(index === 2) {
            if(_.isEmpty(recordDetail.value.eduHistoris)) {
                addEduHistory();
            } else {
                _.each(recordDetail.value.eduHistoris, eduHistory => {
                    if(eduHistory.startYm.length === 6 && eduHistory.startYm.indexOf('.') === -1) eduHistory.startYm = `${eduHistory.startYm.substring(0,4)}.${eduHistory.startYm.substring(4)}`
                    if(eduHistory.endYm.length === 6 && eduHistory.endYm.indexOf('.') === -1) eduHistory.endYm = `${eduHistory.endYm.substring(0,4)}.${eduHistory.endYm.substring(4)}`
                })
            }
        } else if(index === 3) {
            if(_.isEmpty(recordDetail.value.portfolios)) {
                addPortfoilo();
            } else {
                _.each(recordDetail.value.portfolios, portfolio => {
                    if(portfolio.startYm && portfolio.startYm.length === 6 && portfolio.startYm.indexOf('.') === -1) portfolio.startYm = `${portfolio.startYm.substring(0,4)}.${portfolio.startYm.substring(4)}`
                    if(portfolio.endYm && portfolio.endYm.length === 6 && portfolio.startYm.indexOf('.') === -1) portfolio.endYm = `${portfolio.endYm.substring(0,4)}.${portfolio.endYm.substring(4)}`
                    portfolio.deletedAttatches = [];
                })
            }
        } else if(index === 4) {
            inputErrors.value = [[],[]];
            if(_.isEmpty(recordDetail.value.certificates)) {
                addCertificate();
            }
            if(_.isEmpty(recordDetail.value.languageTests)) {
                addLanguageTest();
            }
            if(_.isEmpty(recordDetail.value.foreignLanguages)) {
                addForeignLanguage();
            }
        } else if(index === 5) {
            if(_.isEmpty(recordDetail.value.jobHistorys)) {
                addJobHistory();
            } else {
                _.each(recordDetail.value.jobHistorys, jobHistory => {
                    if(jobHistory.tenureYn === 'Y' || jobHistory.tenureYn === 'N') {
                        jobHistory.tenureYn = jobHistory.tenureYn === 'Y';
                    }
                    if(jobHistory.startYm && jobHistory.startYm.length === 6 && jobHistory.startYm.indexOf('.') === -1) jobHistory.startYm = `${jobHistory.startYm.substring(0,4)}.${jobHistory.startYm.substring(4)}`
                    if(jobHistory.endYm && jobHistory.endYm.length === 6 && jobHistory.startYm.indexOf('.') === -1) jobHistory.endYm = `${jobHistory.endYm.substring(0,4)}.${jobHistory.endYm.substring(4)}`
                })
            }
        } else if(index === 6) {
        }

        _.each(tabList.value, (tab, i) => {
            tab.active = index === i;
        });
    }

    const recordDetail = ref({
        "userName" : "",
        "phoneNum" : "",
        "email" : "",
        "school" : "", // 학교이름
        "residenceArea1": "", // 거주지역_시도
        "residenceArea2": "", // 거주지역_시군구
        "interests" : "",
        "desiredJob" : "",
        "desiredSalary" : 0,
        "introOpenYn" : true,
        "introAline" : "",
        "selfIntro" : "",
        "eduHistOpenYn" : true,
        "schoolGb" : "", // 학교구분  05 고졸 01 대학(2,3년) 02 대학(4년) 03 대학원(석사) 04 대학원(박사)
        "eduTenureGb" : "",  // 교육상태 01 졸업 02 졸업예정 03 재학중/과정 04 중퇴 05 수료 06 휴학
        "portfolioOpenYn" : true,
        "certForeignOpenYn" : true,
        "jobHistOpenYn" : true,
        "milOpenYn" : true,
        "milServiceGb" : "",
        "eduHistoris" : [],
        "portfolios" : [],
        "certificates" : [],
        "languageTests" : [],
        "foreignLanguages" : [],
        "jobHistorys" : []
    })
    const original_record = ref({
        "eduHistoris" : [],
        "portfolios" : [],
        "certificates" : [],
        "languageTests" : [],
        "foreignLanguages" : [],
        "jobHistorys" : []
    })

    const inputError = ref({
        interests: '', // 관심카테고리
        desiredJob: '', // 희망직무
        desiredSalary: '', // 희망연봉
        residenceArea: '', // 거주지역
    })
    const inputErrors = ref([]);

    let deletedEduHistoris = []; // 학력 삭제 대상
    let deletedPortfolios = []; // 포트포리오 삭제 대상
    let deletedCertificates = []; // 자격증 삭제 대상
    let deletedLanguageTests = []; // 어학시험 삭제 대상
    let deletedForeignLanguages = []; // 언어 삭제 대상
    let deletedJobHistorys = []; // 업무경험 삭제 대상

    const data_interests = ref(globals.$interests)
    const data_desiredJob = ref(globals.$desiredJob)

    const currentEduHistIndex = ref(0);

    const schoolGbRefs = ref([]);
    const majorGbRefs = ref([]);
    const submajorTypeGbRefs = ref([]);
    const submajorGbRefs = ref([]);
    const eduTenureGbRefs = ref([]);
    const fullScoreGbRefs = ref([]);
    const langGbRefs = ref([]);
    const langLevelGbRefs = ref([]);
    const jobHistoryEndYmRef = ref([]);

    // 고졸(05)일 경우, 비활성화
    function isDisabled(eduHistory) {
      return eduHistory.schoolGb === '05';
    }

    // 고졸(05)일 경우, 학교(대학) 필수표시(*) 제거.
    function isMarked(eduHistory) {
      return isStudent.value && eduHistory.schoolGb !== '05';
    }

    // Vue의 watch 문법에서 배열이나 객체를 감시할 때는 반드시 감시할 대상이 함수 형태로 전달되어야 함.
    // watch(recordDetail.value.eduHistoris, (newEduHistories) => { 이렇게 하면 안 됨.
    // 각 eduHistory 항목을 감시하여 schoolGb 값이 '05'인 경우 관련 상태를 업데이트
    watch(() => recordDetail.value.eduHistoris, (newEduHistoris) => {
      newEduHistoris.forEach( async (eduHistory, index) => {
        // 고졸(05)일 경우,
        if (eduHistory.schoolGb === '05') {
          // 학교 이름
          eduHistory.schoolName = '';
          //await nextTick(); // Wait for DOM update
          // 전공 계열 선택
          const majorGbSelect = majorGbRefs.value[index];
          if (majorGbSelect) {
            majorGbSelect.initSelectedData();
          }
          // 전공 학과
          eduHistory.majorName = '';
          // 부전공,복수전공 삭제
          deleteSubMajor(eduHistory);
          // (졸업)상태
          const eduTenureGbSelect = eduTenureGbRefs.value[index];
          if (eduTenureGbSelect) {
            eduTenureGbSelect.initSelectedData();
          }
          // 평점
          eduHistory.score = '';
          // 평점 기준 select
          const fullScoreGbSelect = fullScoreGbRefs.value[index];
          if (fullScoreGbSelect) {
            fullScoreGbSelect.initSelectedData();
          }
          // 성적증명서
          eduHistory.transcriptName = '';
        }
      });
    }, { deep: true });

    const retrieveRecordDetail = () => {
        loadingStore.loading = true;
        mypageRecordService.retrieveRecord()
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    recordDetail.value = result;
                    original_record.value = _.cloneDeep(result);

                    if(result.phoneNum && result.phoneNum.length === 11) {
                        recordDetail.value.phoneNumDisplay = result.phoneNum.substring(0,3) + '-' + result.phoneNum.substring(3,7) + '-' + result.phoneNum.substring(7)
                    } else {
                        recordDetail.value.phoneNumDisplay = result.phoneNum;
                    }
                    // 기본 정보
                    if(result.residenceArea1 && result.residenceArea2) {
                        residenceAreaRef.value.initSelectedOption(result.residenceArea1, result.residenceArea2)
                    }

                    if(result.interests !== null && result.interests !== '') {
                        _.each(data_interests.value, el => {
                            el.on = result.interests.indexOf(el.label) > -1;
                        })
                    }
                    if(result.desiredJob !== null && result.desiredJob !== '') {
                        _.each(data_desiredJob.value, el => {
                            el.on = result.desiredJob.indexOf(el.label) > -1;
                        })
                    }
                    // 희망조건, 희망직무, 희망연봉 모두 입력되었을 경우 완성
                    if(result.interests !== null && result.interests !== '' && result.desiredJob !== null && result.desiredJob !== '' && result.desiredSalary > 0 ) {
                        tabList.value[0].completed = true;
                    }
                    // 소개
                    if(recordDetail.value.introOpenYn === null || recordDetail.value.introOpenYn === '') { // 소개 공개(introOpenYn)가 공백일 경우, 체크(true)
                        recordDetail.value.introOpenYn = true;
                    } else { // 소개 공개(introOpenYn)가 공백이 아닐 경우, Y면, 체크(true)
                        if(recordDetail.value.introOpenYn === 'Y') {
                            recordDetail.value.introOpenYn = true;
                        } else {
                            recordDetail.value.introOpenYn = false;
                            tabList.value[1].closed = true;
                        }
                        // 내용이 채워져있는지 확인(저장시 공백 제거)
                        if(recordDetail.value.introAline && recordDetail.value.selfIntro) {
                            tabList.value[1].completed = true;
                        }
                    }
                    // 학력
                    if(recordDetail.value.eduHistOpenYn === null || recordDetail.value.eduHistOpenYn === '') {
                        recordDetail.value.eduHistOpenYn = true;
                    } else {
                        if(recordDetail.value.eduHistOpenYn === 'Y') {
                            recordDetail.value.eduHistOpenYn = true;
                        } else {
                            recordDetail.value.eduHistOpenYn = false;
                            tabList.value[2].closed = true;
                        }
                    }
                    if(recordDetail.value.eduHistoris.length > 0) {
                      tabList.value[2].completed = true;
                    } else {
                        recordDetail.value.eduHistoris.push({
                            eduHistSeq: 0,
                            schoolGb: recordDetail.value.schoolGb, // 학교구분 2
                            schoolName: recordDetail.value.school, // 학교이름 200
                            majorGb: "", // 전공계열구분 2
                            majorName: "", // 전공학과 200
                            submajorTypeGb: globals.$gb.SUBMAJOR_TYPE_GB ? globals.$gb.SUBMAJOR_TYPE_GB[0].value : "",
                            submajorGb: '',
                            submajorName: '',
                            startYm: "", // 시작년월 6
                            endYm: "", // 종료년월 6
                            eduTenureGb: recordDetail.value.eduTenureGb, // 교육상태 2
                            score: "", // 평점
                            fullScoreGb: globals.$gb.FULL_SCORE_GB ? globals.$gb.FULL_SCORE_GB[0].value : "", // 만점구분 2
                            transcriptName: "", // 성적증명서명 200
                            transcriptSize: "", // 성적증명서용량 50
                            transcriptDir: "", // 성적증명서저장위치 2000
                            order: 0,
                            deleteYn: "N", // 삭제요청여부
                        })
                    }
                    // 포트폴리오
                    if(recordDetail.value.portfolioOpenYn === null || recordDetail.value.portfolioOpenYn === '') {
                        recordDetail.value.portfolioOpenYn = true;
                    } else {
                        if(recordDetail.value.portfolioOpenYn === 'Y') {
                            recordDetail.value.portfolioOpenYn = true;
                        } else {
                            recordDetail.value.portfolioOpenYn = false;
                            tabList.value[3].closed = true;
                        }
                        if(recordDetail.value.portfolios.length > 0) {
                            let isCompleted = true;
                            for (let i = 0; i < recordDetail.value.portfolios.length; i++) {
                                const { completed } = recordDetail.value.portfolios[i]
                                if( completed === 'N') {
                                    isCompleted = false;
                                }
                            }
                            if ( isCompleted ) {
                                tabList.value[3].completed = true;
                            }
                        }
                    }
                    // 자격증 및 외국어
                    if(recordDetail.value.certForeignOpenYn === null || recordDetail.value.certForeignOpenYn === '') {
                        recordDetail.value.certForeignOpenYn = true;
                    } else {
                        if(recordDetail.value.certForeignOpenYn === 'Y') {
                            recordDetail.value.certForeignOpenYn = true;
                        } else {
                            recordDetail.value.certForeignOpenYn = false;
                            tabList.value[4].closed = true;
                        }
                        if(recordDetail.value.certificates.length > 0 && recordDetail.value.languageTests.length > 0 && recordDetail.value.foreignLanguages.length > 0) {
                            let isCompleted = true;
                            for (let i = 0; i < recordDetail.value.certificates.length; i++) {
                                const { completed } = recordDetail.value.certificates[i];
                                if( completed === 'N') {
                                    isCompleted = false;
                                }
                            }
                            for (let i = 0; i < recordDetail.value.languageTests.length; i++) {
                                const { completed } = recordDetail.value.languageTests[i];
                                if( completed === 'N') {
                                    isCompleted = false;
                                }
                            }
                            if( isCompleted ) {
                                tabList.value[4].completed = true;
                            }
                        }
                    }
                    // 업무 경험
                    if(recordDetail.value.jobHistOpenYn === null || recordDetail.value.jobHistOpenYn === '') {
                        recordDetail.value.jobHistOpenYn = true;
                    } else {
                        if(recordDetail.value.jobHistOpenYn === 'Y') {
                            recordDetail.value.jobHistOpenYn = true;
                        } else {
                            recordDetail.value.jobHistOpenYn = false;
                            tabList.value[5].closed = true;
                        }
                        if(recordDetail.value.jobHistorys.length > 0) {
                            let isCompleted = true;
                            for (let i = 0; i < recordDetail.value.jobHistorys.length; i++) {
                                const { completed } = recordDetail.value.jobHistorys[i]
                                if( completed === 'N') {
                                    isCompleted = false;
                                }
                            }
                            if ( isCompleted ) {
                                tabList.value[5].completed = true;
                            }
                          }
                    }
                    // 병역
                    if(recordDetail.value.milOpenYn === null || recordDetail.value.milOpenYn === '') {
                        recordDetail.value.milOpenYn = true;
                    } else {
                        if(recordDetail.value.milOpenYn === 'Y') {
                          recordDetail.value.milOpenYn = true;
                        } else {
                          recordDetail.value.milOpenYn = false;
                          tabList.value[6].closed = true;
                        }
                        if(recordDetail.value.milServiceGb) {
                            tabList.value[6].completed = true;
                        }
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const interestCount = computed(()=>{
        return _.filter(data_interests.value, {'on': true}).length || 0;
    })

    const onClickInterest = (interest) => {
        inputError.value.interests = '';
        if(interest.on) {
            interest.on  = false;
        } else {
            if(interestCount.value < 3) {
                interest.on = true;
            } else {
                inputError.value.interests = '최대 3개까지 선택할 수 있습니다.';
            }
        }
    }

    const desiredJobCount = computed(()=>{
        return _.filter(data_desiredJob.value, {'on': true}).length || 0;
    })

    const onClickDesiredJob = (desiredJob) => {
        inputError.value.desiredJob = '';
        if(desiredJob.on) {
            desiredJob.on  = false;
        } else {
            if(desiredJobCount.value < 3) {
                desiredJob.on = true;
            } else {
                inputError.value.desiredJob = '최대 3개까지 선택할 수 있습니다.';
            }
        }
    }

    const onSelectedArea1Option = (option) => {
      recordDetail.value.residenceArea1 = option;
      inputError.value.residenceArea = '';
    }

    const onSelectedArea2Option = (option) => {
      recordDetail.value.residenceArea2 = option;
      inputError.value.residenceArea = '';
    }


    // s : 학력
    const addEduHistory = () => {
        recordDetail.value.eduHistoris.push({
            eduHistSeq: 0,
            schoolGb: "", // 학교구분 2
            schoolName: "", // 학교이름 200
            majorGb: "", // 전공계열구분 2
            majorName: "", // 전공학과 200
            submajorTypeGb: globals.$gb.SUBMAJOR_TYPE_GB ? globals.$gb.SUBMAJOR_TYPE_GB[0].value : "",
            submajorGb: '',
            submajorName: '',
            startYm: "", // 시작년월 6
            endYm: "", // 종료년월 6
            eduTenureGb: "", // 교육상태 2
            score: "", // 평점
            fullScoreGb: globals.$gb.FULL_SCORE_GB ? globals.$gb.FULL_SCORE_GB[0].value : "", // 만점구분 2
            transcriptName: "", // 성적증명서명 200
            transcriptSize: "", // 성적증명서용량 50
            transcriptDir: "", // 성적증명서저장위치 2000
            order: 0,
            deleteYn: "N", // 삭제요청여부
        })
    }

    const moveUpEduHistory = (index) => {
        if(index > 0) {
            const { eduHistoris } = recordDetail.value;
            const temp = eduHistoris[index-1];
            eduHistoris[index-1] = eduHistoris[index];
            eduHistoris[index] = temp;

            initSelectGbRefs();
        }
    }

    const moveDownEduHistory = (index) => {
        const { eduHistoris } = recordDetail.value;
        if(index < eduHistoris.length-1) {
            const temp = eduHistoris[index+1];
            eduHistoris[index+1] = eduHistoris[index];
            eduHistoris[index] = temp;

            initSelectGbRefs();
        }
    }

    const initSelectGbRefs = () => {
        setTimeout(()=>{
            if (currentTabIndex.value === 2) { // 학력
                for(let i=0; i<schoolGbRefs.value.length; i++) {
                    schoolGbRefs.value[i].initSelectedOption();
                }
                for(let i=0; i<majorGbRefs.value.length; i++) {
                    majorGbRefs.value[i].initSelectedOption();
                }
                for(let i=0; i<submajorTypeGbRefs.value.length; i++) {
                    submajorTypeGbRefs.value[i].initSelectedOption();
                }
                for(let i=0; i<submajorGbRefs.value.length; i++) {
                    submajorGbRefs.value[i].initSelectedOption();
                }
                for(let i=0; i<eduTenureGbRefs.value.length; i++) {
                    eduTenureGbRefs.value[i].initSelectedOption();
                }
                for(let i=0; i<fullScoreGbRefs.value.length; i++) {
                    fullScoreGbRefs.value[i].initSelectedOption();
                }
            } else if (currentTabIndex.value === 4) { // 자격증및외국어
                for(let i=0; i<langGbRefs.value.length; i++) {
                    langGbRefs.value[i].initSelectedOption();
                }
                for(let i=0; i<langLevelGbRefs.value.length; i++) {
                    langLevelGbRefs.value[i].initSelectedOption();
                }
            }
        }, 100)
    }

    const deleteEduHistory = (eduHistory, index) => {
        const { eduHistoris } = recordDetail.value;
        inputErrors.value = [];

        if(eduHistory.eduHistSeq !== 0) {
            eduHistory.deleteYn = 'Y';
            deletedEduHistoris.push(eduHistory);
        }
        eduHistoris.splice(index, 1);
        if(_.isEmpty(eduHistoris)) {
            addEduHistory();
        }
        initSelectGbRefs();
    }

    const addSubMajor = (eduHistory, index) => {
        eduHistory.openSubMajor = true;
        eduHistory.submajorTypeGb = globals.$gb.SUBMAJOR_TYPE_GB ? globals.$gb.SUBMAJOR_TYPE_GB[0].value : "";
        eduHistory.submajorGb = "";
        eduHistory.submajorName = "";
        initSelectGbRefs();

        if(inputErrors.value[index] !== undefined) {
            inputErrors.value[index].submajorName = '';
        }
    }

    const deleteSubMajor = (eduHistory) => {
        eduHistory.openSubMajor = false;
        eduHistory.submajorTypeGb = "";
        eduHistory.submajorGb = "";
        eduHistory.submajorName = "";
    }

    const uploadFileWithTranscript = (eduHistory) => {
        const input = document.createElement('input');
        input.type= 'file';
        input.click();

        input.addEventListener('change', function(event){
           // console.log(event.target.files);
           const file = event.target.files[0];

            let paramObj = {
                container: 'private',
                folder: 'eduhist',
                file,
            }
            fileService.uploadFile(paramObj)
                .then((res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        eduHistory.transcriptName = result.originalFilename;
                        eduHistory.transcriptSize = result.fileSize;
                        eduHistory.transcriptDir = result.blobUrl;
                    }
                }).catch( (error) =>  {
                console.log(error);
            });
        });
    }

    const deleteTranscriptFile = (eduHistory) => {
        eduHistory.transcriptName = '';
        eduHistory.transcriptSize = '';
        eduHistory.transcriptDir = '';
    }

    // e : 학력


    // s : 포트폴리오
    const addPortfoilo = () => {
        recordDetail.value.portfolios.push({
            pofolSeq: 0,
            pofolGb: globals.$gb.POFOL_GB[0].value || "01",
            pofolName: '',
            pofolOrgan: '',
            pofolContent: '',
            startYm: '',
            endYm: '',
            order: 0,
            deleteYn : 'N',
            completed : '',
            portfolioAttachs: [
                {
                    pofolSeq: 0,
                    attachSeq: 0,
                    fileName: "",
                    fileSize: "",
                    fileDir: "",
                    deleteYn : 'N',
                }
            ],
            deletedAttatches: [],
        })
    }

    const moveUpPortfolio = (index) => {
        if(index > 0) {
            const { portfolios } = recordDetail.value;
            const temp = portfolios[index-1];
            portfolios[index-1] = portfolios[index];
            portfolios[index] = temp;
        }
    }

    const moveDownPortfolio = (index) => {
        const { portfolios } = recordDetail.value;
        if(index < portfolios.length-1) {
            const temp = portfolios[index+1];
            portfolios[index+1] = portfolios[index];
            portfolios[index] = temp;
        }
    }

    const deletePortfolio = (portfolio, index) => {
        const { portfolios } = recordDetail.value;
        inputErrors.value = [];

        if(portfolio.pofolSeq !== 0) {
            portfolio.deleteYn = 'Y';
            deletedPortfolios.push(portfolio);
        }
        portfolios.splice(index, 1);
        if(_.isEmpty(portfolios)) {
            addPortfoilo();
        }
        initSelectGbRefs();
    }


    const addPortfoliAttach = (portfolio) => {
        portfolio.portfolioAttachs.push({
            attachSeq: 0,
            pofolSeq: portfolio.pofolSeq,
            fileName: "",
            fileSize: "",
            fileDir: "",
            deleteYn: "N",
        })
    }

    const deletePortfolioAttach = (portfolio, index) => {
        if(portfolio.portfolioAttachs.length > 1) {
            if(portfolio.portfolioAttachs[index].attachSeq !== 0) {
                portfolio.portfolioAttachs[index].deleteYn = 'Y';
                portfolio.deletedAttatches.push(portfolio.portfolioAttachs[index]);
            }
            portfolio.portfolioAttachs.splice(index, 1);
        } else {
            portfolio.portfolioAttachs[0].fileName = "";
            portfolio.portfolioAttachs[0].fileSize = "";
            portfolio.portfolioAttachs[0].fileDir = "";
        }
    }

    const uploadFileWithPortfolio = (portfolioAttach) => {
        const input = document.createElement('input');
        input.type= 'file';
        input.click();

        input.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];

            let paramObj = {
                container: 'private',
                folder: 'portfolio',
                file,
            }
            fileService.uploadFile(paramObj)
                .then((res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        portfolioAttach.fileName = result.originalFilename;
                        portfolioAttach.fileSize = result.fileSize;
                        portfolioAttach.fileDir = result.blobUrl;
                    }
                }).catch( (error) =>  {
                console.log(error);
            });
        });
    }

    // e : 포트폴리오


    // s: 자격증 및 외국어

    const addCertificate = () => {
        recordDetail.value.certificates.push({
            seq: 0,
            certName: "",
            certOrgan: "",
            getYy: "",
            deleteYn: "N",
            order: 0,
            completed : '',
        });
    }

    const moveUpCertificate = (index) => {
        if(index > 0) {
            const { certificates } = recordDetail.value;
            const temp = certificates[index-1];
            certificates[index-1] = certificates[index];
            certificates[index] = temp;
        }
    }

    const moveDownCertificate = (index) => {
        const { certificates } = recordDetail.value;
        if(index < certificates.length-1) {
            const temp = certificates[index+1];
            certificates[index+1] = certificates[index];
            certificates[index] = temp;
        }
    }

    const deleteCertificate = (certificate, index) => {
        const { certificates } = recordDetail.value;
        if(certificate.seq !== 0) {
            certificate.deleteYn = 'Y';
            deletedCertificates.push(certificate);
        }
        certificates.splice(index, 1);
        if(_.isEmpty(certificates)) {
            addCertificate();
        }
    }

    const addLanguageTest = () => {
        recordDetail.value.languageTests.push({
            seq: 0,
            langGb: "",
            testType: "",
            testScore: "",
            getYmd: "",
            deleteYn: "N",
            order: 0,
            completed : '',
        });
    }

    const moveUpLanguageTest = (index) => {
        if(index > 0) {
            const { languageTests } = recordDetail.value;
            const temp = languageTests[index-1];
            languageTests[index-1] = languageTests[index];
            languageTests[index] = temp;

            initSelectGbRefs();
        }
    }

    const moveDownLanguageTest = (index) => {
        const { languageTests } = recordDetail.value;
        if(index < languageTests.length-1) {
            const temp = languageTests[index+1];
            languageTests[index+1] = languageTests[index];
            languageTests[index] = temp;
            initSelectGbRefs();
        }
    }

    const deleteLanguageTest = (languageTest, index) => {
        const { languageTests } = recordDetail.value;
        if(languageTest.seq !== 0) {
            languageTest.deleteYn = 'Y';
            deletedLanguageTests.push(languageTest);
        }
        languageTests.splice(index, 1);
        if(_.isEmpty(languageTests)) {
            addLanguageTest();
        }
        initSelectGbRefs();
    }

    const addForeignLanguage = () => {
        recordDetail.value.foreignLanguages.push({
            seq: 0,
            foreignLangName: "",
            langLevelGb: globals.$gb.LANG_LEVEL_GB[0].value || '01',
            deleteYn: "N",
            order: 0,
        });
    }

    const deleteForeignLanguage = (foreignLanguage, index) => {
        const { foreignLanguages } = recordDetail.value;
        if(foreignLanguage.seq !== 0) {
            foreignLanguage.deleteYn = 'Y';
            deletedForeignLanguages.push(foreignLanguage);
        }
        foreignLanguages.splice(index, 1);
        if(_.isEmpty(foreignLanguages)) {
            addForeignLanguage();
        }
        initSelectGbRefs();
    }
    // e: 자격증 및 외국어


    // s: 업무 경험
    const addJobHistory = () => {
        recordDetail.value.jobHistorys.push({
            jobHistSeq: 0,
            companyName: "",
            departName: "",
            jobTitle: "",
            startYm: "",
            endYm: "",
            tenureYn: false,
            jobNote: "",
            order: 0,
            completed : '',
        });
    }

    const moveUpJobHistory = (index) => {
        if(index > 0) {
            const { jobHistorys } = recordDetail.value;
            const temp = jobHistorys[index-1];
            jobHistorys[index-1] = jobHistorys[index];
            jobHistorys[index] = temp;
        }
    }

    const moveDownJobHistory = (index) => {
        const { jobHistorys } = recordDetail.value;
        if(index < jobHistorys.length-1) {
            const temp = jobHistorys[index+1];
            jobHistorys[index+1] = jobHistorys[index];
            jobHistorys[index] = temp;
        }
    }

    const deleteJobHistory = (jobHistory ,index) => {
        const { jobHistorys } = recordDetail.value;
        if(jobHistory.seq !== 0) {
            jobHistory.deleteYn = 'Y';
            deletedJobHistorys.push(jobHistory);
        }
        jobHistorys.splice(index, 1);
        if(_.isEmpty(jobHistorys)) {
            addJobHistory();
        }
        initSelectGbRefs();
    }

    const onClickJobHistTenureYn = (jobHistory, index) => {
        setTimeout(()=>{
            if(jobHistory.tenureYn) {
                jobHistory.endYm = '';
                try {
                    jobHistoryEndYmRef.value[index].setValue('');
                }catch(e){}
            }
        },100)
    }

    // e: 업무 경험

    // 입력된 데이터 validation: trim 이후 길이가 1일경우 -> 숫자 제외, 한글음정여부만 판단
    // valid 1 invalid 0
    const inputTextValidation = (trimInputText) => {
        if(trimInputText.length === 1) {
            const isNumber = /^[0-9]+$/;  // 숫자만 포함하는 정규식
            const validHangulSyllable = /^[가-힣]$/;  // 한글 음절
            if( isNumber.test(trimInputText) ) { // 숫자면 OK
                return 1;
            } else { // 숫자가 아닌데,
                if( validHangulSyllable.test(trimInputText) ) { // 한글 음절이면 OK
                    return 1;
                } else {
                    return 0;
                }
            }
        } else {
            return 1;
        }
    }

    const saveRecord = () => {
        let isInvalid = false;

        // console.log(currentTabIndex.value);
        if(currentTabIndex.value === 0) {
            const interests = _.map(_.filter(data_interests.value, {'on': true}), 'value').join(',');
            const desiredJob = _.map(_.filter(data_desiredJob.value, {'on': true}), 'value').join(',');
            const { desiredSalary, residenceArea1, residenceArea2 } = recordDetail.value;
            // console.log(interests)
            // console.log(desiredJob)
            // console.log(desiredSalary)
            inputError.value.interests = '';
            inputError.value.desiredJob = '';
            inputError.value.residenceArea = '';

            if((residenceArea1 === '' || residenceArea1 === '시/도 선택') ||
                (residenceArea2 === '' || residenceArea2 === '시/구/군 선택') ) {
                inputError.value.residenceArea = (residenceArea1 === '' || residenceArea1 === '시/도 선택') ? '시/도를 선택하세요.' : '시/구/군을 선택하세요.';
                isInvalid = true;
            }

            if(!interests) {
                inputError.value.interests = '관심 카테고리를 선택하세요.';
                isInvalid = true;
            }

            if(!desiredJob) {
                inputError.value.desiredJob = '희망 직무를 선택하세요.';
                isInvalid = true;
            }

            if(!isInvalid) {
                loadingStore.loading = true;
                mypageRecordService.updateDesired({ interests, desiredJob, desiredSalary, residenceArea1, residenceArea2 })
                    .then((res) => {
                        loadingStore.loading = false;
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            $toast("", "저장되었습니다");
                            // 기본 정보 : 희망연봉에 0초과 숫자가 입력되지 않았을 경우 false
                            if(desiredSalary > 0) {
                                tabList.value[currentTabIndex.value].completed = true;
                            } else {
                                tabList.value[currentTabIndex.value].completed = false;
                            }
                        }
                    }).catch( (error) =>  {
                        loadingStore.loading = false;
                        console.log(error);
                    });
            }

        } else if(currentTabIndex.value === 1) { // 소개(공백 제거하여 저장)
            const { introOpenYn, introAline, selfIntro } = recordDetail.value;
            const trimIntroAline = introAline.trim();
            const trimSelfIntro = selfIntro.trim();

            loadingStore.loading = true;
            mypageRecordService.updateIntro(introOpenYn, trimIntroAline, trimSelfIntro)
                .then((res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast("", "저장되었습니다");
                        if(trimIntroAline && trimSelfIntro) {
                            tabList.value[currentTabIndex.value].completed = true;
                        } else {
                            tabList.value[currentTabIndex.value].completed = false;
                        }
                        tabList.value[currentTabIndex.value].closed = !introOpenYn;
                    }
                }).catch( (error) =>  {
                    loadingStore.loading = false;
                    console.log(error);
                });
        } else if(currentTabIndex.value === 2) { // 학력
            const { eduHistOpenYn, eduHistoris } = recordDetail.value;
            let isInvalid = false;
            // console.log(eduHistOpenYn);
            // console.log(eduHistoris);

            let newEduHistoris = _.cloneDeep(eduHistoris);

            const newEduHistorisTemp = [];
            for(let i=0; i<newEduHistoris.length; i++) {
                let eduHistory = newEduHistoris[i];
                // console.log(eduHistory);
                inputErrors.value[i] = {};

                const nowYm = moment().format("YYYYMM")
                const startYmValue = getInputValue(`.eduHistStartYm${i}`, newEduHistoris[i].startYm);
                const endYmValue = getInputValue(`.eduHistEndYm${i}`, newEduHistoris[i].endYm);
                const eduHistScore = getInputValue(`.eduHistScore${i}`, newEduHistoris[i].score);
                eduHistory.startYm = startYmValue;
                eduHistory.endYm = endYmValue;
                eduHistory.score = eduHistScore;

                if(isStudent.value) {
                    if(!eduHistory.schoolGb) {
                        inputErrors.value[i].schoolGb = '학력을 선택하세요.';
                        isInvalid = true;
                    }
                    if(eduHistory.schoolGb !== '05') {
                      if(!eduHistory.schoolName) {
                        inputErrors.value[i].schoolName = '학교를 검색하세요...';
                        isInvalid = true;
                      }
                      if(!eduHistory.majorGb) {
                        inputErrors.value[i].majorName = '전공 계열을 선택하세요...';
                        isInvalid = true;
                      } else if(!(eduHistory.majorName.trim() && inputTextValidation(eduHistory.majorName.trim()))) {
                        inputErrors.value[i].majorName = '전공 학과를 입력하세요...';
                        isInvalid = true;
                      }

                      if(eduHistory.openSubMajor) {
                        if(!eduHistory.submajorTypeGb) {
                          inputErrors.value[i].submajorName = '전공 구분을 선택하세요...';
                          isInvalid = true;
                        } else if(!eduHistory.submajorGb) {
                          inputErrors.value[i].submajorName = '전공 계열를 선택하세요...';
                          isInvalid = true;
                        } else if(!(eduHistory.submajorName.trim() && inputTextValidation(eduHistory.submajorName.trim()))) {
                          inputErrors.value[i].submajorName = '전공 학과를 입력하세요...';
                          isInvalid = true;
                        }
                      }
                    }

                    if(!eduHistory.startYm || (eduHistory.eduTenureGb !== '03' && !eduHistory.endYm)) {
                        inputErrors.value[i].startYm = '기간을 입력하세요.';
                        isInvalid = true;
                    }

                    if(eduHistory.schoolGb !== '05') {
                      if(!eduHistory.eduTenureGb) {
                        inputErrors.value[i].eduTenureGb = '상태를 선택하세요...';
                        isInvalid = true;
                      }
                      if(!eduHistory.score) {
                        inputErrors.value[i].score = '평점을 입력하세요...';
                        isInvalid = true;
                      }

                      if(!eduHistory.transcriptName || !eduHistory.transcriptDir) {
                        inputErrors.value[i].transcriptName = '성적증명서를 첨부하세요...';
                        isInvalid = true;
                      }
                    }
                }

                if(startYmValue) {
                    const startYm = moment(startYmValue.replace('.', '')).format("YYYYMM")
                    if(startYmValue.length !== 7) {
                        inputErrors.value[i].startYm = '기간을 YYYY.MM 형식으로 입력하세요.';
                        isInvalid = true;
                    } else if(startYm === 'Invalid date'  // 존재하지 않은 기간
                        // || Number(nowYm) < Number(startYm)  // 미래 기간
                    ) {
                        inputErrors.value[i].startYm = '올바른 기간을 입력하세요.';
                        isInvalid = true;
                    } else {
                        eduHistory.startYm = startYmValue.replace('.','');
                    }
                }
                if(newEduHistoris[i].eduTenureGb !== '03' && endYmValue) { // 재학중이 아닐때
                    const endYm = moment(endYmValue.replace('.', '')).format("YYYYMM")
                    if(endYmValue.length !== 7) {
                        inputErrors.value[i].startYm = '기간을 YYYY.MM 형식으로 입력하세요.';
                        isInvalid = true;
                    } else if(endYm === 'Invalid date' // 존재하지 않은 기간
                       // || Number(nowYm) < Number(endYm)  // 미래 기간
                    ) {
                        inputErrors.value[i].startYm = '올바른 기간을 입력하세요.';
                        isInvalid = true;
                    } else {
                        eduHistory.endYm = endYmValue.replace('.','');
                    }
                }

                if(eduHistory.endYm) eduHistory.endYm = eduHistory.endYm.replace('.','');

                if(!isStudent.value) { // 재직자
                    let isEmpty = false;
                    const { schoolGb, schoolName, majorGb, majorName, submajorTypeGb, submajorGb, submajorName, startYm, endYm, eduTenureGb, score, fullScoreGb, transcriptName } = newEduHistoris[i];
                    isEmpty = !schoolGb && !schoolName && !majorGb && !majorName && !submajorGb && !submajorName && !startYmValue && !endYmValue && !eduTenureGb && !score && !transcriptName;

                    if(newEduHistoris[i].eduHistSeq !== 0) { // 기존
                        if(isEmpty) {
                            newEduHistoris[i].deleteYn = 'Y';
                        }
                        newEduHistorisTemp.push(newEduHistoris[i]);
                    } else { // 새 항목
                        if(!isEmpty) {
                            newEduHistorisTemp.push(newEduHistoris[i]);
                        }
                    }
                } else {
                    newEduHistorisTemp.push(newEduHistoris[i]);
                }

                eduHistory.order = i;
                delete eduHistory.openSubMajor;
            }
            // console.log(newEduHistoris);

            if(!isInvalid) {
                newEduHistoris = [...newEduHistorisTemp, ...deletedEduHistoris]; // 삭제 대상 추가

                loadingStore.loading = true;
                mypageRecordService.updateEduHistory(eduHistOpenYn, newEduHistoris)
                    .then((res) => {
                        // console.log(res);
                        loadingStore.loading = false;
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            $toast("", "저장되었습니다");

                            recordDetail.value.eduHistoris = result.eduHistoris;
                            _.each(recordDetail.value.eduHistoris, eduHistory => {
                                if(eduHistory.startYm.length === 6) eduHistory.startYm = `${eduHistory.startYm.substring(0,4)}.${eduHistory.startYm.substring(4)}`
                                if(eduHistory.endYm.length === 6) eduHistory.endYm = `${eduHistory.endYm.substring(0,4)}.${eduHistory.endYm.substring(4)}`
                            })
                            original_record.value.eduHistoris = _.cloneDeep(recordDetail.value.eduHistoris);

                            if(_.isEmpty(recordDetail.value.eduHistoris)) {
                                addEduHistory();
                            }

                            deletedEduHistoris = [];
                            tabList.value[currentTabIndex.value].completed = true;
                            tabList.value[currentTabIndex.value].closed = !eduHistOpenYn;
                        }
                    }).catch( (error) =>  {
                    loadingStore.loading = false;
                    console.log(error);
                });
            } else {
                $toast("error", "입력된 내용이 올바른지 확인해 주세요.");
            }
        } else if(currentTabIndex.value === 3) { // 포트폴리오
            const { portfolioOpenYn, portfolios } = recordDetail.value;
            // console.log(portfolioOpenYn);
            // console.log(portfolios);

            let isInvalid = false;
            inputErrors.value = [];
            const portfoliosTemp = [];
            for(let i=0; i<portfolios.length; i++) {
                portfolios[i].completed = 'Y';
                inputErrors.value[i] = {};
                const nowYm = moment().format("YYYYMM")

                const startYmValue = getInputValue(`.pofolStartYm${i}`, portfolios[i].startYm);
                let endYmValue;

                if(startYmValue) {
                    const startYm = moment(startYmValue.replace('.', '')).format("YYYYMM")
                    if(startYmValue.length !== 7) {
                        inputErrors.value[i].startYm = '기간을 YYYY.MM 형식으로 입력하세요.';
                        isInvalid = true;
                    } else if(startYm === 'Invalid date'  // 존재하지 않은 기간
                       // || Number(nowYm) < Number(startYm) // 미래 기간
                    ) {
                        inputErrors.value[i].startYm = '올바른 기간을 입력하세요.';
                        isInvalid = true;
                    }
                } else {
                    portfolios[i].completed = 'N';
                }
                if(portfolios[i].pofolGb !== '01' && portfolios[i].pofolGb !== '04') {
                    endYmValue = getInputValue(`.pofolEndYm${i}`, portfolios[i].endYm);
                    if(endYmValue) {
                        const endYm = moment(endYmValue.replace('.', '')).format("YYYYMM")
                        if(endYmValue.length !== 7) {
                            inputErrors.value[i].startYm = '기간을 YYYY.MM 형식으로 입력하세요.';
                            isInvalid = true;
                        } else if(endYm === 'Invalid date' // 존재하지 않은 기간
                            //|| Number(nowYm) < Number(endYm) // 미래 기간
                        ) {
                            inputErrors.value[i].startYm = '올바른 기간을 입력하세요.';
                            isInvalid = true;
                        }
                    } else {
                        portfolios[i].completed = 'N';
                    }
                }

                const { pofolGb, pofolName, pofolOrgan, startYm, endYm, pofolContent, portfolioAttachs } = portfolios[i];
                const trimPofolName = pofolName.trim();
                const trimPofolOrgan = pofolOrgan.trim();
                const trimPofolContent = pofolContent.trim();

                if( !(trimPofolName && trimPofolOrgan && startYm && portfolioAttachs[0] && portfolioAttachs[0].fileName) ) {
                    portfolios[i].completed = 'N';
                } else {
                  if ( pofolGb !== '01' && pofolGb !== '04' ) {
                      if( !endYm ) {
                          portfolios[i].completed = 'N';
                      }
                  }
                  if ( pofolGb !== '01' ) {
                      if( !trimPofolContent ) {
                          portfolios[i].completed = 'N';
                      }
                  }
                }

                let isEmpty = false;
                if(pofolGb === '01') {
                    isEmpty = !pofolName && !pofolOrgan && !startYmValue && (portfolioAttachs.length === 1 && portfolioAttachs[0].fileName === '');
                } else if(pofolGb === '04'){
                    isEmpty = !pofolName && !pofolOrgan && !startYmValue && !pofolContent && (portfolioAttachs.length === 1 && portfolioAttachs[0].fileName === '');
                } else {
                    isEmpty = !pofolName && !pofolOrgan && !startYmValue && !endYmValue && !pofolContent && (portfolioAttachs.length === 1 && portfolioAttachs[0].fileName === '');
                }

                if(portfolios[i].pofolSeq !== 0) { // 기존
                    if(isEmpty) {
                        portfolios[i].deleteYn = 'Y';
                    }
                    portfoliosTemp.push(portfolios[i]);
                } else { // 새 포트폴리오
                    if(!isEmpty) {
                        portfoliosTemp.push(portfolios[i]);
                    }
                }
            }

            const newPortfolios = [..._.cloneDeep(portfoliosTemp), ...deletedPortfolios];
            _.each(newPortfolios, (portfolio, index) => {
                portfolio.startYm = portfolio.startYm.replace('.','');
                portfolio.endYm = portfolio.endYm.replace('.','');
                portfolio.order = index;
                const portfolioAttachsTemp = [];
                _.each(portfolio.portfolioAttachs, (attach) => {
                    if(attach.attachSeq !== 0) { // 기존 attach
                        if(attach.fileName === '') {
                            attach.deleteYn = 'Y';
                        }
                        portfolioAttachsTemp.push(attach);
                    } else { // 새 attach
                        if(attach.fileName !== '') { // 파일명 있을때만
                            portfolioAttachsTemp.push(attach);
                        }
                    }
                })
                portfolio.portfolioAttachs = [...portfolioAttachsTemp, ...portfolio.deletedAttatches];
            })
            // console.log(newPortfolios);

            if(!isInvalid) {
                loadingStore.loading = true;
                mypageRecordService.updatePortfolio(portfolioOpenYn, newPortfolios)
                    .then((res) => {
                        loadingStore.loading = false;
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);

                            $toast("", "저장되었습니다");

                            recordDetail.value.portfolios = result.portfolios;
                            _.each(recordDetail.value.portfolios, portfolio => {
                                if(portfolio.startYm && portfolio.startYm.length === 6) portfolio.startYm = `${portfolio.startYm.substring(0,4)}.${portfolio.startYm.substring(4)}`
                                if(portfolio.endYm && portfolio.endYm.length === 6) portfolio.endYm = `${portfolio.endYm.substring(0,4)}.${portfolio.endYm.substring(4)}`
                                portfolio.deletedAttatches = [];
                            })
                            original_record.value.portfolios = _.cloneDeep(recordDetail.value.portfolios);

                            if(_.isEmpty(recordDetail.value.portfolios)) {
                                addPortfoilo();
                            }

                            deletedPortfolios = [];

                            if (newPortfolios.length > 0) {
                                let isCompleted = true;
                                for(let i=0; i < newPortfolios.length; i++) {
                                    if(newPortfolios[i].completed === 'N') {
                                        isCompleted = false;
                                    }
                                }
                                if( isCompleted ) {
                                    tabList.value[currentTabIndex.value].completed = true;
                                } else {
                                    tabList.value[currentTabIndex.value].completed = false;
                                }
                            } else {
                                tabList.value[currentTabIndex.value].completed = false;
                            }
                            tabList.value[currentTabIndex.value].closed = !portfolioOpenYn;
                        }
                    }).catch( (error) =>  {
                    loadingStore.loading = false;
                    console.log(error);
                });
            } else {
                $toast("error", "입력된 내용이 올바른지 확인해 주세요.");
            }

        } else if(currentTabIndex.value === 4) { // 자격증및외국어
            const { certForeignOpenYn, certificates, languageTests, foreignLanguages } = recordDetail.value;
            // console.log(certForeignOpenYn);
            // console.log(certificates);
            // console.log(languageTests);
            // console.log(foreignLanguages);

            let isInvalid = false;
            inputErrors.value = [[],[]];
            // 자격증
            const certificatesTemp = [];
            for(let i=0; i<certificates.length; i++) {
                certificates[i].completed = 'Y';
                inputErrors.value[0][i] = {};
                const nowYy = moment().format("YYYY")
                const getYyValue = getInputValue(`.certGetYy${i}`, certificates[i].getYy);
                if(getYyValue) {
                    const getYy = moment(getYyValue).format("YYYY");
                    if(getYyValue.length !== 4) {
                        inputErrors.value[0][i].getYy = '취득연도를 YYYY 형식으로 입력하세요.';
                        isInvalid = true;
                    } else if(getYy === 'Invalid date' || // 존재하지 않은 기간
                        Number(nowYy) < Number(getYy) // 미래 기간
                    ) {
                        inputErrors.value[0][i].getYy = '올바른 취득연도를 입력하세요.';
                        isInvalid = true;
                    }
                } else {
                    certificates[i].completed = 'N';
                }

                const { certName, certOrgan, getYy } = certificates[i];
                const trimCertName = certName.trim();
                const trimCertOrgan = certOrgan.trim();
                const isEmpty = !certName && !certOrgan && !getYyValue;
                if( !(trimCertName && trimCertOrgan) ) {
                    certificates[i].completed = 'N';
                }

                if(certificates[i].seq !== 0) { // 기존
                    if(isEmpty) {
                        certificates[i].deleteYn = 'Y';
                    }
                    certificatesTemp.push(certificates[i]);
                } else { // 새 자격증
                    if(!isEmpty) {
                        certificatesTemp.push(certificates[i]);
                    }
                }
            }

            // 어학시험
            const languageTestsTemp = [];
            for(let i=0; i<languageTests.length; i++) {
                languageTests[i].completed = 'Y';
                inputErrors.value[1][i] = {};
                const nowYmd = moment().format("YYYYMMDD")
                const getYmdValue = getInputValue(`.languageTestGetYmd${i}`, languageTests[i].getYmd);
                if(getYmdValue) {
                    const getYmd = moment(getYmdValue.replaceAll('.', '')).format("YYYYMMDD")
                    if(getYmdValue.length !== 10) {
                        inputErrors.value[1][i].getYmd = '취득일을 YYYY.MM.DD 형식으로 입력하세요.';
                        isInvalid = true;
                    } else if(getYmd === 'Invalid date' || // 존재하지 않은 기간
                        Number(nowYmd) < Number(getYmd) // 미래 기간
                    ) {
                        inputErrors.value[1][i].getYmd = '올바른 취득일을 입력하세요.';
                        isInvalid = true;
                    }
                } else {
                    languageTests[i].completed = 'N';
                }

                const { langGb, testType, testScore, getYmd } = languageTests[i];
                const trimTestType = testType.trim();
                const trimTestScore = testScore.trim();

                if( !(langGb && trimTestType && trimTestScore) ) {
                    languageTests[i].completed = 'N';
                }

                const isEmpty = !langGb && !testType && !testScore && !getYmdValue;
                if(languageTests[i].seq !== 0) { // 기존
                    if(isEmpty) {
                        languageTests[i].deleteYn = 'Y';
                    }
                    languageTestsTemp.push(languageTests[i]);
                } else { // 새 자격증
                    if(!isEmpty) {
                        languageTestsTemp.push(languageTests[i]);
                    }
                }
            }

            // 언어
            const foreignLanguagesTemp = [];
            for(let i=0; i<foreignLanguages.length; i++) {
                const { foreignLangName } = foreignLanguages[i];
                const trimForeignLangName = foreignLangName.trim();
                foreignLanguages[i].foreignLangName = trimForeignLangName;

                const isEmpty = !trimForeignLangName;
                if(foreignLanguages[i].seq !== 0) { // 기존
                    if(isEmpty) {
                        foreignLanguages[i].deleteYn = 'Y';
                    }
                    foreignLanguagesTemp.push(foreignLanguages[i]);
                } else { // 새 자격증
                    if(!isEmpty) {
                        foreignLanguagesTemp.push(foreignLanguages[i]);
                    }
                }
            }

            const newCertificates = [..._.cloneDeep(certificatesTemp), ...deletedCertificates];
            const newLanguageTests = [..._.cloneDeep(languageTestsTemp), ...deletedLanguageTests];
            const newForeignLanguages = [..._.cloneDeep(foreignLanguagesTemp), ...deletedForeignLanguages];

            _.each(newCertificates, (certificate, index) => {
                certificate.order = index;
            })

            _.each(newLanguageTests, (languageTest, index) => {
                languageTest.getYmd = languageTest.getYmd.replace('.','').replace('.','');
                languageTest.order = index;
            })

            _.each(newForeignLanguages, (foreignLanguage, index) => {
                foreignLanguage.order = index;
            })

            // console.log(newCertificates);
            // console.log(newLanguageTests);
            // console.log(newForeignLanguages);

            if(!isInvalid) {
                loadingStore.loading = true;
                mypageRecordService.updateCertForeign(certForeignOpenYn, newCertificates, newLanguageTests, newForeignLanguages)
                    .then((res) => {
                        loadingStore.loading = false;
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            $toast("", "저장되었습니다");

                            recordDetail.value.certificates = result.certificates;
                            recordDetail.value.languageTests = result.languageTests;
                            recordDetail.value.foreignLanguages = result.foreignLanguages;

                            original_record.value.certificates = _.cloneDeep(recordDetail.value.certificates);
                            original_record.value.languageTests = _.cloneDeep(recordDetail.value.languageTests);
                            original_record.value.foreignLanguages = _.cloneDeep(recordDetail.value.foreignLanguages);

                            if(_.isEmpty(recordDetail.value.certificates)) {
                                addCertificate();
                            }

                            if(_.isEmpty(recordDetail.value.languageTests)) {
                                addLanguageTest();
                            }

                            if(_.isEmpty(recordDetail.value.foreignLanguages)) {
                                addForeignLanguage();
                            }

                            deletedCertificates = [];
                            deletedLanguageTests = [];
                            deletedForeignLanguages = [];

                            _.each(recordDetail.value.languageTests, languageTest => {
                                if(languageTest.getYmd && languageTest.getYmd.length === 8) languageTest.getYmd = `${languageTest.getYmd.substring(0,4)}.${languageTest.getYmd.substring(4,6)}.${languageTest.getYmd.substring(6)}`
                            })

                            if(newCertificates.length > 0 && newLanguageTests.length > 0 && newForeignLanguages.length > 0) {
                                let isCompleted = true;
                                for (let i = 0; i < newCertificates.length; i++) {
                                    const { completed } = newCertificates[i];
                                    if( completed === 'N') {
                                        isCompleted = false;
                                    }
                                }
                                for (let i = 0; i < newLanguageTests; i++) {
                                    const { completed } = newLanguageTests[i];
                                    if( completed === 'N') {
                                        isCompleted = false;
                                    }
                                }
                                if( isCompleted ) {
                                    tabList.value[currentTabIndex.value].completed = true;
                                } else {
                                    tabList.value[currentTabIndex.value].completed = false;
                                }
                            } else {
                                tabList.value[currentTabIndex.value].completed = false;
                            }
                            tabList.value[currentTabIndex.value].closed = !certForeignOpenYn;
                        }
                    }).catch( (error) =>  {
                    loadingStore.loading = false;
                    console.log(error);
                });
            }

        } else if(currentTabIndex.value === 5) { // 업무경험
            const { jobHistOpenYn, jobHistorys } = recordDetail.value;
            // console.log(jobHistOpenYn);
            // console.log(jobHistorys);
            let isInvalid = false;
            inputErrors.value = [];

            const jobHistorysTemp = [];
            for(let i=0; i<jobHistorys.length; i++) {
                jobHistorys[i].completed = 'Y';
                inputErrors.value[i] = {};
                const nowYm = moment().format("YYYYMM")
                const startYmValue = getInputValue(`.jobHistoryStartYm${i}`, jobHistorys[i].startYm);
                const endYmValue = getInputValue(`.jobHistoryEndYm${i}`, jobHistorys[i].endYm);

                if(startYmValue) {
                    const startYm = moment(startYmValue.replace('.', '')).format("YYYYMM")
                    if(startYmValue.length !== 7) {
                        inputErrors.value[i].startYm = '기간을 YYYY.MM 형식으로 입력하세요.';
                        isInvalid = true;
                    } else if(startYm === 'Invalid date' || // 존재하지 않은 기간
                        Number(nowYm) < Number(startYm) // 미래 기간
                    ) {
                        inputErrors.value[i].startYm = '올바른 기간을 입력하세요.';
                        isInvalid = true;
                    }
                } else {
                    jobHistorys[i].completed = 'N';
                }
                if(endYmValue) {
                    const endYm = moment(endYmValue.replace('.', '')).format("YYYYMM")
                    if(endYmValue.length !== 7) {
                        inputErrors.value[i].startYm = '기간을 YYYY.MM 형식으로 입력하세요.';
                        isInvalid = true;
                    } else if(endYm === 'Invalid date' || // 존재하지 않은 기간
                        Number(nowYm) < Number(endYm) // 미래 기간
                    ) {
                        inputErrors.value[i].startYm = '올바른 기간을 입력하세요.';
                        isInvalid = true;
                    }
                }

                const { companyName, departName, jobTitle, startYm, endYm, tenureYn, jobNote} = jobHistorys[i];
                const trimCompanyName = companyName.trim();
                const trimDepartName = departName.trim();
                const trimJobTitle = jobTitle.trim();
                const trimJobNote = jobNote.trim();

                if( !(trimCompanyName && trimDepartName && trimJobTitle && trimJobNote) ) {
                    jobHistorys[i].completed = 'N';
                } else { // 위의 모든 항목이 다 채워졌을 경우,
                    if( !tenureYn ) { // 재직중이 아니면,
                        if( !endYm ) {
                            jobHistorys[i].completed = 'N';
                        }
                    }
                }

                const isEmpty = !companyName && !departName && !jobTitle && !startYmValue && !endYmValue && !tenureYn && !jobNote;
                if(jobHistorys[i].jobHistSeq !== 0) { // 기존
                    if(isEmpty) {
                        jobHistorys[i].deleteYn = 'Y';
                    }
                    jobHistorysTemp.push(jobHistorys[i]);
                } else { // 새 업무 경험
                    if(!isEmpty) {
                        jobHistorysTemp.push(jobHistorys[i]);
                    }
                }
            }

            const newJobHistorys = [..._.cloneDeep(jobHistorysTemp), ...deletedJobHistorys];
            _.each(newJobHistorys, (jobHistory, index) => {
                jobHistory.startYm = jobHistory.startYm.replace('.','');
                jobHistory.endYm = jobHistory.endYm.replace('.','');
                jobHistory.tenureYn = jobHistory.tenureYn ? 'Y' : 'N'
                jobHistory.order = index;
            })
            // console.log(newJobHistorys)

            if(!isInvalid) {
                loadingStore.loading = true;
                mypageRecordService.updateJobHistory(jobHistOpenYn, newJobHistorys)
                    .then((res) => {
                        loadingStore.loading = false;
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            $toast("", "저장되었습니다");

                            recordDetail.value.jobHistorys = result.jobHistorys;
                            _.each(recordDetail.value.jobHistorys, jobHistory => {
                                jobHistory.tenureYn = jobHistory.tenureYn === 'Y';
                                if(jobHistory.startYm.length === 6) jobHistory.startYm = `${jobHistory.startYm.substring(0,4)}.${jobHistory.startYm.substring(4)}`
                                if(jobHistory.endYm.length === 6) jobHistory.endYm = `${jobHistory.endYm.substring(0,4)}.${jobHistory.endYm.substring(4)}`
                            })
                            original_record.value.jobHistorys = _.cloneDeep(recordDetail.value.jobHistorys);

                            if(_.isEmpty(recordDetail.value.jobHistorys)) {
                                addJobHistory();
                            }

                            deletedJobHistorys = [];

                            if(newJobHistorys.length > 0) {
                                let isCompleted = true;
                                for (let i = 0; i < newJobHistorys.length; i++) {
                                    const { completed } = newJobHistorys[i]
                                    if( completed === 'N') {
                                        isCompleted = false;
                                    }
                                }
                                if ( isCompleted ) {
                                    tabList.value[currentTabIndex.value].completed = true;
                                } else {
                                    tabList.value[currentTabIndex.value].completed = false;
                                }
                            } else {
                                tabList.value[currentTabIndex.value].completed = false;
                            }
                            tabList.value[currentTabIndex.value].closed = !jobHistOpenYn;
                        }
                    }).catch( (error) =>  {
                    loadingStore.loading = false;
                    // console.log(error);
                });
            } else {
                $toast("error", "입력된 내용이 올바른지 확인해 주세요.");
            }

        } else if(currentTabIndex.value === 6) { // 병역
            const { milOpenYn, milServiceGb } = recordDetail.value;
            if(!milServiceGb) {
                $toast("error", "입력된 내용이 없습니다.");
                return;
            }
            // console.log(milOpenYn);
            // console.log(milServiceGb);

            loadingStore.loading = true;
            mypageRecordService.updateMilService(milOpenYn, milServiceGb)
                .then((res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast("", "저장되었습니다");

                        if(milServiceGb) {
                            tabList.value[currentTabIndex.value].completed = true;
                        }
                        tabList.value[currentTabIndex.value].closed = !milOpenYn;
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }

    }

    const goResume = () => {
        // const recordStore = useRecordStore()
        // let record = _.cloneDeep(recordDetail.value);
        // // console.log(globals);
        // // console.log(record);
        // recordStore.record = record;
        // router.push({name: 'MyPageResume'});

        // 이력서 팝업 오픈
        resumeModal.value.retrieveRecordDetail();
        const result = resumeModal.value.show();
    }

    const setNotDeleted = (array) => {
        return _.filter(array, el => el.deleteYn !== 'Y');
    }


    const checkProgressClass = () => {
        const length = _.filter(tabList.value, {completed: true}).length;
        return length === 0 ? '' : `step${length}`;
    }

    const getInputValue = (selector, defaultValue) => {
        try {
            const container = document.querySelector(selector); // 한글입력시 v-model에 값이 안들어와서 dom으로 처리
            const input = container.querySelector('input');
            return input.value;
        } catch(e){
            return defaultValue;
        }
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content">
                    <div class="floating-bar">
                        <p>이력서 완성도</p>
                        <div class="progress-bar">
                            <div>
                                <span :class="checkProgressClass()"></span>
                            </div>
                        </div>
                        <ul class="record-list">
                            <li :class="{'on': tab.active, 'complete': tab.completed, 'closed': tab.closed, 'complete-closed': tab.completed && tab.closed }" v-for="(tab, index) in tabList" @click="changeTab(index)">
                                {{ tab.title }}
                                <span v-if="index===2 && original_record.eduHistoris.length > 0">({{ original_record.eduHistoris.length}})</span>
                                <span v-else-if="index===3 && original_record.portfolios.length > 0">({{ original_record.portfolios.length}})</span>
                                <span v-else-if="index===4 && (original_record.certificates.length + original_record.languageTests.length + original_record.foreignLanguages.length) > 0">({{ (original_record.certificates.length + original_record.languageTests.length + original_record.foreignLanguages.length) }})</span>
                                <span v-else-if="index===5 && original_record.jobHistorys.length > 0">({{ original_record.jobHistorys.length}})</span>
                            </li>
                        </ul>
                    </div>
                    <div class="record-wrap">
                        <div class="sub-title">
                            <h3>이력 관리</h3>
                            <p><span>*표시는 필수 입력사항</span>입니다.</p>
                        </div>
                        <!-- s: 기본 정보 -->
                        <div class="content-inner" v-show="currentTabIndex === 0">
                            <div class="content-title">
                                <h4>기본 정보</h4>
                            </div>
                            <div class="content-item">
                                <div class="content-sub-title">
                                    <h5>내정보</h5>
                                </div>
                                <KSAInput class="text-box" v-model="recordDetail.userName" label="이름" disabled/>
                                <KSAInput class="text-box" v-model="recordDetail.phoneNumDisplay" label="핸드폰 번호" disabled/>
                                <KSAInput class="text-box" v-model="recordDetail.email" label="이메일" disabled/>
                                <div class="text-box">
                                    <p class="label mark">거주 지역</p>
                                    <KSASelectResidenceArea
                                        class="select"
                                        ref="residenceAreaRef"
                                        @selectOption1="onSelectedArea1Option"
                                        @selectOption2="onSelectedArea2Option"
                                    />
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="content-item">
                                <div class="content-sub-title">
                                    <h5>희망조건</h5>
                                </div>
                                <div class="text-box">
                                    <p class="label" :class="{'mark': isStudent}" style="margin: 0">희망 직무 (최대 3개)</p>
                                    <ul class="grid category-wrap">
                                        <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                            {{ desiredJob.label }}
                                        </li>
                                    </ul>
                                    <p>
                                        <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                                    </p>
                                </div>
                                <div class="text-box">
                                    <p class="label mark" style="margin: 0">관심 카테고리 (최대 3개)</p>
                                    <ul class="grid category-wrap">
                                        <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                            {{ interest.label }}
                                        </li>
                                    </ul>
                                    <p>
                                        <small style="color:#E71414;">{{ inputError.interests }}</small>
                                    </p>
                                </div>
                                <div class="text-box">
                                    <p class="label">희망 연봉</p>
                                    <div class="grid half">
                                        <KSAInputNumber v-model="recordDetail.desiredSalary" maxLength="6"/>
                                        <span class="ml-xs">만원 이상</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- e: 기본 정보 -->

                        <!-- s : 소개 -->
                        <div class="content-inner" v-show="currentTabIndex === 1">
                            <div class="content-title grid">
                                <h4>소개</h4>
                                <KSACheckBox class="ml-sm" label="공개" v-model="recordDetail.introOpenYn" />
                            </div>
                            <div class="content-item introduction">
                                <div class="text-box">
                                    <KSAInput v-model="recordDetail.introAline" label="한 줄 소개" :maxLength="20" />
                                    <span v-if="recordDetail.introAline !== undefined && recordDetail.introAline !== null" class="num-limit">{{ recordDetail.introAline.length }}자<i>/20자</i></span>
                                </div>
                                <div class="text-box">
                                    <p class="label">자기 소개 (역량 중심)</p>
                                    <KSATextArea class="textarea-box" v-model="recordDetail.selfIntro" placeholder="역량 중심으로 작성하세요." max-length="2000" />
                                </div>
                            </div>
                        </div>
                        <!-- e : 소개 -->

                        <!-- s : 학력 -->
                        <div class="content-inner" v-show="currentTabIndex === 2">
                            <div class="content-title grid">
                                <h4>학력</h4>
                                <KSACheckBox class="ml-sm" label="공개" v-model="recordDetail.eduHistOpenYn" />
                            </div>
                            <div class="btn-wrap text-right">
                                <KSASecondaryButton class="gray_button add_btn" text="추가" @click="addEduHistory" />
                            </div>
                            <div class="content-item gray-bg" v-for="(eduHistory, index) in recordDetail.eduHistoris" @click="currentEduHistIndex = index">
                                <div class="content-sub-title">
                                    <h5>학교</h5>
                                </div>
                                <div class="control">
                                    <div class="up" :class="{'active': index > 0 && recordDetail.eduHistoris.length > 1}" @click="moveUpEduHistory(index)"><i></i></div>
                                    <div class="down" :class="{'active': index < recordDetail.eduHistoris.length-1 && recordDetail.eduHistoris.length > 1}" @click="moveDownEduHistory(index)"><i></i></div>
                                    <div class="delete" @click="deleteEduHistory(eduHistory, index)"><i></i></div>
                                </div>
                                <div class="text-box">
                                    <p class="label" :class="{'mark': isStudent}">학력</p>
                                    <KSASelect ref="schoolGbRefs" class="select" v-model="eduHistory.schoolGb" :options="$gb.SCHOOL_GB"/>
                                    <p v-if="inputErrors[index] && inputErrors[index].schoolGb">
                                        <small style="color:#E71414;">{{ inputErrors[index].schoolGb }}</small>
                                    </p>
                                </div>
                                <KSAInputSchoolSelect
                                    label="학교"
                                    class="text-box"
                                    :class="{'mark': isMarked(eduHistory)}"
                                    v-model="eduHistory.schoolName"
                                    placeholder="학교를 입력하세요."
                                    :msg="(inputErrors[index] && inputErrors[index].schoolName) ? inputErrors[index].schoolName: ''"
                                    msg-color="#E71414"
                                    :disabled="isDisabled(eduHistory)"
                                />
                                <div class="text-box">
                                    <p class="label" :class="{'mark': isMarked(eduHistory)}">전공</p>
                                    <div class="box-inner">
                                        <div class="grid half">
                                            <KSAInput class="input" placeholder="주전공" disabled />
                                            <KSASelect ref="majorGbRefs" class="select ml-xs" v-model="eduHistory.majorGb" :options="$gb.MAJOR_GB" :disabled="isDisabled(eduHistory)"/>
                                        </div>
                                        <KSAInput class="mt-xs" v-model="eduHistory.majorName" placeholder="전공 학과" max-length="200" :msg="(inputErrors[index] && inputErrors[index].majorName) ? inputErrors[index].majorName : ''" :disabled="isDisabled(eduHistory)"/>
                                    </div>
                                    <button v-if="!(eduHistory.openSubMajor || eduHistory.submajorName !== '')" class="text-btn" @click="addSubMajor(eduHistory, index)"  :disabled="isDisabled(eduHistory)">+ 전공 추가</button>
                                </div>
                                <div class="text-box" v-show="eduHistory.openSubMajor || eduHistory.submajorName !== ''">
                                    <p class="label" :class="{'mark': isStudent}">전공</p>
                                    <div class="box-inner">
                                        <button class="text-btn delete" @click="deleteSubMajor(eduHistory);">삭제</button>
                                        <div class="grid half">
                                            <KSASelect ref="submajorTypeGbRefs" class="select" v-model="eduHistory.submajorTypeGb" :options="$gb.SUBMAJOR_TYPE_GB"/>
                                            <KSASelect ref="submajorGbRefs" class="select ml-xs" v-model="eduHistory.submajorGb" :options="$gb.MAJOR_GB"/>
                                        </div>
                                        <KSAInput class="mt-xs" v-model="eduHistory.submajorName" placeholder="전공 학과" max-length="200" :msg="(inputErrors[index] && inputErrors[index].submajorName) ? inputErrors[index].submajorName : ''" />
                                    </div>
                                    <button class="text-btn" disabled>+ 전공 추가</button>
                                </div>
                                <div class="text-box">
                                    <p class="label" :class="{'mark': isStudent}">기간</p>
                                    <div class="grid half">
                                        <KSAInputDateYYYYMM :class="`input eduHistStartYm${index}`" v-model="eduHistory.startYm" />
                                        <span style="margin: 0 4px">~</span>
                                        <KSAInputDateYYYYMM :class="`input eduHistEndYm${index}`" v-model="eduHistory.endYm" :disabled="eduHistory.eduTenureGb === '03'"/>
                                    </div>
                                    <p v-if="inputErrors[index] && inputErrors[index].startYm">
                                        <small style="color:#E71414;">{{ inputErrors[index].startYm }}</small>
                                    </p>
                                </div>
                                <div class="text-box">
                                    <p class="label" :class="{'mark': isMarked(eduHistory)}">상태</p>
                                    <KSASelect
                                        ref="eduTenureGbRefs"
                                        class="select"
                                        :options="$gb.EDU_TENURE_GB"
                                        v-model="eduHistory.eduTenureGb"
                                        :disabled="isDisabled(eduHistory)"
                                    />
                                    <p v-if="inputErrors[index] && inputErrors[index].eduTenureGb">
                                        <small style="color:#E71414;">{{ inputErrors[index].eduTenureGb }}</small>
                                    </p>
                                </div>
                                <div class="text-box">
                                    <p class="label" :class="{'mark': isMarked(eduHistory)}">평점</p>
                                    <div class="grid half">
                                        <KSAInputScore :class="`input eduHistScore${index}`" v-model="eduHistory.score" :disabled="isDisabled(eduHistory)" />
                                        <KSASelect ref="fullScoreGbRefs" class="select ml-xs" :options="$gb.FULL_SCORE_GB" v-model="eduHistory.fullScoreGb" :disabled="isDisabled(eduHistory)"/>
                                    </div>
                                    <p v-if="inputErrors[index] && inputErrors[index].score">
                                        <small style="color:#E71414;">{{ inputErrors[index].score }}</small>
                                    </p>
                                </div>
                                <div class="text-box">
                                    <p class="label" :class="{'mark': isMarked(eduHistory)}">성적증명서</p>
                                    <div class="box-inner">
                                        <button v-if="eduHistory.transcriptName" class="text-btn delete" @click="deleteTranscriptFile(eduHistory)">삭제</button>
                                        <div class="grid file">
                                            <KSAPrimaryButton text="파일 선택" @click="uploadFileWithTranscript(eduHistory)" :disabled="isDisabled(eduHistory)"/>
                                            <KSAInput class="input" v-model="eduHistory.transcriptName" :disabled="true" />
                                        </div>
                                        <p v-if="inputErrors[index] && inputErrors[index].transcriptName">
                                            <small style="color:#E71414;">{{ inputErrors[index].transcriptName }}</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- e : 학력 -->

                        <!-- s : 포트폴리오 -->
                        <div class="content-inner" v-show="currentTabIndex === 3">
                            <div class="content-title grid">
                                <h4>포트폴리오 <small>(수상작, 논문, 프로젝트 결과물)</small></h4>
                                <KSACheckBox class="ml-sm" label="공개" v-model="recordDetail.portfolioOpenYn" />
                            </div>
                            <div class="btn-wrap text-right">
                                <KSASecondaryButton class="gray_button add_btn" text="추가" @click="addPortfoilo" />
                            </div>
                            <div class="content-item gray-bg" v-for="(portfolio, index) in recordDetail.portfolios">
                                <div class="content-sub-title">
                                    <h5>내역</h5>
                                </div>
                                <div class="control">
                                    <div class="up" :class="{'active': index > 0 && recordDetail.portfolios.length > 1}" @click="moveUpPortfolio(index)"><i></i></div>
                                    <div class="down" :class="{'active': index < recordDetail.portfolios.length-1 && recordDetail.portfolios.length > 1}" @click="moveDownPortfolio(index)"><i></i></div>
                                    <div class="delete" @click="deletePortfolio(portfolio, index)"><i></i></div>
                                </div>
                                <div class="text-box">
                                    <p class="label">구분</p>
                                    <template v-for="pofolGb in $gb.POFOL_GB">
                                        <KSARadioButton style="margin-right: 40px" :name="'pofolGb' + index" :label="pofolGb.label" v-model="portfolio.pofolGb" :value="pofolGb.value" />
                                    </template>
                                </div>
                                <KSAInput class="text-box" v-model="portfolio.pofolName" label="이름" placeholder="이름을 입력하세요."/>
                                <KSAInput class="text-box" v-model="portfolio.pofolOrgan" label="소속/기관" placeholder="이름을 입력하세요."/>

                                <div class="text-box">
                                    <p class="label">기간</p>
                                    <div class="grid half">
                                        <KSAInputDateYYYYMM :class="`input pofolStartYm${index}`" v-model="portfolio.startYm"/>
                                        <span v-if="portfolio.pofolGb !== '01' && portfolio.pofolGb !== '04'" style="margin: 0 4px">~</span>
                                        <KSAInputDateYYYYMM v-if="portfolio.pofolGb !== '01' && portfolio.pofolGb !== '04'" :class="`input pofolEndYm${index}`" v-model="portfolio.endYm"/>
                                    </div>
                                    <p v-if="inputErrors[index] && inputErrors[index].startYm">
                                        <small style="color:#E71414;">{{ inputErrors[index].startYm }}</small>
                                    </p>
                                </div>
                                <div class="text-box" v-if="portfolio.pofolGb !== '01'">
                                    <p class="label">내용</p>
                                    <KSATextArea
                                        v-model="portfolio.pofolContent"
                                        :placeholder="portfolio.pofolGb === '02' ? '활동 내용을 작성하세요.' : (portfolio.pofolGb === '03' ? '이수한 교육 과정을 작성하세요.' : '내용을 작성하세요.')" />
                                </div>

                                <div class="text-box">
                                    <p class="label">증빙문서</p>
                                    <div class="box-inner" v-for="(portfolioAttach, index) in portfolio.portfolioAttachs">
                                        <button class="text-btn delete" @click="deletePortfolioAttach(portfolio, index)">삭제</button>
                                        <div class="grid file">
                                            <KSAPrimaryButton text="파일 선택" @click="uploadFileWithPortfolio(portfolioAttach)" />
                                            <KSAInput class="input" v-model="portfolioAttach.fileName" :disabled="true"/>
                                        </div>
                                    </div>
                                    <button class="text-btn" @click="addPortfoliAttach(portfolio)">+ 파일 추가</button>
                                </div>
                            </div>
                        </div>
                        <!-- e : 포트폴리오 -->

                        <!-- s : 자격증 및 외국어 -->
                        <div class="content-inner" v-show="currentTabIndex === 4">
                            <div class="content-title grid">
                                <h4>자격증 및 외국어</h4>
                                <KSACheckBox class="ml-sm" label="공개" v-model="recordDetail.certForeignOpenYn" />
                            </div>
                            <div class="btn-wrap text-right">
                                <KSASecondaryButton class="gray_button add_btn" text="추가" @click="addCertificate" />
                            </div>
                            <div class="content-item gray-bg" v-for="(certificate, index) in recordDetail.certificates">
                                <div class="content-sub-title">
                                    <h5>자격증</h5>
                                </div>
                                <div class="control">
                                    <div class="up" :class="{'active': index > 0 && recordDetail.certificates.length > 1}" @click="moveUpCertificate(index)"><i></i></div>
                                    <div class="down" :class="{'active': index < recordDetail.certificates.length-1 && recordDetail.certificates.length > 1}" @click="moveDownCertificate(index)"><i></i></div>
                                    <div class="delete" @click="deleteCertificate(certificate, index)"><i></i></div>
                                </div>
                                <KSAInput class="text-box" v-model="certificate.certName" label="자격증 이름" placeholder="자격증 이름을 입력하세요."/>
                                <KSAInput class="text-box" v-model="certificate.certOrgan" label="발급 기관" placeholder="발급 기관을 입력하세요."/>
                                <KSAInput :class="`text-box certGetYy${index}`" v-model="certificate.getYy" label="취득연도" placeholder="YYYY" max-length="4"/>
                                <p v-if="inputErrors[0] && inputErrors[0][index] && inputErrors[0][index].getYy">
                                    <small style="color:#E71414;">{{ inputErrors[0][index].getYy }}</small>
                                </p>
                            </div>
                            <hr>
                            <div class="btn-wrap text-right">
                                <KSASecondaryButton class="gray_button add_btn" text="추가" @click="addLanguageTest" />
                            </div>
                            <div class="content-item gray-bg" v-for="(languageTest, index) in recordDetail.languageTests">
                                <div class="content-sub-title">
                                    <h5>어학 시험</h5>
                                </div>
                                <div class="control">
                                    <div class="up" :class="{'active': index > 0 && recordDetail.languageTests.length > 1}" @click="moveUpLanguageTest(index)"><i></i></div>
                                    <div class="down" :class="{'active': index < recordDetail.languageTests.length-1 && recordDetail.languageTests.length > 1}" @click="moveDownLanguageTest(index)"><i></i></div>
                                    <div class="delete" @click="deleteLanguageTest(languageTest, index)"><i></i></div>
                                </div>
                                <div class="text-box">
                                    <p class="label">언어</p>
                                    <KSASelect ref="langGbRefs" class="select" v-model="languageTest.langGb" :options="$gb.LANG_GB || []"/>
                                </div>
                                <KSAInput class="text-box" v-model="languageTest.testType" label="시험 종류" placeholder="시험 종류를 입력하세요."/>
                                <KSAInput class="text-box" v-model="languageTest.testScore" label="점수" placeholder="점수를 입력하세요."/>
                                <KSAInputDateYYYYMM :class="`text-box languageTestGetYmd${index}`" v-model="languageTest.getYmd" label="취득일" format="YYYYMMDD"/>
                                <p v-if="inputErrors[1] && inputErrors[1][index] && inputErrors[1][index].getYmd">
                                    <small style="color:#E71414;">{{ inputErrors[1][index].getYmd }}</small>
                                </p>
                            </div>
                            <hr>
                            <div class="content-item gray-bg">
                                <div class="content-sub-title">
                                    <h5>언어</h5>
                                </div>
                                <div class="text-box">
                                    <div class="box-inner" v-for="(foreignLanguage, index) in recordDetail.foreignLanguages">
                                        <button class="text-btn delete" @click="deleteForeignLanguage(foreignLanguage, index)">삭제</button>
                                        <div class="grid half">
                                            <KSAInput class="input" v-model="foreignLanguage.foreignLangName" placeholder="언어명" />
                                            <KSASelect ref="langLevelGbRefs" class="select ml-xs" :options="$gb.LANG_LEVEL_GB" v-model="foreignLanguage.langLevelGb"/>
                                        </div>
                                    </div>
                                    <button class="text-btn" @click="addForeignLanguage">+ 언어 추가</button>
                                </div>
                            </div>
                        </div>
                        <!-- e : 자격증 및 외국어 -->

                        <!-- s : 업무 경험 -->
                        <div class="content-inner" v-show="currentTabIndex === 5">
                            <div class="content-title grid">
                                <h4>업무 경험</h4>
                                <KSACheckBox class="ml-sm" label="공개" v-model="recordDetail.jobHistOpenYn" />
                            </div>
                            <div class="btn-wrap text-right">
                                <KSASecondaryButton class="gray_button add_btn" text="추가" @click="addJobHistory" />
                            </div>
                            <div class="content-item gray-bg work" v-for="(jobHistory, index) in recordDetail.jobHistorys">
                                <div class="content-sub-title">
                                    <h5>경력</h5>
                                </div>
                                <div class="control">
                                    <div class="up" :class="{'active': index > 0 && recordDetail.jobHistorys.length > 1}" @click="moveUpJobHistory(index)"><i></i></div>
                                    <div class="down" :class="{'active': index < recordDetail.jobHistorys.length-1 && recordDetail.jobHistorys.length > 1}" @click="moveDownJobHistory(index)"><i></i></div>
                                    <div class="delete" @click="deleteJobHistory(jobHistory, index)"><i></i></div>
                                </div>
                                <KSAInput class="text-box" v-model="jobHistory.companyName" label="회사 이름" placeholder="회사 이름을 입력하세요."/>
                                <div class="text-box">
                                    <p class="label">부서 및 직급</p>
                                    <div class="grid half">
                                        <KSAInput class="input" v-model="jobHistory.departName" placeholder="부서" />
                                        <KSAInput class="input ml-xs" v-model="jobHistory.jobTitle" placeholder="직급/직책" />
                                    </div>
                                </div>
                                <div class="text-box">
                                    <p class="label">기간</p>
                                    <div class="grid half mb-xs">
                                        <KSAInputDateYYYYMM :class="`input jobHistoryStartYm${index}`" v-model="jobHistory.startYm"/>
                                        <span style="margin: 0 4px">~</span>
                                        <KSAInputDateYYYYMM ref="jobHistoryEndYmRef" :class="`input jobHistoryEndYm${index}`" v-model="jobHistory.endYm" :disabled="jobHistory.tenureYn" />
                                    </div>
                                    <p v-if="inputErrors[index] && inputErrors[index].startYm">
                                        <small style="color:#E71414;">{{ inputErrors[index].startYm }}</small>
                                    </p>
                                    <KSACheckBox label="재직중" v-model="jobHistory.tenureYn" @click="onClickJobHistTenureYn(jobHistory, index)"/>
                                </div>
                                <div class="text-box">
                                    <p class="label">내용</p>
                                    <KSATextArea class="textarea-box" v-model="jobHistory.jobNote" placeholder="역할과 성과 위주의 업무 내용을 작성하세요." max-length="2000" />
                                </div>
                            </div>
                        </div>
                        <!-- e : 업무 경험 -->

                        <!-- s : 병역 -->
                        <div class="content-inner" v-show="currentTabIndex === 6">
                            <div class="content-title grid">
                                <h4>병역</h4>
                                <KSACheckBox class="ml-sm" label="공개" v-model="recordDetail.milOpenYn" />
                            </div>
                            <div class="text-box">
                                <p class="label" :class="{'mark': isStudent}">병역</p>
                                <template v-for="milService in $gb.MIL_SERVICE_GB">
                                    <KSARadioButton style="margin-right: 40px" name="milServiceGb" :label="milService.label" v-model="recordDetail.milServiceGb" :value="milService.value" />
                                </template>
                            </div>
                        </div>
                        <!-- e : 병역 -->
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-wrap bottom-btn text-right">
            <div class="inner">
                <KSATextButton class="mr-sm" text="내 이력서 보기" @click="goResume" />
                <KSAPrimaryButton text="이력서 저장" @click="saveRecord" :disabled="saveIsDisabled"/>
            </div>
        </div>
    </div>
    <ResumeModal ref="resumeModal"/>
</template>

<style lang="scss" scoped>

    @import "../../../../assets/scss/theme";

    hr {
        border: 0;
        height: 1px;
        background-color: $gray-regular_color;
        margin: 40px 0;
    }

    .container {
        padding-bottom: 0;
        .content {
            display: flex;
            flex-direction: row-reverse;
        }
    }

</style>