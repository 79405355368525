import http from '../../../../api/http';
import { useUserStore } from "../../stores/user";

function retrieveAllPaging(paramObj) {
    return http.post('/lecture/u/retrieveAllPaging', {
        lectGb: paramObj.lectGb || "00",
        lectOpenYn: paramObj.lectOpenYn, // "A",
        repreLectYn: paramObj.repreLectYn, // "N",
        lectName: paramObj.lectName, // "string",
        searchDurationGb: paramObj.searchDurationGb || "C",
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string",
        regTermYn: paramObj.regTermYn || '',
        pageNumber: paramObj.pageNo || 1,
        size: paramObj.size || 30,
    });
}

function retrieveLectureDetail(lectId) { // 오픈형 과정 상세조회
    return http.get(`/lecture/u/retrieveByLectId?lectId=${lectId}`);
}

function retrieveLectureDetailByUuid(lectUuid) { // 비공개 과정 상세조회
    return http.get(`/lecture/u/retrieveByLectUuid?lectUuid=${lectUuid}`);
}

function retrieveMyLectureDetail(paramObj) { //  수강현황의 과정 상세조회
    return http.post('/lecture/u/retrieveByLectIdAndUserId',{
        lectId: paramObj.lectId,
        userId: paramObj.userId,
        regDt: paramObj.regDt,
    });
}

function retrieveLectureAllIndex(lectId) {
    return http.get(`/lecture/u/retrieveAllIndexByLectId?lectId=${lectId}`);
}

function retrieveLectureAllIndexByUuid(lectUuid) {  // UUID
    return http.get(`/lecture/u/retrieveAllIndexByLectUuid?lectUuid=${lectUuid}`);
}


function retrieveUserLectureAllIndex(lectId, userId) {
    return http.get(`/lecture/u/retrieveAllIndexByLectIdAndUserId?lectId=${lectId}&userId=${userId}`);
}

function checkUserLectureSelByLectId(lectId) {
    return http.get(`/lecture/u/checkUserLectureSelByLectId?lectId=${lectId}`);
}

function requestUserLectureSelByLectId(lectId) {
    const { user } = useUserStore()
    return http.post('/lecture/u/requestUserLectureSelByLectId', {
        lectId: lectId,
        userId: user.userId,
    });
}

function deleteUserLectureByLectId(lectId, regDt) {
    const { user } = useUserStore()
    return http.post('/lecture/u/requestUserLectureDeleteByLectId', {
        lectId: lectId,
        userId: user.userId,
        regDt: regDt,
    });
}

function retrieveMyLectureListByLectGb(lectGb) {
    return http.get(`/lecture/u/retrieveUserLectureList?lectGb=${lectGb || ''}`);
}

function retrieveMyLectureListByCreatId(lectGb) {
    return http.get(`/lecture/u/retrieveUserLectureListByCreateId?lectGb=${lectGb || ''}`);
}

function retrieveMyLecutreUserList(parmaObj){
    return http.post('/lecture/u/retrieveAllUserLectureSelByLectId', {
        pageNumber: parmaObj.pageNo || 0,
        size: parmaObj.size ||30,
        lectId: parmaObj.lectId, // 0,
        // selYnList: parmaObj.selYnList, //
        // userGbList: parmaObj.userGbList, //
        // progRate: parmaObj.progRate, //
        // finYn: parmaObj.finYn, //
        // searchType: parmaObj.searchType, // "string",
        // searchInput: parmaObj.searchInput, // "string",
        // searchDurationGb: parmaObj.searchDurationGb, // "R",
        // startYmd: parmaObj.startYmd, // "string",
        // endYmd: parmaObj.endYmd, // "string"
    });
}

function retrieveUserLectureStatus(userId, lectId, regDt) { // 수강생 각종 상태 조회
    return http.get(`/lecture/u/retrieveUserLectureStatus?userId=${userId}&lectId=${lectId}&regDt=${regDt}`)
}

function retrieveLectureLearningStatus(userId, lectId, regDt) { // 수강생 학습현황 조회
    return http.get(`/lecture/u/retrieveLectureLearningStatus?userId=${userId}&lectId=${lectId}&regDt=${regDt}`)
}

function getClientIp() {  // 사용자 IP 조회
    return http.get(`/lecture/u/getClientIp`);
}

function checkClientIp(lectId) { // 사용자 IP가 허용 가능한 IP인지 체크
    return http.get(`/lecture/u/checkClientIp?lectId=${lectId}`)
}

function cancelLectureByUser(lectId, regDt) {
    const { user } = useUserStore()
    return http.post('/lecture/u/cancelLectureByUser', {
        lectId: lectId,
        userId: user.userId,
        regDt: regDt,
    });
}

export default {
    retrieveAllPaging, // 목록조회
    retrieveLectureDetail, // 강의 상세조회(인증x)
    retrieveLectureDetailByUuid, // 강의 상세조회(UUID)
    retrieveMyLectureDetail, // 강의 상세조회(수강현황)
    retrieveLectureAllIndex, // 차시 목록조회
    retrieveLectureAllIndexByUuid, // 차시 목록조회(UUID)
    retrieveUserLectureAllIndex, // 차시 목록조회(수강현황>상세)
    checkUserLectureSelByLectId, // 수강신청 체크
    requestUserLectureSelByLectId, // 수강신청 요청
    deleteUserLectureByLectId, // 수강생 수강신청 삭제
    retrieveMyLectureListByLectGb, // 나의 수강내역 조회
    retrieveMyLectureListByCreatId, // 나의 개설강의내역 조회
    retrieveMyLecutreUserList, // 수강생 목록조회(강의자)
    retrieveUserLectureStatus, // 수강생 각종 상태조회
    retrieveLectureLearningStatus, // 수강생 학습현황 조회
    getClientIp,  // 사용자 IP 조회
    checkClientIp, // 사용자 IP가 허용 가능한 IP인지 체크
    cancelLectureByUser, // 사용자가 수강 신청 취소(오프라인 강의만)
};