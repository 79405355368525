<script>
/*
    https://vuejsexamples.com/a-vue-3-component-to-make-pagination-based-on-vuejs-paginate/
    vuejs-paginate-next
 */
export default ({
    data() {
        return {
            innerValue: 1,
        }
    },
    props: {
        modelValue: {
            type: Number
        },
        pageCount: {
            type: Number,
            required: true
        },
        initialPage: {
            type: Number,
            default: 1
        },
        forcePage: {
            type: Number
        },
        clickHandler: {
            type: Function,
            default: () => { }
        },
        pageRange: {
            type: Number,
            default: 10
        },
        marginPages: {
            type: Number,
            default: 0
        },
        prevText: {
            type: String,
            default: '<'
        },
        nextText: {
            type: String,
            default: '>'
        },
        breakViewText: {
            type: String,
            default: '…'
        },
        containerClass: {
            type: String,
            default: 'pagination'
        },
        pageClass: {
            type: String,
            default: 'page-item'
        },
        pageLinkClass: {
            type: String,
            default: 'page-link'
        },
        prevClass: {
            type: String,
            default: 'page-item'
        },
        prevLinkClass: {
            type: String,
            default: 'page-link prev'
        },
        nextClass: {
            type: String,
            default: 'page-item'
        },
        nextLinkClass: {
            type: String,
            default: 'page-link next'
        },
        breakViewClass: {
            type: String
        },
        breakViewLinkClass: {
            type: String
        },
        activeClass: {
            type: String,
            default: 'active'
        },
        disabledClass: {
            type: String,
            default: 'disabled ignore'
        },
        noLiSurround: {
            type: Boolean,
            default: false
        },
        firstLastButton: {
            type: Boolean,
            default: true
        },
        firstButtonText: {
            type: String,
            default: '<<'
        },
        lastButtonText: {
            type: String,
            default: '>>'
        },
        hidePrevNext: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selected: {
            get: function () {
                return this.modelValue || this.innerValue
            },
            set: function (newValue) {
                this.innerValue = newValue
            }
        },
        pages: function () {
            let items = {}
            if (this.pageCount <= this.pageRange) {
                for (let index = 0; index < this.pageCount; index++) {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = page
                }
            } else {
                const halfPageRange = Math.floor(this.pageRange / 2)

                let setPageItem = index => {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }

                    items[index] = page
                }

                let setBreakView = index => {
                    let breakView = {
                        disabled: true,
                        breakView: true
                    }

                    items[index] = breakView
                }

                // 1st - loop thru low end of margin pages
                for (let i = 0; i < this.marginPages; i++) {
                    setPageItem(i);
                }

                // 2nd - loop thru selected range
                let selectedRangeLow = 0;
                if (this.selected - halfPageRange > 0) {
                    selectedRangeLow = this.selected - 1 - halfPageRange;
                }

                let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                if (selectedRangeHigh >= this.pageCount) {
                    selectedRangeHigh = this.pageCount - 1;
                    selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                }

                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
                    setPageItem(i);
                }

                // Check if there is breakView in the left of selected range
                if (selectedRangeLow > this.marginPages) {
                    // setBreakView(selectedRangeLow - 1) // 양쪽 ... 안나오게
                }

                // Check if there is breakView in the right of selected range
                if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                    // setBreakView(selectedRangeHigh + 1) // 양쪽 ... 안나오게
                }

                // 3rd - loop thru high end of margin pages
                for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
                    setPageItem(i);
                }
            }
            return items
        }
    },
    methods: {
        handlePageSelected(selected) {
            if (this.selected === selected) return

            this.innerValue = selected
            this.$emit('update:modelValue', selected)
            this.clickHandler(selected)
        },
        prevPage() {
            if (this.selected <= 1) return

            this.handlePageSelected(this.selected - 1)
        },
        nextPage() {
            if (this.selected >= this.pageCount) return

            this.handlePageSelected(this.selected + 1)
        },
        firstPageSelected() {
            return this.selected === 1
        },
        lastPageSelected() {
            return (this.selected === this.pageCount) || (this.pageCount === 0)
        },
        selectFirstPage() {
            if (this.selected <= 1) return

            this.handlePageSelected(1)
        },
        selectLastPage() {
            if (this.selected >= this.pageCount) return

            this.handlePageSelected(this.pageCount)
        }
    },
    beforeMount() {
        this.innerValue = this.initialPage
    },
    beforeUpdate() {
        if (this.forcePage === undefined) return
        if (this.forcePage !== this.selected) {
            this.selected = this.forcePage
        }
    },
})
</script>

<template>
    <ul :class="containerClass" v-if="!noLiSurround">
        <li v-if="firstLastButton" :class="[pageClass, firstPageSelected() ? disabledClass : '']">
            <a
                @click="selectFirstPage()"
                @keyup.enter="selectFirstPage()"
                :class="[pageLinkClass, 'first']"
                :tabindex="firstPageSelected() ? -1 : 0"
            ></a>
        </li>

        <li
            v-if="!(firstPageSelected() && hidePrevNext)"
            :class="[prevClass, firstPageSelected() ? disabledClass : '']"
        >
            <a
                @click="prevPage()"
                @keyup.enter="prevPage()"
                :class="prevLinkClass"
                :tabindex="firstPageSelected() ? -1 : 0"
            ></a>
        </li>

        <li
            v-for="page in pages"
            :key="page.index"
            :class="[pageClass, page.selected ? activeClass : '', page.disabled ? disabledClass : '', page.breakView ? breakViewClass : '']"
        >
            <a v-if="page.breakView" :class="[pageLinkClass, breakViewLinkClass]" tabindex="0">
                <slot name="breakViewContent">{{ breakViewText }}</slot>
            </a>
            <a v-else-if="page.disabled" :class="pageLinkClass" tabindex="0">{{ page.content }}</a>
            <a
                v-else
                @click="handlePageSelected(page.index + 1)"
                @keyup.enter="handlePageSelected(page.index + 1)"
                :class="pageLinkClass"
                tabindex="0"
            >{{ page.content }}</a>
        </li>

        <li
            v-if="!(lastPageSelected() && hidePrevNext)"
            :class="[nextClass, lastPageSelected() ? disabledClass : '']"
        >
            <a
                @click="nextPage()"
                @keyup.enter="nextPage()"
                :class="nextLinkClass"
                :tabindex="lastPageSelected() ? -1 : 0"
            ></a>
        </li>

        <li v-if="firstLastButton" :class="[pageClass, lastPageSelected() ? disabledClass : '']">
            <a
                @click="selectLastPage()"
                @keyup.enter="selectLastPage()"
                :class="[pageLinkClass, 'last']"
                :tabindex="lastPageSelected() ? -1 : 0"
            ></a>
        </li>
    </ul>

    <div :class="containerClass" v-else>
        <a
            v-if="firstLastButton"
            @click="selectFirstPage()"
            @keyup.enter="selectFirstPage()"
            :class="[pageLinkClass, firstPageSelected() ? disabledClass : '']"
            tabindex="0"
        ></a>
        <a
            v-if="!(firstPageSelected() && hidePrevNext)"
            @click="prevPage()"
            @keyup.enter="prevPage()"
            :class="[prevLinkClass, firstPageSelected() ? disabledClass : '']"
            tabindex="0"
        ></a>
        <template v-for="page in pages">
            <a
                v-if="page.breakView"
                :key="page.index"
                :class="[pageLinkClass, breakViewLinkClass, page.disabled ? disabledClass : '']"
                tabindex="0"
            >
                <slot name="breakViewContent">{{ breakViewText }}</slot>
            </a>
            <a
                v-else-if="page.disabled"
                :key="page.index"
                :class="[pageLinkClass, page.selected ? activeClass : '', disabledClass]"
                tabindex="0"
            >{{ page.content }}</a>
            <a
                v-else
                :key="page.index"
                @click="handlePageSelected(page.index + 1)"
                @keyup.enter="handlePageSelected(page.index + 1)"
                :class="[pageLinkClass, page.selected ? activeClass : '']"
                tabindex="0"
            >{{ page.content }}</a>
        </template>
        <a
            v-if="!(lastPageSelected() && hidePrevNext)"
            @click="nextPage()"
            @keyup.enter="nextPage()"
            :class="[nextLinkClass, lastPageSelected() ? disabledClass : '']"
            tabindex="0"
        ></a>
        <a
            v-if="firstLastButton"
            @click="selectLastPage()"
            @keyup.enter="selectLastPage()"
            :class="[pageLinkClass, lastPageSelected() ? disabledClass : '']"
            tabindex="0"
        ></a>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    ul {
        display: flex;
        width: fit-content;
        margin: 50px auto 30px;
        li {
            display: flex;
            align-items: center;
            width: 30px;
            margin: 0 5px;
            &:hover, &.active {
                color: $blue_color;
                font-weight: 700;
            }
            &.ignore {
                a {
                    background-image: url(../../../assets/images/icon-main.png);
                    background-size: 200px 200px;
                    cursor: default;
                    &.first {
                        background-position: -10px -6px;
                    }
                    &.prev {
                        background-position: -47px -6px;
                    }
                    &.next {
                        background-position: -77px -6px;
                    }
                    &.last {
                        background-position: -106px -6px;
                    }
                }
            }
            a {
                /*display: block;*/
                /*width: 24px;*/
                /*padding: 4px 0;*/
                /*cursor: pointer;*/
                /*text-align: center;*/
                background-image: url(../../../assets/images/icon-main.png);
                background-size: 200px 200px;
                cursor: pointer;
                &.first {
                    width: 10px;
                    height: 10px;
                    background-position: -29px -6px;
                }
                &.prev {
                    width: 6px;
                    height: 10px;
                    background-position: -62px -6px;
                }
                &.next {
                    width: 6px;
                    height: 10px;
                    background-position: -92px -6px;
                }
                &.last {
                    width: 10px;
                    height: 10px;
                    background-position: -125px -6px;
                }
            }
        }
    }
</style>
