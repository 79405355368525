<script setup>
    import { ref, watch, getCurrentInstance } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import adminUserService from "../../services/user/adminUserService";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import _ from 'lodash'

    const router = useRouter()
    const loadingStore = usePageLoadingStore()

    const { $regex } = getCurrentInstance().appContext.config.globalProperties;

    const userGbGroup = ref([
        {label: '슈퍼 관리자', value: 'SUPER_ADMIN'},
        {label: '일반 관리자', value: 'ADMIN'},
    ])

    const menuAccessGroup = ref([
        {label: '회원 관리', value: 'us'}, // user
        {label: '강의 관리', value: 'lt'}, // lecture
        {label: '수강 관리', value: 'cr'}, // course
        {label: '관리자 관리', value: 'am'}, // admin
        {label: '인재 채용 현황', value: 'hr'}, // human Resources
        {label: '장비 수요 현황', value: 'eq'}, // equipment
        {label: '통계 자료', value: 'sc'}, // statistics
        {label: '사이트 관리', value: 'st'}, // site
        {label: '메일/메시지 관리', value: 'mg'}, // messages
        {label: '고객센터', value: 'cs'}, // customer service
    ])

    const input = ref({
        userGb: 'ADMIN',
        userLoginId: '',
        password: '',
        userName: '',
        phoneNum: '',
        email1: '',
        email2: '',
        menuAccessGroup: [],
    })

    const inputError = ref({
        userLoginId: '',
        password: '',
        userName: '',
        phoneNum: '',
        email: '',
        menuAccessGroup: '',
    })

    const menuAccessGroupRef = ref(null)

    const modal =ref(null)
    const modalContent = ref([])

    watch(input, ()=>{
        if(input.value.userGb === 'SUPER_ADMIN') {
            menuAccessGroupRef.value.initOptions();
        }
    }, { deep: true});

    const onBlurEmail = () => {
        const { email1, email2 } = input.value;
        const email = `${email1}@${email2}`;
        if(!$regex.email.test(email)) {
            inputError.value.email = '올바른 이메일 형식이 아닙니다. 다시 입력해 주세요.';
        } else {
            inputError.value.email = '';
        }
    }

    const regUser = () => {
        const req = _.cloneDeep(input.value);
        const { userGb, userLoginId, password, userName, phoneNum, email1, email2, menuAccessGroup } = req;
        let isInvalid = false;
        // console.log(req)
        if(!userLoginId) {
            inputError.value.userLoginId = '영문 소문자,숫자 사용하여 6~20자 이내로 입력하세요.';
            isInvalid = true;
        }
        if(!password) {
            inputError.value.password = '영문 대소문자,숫자,기호 사용하여 8자 이상으로 입력하세요.';
            isInvalid = true;
        }
        if(!userName) {
            inputError.value.userName = '이름을 입력하세요.';
            isInvalid = true;
        }
        if(!phoneNum) {
            inputError.value.phoneNum = '핸드폰 번호를 입력하세요.';
            isInvalid = true;
        } else if (phoneNum.length !== 13) {
            inputError.value.phoneNum = '올바른 핸드폰 번호를 입력하세요.';
            isInvalid = true;
        }
        if(!email1 || !email2) {
            inputError.value.email = '이메일을 입력하세요';
            isInvalid = true;
        }
        if(menuAccessGroup.length === 0) {
            inputError.value.menuAccessGroup = '접근 가능한 메뉴를 선택하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                userGb: userGb,
                userLoginId: userLoginId,
                userLoginPw: password,
                userName: userName,
                phoneNum: phoneNum.replace('-','').replace('-',''),
                email: `${email1}@${email2}`,
                accessMenu: menuAccessGroup.join(','), // string
            }

            loadingStore.loading = true;
            adminUserService.createAdminAccount(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        modalContent.value = ["관리자를 등록하였습니다."];
                        const ok = await modal.value.show();
                        if(ok){
                            router.push({name: 'ManagerList'})
                        }
                    } else {
                        modalContent.value = ["관리자 등록을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalContent.value = res.response.data.result.message.split("\n");
                modal.value.show();
            });
        }
    }

    const goBack = () => {
        router.push({name: 'ManagerList'});
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>관리자 등록</h3>
                    <p><span>* 표시는 필수 입력사항</span> 입니다.</p>
                </div>
                <!-- s: 관리자 기본 정보 -->
                <div class="content-inner">
                    <div class="content-title" style="padding-top: 0;">
                        <h4>관리자 기본 정보</h4>
                    </div>
                    <div class="table-form">
                        <table class="vertical">
                            <colgroup>
                                <col width="200px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th>유형</th>
                                <td>
                                    <template v-for="userGb in userGbGroup">
                                        <KSARadioButton class="mr-md" name="userGb" :label="userGb.label" v-model="input.userGb" :value="userGb.value" />
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">아이디</th>
                                <td>
                                    <KSAInput v-model="input.userLoginId" placeholder="ID 입력하세요." :msg="inputError.userLoginId"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">비밀번호</th>
                                <td>
                                    <KSAInput type="password" v-model="input.password" placeholder="PW 입력하세요." :msg="inputError.password"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">이름</th>
                                <td>
                                    <KSAInput v-model="input.userName" placeholder="이름을 입력하세요." :msg="inputError.userName"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">핸드폰 번호</th>
                                <td>
                                    <KSAInputPhoneNum class="input" v-model="input.phoneNum" label="" :msg="inputError.phoneNum"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="mark">이메일</th>
                                <td>
                                    <KSAFormEmail
                                        :email1="input.email1"
                                        :email2="input.email2"
                                        @update:email1="(value)=>{input.email1 = value}"
                                        @update:email2="(value)=>{input.email2 = value}"
                                        @blur="onBlurEmail"
                                    />
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.email }}</small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- e: 관리자 기본 정보 -->

                <!-- s: 관리자 권환 설정 -->
                <div class="content-inner">
                    <div class="content-title">
                        <h4>관리자 권한 설정</h4>
                        <p>
                           - 관리자가 접근 가능한 메뉴를 체크하세요.<br>
                           - 읽기/등록/수정/삭제 모두 가능합니다.
                        </p>
                    </div>
                    <div class="table-form">
                        <table class="vertical">
                            <colgroup>
                                <col width="200px" />
                                <col />
                            </colgroup>
                            <tr>
                                <th class="mark">메뉴 설정</th>
                                <td>
                                    <KSACheckBoxGroup
                                        ref="menuAccessGroupRef"
                                        className="mr-md"
                                        name="menuAccessGroup"
                                        v-model="input.menuAccessGroup"
                                        :options="menuAccessGroup"
                                        :disabled="input.userGb === 'SUPER_ADMIN'"
                                    />
                                    <div>
                                        <small style="color:#E71414;">{{ inputError.menuAccessGroup }}</small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- e: 관리자 권환 설정 -->

                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="취소" @click="goBack" />
                    <KSAPrimaryButton text="등록" @click="regUser" />
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

</style>