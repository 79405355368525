<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useMailTemplateStore} from "../../stores/mail/mailTemplateStore";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import mailTemplateServcie from "../../services/mail/mailTemplateServcie";
    import _ from "lodash";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const mailTemplateStore = useMailTemplateStore()

    const tabRef = ref(null)

    onMounted(()=>{
        if(mailTemplateStore.msgGb) {
            msgGb.value = mailTemplateStore.msgGb;
            mailTemplateStore.msgGb = '';
            tabRef.value.init(msgGb.value);
            retrieveList()
        } else {
            retrieveList()
        }
    })

    const msgGb = ref('01') // 01 이메일 02 메시지
    const list = ref([])

    const tabOptions = ref([
        { label: '메일', value: '01' },
        { label: '메시지', value: '02' },
    ])

    const retrieveList = () => {
        loadingStore.loading = true;
        mailTemplateServcie.retrieveList(msgGb.value)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    list.value = result;
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const onChangeTab = (option) => {
        msgGb.value = option.value;
        retrieveList();
    }

    const goMsgDetail = (msg) => {
        mailTemplateStore.msgGb = msgGb;
        router.push({name: 'MailSMSTemplateUpdate', params:{id: msg.templateId}});
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>템플릿 관리</h3>
                </div>

                <KSATab class="tab mb-lg" ref="tabRef" :options="tabOptions" @change="onChangeTab"/>

                <div class="list-top">
                    <p>총 게시물 <span>{{ $number(list.length )}}</span>개</p>
                </div>
                <div class="table-form" v-if="msgGb === '01'">
                    <table class="horizontal">
                        <tr>
                            <th>타입</th>
                            <th>메일 제목</th>
                        </tr>
                        <tr v-for="msg in list" @click="goMsgDetail(msg)">
                            <td>{{ $gbNameKR('TEMPATE_TYPE_EMAIL', msg.tempateType) }}</td>
                            <td>{{ msg.title }}</td>
                        </tr>
                    </table>
                </div>
                <div class="table-form" v-else>
                    <table class="horizontal">
                        <tr>
                            <th>타입</th>
                        </tr>
                        <tr v-for="msg in list" @click="goMsgDetail(msg)">
                            <td>{{ $gbNameKR('TEMPATE_TYPE_SMS', msg.tempateType) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .tab {
        grid-template-columns: repeat(2, 1fr);
    }
</style>