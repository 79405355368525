import http from '../../../../api/http'

function retrieveList(msgGb) {
    return http.get(`/message/template/retrieveAll?msgGb=${msgGb}`)
}

function retrieveTemplateDetail(templateId) {
    return http.post('/message/template/retrieve', {
        templateId
    })
}

function updateTemplate(paramObj) {
    return http.post('/message/template/update', {
        templateId: paramObj.templateId,
        msgGb: paramObj.msgGb,
        tempateType: paramObj.tempateType,
        title: paramObj.title,
        content: paramObj.content,
        order: paramObj.order,
        useYn: paramObj.useYn,
    })
}


export default {
    retrieveList,
    retrieveTemplateDetail,
    updateTemplate
}