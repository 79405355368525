<script setup>
    import { ref, computed, defineEmits, defineExpose } from "vue";
    const props = defineProps({
        modelValue: { type: String },
        label: { type: String },
        placeholder: { type: String },
        msg: { type: String },
        msgColor: { type: String },
    });
    const emit = defineEmits(["update:modelValue", "update:msg"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const t = ref({
        timer: null,
        counter: 180,
        displayTxt: "03:00",
    });

    const startTimer = () => {
        if(t.value.timer) clearInterval(t.value.timer);
        t.value.counter = 180;
        t.value.timer = setInterval(() => {
            t.value.counter--; //1초씩 감소
            t.value.displayTxt = prettyTime();
            if (t.value.counter <= 0) stopTimer();
        }, 1000);
        emit("update:msg", "");
    }

    const stopTimer = () => {
        clearTimer();
        emit("update:msg", "인증번호 입력 시간이 모두 지났습니다. 인증번호를 다시 전송하세요.");
    }

    const clearTimer = () => {
        if(t.value.timer) clearInterval(t.value.timer);
        t.value.timer = null;
        t.value.counter = 0;
        t.value.displayTxt = '';
    }

    const prettyTime = () => {
        // 시간 형식으로 변환 리턴
        let time = t.value.counter / 60;
        let minutes = parseInt(time);
        let secondes = Math.round((time - minutes) * 60);
        return (
            minutes.toString().padStart(2, "0") +
            ":" +
            secondes.toString().padStart(2, "0")
        );
    }

    defineExpose({
        startTimer,
        stopTimer,
        clearTimer,
    });


</script>

<template>
    <div class="certify-box">
        <div>
            <label>
                <p v-if="label">{{ label }}</p>
                <input type="text" v-model="model" :placeholder="placeholder || '인증번호 6자리 숫자 입력'" :maxlength="6">
                <p class="time" v-if="t.timer">
                    {{ t.displayTxt }}
                </p>
            </label>
            <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/ksa_input";

    .certify-box {
        position: relative;
        margin-top: 30px;
        label {
            position: relative;
            display: block;
            p {
                @include font-size_xs;
                color: $gray_color;
            }
            .time {
                position: absolute;
                bottom: 3px;
                right: 12px;
            }
        }
        input {
            &:disabled {
                color: $black_color;
                background: $gray-thin_color;
            }
        }
    }
</style>