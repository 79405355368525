import http from '../../../../api/http';

function retrieveAllPaging(paramObj) {
    return http.post('/insights/notice/retrieveAllPaging', {
        boardGb: "NOTICE",
        pageNumber: paramObj.pageNo,
        size: paramObj.size,
        searchType: paramObj.searchType,
        searchInput: paramObj.searchInput,
        startYmd: paramObj.startYmd,
        endYmd: paramObj.endYmd,
    })
}

function retrieveNotifyDetail(boardId) {
    return http.post('/insights/notice/retrieve', {
        boardId
    })
}

function createNotice(paramObj) {
    return http.post('/insights/notice/create', {
        // boardId: 0,
        boardGb: paramObj.boardGb || "NOTICE",
        boardGroup: paramObj.boardGroup, // "string",
        title: paramObj.title, // "string",
        content: paramObj.content, // "string",
        secretYn: paramObj.secretYn || 'N', // "string",
        notifyYn: paramObj.notifyYn || 'N',  // "string",
        answer: paramObj.answer, // "string",
        hitCount: paramObj.hitCount, // 0,
        createId: paramObj.createId, // 0,
        attachList: paramObj.attachList, // [
        //    {
        //         "attachSeq": 0,
        //         "fileName": "string",
        //         "fileSize": "string",
        //         "fileDir": "string",
        //         "deleteYn": "string"
        //     }
        // ]
    })
}

function updateNotice(paramObj) {
    return http.post('/insights/notice/update', {
        boardId: paramObj.boardId, // 0,
        boardGb: paramObj.boardGb || "NOTICE",
        boardGroup: paramObj.boardGroup, // "string",
        title: paramObj.title, // "string",
        notifyYn: paramObj.notifyYn, // "string",
        content: paramObj.content, // "string",
        secretYn: paramObj.secretYn, // "string",
        answer: paramObj.answer, // "string",
        hitCount: paramObj.hitCount, // 0,
        createId: paramObj.createId, // 0,
        attachList: paramObj.attachList, // [
        //    {
        //         "attachSeq": 0,
        //         "fileName": "string",
        //         "fileSize": "string",
        //         "fileDir": "string",
        //         "deleteYn": "string"
        //     }
        // ]
    })
}


function deleteNotice(boardId) {
    return http.post('/insights/notice/delete', {
        boardId
    })
}

export default {
    retrieveAllPaging,
    retrieveNotifyDetail,
    createNotice,
    updateNotice,
    deleteNotice,
};