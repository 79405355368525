<script setup>
    import { ref, onBeforeMount } from 'vue'
    import { useRouter, onBeforeRouteLeave } from 'vue-router'
    import { useSignupStore } from "../../stores/signup";
    import SignupProgressBar from '../../../../components/signup/SignupProgressBar.vue'

    const router = useRouter()
    const signupStore = useSignupStore()

    const isSelectedAll = ref(false)
    const agreeTermYn = ref(false)
    const agreePrivacyYn = ref(false)
    const agreeInfoProvYn = ref(false)

    onBeforeMount(()=>{
        if(!signupStore.signupRequest.userName || !signupStore.signupRequest.phoneNum) {
            router.push({name: 'Signup'})
        } else if(!signupStore.signupRequest.userGb) {
            router.push({name: 'SignupMemberType'})
        }

        if(signupStore.signupRequest.agreeTermYn) {
            agreeTermYn.value = signupStore.signupRequest.agreeTermYn === 'Y';
        }
        if(signupStore.signupRequest.agreePrivacyYn) {
            agreePrivacyYn.value = signupStore.signupRequest.agreePrivacyYn === 'Y';
        }
        if(signupStore.signupRequest.agreeInfoProvYn) {
            agreeInfoProvYn.value = signupStore.signupRequest.agreeInfoProvYn === 'Y';
        }
        isSelectedAll.value = agreeTermYn.value && agreePrivacyYn.value && agreeInfoProvYn.value;
    })

    const confirmModal = ref(null)
    const modalContent = ref([])

    onBeforeRouteLeave(async (to, from, next) => {
        if(to.name !== "Signup" && to.name.startsWith("Signup")) {
            next();
        } else {
            modalContent.value = ["페이지를 나가겠습니까?", "페이지 이동 시 입력한 정보는 저장되지 않습니다"];
            const ok = await confirmModal.value.show();
            if(ok) {
                next();
            }
        }
    })

    const toggleSelectAll = () => {
        agreeTermYn.value = isSelectedAll.value
        agreePrivacyYn.value = isSelectedAll.value
        agreeInfoProvYn.value = isSelectedAll.value
        saveAgreeYnToStore();
    }

    const toggleAgreeTerm = () => {
        isSelectedAll.value = agreeTermYn.value && agreePrivacyYn.value && agreeInfoProvYn.value;
        saveAgreeYnToStore();
    }

    const toggleAgreePrivacy = () => {
        isSelectedAll.value = agreeTermYn.value && agreePrivacyYn.value && agreeInfoProvYn.value;
        saveAgreeYnToStore();
    }

    const toggleAgreeInfoProvYn = () => {
        isSelectedAll.value = agreeTermYn.value && agreePrivacyYn.value && agreeInfoProvYn.value;
        saveAgreeYnToStore();
    }

    const prev = () => {
        router.push({name: 'SignupMemberType'});
    }

    const next = () => {
        saveAgreeYnToStore();
        router.push({name: 'SignupMemberInfo'});
    }

    const saveAgreeYnToStore = () => {
        signupStore.signupRequest.agreeTermYn = agreeTermYn.value ? 'Y' : 'N';
        signupStore.signupRequest.agreePrivacyYn = agreePrivacyYn.value ? 'Y' : 'N';
        signupStore.signupRequest.agreeInfoProvYn = agreeInfoProvYn.value ? 'Y' : 'N';
    }
</script>


<template>
    <div class="sign-wrap">
        <div class="sign-inner">
            <SignupProgressBar :activeIndex="2"/>
            <div class="terms-wrap">
                <div class="check-box">
                    <KSACheckBox v-model="isSelectedAll" @change="toggleSelectAll"/> <span class="terms-txt">한국반도체아카데미 <b>회원가입 약관과 개인정보이용</b>에 모두 동의합니다.</span>
                </div>
                <hr>
                <KSACheckBox label="회원가입 약관에 동의합니다." v-model="agreeTermYn" @change="toggleAgreeTerm" /><span class="red">(필수)</span>
                <div class="terms-box">
                    1.수집하는 개인정보 항목<br><br>

                    회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br>
                    ο 수집항목 : 이름 , 로그인ID , 비밀번호 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 회사전화번호 이메일 , 회사명 , 부서<br><br>

                    2.개인정보의 수집 및 이용목적<br><br>

                    회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br>
                    ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 제공<br>
                    ο 회원 관리 회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 가입 의사 확인 , 연령확인 , 불만처리 등 민원처리 , 공지사항 전달<br>
                    ο 마케팅 및 광고에 활용 이벤트 등 광고성 정보 전달<br><br>


                    3.개인정보의 보유 및 이용기간<br><br>

                    회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.
                </div>
                <div class="table-form mb-lg">
                    <p><KSACheckBox label="개인정보이용에 동의합니다." v-model="agreePrivacyYn" @change="toggleAgreePrivacy" /><span class="red">(필수)</span></p>
                    <table class="horizontal">
                        <colgroup>
                            <col width="33.333%" />
                            <col width="33.333%" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>목적</th>
                            <th>항목</th>
                            <th>보유기간</th>
                        </tr>
                        <tr>
                            <td>이용자 식별 및<br>본인여부 확인</td>
                            <td>아이디, 이름(담당자 이름),<br> 비밀번호</td>
                            <td>회원탈퇴 시까지</td>
                        </tr>
                        <tr>
                            <td>고객서비스 이용에 관한 통지, CS대응을 위한 이용자 식별</td>
                            <td>연락처<br>(이메일, 핸드폰 번호)</td>
                            <td>회원탈퇴 시까지</td>
                        </tr>
                        <tr>
                            <td>교육 신청 및 채용연계에 따른 개인정보 수집</td>
                            <td>[필수] 이름, 핸드폰 번호, 이메일, 관심 카테고리, 희망 직무, 학력사항, 병역<br>
                                [선택] 자기 소개서, 희망 연봉, 포트폴리오, 수상, 활동, 교육 연수, 기타, 자격증, 어학 시험, 외국어, 업무 경험</td>
                            <td>이력서 삭제 또는<br>회원탈퇴 시까지</td>
                        </tr>
                    </table>
                </div>
                <KSACheckBox label="제 3자 개인정보 제공에 동의합니다." v-model="agreeInfoProvYn" @change="toggleAgreeInfoProvYn" /><span class="gray">(선택)</span>
                <div class="terms-box">
                    한국반도체아카데미는 원칙적으로 개인정보를 제3자에게 제공하지 않으며,<br><br>

                    다만 아래의 경우는 예외로 합니다.<br><br>

                    - 한국반도체아카데미 내 서비스 이용을 위해 이용자가 본인의 이력서 공개에 동의한 경우<br>
                    [제공받는 자]<br>
                    -기업회원<br>
                    [제공하는 항목]<br>
                    - 이력서 내 모든 개인정보 항목<br>
                    [제공받는 자의 이용목적]<br>
                    - 채용 목적에 따른 인재 검색<br>
                    [보유 및 이용기간]<br>
                    - 동의일로부터 철회 요청 시까지<br>
                    [개인정보 수집ᆞ이용 동의 거부의 권리]<br>
                    귀하는 개인정보의 수집.이용에 대해서 동의를 거부하실 권리가 있습니다. 다만, 동의를 거부하시는 경우, 채용 지원 서비스 이용이 제한적일 수 있음을 알려드립니다.
                </div>
            </div>

            <div class="btn-wrap">
                <KSASecondaryButton class="full-btn" text="이전" @click="prev" />
                <KSAPrimaryButton class="full-btn" text="다음" @click="next" :disabled="!agreeTermYn || !agreePrivacyYn"/>
            </div>
        </div>
    </div>
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="나가기" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .terms-wrap {
        margin: 20px 0;
        > .check-box:first-child {
            @include font-size_md;
        }
        hr {
            border: $border_line;
            margin: 20px 0;
        }
        .terms-box {
            height: 180px;
            overflow-y: auto;
            @include font-size_xs;
            background: $gray-thin_color;
            border: $border_line;
            padding: 20px;
            margin: 10px 0 40px;
        }
        .table-form {
            table.horizontal {
                th, td {
                    @include font-size_xs;
                }
                th {
                    font-weight: 400;
                }
            }
        }
    }
</style>