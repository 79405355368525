<script setup>
    import { ref, onMounted, computed, getCurrentInstance } from 'vue'
    import insightsNotifyService from "../../services/insights/insightsNotifyService";
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import { useUserInsightsStore} from "../../stores/insights/userInsightsStore";

    const loadingStore = usePageLoadingStore()
    const userInsightsStore = useUserInsightsStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    const searchType = ref('all');
    const searchTypeOptions = [
        { label:'전체', value:'all', },
        { label:'제목', value:'title', },
        { label:'내용', value:'content', },
    ]
    const input = ref({
        search: ''
    });

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    onMounted(() => {
        retrieveList();
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1 && searchType.value === 'all' && input.value.search === '';
    });

    const retrieveList = () => {
        let paramObj = {
            pageNo: currentPage.value,
            size: pageSize,
            searchType: searchType.value,
            searchInput: input.value.search || ""
        }

        loadingStore.loading = true;
        insightsNotifyService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                    if(isInitView.value) {
                        userInsightsStore.setNoticeList(list.value);
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSearchInput = (value) => {
        input.value.search = value;
        currentPage.value = 1;
        retrieveList();
    }

    const onOptionSelected = (option) => {
        searchType.value = option.value
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>공지사항</h3>
            </div>
            <div class="list-top">
                <p>총 게시물 <span>{{ $number(totalCount) }}</span>건</p>
                <div class="search-box">
                    <KSASelect :options="searchTypeOptions" class="search-select" @selectOption="onOptionSelected"/>
                    <KSASearchInputBox placeholder="검색어를 입력하세요." class="search-input" @search="onSearchInput" />
                </div>
            </div>
            <div class="table-form board-list border-list-mobile">
                <table>
                  <thead>
                    <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>작성일</th>
                        <th>조회수</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(notice, index) in (isInitView ? userInsightsStore.noticeList : list)">
                        <td v-if="notice.notifyYn === 'Y'" style="font-weight: bold; color: blue;">공지</td>
                        <td v-else>{{ notice.boardNo }}</td>
                        <td v-if="notice.notifyYn === 'Y'" class="text-left mobile-list-main"><RouterLink
                            :to="{ name: 'InsightsNotifyDetail', params: {id: notice.boardId }}" style="font-weight: bold;">{{ notice.title }}📣<img v-if="notice.attachList.length > 0 && notice.attachList[0].fileName" src="/src/assets/images/icon-file.png" /></RouterLink></td>
                        <td v-else class="text-left mobile-list-main"><RouterLink
                            :to="{ name: 'InsightsNotifyDetail', params: {id: notice.boardId }}">{{ notice.title }}<img v-if="notice.attachList.length > 0 && notice.attachList[0].fileName" src="/src/assets/images/icon-file.png" /></RouterLink></td>
                        <td class="mobile-list-sub">{{ $yyyymmddOrHHmm(notice.createDate)}}</td>
                        <td class="mobile-list-sub">{{ $number(notice.hitCount) }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="result-none" v-if="list.length === 0">
                    검색 결과가 없습니다.
                </div>
            </div>
            <Paginate
                v-if="list.length > 0"
                v-model="currentPage"
                :page-count="totalPages"
                :click-handler="onSelectedPage"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    img {
        width: 14px;
        vertical-align: middle;
        margin: 0 0 0 4px;
    }
</style>
